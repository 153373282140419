import makeStyles from '@material-ui/core/styles/makeStyles';
var useStyles = makeStyles({
    container: {
        cursor: 'pointer',
        color: 'inherit',
        width: '12px',
        height: '15px',
        border: 0,
        background: 'transparent',
        '&:focus': {
            outline: 'none',
        },
    },
    icon: {
        fontWeight: 900,
        fontSize: '12px',
        lineHeight: '14px',
    },
    active: {
        color: '#FFEB3B',
    },
}, { name: 'FavoritesIcon' });
export { useStyles };
