import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from "classnames";
import { BetInfo, MoreInfo, WagerView } from '~atoms';
import { useStructuredSelector } from '~hooks';
import { changeCombinationDDLState, setWagerElement } from '~utils/wager';
import { betslipDataSelector, selectBetType, selectSelectedCombinationsCount, } from '~slices/betslip';
import { CSIcon } from "~icons/rankingList/CSIcon";
import { useStyles } from './BetslipWagerArea.styles';
var BetslipWagerArea = function (_a) {
    var _b, _c, _d, _e, _f;
    var wa = _a.wa, onChangeWager = _a.onChangeWager, onValidate = _a.onValidate, wagerError = _a.wagerError, onBluer = _a.onBluer, wagerValue = _a.wagerValue, onInputClick = _a.onInputClick;
    var classes = useStyles();
    var t = useTranslation('ticket').t;
    var _g = useStructuredSelector({
        betslip: betslipDataSelector,
        selectedCount: selectSelectedCombinationsCount,
        selectedBetType: selectBetType,
    }), betslip = _g.betslip, selectedBetType = _g.selectedBetType, selectedCount = _g.selectedCount;
    var eventsLength = (_c = (_b = betslip.to) === null || _b === void 0 ? void 0 : _b.evs) === null || _c === void 0 ? void 0 : _c.length;
    var mo = (_d = betslip.to) === null || _d === void 0 ? void 0 : _d.mo;
    var cu = (_e = betslip.to) === null || _e === void 0 ? void 0 : _e.cu;
    var combinationName = selectedCount === 1 &&
        ['SINGLE', 'BANKER_ONLY', 'COMBINATION'].includes(selectedBetType)
        ? t("bet_type.".concat(selectedBetType))
        : t('bet_type.SYSTEM');
    var combinations = ((_f = betslip.to) === null || _f === void 0 ? void 0 : _f.sy) || [];
    var firstSelectedCombination = combinations.find(function (_a) {
        var ssel = _a.ssel, usel = _a.usel;
        return ssel || usel;
    });
    var getCombinationValue = function (_a) {
        var _b = _a.bss, _c = _b === void 0 ? {} : _b, _d = _c.bnk, bnk = _d === void 0 ? 0 : _d, _e = _c.bssn, bssn = _e === void 0 ? 'SINGLE' : _e, _f = _c.num, num = _f === void 0 ? 0 : _f, _g = _c.den, den = _g === void 0 ? 0 : _g, _h = _a.nbet, nbet = _h === void 0 ? 0 : _h;
        var betsCount = "".concat(t('counters.bet', { count: nbet }));
        var bankersCount = bnk ? "+ ".concat(t('counters.banker', { count: bnk })) : '';
        var betTypes = {
            SINGLE: function () { return betsCount; },
            BANKER_ONLY: function () { return betsCount; },
            COMBINATION: function () { return "".concat(den + bnk, "x (").concat(betsCount, ")"); },
            SYSTEM: function () {
                return "".concat(t('system_fraction', {
                    num: num,
                    den: den,
                }), " ").concat(bankersCount, " (").concat(betsCount, ")");
            },
        };
        return betTypes[bssn]();
    };
    var handleWagerElementRefChange = useCallback(function (node) {
        setWagerElement(node);
    }, []);
    var handleMoreClick = function () {
        changeCombinationDDLState(true);
    };
    useEffect(function () {
        return function () {
            setWagerElement(null);
        };
    }, []);
    return (React.createElement("div", { className: classes.container },
        React.createElement(BetInfo, { eventsCount: eventsLength, maxOdd: mo, combinationName: combinationName, combinationValue: firstSelectedCombination && selectedCount === 1 ? (getCombinationValue(firstSelectedCombination)) : (React.createElement(MoreInfo, { onClick: handleMoreClick })) }),
        React.createElement("div", null,
            React.createElement(WagerView, { currency: cu, value: wa || '', onChange: onChangeWager, onValidate: onValidate, onBluer: onBluer, ref: handleWagerElementRefChange, wagerValue: wagerValue, onContainerClick: onInputClick }),
            wagerError && React.createElement("div", { className: classNames(classes.wagerError, 'flex-row flex-center') },
                React.createElement("span", null, wagerError),
                " ",
                cu === 'CRD' ? React.createElement(CSIcon, { color: '#FF0000' }) : null))));
};
export { BetslipWagerArea };
