import { useSelector } from 'react-redux';
import { name } from './constants';
export var cashOutSelector = function (state) { return state[name]; };
export var cashOutDataSelector = function (state) {
    return cashOutSelector(state).data;
};
export var loadingCashOutSelector = function (state) {
    return cashOutSelector(state).loading;
};
export var isCashoutAvailableSelector = function (state) {
    return cashOutSelector(state).data.cashout !== 0 &&
        cashOutSelector(state).loading === 'fulfilled';
};
export var currencySelector = function (state) {
    return cashOutSelector(state).data.currency;
};
/* @deprecated use useStructuredSelector instead */
export var useCashOutDataSelector = function () { return useSelector(cashOutDataSelector); };
/* @deprecated use useStructuredSelector instead */
export var useloadingCashOutSelector = function () {
    return useSelector(loadingCashOutSelector);
};
/* @deprecated use useStructuredSelector instead */
export var useIsCashoutAvailableSelector = function () {
    return useSelector(isCashoutAvailableSelector);
};
/* @deprecated use useStructuredSelector instead */
export var useCurrencyCashOutSelector = function () { return useSelector(currencySelector); };
