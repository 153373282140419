import { useCallback } from 'react';
import { push } from 'connected-react-router';
import { useAppDispatch } from '~store/configureStore';
import { useIsDesktop, useStructuredSelector } from '~view/hooks';
import { history } from '~utils/history';
import scrollWatcher from '~utils/scroll';
import { activeSidebetIdSelector, pickActiveSidebetId } from '~slices/sidebets';
import { getPathWithLanguageCode } from '~utils/languageInURL/getPathWithLanguageCode';
var useOpenSideBets = function (_a) {
    var eventId = _a.eventId, round = _a.round, isEasyGameLocked = _a.isEasyGameLocked, isMakeTipButton = _a.isMakeTipButton, ticket = _a.ticket;
    var isDesktop = useIsDesktop();
    var dispatch = useAppDispatch();
    var activeSidebetId = useStructuredSelector({
        activeSidebetId: activeSidebetIdSelector,
    }).activeSidebetId;
    var openSideBets = useCallback(function () {
        if (isDesktop) {
            if (activeSidebetId === eventId) {
                dispatch(pickActiveSidebetId(0));
            }
            else {
                dispatch(pickActiveSidebetId(eventId));
            }
            return;
        }
        scrollWatcher.setScrollTop(document.documentElement.scrollTop);
        scrollWatcher.setSouldScroll(true);
        dispatch(push({
            pathname: getPathWithLanguageCode("/sidebets/".concat(eventId)),
            state: {
                prevPath: history.location.pathname,
                round: round,
                isEasyGameLocked: isEasyGameLocked,
                isMakeTipButton: isMakeTipButton,
                ticket: ticket,
                scrollPosition: document.documentElement.scrollTop,
            },
        }));
    }, [eventId, round, isDesktop, activeSidebetId, isEasyGameLocked, isMakeTipButton, ticket]);
    return {
        openSideBets: openSideBets,
    };
};
export { useOpenSideBets };
