import * as React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { useEffect } from 'react';
import { push } from 'connected-react-router';
import { PrivateRoute } from '~routes/PrivateRoute';
import Payments from '~pages/payments/Payments';
import { isBetCheckProject } from '~utils/constants/betCheckConstants';
import { getPathWithLanguageCode } from '~utils/languageInURL';
import { useAppDispatch } from '~store';
import { TopUpTab } from '~molecules/TopUpTab';
import { DepositTab } from '~molecules/DepositTab';
import { WithdrawTab } from '~molecules/WithdrawTab';
import { WithdrawStatus } from '~molecules/WithdrawTab/components/WithdrawStatus';
import { WithdrawConfirmation } from '~molecules/WithdrawTab/components/WithdrawConfirmation';
import { TopUpStatus } from '~pages/payments/components/TopUpStatus';
export var PaymentsURL;
(function (PaymentsURL) {
    PaymentsURL["PAYMENTS"] = "payments";
    PaymentsURL["TOP_UP"] = "top-up";
    PaymentsURL["DEPOSIT"] = "deposit";
    PaymentsURL["WITHDRAW"] = "withdraw";
    PaymentsURL["STATUS"] = "status";
    PaymentsURL["DEPOSIT_SHOP_BALANCE"] = "deposit/shop-balance";
    PaymentsURL["DEPOSIT_ONLINE_BALANCE"] = "deposit/online-balance";
    PaymentsURL["WITHDRAW_SHOP_BALANCE"] = "withdraw/shop-balance";
    PaymentsURL["WITHDRAW_JACKPOT_BALANCE"] = "withdraw/jackpot-balance";
    PaymentsURL["WITHDRAW_ONLINE"] = "withdraw/jackpot-balance/online";
    PaymentsURL["WITHDRAW_IN_SHOP"] = "withdraw/jackpot-balance/withdraw-in-shop";
    PaymentsURL["TOP_UP_STATUS"] = "top-up/status";
    PaymentsURL["WITHDRAW_STATUS"] = "withdraw/status";
    PaymentsURL["WITHDRAW_CONFIRMATION"] = "withdraw/confirmation";
    PaymentsURL["PAYMENT_STATUS"] = "payment-status";
    PaymentsURL["PAYMENT_CODE"] = "payment-code";
    PaymentsURL["TOP_IT_UP"] = "top-it-up";
})(PaymentsURL || (PaymentsURL = {}));
export var PaymentsRoutes = function (_a) {
    var match = _a.match;
    var root = match.url;
    var dispatch = useAppDispatch();
    useEffect(function () {
        if (isBetCheckProject) {
            dispatch(push(getPathWithLanguageCode('/home')));
        }
    }, []);
    return (React.createElement(Payments, null,
        React.createElement(Switch, null,
            React.createElement(PrivateRoute, { exact: true, path: "".concat(root, "/").concat(PaymentsURL.TOP_UP), component: TopUpTab }),
            React.createElement(PrivateRoute, { exact: true, path: "".concat(root, "/").concat(PaymentsURL.TOP_UP_STATUS), component: TopUpStatus }),
            React.createElement(PrivateRoute, { exact: true, path: "".concat(root, "/").concat(PaymentsURL.DEPOSIT), component: DepositTab }),
            React.createElement(PrivateRoute, { exact: true, path: "".concat(root, "/").concat(PaymentsURL.DEPOSIT, "/:type"), component: DepositTab }),
            React.createElement(PrivateRoute, { exact: true, path: "".concat(root, "/").concat(PaymentsURL.DEPOSIT, "/:type/:action"), component: DepositTab }),
            React.createElement(PrivateRoute, { exact: true, path: "".concat(root, "/").concat(PaymentsURL.WITHDRAW), component: WithdrawTab }),
            React.createElement(PrivateRoute, { exact: true, path: "".concat(root, "/").concat(PaymentsURL.WITHDRAW_STATUS), component: WithdrawStatus }),
            React.createElement(PrivateRoute, { exact: true, path: "".concat(root, "/").concat(PaymentsURL.WITHDRAW_CONFIRMATION), component: WithdrawConfirmation }),
            React.createElement(PrivateRoute, { exact: true, path: "".concat(root, "/").concat(PaymentsURL.WITHDRAW, "/:type"), component: WithdrawTab }),
            React.createElement(PrivateRoute, { exact: true, path: "".concat(root, "/").concat(PaymentsURL.WITHDRAW, "/:type/:action"), component: WithdrawTab }),
            React.createElement(Redirect, { to: root }))));
};
