import React from 'react';
import cn from 'classnames';
import { mapSportIdToSportIcon } from '~slices/sports';
import { SoccerIcon } from '~icons/sports';
import { HighlightedText } from '~atoms';
import { getTestId } from '~/utils/getTestId';
import { useStyles } from './SportCard.styles';
var noop = function () { return undefined; };
var SportCard = function (_a) {
    var id = _a.id, _b = _a.active, active = _b === void 0 ? false : _b, _c = _a.highlightedValue, highlightedValue = _c === void 0 ? '' : _c, _d = _a.onClick, onClick = _d === void 0 ? noop : _d, bid = _a.bid, name = _a.name;
    var classes = useStyles();
    var handleClick = function () { return onClick(id, name); };
    var SportIcon = mapSportIdToSportIcon[bid] || SoccerIcon;
    return (React.createElement("div", { role: "button", tabIndex: 0, onClick: handleClick, className: classes.container, "data-testid": getTestId("BTN_SPORT_CARD-".concat(id)) },
        React.createElement("div", { className: cn(classes.status, active && classes.active) }),
        React.createElement("div", { className: classes.content },
            React.createElement(SportIcon, { className: classes.icon }),
            React.createElement("div", { className: classes.name },
                React.createElement(HighlightedText, { value: name, highlightValue: highlightedValue })))));
};
export { SportCard };
