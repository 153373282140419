import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var OlympicsIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { viewBox: "0 0 28 28", className: className },
        React.createElement("svg", { width: "28", height: "28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "m18.5 2.6-1.3 1.2.9 4.6-1.6 1.6L3 25.2c-.5.5-.4 1.3 0 1.8H3c.5.6 1.3.6 1.8.1L20 13.5l1.6-1.5 4.6.8 1.3-1.3-9-9Z", fill: "#6D92AC" }),
            React.createElement("path", { d: "m18.5 2.6-1.3 1.2.9 4.6 3.5 3.6 4.6.8 1.3-1.3-9-9Z", fill: "#FFC144" }),
            React.createElement("path", { d: "M24.3 2H22c0-.8-.7-1.5-1.5-1.5h-4c0 .6.2 1.1.4 1.6H15c-.9 0-1.6-.7-1.6-1.6 0 3 2.4 5.2 5.2 5.2h3.1l2.7 2.7a3.2 3.2 0 0 0 0-6.3Z", fill: "#FF5A58" }),
            React.createElement("path", { d: "m13 17.3 2 2c.3.3.3.8 0 1l-.3.3a1 1 0 0 1-1.4 0L12 19.2l1.4 1.4c.3.3.3 1-.2 1.2l-.4.2a1 1 0 0 1-1.1-.2l-.8-.8.6.6a.7.7 0 0 1-.3 1c-.7.3-1.5.2-2-.3l-1.7-1.7L4 24 .5 20.6l2.9-2.8c1.9-2 4.8-3.2 7.6-3.7a3 3 0 0 1 2.6.9l1.5 1.6c.6.5.9 1.3.8 2.1-.1.5-.8.8-1.2.4L13 17.3Z", fill: "#FFD0C7" }),
            React.createElement("path", { d: "M19.7 3.7a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm8.1 7.5a.5.5 0 0 1 0 .7l-1.2 1.3a.5.5 0 0 1-.5.2l-4.3-.9-1.4 1.4-4 3.6v1.3c0 .4-.3.7-.7.9 0 .4 0 .7-.3 1l-.3.3c-.3.3-.7.5-1.1.5 0 .3-.3.6-.6.7l-.4.3c-.3.1-.7.2-1 0-.1.4-.4.6-.7.7-.5.2-1 .2-1.4 0l-4.8 4.3a1.8 1.8 0 0 1-2.4 0l-.1-.1c-.7-.7-.8-1.8-.1-2.5l.7-.8-3-3a.5.5 0 0 1 0-.9L3 17.4c1.9-1.8 4.8-3.3 7.9-3.8l1.6.1 3.6-4 1.4-1.4L17 6A5.8 5.8 0 0 1 12.8.5a.5.5 0 0 1 1 0c0 .6.5 1 1 1h1.4a3.4 3.4 0 0 1-.1-1 .6.6 0 0 1 .5-.5h4a2.1 2.1 0 0 1 2 1.5h1.7a3.7 3.7 0 0 1 1.1 7.3l2.4 2.4Zm-9.3-6h4.7a.5.5 0 0 1 0 1H23l1.5 1.6a2.6 2.6 0 0 0-.2-5.2h-1.7c0 .1 0 .2-.2.3a.6.6 0 0 1-.8-.1V2.2c0-.6-.5-1.1-1-1.1h-3.4c.2.6.5 1.2 1 1.6a.5.5 0 1 1-.6.8c-.4-.2-.7-.5-.9-.9H15a2 2 0 0 1-.6 0 4.7 4.7 0 0 0 4.2 2.6ZM9 22.8l-.2-.2-1.3-1.3-3 3.1-1.1 1.2c-.3.3-.2.7 0 1h.1c.3.4.7.4 1 .1l4.5-4Zm5.9-5.9-1.6-1.5c-.2-.3-.5-.4-.8-.6-.4-.1-.9-.2-1.3-.1a14 14 0 0 0-7.3 3.5l-2.5 2.4L4 23.3l2.7-2.7-1.2-1.2a.5.5 0 0 1 .8-.7l3.2 3.2a1.3 1.3 0 0 0 1.4.3l.1-.1V22l-.5-.6a.5.5 0 1 1 .7-.7l.8.7c.1.1.3.2.5 0l.3-.1.2-.2-.1-.2-1.4-1.3a.5.5 0 0 1 .8-.8l1.4 1.4c.2.2.5.2.6 0l.3-.2v-.3l-.2-.2-1.8-1.8a.5.5 0 1 1 .8-.7l1.7 1.7h.2a2 2 0 0 0-.5-1.8Zm4.4-3.4-2.7-2.7-3 3.4.5.4 1.5 1.6.3.3 3.4-3Zm1.6-1.5-2.7-2.8-.8.8 2.7 2.7.8-.7Zm3.7 0L19 6.3h-.7l.4 1.9 3.2 3.3 2.7.5Zm2.2-.5-2.8-2.7-2.5-2.5h-1l5.8 5.7.5-.5Z", fill: "#000" }))));
};
