import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { currencyConverter } from '~utils/converters';
import { useStyles } from '../topRanking.styles';
export var DesktopTicketDetailsFooter = function (_a) {
    var to = _a.to;
    var classes = useStyles();
    var t = useTranslation('ticket').t;
    var maxOdd = to === null || to === void 0 ? void 0 : to.mo;
    if (!maxOdd) {
        return null;
    }
    return (React.createElement("div", { className: classNames('flex-row', 'space-between', classes.footer) },
        (to === null || to === void 0 ? void 0 : to.bt) && (React.createElement("div", { className: classNames(classes.capitalize, classes.footerLabel) }, t("bet_type.".concat(to === null || to === void 0 ? void 0 : to.bt)))),
        React.createElement("div", { className: "flex-row" },
            React.createElement("span", { className: classes.wager }, t('wager')),
            currencyConverter(to === null || to === void 0 ? void 0 : to.wa, to === null || to === void 0 ? void 0 : to.cu))));
};
