import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var SquashIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("svg", { width: "28", height: "28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M25.7 24.5a4 4 0 0 1-3.5 3.5c-.2 0-.3-.4-.4-.4-.2 0-.4.4-.5.4a4 4 0 0 1-3.6-3.6l.3-.4c0-.2-.3-.3-.3-.5a4 4 0 0 1 3.5-3.5c.2 0 .4.7.4.7l.5-.7a4 4 0 0 1 3.6 3.6c0 .1-.5.3-.5.5 0 .1.5.2.5.4Z", fill: "#BBEC6A" }),
            React.createElement("path", { d: "M25.7 24a4 4 0 0 0-4.5-4 3.8 3.8 0 0 1 2 1.1 4 4 0 0 1-2.4 6.8 3.6 3.6 0 0 0 .5 0l.5-.1.4.2a4 4 0 0 0 2.3-1.2 4 4 0 0 0 1.2-2.8Z", fill: "#97DA7B" }),
            React.createElement("path", { d: "m25.7 24.5-1.2.2c-1 0-1.8-.4-2.5-1-1-1-1.2-2.4-.8-3.7h1l-.1.2c-.5 1-.2 2.1.5 2.9.8.7 2 1 3 .5v.9ZM22.2 28h-1l.2-.3a2.6 2.6 0 0 0-3.4-3.4l-.3.1v-.9c1.3-.4 2.7-.1 3.7.8 1 1 1.2 2.4.8 3.7Z", fill: "#EEE1DC" }),
            React.createElement("path", { d: "m15.4 18.3-5.7-5.7c.2 1.3-.6 4.2-2.7 6.3l-.3.3.4 1.7 1.7.4.3-.3c2-2 5-2.9 6.3-2.7Z", fill: "#F8F2F0" }),
            React.createElement("path", { d: "M13.5 14.5c-1.4-1.4-1.6-3.7-.6-6a10 10 0 0 1 5.3-5.3c2.3-1 4.6-.8 6 .7 1.4 1.4 1.5 3.7.6 6a10 10 0 0 1-5.3 5.3c-2.3.9-4.6.7-6-.7Zm11 .4c2-2 3-4.2 3.4-6.4V5.7A6.7 6.7 0 0 0 26.1 2c-3.2-3.2-9-2.5-13 1.5a11 11 0 0 0-3.4 9 6.8 6.8 0 0 0 5.7 5.7l.2.1c2.9.4 6.3-.8 9-3.4Z", fill: "#FE8607" }),
            React.createElement("path", { d: "m7.5 23.2-1-1.9-1.7-.8-3 3 1 1.7 1.8 1 3-3Z", fill: "#7CA2B1" }),
            React.createElement("path", { d: "m4.6 26.1-2.7-2.7a1 1 0 0 0-1.4 0l-.1.2a1 1 0 0 0 0 1.4L3 27.6c.3.4 1 .4 1.4 0 .5-.5.5-1 0-1.5Z", fill: "#00C0FA" }),
            React.createElement("path", { d: "M15.6 18.3c2.9.4 6.3-.8 9-3.4 1.8-2 3-4.2 3.3-6.4V5.7a6.7 6.7 0 0 0-2.3-4.2c.7 1 1.2 2 1.4 3.2v2.8c-.4 2.3-1.5 4.6-3.4 6.5a11 11 0 0 1-9 3.4 5 5 0 0 0-1.6 0 7 7 0 0 0 2.6 1Z", fill: "#F46700" }),
            React.createElement("path", { d: "m3.7 25.2 3-3 .8 1-3 3-.8-1Z", fill: "#668D97" }),
            React.createElement("path", { d: "M4.6 27.5c.4-.4.4-1 0-1.4l-1-.9c.4.4.4 1 0 1.4v.1a1 1 0 0 1-1.5 0l1 1c.3.3 1 .3 1.4 0v-.2Z", fill: "#08A9F1" }),
            React.createElement("path", { d: "M9.1 21c2-2 5-2.9 6.3-2.7a7 7 0 0 1-2.3-.8A10 10 0 0 0 8.2 20l-.3.3.9 1 .3-.4Z", fill: "#EEE1DC" }),
            React.createElement("path", { d: "m8.8 21.3-2.1-2a.4.4 0 0 0-.6 0l-1.3 1.2 2.7 2.7 1.3-1.3c.2-.2.2-.4 0-.6Z", fill: "#00C0FA" }),
            React.createElement("path", { d: "m7.5 23.2 1.3-1.3c.2-.2.2-.4 0-.6l-1-.9c.2.2.2.4 0 .6l-1.2 1.3 1 .9Z", fill: "#08A9F1" }),
            React.createElement("path", { d: "M24.5 3.6c-1.1-1.2-2.8-1.6-4.7-1.3a9.7 9.7 0 0 0-5 2.8 9.7 9.7 0 0 0-2.9 5.1c-.3 1.9.2 3.5 1.3 4.6.9 1 2 1.4 3.5 1.4l1.1-.1a9.7 9.7 0 0 0 5.1-2.8 9.7 9.7 0 0 0 2.8-5.1c.3-1.9-.1-3.5-1.2-4.6Zm.4 4.4c0 .4-.2.7-.3 1l-1.2-1.2 1.5-1.5V8Zm-5.3 6.7L18 13l2.1-2 2 1.9c-.8.7-1.7 1.3-2.5 1.7Zm-6.8-4.4.2-1 1.3 1.3-1.5 1.5v-1.8Zm.6-1.8c.4-1 1-1.8 1.6-2.5l2 2-2.1 2-1.5-1.5ZM18 3.7l1.5 1.6-2 2-2-2 2.5-1.6Zm2.1 2.1 2 2-2 2-2-1.9 2-2Zm-2.6 2.7 2 2-2.2 2-2-2 2.2-2Zm5 3.9-1.9-2 2-2 1.6 1.5c-.4.9-1 1.7-1.7 2.5Zm2-7-1.8 1.8-2-2 1.8-1.7v-.1a3.6 3.6 0 0 1 2 2ZM21 3h.8l-1.6 1.6L19 3.4c.7-.2 1.3-.3 2-.3Zm-8 10 .1-.1 1.8-1.8 2 2-1.8 1.7-.1.1a3.6 3.6 0 0 1-2-2Zm2.9 2.2 1.5-1.6 1.3 1.3a6 6 0 0 1-2.8.3Z", fill: "#7CA2B1" }))));
};
