import * as React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FootballFieldIcon from '~icons/football-field.jpg';
var useStyles = makeStyles({
    wrapper: {
        display: 'flex',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
    },
    text: {
        position: 'absolute',
        color: '#ffffff',
    },
    img: {
        width: '100%',
        filter: 'brightness(0.5)',
    },
}, { name: 'NotAvailableEvent' });
export var NotAvailableEvent = function () {
    var t = useTranslation('ticket').t;
    var classes = useStyles();
    return (React.createElement("div", { className: classNames(classes.wrapper) },
        React.createElement("img", { className: classNames(classes.img), src: FootballFieldIcon, alt: "football" }),
        React.createElement("span", { className: classNames(classes.text) }, t('unavailable_event'))));
};
