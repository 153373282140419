var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { unwrapResult } from '@reduxjs/toolkit';
import { parseJwt } from '~utils/parseJwt';
import store from '~store/configureStore';
import { accessTokenSelector, checkRegistrationData, userEmailSelector, } from '~slices/auth';
import { errorSelector } from '~slices/account';
import { getAsyncValidationMessage } from './getAsyncValidationMessage';
var asyncValidation = function (values, _dispatch, 
// eslint-disable-next-line @typescript-eslint/no-explicit-any
props, blurredField) {
    var _a;
    var token = accessTokenSelector(store.getState());
    var userEmail = userEmailSelector(store.getState());
    var accountError = errorSelector(store.getState());
    var tokenData;
    if (token) {
        tokenData = parseJwt(token);
    }
    if (!blurredField) {
        return Promise.resolve();
    }
    var errors = accountError === null || accountError === void 0 ? void 0 : accountError.errors;
    if (((values === null || values === void 0 ? void 0 : values.email) && (values === null || values === void 0 ? void 0 : values.email) === userEmail) ||
        (errors && errors[0].email) === (values === null || values === void 0 ? void 0 : values.email) ||
        ((tokenData === null || tokenData === void 0 ? void 0 : tokenData.email) === (values === null || values === void 0 ? void 0 : values.email) && props.form !== 'registration-form')) {
        return Promise.resolve();
    }
    var asyncErrors = (_a = props.asyncErrors) !== null && _a !== void 0 ? _a : {};
    if (props.asyncErrors && blurredField in props.asyncErrors) {
        return Promise.reject(asyncErrors);
    }
    return store
        .dispatch(checkRegistrationData({
        fieldType: blurredField.toUpperCase(),
        fieldValue: values[blurredField],
    }))
        .then(unwrapResult)
        .then(function (res) {
        var _a;
        var errors = res === null || res === void 0 ? void 0 : res.errors;
        if (errors) {
            return Promise.reject(__assign((_a = {}, _a[errors[0].field || ''] = errors[0].field !== 'username'
                ? getAsyncValidationMessage('ER0002_email')
                : getAsyncValidationMessage('ER0002_username'), _a), asyncErrors));
        }
        else if (Object.keys(asyncErrors).length > 0) {
            return Promise.reject(asyncErrors);
        }
        return null;
    })
        .catch(function (res) {
        var _a;
        var errors = res === null || res === void 0 ? void 0 : res.errors;
        if (errors) {
            return Promise.reject(__assign((_a = {}, _a[errors[0].field || ''] = errors[0].field !== 'username'
                ? getAsyncValidationMessage('ER0002_email')
                : getAsyncValidationMessage('ER0002_username'), _a), asyncErrors));
        }
        else if (Object.keys(asyncErrors).length > 0) {
            return Promise.reject(asyncErrors);
        }
        return null;
    });
};
export default asyncValidation;
