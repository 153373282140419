var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '~store';
import { OUTRIGHTS_SOCKET_NAME, connectOutrights, getOutrights, outrightsOutrightsDataSelector, resetOutrights, } from '~slices/outrights';
import { deleteOutrightOdd, selectOutrightOdd, shortcutCountSelector, } from '~slices/shortcut';
import { useIsDesktop, useStructuredSelector } from '~hooks';
import { getShortcutByIdSelector } from '~slices/events';
import { MODALS } from '~modals/modals';
import { openModal } from '~modals/modalSlice';
import { OddPlate } from '~molecules';
import { OddWidth } from '~constants/view/OddWidth';
import useStyles from '../Outrights.styles';
import { List } from './List';
var WEBSOCKET = process.env.REACT_APP_BASE_WEBSOCKET || '';
export var OutcomesList = function (_a) {
    var onClick = _a.onClick, onBackClick = _a.onBackClick, TOURNAMENT = _a.TOURNAMENT, CATEGORY = _a.CATEGORY, rest = __rest(_a, ["onClick", "onBackClick", "TOURNAMENT", "CATEGORY"]);
    var classes = useStyles();
    var dispatch = useAppDispatch();
    var t = useTranslation('common').t;
    var outrights = useStructuredSelector({
        outrights: outrightsOutrightsDataSelector,
    }).outrights;
    var isDesktop = useIsDesktop();
    var _b = useStructuredSelector({
        count: function (state) { return shortcutCountSelector(state); },
        shortcutEvents: function (state) {
            return getShortcutByIdSelector(state, 
            // @ts-expect-error
            TOURNAMENT === null || TOURNAMENT === void 0 ? void 0 : TOURNAMENT.bid);
        },
    }), shortcutEvents = _b.shortcutEvents, count = _b.count;
    useEffect(function () {
        if (i18next.language && TOURNAMENT) {
            // @ts-expect-error
            dispatch(getOutrights({ id: TOURNAMENT.id, langIso: i18next.language }));
        }
    }, [i18next.language, TOURNAMENT]);
    useEffect(function () {
        dispatch(connectOutrights({
            url: "".concat(WEBSOCKET, "sport-events/v1/ws/outright"),
            langIso: i18next.language,
            // @ts-expect-error
            betradarId: TOURNAMENT === null || TOURNAMENT === void 0 ? void 0 : TOURNAMENT.bid,
            eventsType: OUTRIGHTS_SOCKET_NAME,
        }));
        return function () {
            dispatch({ type: 'SOCKET_CLOSE', eventsType: OUTRIGHTS_SOCKET_NAME });
            dispatch(resetOutrights());
        };
    }, []);
    var renderValue = function (item) {
        var isSelected = shortcutEvents === null || shortcutEvents === void 0 ? void 0 : shortcutEvents.some(function (shortcutEvent) { return shortcutEvent.oi === item.id; });
        var onClickOutright = function () {
            var handleFunc = !isSelected ? selectOutrightOdd : deleteOutrightOdd;
            if (count === 30 && !isSelected) {
                dispatch(openModal({
                    modalName: MODALS.ADDING_BET_ERROR_MODAL,
                    modalProps: {
                        closeButtonLabel: t('buttons.try_again'),
                        cancelVariant: 'contained',
                        fullWidth: true,
                        hugeButton: true,
                        cancelColor: 'primary',
                        columnDialog: false,
                    },
                }));
            }
            else {
                dispatch(handleFunc({
                    // @ts-expect-error
                    cbid: TOURNAMENT === null || TOURNAMENT === void 0 ? void 0 : TOURNAMENT.cbid,
                    // @ts-expect-error
                    sni: CATEGORY.sni,
                    cbi: item.cbi,
                    // @ts-expect-error
                    ci: CATEGORY.cid,
                    cn: CATEGORY === null || CATEGORY === void 0 ? void 0 : CATEGORY.cn,
                    con: item.cn,
                    lc: i18next.language,
                    // @ts-expect-error
                    mts: item.mts,
                    // @ts-expect-error
                    oi: item.id,
                    fl: CATEGORY.cf,
                    // @ts-expect-error
                    otb: TOURNAMENT === null || TOURNAMENT === void 0 ? void 0 : TOURNAMENT.bid,
                    // @ts-expect-error
                    oti: TOURNAMENT === null || TOURNAMENT === void 0 ? void 0 : TOURNAMENT.id,
                    otn: TOURNAMENT === null || TOURNAMENT === void 0 ? void 0 : TOURNAMENT.on,
                    // @ts-expect-error
                    sbi: CATEGORY === null || CATEGORY === void 0 ? void 0 : CATEGORY.sbid,
                    sd: CATEGORY === null || CATEGORY === void 0 ? void 0 : CATEGORY.sd,
                    // @ts-expect-error
                    si: TOURNAMENT.sid,
                    sn: CATEGORY === null || CATEGORY === void 0 ? void 0 : CATEGORY.sn,
                    tbi: TOURNAMENT.tbid,
                    // @ts-expect-error
                    ti: TOURNAMENT === null || TOURNAMENT === void 0 ? void 0 : TOURNAMENT.tid,
                    tn: CATEGORY === null || CATEGORY === void 0 ? void 0 : CATEGORY.tn,
                    // @ts-expect-error
                    val: item.vl,
                    eventsType: OUTRIGHTS_SOCKET_NAME,
                }));
            }
        };
        return (React.createElement(OddPlate, { oddWidth: isDesktop ? OddWidth.S : OddWidth.M, value: item === null || item === void 0 ? void 0 : item.vl, onClick: onClickOutright, isSelected: isSelected }));
    };
    return (React.createElement(List, __assign({ header: TOURNAMENT === null || TOURNAMENT === void 0 ? void 0 : TOURNAMENT.on, list: outrights, className: classes.list, 
        // @ts-expect-error
        renderValue: renderValue, onBackClick: onBackClick, withBackButton: true }, rest)));
};
