var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
export var name = 'gameTypes';
export var GAMES = {
    DAILY: 'daily',
    WEEKLY: 'weekly',
    GROUP: 'group',
};
export var GAMES_MODE = {
    GLOBAL: 'GLOBAL',
    GROUP: 'GROUP',
};
export var GLOBAL = 'global';
export var RANKINGS = __assign(__assign({}, GAMES), { GLOBAL: GLOBAL });
export var globalStyles = (_a = {},
    _a[GAMES.DAILY] = {
        mainColor: '#00C150',
        startColor: '#104E2A',
        endColor: '#06B56A',
    },
    _a[GAMES.WEEKLY] = {
        mainColor: '#F64845',
        startColor: '#4B0000',
        endColor: '#DE0906',
    },
    _a[GAMES.GROUP] = {
        mainColor: '#2093B2',
        startColor: '#0F1114',
        endColor: '#2093B2',
    },
    _a[RANKINGS.GLOBAL] = {
        mainColor: '#EDAB7B',
        startColor: '#BD5A13',
        endColor: '#EDAB7B',
    },
    _a);
export var GAME_PERIOD;
(function (GAME_PERIOD) {
    GAME_PERIOD["DAILY"] = "DAILY";
    GAME_PERIOD["WEEKLY"] = "WEEKLY";
    GAME_PERIOD["TOURNAMENT"] = "TOURNAMENT";
})(GAME_PERIOD || (GAME_PERIOD = {}));
export var GAME_CLASS;
(function (GAME_CLASS) {
    GAME_CLASS["PRO"] = "PRO";
    GAME_CLASS["EASY_PLUS"] = "EASY_PLUS";
    GAME_CLASS["EASY_TIP"] = "EASY_TIP";
})(GAME_CLASS || (GAME_CLASS = {}));
