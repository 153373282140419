export var languagesList = [
    'en',
    'de',
    'uk',
    'pl',
    'nl',
    'tr',
    'fr',
    'es',
    'ru',
];
export var ENGLISH = 'en';
export var GERMAN = 'de';
