import React from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { push } from 'connected-react-router';
import scrollWatcher from '~utils/scroll';
import { noop } from '~utils/noop';
import { useAppDispatch } from '~store';
import { MATCHDAY_SOCKET_NAME, resetEvents } from '~slices/events';
import { useIsDesktop, useStructuredSelector } from '~hooks';
import { selectIsTournamentActive } from '~slices/router';
import { getPathWithLanguageCode } from '~utils/languageInURL/getPathWithLanguageCode';
import { setSelectedTournament } from "~slices/flags";
import { useStyles } from './TournamentLink.styles';
var TournamentLink = function (_a) {
    var _b = _a.onSelect, onSelect = _b === void 0 ? noop : _b, children = _a.children, tournamentId = _a.tournamentId, tournamentName = _a.tournamentName, _c = _a.toOutrights, toOutrights = _c === void 0 ? false : _c;
    var classes = useStyles();
    var dispatch = useAppDispatch();
    var history = useHistory();
    var isDesktop = useIsDesktop();
    var url = useRouteMatch().url;
    var isSelected = useStructuredSelector({
        isSelected: function (state) {
            return selectIsTournamentActive(state, tournamentName || (tournamentId === null || tournamentId === void 0 ? void 0 : tournamentId.toString()) || '', toOutrights);
        },
    }).isSelected;
    var modifiedName = tournamentName ? encodeURI(tournamentName) : tournamentId;
    var tournamentUrl = "".concat(url, "/").concat(toOutrights ? 'outrights/' : '').concat(modifiedName);
    var handleTournamentSelect = function () {
        dispatch(resetEvents(MATCHDAY_SOCKET_NAME));
        onSelect();
        if (toOutrights) {
            var scrollPosition = isDesktop
                ? scrollWatcher.getScrollTop()
                : document.documentElement.scrollTop;
            dispatch(push({
                pathname: getPathWithLanguageCode(tournamentUrl),
                state: {
                    prevPath: history.location.pathname,
                    scrollPosition: scrollPosition,
                    shouldScrollBack: true,
                },
            }));
            return;
        }
        dispatch(setSelectedTournament({ id: tournamentId, name: modifiedName }));
        dispatch(push(getPathWithLanguageCode(tournamentUrl)));
    };
    var handleClick = function (e) {
        e.preventDefault();
        if (!isSelected) {
            handleTournamentSelect();
        }
    };
    return (React.createElement(Link, { to: getPathWithLanguageCode(tournamentUrl), className: classes.container, onClick: handleClick }, !!children && children(isSelected)));
};
export { TournamentLink };
