import React, { useEffect } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import i18next from 'i18next';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import { matchPath } from "react-router-dom";
import { changeLocalSportId, currentSportIdSelector, optionsDataSelector, selectHeaderSportId, setSportName, } from '~slices/options';
import { availableSportsSelector, getSports, mapSportIdToSportIcon, } from '~slices/sports';
import { useAppDispatch } from '~store';
import { useIsDesktop, useStructuredSelector } from '~hooks';
import { SoccerIcon } from '~icons/sports';
import { getTestId } from '~utils/getTestId';
import { getPathWithLanguageCode } from '~utils/languageInURL/getPathWithLanguageCode';
import { setSelectedFlag } from "~slices/flags";
import { isAuthenticatedSelector } from "~slices/auth";
import { useSportListStyles } from './SportList.styles';
var SportItem = withStyles({
    root: {
        height: '40px',
    },
    selected: {
        background: 'radial-gradient(100% 100% at 50.13% 0%, #3C3C46 0%, #1B1B22 100%)',
        border: '1px solid #4E4E5A',
        boxSizing: 'border-box',
        borderRadius: '4px',
    },
})(ListItem);
export var SportList = React.memo(function SportList() {
    var classes = useSportListStyles();
    var dispatch = useAppDispatch();
    var t = useTranslation('ticket').t;
    var isDesktop = useIsDesktop();
    var _a = useStructuredSelector({
        isAuthenticated: isAuthenticatedSelector,
        userSportId: selectHeaderSportId,
        currentSportId: currentSportIdSelector,
        optionsData: optionsDataSelector,
        sports: availableSportsSelector,
    }), isAuthenticated = _a.isAuthenticated, userSportId = _a.userSportId, currentSportId = _a.currentSportId, wasLoadedOptions = _a.optionsData.wasLoadedOptions, sports = _a.sports;
    var userSport = sports.find(function (_a) {
        var id = _a.id;
        return id === userSportId;
    });
    var SportIcon = mapSportIdToSportIcon[Number(userSport === null || userSport === void 0 ? void 0 : userSport.betradarId)] || SoccerIcon;
    useEffect(function () {
        if (i18next.language) {
            dispatch(getSports({ language: i18next.language }));
        }
    }, [i18next.language]);
    useEffect(function () {
        var _a;
        var path = '/:lng/home/:sportName/:flagName/:tournamentName';
        var match = matchPath(window.location.pathname, { path: path });
        var sportName = match === null || match === void 0 ? void 0 : match.params.sportName;
        if (sports && !userSportId && !currentSportId && !sportName && (!isAuthenticated || isAuthenticated && wasLoadedOptions)) {
            dispatchLocalSportId(((_a = sports[0]) === null || _a === void 0 ? void 0 : _a.id) || 1);
        }
    }, [isAuthenticated]);
    var dispatchLocalSportId = function (id) {
        dispatch(changeLocalSportId({
            id: id,
            desktop: isDesktop,
            langIso: i18next.language,
        }));
    };
    var setGameNameInURL = function (id) {
        var selectedSport = sports.find(function (game) { return game.id === id; });
        var name = (selectedSport === null || selectedSport === void 0 ? void 0 : selectedSport.name) || '';
        dispatch(setSportName({ id: id, name: name }));
        dispatch(push(getPathWithLanguageCode("/home/all/live/".concat(name))));
    };
    var changeSport = function (id) { return function () {
        setGameNameInURL(id);
        dispatchLocalSportId(id);
        dispatch(setSelectedFlag(undefined));
    }; };
    var renderSport = function (_a) {
        var id = _a.id, name = _a.name, betradarId = _a.betradarId;
        var Icon = (betradarId && mapSportIdToSportIcon[betradarId]) || SoccerIcon;
        // @ts-expect-error
        var onClick = changeSport(id);
        return (React.createElement(SportItem, { onClick: onClick, button: true, selected: currentSportId === id, key: id, "data-testid": getTestId("BTN_SPORTS-".concat(id)) },
            React.createElement(ListItemIcon, { classes: { root: classes.sportIcon } },
                React.createElement(Icon, { className: classes.sportIcon })),
            React.createElement(ListItemText, { className: classes.sportLabel, primary: name })));
    };
    return (React.createElement("div", { className: classes.flexColumn },
        React.createElement("div", { className: classNames(classes.flexColumn, classes.favouriteBlock) },
            React.createElement("span", null, t('favourite_sports')),
            React.createElement("div", { role: "button", tabIndex: 0, className: classNames(classes.favouriteSport, classes.flexColumn), onClick: changeSport(userSportId), "data-testid": getTestId("BTN_SPORTS_FAVOURITE") },
                React.createElement(SportIcon, null),
                React.createElement("span", { className: classes.sportLabel }, userSport === null || userSport === void 0 ? void 0 : userSport.name))),
        React.createElement(List, { component: "nav" }, sports.slice(0, 5).map(renderSport)),
        React.createElement("span", { className: classes.favouriteBlock }, t('all_sports')),
        React.createElement("div", { className: classes.scrollableList },
            React.createElement(AutoSizer, null, function (_a) {
                var height = _a.height, width = _a.width;
                return (React.createElement(List, { component: "nav", style: { height: height, width: width } }, sports.slice(5).map(renderSport)));
            }))));
});
