import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var RinkHockeyIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, viewBox: "0 0 28 28" },
        React.createElement("svg", { width: "28", height: "28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M15.6 20.4v-.1l.8-2.6c.2-.5 0-1-.4-1.5l-.2-.2-6-6v-.1h-.1c-.4.5-.8 1.1-1 1.7-.2.4-.3.8-.2 1.2l.7.9a95.6 95.6 0 0 0 4.8 4.6l-.9 3.2-.2 1h4.5l.3-.2c.2 0 .3-.3.1-.6l-.3-.3-.4-.2-1.5-.8Z", fill: "#fff" }),
            React.createElement("path", { d: "M2.8 16.7v4.5c.1.3.3.4.6.3l.4-.5L5.1 19l.1-.1H7.6l2-3.6.1-.1h-.1l-.8-.7c-.3-.3-.5-.5-.6-.9l-.1.2-1.9 3c0 .2 0 .2-.2.2l-2-.1-1.2-.1ZM22 4.8a1.9 1.9 0 1 0 0 3.8 1.9 1.9 0 0 0 0-3.8ZM1 16.8c0 .3.3.6.6.6s.6-.3.6-.6c0-.4-.3-.6-.6-.6s-.6.2-.6.6ZM1 19.9c0 .3.3.6.6.6a.6.6 0 0 0 0-1.3c-.3 0-.6.3-.6.7ZM14 23.7c0 .3.2.6.6.6.3 0 .6-.3.6-.6s-.3-.6-.7-.6c-.3 0-.6.3-.6.6ZM17 23.7c0 .3.3.6.6.6.4 0 .6-.3.6-.6s-.3-.6-.6-.6-.6.3-.6.6ZM16 24.3c.4 0 .7-.3.7-.6s-.3-.6-.6-.6c-.4 0-.6.3-.6.6s.3.6.6.6ZM13 23c-.3 0-.6.4-.6.7 0 .3.3.6.6.6.4 0 .6-.3.6-.6s-.2-.6-.6-.6ZM1 21.4c0 .3.3.6.6.6s.6-.3.6-.6-.2-.6-.6-.6c-.3 0-.6.2-.6.6ZM1 18.3c0 .4.3.6.6.6s.6-.2.6-.6c0-.3-.2-.6-.6-.6-.3 0-.6.3-.6.6Z", fill: "#fff" }),
            React.createElement("path", { d: "M9.9 9.4a124.3 124.3 0 0 1 4.6-6.2c.2-.2.4-.2.7-.2l.7.5 3 3.6 1.6 2v7.3c0 .5-.2 1-.7 1a1 1 0 0 1-1.3-.9V11.7H13l10 10 .2.1h3.3c.4 0 .6.3.5.7 0 .1-.3.3-.5.3h-3.3c-.3 0-.5-.2-.5-.5V22h-.1L11 10.3l-1-.9h-.1ZM13.4 8h3.7l-1.8-2.5L13.4 8Z", fill: "#DDB95D" }),
            React.createElement("path", { d: "m15.6 20.4 1.5.8.4.2.3.3c.2.3.1.5-.1.6l-.3.1h-4.5l.2-.9a775.5 775.5 0 0 1 .9-3.4 95.8 95.8 0 0 1-5.5-5.3l.1-1.2 1-1.7h.2l6 6.1.2.2c.5.5.6 1 .4 1.5l-.8 2.6v.1Z", fill: "#78B9EB" }),
            React.createElement("path", { d: "M2.8 16.7a83.3 83.3 0 0 1 3.4 0l1.9-3v-.2l.7.9.8.6.1.1v.1l-2.1 3.6H5L3.8 21c0 .2-.2.4-.4.5-.3.1-.5 0-.6-.3v-4.5Z", fill: "#78B9EB" }),
            React.createElement("path", { d: "M22 4.8a1.9 1.9 0 1 1 0 3.8 1.9 1.9 0 0 1 0-3.8Z", fill: "#FEDEBB" }),
            React.createElement("path", { d: "M1 16.8c0-.4.3-.6.6-.6.4 0 .6.2.6.6 0 .3-.3.6-.6.6a.6.6 0 0 1-.6-.6ZM1 19.9c0-.4.3-.7.6-.7.4 0 .6.3.6.7 0 .3-.3.6-.6.6a.6.6 0 0 1-.6-.6ZM14 23.7c0-.3.2-.6.5-.6.4 0 .7.3.7.6s-.3.6-.6.6a.6.6 0 0 1-.7-.6ZM17 23.7c0-.3.3-.6.6-.6s.6.3.6.6-.2.6-.6.6a.6.6 0 0 1-.6-.6ZM16 24.3a.6.6 0 0 1-.5-.6c0-.3.2-.6.6-.6.3 0 .6.3.6.6s-.3.6-.6.6ZM13 23c.4 0 .6.4.6.7 0 .3-.2.6-.6.6a.6.6 0 0 1-.6-.6c0-.3.3-.6.6-.6ZM1 21.4c0-.3.3-.6.6-.6.4 0 .6.3.6.6s-.3.6-.6.6a.6.6 0 0 1-.6-.6ZM1 18.3c0-.3.3-.6.6-.6.4 0 .6.3.6.6 0 .4-.3.6-.6.6a.6.6 0 0 1-.6-.6Z", fill: "#BFBFBF" }))));
};
