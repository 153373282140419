var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useRef, useState } from 'react';
import { GlobalRankingListItem } from "~atoms/RankingListCartoonItem";
import { useStyles } from "~atoms/RankingList";
import { getGlobalRankingDetails } from "~slices/ranking";
import { useAppDispatch } from "~store";
export var GlobalRankingList = function (_a) {
    var _b, _c, _d;
    var rankingListData = _a.rankingListData, isLastPage = _a.isLastPage, userName = _a.userName;
    var classes = useStyles();
    var dispatch = useAppDispatch();
    var _e = useState(null), openedLevel = _e[0], setOpenedLevel = _e[1];
    var _f = useState(null), trendBalance = _f[0], setTrendBalance = _f[1];
    var _g = useState(false), isRankingListDetailsLoading = _g[0], setIsRankingListDetailsLoading = _g[1];
    var _h = useState(false), isMyRankDisplay = _h[0], setIsMyRankDisplay = _h[1];
    var _j = useState(0), pageNumber = _j[0], setPageNumber = _j[1];
    var _k = useState([]), playersList = _k[0], setPlayersList = _k[1];
    var pageSize = 10;
    var myRankPage = useRef(null);
    var getTrendBalance = function () {
        var _a, _b;
        var mySelfLevel = (_a = rankingListData === null || rankingListData === void 0 ? void 0 : rankingListData.myself) === null || _a === void 0 ? void 0 : _a.level;
        var mySelfBalance = (_b = rankingListData === null || rankingListData === void 0 ? void 0 : rankingListData.myself) === null || _b === void 0 ? void 0 : _b.balance;
        if (!mySelfLevel || !mySelfBalance) {
            setTrendBalance(null);
            return;
        }
        var nextLevel = rankingListData === null || rankingListData === void 0 ? void 0 : rankingListData.levels.find(function (item) { return (item === null || item === void 0 ? void 0 : item.level) === mySelfLevel + 1; });
        (nextLevel === null || nextLevel === void 0 ? void 0 : nextLevel.minBalance) ? setTrendBalance(nextLevel.minBalance - mySelfBalance) : setTrendBalance(null);
    };
    useEffect(function () {
        getTrendBalance();
    }, [(_b = rankingListData === null || rankingListData === void 0 ? void 0 : rankingListData.myself) === null || _b === void 0 ? void 0 : _b.level, (_c = rankingListData === null || rankingListData === void 0 ? void 0 : rankingListData.myself) === null || _c === void 0 ? void 0 : _c.balance]);
    var checkIfMine = function (level) {
        var levelItem = rankingListData.levels.find(function (item) { return item.level === level; });
        return levelItem === null || levelItem === void 0 ? void 0 : levelItem.mine;
    };
    var onItemClick = function (level) {
        setOpenedLevel(level);
        setPlayersList([]);
        var pageNumber = 0;
        setIsMyRankDisplay(false);
        myRankPage.current = null;
        if (!level)
            return;
        if (checkIfMine(level)) {
            var previousRanksCount_1 = 0;
            rankingListData.levels.forEach(function (levelItem) {
                if (levelItem.level > level) {
                    previousRanksCount_1 = previousRanksCount_1 + levelItem.playersCount;
                }
                else {
                    return;
                }
            });
            myRankPage.current = rankingListData.myself.rank ? Math.floor((rankingListData.myself.rank - previousRanksCount_1) / pageSize) : 0;
            myRankPage.current > pageNumber ? setIsMyRankDisplay(true) : null;
        }
        setPageNumber(pageNumber);
        setIsRankingListDetailsLoading(true);
        dispatch(getGlobalRankingDetails({ level: level, pageSize: pageSize, page: pageNumber }))
            .then(function (_a) {
            var payload = _a.payload;
            var rankedList = payload === null || payload === void 0 ? void 0 : payload.content;
            setPlayersList(function (prevState) { return __spreadArray(__spreadArray([], prevState, true), rankedList, true); });
        })
            .finally(function () { return setIsRankingListDetailsLoading(false); });
    };
    var getPlayersListData = function (page) {
        setIsRankingListDetailsLoading(true);
        dispatch(getGlobalRankingDetails({ level: Number(openedLevel), pageSize: pageSize, page: page }))
            .then(function (_a) {
            var payload = _a.payload;
            var rankedList = payload === null || payload === void 0 ? void 0 : payload.content;
            setPlayersList(function (prevState) { return __spreadArray(__spreadArray([], prevState, true), rankedList, true); });
            if (myRankPage.current !== null) {
                setIsMyRankDisplay(myRankPage.current > page);
            }
        })
            .finally(function () { return setIsRankingListDetailsLoading(false); });
    };
    var handleScroll = function (containerRef) {
        if (!openedLevel)
            return;
        var getPlayersData = function (pageNumber) {
            setPageNumber(pageNumber);
            getPlayersListData(pageNumber);
        };
        var container = containerRef.current;
        if (container &&
            container.scrollTop + container.clientHeight + 150 >=
                container.scrollHeight &&
            !isLastPage &&
            !isRankingListDetailsLoading) {
            getPlayersData(pageNumber + 1);
        }
    };
    return (React.createElement(React.Fragment, null, (_d = rankingListData === null || rankingListData === void 0 ? void 0 : rankingListData.levels) === null || _d === void 0 ? void 0 : _d.map(function (item, index) {
        return (React.createElement("div", { className: classes.rankingListWrapper, key: index },
            React.createElement(GlobalRankingListItem, { key: index, itemIndex: index, level: item.level, topRankedPlayers: item.rankedPlayers, playersCount: item.playersCount, rankedPlayersList: playersList, maxBalance: item.maxBalance, minBalance: item.minBalance, isCurrentUser: item.mine, isRankingListDetailsLoading: isRankingListDetailsLoading, isMyRankDisplay: isMyRankDisplay, myselfData: rankingListData === null || rankingListData === void 0 ? void 0 : rankingListData.myself, userName: userName, avatar: item.name || 'Joker', avatarUrl: item.avatarUrl || 'avatars/joker.svg', openedLevel: openedLevel, trendBalance: trendBalance, onItemListClick: onItemClick, onNestedListScroll: handleScroll })));
    })));
};
