import React from 'react';
import classNames from 'classnames';
import { Option } from './Option';
import { useStyles } from './OptionsSelection.styles';
import { scrollParentAtom } from './scrollParentAtom';
var noop = function () { return undefined; };
var defaultSelectionStrategy = function (triggeredId) {
    return [triggeredId];
};
var OptionsSelection = function (_a) {
    var _b;
    var options = _a.options, selected = _a.selected, _c = _a.palette, palette = _c === void 0 ? 'dark' : _c, _d = _a.selectionStrategy, selectionStrategy = _d === void 0 ? defaultSelectionStrategy : _d, _e = _a.onChange, onChange = _e === void 0 ? noop : _e;
    var classes = useStyles();
    var handleOptionClick = function (id) {
        return onChange(selectionStrategy(id, selected));
    };
    return (React.createElement("div", { ref: function (e) {
            scrollParentAtom.setValue(e);
        }, className: classNames(classes.container, (_b = {},
            _b[classes.lightContainer] = palette === 'light',
            _b)) }, options.map(function (_a) {
        var id = _a.id, value = _a.value;
        return (React.createElement(Option, { key: id, active: selected.includes(id), palette: palette, onClick: function () { return handleOptionClick(id); } }, value));
    })));
};
export { OptionsSelection };
