import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var SpeedSkatingIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("svg", { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M24.0799 12.7281C24.0812 13.4081 23.6939 14.0291 23.0827 14.3271C22.4716 14.6251 21.7437 14.5477 21.2088 14.1282L19.7329 12.9748L19.6618 12.9227L19.2773 13.4401C19.2204 13.5192 19.1741 13.6052 19.1397 13.6962C19.045 13.9744 19.0835 14.2807 19.2441 14.5268L21.6502 18.2095C22.6179 19.6903 22.9031 21.5155 22.4333 23.2211L21.8875 25.2142C21.5795 25.9221 20.7634 26.2558 20.0478 25.9661C19.3322 25.6764 18.9778 24.8694 19.2488 24.1465L19.5099 23.3018C19.9318 21.9474 19.7282 20.4758 18.9547 19.2868L15.6563 14.2421C15.5861 14.1346 15.4803 14.0556 15.3573 14.019H15.3526C15.1622 13.9643 14.9574 14.0188 14.8196 14.1609C14.6816 14.3027 14.6329 14.5091 14.6929 14.6977L15.9885 18.7363C16.5158 20.3715 16.1163 22.1638 14.9443 23.4204L13.2407 25.2522C12.9942 25.5551 12.634 25.7433 12.2446 25.773C11.8551 25.8027 11.4706 25.6709 11.1809 25.4088C10.6206 24.8683 10.577 23.9852 11.0814 23.392L12.7376 21.3512C13.2396 20.7349 13.3984 19.9076 13.1599 19.1492L11.8122 14.8259C11.4189 13.557 11.6364 12.1774 12.4007 11.0909L14.5506 8.04399C14.8788 7.58321 14.901 6.97118 14.6075 6.48754L13.3118 4.35195C13.243 4.23894 13.1305 4.15969 13.001 4.13256C12.8716 4.10564 12.7368 4.13341 12.6285 4.20946L9.05497 6.75328C8.73752 6.98101 8.34018 7.06795 7.95673 6.9934C7.57328 6.91884 7.23746 6.68941 7.02854 6.35936C6.63633 5.68859 6.82902 4.82897 7.46988 4.38976L11.1809 1.74639C11.4373 1.56609 11.703 1.39049 11.9735 1.22921C12.3717 0.776538 12.9673 0.54903 13.5658 0.621021C14.1644 0.693225 14.6888 1.05553 14.9683 1.5898L15.7369 2.86171C16.492 4.10628 17.4779 5.19512 18.6413 6.0699L20.8149 7.69771C21.97 8.5631 22.886 9.7079 23.4773 11.0245L23.9233 12.002C24.0255 12.2306 24.0789 12.478 24.0799 12.7281Z", fill: "#F6AB27" }),
            React.createElement("path", { d: "M20.7342 10.7348C20.5705 10.9394 20.273 10.9753 20.0651 10.8153L18.7364 9.77602C18.5396 9.61965 18.288 9.54937 18.0387 9.58141C17.6563 9.62584 17.3388 9.89736 17.2357 10.2682C17.1327 10.6391 17.2643 11.0355 17.5689 11.271L19.6712 12.9131C19.6951 12.93 19.716 12.9507 19.7329 12.9746L19.6618 12.9225L19.2773 13.4399C19.2204 13.5189 19.1741 13.605 19.1397 13.696C19.1198 13.6868 19.1006 13.6757 19.0826 13.6629L16.9804 12.0161C16.1656 11.3667 16.0266 10.1821 16.6685 9.36159C17.3106 8.54128 18.4939 8.39153 19.32 9.0262L20.6489 10.0655C20.7495 10.1426 20.8151 10.2567 20.8312 10.3823C20.8472 10.5079 20.8124 10.6348 20.7342 10.7348Z", fill: "#EC9130" }),
            React.createElement("path", { d: "M9.76204 27.9999C9.57768 27.9999 9.4102 27.8933 9.33202 27.7265C9.25383 27.5596 9.27946 27.3627 9.3976 27.221L11.0286 25.2645C11.1339 25.1235 11.3085 25.0523 11.4821 25.0795C11.656 25.1066 11.8004 25.2275 11.8577 25.3939C11.9149 25.5601 11.8756 25.7445 11.7554 25.8729L10.1246 27.8294C10.0348 27.9371 9.90218 27.9995 9.76204 27.9999Z", fill: "#A5B4B4" }),
            React.createElement("path", { d: "M19.7283 28C19.6894 28 19.6506 27.995 19.613 27.9856C19.3587 27.9222 19.2041 27.6648 19.2675 27.4106L19.6429 25.9099C19.711 25.6612 19.9652 25.5126 20.2154 25.5749C20.4655 25.6373 20.6202 25.8881 20.5636 26.1395L20.188 27.6402C20.1355 27.8515 19.946 27.9997 19.7283 28Z", fill: "#A5B4B4" }),
            React.createElement("path", { d: "M24.9485 3.32205C24.9485 5.15665 23.4613 6.6441 21.6265 6.6441C19.7917 6.6441 18.3044 5.15665 18.3044 3.32205C18.3044 1.48724 19.7917 0 21.6265 0C23.4613 0 24.9485 1.48724 24.9485 3.32205Z", fill: "#F6DB6D" }),
            React.createElement("path", { d: "M23.126 0.360596L21.7212 2.06146C21.4589 2.37891 21.3467 2.7944 21.4138 3.20071C21.4809 3.60703 21.7206 3.96442 22.0711 4.1806L24.1591 5.46939C24.8179 4.69479 25.0847 3.66086 24.8832 2.66409C24.6816 1.66754 24.0339 0.81839 23.126 0.360596Z", fill: "#586270" }),
            React.createElement("path", { d: "M6.91491 10.4407C6.83159 10.4414 6.74978 10.4198 6.67757 10.3781L3.35552 8.47986C3.12801 8.3487 3.04983 8.05817 3.18078 7.83066C3.31194 7.60316 3.60268 7.52497 3.83019 7.65592L7.15203 9.55417C7.33916 9.6612 7.43059 9.8808 7.37484 10.0889C7.3193 10.2969 7.13024 10.4416 6.91491 10.4407Z", fill: "#95C2D5" }),
            React.createElement("path", { d: "M7.38916 12.8135C7.30585 12.8142 7.22403 12.7924 7.15183 12.7509L3.82999 10.8527C3.6828 10.7679 3.59201 10.6111 3.5918 10.441C3.59158 10.2712 3.68216 10.1142 3.82913 10.0292C3.97611 9.94415 4.15726 9.94394 4.30444 10.0287L7.6265 11.927C7.81342 12.034 7.90506 12.2536 7.8493 12.4617C7.79355 12.6698 7.60471 12.8144 7.38916 12.8135Z", fill: "#95C2D5" }),
            React.createElement("path", { d: "M19.2475 24.1445C18.9527 24.8734 19.3043 25.7031 20.033 25.9981C20.7616 26.2931 21.5915 25.9415 21.8866 25.2129L22.1192 24.3676L19.4549 23.4797L19.2475 24.1445Z", fill: "#394F63" }),
            React.createElement("path", { d: "M11.0805 23.3966C10.5787 23.9899 10.6219 24.8704 11.1795 25.4117C11.4691 25.6741 11.8541 25.8059 12.2439 25.776C12.6338 25.7461 12.994 25.5572 13.2405 25.2537L13.8602 24.5893L11.6695 22.672L11.0805 23.3966Z", fill: "#394F63" }))));
};
