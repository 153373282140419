// TODO: fix max lines issue
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable max-lines */
import makeStyles from '@material-ui/core/styles/makeStyles';
export var useEventRowStyles = makeStyles(function (theme) {
    var _a, _b, _c, _d, _e, _f;
    return ({
        liveLabel: {
            fontSize: '8px',
        },
        testData: {
            color: 'white',
            background: '#161616',
            padding: '5px',
            border: '1px solid #606060',
        },
        field: {
            fontWeight: 'bold',
            color: '#2aa1f6',
        },
        value: {
            color: '#ed9b4f',
            borderRight: '4px solid #2bd32b',
            paddingRight: '4px',
            marginRight: '4px',
        },
        '@keyframes move': {
            '0%': {
                backgroundPosition: '0 0',
            },
            '100%': {
                backgroundPosition: '50px 50px',
            },
        },
        flexRow: {
            display: 'flex',
            flexDirection: 'row',
        },
        alignCenter: {
            alignItems: 'center',
        },
        competeWrap: {
            position: 'relative',
        },
        leagueName: {
            display: 'flex',
            paddingLeft: '8px',
            height: '15px',
            alignItems: 'center',
            position: 'relative',
            '&:before': {
                content: '""',
                position: 'absolute',
                left: 0,
                top: 0,
                display: 'block',
                height: '15px',
                borderLeft: '1px solid rgba(142, 142, 142, 0.2);',
            },
        },
        leagueNameText: {
            fontSize: '10px',
            lineHeight: '12px',
            color: '#ffffff',
            marginLeft: '8px',
        },
        marginLeftAuto: {
            marginLeft: 'auto',
        },
        rightColumn: {
            marginLeft: 'auto',
            display: 'flex',
        },
        countryFlag: {
            maxHeight: '12px',
            marginRight: '8px',
        },
        competitor: (_a = {
                fontSize: '12px',
                lineHeight: '18px',
                color: '#ffffff',
                display: 'flex',
                alignItems: 'center',
                fontWeight: 'normal'
            },
            _a[theme.breakpoints.up('lg')] = {
                fontSize: '14px',
                lineHeight: '16px',
            },
            _a),
        card: {
            width: '11px',
            height: '15px',
            fontWeight: 'bold',
            fontSize: '8px',
            lineHeight: '9px',
            borderRadius: '1px',
            marginRight: '4px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        yellowCard: {
            color: '#212121',
            background: '#FFEB3B',
        },
        redCard: {
            color: '#ffffff',
            background: '#FF0000',
        },
        competitorResult: (_b = {
                fontSize: '12px',
                width: '12px',
                maxWidth: '12px',
                minWidth: '12px',
                boxSizing: 'border-box',
                lineHeight: '14px',
                paddingLeft: '3px',
                textAlign: 'center',
                color: '#f2f2f2'
            },
            _b[theme.breakpoints.up('lg')] = {
                fontSize: '14px',
                lineHeight: '22px',
            },
            _b),
        hugeCompetitorResult: {
            width: '18px',
            minWidth: '18px',
            maxWidth: '18px',
            boxSizing: 'border-box',
        },
        '@keyframes scoreBlinking': {
            '25%': {
                opacity: 0.5,
            },
            '50%': {
                opacity: 0,
            },
            '75%': {
                opacity: 0.5,
            },
        },
        competitorGoal: {
            animation: '$scoreBlinking 1s linear infinite',
        },
        tennisResult: {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '8px',
            lineHeight: '9px',
        },
        hugeScoreResult: {
            width: '15px',
            minWidth: '15px',
            maxWidth: '15px',
            color: '#8E8E8E',
        },
        smallScoreResult: {
            width: '7px',
        },
        pastHalf: {
            color: '#8E8E8E',
        },
        favouriteBtn: (_c = {
                cursor: 'pointer',
                color: 'inherit',
                padding: '2px 8px 6px 16px',
                margin: '0 0 -7px -16px',
                border: 0,
                background: 'transparent',
                '&:focus': {
                    outline: 'none',
                }
            },
            _c[theme.breakpoints.up('lg')] = {
                padding: '2px 8px 8px 16px',
                margin: '0 0 -7px -16px',
            },
            _c),
        noSr: {
            width: '3px',
            height: '3px',
            borderRadius: '50%',
            marginLeft: '4px',
            marginRight: '8px',
        },
        ti: {
            position: 'absolute',
            right: '-10px',
            width: '6px',
            height: '6px',
            borderRadius: '50%',
            backgroundColor: '#F9DC1C',
            visibility: 'hidden',
            '&.isDesktop': {
                right: '-12px',
            },
            '&.visible': {
                visibility: 'visible',
            },
        },
        sr: {
            backgroundColor: '#F9DC1C',
        },
        star: {
            fontWeight: 900,
            fontSize: '12px',
            lineHeight: '15px',
        },
        starActive: {
            color: '#FFEB3B',
        },
        octagon: {
            fontSize: '12px',
            lineHeight: '15px',
            color: 'red',
        },
        time: {
            height: '18px',
            fontSize: '14px',
            lineHeight: '18px',
            marginLeft: '6px',
        },
        blue: {
            color: '#2AA1F7',
        },
        sets: {
            height: '18px',
            fontSize: '14px',
            lineHeight: '18px',
            color: '#2AA1F7',
            marginLeft: '6px',
        },
        root: (_d = {
                background: 'linear-gradient(180deg, #151515 0%, #323232 100%)',
                border: '1px solid #3E3E3E',
                borderRadius: '4px',
                minWidth: '101px',
                height: '48px',
                boxSizing: 'border-box',
                '& i': {
                    fontSize: '12px',
                    color: '#FFFFFF',
                }
            },
            _d[theme.breakpoints.up('lg')] = {
                width: '100%',
                '& i': {
                    color: '#8e8e8e !important',
                },
            },
            _d),
        label: (_e = {
                fontWeight: 500,
                fontSize: '12px',
                lineHeight: '18px',
                color: '#8E8E8E',
                display: 'block',
                width: 'auto',
                margin: '0 auto 0 42.5%'
            },
            _e[theme.breakpoints.down('md')] = {
                display: 'flex',
                marginLeft: '10%',
            },
            _e),
        marketsGroup: (_f = {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                width: 176,
                '&:not(:last-child)': {
                    marginRight: 20,
                },
                '&:first-child': {
                    width: 152,
                    '& $marketSpecifiers': {
                        display: 'none',
                    },
                }
            },
            _f[theme.breakpoints.up('lg')] = {
                '&:first-child': {
                    width: 140,
                },
            },
            _f),
        fullWidthMarketsGroup: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            columnGap: 8,
            width: '100%',
            minHeight: 74,
            padding: '10px 0 10px 16px',
            boxSizing: 'border-box',
        },
        marketSpecifiers: {
            fontSize: 14,
            lineHeight: '16px',
            marginRight: 8,
            width: '20px',
            maxWidth: '20px',
            minWidth: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
        },
        '@keyframes goalPaneMoving': {
            '6%': {
                transform: 'scaleX(1)',
            },
            '60%': {
                transform: 'scaleX(1)',
            },
            '66%': {
                transform: 'scaleX(0)',
            },
        },
        '@keyframes nogoalPaneMoving': {
            '6%': {
                transform: 'scaleX(1)',
            },
            '60%': {
                transform: 'scaleX(1)',
            },
            '66%': {
                transform: 'scaleX(0)',
            },
        },
        '@keyframes nocardPaneMoving': {
            '5%': {
                transform: 'scaleX(1)',
            },
            '60%': {
                transform: 'scaleX(1)',
            },
            '95%': {
                transform: 'scaleX(1)',
            },
            '100%': {
                transform: 'scaleX(0)',
            },
        },
        goalPane: {
            position: 'absolute',
            width: 48,
            bottom: 10,
            right: 0,
            height: 14,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#2AA1F7',
            borderRadius: 2,
            animation: "$goalPaneMoving ".concat(process.env.GOAL_ANIMATION_FLASH_DURATION, "s linear ").concat(process.env.GOAL_ANIMATION_FLASHES_COUNT),
            transform: 'scaleX(0)',
            transformOrigin: 'right',
            zIndex: 10,
        },
        bsr: {
            position: 'absolute',
            width: 48,
            bottom: 10,
            right: 0,
            height: 14,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#FFFFFF',
            color: '#212121',
            borderRadius: 2,
            '&.animation': {
                animation: '$goalPaneMoving 3s linear 1',
                transform: 'scaleX(0)',
                transformOrigin: 'right',
            },
        },
        right16: {
            right: 16,
        },
        nogoal: {
            animation: '$nogoalPaneMoving 5s linear 1',
            transform: 'scaleX(0)',
            transformOrigin: 'right',
        },
        nocard: {
            animation: '$nocardPaneMoving 5s linear 1',
            transform: 'scaleX(0)',
            transformOrigin: 'right',
        },
        bsrText: {
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontSize: '8px',
            lineHeight: '9px',
            textAlign: 'center',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            padding: '0 3px',
            '&.animation': {
                textTransform: 'uppercase',
                transform: 'scale(0)',
                animation: '$textPaneBlinking 3s linear 3',
            },
        },
        '@keyframes textPaneBlinking': {
            '16%': {
                transform: 'scale(1)',
            },
            '26%': {
                transform: 'scale(1)',
            },
            '36%': {
                transform: 'scale(0.72)',
            },
            '46%': {
                transform: 'scale(1)',
            },
            '56%': {
                transform: 'scale(1)',
            },
            '66%': {
                transform: 'scale(0)',
            },
        },
        goalPaneText: {
            fontSize: 8,
            lineHeight: 1,
            letterSpacing: '0.4em',
            textTransform: 'uppercase',
            transform: 'scale(0)',
            animation: "$textPaneBlinking ".concat(process.env.GOAL_ANIMATION_FLASH_DURATION, "s linear 3"),
        },
        smallSizeCompetitorResult: {
            width: '12px',
            minWidth: '12px',
            maxWidth: '12px',
            boxSizing: 'border-box',
            textAlign: 'right',
        },
        meduimSizeCompetitorResult: {
            width: '20px',
            minWidth: '20px',
            maxWidth: '20px',
            boxSizing: 'border-box',
            textAlign: 'right',
        },
    });
}, { name: 'EventRow' });
export var useLeagueStyles = makeStyles(function (theme) {
    var _a;
    return (_a = {
            liveStandings: {
                boxSizing: 'border-box',
                height: '44px',
                background: '#151515',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                lineHeight: '14px',
                letterSpacing: '0.05em',
                color: '#FFFFFF',
                cursor: 'pointer',
                outline: 'none',
                margin: '16px',
                backgroundColor: '#1b1c22',
                border: '1px solid #F2F2F2',
                borderRadius: '4px',
                fontWeight: 'bold',
                fontSize: '16px',
                userSelect: 'none',
            },
            progressBarContainer: {
                position: 'absolute',
                bottom: 0,
                width: '100vw',
                zIndex: 2,
            },
            frame: {
                '@media (max-width:341px)': {
                    marginTop: '-47px',
                },
                '@media (min-width: 342px) and (max-width:772px)': {
                    marginTop: '-55px',
                },
                '@media (min-width: 773px) and (max-width:1366px)': {
                    marginTop: '-53px',
                },
                '@media (min-width: 1367px) and (max-width:1620px)': {
                    marginTop: '-55px',
                },
                '@media (min-width: 1621px)': {
                    marginTop: '-53px',
                },
            },
            hide: {
                display: 'none',
            }
        },
        _a[theme.breakpoints.up('lg')] = {
            stickyZone: {
                position: 'sticky',
                top: '120px',
                zIndex: 1,
                '&.sidebets': {
                    top: 0,
                },
            },
        },
        _a);
}, { name: 'League' });
