import React, { useEffect, useRef, useState } from 'react';
import i18next from 'i18next';
import { push } from 'connected-react-router';
import { unwrapResult } from '@reduxjs/toolkit';
import { request } from '~utils/request';
import { resetProviderId, templateInfoSelector } from '~slices/top-ranking';
import { getBp, startPolling, useResponseObjectSelector } from '~slices/bp';
import { isAuthenticatedSelector } from '~slices/auth';
import { useAppDispatch } from '~store';
import { useStructuredSelector } from '~hooks';
import { getCashOut } from '~slices/cashOut';
import { deleteFullBetslip } from '~store/slices/betslip';
import { setIsMobileTopRankingHidden } from '~slices/view';
import { getTestId } from '~utils/getTestId';
import { getPathWithLanguageCode } from '~utils/languageInURL/getPathWithLanguageCode';
import { BpHeader } from './BpHeader';
export var BPRegistration = function () {
    var dispatch = useAppDispatch();
    var _a = useState(''), formAction = _a[0], setFormAction = _a[1];
    var _b = useState(''), inputValue = _b[0], setInputValue = _b[1];
    var submit = useRef(null);
    var iframeRef = useRef(null);
    var providers = useResponseObjectSelector();
    var _c = useStructuredSelector({
        isAuthenticated: isAuthenticatedSelector,
        templateInfo: templateInfoSelector,
    }), isAuthenticated = _c.isAuthenticated, _d = _c.templateInfo, requestId = _d.requestId, template = _d.template, providerId = _d.providerId, ticketId = _d.ticketId, linkType = _d.linkType;
    var currentProvider = providers === null || providers === void 0 ? void 0 : providers.find(function (item) { return item.id === providerId; });
    var restartPolling = function () {
        if (isAuthenticated) {
            dispatch(getBp(true))
                .then(unwrapResult)
                .then(function (res) {
                if (res.requestId) {
                    dispatch(startPolling(res.requestId));
                }
            });
        }
    };
    var getBPLoginTemplateLink = function () {
        return request({
            url: "bp/v1/bp-integrator/template/".concat(template),
            params: { providerId: providerId, ticketId: ticketId, requestId: requestId, linkType: linkType },
        })
            .then(function (result) {
            setFormAction(result.data.templateUrl);
            setInputValue(result.data.jwtToken);
        })
            // TODO: add error handler
            .catch();
    };
    useEffect(function () {
        if (providerId && (ticketId || template || linkType)) {
            getBPLoginTemplateLink();
        }
    }, [providerId, template, ticketId, linkType]);
    var handleSubmitAndSubscribe = function () {
        var _a;
        if (formAction && inputValue) {
            (_a = submit === null || submit === void 0 ? void 0 : submit.current) === null || _a === void 0 ? void 0 : _a.click();
            var handleMessage_1 = function (event) {
                if (formAction.indexOf(event.origin) === 0) {
                    if (event.data === 'close') {
                        // dispatch(push(location?.state?.from || '/'));
                        dispatch(getCashOut());
                        dispatch(resetProviderId());
                    }
                    if (event.data === 'refresh') {
                        getBPLoginTemplateLink();
                    }
                    if (event.data === 'registration_success') {
                        dispatch(resetProviderId());
                        restartPolling();
                    }
                    if (event.data === 'clear') {
                        dispatch(deleteFullBetslip({ eventsType: 'betSlip' }));
                    }
                    if (event.data === 'close_go_live') {
                        dispatch(resetProviderId());
                        dispatch(push(getPathWithLanguageCode('/home/all/live')));
                        dispatch(getCashOut());
                    }
                    if (event.data === 'close_go_edit_data') {
                        dispatch(push(getPathWithLanguageCode('/my-account/general')));
                    }
                    if (event.data.open_partner_site) {
                        var link = event.data.open_partner_site;
                        window.open(link, '_blank');
                    }
                }
            };
            window.addEventListener('message', handleMessage_1);
            return function () {
                window.removeEventListener('message', handleMessage_1);
            };
        }
        return function () { return undefined; };
    };
    useEffect(function () {
        var _a;
        if (iframeRef.current && i18next.language) {
            (_a = iframeRef.current.contentWindow) === null || _a === void 0 ? void 0 : _a.postMessage({
                type: 'CHANGE_LANGUAGE',
                payload: i18next.language,
            }, '*');
        }
    }, [iframeRef.current, i18next.language]);
    useEffect(function () {
        var unsubscribe = handleSubmitAndSubscribe();
        return unsubscribe;
    }, [formAction, inputValue]);
    return (React.createElement(React.Fragment, null,
        linkType && React.createElement(BpHeader, { provider: currentProvider }),
        React.createElement("form", { name: "templateform", target: "hidden_iframe", action: formAction, method: "post", style: { display: 'none' } },
            React.createElement("input", { type: "text", name: "jwt", defaultValue: inputValue }),
            React.createElement("input", { type: "submit", ref: submit })),
        React.createElement("iframe", { src: "", style: {
                flexGrow: 1,
                border: 'none',
                width: '100%',
                zIndex: 4,
            }, onLoad: function () {
                setTimeout(function () {
                    dispatch(setIsMobileTopRankingHidden(false));
                }, 500);
            }, ref: iframeRef, name: "hidden_iframe", title: "bpRegistration", "data-testid": getTestId('SDK') })));
};
