var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import React, { useEffect, useLayoutEffect, useState } from 'react';
import classNames from 'classnames';
import i18next from 'i18next';
import qs from 'qs';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { goBack, push, replace } from 'connected-react-router';
import { unwrapResult } from '@reduxjs/toolkit';
import { useIsDesktop, useStructuredSelector } from '~hooks';
import { currentSportIdSelector } from '~slices/options';
import { useAppDispatch } from '~store';
import { getTournamentsOutrights } from '~slices/outrights';
import scrollWatcher from '~utils/scroll';
import { selectedTournamentSelector } from "~slices/flags";
import { OutcomesList, OutrightsList, TournamentsList } from './components';
import useStyles from './Outrights.styles';
var ListType;
(function (ListType) {
    ListType["CATEGORY"] = "CATEGORY";
    ListType["TOURNAMENT"] = "TOURNAMENT";
    ListType["OUTRIGHT"] = "OUTRIGHT";
})(ListType || (ListType = {}));
var RenderComponent = (_a = {},
    _a[ListType.CATEGORY] = TournamentsList,
    _a[ListType.TOURNAMENT] = OutrightsList,
    _a[ListType.OUTRIGHT] = OutcomesList,
    _a);
var STEPS = [ListType.CATEGORY, ListType.TOURNAMENT, ListType.OUTRIGHT];
export var Outrights = function (_a) {
    var _b;
    var prevIds = _a.prevIds;
    var dispatch = useAppDispatch();
    var isDesktop = useIsDesktop();
    var location = useLocation();
    var t = useTranslation('ticket').t;
    var classes = useStyles();
    var _c = useState(false), showWidget = _c[0], setShowWidget = _c[1];
    var _d = useState(''), widgetTypeToShow = _d[0], setWidgetTypeToShow = _d[1];
    var _e = useState(false), isPossibleToShowTable = _e[0], setIsPossibleToShowTable = _e[1];
    var _f = useState((_b = {
            stepIndex: 0
        },
        _b[ListType.CATEGORY] = undefined,
        _b[ListType.TOURNAMENT] = undefined,
        _b[ListType.OUTRIGHT] = undefined,
        _b)), state = _f[0], setState = _f[1];
    var category = state.CATEGORY;
    var _g = useState({
        scid: (prevIds === null || prevIds === void 0 ? void 0 : prevIds.scid) || (category === null || category === void 0 ? void 0 : category.scid),
        srid: (prevIds === null || prevIds === void 0 ? void 0 : prevIds.srid) || (category === null || category === void 0 ? void 0 : category.srid),
    }), ids = _g[0], setids = _g[1];
    var scid = ids.scid, srid = ids.srid;
    var _h = useStructuredSelector({
        currentSportId: currentSportIdSelector,
        selectedTournament: selectedTournamentSelector,
    }), currentSportId = _h.currentSportId, selectedTournament = _h.selectedTournament;
    useEffect(function () {
        if (state.stepIndex > 0) {
            setids({
                scid: (category === null || category === void 0 ? void 0 : category.scid) || (prevIds === null || prevIds === void 0 ? void 0 : prevIds.scid),
                srid: (category === null || category === void 0 ? void 0 : category.srid) || (prevIds === null || prevIds === void 0 ? void 0 : prevIds.srid),
            });
        }
        else {
            setids({
                scid: (prevIds === null || prevIds === void 0 ? void 0 : prevIds.scid) || (category === null || category === void 0 ? void 0 : category.scid),
                srid: (prevIds === null || prevIds === void 0 ? void 0 : prevIds.srid) || (category === null || category === void 0 ? void 0 : category.srid),
            });
        }
    }, [state.stepIndex]);
    useEffect(function () {
        // if (scid && srid && !isPossibleToShowTable) {
        //   setWidgetTypeToShow('sc');
        // } else if (scid && srid && isPossibleToShowTable) {
        //   setWidgetTypeToShow('sr');
        // } else if (srid && !scid) {
        //   setWidgetTypeToShow('sr');
        // } else if (scid && !srid) {
        //   setWidgetTypeToShow('sc');
        // }
        if (scid) {
            setWidgetTypeToShow('sc');
        }
    }, [scid, isPossibleToShowTable, srid]);
    useEffect(function () {
        window.SIR('addWidget', '#srWidget', 'season.liveTable', {
            tournamentId: srid,
            disableWidgetHeader: true,
            layout: 'single',
            scoreboard: 'disable',
            detailedScoreboard: 'disable',
            tabsPosition: 'true',
            dataByLogoColor: 'black',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onTrack: function (a, b) {
                setIsPossibleToShowTable(b.error !== 'NoDataError');
            },
        });
    }, [srid]);
    useLayoutEffect(function () {
        setShowWidget(false);
    }, [srid]);
    var outrightId = qs.parse(location.search, {
        ignoreQueryPrefix: true,
    }).outrightId;
    useEffect(function () {
        if (outrightId && selectedTournament) {
            dispatch(getTournamentsOutrights({
                id: selectedTournament.id,
                langIso: i18next.language,
            }))
                .then(unwrapResult)
                .then(function (data) {
                setState(function (prevState) {
                    var _a;
                    return (__assign(__assign({}, prevState), (_a = { stepIndex: STEPS.length - 1 }, _a[STEPS[1]] = data.find(function (_a) {
                        var bid = _a.bid;
                        return bid === parseInt(outrightId, 10);
                    }), _a)));
                });
            });
        }
    }, [outrightId, selectedTournament]);
    useEffect(function () {
        if (selectedTournament && !outrightId) {
            dispatch(getTournamentsOutrights({
                id: selectedTournament.id,
                langIso: i18next.language,
            })).then(function () {
                setState(function (prevState) { return (__assign(__assign({}, prevState), { stepIndex: STEPS.length - 2 })); });
            });
        }
    }, [selectedTournament]);
    var toggleWidget = function () {
        setShowWidget(!showWidget);
    };
    var onClick = function (item) {
        var _a;
        if (!isDesktop)
            scrollWatcher.setScrollTop(document.documentElement.scrollTop);
        if (state.stepIndex === 0) {
            scrollWatcher.setSouldScroll(false);
        }
        if (state.stepIndex === 0 && !(selectedTournament === null || selectedTournament === void 0 ? void 0 : selectedTournament.id)) {
            dispatch(replace({
                // @ts-expect-error
                pathname: "".concat(location.pathname, "/").concat((_a = item === null || item === void 0 ? void 0 : item.tournament) === null || _a === void 0 ? void 0 : _a.id),
                state: location.state,
            }));
        }
        setState(function (prevState) {
            var _a;
            return (__assign(__assign({}, prevState), (_a = { stepIndex: prevState.stepIndex + 1 > STEPS.length
                        ? prevState.stepIndex
                        : prevState.stepIndex + 1 }, _a[STEPS[prevState.stepIndex]] = item, _a)));
        });
    };
    var onBackClick = function () {
        if (state.stepIndex === 0) {
            if (location.state.shouldScrollBack) {
                dispatch(push({
                    pathname: location.state.prevPath,
                    state: {
                        scrollPosition: location.state.scrollPosition,
                        fromOutrights: true,
                    },
                }));
            }
            else {
                dispatch(goBack());
            }
        }
        else {
            if (state.stepIndex === 2) {
                scrollWatcher.setSouldScroll(true);
            }
            setState(function (prevState) { return (__assign(__assign({}, prevState), { stepIndex: prevState.stepIndex - 1 < 0 ? 0 : prevState.stepIndex - 1 })); });
        }
        if (outrightId) {
            dispatch(replace({ pathname: location.pathname }));
        }
        if ((selectedTournament === null || selectedTournament === void 0 ? void 0 : selectedTournament.id) && state.stepIndex === 1) {
            dispatch(replace({
                pathname: location.pathname.split('/').slice(0, -1).join('/'),
                state: location.state,
            }));
        }
    };
    var Component = RenderComponent[STEPS[state.stepIndex]];
    return (React.createElement("div", { style: isDesktop ? { minHeight: '350px' } : {} },
        React.createElement("div", { className: classNames(classes.root, 'flex-column') },
            React.createElement(Component, __assign({ onClick: onClick, onBackClick: onBackClick }, state)),
            scid && (React.createElement("div", { style: { backgroundColor: '#1c1d23', zIndex: 1 } },
                React.createElement("div", { role: "button", tabIndex: -1, className: classes.liveStandings, onClick: toggleWidget }, t('standings')))),
            showWidget && widgetTypeToShow === 'sc' && (React.createElement("iframe", { className: classes.frame, src: "".concat(process.env.REACT_APP_BR_URL, "/").concat(process.env.REACT_APP_BR_BRAND_NAME, "/").concat(i18next.language, "/").concat(currentSportId, "/season/").concat(scid), style: { flexGrow: 1, border: 'none', minHeight: '90vh' }, id: "iframe", title: "standings" })))));
};
