import React from 'react';
import classNames from 'classnames';
import { squareGallerySelector } from '~slices/teasers';
import { useIsMobileDevise, useStructuredSelector } from '~hooks';
import { useStyles } from '~molecules/EmptyBetslipBanner/EmptyBetslipBanner.styles';
import { HorizonGallery } from '~molecules/carouselSlider/HorizonGallery';
export var EmptyBetslipBanner = function () {
    var classes = useStyles();
    var isMobile = useIsMobileDevise();
    var banners = useStructuredSelector({
        banners: function (state) { return squareGallerySelector(state); },
    }).banners;
    var slideshowIndex = banners === null || banners === void 0 ? void 0 : banners
    //@ts-ignore
    .map(function (ssh) { var _a; return (_a = ssh === null || ssh === void 0 ? void 0 : ssh.pages) === null || _a === void 0 ? void 0 : _a.filter(function (page) { return page.location === 'RIGHT'; }); }).map(function (res) { return (res === null || res === void 0 ? void 0 : res.length) !== 0; }).indexOf(true);
    var slideshow = banners && slideshowIndex !== undefined
        ? banners[slideshowIndex]
        : undefined;
    return (React.createElement("div", { className: classNames(classes.container, isMobile && classes.mobile) },
        React.createElement(HorizonGallery, { position: 'TOP', slideshow: slideshow, type: slideshow === null || slideshow === void 0 ? void 0 : slideshow.slideshowType, className: classes.banner })));
};
