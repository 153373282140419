import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { TournamentLabelType } from '~/constants/view/TournamentLabelType';
import { useStyles } from './TournamentLabel.styles';
var TournamentLabel = function (_a) {
    var _b, _c;
    var label = _a.label, _d = _a.shifted, shifted = _d === void 0 ? false : _d;
    var classes = useStyles();
    var t = useTranslation('ticket.tournament_state').t;
    if (label === TournamentLabelType.LIVE) {
        return (React.createElement("div", { className: classNames(classes.container, classes.live, (_b = {},
                _b[classes.shifted] = shifted,
                _b)) }, t('live')));
    }
    if (label === TournamentLabelType.TODAY) {
        return (React.createElement("div", { className: classNames(classes.container, classes.today, (_c = {},
                _c[classes.shifted] = shifted,
                _c)) }, t('today')));
    }
    return null;
};
export { TournamentLabel };
