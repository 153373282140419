export var addExternalScript = function (_a) {
    var url = _a.url, body = _a.body;
    var head = document.querySelector('head');
    var script = document.createElement('script');
    if (url) {
        script.setAttribute('src', url);
    }
    if (body) {
        script.innerHTML = body;
    }
    if (url || body) {
        head === null || head === void 0 ? void 0 : head.appendChild(script);
    }
};
