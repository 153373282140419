import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IconButton from '@material-ui/core/IconButton';
var useStyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        padding: '10px 0',
        backgroundColor: '#FF0000',
        color: 'white',
    },
    triangle: {
        marginRight: 8,
        fontSize: 14,
        fontWeight: 900,
    },
    label: {
        fontSize: 12,
        lineHeight: '14px',
    },
    deleteButton: {
        position: 'absolute',
        top: 0,
        right: 0,
        padding: '11px 14px',
        fontSize: 12,
        lineHeight: '12px',
    },
}, { name: 'EventDeactivatedOdd' });
export var EventDeactivatedOdd = function (_a) {
    var onDeleteClick = _a.onDeleteClick;
    var t = useTranslation('ticket').t;
    var classes = useStyles();
    return (React.createElement("div", { className: classes.root },
        React.createElement("i", { className: classNames('fas fa-exclamation-triangle', classes.triangle) }),
        React.createElement("span", { className: classes.label }, t('deactivated_bet')),
        onDeleteClick && (React.createElement(IconButton, { className: classes.deleteButton, onClick: onDeleteClick },
            React.createElement("i", { className: "far fa-times-circle" })))));
};
