import makeStyles from '@material-ui/core/styles/makeStyles';
export var useStyles = makeStyles({
    wrapper: {
        width: '100%',
        maxWidth: 88,
        height: 88,
        borderRadius: 14.3,
        background: 'linear-gradient(270deg, #3C088E -1.63%, #CD0806 135.88%)',
    },
    md: {
        width: '100%',
        maxWidth: 98,
        height: 98,
    },
    avatarName: {
        height: 18.07,
        padding: '2px 10px',
        textAlign: 'center',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontSize: 12,
        fontWeight: 400,
        color: '#FFFFFF',
        boxSizing: 'border-box',
    },
    avatar: {
        height: 43,
        background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.08) 23.12%, rgba(0, 0, 0, 0.00) 40.99%, rgba(0, 0, 0, 0.00) 60.42%, rgba(0, 0, 0, 0.08) 75%, rgba(0, 0, 0, 0.25) 100%)',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        fontSize: '12px',
        color: '#FFFFFF',
        '& img': {
            height: 43,
        },
    },
    stars: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 26.71,
        padding: '0 4px',
        boxSizing: 'border-box',
        '& img': {
            height: 16,
            width: 16,
        },
    },
    starsMd: {
        height: 29,
        '& img': {
            height: 18,
            width: 18,
        },
    },
    avatarMd: {
        height: 48,
        '& img': {
            height: 48,
        },
    },
    avatarNameMd: {
        height: 19.25
    },
}, { name: 'RankingListAvatar' });
