var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from '@reduxjs/toolkit';
import { createAsyncReducers } from '~utils/createAsyncReducers';
import { getAboutPageContent } from '~slices/about/thunks';
import { name } from './constants';
var initialState = {
    data: {
        id: 0,
        name: '',
        title: '',
        priority: 0,
        description: '',
        text: '',
    },
    loading: 'idle',
    error: null,
};
export var about = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        resetAboutPageContent: function () {
            return __assign({}, initialState);
        },
    },
    extraReducers: function (builder) {
        var _a = createAsyncReducers(), fulfilled = _a.fulfilled, pending = _a.pending, rejected = _a.rejected;
        builder
            .addCase(getAboutPageContent.fulfilled, fulfilled)
            .addCase(getAboutPageContent.pending, pending)
            .addCase(getAboutPageContent.rejected, rejected);
    },
});
export var resetAboutPageContent = about.actions.resetAboutPageContent;
