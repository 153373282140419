var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Field } from 'redux-form';
import { Checkbox } from '~atoms';
var renderCheckbox = function (_a) {
    var input = _a.input, meta = _a.meta, title = _a.title, className = _a.className, required = _a.required;
    return (React.createElement(Checkbox, __assign({}, input, { error: meta.touched && meta.invalid, helperText: meta.touched ? meta.error : '', title: title, required: required, className: className })));
};
var CheckboxField = function (_a) {
    var name = _a.name, title = _a.title, required = _a.required, className = _a.className, validators = _a.validators;
    return (React.createElement(Field, { name: name, className: className, 
        // @ts-expect-error
        title: title, type: "checkbox", component: renderCheckbox, required: required, validate: validators }));
};
export default CheckboxField;
