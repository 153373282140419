import React from 'react';
import { currencyConverter } from '~utils/converters';
import { ProviderLogo } from '~atoms/providerLogo';
export var BetProviderLogo = function (_a) {
    var id = _a.id, betProviderUser = _a.betProviderUser, welcomeBonus = _a.welcomeBonus, backgroundColor = _a.backgroundColor;
    var convertedUserBalance = currencyConverter(betProviderUser === null || betProviderUser === void 0 ? void 0 : betProviderUser.balance, betProviderUser === null || betProviderUser === void 0 ? void 0 : betProviderUser.currency);
    var convertedWellcomeBonus = currencyConverter(Math.floor(welcomeBonus || 0), betProviderUser === null || betProviderUser === void 0 ? void 0 : betProviderUser.currency, {
        maximumFractionDigits: 0,
    });
    var isBalance = Boolean(betProviderUser);
    return (React.createElement(ProviderLogo, { id: id, money: isBalance ? convertedUserBalance : convertedWellcomeBonus, backgroundColor: backgroundColor, isBalance: isBalance }));
};
