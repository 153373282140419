import React, { useEffect, useState } from 'react';
import { push } from 'connected-react-router';
import { Route, Switch, useLocation } from 'react-router-dom';
import { BalanceCard } from '~atoms/BalanceCard';
import { useStructuredSelector } from '~hooks';
import { betslipDataSelector } from '~slices/betslip';
import { useAppDispatch } from '~store';
import { getPathWithLanguageCode } from '~utils/languageInURL';
import { PaymentsURL } from '~pages/payments/payments.routes';
import { ShopBalance } from '~molecules/WithdrawTab/components/ShopBalance';
import { PaymentCode } from '~pages/payments/components/PaymentCode';
import { JackpotBalance } from '~molecules/WithdrawTab/components/JackpotBalance';
import { WithdrawStatus } from '~molecules/WithdrawTab/components/WithdrawStatus';
import { WithdrawOnline } from '~molecules/WithdrawTab/components/WithdrawOnline';
import { WithdrawInShop } from '~molecules/WithdrawTab/components/WithdrawInShop';
import { WithdrawPaymentCode } from '~molecules/WithdrawTab/components/WithdrawPaymentCode';
import { PaymentStatus } from '~molecules/WithdrawTab/components/PaymentStatus';
import { useStyles } from './WithdrawTab.styles';
export var WithdrawTab = function () {
    var _a;
    var classes = useStyles();
    var pathname = useLocation().pathname;
    var _b = useState('shop-balance'), activeCard = _b[0], setActiveCard = _b[1];
    var dispatch = useAppDispatch();
    var mockData = {
        shopBalance: 200,
        onlineBalance: 400,
        jackpot: 5500,
    };
    // TODO, replace with real data from BE
    var betslip = useStructuredSelector({
        betslip: betslipDataSelector,
    }).betslip;
    var ticketOffer = betslip.to;
    var currency = (_a = ticketOffer === null || ticketOffer === void 0 ? void 0 : ticketOffer.cu) !== null && _a !== void 0 ? _a : 'EUR';
    useEffect(function () {
        var regex = /\/withdraw\/([^\/]+)/;
        var match = pathname.match(regex);
        var cardName = match ? match[1] : '';
        if (cardName && cardName !== activeCard) {
            setActiveCard(cardName);
        }
    }, [pathname]);
    var onBalanceCardClick = function (type) {
        if (activeCard === type) {
            return;
        }
        setActiveCard(type);
        dispatch(push(getPathWithLanguageCode("/".concat(PaymentsURL.PAYMENTS, "/").concat(PaymentsURL.WITHDRAW, "/").concat(type))));
    };
    return (React.createElement("div", { className: classes.container },
        React.createElement("div", { className: classes.balanceCardWrapper },
            React.createElement(BalanceCard, { type: 'SHOP', balance: mockData.shopBalance, currency: currency, isActive: activeCard === 'shop-balance', onClick: function () { return onBalanceCardClick('shop-balance'); } })),
        React.createElement(Switch, null,
            React.createElement(Route, { path: "/:lang/payments/withdraw/shop-balance", component: function () { return React.createElement(ShopBalance, { balance: mockData.shopBalance }); }, exact: true }),
            React.createElement(Route, { path: "/:lang/payments/withdraw/shop-balance/payment-code", component: PaymentCode, exact: true }),
            React.createElement(Route, { path: "/:lang/".concat(PaymentsURL.PAYMENTS, "/").concat(PaymentsURL.WITHDRAW_JACKPOT_BALANCE, "/").concat(PaymentsURL.PAYMENT_CODE), component: WithdrawPaymentCode, exact: true }),
            React.createElement(Route, { path: "/:lang/".concat(PaymentsURL.PAYMENTS, "/").concat(PaymentsURL.WITHDRAW_JACKPOT_BALANCE, "/").concat(PaymentsURL.PAYMENT_STATUS), component: PaymentStatus, exact: true }),
            React.createElement(Route, { path: "/:lang/payments/withdraw/shop-balance/status", component: WithdrawStatus, exact: true }),
            React.createElement(Route, { path: "/:lang/payments/withdraw/jackpot-balance", component: function () { return React.createElement(JackpotBalance, { balance: mockData.jackpot }); }, exact: true }),
            React.createElement(Route, { path: "/:lang/payments/withdraw/jackpot-balance/withdraw-in-shop", component: WithdrawInShop, exact: true }),
            React.createElement(Route, { path: "/:lang/payments/withdraw/jackpot-balance/online", component: WithdrawOnline }))));
};
