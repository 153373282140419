import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { numberWithDotsAndComma } from '~utils/numberWithDotsAndComma';
import { Button, Currency, FullscreenLoader } from '~atoms';
import { BalanceInput } from '~atoms/BalanceInput';
import { useStructuredSelector } from '~hooks';
import { betslipDataSelector } from '~slices/betslip';
import { getPathWithLanguageCode } from '~utils/languageInURL';
import { PaymentsURL } from '~pages/payments/payments.routes';
import { useAppDispatch } from '~store';
import { useStyles } from '~molecules/WithdrawTab/components/ShopBalance/ShopBalance.styles';
import { CSIcon } from "~icons/rankingList/CSIcon";
var DEFAULT_DEPOSIT_AMOUNT = '10';
export var ShopBalance = function (_a) {
    var _b;
    var balance = _a.balance;
    var dispatch = useAppDispatch();
    var classes = useStyles();
    var t = useTranslation('tip-game').t;
    var _c = useState(DEFAULT_DEPOSIT_AMOUNT), withdrawAmount = _c[0], setWithdrawAmount = _c[1];
    var _d = useState(false), isDataLoading = _d[0], setIsDataLoading = _d[1];
    // TODO, replace with real data from BE
    var betslip = useStructuredSelector({
        betslip: betslipDataSelector,
    }).betslip;
    var ticketOffer = betslip.to;
    var currency = (_b = ticketOffer === null || ticketOffer === void 0 ? void 0 : ticketOffer.cu) !== null && _b !== void 0 ? _b : 'NONE';
    var currencyValue = currency === 'CRD' ? React.createElement(CSIcon, { size: "lg" }) : React.createElement(Currency, { currency: currency });
    var onWithdrawButtonClick = function () {
        setIsDataLoading(true);
        setTimeout(function () {
            setIsDataLoading(false);
        }, 2000);
        setTimeout(function () {
            dispatch(push(getPathWithLanguageCode("/".concat(PaymentsURL.PAYMENTS, "/").concat(PaymentsURL.WITHDRAW_SHOP_BALANCE, "/").concat(PaymentsURL.PAYMENT_CODE, "?process=withdraw&sum=").concat(withdrawAmount))));
        }, 2100);
    };
    return (React.createElement("div", { className: classes.container },
        isDataLoading && React.createElement(FullscreenLoader, null),
        React.createElement("div", null,
            React.createElement("div", { className: classes.currentBalanceTitle },
                t('payments.your_current_balance'),
                ":"),
            React.createElement("div", { className: classes.currentBalanceAmount },
                numberWithDotsAndComma(balance),
                currencyValue),
            React.createElement(BalanceInput, { setValue: setWithdrawAmount, currency: currency, amount: withdrawAmount })),
        React.createElement(Button, { color: "primary", variant: "contained", fullWidth: true, className: classes.button, onClick: onWithdrawButtonClick }, t('payments.withdraw'))));
};
