import React from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { termsHaveUpdatesSelector } from '~slices/terms';
import { useIsDesktop, useStructuredSelector } from '~hooks';
import { DesktopHeader } from './DesktopHeader';
import { MobileHeader } from './MobileHeader';
import { useHeaderStyles } from './Header.styles';
var HeaderTemp = function () {
    var _a;
    var headerClasses = useHeaderStyles();
    var isDesktop = useIsDesktop();
    var termsHaveUpdate = useStructuredSelector({
        termsHaveUpdate: termsHaveUpdatesSelector,
    }).termsHaveUpdate;
    // useEffect(() => {
    //   const scriptBody = `
    //   ;(function (a, b, c, d, e, f) {
    //     a[e]=a[e]||[];a.x=b.createElement(c);a.y=b.getElementsByTagName(c)[0];x.src=d+"?id="+f;x.setAttribute("data-srtmn",e);y.parentNode.insertBefore(x,y);
    //   }(window, document, "script", "https://tm.ads.sportradar.com/dist/tag-manager.js", "srtmCommands", "${process.env.REACT_APP_SPORTRADAR_ID}"));
    // `;
    //
    //   addExternalScript({ body: scriptBody });
    // }, []);
    var t = useTranslation('header').t;
    return (React.createElement(React.Fragment, null,
        termsHaveUpdate && React.createElement("div", { className: headerClasses.overlay }),
        React.createElement("nav", { className: classNames('flex-row flex-center', headerClasses.header, (_a = {},
                _a[headerClasses.header] = termsHaveUpdate,
                _a)) }, isDesktop ? React.createElement(DesktopHeader, { t: t }) : React.createElement(MobileHeader, null))));
};
export var Header = withRouter(HeaderTemp);
