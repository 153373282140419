import { useCallback } from 'react';
import { LOGIN_PRESS } from '~constants/cpa-message-codes';
import { useAppDispatch } from '~store';
import { MODALS, closeModal, openModal } from '~modals';
import { PLATFORMS } from '~slices/teasers';
import { changeHintState } from '~slices/hints';
import { isAuthenticatedSelector, isCookieAcceptedSelector, } from '~slices/auth';
import { usePlatofrmType, useStructuredSelector } from '~hooks';
var useOpenLogin = function (onSubmit) {
    var dispatch = useAppDispatch();
    var platform = usePlatofrmType();
    var _a = useStructuredSelector({
        isAuthenticated: isAuthenticatedSelector,
        cookieAccepted: isCookieAcceptedSelector,
    }), isAuthenticated = _a.isAuthenticated, cookieAccepted = _a.cookieAccepted;
    var openLogin = useCallback(function () {
        if (isAuthenticated) {
            return;
        }
        if (cookieAccepted) {
            dispatch(openModal({
                modalName: MODALS.LOGIN_MODAL,
                modalProps: {
                    isOpen: true,
                    onSubmit: function () {
                        dispatch(closeModal({ key: MODALS.LOGIN_MODAL }));
                        if (onSubmit) {
                            onSubmit();
                        }
                    },
                    onClose: function () {
                        dispatch(closeModal({ key: MODALS.LOGIN_MODAL }));
                    },
                },
            }));
        }
        else if (platform === PLATFORMS.CROSS_PLATFORM_MOBILE) {
            // @ts-expect-error
            window.ReactNativeWebView.postMessage(JSON.stringify({
                code: LOGIN_PRESS,
                target: '/top-ranking',
            }));
        }
        else {
            dispatch(changeHintState({ isCookiesConsentHintEnabled: true }));
        }
    }, [isAuthenticated, cookieAccepted, platform, onSubmit]);
    return { openLogin: openLogin };
};
export { useOpenLogin };
