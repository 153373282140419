import React, { useRef } from 'react';
import { NestedGlobalRankingListItem } from "~atoms/RankingListCartoonItem/components/NestedGlobalRankingListItem";
import { useStyles } from '../RankingListCartoonItem.styles';
export var NestedGlobalRankingList = function (_a) {
    var rankedPlayersList = _a.rankedPlayersList, myselfData = _a.myselfData, trendBalance = _a.trendBalance, backgroundColor = _a.backgroundColor, userName = _a.userName, isMyRankDisplay = _a.isMyRankDisplay, onScroll = _a.onScroll;
    var classes = useStyles();
    var containerRef = useRef(null);
    var handleScroll = function () {
        if (onScroll) {
            onScroll(containerRef);
        }
    };
    return (React.createElement("div", { ref: containerRef, onScroll: handleScroll, className: classes.globalListWrapper },
        rankedPlayersList.map(function (item) { return (React.createElement(NestedGlobalRankingListItem, { key: item.rank, currentUserRank: myselfData === null || myselfData === void 0 ? void 0 : myselfData.rank, trendBalance: trendBalance, rankingItem: item, backgroundColor: backgroundColor })); }),
        isMyRankDisplay && myselfData ? (React.createElement("div", { className: classes.stickyBottom },
            React.createElement(NestedGlobalRankingListItem, { rankingItem: myselfData, currentUserRank: myselfData.rank, trendBalance: trendBalance, backgroundColor: backgroundColor, userName: userName }))) : null));
};
