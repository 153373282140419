import React from 'react';
import { useTranslation } from 'react-i18next';
import { RankingListTabs } from '~pages/ranking-list/RankingListTabs';
import { GAMES_MODE } from "~slices/gameTypes/constants";
import { RankingListTabsGroup } from "~pages/ranking-list/RankingListTabsGroup";
import { useStructuredSelector } from "~hooks";
import { useSelectedGameTypeSelector } from "~slices/gameTypes";
import { useStyles } from './RankingListComponents.styles';
export var RankingListDesktop = function () {
    var classes = useStyles();
    var t = useTranslation('tip-game').t;
    var selectedGameType = useStructuredSelector({
        selectedGameType: useSelectedGameTypeSelector,
    }).selectedGameType;
    return (React.createElement("div", { className: classes.desktopWrapper },
        React.createElement("div", { className: classes.title }, t('ranking-list.ranking-list')),
        selectedGameType === GAMES_MODE.GROUP ? React.createElement(RankingListTabsGroup, null) : React.createElement(RankingListTabs, null)));
};
