import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var FormulaIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("svg", { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M10.7524 13.1809L12.8675 11.3062C13.1073 11.0937 13.4857 11.264 13.4857 11.5844V13.8631", fill: "#DB0E0E" }),
            React.createElement("path", { d: "M24.6205 13.1565L23.9366 8.78173C23.8798 8.41882 24.1604 8.09097 24.5277 8.09097H27.4016C27.7321 8.09097 28 8.35883 28 8.68931V17.3516C28 17.876 27.5749 18.3011 27.0505 18.3011H25.4834H23.2954L24.8192 14.587", fill: "#ED1817" }),
            React.createElement("path", { d: "M27.9999 8.68916V10.0817H24.1399L23.9365 8.78163C23.8798 8.41873 24.1604 8.09082 24.5276 8.09082H27.4015C27.7321 8.09082 27.9999 8.35868 27.9999 8.68916Z", fill: "#E6E6E6" }),
            React.createElement("path", { d: "M25.4835 16.8926L11.4858 18.3012H9.74355L3.76129 16.8937L1.64526 16.396C2.71156 14.2669 7.86526 13.1595 12.6927 13.1018C12.7321 13.1006 12.771 13.1006 12.8098 13.1001H12.8184C14.1481 13.0892 15.4499 13.1578 16.6316 13.3069C16.635 13.3075 16.639 13.3081 16.6424 13.3086C17.5144 13.4212 18.2876 12.744 18.2876 11.8646V10.4681C18.2876 10.0452 18.659 9.71835 19.0779 9.77205C21.151 10.0366 22.5567 10.784 23.5087 11.7001C23.5087 11.7006 23.5093 11.7006 23.5093 11.7006C24.0927 12.2618 24.5058 12.8869 24.7984 13.5035C25.6173 15.2321 25.4835 16.8926 25.4835 16.8926Z", fill: "#ED1817" }),
            React.createElement("path", { d: "M18.8495 15.2229H16.8104C14.3985 15.2229 12.1075 16.2792 10.5411 18.1134L10.3809 18.301H18.7094", fill: "#D30F0F" }),
            React.createElement("path", { d: "M19.9543 15.2229H17.9151C15.5032 15.2229 13.2122 16.2792 11.6459 18.1134L11.4856 18.301H19.8142", fill: "#E6E6E6" }),
            React.createElement("path", { d: "M12.6927 13.1017C9.267 13.1852 7.33331 16.644 7.33331 16.644L3.76129 16.8937L1.64526 16.396C2.71162 14.2669 7.86531 13.1594 12.6927 13.1017Z", fill: "#D30F0F" }),
            React.createElement("path", { d: "M4.21542 15.9045L0 16.6616V17.9473H4.23888", fill: "#E6E6E6" }),
            React.createElement("path", { d: "M23.4476 14.8915C23.4476 14.8915 23.9671 13.3338 23.5093 11.7006C24.0927 12.2618 24.5059 12.8869 24.7985 13.5035L25.7692 18.3011H24.3405L23.4476 14.8915Z", fill: "#D30F0F" }),
            React.createElement("path", { d: "M7.06625 19.9092C8.73225 19.9092 10.0828 18.5586 10.0828 16.8926C10.0828 15.2266 8.73225 13.8761 7.06625 13.8761C5.40024 13.8761 4.04968 15.2266 4.04968 16.8926C4.04968 18.5586 5.40024 19.9092 7.06625 19.9092Z", fill: "#2C4046" }),
            React.createElement("path", { d: "M22.4669 19.9092C24.1329 19.9092 25.4834 18.5586 25.4834 16.8926C25.4834 15.2266 24.1329 13.8761 22.4669 13.8761C20.8009 13.8761 19.4503 15.2266 19.4503 16.8926C19.4503 18.5586 20.8009 19.9092 22.4669 19.9092Z", fill: "#2C4046" }),
            React.createElement("path", { d: "M7.06626 18.0723C7.71778 18.0723 8.24593 17.5442 8.24593 16.8926C8.24593 16.2411 7.71778 15.713 7.06626 15.713C6.41475 15.713 5.8866 16.2411 5.8866 16.8926C5.8866 17.5442 6.41475 18.0723 7.06626 18.0723Z", fill: "#9AA7B8" }),
            React.createElement("path", { d: "M22.4669 18.0723C23.1184 18.0723 23.6466 17.5442 23.6466 16.8926C23.6466 16.2411 23.1184 15.713 22.4669 15.713C21.8154 15.713 21.2872 16.2411 21.2872 16.8926C21.2872 17.5442 21.8154 18.0723 22.4669 18.0723Z", fill: "#9AA7B8" }),
            React.createElement("path", { d: "M7.06619 18.501C6.17938 18.501 5.45789 17.7796 5.45789 16.8928C5.45789 16.006 6.17932 15.2845 7.06619 15.2845C7.95306 15.2845 8.67449 16.0059 8.67449 16.8928C8.67449 17.7796 7.953 18.501 7.06619 18.501ZM7.06619 16.1417C6.65204 16.1417 6.31506 16.4786 6.31506 16.8928C6.31506 17.3069 6.65199 17.6439 7.06619 17.6439C7.48039 17.6439 7.81732 17.3069 7.81732 16.8928C7.81732 16.4786 7.48034 16.1417 7.06619 16.1417Z", fill: "#1D2C30" }),
            React.createElement("path", { d: "M22.4667 18.501C21.5799 18.501 20.8584 17.7796 20.8584 16.8928C20.8584 16.006 21.5798 15.2845 22.4667 15.2845C23.3536 15.2845 24.075 16.0059 24.075 16.8928C24.075 17.7796 23.3535 18.501 22.4667 18.501ZM22.4667 16.1417C22.0526 16.1417 21.7156 16.4786 21.7156 16.8928C21.7156 17.3069 22.0525 17.6439 22.4667 17.6439C22.8809 17.6439 23.2178 17.3069 23.2178 16.8928C23.2179 16.4786 22.8809 16.1417 22.4667 16.1417Z", fill: "#1D2C30" }))));
};
