import makeStyles from '~utils/makeStyles';
export var useStyles = makeStyles({
    wrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        paddingTop: '20px',
        height: '100%',
        '& [class*="subheader"]': {
            display: 'flex',
            marginTop: '50px',
            maxWidth: '100%',
            fontWeight: 700,
            fontSize: '64px',
            '& > div': {
                marginLeft: '5px',
                fontWeight: 700,
                fontSize: '64px',
            },
        },
    },
    receivedButton: {
        fontSize: '16px',
        backgroundColor: '#00C150',
        '&:hover': {
            backgroundColor: '#00C150',
        },
    },
    NotReceivedButton: {
        marginTop: '8px',
        fontSize: '16px',
        backgroundColor: '#FF0C38',
        '&:hover': {
            backgroundColor: '#FF0C38',
        },
    },
    actionBlock: {},
}, { name: 'WithdrawConfirmation' });
