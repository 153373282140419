var getMaxCoordinates = function (oc, positionsMap) {
    var maxX = 0;
    var maxY = 0;
    Object.entries(positionsMap).forEach(function (_a) {
        var position = _a[0], ids = _a[1];
        var _b = position.split('x').map(Number), x = _b[0], y = _b[1];
        var od = oc.find(function (_a) {
            var id = _a.id;
            return (ids || []).includes(Number(id));
        });
        if (!od) {
            return;
        }
        maxX = x > maxX ? x : maxX;
        maxY = y > maxY ? y : maxY;
    });
    return {
        x: maxX,
        y: maxY,
    };
};
export { getMaxCoordinates };
