import React from 'react';
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { CSIcon } from "~icons/rankingList/CSIcon";
import { GAMES_MODE } from "~slices/gameTypes/constants";
import { useStyles } from './GameButtonSimple.styles';
export var GameButtonSimple = function (_a) {
    var game = _a.game, isActive = _a.isActive, isBlocked = _a.isBlocked, isDisabled = _a.isDisabled, onClick = _a.onClick;
    var classes = useStyles();
    var t = useTranslation('tip-game').t;
    var balance = (game === null || game === void 0 ? void 0 : game.playerBalance) || (game === null || game === void 0 ? void 0 : game.playerBalance) === 0 ? game.playerBalance : game === null || game === void 0 ? void 0 : game.startCredits;
    var name = game.groupName || game.name;
    var title = game.tournament && !game.groupName
        ? game.tournament
        : game.gameMode === GAMES_MODE.GLOBAL
            ? t("game-name.".concat(game.name))
            : name;
    var handleCheckboxChange = function () {
        if (!isDisabled && onClick) {
            onClick(name, !!isBlocked);
        }
    };
    return (React.createElement("div", { role: "button", tabIndex: 0, className: classNames(classes.container, isActive && classes.active, isActive && classes.active), onClick: handleCheckboxChange },
        React.createElement("div", { className: classes.balance },
            balance,
            " ",
            balance || balance === 0 ? React.createElement(CSIcon, { color: "#FFF" }) : ''),
        React.createElement("div", { className: classes.title }, title)));
};
