var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { push } from 'connected-react-router';
import { useIsDesktop } from '~hooks';
import { ZIndex } from '~constants/view/ZIndex';
import { checkIsExternalLink } from '~utils/checkIsExternalLink';
import { useAppDispatch } from '~store';
import { getPathWithLanguageCode } from '~utils/languageInURL';
var useStyles = makeStyles({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        backgroundColor: '#222',
        position: 'relative',
    },
    ul: {
        listStyle: 'none',
        padding: 0,
        margin: 0,
        color: '#fff',
        display: 'flex',
        width: '100%',
        transform: 'translateX(0)',
        zIndex: ZIndex.GALLERY_SWIPE_AREA,
    },
    li: {
        position: 'relative',
        width: '300px',
        height: '150px',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        overflow: 'hidden',
        margin: '0 8px',
        flexShrink: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&.mobile': {
            margin: '0 5px',
            width: '252px',
            height: '126px',
        },
        '& span': {
            fontSize: '30px',
            fontWeight: 'bold',
            color: '#2AA1F7',
            position: 'absolute',
            height: '100%',
            width: '100%',
            display: 'grid',
            justifyContent: 'center',
            alignContent: 'center',
            background: '#0000007d',
        },
    },
    img: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
    },
    buttonsBlock: {
        zIndex: 15,
        '&.hide': {
            visibility: 'hidden',
        },
    },
    swipe: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        zIndex: ZIndex.GALLERY_SWIPE_AREA,
    },
    slideButton: {
        position: 'absolute',
        right: 18,
        top: '50%',
        transform: 'translate(50%, -50%)',
        width: '35px',
        height: '35px',
        background: '#323232',
        outline: 'none',
        border: 'none',
        borderRadius: '50%',
        cursor: 'pointer',
        color: '#2AA1F7',
        '&.left': {
            left: -18,
        },
    },
    cursorPointer: {
        cursor: 'pointer',
    },
}, { name: 'HorizonGallery' });
var prevId = 0;
var getUniqId = function () {
    return (prevId += 1);
};
export var HorizonGallery = memo(function HorizonGallery(_a) {
    var _b, _c;
    var slideshow = _a.slideshow, className = _a.className;
    var dispatch = useAppDispatch();
    var classes = useStyles();
    var isDesktop = useIsDesktop();
    var initialItemWidth = isDesktop ? 300 + 16 : 252 + 5;
    var teasers = (slideshow === null || slideshow === void 0 ? void 0 : slideshow.teasers) || [];
    var startSlide = teasers.length - 1 || 1;
    var sortedTeasers = __spreadArray([], teasers, true).sort(function (a, b) { return Number(a.orderNumber) - Number(b.orderNumber); });
    var teasersList = __spreadArray(__spreadArray(__spreadArray(__spreadArray([], sortedTeasers, true), sortedTeasers, true), sortedTeasers, true), sortedTeasers, true).slice(sortedTeasers.length - startSlide, 4 * sortedTeasers.length - startSlide)
        .map(function (teaser) {
        return __assign(__assign({}, teaser), { id: getUniqId() });
    });
    var DIRECTION_TYPE = {
        next: 'NEXT',
        prev: 'PREV',
    };
    var wrapperRef = useRef(null);
    var nextRef = useRef(null);
    var prevRef = useRef(null);
    var initialCountBehind = useRef(startSlide * 2);
    var _d = useState(initialItemWidth), itemWidth = _d[0], setItemWidth = _d[1];
    var _e = useState(initialCountBehind.current), countBehind = _e[0], setCountBehind = _e[1];
    var _f = useState(true), needTransition = _f[0], setNeedTransition = _f[1];
    var _g = useState('NEXT'), direction = _g[0], setDirection = _g[1];
    var _h = useState(teasersList), elements = _h[0], setElements = _h[1];
    var _j = useState(0), initialClientX = _j[0], setInitialClientX = _j[1];
    var _k = useState(0), finalClientX = _k[0], setFinalClientX = _k[1];
    useEffect(function () {
        initialCountBehind.current = startSlide * 2;
        setCountBehind(initialCountBehind.current);
        setNeedTransition(true);
        setDirection('NEXT');
        setElements(teasersList);
        setInitialClientX(0);
        setFinalClientX(0);
    }, [slideshow]);
    var visibleItemsCount = (((_b = wrapperRef.current) === null || _b === void 0 ? void 0 : _b.clientWidth) &&
        ((_c = wrapperRef.current) === null || _c === void 0 ? void 0 : _c.clientWidth) / itemWidth) ||
        0;
    var swipeableItemCount = visibleItemsCount - Math.floor(visibleItemsCount) <= 0.3
        ? Math.floor(visibleItemsCount) - 1
        : Math.floor(visibleItemsCount);
    var swipeCount = (slideshow === null || slideshow === void 0 ? void 0 : slideshow.switchMode) === 'ONE_BY_ONE' ? 1 : swipeableItemCount;
    var handleTouchStart = function (event) {
        setInitialClientX(event.nativeEvent.touches[0].clientX);
    };
    var handleTouchMove = function (event) {
        setFinalClientX(event.nativeEvent.touches[0].clientX);
    };
    var vaildNextSlider = function () {
        var tempCurrent = countBehind;
        var doneSwipeCount = Math.abs(initialCountBehind.current - countBehind);
        tempCurrent -= doneSwipeCount;
        var tempNums = __spreadArray(__spreadArray([], elements, true), elements.slice(0, doneSwipeCount), true).slice(-elements.length);
        setCountBehind(tempCurrent);
        setNeedTransition(false);
        setElements(tempNums);
    };
    var vaildPrevSlider = function () {
        var tempCurrent = countBehind;
        var doneSwipeCount = Math.abs(initialCountBehind.current - countBehind);
        tempCurrent += doneSwipeCount;
        var tempNums = __spreadArray(__spreadArray([], elements.slice(-doneSwipeCount), true), elements, true).slice(0, elements.length);
        setCountBehind(tempCurrent);
        setNeedTransition(false);
        setElements(tempNums);
    };
    var handleSliderTranslateEnd = function () {
        switch (direction) {
            case DIRECTION_TYPE.next:
                vaildNextSlider();
                break;
            case DIRECTION_TYPE.prev:
                vaildPrevSlider();
                break;
            default:
                break;
        }
    };
    var handleNext = useCallback(function () {
        var tempCurrent = countBehind + swipeCount;
        if (tempCurrent > elements.length - Math.floor(visibleItemsCount))
            return;
        setCountBehind(tempCurrent);
        setNeedTransition(true);
        setDirection(DIRECTION_TYPE.next);
    }, [countBehind, needTransition]);
    var handlePrev = function () {
        var tempCurrent = countBehind - swipeCount;
        if (tempCurrent < 0)
            return;
        setCountBehind(tempCurrent);
        setNeedTransition(true);
        setDirection(DIRECTION_TYPE.prev);
    };
    var handleTouchEnd = function () {
        if (finalClientX !== 0 && finalClientX + 20 < initialClientX) {
            handleNext();
        }
        if (finalClientX !== 0 && finalClientX > initialClientX + 20) {
            handlePrev();
        }
        setInitialClientX(0);
        setFinalClientX(0);
    };
    var onSlideClick = function (url) {
        checkIsExternalLink(url)
            ? window.open(url, '_blank')
            : dispatch(push(getPathWithLanguageCode("".concat(url))));
    };
    var transLateVal = function (slideMode) {
        if (slideMode === void 0) { slideMode = false; }
        var oneSideDelta = (visibleItemsCount - Math.floor(visibleItemsCount)) / 2;
        var currentCount = visibleItemsCount - Math.floor(visibleItemsCount) <= 0.3
            ? countBehind - 0.5
            : countBehind - 1;
        return Math.floor(-(currentCount * itemWidth + (itemWidth - itemWidth * oneSideDelta)));
    };
    var sliderStyle = function () {
        if (needTransition) {
            return {
                transform: "translateX(".concat(transLateVal(), "px)"),
                transition: 'transform 0.3s linear',
            };
        }
        return {
            transform: "translateX(".concat(transLateVal(), "px)"),
        };
    };
    useEffect(function () {
        var tId = setTimeout(function () {
            setNeedTransition(true);
            handleNext();
        }, Number(slideshow === null || slideshow === void 0 ? void 0 : slideshow.duration) * 1000);
        return function () {
            if (tId) {
                clearTimeout(tId);
            }
        };
    }, [handleNext]);
    useEffect(function () {
        setItemWidth(isDesktop ? 300 + 16 : 252 + 10);
    }, [isDesktop]);
    var height = isDesktop ? '150px' : '126px';
    return (React.createElement("div", { className: className, style: { overflow: 'hidden', height: height, minHeight: height } },
        React.createElement("div", { ref: wrapperRef, className: classes.wrapper },
            React.createElement("div", { onTouchStart: handleTouchStart, onTouchMove: handleTouchMove, onTouchEnd: handleTouchEnd, className: classes.swipe }),
            React.createElement("ul", { className: classes.ul, style: sliderStyle(), onTransitionEnd: handleSliderTranslateEnd }, elements.map(function (item, i) {
                var _a;
                var imageType = (_a = item === null || item === void 0 ? void 0 : item.mediaUrl) === null || _a === void 0 ? void 0 : _a.split('.').pop();
                return (React.createElement("li", { className: classNames(classes.li, { mobile: !isDesktop }, item.linkUrl && classes.cursorPointer), key: item.id }, imageType === 'mp4' || imageType === 'avi' ? (React.createElement("video", { muted: true, loop: true, playsInline: true, autoPlay: true, src: item === null || item === void 0 ? void 0 : item.mediaUrl, onClick: function () {
                        return item.linkUrl ? onSlideClick(item.linkUrl) : null;
                    } })) : (React.createElement("img", { className: classes.img, src: item === null || item === void 0 ? void 0 : item.mediaUrl, onClick: function () {
                        return item.linkUrl ? onSlideClick(item.linkUrl) : null;
                    }, alt: "alt" }))));
            })),
            React.createElement("div", { className: classNames(classes.buttonsBlock, { hide: !isDesktop }) },
                React.createElement("button", { className: classNames(classes.slideButton, 'left'), ref: prevRef, type: "button", onClick: handlePrev },
                    React.createElement("i", { className: "fas fa-chevron-left" })),
                React.createElement("button", { className: classes.slideButton, ref: nextRef, type: "button", onClick: handleNext },
                    React.createElement("i", { className: "fas fa-chevron-right" }))))));
});
