import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Collapse from '@material-ui/core/Collapse';
import { CSIcon } from '~icons/rankingList/CSIcon';
import CSCoin from '~icons/balance/CS_Coin.svg';
import locked from '~icons/rankingList/locked-white.svg';
import unlocked from '~icons/rankingList/unlocked-white.svg';
import { Currency } from '~atoms';
import { VerticalLinearProgressCartoon } from '~atoms/VerticalLinearProgressCartoon';
import { RankingListCartoonNickname } from "~atoms/RankingListCartoonNickname";
import { numberWithDots } from "~utils/numberWithDots";
import { BalanceTrend } from "~molecules/BalanceTrend";
import { numberWithDotsAndComma } from "~utils/numberWithDotsAndComma";
import { NestedRankingListCartoon } from './components/NestedRankingListCartoon';
import { useStyles } from './RankingListCartoonItem.styles';
export var RankingListCartoonItem = function (_a) {
    var _b, _c, _d, _e;
    var itemIndex = _a.itemIndex, place = _a.place, level = _a.level, userName = _a.userName, avatar = _a.avatar, avatarUrl = _a.avatarUrl, stars = _a.stars, winAmount = _a.winAmount, _f = _a.currency, currency = _f === void 0 ? 'NONE' : _f, isLocked = _a.isLocked, currentUserRank = _a.currentUserRank, currentUserLevel = _a.currentUserLevel, progress = _a.progress, ranks = _a.ranks, count = _a.count, openedLevel = _a.openedLevel, trendBalance = _a.trendBalance, onItemListClick = _a.onItemListClick;
    var classes = useStyles();
    var t = useTranslation('tip-game').t;
    var backgrounds = [
        'linear-gradient(270deg, #00A5FF -94.93%, #0C104A 47.58%, #0C0F49 78.73%)',
        'linear-gradient(270deg, #31B2F8 -48.53%, #0C104A 50.51%, #0C0F49 102.45%)',
        'linear-gradient(270deg, #31B2F8 -47.74%, #0C104A 55.2%, #0C0F49 102.61%)',
        'linear-gradient(270deg, #31B2F8 -48.9%, #0C104A 62.47%, #0C0F49 103.43%)',
        'linear-gradient(270deg, #31B2F8 -48.9%, #0C104A 77.15%, #0C0F49 104.17%)',
        'linear-gradient(270deg, #31B2F8 -49.26%, #0C104A 94.57%, #0C0F49 103.43%)',
        'linear-gradient(270deg, #31B2F8 -34.53%, #0C0F49 103.06%, #0C104A 103.06%)',
        'linear-gradient(270deg, #31B2F8 -7.89%, #0C104A 99.69%, #0C0F49 100%)',
        'linear-gradient(270deg, #31B2F8 -0.7%, #224A95 76.39%, #0C104A 78.73%, #0C0F49 78.73%)',
        'linear-gradient(270deg, #62C3F7 16%, #224A95 78.73%, #0C104A 78.73%, #0C0F49 78.73%)'
    ];
    var getBackground = function () {
        var index = itemIndex < backgrounds.length ? itemIndex : backgrounds.length - 1;
        return backgrounds[index];
    };
    var isNestedListOpen = openedLevel === level;
    var isCurrentUser = currentUserLevel && currentUserLevel === level;
    var checkIfNarrowScreen = function (maxWith) { return window.innerWidth < maxWith; };
    var textColor = isCurrentUser ?
        classes.greenText
        : place === 'first'
            ? classes.goldText
            : place === 'second'
                ? classes.silverText
                : place === 'third'
                    ? classes.bronzeText
                    : classes.whiteText;
    var maxBalance = (ranks === null || ranks === void 0 ? void 0 : ranks.length) && ((_b = ranks[0]) === null || _b === void 0 ? void 0 : _b.balance) ?
        ranks[0].balance
        : (ranks === null || ranks === void 0 ? void 0 : ranks.length) && ((_c = ranks[ranks.length - 1]) === null || _c === void 0 ? void 0 : _c.balance)
            ? '0.00'
            : null;
    var minBalance = (ranks === null || ranks === void 0 ? void 0 : ranks.length) && ((_d = ranks[ranks.length - 1]) === null || _d === void 0 ? void 0 : _d.balance) ?
        ranks[ranks.length - 1].balance
        : (ranks === null || ranks === void 0 ? void 0 : ranks.length) && ((_e = ranks[0]) === null || _e === void 0 ? void 0 : _e.balance)
            ? '0.00'
            : null;
    var currencyImg = !winAmount
        ? null
        : currency === 'CRD'
            ? React.createElement("img", { src: CSCoin, alt: 'coin' })
            : currency
                ? React.createElement(Currency, { currency: currency })
                : null;
    var onItemClick = function (level) {
        if (!(ranks === null || ranks === void 0 ? void 0 : ranks.length))
            return;
        var activeLevel = openedLevel === level ? null : level;
        onItemListClick(activeLevel);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { role: "button", tabIndex: 0, className: classNames(classes.wrapper, isCurrentUser && classes.greenBorder, (ranks === null || ranks === void 0 ? void 0 : ranks.length) && !isLocked && classes.pointer, isLocked && classes.lockedItem), style: { background: "".concat(getBackground()) }, onClick: function () { return onItemClick(level); } },
            React.createElement("div", { className: classes.blockWrapper },
                React.createElement(RankingListCartoonNickname, { nickname: userName, stars: stars, avatar: avatar, avatarUrl: avatarUrl }),
                React.createElement("div", { className: classes.centerBlock },
                    React.createElement("div", { className: classes.level },
                        React.createElement("div", { className: textColor },
                            React.createElement("span", null, t('level')),
                            React.createElement("span", null, level)),
                        isCurrentUser && trendBalance ? React.createElement(BalanceTrend, { balance: numberWithDotsAndComma(trendBalance) }) : null),
                    React.createElement("div", { className: classes.divider }),
                    React.createElement("div", { className: classes.credits },
                        React.createElement("span", null, t('ranking-list.bonus')),
                        React.createElement("span", { className: classes.credits },
                            count ? (React.createElement("div", { className: classes.jackpotCount },
                                count,
                                " x")) : null,
                            winAmount ? numberWithDots(winAmount, checkIfNarrowScreen(435)) : null,
                            currencyImg)),
                    React.createElement("div", { className: classes.divider }),
                    React.createElement("div", { className: classes.balance },
                        maxBalance ? (React.createElement("div", null,
                            React.createElement("span", null, checkIfNarrowScreen(375) ? t('ranking-list.max') : t('ranking-list.max_balance')),
                            React.createElement("span", null,
                                maxBalance,
                                React.createElement(CSIcon, { size: 'sm' })))) : null,
                        minBalance ? (React.createElement("div", null,
                            React.createElement("span", null, checkIfNarrowScreen(375) ? t('ranking-list.min') : t('ranking-list.min_balance')),
                            React.createElement("span", null,
                                minBalance,
                                React.createElement(CSIcon, { size: 'sm' })))) : null))),
            React.createElement("div", { className: classes.rightBlock },
                React.createElement("div", { className: classes.rightBlockBackground }),
                React.createElement("div", { className: classes.rightBlockWrapper },
                    React.createElement("div", { className: classes.progressWrapper },
                        React.createElement(VerticalLinearProgressCartoon, { progress: progress })),
                    isLocked ? (React.createElement("img", { src: locked, alt: 'locked' })) : (React.createElement("img", { className: classes.unlocked, src: unlocked, alt: 'unlocked' }))))),
        (ranks === null || ranks === void 0 ? void 0 : ranks.length) && !isLocked ? (React.createElement(Collapse, { in: isNestedListOpen, timeout: "auto", unmountOnExit: true },
            React.createElement(NestedRankingListCartoon, { rankingList: ranks, currentUserRank: currentUserRank, trendBalance: trendBalance }))) : null));
};
