import React from 'react';
import { CSIcon } from '~icons/rankingList/CSIcon';
import { numberWithDotsAndComma } from '~utils/numberWithDotsAndComma';
import { useStyles } from './BalanceInputLabel.styles';
export var BalanceInputLabel = function (_a) {
    var currency = _a.currency, value = _a.value;
    var classes = useStyles();
    var valueWithComa = numberWithDotsAndComma(value);
    return (React.createElement("div", { className: classes.wrapper },
        valueWithComa,
        React.createElement("div", { className: classes.divider }),
        currency === 'CRD' ? React.createElement(CSIcon, { size: "md" }) : currency));
};
