import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import { useAppDispatch } from '~store';
import { currencyConverter } from '~utils/converters';
import { saveProviderId } from '~slices/top-ranking';
import { useStyles } from '../Tip.styles';
export var PayoutStatusBtn = function (_a) {
    var providerId = _a.providerId, ticketId = _a.ticketId, cashout = _a.cashout, currency = _a.currency;
    var t = useTranslation('ticket').t;
    var classes = useStyles();
    var dispatch = useAppDispatch();
    var convertedAmount = currencyConverter(cashout, currency);
    var redirectToTicket = function () {
        dispatch(saveProviderId({ providerId: providerId, template: 'open-ticket', ticketId: ticketId }));
    };
    return (React.createElement("div", { className: classes.btnWrapper },
        React.createElement(Button, { fullWidth: true, variant: "contained", color: "primary", onClick: redirectToTicket },
            t('cash_out'),
            ": ",
            convertedAmount)));
};
