import makeStyles from '@material-ui/core/styles/makeStyles';
import { ZIndex } from '~constants/view/ZIndex';
export var useStyles = makeStyles(function (theme) { return ({
    loaderWrapper: {
        width: '100%',
        height: '100%',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: ZIndex.LOADER,
        opacity: 0.85,
        background: '#151515',
    },
    dots: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
    },
    smallDots: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    dot: {
        minWidth: '8px',
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        background: '#eee',
        margin: '2px',
    },
    firstDot: {
        animation: "$dot1 1.4s infinite both",
    },
    secondDot: {
        animation: "$dot1 1.4s infinite both",
        animationDelay: '0.2s',
    },
    thirdDot: {
        animation: "$dot1 1.4s infinite both",
        animationDelay: '0.4s',
    },
    '@keyframes dot1': {
        '0%': {
            background: '#d4ecfd',
        },
        '50%': {
            background: '#2AA1F7',
        },
        '100%': {
            background: '#d4ecfd',
        },
    },
}); }, { name: 'Loader' });
