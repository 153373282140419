var getLocalStorageInputName = function (name) {
    return "local_storage_fields.".concat(name);
};
var saveToStorage = function (key, value) {
    localStorage.setItem(key, value);
};
var setToStorageWithLifetime = function (key, value, minutes) {
    var expirationTimestamp = Date.now() + minutes * 60000;
    var item = { value: value, lifetime: expirationTimestamp };
    localStorage.setItem(key, JSON.stringify(item));
};
var getFromStorageWithLifetime = function (key) {
    var item = JSON.parse(localStorage.getItem(key));
    if (item && item.lifetime && item.lifetime > Date.now()) {
        return item.value;
    }
    // Item has expired or doesn't exist
    return null;
};
var loadFromStorage = function (key) {
    return localStorage.getItem(key);
};
var removeFromStorage = function (key) {
    localStorage.removeItem(key);
};
export { getLocalStorageInputName, saveToStorage, setToStorageWithLifetime, getFromStorageWithLifetime, loadFromStorage, removeFromStorage, };
