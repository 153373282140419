import { name } from './constants';
export var accountSelector = function (state) { return state[name]; };
export var loadingSelector = function (state) {
    return accountSelector(state).loading;
};
export var errorSelector = function (state) {
    return accountSelector(state).error;
};
export var checkPasswordSelector = function (state) {
    return accountSelector(state).meta.invalidPassword;
};
export var accountInfoSelector = function (state) {
    return accountSelector(state).data;
};
