import makeStyles from '@material-ui/core/styles/makeStyles';
var useStyles = makeStyles(function () { return ({
    container: {
        position: 'relative',
        display: 'inline-flex',
        flexDirection: 'column',
        paddingTop: '11px',
    },
    arrow: {
        display: 'block',
        width: '52px',
        height: '31px',
        animationName: '$fade',
        animationDuration: '1000ms',
        animationIterationCount: 'infinite',
        animationDirection: 'normal',
        marginTop: '-11px',
    },
    '@keyframes fade': {
        '0%': {
            opacity: 0.2,
        },
        '20%': {
            opacity: 1,
        },
        '100%': {
            opacity: 0.2,
        },
    },
}); }, { name: 'Arrows' });
export { useStyles };
