var _a;
import React, { useState } from 'react';
import classNames from 'classnames';
import { useIsDesktop, useValueChange } from '~hooks';
import triangleIncrease from '~molecules/odd/icons/triangle-increase.svg';
import triangleDecrease from '~molecules/odd/icons/triangle-decrease.svg';
import { normalizeOddOutput } from '~utils/normalizeNumberOutput';
import useStyles from './Event.styles';
var Indicators;
(function (Indicators) {
    Indicators["INCREASED"] = "INCREASED";
    Indicators["DECREASED"] = "DECREASED";
    Indicators["NONE"] = "NONE";
})(Indicators || (Indicators = {}));
var srcMap = (_a = {},
    _a[Indicators.INCREASED] = triangleIncrease,
    _a[Indicators.DECREASED] = triangleDecrease,
    _a);
export var EventOdd = function (_a) {
    var _b, _c, _d;
    var odd = _a.odd, ticketDetails = _a.ticketDetails, onClick = _a.onClick;
    var classes = useStyles();
    var isDesktop = useIsDesktop();
    var _e = useState(Indicators.NONE), indicatorToShow = _e[0], setIndicatorToShow = _e[1];
    var value = odd || 0;
    useValueChange(function (currentValue, prevValue) {
        setIndicatorToShow(currentValue > prevValue ? Indicators.INCREASED : Indicators.DECREASED);
        var tId = setTimeout(function () {
            setIndicatorToShow(Indicators.NONE);
        }, 3000);
        return function () {
            clearTimeout(tId);
        };
    }, value);
    var classesMap = (_b = {},
        _b[Indicators.INCREASED] = classes.triangleIncrease,
        _b[Indicators.DECREASED] = classes.triangleDecrease,
        _b);
    return (React.createElement("div", { onClick: onClick, tabIndex: 0, role: "button", className: classNames(classes.oddValue, (_c = {},
            _c[classes.oddValueDesktop] = isDesktop,
            _c)) },
        indicatorToShow !== Indicators.NONE && !ticketDetails && (React.createElement("img", { alt: "triangle", className: classesMap[indicatorToShow], src: srcMap[indicatorToShow] })),
        ticketDetails ? (React.createElement("i", { className: classNames((_d = {
                    'fas fa-chart-line': [
                        Indicators.NONE,
                        Indicators.INCREASED,
                    ].includes(indicatorToShow),
                    'far fa-chart-line-down': indicatorToShow === Indicators.DECREASED
                },
                _d[classes.trendIconHigher] = indicatorToShow === Indicators.INCREASED,
                _d[classes.trendIconLower] = indicatorToShow === Indicators.DECREASED,
                _d[classes.trendIconEqual] = indicatorToShow === Indicators.NONE,
                _d)) })) : (normalizeOddOutput(Number(value)))));
};
