var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import cn from 'classnames';
import i18next from 'i18next';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import FormGroup from '@material-ui/core/FormGroup';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Button } from '~atoms/button';
import { useAppDispatch } from '~store';
import { setLanguage } from '~slices/options';
import { useStructuredSelector } from '~hooks';
import { isAuthenticatedSelector } from '~slices/auth';
import { getTestId } from '~utils/getTestId';
import { setLanguageCodeInURL } from '~utils/languageInURL';
import { CDN_BETCHECK_PATH } from '~mainGrid/drawer/Languages';
var languages = [
    {
        id: 'en',
        icon: "https://".concat(CDN_BETCHECK_PATH, "/flags/category/country/gbr.png"),
        name: 'English',
    },
    {
        id: 'de',
        icon: "https://".concat(CDN_BETCHECK_PATH, "/flags/category/country/deu.png"),
        name: 'Deutsch',
    },
    {
        id: 'pl',
        icon: "https://".concat(CDN_BETCHECK_PATH, "/flags/category/country/pol.png"),
        name: 'Polski',
    },
    {
        id: 'uk',
        icon: "https://".concat(CDN_BETCHECK_PATH, "/flags/category/country/ukr.png"),
        name: 'Українська',
    },
    {
        id: 'nl',
        icon: "https://".concat(CDN_BETCHECK_PATH, "/flags/category/country/nld.png"),
        name: 'Dutch',
    },
    {
        id: 'es',
        icon: "https://".concat(CDN_BETCHECK_PATH, "/flags/category/country/esp.png"),
        name: 'Español',
    },
    {
        id: 'fr',
        icon: "https://".concat(CDN_BETCHECK_PATH, "/flags/category/country/fra.png"),
        name: 'French',
    },
    {
        id: 'ru',
        icon: "https://".concat(CDN_BETCHECK_PATH, "/flags/category/country/rus.png"),
        name: 'Русский',
    },
    {
        id: 'tr',
        icon: "https://".concat(CDN_BETCHECK_PATH, "/flags/category/country/tur.png"),
        name: 'Turkish',
    },
];
var useStyles = makeStyles({
    systemMultiselect: {
        paddingRight: '15px',
        '~media (min-width:370px)': {
            paddingRight: '37px',
        },
    },
    dropdownButton: {
        border: '1px solid #FFFFFF',
        '& i': {
            color: 'white',
            fontSize: '14px !important',
        },
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        color: '#fff',
        width: '100%',
        textAlign: 'start',
        padding: '8px 0 8px 25px',
        fontSize: '14px',
        lineHeight: '20px',
        backgroundColor: 'transparent',
        border: 'none',
        outline: 'none',
    },
    icon: {
        marginRight: '23px',
        width: '16px',
        height: '12px',
        borderRadius: '2px',
    },
    mainIcon: {
        marginRight: '23px',
        width: '29px',
        height: '18px',
        borderRadius: '2px',
    },
    whiteFlag: {
        background: '#FFF',
    },
    label: {
        fontWeight: 'normal',
    },
    activeLanguage: {
        color: '#2AA1F7',
    },
    activeButton: {
        borderTop: '1px solid #444444',
        borderBottom: '1px solid #444444',
    },
    systemModal: {
        marginTop: '30px',
        color: '#fff',
        position: 'relative',
        background: 'radial-gradient(102.51% 128.71% at 20.97% 0%, #303038 0%, #1B1B22 100%)',
        '&::before': {
            content: '""',
            position: 'absolute',
            right: '20px',
            top: '-16px',
            borderBottom: '8px solid #212128',
            border: '8px solid transparent',
        },
    },
    group: {
        width: '200px',
        padding: '10px 0',
    },
}, { name: 'LanguageSelector' });
export var LanguageSelector = function () {
    var classes = useStyles();
    var dispatch = useAppDispatch();
    var _a = React.useState(false), open = _a[0], setOpen = _a[1];
    var activeLanguage = languages.find(function (lang) { return lang.id === i18next.language; });
    var anchorRef = React.useRef(null);
    var isAuthenticated = useStructuredSelector({
        isAuthenticated: isAuthenticatedSelector,
    }).isAuthenticated;
    var handleClose = function (event) {
        if (anchorRef.current &&
            anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    var onLanguageClick = function (newLanguage) {
        setLanguageCodeInURL(i18next.language, newLanguage);
        i18next.changeLanguage(newLanguage);
        try {
            var language = newLanguage === 'uk' ? 'ukr' : newLanguage;
            window.SIR('changeLanguage', language);
        }
        catch (e) {
            console.error('SIR Error:', e);
        }
        if (isAuthenticated) {
            dispatch(setLanguage());
        }
        setOpen(false);
    };
    var handleToggle = function () {
        setOpen(function (prevOpen) { return !prevOpen; });
    };
    return (React.createElement("div", null,
        React.createElement(Button, { ref: anchorRef, "aria-controls": open ? 'menu-list-grow' : undefined, "aria-haspopup": "true", "data-testid": getTestId('BTN_LANGUAGE'), onClick: handleToggle, className: classes.dropdownButton },
            (activeLanguage === null || activeLanguage === void 0 ? void 0 : activeLanguage.id) === 'ru' ? (React.createElement("div", { className: cn(classes.mainIcon, classes.whiteFlag) })) : (React.createElement("img", { src: activeLanguage === null || activeLanguage === void 0 ? void 0 : activeLanguage.icon, alt: activeLanguage === null || activeLanguage === void 0 ? void 0 : activeLanguage.name, className: classes.mainIcon })),
            open ? (React.createElement("i", { className: "far fa-chevron-up" })) : (React.createElement("i", { className: "far fa-chevron-down" }))),
        React.createElement(Popper, { open: open, anchorEl: anchorRef.current, placement: "bottom-end", role: undefined, transition: true, disablePortal: true, style: {
                zIndex: 1,
            } }, function (_a) {
            var TransitionProps = _a.TransitionProps, placement = _a.placement;
            return (React.createElement(Grow, __assign({}, TransitionProps, { style: {
                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                } }),
                React.createElement(Paper, { className: classes.systemModal },
                    React.createElement(ClickAwayListener, { onClickAway: handleClose },
                        React.createElement(MenuList, { autoFocusItem: open, disablePadding: true, id: "menu-list-grow" },
                            React.createElement(FormGroup, { className: classes.group }, languages.map(function (language) {
                                var _a, _b;
                                return (React.createElement("button", { type: "button", key: language.id, onClick: function () { return onLanguageClick(language.id); }, className: cn((_a = {},
                                        _a[classes.button] = true,
                                        _a[classes.activeButton] = i18next.language === language.id,
                                        _a)) },
                                    language.id === 'ru' ? (React.createElement("div", { className: cn(classes.icon, classes.whiteFlag) })) : (React.createElement("img", { src: language.icon, alt: language.name, className: classes.icon })),
                                    React.createElement("span", { className: cn((_b = {},
                                            _b[classes.label] = true,
                                            _b[classes.activeLanguage] = i18next.language === language.id,
                                            _b)) }, language.name)));
                            })))))));
        })));
};
