// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable max-lines */
import makeStyles from '@material-ui/core/styles/makeStyles';
export var useGroupStyles = makeStyles(function (theme) {
    var _a, _b;
    return (_a = {
            groupHeader: (_b = {
                    background: '#323232',
                    color: '#8E8E8E',
                    display: 'flex',
                    fontSize: '10px',
                    lineHeight: '12px',
                    padding: '6px 16px',
                    justifyContent: 'flex-end',
                    minHeight: '32px',
                    boxSizing: 'border-box'
                },
                _b[theme.breakpoints.up('lg')] = {
                    fontSize: 14,
                    lineHeight: '16px',
                    padding: '8px 16px',
                },
                _b),
            groupName: {
                color: '#fff',
            },
            outcomes: {
                marginLeft: '4px',
                display: 'flex',
                justifyContent: 'center',
            },
            group: {
                marginBottom: '27px',
            }
        },
        _a[theme.breakpoints.up('lg')] = {
            group: {
                '&:last-child': {
                    marginBottom: 0,
                },
            },
        },
        _a);
}, { name: 'Group' });
export var useCompetitorStyles = makeStyles(function (theme) {
    var _a;
    return ({
        liveLabel: {
            height: '20px',
            padding: '4px',
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '12px',
        },
        competitorsContainer: (_a = {
                display: 'flex',
                alignItems: 'center',
                padding: '3px 0px',
                height: '36px',
                background: 'radial-gradient(163.2% 163.24% at 50.13% 0%, #303038 0%, #1B1B22 100%)'
            },
            _a[theme.breakpoints.up('lg')] = {
                background: '#151515',
            },
            _a.color = '#fff',
            _a),
        namesContainer: {
            padding: '0 8px 0 16px',
            boxSizing: 'border-box',
            display: 'grid',
            alignItems: 'center',
        },
        teamName: {
            fontSize: '12px',
            lineHeight: '14px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        liveIcon: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: '8px',
        },
        betstop: {
            marginLeft: '8px',
            fontSize: '10px',
            color: '#FF0000',
        },
        liveStatusContainer: {
            display: 'grid',
            gridTemplateColumns: 'max-content max-content',
            alignItems: 'center',
            gridGap: '8px',
        },
        time: {
            fontSize: '10px',
            lineHeight: '12px',
            color: '#2AA1F7',
            marginLeft: '4px',
            alignSelf: 'center',
        },
        date: {
            marginLeft: 'auto',
            justifySelf: 'end',
            alignSelf: 'center',
            textAlign: 'right',
            fontSize: '10px',
            lineHeight: '14px',
            gridColumnStart: 4,
            paddingRight: '16px',
            '&.isTennis': {
                paddingRight: '5px',
            },
        },
        dateContainer: {
            gridColumnStart: 4,
        },
        scoreBlock: {
            marginLeft: 'auto',
            position: 'relative',
            display: 'grid',
            gridTemplateColumns: 'max-content max-content',
            gridColumnStart: 4,
            justifyContent: 'end',
            paddingRight: '16px',
            '&.isTennis': {
                paddingRight: '5px',
            },
        },
        cards: {
            display: 'grid',
            gridGap: '1px',
            gridTemplateRows: '15px 15px',
            alignContent: 'center',
        },
        score: {
            display: 'grid',
            gridAutoRows: 'max-content',
            gridGap: '4px',
            alignContent: 'center',
        },
        card: {
            width: '11px',
            height: '15px',
            fontWeight: 'bold',
            fontSize: '8px',
            lineHeight: '9px',
            borderRadius: '1px',
            display: 'flex',
            marginRight: '3px',
            justifyContent: 'center',
            alignItems: 'center',
        },
        yellowCard: {
            color: '#212121',
            background: '#FFEB3B',
        },
        redCard: {
            color: '#ffffff',
            background: '#FF0000',
        },
        competitorScore: {
            fontSize: '12px',
            lineHeight: '12px',
            display: 'flex',
            width: '13px',
            color: '#8E8E8E',
            justifyContent: 'center',
            fontStyle: 'normal',
            fontWeight: 'normal',
            paddingRight: '4px',
        },
        competitorScoreResult: {
            color: '#fff',
            paddingLeft: 4,
            '&:last-child': {
                borderLeft: '1px solid rgba(255,255,255, 0.2)',
            },
            '&.isTennis': {
                borderLeft: '1px solid rgba(255,255,255, 0.2)',
            },
        },
        lastScore: {
            borderLeft: '1px solid rgba(255,255,255, 0.2)',
        },
        shadowColor: {
            color: '#8E8E8E',
        },
        competitorTennisScore: {
            fontSize: '8px',
            width: '10px',
        },
        noSr: {
            width: '6px',
            height: '6px',
            borderRadius: '50%',
            '&.small': {
                width: '3px',
                height: '3px',
                marginRight: '4px',
            },
        },
        sr: {
            backgroundColor: '#F9DC1C',
        },
        '@keyframes goalPaneMoving': {
            '6%': {
                transform: 'scaleX(1) translateY(-50%)',
            },
            '60%': {
                transform: 'scaleX(1) translateY(-50%)',
            },
            '66%': {
                transform: 'scaleX(0) translateY(-50%)',
            },
        },
        '@keyframes nogoalPaneMoving': {
            '6%': {
                transform: 'scaleX(1) translateY(-50%)',
            },
            '60%': {
                transform: 'scaleX(1) translateY(-50%)',
            },
            '66%': {
                transform: 'scaleX(0) translateY(-50%)',
            },
        },
        '@keyframes nocardPaneMoving': {
            '5%': {
                transform: 'scaleX(1) translateY(-50%)',
            },
            '60%': {
                transform: 'scaleX(1) translateY(-50%)',
            },
            '95%': {
                transform: 'scaleX(1) translateY(-50%)',
            },
            '100%': {
                transform: 'scaleX(0) translateY(-50%)',
            },
        },
        '@keyframes textPaneBlinking': {
            '16%': {
                transform: 'scale(1)',
            },
            '26%': {
                transform: 'scale(1)',
            },
            '36%': {
                transform: 'scale(0.72)',
            },
            '46%': {
                transform: 'scale(1)',
            },
            '56%': {
                transform: 'scale(1)',
            },
            '66%': {
                transform: 'scale(0)',
            },
        },
        bsrWrapper: {
            position: 'relative',
            width: 0,
            maxWidth: 0,
            height: '100%',
        },
        bsr: {
            position: 'absolute',
            left: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            width: 48,
            bottom: 3,
            height: 20,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#FFFFFF',
            color: '#212121',
            borderRadius: 2,
        },
        goalPanel: {
            position: 'absolute',
            top: '50%',
            transform: 'scaleX(0) translateY(-50%)',
            bottom: 3,
            width: 48,
            height: 20,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#2AA1F7',
            borderRadius: 2,
            textAlign: 'center',
            animation: "$goalPaneMoving ".concat(process.env.GOAL_ANIMATION_FLASH_DURATION, "s linear ").concat(process.env.GOAL_ANIMATION_FLASHES_COUNT),
            transformOrigin: 'right',
        },
        goalPaneText: {
            fontSize: 8,
            lineHeight: 1,
            letterSpacing: '0.4em',
            textTransform: 'uppercase',
            transform: 'scale(0)',
            animation: "$textPaneBlinking ".concat(process.env.GOAL_ANIMATION_FLASH_DURATION, "s linear 3"),
        },
        nogoal: {
            animation: '$nogoalPaneMoving 5s linear 1',
            transform: 'scaleX(0) translateY(-50%)',
            transformOrigin: 'right',
        },
        nocard: {
            animation: '$nocardPaneMoving 5s linear 1',
            transform: 'scaleX(0) translateY(-50%)',
            transformOrigin: 'right',
        },
        bsrText: {
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontSize: '8px',
            textAlign: 'center',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            padding: '0 3px',
            '&.animation': {
                textTransform: 'uppercase',
                transform: 'scale(0)',
                animation: '$textPaneBlinking 3s linear 3',
            },
        },
        '@keyframes scoreBlinking': {
            '25%': {
                opacity: 0.5,
            },
            '50%': {
                opacity: 0,
            },
            '75%': {
                opacity: 0.5,
            },
        },
        competitorGoal: {
            animation: '$scoreBlinking 1s linear infinite',
        },
    });
}, { name: 'Competitor' });
export var useRowStyles = makeStyles({
    wideOdd: {
        width: '104px',
    },
    rows: {
        margin: '8px 16px 8px 0',
        display: 'flex',
        flexDirection: 'row',
    },
    rowField: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        borderBottom: '1px solid rgb(142,142,142, .4)',
    },
    rowName: {
        color: '#fff',
        fontSize: '12px',
        marginLeft: '16px',
        lineHeight: '18px',
    },
    goals: {
        width: '140px',
        background: '#262626',
        justifyContent: 'flex-start',
        paddingLeft: '16px',
        boxSizing: 'border-box',
        fontSize: '12px',
        lineHeight: '18px',
    },
    oddNames: {
        marginLeft: 'auto',
    },
    oddName: {
        display: 'flex',
        alignItems: 'center',
        height: '32px',
        color: '#ffffff',
        margin: '4px 0 4px 4px',
        width: '126px',
        fontSize: '12px',
        boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.05)',
        borderRadius: '4px',
        background: '#262626',
        padding: '8px',
    },
}, { name: 'Row' });
export var useStyles = makeStyles(function (theme) {
    var _a, _b, _c, _d, _e, _f, _g;
    return ({
        root: (_a = {
                paddingLeft: '16px'
            },
            _a[theme.breakpoints.up('lg')] = {
                paddingLeft: 0,
            },
            _a),
        textColorInherit: {
            opacity: 1,
            color: '#b8b8b8',
        },
        tab: (_b = {
                background: '#151515',
                paddingLeft: '8px'
            },
            _b[theme.breakpoints.up('lg')] = {
                paddingLeft: 0,
            },
            _b),
        flex: {
            display: 'flex',
        },
        fullWidth: {
            width: '100%',
        },
        tabItem: {
            color: '#8E8E8E',
            paddingLeft: '16px',
            fontSize: '10px',
            lineHeight: '12px',
        },
        button: {
            width: 'fit-content',
            height: '30px',
            background: '#FFFFFF',
            boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.05)',
            borderRadius: '4px',
            border: 'none',
            outline: 'none',
            padding: '7px 16px 7px 12px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        betStop: {
            color: 'red',
            marginLeft: '8px',
        },
        srWidgetContainer: (_c = {
                height: '61vw',
                overflow: 'hidden'
            },
            _c[theme.breakpoints.up('lg')] = {
                height: 'auto',
            },
            _c),
        sidebetsAlign: (_d = {},
            _d[theme.breakpoints.up('lg')] = {
                display: 'flex',
                alignItems: 'center',
            },
            _d),
        tabsWrapper: {},
        widgetWrapper: (_e = {
                width: '100%'
            },
            _e[theme.breakpoints.up('lg')] = {
                width: 'auto',
                flexGrow: 1,
            },
            _e),
        tabText: (_f = {
                fontSize: 8
            },
            _f[theme.breakpoints.up('lg')] = {
                fontSize: 10,
                height: '64px',
                position: 'relative',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    border: '1px solid #8E8E8E',
                    width: '100%',
                    left: 0,
                    bottom: 0,
                },
            },
            _f),
        unaviableWidget: (_g = {
                height: '61vw',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
            },
            _g[theme.breakpoints.up('lg')] = {
                height: '100%',
                position: 'relative',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    backgroundColor: '#1515151f',
                    left: 0,
                    right: 0,
                    bottom: 0,
                    top: 0,
                },
            },
            _g),
    });
}, { name: 'Sidebets' });
