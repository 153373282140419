import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var SnookerIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M27.2407 21.6962L16.1326 3.67495C15.9083 3.31089 15.5945 3.01032 15.2212 2.80183C14.8478 2.59334 14.4273 2.48389 13.9997 2.48389C13.5721 2.48389 13.1517 2.59334 12.7783 2.80183C12.405 3.01032 12.0912 3.31089 11.8669 3.67495L0.759243 21.6962C0.525304 22.0758 0.396905 22.511 0.3873 22.9567C0.377695 23.4025 0.487232 23.8428 0.704603 24.2321C0.921975 24.6214 1.2393 24.9456 1.62384 25.1713C2.00837 25.397 2.44617 25.516 2.89206 25.516H25.1079C25.5538 25.516 25.9916 25.397 26.3761 25.1713C26.7606 24.9456 27.0779 24.6214 27.2953 24.2321C27.5127 23.8428 27.6222 23.4025 27.6126 22.9567C27.603 22.511 27.4746 22.0758 27.2407 21.6962ZM25.7674 23.3789C25.704 23.4981 25.6088 23.5973 25.4924 23.6657C25.376 23.7341 25.2429 23.7688 25.1079 23.766H2.89206C2.75762 23.766 2.62563 23.7301 2.50971 23.6621C2.39378 23.594 2.29812 23.4962 2.23259 23.3789C2.16707 23.2615 2.13406 23.1287 2.13697 22.9943C2.13988 22.8599 2.17861 22.7288 2.24915 22.6143L13.3571 4.5937C13.4247 4.48406 13.5193 4.39355 13.6318 4.33077C13.7443 4.26799 13.871 4.23504 13.9999 4.23504C14.1287 4.23504 14.2554 4.26799 14.3679 4.33077C14.4804 4.39355 14.575 4.48406 14.6427 4.5937L25.7508 22.6152C25.8238 22.7285 25.864 22.8599 25.8669 22.9947C25.8698 23.1295 25.8354 23.2624 25.7674 23.3789Z", fill: "#E0E0E2" }),
        React.createElement("path", { d: "M14.0001 12.0173C14.9742 12.0173 15.7639 11.2276 15.7639 10.2535C15.7639 9.27942 14.9742 8.48975 14.0001 8.48975C13.026 8.48975 12.2363 9.27942 12.2363 10.2535C12.2363 11.2276 13.026 12.0173 14.0001 12.0173Z", fill: "#8EEF5B" }),
        React.createElement("path", { d: "M11.8107 16.8584C12.7848 16.8584 13.5744 16.0687 13.5744 15.0946C13.5744 14.1205 12.7848 13.3308 11.8107 13.3308C10.8365 13.3308 10.0469 14.1205 10.0469 15.0946C10.0469 16.0687 10.8365 16.8584 11.8107 16.8584Z", fill: "#4FABF7" }),
        React.createElement("path", { d: "M11.8107 16.8584C12.7848 16.8584 13.5744 16.0687 13.5744 15.0946C13.5744 14.1205 12.7848 13.3308 11.8107 13.3308C10.8365 13.3308 10.0469 14.1205 10.0469 15.0946C10.0469 16.0687 10.8365 16.8584 11.8107 16.8584Z", fill: "#4FABF7" }),
        React.createElement("path", { d: "M16.5277 16.8582C17.5017 16.8582 18.2913 16.0686 18.2913 15.0946C18.2913 14.1206 17.5017 13.3311 16.5277 13.3311C15.5537 13.3311 14.7642 14.1206 14.7642 15.0946C14.7642 16.0686 15.5537 16.8582 16.5277 16.8582Z", fill: "#4FABF7" }),
        React.createElement("path", { d: "M9.28309 21.699C10.2571 21.699 11.0467 20.9094 11.0467 19.9354C11.0467 18.9614 10.2571 18.1719 9.28309 18.1719C8.30911 18.1719 7.51953 18.9614 7.51953 19.9354C7.51953 20.9094 8.30911 21.699 9.28309 21.699Z", fill: "#FFC839" }),
        React.createElement("path", { d: "M9.28309 21.699C10.2571 21.699 11.0467 20.9094 11.0467 19.9354C11.0467 18.9614 10.2571 18.1719 9.28309 18.1719C8.30911 18.1719 7.51953 18.9614 7.51953 19.9354C7.51953 20.9094 8.30911 21.699 9.28309 21.699Z", fill: "#FFC839" }),
        React.createElement("path", { d: "M14.0001 21.6992C14.9742 21.6992 15.7639 20.9095 15.7639 19.9354C15.7639 18.9613 14.9742 18.1716 14.0001 18.1716C13.026 18.1716 12.2363 18.9613 12.2363 19.9354C12.2363 20.9095 13.026 21.6992 14.0001 21.6992Z", fill: "#FFC839" }),
        React.createElement("path", { d: "M18.7172 21.699C19.6912 21.699 20.4807 20.9094 20.4807 19.9354C20.4807 18.9614 19.6912 18.1719 18.7172 18.1719C17.7432 18.1719 16.9536 18.9614 16.9536 19.9354C16.9536 20.9094 17.7432 21.699 18.7172 21.699Z", fill: "#FFC839" })));
};
