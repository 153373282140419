import { useState } from 'react';
import { getLocalStorageInputName, loadFromStorage, saveToStorage, } from '~utils/localStorage';
export var LocalStorageField = function (_a) {
    var name = _a.name, children = _a.children;
    var storageKey = getLocalStorageInputName(name);
    var _b = useState(loadFromStorage(storageKey) || ''), value = _b[0], setValue = _b[1];
    var onChange = function (v) {
        saveToStorage(storageKey, v);
        setValue(v);
    };
    return children(value, onChange);
};
