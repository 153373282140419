import React from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { useIsDesktop } from '~hooks';
import { Button } from '~atoms/button';
import { closeModal } from '../modalSlice';
import { useModalStyles } from './Modal.styles';
export var ConfirmationModal = function (_a) {
    var _b, _c, _d;
    var modalName = _a.modalName, _e = _a.isVisible, isVisible = _e === void 0 ? false : _e, _f = _a.modalProps, message = _f.message, handleConfirm = _f.handleConfirm, dialogClassName = _f.dialogClassName, rootClassName = _f.rootClassName, hideBackdrop = _f.hideBackdrop, title = _f.title, titleClassName = _f.titleClassName, closeButtonLabel = _f.closeButtonLabel, confirmButtonLabel = _f.confirmButtonLabel, variant = _f.variant, _g = _f.cancelVariant, cancelVariant = _g === void 0 ? 'outlined' : _g, fullWidth = _f.fullWidth, hugeButton = _f.hugeButton, _h = _f.cancelColor, cancelColor = _h === void 0 ? 'secondary' : _h, _j = _f.columnDialog, columnDialog = _j === void 0 ? true : _j, closable = _f.closable, children = _a.children;
    var dispatch = useDispatch();
    var modalClasses = useModalStyles();
    var isDesktop = useIsDesktop();
    var handleClose = function () {
        dispatch(closeModal({ key: modalName }));
    };
    return (React.createElement(Dialog, { open: isVisible, disableAutoFocus: true, disableBackdropClick: true, className: dialogClassName, hideBackdrop: hideBackdrop, classes: {
            paperWidthSm: dialogClassName,
            paper: modalClasses.shinyBack,
            root: rootClassName,
        } },
        (title || closable) && (React.createElement(DialogTitle, { disableTypography: true, className: titleClassName, classes: { root: modalClasses.title } },
            React.createElement("div", { className: classNames('flex-row', 'space-between', 'flex-center') },
                React.createElement("div", null, title),
                closable && (React.createElement(Button, { onClick: handleClose, className: classNames((_b = {}, _b[modalClasses.closeIcon] = isDesktop, _b)) },
                    React.createElement("i", { className: classNames('fas fa-times') })))))),
        React.createElement(DialogContent, { classes: { root: modalClasses.content } },
            message && React.createElement("div", null, message),
            children),
        React.createElement(DialogActions, { classes: {
                root: columnDialog
                    ? modalClasses.columnDialogAction
                    : modalClasses.dialogAction,
            } },
            confirmButtonLabel && (React.createElement(Button, { onClick: handleConfirm, color: "primary", fullWidth: fullWidth, variant: variant, classes: {
                    root: classNames(modalClasses.button, (_c = {},
                        _c[modalClasses.hugeButton] = hugeButton,
                        _c)),
                } }, confirmButtonLabel)),
            React.createElement(Button, { onClick: handleClose, fullWidth: fullWidth, size: "large", variant: cancelVariant, color: cancelColor, classes: {
                    root: classNames(modalClasses.button, (_d = {},
                        _d[modalClasses.hugeButton] = hugeButton,
                        _d[modalClasses.secondary] = cancelVariant === 'text',
                        _d)),
                } }, closeButtonLabel))));
};
