import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var BoxingIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, viewBox: "0 0 28 28" },
        React.createElement("path", { d: "M16.0781 22.8493V27.9996H18.9041C20.5078 27.9996 21.8127 26.6946 21.8127 25.091V20.0635C20.3566 21.5264 18.3548 22.4876 16.0781 22.8493Z", fill: "#FF0C38" }),
        React.createElement("path", { d: "M13.9998 23.0108C11.7227 23.0108 9.61817 22.4522 7.91504 21.417V25.0913C7.91504 26.6949 9.22001 27.9998 10.8236 27.9998H16.0775V22.8496C15.4064 22.9562 14.7116 23.0108 13.9998 23.0108Z", fill: "#FF3F62" }),
        React.createElement("path", { d: "M6.8912 8.06055C5.13993 8.06055 3.7207 9.48034 3.7207 11.2316V13.8631C3.7207 17.2474 5.35601 19.8471 7.8794 21.3953V8.06055H6.8912Z", fill: "#FF0C38" }),
        React.createElement("path", { d: "M24.279 8.06061C24.2032 3.59588 20.5604 0 16.0781 0V8.06061H24.279Z", fill: "#FF0C38" }),
        React.createElement("path", { d: "M16.0775 0C11.5953 0 7.9547 3.59644 7.87891 8.06061H16.0775V0Z", fill: "#FF3F62" }),
        React.createElement("path", { d: "M24.2796 8.20196C24.2796 8.15445 24.279 8.1075 24.2784 8.06055H7.87891V21.3953C7.8909 21.4027 7.90306 21.4098 7.91511 21.4171C9.61824 22.4523 11.7228 23.0108 13.9999 23.0108C14.7116 23.0108 15.4065 22.9563 16.0775 22.8496C18.3542 22.488 20.356 21.5267 21.8122 20.0638C23.3502 18.5186 24.2796 16.4138 24.2796 13.8631V8.20196Z", fill: "#C70024" })));
};
