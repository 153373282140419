import * as React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useIsDesktop } from '~hooks';
import { useStyles } from './Imprint.styles';
export var Imprint = function () {
    var classes = useStyles();
    var appVersion = process.env.REACT_APP_VERSION;
    var t = useTranslation('footer').t;
    var desktop = useIsDesktop();
    return (React.createElement("div", { className: classes.imprint },
        !desktop && (React.createElement("div", { className: classNames('flex-row', 'space-between', 'flex-center') },
            React.createElement("span", { className: classNames(classes.imprintText, classes.imprintHeaderText) }, t('about_us_imprint')))),
        React.createElement("div", { className: !desktop
                ? classNames(classes.imprintRow, classes.imprintRowBorder)
                : '' },
            React.createElement("span", { className: classNames(classes.imprintText, classes.imprintHeaderText) }, t('true_white_holding_gmbh'))),
        React.createElement("div", { className: desktop ? classes.imprintGrid : '' },
            React.createElement("div", { className: desktop ? classes.imprintCol : '' },
                React.createElement("div", { className: classNames(classes.imprintRow, classes.imprintRowBorder) },
                    React.createElement("div", { className: classNames('flex-row', 'space-between', 'flex-center') },
                        React.createElement("span", { className: classNames(classes.imprintText, classes.imprintHeaderText) }, t('address')),
                        React.createElement("i", { className: classNames('fas fa-map-marker-alt fa-fw', classes.icon) })),
                    React.createElement("span", { className: classes.imprintText }, t('address_text'))),
                React.createElement("div", { className: classes.imprintRow },
                    React.createElement("div", { className: classNames('flex-row', 'space-between', 'flex-center') },
                        React.createElement("span", { className: classNames(classes.imprintText, classes.imprintHeaderText) }, t('e_mail')),
                        React.createElement("i", { className: classNames('fas fa-envelope fa-fw', classes.icon) })),
                    React.createElement("span", { className: classes.imprintText }, t('e_mail_text')))),
            React.createElement("div", { className: desktop
                    ? classNames(classes.imprintCol, classes.imprintColFirst)
                    : '' },
                React.createElement("div", { className: classes.imprintRow },
                    React.createElement("div", { className: classNames('flex-row', 'space-between', 'flex-center') },
                        React.createElement("span", { className: classNames(classes.imprintText, classes.imprintHeaderText) }, t('phone')),
                        React.createElement("i", { className: classNames('fas fa-phone fa-fw', classes.icon) })),
                    React.createElement("span", { className: classes.imprintText }, t('phone_text'))),
                React.createElement("div", { className: classNames(classes.imprintRow, classes.imprintRowBorder) },
                    React.createElement("div", null,
                        React.createElement("span", { className: classes.imprintText }, t('tax_id'))),
                    React.createElement("div", null,
                        React.createElement("span", { className: classes.imprintText }, t('tax_id_text')))),
                React.createElement("div", { className: classNames(classes.imprintRow, classes.imprintRowBorder) },
                    React.createElement("div", null,
                        React.createElement("span", { className: classes.imprintText },
                            t('version'),
                            ": ",
                            appVersion)))))));
};
