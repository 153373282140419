import { useSelector } from 'react-redux';
import { name } from './constants';
export var termsSelector = function (state) { return state[name]; };
export var termsDataSelector = function (state) {
    return termsSelector(state).data;
};
export var termsLoadingSelector = function (state) {
    return termsSelector(state).loading;
};
export var termsIsPendingSelector = function (state) {
    return termsLoadingSelector(state) === 'pending';
};
export var termsDocumentSelector = function (state) {
    return termsDataSelector(state).document;
};
export var termsAcceptStatusSelectorDataSelector = function (state) {
    return termsDataSelector(state).termsAcceptStatus;
};
export var termsHaveUpdatesSelector = function (state) {
    return termsDataSelector(state).termsHaveUpdateStatus;
};
/* @deprecated use useStructuredSelector instead */
export var useTermsSelector = function () { return useSelector(termsSelector); };
/* @deprecated use useStructuredSelector instead */
export var useTermsDataSelector = function () { return useSelector(termsDataSelector); };
/* @deprecated use useStructuredSelector instead */
export var useTermsLoadingSelector = function () { return useSelector(termsLoadingSelector); };
/* @deprecated use useStructuredSelector instead */
export var useTermsIsPendingSelector = function () {
    return useSelector(termsIsPendingSelector);
};
/* @deprecated use useStructuredSelector instead */
export var useTermsHaveUpdateSelector = function () {
    return useSelector(termsHaveUpdatesSelector);
};
/* @deprecated use useStructuredSelector instead */
export var useTermsAcceptStatusSelector = function () {
    return useSelector(termsAcceptStatusSelectorDataSelector);
};
/* @deprecated use useStructuredSelector instead */
export var useTermsDocumentSelector = function () {
    return useSelector(termsDocumentSelector);
};
