import makeStyles from '@material-ui/core/styles/makeStyles';
var useStyles = makeStyles({
    container: {
        position: 'absolute',
        top: '3px',
        right: '3px',
        padding: '1px 4px',
        borderRadius: '2px',
        fontWeight: 500,
        fontSize: '7px',
        lineHeight: '8px',
        boxSizing: 'border-box',
    },
    live: {
        backgroundColor: '#2AA1F7',
        color: '#ffffff',
    },
    today: {
        border: '1px solid #000000',
        backgroundColor: '#ffffff',
        color: '#212121',
        padding: '0px 4px',
    },
    shifted: {
        top: '-3px',
        right: '-3px',
    },
}, { name: 'TournamentLabel' });
export { useStyles };
