import makeStyles from '@material-ui/core/styles/makeStyles';
export var useEditAccountStyles = makeStyles(function (theme) {
    var _a;
    return ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            padding: '32px 16px',
            color: '#ffffff',
            alignSelf: 'stretch',
            alignItems: 'stretch',
            flex: '1 1 100%',
        },
        title: {
            fontWeight: 300,
            fontSize: '20px',
            lineHeight: '23px',
            textAlign: 'center',
            color: '#f2f2f2',
            marginBottom: '24px',
        },
        flex: {
            flex: 1,
        },
        form: {
            width: '100%',
            alignSelf: 'stretch',
            alignItems: 'stretch',
            flex: '1 1 100%',
        },
        divider: (_a = {
                backgroundColor: '#8E8E8E',
                opacity: '0.2',
                marginBottom: '16px'
            },
            _a[theme.breakpoints.up('lg')] = {
                marginBottom: '24px',
            },
            _a),
        button: {
            minWidth: '120px',
            width: '100%',
        },
        nav: {
            backgroundColor: 'rgba(255, 255, 255, 0.15)',
            margin: '0 16px 16px 16px',
            width: 'unset',
            textAlign: 'center',
            color: '#fff',
            borderRadius: '4px',
        },
        text: {
            fontWeight: 'bold',
            fontSize: '16px',
        },
        closeButton: {
            alignSelf: 'center',
            padding: '9px 30px',
            height: 'unset',
            marginTop: '100px',
        },
        closeButtonLabel: {
            fontSize: '12px',
            lineHeight: '14px',
        },
        headerText: {
            fontSize: '20px',
            lineHeight: '23px',
            color: '#fff',
            margin: '36px 0 21px 16px',
            fontWeight: 300,
        },
        line: {
            backgroundColor: '#8E8E8E',
            opacity: 0.2,
            margin: '0 16px 16px',
            height: '1px',
        },
        marginBottom: {
            marginBottom: 16,
        },
        modalInputes: {
            background: '#F9F9F9',
            border: '1px solid #F2F2F2',
        },
        passTitle: {
            padding: '16px 24px',
            fontSize: '20px',
        },
        lastInput: {
            marginBottom: '16px',
        },
        actionsButtons: {
            padding: '0 24px 16px',
        },
        bold: {
            fontWeight: 'bold',
        },
    });
}, { name: 'MyAccount' });
