import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
var useStyles = makeStyles({
    soonLive: {
        fontSize: '8px',
        lineHeight: '9px',
        fontWeight: 'bold',
        color: '#2AA1F7',
        background: '#fff',
        padding: '2px 4px',
        borderRadius: '2px',
        textTransform: 'uppercase',
    },
}, { name: 'SoonLiveHeader' });
var SoonLiveHeader = function (_a) {
    var label = _a.label;
    var classes = useStyles();
    return React.createElement("div", { className: classes.soonLive }, label);
};
export { SoonLiveHeader };
