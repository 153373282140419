var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import makeStyles from '@material-ui/core/styles/makeStyles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import { MOBILE_SPORT_CARD_WIDTH, SPORT_CARD_WIDTH, } from '~constants/view/sizes';
var HEIGHT = 96;
var PADDING_TOP = 16;
var PADDING_BOTTOM = 4;
var ICON_SIZE = 30;
var shared = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '4px',
};
export var useStyles = makeStyles(function (theme) {
    var _a;
    return ({
        container: (_a = {
                position: 'relative',
                width: "".concat(MOBILE_SPORT_CARD_WIDTH, "px"),
                height: "".concat(HEIGHT, "px"),
                cursor: 'pointer',
                backgroundColor: '#151515',
                borderRadius: '4px'
            },
            _a[theme.breakpoints.up('lg')] = {
                width: "".concat(SPORT_CARD_WIDTH, "px"),
            },
            _a),
        status: __assign(__assign({}, shared), { display: 'none' }),
        content: __assign(__assign({}, shared), { padding: "".concat(PADDING_TOP, "px 8px ").concat(PADDING_BOTTOM, "px 8px"), boxSizing: 'border-box' }),
        active: {
            display: 'block',
            backgroundColor: alpha('#2AA1F7', 0.1),
        },
        icon: {
            width: "".concat(ICON_SIZE, "px"),
            maxWidth: "".concat(ICON_SIZE, "px"),
            minWidth: "".concat(ICON_SIZE, "px"),
            height: "".concat(ICON_SIZE, "px"),
            maxHeight: "".concat(ICON_SIZE, "px"),
            minHeight: "".concat(ICON_SIZE, "px"),
        },
        name: {
            width: '100%',
            height: "".concat(HEIGHT - PADDING_TOP - PADDING_BOTTOM - ICON_SIZE, "px"),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'end',
        },
    });
}, { name: 'SportCard' });
