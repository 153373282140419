import React, { useEffect, useState } from 'react';
import { push } from 'connected-react-router';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useStructuredSelector } from '~hooks';
import { betslipDataSelector } from '~slices/betslip';
import { getPathWithLanguageCode } from '~utils/languageInURL';
import { PaymentsURL } from '~pages/payments/payments.routes';
import { useAppDispatch } from '~store';
import { ShopBalance } from '~molecules/DepositTab/components/ShopBalance';
import { PaymentCode } from '~pages/payments/components/PaymentCode';
import { OnlineBalance } from '~molecules/DepositTab/components/OnlineBalance';
import { DepositStatus } from '~pages/payments/components/DepositStatus';
import { useStyles } from './DepositTab.styles';
export var DepositTab = function () {
    var _a;
    var dispatch = useAppDispatch();
    var pathname = useLocation().pathname;
    var classes = useStyles();
    var _b = useState('shop-balance'), activeCard = _b[0], setActiveCard = _b[1];
    // TODO, replace with real data from BE
    var betslip = useStructuredSelector({
        betslip: betslipDataSelector,
    }).betslip;
    var ticketOffer = betslip.to;
    var currency = (_a = ticketOffer === null || ticketOffer === void 0 ? void 0 : ticketOffer.cu) !== null && _a !== void 0 ? _a : 'EUR';
    useEffect(function () {
        var regex = /\/deposit\/([^\/]+)/;
        var match = pathname.match(regex);
        var cardName = match ? match[1] : '';
        if (cardName && cardName !== activeCard) {
            setActiveCard(cardName);
        }
    }, [pathname]);
    var onBalanceCardClick = function (type) {
        if (activeCard === type) {
            return;
        }
        var url = type === 'online-balance'
            ? PaymentsURL.DEPOSIT_ONLINE_BALANCE
            : PaymentsURL.DEPOSIT_SHOP_BALANCE;
        dispatch(push(getPathWithLanguageCode("/".concat(PaymentsURL.PAYMENTS, "/").concat(url))));
        setActiveCard(type);
    };
    return (React.createElement("div", { className: classes.container },
        React.createElement("div", { className: classes.balanceCardWrapper }),
        React.createElement(Switch, null,
            React.createElement(Route, { path: "/:lang/payments/deposit/shop-balance", component: ShopBalance, exact: true }),
            React.createElement(Route, { path: "/:lang/payments/deposit/online-balance", component: OnlineBalance, exact: true }),
            React.createElement(Route, { path: "/:lang/payments/deposit/shop-balance/payment-code", component: PaymentCode }),
            React.createElement(Route, { path: "/:lang/payments/deposit/shop-balance/status", component: DepositStatus }))));
};
