var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import classNames from 'classnames';
import './selectbox-styles.scss';
/* eslint-enable @typescript-eslint/no-explicit-any */
var ArrowDown = function (classes) { return (React.createElement("i", __assign({}, classes, { className: "far fa-chevron-down icon ".concat(classes.className) }))); };
// TODO refactor after rewriting registration to react-hook-form
function SelectBox(props) {
    var _a, _b, _c;
    return (React.createElement("div", { className: "select-box ".concat(props.selectBoxStyle ? props.selectBoxStyle : "") },
        React.createElement("div", { className: "select-box__title ".concat(props.selectBoxTitleStyle ? props.selectBoxTitleStyle : "") },
            props.title,
            " ",
            (props.required || props.showAsterisk) && '*'),
        React.createElement(Select, __assign({}, props, { fullWidth: true, value: props.value || '', label: !props.value && props.placeholder, MenuProps: __assign(__assign({}, props.MenuProps), { PaperProps: __assign(__assign({}, (_a = props.MenuProps) === null || _a === void 0 ? void 0 : _a.PaperProps), { className: classNames(props.menuListStyle, (_c = (_b = props.MenuProps) === null || _b === void 0 ? void 0 : _b.PaperProps) === null || _c === void 0 ? void 0 : _c.className) }) }), renderValue: props.renderValue ||
                (props.displayEmpty
                    ? function () {
                        return !props.value ? (React.createElement("div", { style: { color: '#8E8E8E' } }, props.placeholder)) : (props.value);
                    }
                    : undefined), IconComponent: function (prop) {
                // @ts-ignore
                return props.IconComponent ? props.IconComponent(prop) : ArrowDown(prop);
            }, variant: "outlined" }), props.options.map(function (option) {
            return props.optionRenderer ? (props.optionRenderer(option)) : (React.createElement(MenuItem, { key: option.id, value: option.value }, option.value));
        })),
        React.createElement("p", { className: "helper-text" }, props.helperText)));
}
export default SelectBox;
