import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Collapse from '@material-ui/core/Collapse';
import { RankingListAvatar } from "~atoms/RankingListItem/components";
import CSCoin from '~icons/balance/CS_Coin.svg';
import { CSIcon } from "~icons/rankingList/CSIcon";
import { BalanceTrend } from "~molecules/BalanceTrend";
import { numberWithDots } from "~utils/numberWithDots";
import { numberWithDotsAndComma } from "~utils/numberWithDotsAndComma";
import { NestedRankingList } from './components/NestedRankingList';
import { useStyles } from './RankingListItem.styles';
export var RankingListItem = function (_a) {
    var _b, _c, _d, _e;
    var place = _a.place, level = _a.level, avatar = _a.avatar, avatarUrl = _a.avatarUrl, stars = _a.stars, winAmount = _a.winAmount, currentUserRank = _a.currentUserRank, currentUserLevel = _a.currentUserLevel, ranks = _a.ranks, count = _a.count, openedLevel = _a.openedLevel, trendBalance = _a.trendBalance, onItemListClick = _a.onItemListClick;
    var classes = useStyles();
    var t = useTranslation('tip-game').t;
    var isCurrentUser = currentUserLevel && currentUserLevel === level;
    var isNestedListOpen = openedLevel === level;
    var checkIfNarrowScreen = function (maxWith) { return window.innerWidth < maxWith; };
    var textColor = isCurrentUser ?
        classes.greenText
        : place === 'first'
            ? classes.goldText
            : place === 'second'
                ? classes.silverText
                : place === 'third'
                    ? classes.bronzeText
                    : classes.whiteText;
    var maxBalance = (ranks === null || ranks === void 0 ? void 0 : ranks.length) && ((_b = ranks[0]) === null || _b === void 0 ? void 0 : _b.balance) ?
        ranks[0].balance
        : (ranks === null || ranks === void 0 ? void 0 : ranks.length) && ((_c = ranks[ranks.length - 1]) === null || _c === void 0 ? void 0 : _c.balance)
            ? '0.00'
            : null;
    var minBalance = (ranks === null || ranks === void 0 ? void 0 : ranks.length) && ((_d = ranks[ranks.length - 1]) === null || _d === void 0 ? void 0 : _d.balance) ?
        ranks[ranks.length - 1].balance
        : (ranks === null || ranks === void 0 ? void 0 : ranks.length) && ((_e = ranks[0]) === null || _e === void 0 ? void 0 : _e.balance)
            ? '0.00'
            : null;
    var onItemClick = function (level) {
        if (!(ranks === null || ranks === void 0 ? void 0 : ranks.length))
            return;
        var activeLevel = openedLevel === level ? null : level;
        onItemListClick(activeLevel);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { role: "button", tabIndex: 0, className: classNames(classes.container, isCurrentUser && classes.greenBorder, (ranks === null || ranks === void 0 ? void 0 : ranks.length) && classes.pointer), onClick: function () { return onItemClick(level); } },
            React.createElement("div", { className: classes.wrapper },
                React.createElement(RankingListAvatar, { stars: stars, avatar: avatar, avatarUrl: avatarUrl, size: "md" }),
                React.createElement("div", { className: classes.fullWidth },
                    React.createElement("div", { className: classes.level },
                        React.createElement("div", { className: textColor },
                            React.createElement("span", null, t('level')),
                            React.createElement("span", null, level)),
                        isCurrentUser && trendBalance ? React.createElement(BalanceTrend, { balance: numberWithDotsAndComma(trendBalance) }) : null),
                    React.createElement("div", { className: classes.divider }),
                    winAmount && (React.createElement("div", { className: classes.bonusWrapper },
                        React.createElement("div", { className: classes.bonusText }, t('ranking-list.bonus')),
                        React.createElement("div", { className: classes.bonus },
                            count && (React.createElement("div", { className: classes.jackpotCount },
                                count,
                                " x")),
                            numberWithDots(winAmount, checkIfNarrowScreen(435)),
                            React.createElement("img", { src: CSCoin, alt: 'coin' })))),
                    maxBalance ? (React.createElement("div", { className: classes.balanceWrapper },
                        React.createElement("span", null, checkIfNarrowScreen(375) ? t('ranking-list.max') : t('ranking-list.max_balance')),
                        React.createElement("span", null,
                            maxBalance,
                            React.createElement(CSIcon, { size: 'sm' })))) : null,
                    minBalance ? (React.createElement("div", { className: classes.balanceWrapper },
                        React.createElement("span", null, checkIfNarrowScreen(375) ? t('ranking-list.min') : t('ranking-list.min_balance')),
                        React.createElement("span", null,
                            minBalance,
                            React.createElement(CSIcon, { size: 'sm' })))) : null))),
        (ranks === null || ranks === void 0 ? void 0 : ranks.length) && (React.createElement(Collapse, { in: isNestedListOpen, timeout: "auto", unmountOnExit: true },
            React.createElement(NestedRankingList, { rankingList: ranks, currentUserRank: currentUserRank })))));
};
