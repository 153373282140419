import React from 'react';
import { useTranslation } from 'react-i18next';
import { DatePickerField } from '~atoms';
import { checkIfFieldIsRequired, isValidDate, required, validateField, } from '~utils/formValidators';
export var BirthDate = function (_a) {
    var tabIndex = _a.tabIndex, registrationBlocks = _a.registrationBlocks;
    var t = useTranslation('registration').t;
    return (React.createElement(DatePickerField, { name: "userProfileDto.birthDate", title: t('form.date_birth'), dateFormat: "DD/MM/YYYY", fullWidth: true, placeholder: "DD/MM/YYYY", required: checkIfFieldIsRequired('birthDate', tabIndex, registrationBlocks), validators: [
            function (value) {
                return validateField(value, 'birthDate', tabIndex, required, registrationBlocks);
            },
            function (value) {
                return validateField(value, 'birthDate', tabIndex, isValidDate, registrationBlocks);
            },
        ] }));
};
