import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { numberWithDotsAndComma } from '~utils/numberWithDotsAndComma';
import { CSIcon } from '~icons/rankingList/CSIcon';
import { Currency } from '~atoms';
import { RankingListCartoonNickname } from "~atoms/RankingListCartoonNickname";
import { BalanceTrend } from "~molecules/BalanceTrend";
import goldMedal from "~icons/rankingList/gold-medal.svg";
import silverMedal from "~icons/rankingList/silver-medal.svg";
import bronzeMedal from "~icons/rankingList/bronze-medal.svg";
import { useStyles } from '../RankingListCartoonItem.styles';
export var NestedRankingListCartoonItem = function (_a) {
    var _b, _c, _d;
    var rankingItem = _a.rankingItem, currentUserRank = _a.currentUserRank, trendBalance = _a.trendBalance, isGroupRank = _a.isGroupRank;
    var classes = useStyles();
    var t = useTranslation('tip-game').t;
    var isCurrentUser = (rankingItem === null || rankingItem === void 0 ? void 0 : rankingItem.rank) === currentUserRank;
    var isTrendBalance = isCurrentUser && trendBalance;
    var isPrizePlace = isGroupRank && ((rankingItem === null || rankingItem === void 0 ? void 0 : rankingItem.rank) === 1 || (rankingItem === null || rankingItem === void 0 ? void 0 : rankingItem.rank) === 2 || (rankingItem === null || rankingItem === void 0 ? void 0 : rankingItem.rank) === 3);
    var prizePlaceStyles = {
        1: {
            'text': classes.goldText,
            'border': classes.goldBorder,
            'medal': React.createElement("img", { src: goldMedal, alt: 'gold medal' })
        },
        2: {
            'text': classes.silverText,
            'border': classes.silverBorder,
            'medal': React.createElement("img", { src: silverMedal, alt: 'silver medal' })
        },
        3: {
            'text': classes.bronzeText,
            'border': classes.bronzeBorder,
            'medal': React.createElement("img", { src: bronzeMedal, alt: 'bronze medal' })
        },
    };
    return (React.createElement("div", { key: rankingItem === null || rankingItem === void 0 ? void 0 : rankingItem.rank, className: classNames(classes.nestedWrapper, isCurrentUser && classes.greenBorder, isPrizePlace && !isCurrentUser && (rankingItem === null || rankingItem === void 0 ? void 0 : rankingItem.rank) && ((_b = prizePlaceStyles[rankingItem === null || rankingItem === void 0 ? void 0 : rankingItem.rank]) === null || _b === void 0 ? void 0 : _b.border)) },
        React.createElement("div", { className: classes.blockWrapper },
            React.createElement(RankingListCartoonNickname, { nickname: rankingItem.userName, avatar: rankingItem.expLevelName || 'Joker', stars: rankingItem.expSubLevel, avatarUrl: rankingItem.avatarPath || 'avatars/joker.svg', isNestedList: true }),
            React.createElement("div", { className: classes.nestedCenterBlock },
                React.createElement("div", null,
                    React.createElement("div", { className: isGroupRank ? classes.globalListRank : classes.nestedRank },
                        React.createElement("div", { className: isPrizePlace && (rankingItem === null || rankingItem === void 0 ? void 0 : rankingItem.rank) ? (_c = prizePlaceStyles[rankingItem === null || rankingItem === void 0 ? void 0 : rankingItem.rank]) === null || _c === void 0 ? void 0 : _c.text : undefined },
                            React.createElement("span", null, t('rank')),
                            isPrizePlace && (rankingItem === null || rankingItem === void 0 ? void 0 : rankingItem.rank) ? (_d = prizePlaceStyles[rankingItem === null || rankingItem === void 0 ? void 0 : rankingItem.rank]) === null || _d === void 0 ? void 0 : _d.medal : React.createElement("span", null, rankingItem.rank)),
                        isTrendBalance ? React.createElement(BalanceTrend, { balance: numberWithDotsAndComma(trendBalance) }) : null),
                    rankingItem.winAmount && (React.createElement("div", null,
                        React.createElement("div", { className: classes.jackpotAmount },
                            numberWithDotsAndComma(rankingItem.winAmount),
                            rankingItem.currency && (React.createElement(Currency, { currency: rankingItem.currency })))))),
                React.createElement("div", { className: classes.divider }),
                rankingItem.balance || rankingItem.balance === 0 ? (React.createElement("div", { className: classes.nestedCredits },
                    React.createElement("div", null, t('ranking-list.credits')),
                    React.createElement("div", { className: classes.nestedBalance },
                        React.createElement("span", null, numberWithDotsAndComma(rankingItem.balance)),
                        React.createElement(CSIcon, { size: 'sm' })))) : null,
                React.createElement("div", { className: classes.divider })))));
};
