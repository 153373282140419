import makeStyles from '@material-ui/core/styles/makeStyles';
var useStyles = makeStyles(function (theme) {
    var _a, _b;
    return ({
        button: (_a = {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '135px',
                maxWidth: '135px',
                border: '1px solid #444444',
                borderRadius: '4px',
                height: '32px',
                padding: '0 8px',
                backgroundColor: '#151515',
                boxSizing: 'border-box',
                '& span': (_b = {
                        display: 'inline-flex',
                        fontSize: '12px',
                        color: '#fff',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: 'auto',
                        minWidth: '103px'
                    },
                    _b[theme.breakpoints.up('lg')] = {
                        minWidth: '88px',
                    },
                    _b)
            },
            _a[theme.breakpoints.up('lg')] = {
                width: '120px',
                maxWidth: '120px',
            },
            _a),
        labelButton: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        systemMultiselect: {
            paddingRight: '15px',
            '~media (min-width:370px)': {
                paddingRight: '37px',
            },
        },
        openIcon: {
            fontSize: '8px',
        },
        greenText: {
            color: '#00C150',
        },
    });
}, { name: 'CombinationSelect' });
export { useStyles };
