import React from 'react';
import groupBy from 'lodash.groupby';
import { useStructuredSelector } from '~hooks';
import { NON_DROPDOWN_TOURNAMENTS_COUNT } from '~constants/view/quantities';
import { selectTournamentsListData } from '~slices/tournamentsList';
import { ItemsList } from './ItemsList';
import { Category } from './Category';
var Tournaments = function (_a) {
    var onSelect = _a.onSelect;
    var tournaments = useStructuredSelector({
        tournaments: selectTournamentsListData,
    }).tournaments;
    var grouppedTournaments = groupBy(tournaments.slice(NON_DROPDOWN_TOURNAMENTS_COUNT), function (item) {
        var _a;
        return (_a = item === null || item === void 0 ? void 0 : item.ctn) === null || _a === void 0 ? void 0 : _a.nm;
    });
    return (React.createElement(ItemsList, null, Object.entries(grouppedTournaments).map(function (_a) {
        var categoryName = _a[0], categoryTournaments = _a[1];
        return (React.createElement(Category, { key: "".concat(categoryName, "-").concat(categoryTournaments === null || categoryTournaments === void 0 ? void 0 : categoryTournaments[0].id), categoryName: categoryName, onSelect: onSelect, tournaments: categoryTournaments }));
    })));
};
export { Tournaments };
