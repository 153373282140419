var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createAction, createSlice, } from '@reduxjs/toolkit';
import { createAsyncReducers } from '~utils/createAsyncReducers';
import { getTickets, lazyGetTickets } from '~slices/tickets/thunks';
import { POSSIBLE_GOAL_IDS, POSSIBLE_RED_CARDS_IDS } from '~utils/events';
import { UpdateTypes, name } from './constants';
var WEBSOCKET = process.env.REACT_APP_BASE_WEBSOCKET || '';
export var connectTickets = createAction("".concat(name, "/connectTickets"), function (socketParams) { return ({
    payload: __assign({ url: "".concat(WEBSOCKET, "sport-events/v1/ws/ticket-updates") }, socketParams),
}); });
export var connectedTickets = createAction("".concat(name, "/connectedTickets"));
export var socketMessage = createAction("".concat(name, "/socketMessage"));
var initialState = {
    data: {},
    meta: {},
    loading: 'idle',
    error: null,
};
var updateTicket = function (state, payload) {
    var _a;
    var updatedTickets = (_a = state.data.content) === null || _a === void 0 ? void 0 : _a.map(function (oldTicket) {
        var _a, _b;
        var updatedTicket = payload.find(function (newTicket) { return newTicket.mti === oldTicket.mtsTicketId; });
        if (!updatedTicket) {
            return __assign({}, oldTicket);
        }
        var updatedEvents = (_b = (_a = oldTicket.ticketDetails) === null || _a === void 0 ? void 0 : _a.evs) === null || _b === void 0 ? void 0 : _b.map(function (event) {
            var _a;
            var updateEvent = updatedTicket.evs.find(function (newEvent) { return event.id === newEvent.id; });
            if (!updateEvent) {
                return __assign({}, event);
            }
            var updatedSelections = Object.entries(((_a = event.sl) === null || _a === void 0 ? void 0 : _a.sel) || {}).map(function (_a) {
                var _b, _c, _d, _e, _f, _g;
                var key = _a[0], value = _a[1];
                var updatedMarket = Object.entries(((_b = updateEvent.sl) === null || _b === void 0 ? void 0 : _b.sel) || {}).find(function (_a) {
                    var _b, _c;
                    var newKey = _a[0], newValue = _a[1];
                    if (value.id === newValue.id) {
                        return ((_b = value === null || value === void 0 ? void 0 : value.oc) === null || _b === void 0 ? void 0 : _b.id) === ((_c = newValue.oc) === null || _c === void 0 ? void 0 : _c.id);
                    }
                    return false;
                });
                if (!updatedMarket) {
                    return __assign({}, value);
                }
                var updatedOdd = ((_c = value === null || value === void 0 ? void 0 : value.oc) === null || _c === void 0 ? void 0 : _c.id) === ((_d = updatedMarket[1].oc) === null || _d === void 0 ? void 0 : _d.id)
                    ? __assign(__assign({}, value), { oc: __assign(__assign({}, value.oc), { odd: __assign(__assign({}, (_e = value === null || value === void 0 ? void 0 : value.oc) === null || _e === void 0 ? void 0 : _e.odd), { st: (_g = (_f = updatedMarket[1].oc) === null || _f === void 0 ? void 0 : _f.odd) === null || _g === void 0 ? void 0 : _g.st }) }) }) : value;
                return [key, __assign({}, updatedOdd)];
            });
            return __assign(__assign({}, event), { la: updateEvent.la, ls: updateEvent.ls, ms: updateEvent.ms, pa: updateEvent.pa, sl: __assign(__assign({}, event.sl), { 
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    sel: Object.fromEntries(updatedSelections) }) });
        });
        return __assign(__assign({}, oldTicket), { status: updatedTicket.s, cashout: updatedTicket.co, ticketDetails: __assign(__assign({}, oldTicket.ticketDetails), { evs: updatedEvents }) });
    });
    return __assign(__assign({}, state), { data: __assign(__assign({}, state.data), { content: updatedTickets }) });
};
var updateOdd = function (state, payload) {
    var _a;
    var updatedTickets = (_a = state.data.content) === null || _a === void 0 ? void 0 : _a.map(function (myTicket) {
        var _a, _b;
        var updatedEvents = (_b = (_a = myTicket.ticketDetails) === null || _a === void 0 ? void 0 : _a.evs) === null || _b === void 0 ? void 0 : _b.map(function (event) {
            var updates = payload.find(function (newEvent) { return newEvent.bid === event.id; });
            if (!updates) {
                return __assign({}, event);
            }
            return __assign(__assign({}, event), { ms: updates.ms || event.ms, la: updates.li || event.la, tm: updates.ti || event.tm });
        });
        return __assign(__assign({}, myTicket), { ticketDetails: __assign(__assign({}, myTicket.ticketDetails), { evs: updatedEvents }) });
    });
    return __assign(__assign({}, state), { data: __assign(__assign({}, state.data), { content: updatedTickets }) });
};
var updateMatch = function (state, payload) {
    var _a;
    console.log('updateMatch');
    var updatedTickets = (_a = state.data.content) === null || _a === void 0 ? void 0 : _a.map(function (myTicket) {
        var _a, _b;
        var updatedEvents = (_b = (_a = myTicket.ticketDetails) === null || _a === void 0 ? void 0 : _a.evs) === null || _b === void 0 ? void 0 : _b.map(function (event) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
            var updates = payload.find(function (newEvent) { return newEvent.bid === event.id; });
            if (!updates) {
                return __assign({}, event);
            }
            var isSoccer = Number(event === null || event === void 0 ? void 0 : event.si) === 1;
            var prevBetstopReasonId = (_a = event.bsr) === null || _a === void 0 ? void 0 : _a.bid;
            var isPossibleGoal = POSSIBLE_GOAL_IDS.includes(((_b = updates.bsr) === null || _b === void 0 ? void 0 : _b.bid) || 0) && updates.bs;
            var prevIsPossibleGoal = POSSIBLE_GOAL_IDS.includes(prevBetstopReasonId || 0);
            var goal = false;
            // @ts-expect-error
            var scoreBeforePossibleGoal = event.scoreBeforePossibleGoal || {
                c1: 0,
                c2: 0,
            };
            var noGoal = false;
            var c1Goal = Number((_c = event === null || event === void 0 ? void 0 : event.c1) === null || _c === void 0 ? void 0 : _c.sc) < Number((_d = updates === null || updates === void 0 ? void 0 : updates.c1) === null || _d === void 0 ? void 0 : _d.sc);
            var c2Goal = Number((_e = event === null || event === void 0 ? void 0 : event.c2) === null || _e === void 0 ? void 0 : _e.sc) < Number((_f = updates === null || updates === void 0 ? void 0 : updates.c2) === null || _f === void 0 ? void 0 : _f.sc);
            var isPossibleCard = POSSIBLE_RED_CARDS_IDS.includes(((_g = updates.bsr) === null || _g === void 0 ? void 0 : _g.bid) || 0) && updates.bs;
            var prevIsPossCard = POSSIBLE_RED_CARDS_IDS.includes(((_h = event.bsr) === null || _h === void 0 ? void 0 : _h.bid) || 0);
            var c1RedCards = (((_j = updates.c1) === null || _j === void 0 ? void 0 : _j.rc) || 0) + (((_k = updates.c1) === null || _k === void 0 ? void 0 : _k.yrc) || 0);
            var c2RedCards = (((_l = updates.c2) === null || _l === void 0 ? void 0 : _l.rc) || 0) + (((_m = updates.c2) === null || _m === void 0 ? void 0 : _m.yrc) || 0);
            // @ts-expect-error
            var redCardsBeforePossCard = event.redCardsBeforePossCard || {
                c1: c1RedCards,
                c2: c2RedCards,
            };
            var noCard = false;
            if (isPossibleGoal && !prevIsPossibleGoal) {
                scoreBeforePossibleGoal = {
                    c1: ((_o = updates === null || updates === void 0 ? void 0 : updates.c1) === null || _o === void 0 ? void 0 : _o.sc) || 0,
                    c2: ((_p = updates === null || updates === void 0 ? void 0 : updates.c2) === null || _p === void 0 ? void 0 : _p.sc) || 0,
                };
            }
            if (prevIsPossibleGoal && !isPossibleGoal) {
                if (isSoccer &&
                    (scoreBeforePossibleGoal.c1 < Number((_q = updates === null || updates === void 0 ? void 0 : updates.c1) === null || _q === void 0 ? void 0 : _q.sc) ||
                        scoreBeforePossibleGoal.c2 < Number((_r = updates === null || updates === void 0 ? void 0 : updates.c2) === null || _r === void 0 ? void 0 : _r.sc))) {
                    goal = true;
                }
                else if (isSoccer) {
                    noGoal = true;
                }
            }
            if (c1Goal || c2Goal) {
                goal = true;
            }
            if (!prevIsPossCard && isPossibleCard) {
                redCardsBeforePossCard = {
                    c1: c1RedCards,
                    c2: c2RedCards,
                };
            }
            if (!isPossibleCard && prevIsPossCard) {
                if (redCardsBeforePossCard.c1 >= c1RedCards ||
                    redCardsBeforePossCard.c2 >= c2RedCards) {
                    noCard = true;
                }
            }
            return __assign(__assign({}, event), { redCardsBeforePossCard: redCardsBeforePossCard, scoreBeforePossibleGoal: scoreBeforePossibleGoal, goal: goal, noGoal: noGoal, noCard: noCard, gt: updates.gt, lu: updates.lu, ls: updates.ls, ms: updates.ms, bs: updates.bs, bsr: updates.bsr, c1: event.c1 ? __assign(__assign({}, event.c1), updates.c1) : undefined, c2: event.c2 ? __assign(__assign({}, event.c2), updates.c2) : undefined, tm: updates.ti || event.tm });
        });
        return __assign(__assign({}, myTicket), { ticketDetails: __assign(__assign({}, myTicket.ticketDetails), { evs: updatedEvents }) });
    });
    return __assign(__assign({}, state), { data: __assign(__assign({}, state.data), { content: updatedTickets }) });
};
export var ticket = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        resetTickets: function () { return initialState; },
        update: function (state, _a) {
            var payload = _a.payload;
            var updatedState = __assign({}, state);
            var ticketUpdates = payload.filter(function (update) { return update.ut === UpdateTypes.TICKET; }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            );
            var oddUpdates = payload.filter(function (update) { return update.ut === UpdateTypes.ODD; }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            );
            var matchUpdate = payload.filter(function (update) { return update.ut === UpdateTypes.MATCH; }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            );
            if (ticketUpdates.length) {
                updatedState = __assign({}, updateTicket(updatedState, ticketUpdates));
            }
            if (oddUpdates.length) {
                updatedState = __assign({}, updateOdd(updatedState, oddUpdates));
            }
            if (matchUpdate.length) {
                updatedState = __assign({}, updateMatch(updatedState, matchUpdate));
            }
            return __assign({}, updatedState);
        },
        setResponseUpdates: function (state, _a) {
            var _b;
            var payload = _a.payload;
            var updatedTickets = (_b = state.data.content) === null || _b === void 0 ? void 0 : _b.map(function (myTicket) {
                var _a, _b;
                var updatedEvents = (_b = (_a = myTicket.ticketDetails) === null || _a === void 0 ? void 0 : _a.evs) === null || _b === void 0 ? void 0 : _b.map(function (event) {
                    var _a, _b;
                    var updates = payload.find(
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    function (newEvent) { return newEvent.bid === event.id; });
                    var updateOutComes = __assign({}, (_a = event.sl) === null || _a === void 0 ? void 0 : _a.sel);
                    if (!updates) {
                        return __assign({}, event);
                    }
                    Object.entries(((_b = event.sl) === null || _b === void 0 ? void 0 : _b.sel) || {}).forEach(function (_a) {
                        var _b, _c;
                        var key = _a[0], value = _a[1];
                        var updateMarket = (value.key &&
                            updates.mr &&
                            ((_b = updates.mr[value.key]) === null || _b === void 0 ? void 0 : _b.oc.find(
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            function (outCome) { var _a; return outCome.id === ((_a = value.oc) === null || _a === void 0 ? void 0 : _a.id); }))) ||
                            value.oc;
                        Object.defineProperty(updateOutComes, key, {
                            value: __assign(__assign({}, value), { oc: __assign(__assign({}, value.oc), { odd: __assign(__assign({}, (_c = value.oc) === null || _c === void 0 ? void 0 : _c.odd), { st: updateMarket.st || null }) }) }),
                        });
                    });
                    return __assign(__assign({}, event), { bsr: updates.bsr || event.bsr, ms: updates.ms || event.ms, la: updates.la || event.la, tm: updates.ti || event.tm, ls: updates.ls || event.ls, lsd: updates.lsd, lu: updates.lu, c1: event.c1 ? __assign(__assign({}, event.c1), updates.c1) : undefined, c2: event.c2 ? __assign(__assign({}, event.c2), updates.c2) : undefined });
                });
                return __assign(__assign({}, myTicket), { ticketDetails: __assign(__assign({}, myTicket.ticketDetails), { evs: updatedEvents }) });
            });
            return __assign(__assign({}, state), { data: __assign(__assign({}, state.data), { content: updatedTickets }) });
        },
        pickActiveId: function (state, _a) {
            var payload = _a.payload;
            return __assign(__assign({}, state), { data: __assign(__assign({}, state.data), { activeTicketId: payload }) });
        },
        setActiveArchiveTicket: function (state, _a) {
            var payload = _a.payload;
            return __assign(__assign({}, state), { meta: __assign(__assign({}, state.meta), { activeArchiveTicket: payload }) });
        },
        setPurchasedTickets: function (state, _a) {
            var payload = _a.payload;
            return __assign(__assign({}, state), { meta: __assign(__assign({}, state.meta), { purchasedTickets: payload }) });
        },
        setPurchasePending: function (state, _a) {
            var payload = _a.payload;
            return __assign(__assign({}, state), { meta: __assign(__assign({}, state.meta), { isPurchasePending: payload }) });
        },
    },
    extraReducers: function (builder) {
        var _a = createAsyncReducers(), pending = _a.pending, rejected = _a.rejected, fulfilled = _a.fulfilled;
        builder
            .addCase(getTickets.pending, pending)
            .addCase(getTickets.fulfilled, fulfilled)
            .addCase(getTickets.rejected, rejected)
            .addCase(lazyGetTickets.pending, pending)
            .addCase(lazyGetTickets.rejected, rejected)
            .addCase(lazyGetTickets.fulfilled, function (state, _a) {
            var payload = _a.payload;
            var tickets = state.data.content || [];
            var newTickets = tickets.concat(payload.content);
            return __assign(__assign({}, state), { data: __assign(__assign({}, payload), { content: newTickets }), loading: 'fulfilled' });
        });
    },
});
export var resetTickets = (_a = ticket.actions, _a.resetTickets), setResponseUpdates = _a.setResponseUpdates, pickActiveId = _a.pickActiveId, update = _a.update, setActiveArchiveTicket = _a.setActiveArchiveTicket, setPurchasedTickets = _a.setPurchasedTickets, setPurchasePending = _a.setPurchasePending;
export var changeTicketsType = createAction("".concat(name, "/changeTicketsType"));
