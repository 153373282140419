// flex-column
var flexColumn = {
    display: 'flex',
    flexDirection: 'column',
};
// flex-row
var flexRow = {
    display: 'flex',
    flexDirection: 'row',
};
// space-between
var spaceBetween = {
    justifyContent: 'space-between',
};
// flex-end
var flexEnd = {
    justifyContent: 'flex-end',
};
// flex-center
var flexCenter = {
    alignItems: 'center',
};
// justify-center
var justifyCenter = {
    justifyContent: 'center',
};
// flex-auto
var flexAuto = {
    flex: 'auto',
};
// relative
var relative = {
    position: 'relative',
};
// flex-row-reverse
var flexRowReverse = {
    display: 'flex',
    flexDirection: 'row-reverse',
};
// flex-scroll
var flexScroll = {
    flex: 'auto',
    overflowY: 'auto',
    flexGrow: 0,
};
// flex-bar rewrited ☑
var flexBar = {
    flexShrink: 0,
};
// bottom arrow
var bottomArrow = {
    content: '""',
    position: 'absolute',
    bottom: '-7px',
    left: '50%',
    marginLeft: '-6px',
    width: '0',
    height: '0',
    borderStyle: 'solid',
    borderWidth: '0 6px 5px 6px',
    borderColor: 'transparent transparent #303038 transparent',
    transition: 'bottom 300ms cubic-bezier(0.4, 0, 0.2, 1)',
};
export var common = {
    flexColumn: flexColumn,
    flexRow: flexRow,
    spaceBetween: spaceBetween,
    flexEnd: flexEnd,
    flexCenter: flexCenter,
    justifyCenter: justifyCenter,
    flexAuto: flexAuto,
    relative: relative,
    flexRowReverse: flexRowReverse,
    flexScroll: flexScroll,
    flexBar: flexBar,
    bottomArrow: bottomArrow,
};
