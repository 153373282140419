import React from 'react';
import classNames from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';
var useStyle = makeStyles(function (theme) {
    var _a;
    return ({
        rootPlate: (_a = {
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                maxWidth: '83px',
                width: '100%',
                height: '96px',
                borderRadius: '4px',
                backgroundColor: '#151515',
                boxSizing: 'border-box',
                margin: '2px',
                '& svg': {
                    marginBottom: '16px',
                },
                '& i': {
                    position: 'absolute',
                    color: '#FFFFFF',
                    fontSize: '10px',
                    lineHeight: '10px',
                    top: '8px',
                    right: '8px',
                }
            },
            _a[theme.breakpoints.up('lg')] = {
                margin: 'auto',
                backgroundColor: '#212121',
                '& i': {
                    color: '#2AA1F7',
                },
            },
            _a),
        selectedPlate: {
            background: 'radial-gradient(100% 100% at 50.13% 0%, #3C3C46 0%, #1B1B22 100%)',
            border: '1px solid #4E4E5A',
        },
        label: {
            fontWeight: 'normal',
            textAlign: 'center',
            fontSize: '12px',
            lineHeight: '14px',
            color: '#FFFFFF',
        },
    });
}, { name: 'SportPlate' });
export var SportPlate = function (_a) {
    var _b;
    var isSelected = _a.isSelected, Icon = _a.icon, label = _a.label, id = _a.id, onChange = _a.onChange;
    var classes = useStyle();
    var handleClick = function () {
        onChange(id);
    };
    return (React.createElement("div", { role: "button", tabIndex: 0, onClick: handleClick, className: classNames(classes.rootPlate, (_b = {},
            _b[classes.selectedPlate] = isSelected,
            _b)) },
        Icon && React.createElement(Icon, null),
        isSelected && React.createElement("i", { className: "fal fa-check" }),
        React.createElement("span", { className: classes.label }, label)));
};
