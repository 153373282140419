var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ZIndex } from '~constants/view/ZIndex';
export var useStatisticsHeaderStyles = makeStyles({
    header: {
        padding: '7px 8px',
        backgroundColor: '#151515',
    },
    noOverflow: {
        overflow: 'hidden',
    },
    button: {
        '&:hover': {
            background: 'none',
            cursor: 'default',
        },
    },
    hover: {
        '&:hover': {
            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
            backgroundColor: '#d5d5d5',
        },
    },
    height: {
        lineHeight: 0,
    },
    backButton: {
        padding: '10px',
        height: '32px',
        width: '74px',
        float: 'left',
        fontSize: '12px',
        lineHeight: 1.5,
        color: 'black',
        backgroundColor: 'white',
    },
    backButtonIcon: {
        fontSize: '10px !important',
    },
}, { name: 'StatisticsHeader' });
export var useTabsStyles = makeStyles({
    root: {
        top: 0,
        position: 'sticky',
        zIndex: 20,
        textTransform: 'none',
        color: '#fff',
        backgroundColor: '#151515',
        minHeight: '32px',
    },
    indicator: {
        display: 'none',
    },
    loader: {
        marginTop: '15px',
    },
}, { name: 'Tabs' });
export var useTabStyles = makeStyles({
    root: {
        textTransform: 'none',
        color: '#fff',
        backgroundColor: '#323232',
        width: 60,
        minHeight: '32px',
        boxSizing: 'border-box',
        padding: 0,
        marginRight: '1px',
        fontWeight: 'bold',
        fontSize: '12px',
        lineHeight: '14px',
        '&:last-child': {
            marginRight: '0',
        },
    },
    fixedTab: {
        width: '60px',
        backgroundColor: '#2AA1F7',
    },
    selected: {
        opacity: 0,
        '&:after': {
            position: 'absolute',
            bottom: '0',
            left: '50%',
            marginLeft: '-5px',
            content: "''",
            width: 0,
            height: 0,
            borderLeft: '5px solid transparent',
            borderRight: '5px solid transparent',
        },
    },
    selectedAll: {
        '&:after': {
            borderBottom: '5px solid #000',
        },
    },
    selectedCategory: {
        '&:after': {
            borderBottom: '5px solid #FFF',
        },
    },
    textColorInherit: {
        opacity: 1,
    },
    flagImage: {
        maxWidth: '30px',
        width: '100%',
        height: '20px',
        borderRadius: '2px',
    },
}, { name: 'Tab' });
export var useEventTabsStyles = makeStyles(function (theme) {
    var _a, _b, _c;
    return ({
        sportTab: {
            '&::after': theme.common.bottomArrow,
            '&.withArrow::after': {
                bottom: '0px',
            },
        },
        selected: {
            '&:after': {
                position: 'absolute',
                bottom: '0',
                left: '50%',
                transform: 'translate(-50%, 0)',
                content: "''",
                width: 64,
                height: 3,
            },
            '&.desktop': {
                '&:after': {
                    width: '100%',
                },
            },
        },
        star: {
            position: 'absolute',
            fontWeight: 900,
            fontSize: '8px',
            lineHeight: '15px',
            color: '#FFEB3B',
            '&.mobile': {
                top: '-3px',
            },
            '&.white': {
                color: 'white',
            },
        },
        '&.white': {
            color: 'white',
        },
        selectedCategory: {
            '&:after': {
                borderBottom: '2px solid white',
            },
        },
        textColorInherit: {
            opacity: 1,
            color: '#b8b8b8',
        },
        barWrapper: __assign(__assign({}, theme.common.flexBar), { position: 'sticky', top: '32px', zIndex: ZIndex.HOME_TABS }),
        root: {
            backgroundColor: '#151515',
        },
        wrapper: {
            display: 'flex',
            alignItems: 'flex-start',
        },
        scrollWrapper: {
            flex: 'auto',
            width: '100%',
            '&.marketFiltersWidth': (_a = {
                    height: '100%',
                    width: '50%',
                    overflow: 'auto'
                },
                _a[theme.breakpoints.down(1800)] = {
                    width: '100%',
                },
                _a[theme.breakpoints.down(1600)] = {
                    width: '0px !important',
                    maxWidth: '0px !important',
                    overflowX: 'hidden',
                },
                _a),
        },
        sidebetBox: (_b = {
                position: 'sticky',
                top: '80px',
                width: 'calc(100% - 16px)',
                marginLeft: 16,
                height: '100%',
                display: 'flex',
                alignItems: 'stretch',
                flexDirection: 'column'
            },
            _b[theme.breakpoints.down(1600)] = {
                marginLeft: 0,
                width: '100%',
            },
            _b),
        sidebetBoxWrap: {
            color: '#fff',
            minHeight: '100%',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            overflowY: 'auto',
            overflowX: 'hidden',
        },
        sidebetHeader: {
            display: 'flex',
            justifyContent: 'flex-end',
            backgroundColor: '#323232',
            padding: '8px 21px 8px 12px',
            color: '#F2F2F2',
            outline: 'none',
            position: 'sticky',
            top: '0px',
            zIndex: 2,
            '& i': {
                cursor: 'pointer',
                fontWeight: 300,
            },
        },
        sidebetsModalWidth: (_c = {
                width: '50%'
            },
            _c[theme.breakpoints.down(1600)] = {
                width: '100%',
            },
            _c),
        search: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#b8b8b8',
            textTransform: 'capitalize',
            '&::after': theme.common.bottomArrow,
            '&.withArrow::after': {
                bottom: '0px',
            },
        },
        overflowY: {
            overflowY: 'auto',
            marginBottom: 0,
        },
        overflowHidden: {
            overflow: 'hidden',
        },
        fullWidth: {
            width: '100%',
        },
        fullHeight: {
            height: '100%',
        }
    });
}, { name: 'EventTabs' });
export var useTournamentsTabsStyles = makeStyles({
    root: {
        display: 'flex',
        overflow: 'hidden',
        backgroundColor: '#ffffff',
        width: '100%',
        maxWidth: '100%',
    },
    links: {
        display: 'flex',
        maxWidth: '100%',
        overflow: 'hidden',
        '& > *': {
            overflow: 'hidden',
        },
    },
    tournamentTitleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: '16px 16px 9px 16px',
        boxSizing: 'border-box',
        alignItems: 'center',
        background: '#151515',
    },
    tournamentTitleWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    tournamentTitle: {
        fontSize: 16,
        fontWeight: 600,
        color: '#FDFDFD',
    },
    flagIcon: {
        display: 'flex',
        alignItems: 'center',
        width: 18,
        height: 12,
        '& img': {
            width: 18,
            height: 12,
            borderRadius: 2,
        }
    },
    sportIcon: {
        display: 'flex',
        alignItems: 'center',
        height: 14,
        width: 14,
        marginRight: 10,
        '& svg': {
            height: 14,
            width: 14,
        }
    },
    divider: {
        height: 15,
        width: 1,
        margin: '0 8px',
        background: '#8E8E8E',
        opacity: 0.4,
    },
    className: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 16,
        color: '#FDFDFD',
        fontWeight: 600,
        textTransform: 'uppercase',
    },
    gameDescription: {
        marginLeft: 5,
        width: 20,
        height: 20,
        textAlign: 'center',
        boxSizing: 'border-box',
        color: '#6E94FF',
        border: '1px solid #6E94FF',
        borderRadius: 50,
        cursor: 'pointer',
    },
}, { name: 'TournamentsTabs' });
export var useStyles = makeStyles({
    topBanner: {
        position: 'static',
    },
}, { name: 'Home' });
