import React from 'react';
import { useTranslation } from 'react-i18next';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import { useIsDesktop } from '~hooks';
import { useStyles } from '../Options.styles';
var AntSwitch = withStyles(function () {
    return createStyles({
        root: {
            width: 28,
            height: 16,
            padding: 0,
            display: 'flex',
            borderRadius: 50,
            marginRight: 5,
        },
        switchBase: {
            padding: 2,
            color: '#2AA1F7',
            '&$checked': {
                transform: 'translateX(10px)',
                '& + $track': {
                    opacity: 1,
                    backgroundColor: '#4E4E5A',
                },
            },
        },
        thumb: {
            width: 13,
            height: 13,
            boxShadow: 'none',
        },
        track: {
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: '#4E4E5A',
            '&$checked': {
                backgroundColor: '#4E4E5A',
            },
        },
        colorPrimary: {
            color: '#b1b1b1',
            '&$checked': {
                color: '#2AA1F7',
            },
        },
        checked: {},
    });
})(Switch);
export var SwitchButtons = function (_a) {
    var options = _a.options, changeOption = _a.changeOption;
    var classes = useStyles();
    var isDesktop = useIsDesktop();
    var t = useTranslation('common').t;
    var onChange = function (e) {
        changeOption(e.target.name, e.target.checked);
    };
    return (React.createElement("div", { className: classes.blockMargin },
        React.createElement(FormGroup, { row: isDesktop },
            React.createElement(FormControlLabel, { control: React.createElement(AntSwitch, { checked: options.autoAccept, color: "primary", name: "autoAccept", onChange: onChange }), className: classes.switchButton, label: React.createElement("span", { className: classes.switchButtonLabel }, t('auto_accept')) }),
            React.createElement(FormControlLabel, { control: React.createElement(AntSwitch, { checked: options.wantNewslettersOnEmail, color: "primary", name: "wantNewslettersOnEmail", onChange: onChange }), label: React.createElement("span", { className: classes.switchButtonLabel }, t('newsletters')), className: classes.switchButton }),
            React.createElement(FormControlLabel, { control: React.createElement(AntSwitch, { checked: options.wantPushNotification, color: "primary", name: "wantPushNotification", onChange: onChange }), label: React.createElement("span", { className: classes.switchButtonLabel }, t('push_notification')), className: classes.switchButton }))));
};
