import makeStyles from '@material-ui/core/styles/makeStyles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
var useStyles = makeStyles({
    item: {
        width: '130px',
        height: '30px',
        boxSizing: 'border-box',
        borderRadius: '0px',
        color: '#fff',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        textAlign: 'center',
        position: 'relative',
        paddingLeft: '16px',
    },
    light: {
        color: '#212121',
        '&:hover': {
            backgroundColor: '#F2F2F2',
        },
    },
    active: {
        backgroundColor: alpha('#2AA1F7', 0.1),
        '&:hover': {
            backgroundColor: alpha('#2AA1F7', 0.1),
        },
        '&$light': {
            backgroundColor: alpha('#2AA1F7', 0.2),
        },
        '&$light:hover': {
            backgroundColor: alpha('#2AA1F7', 0.2),
        },
        '&::before': {
            left: '0',
            top: '0',
            content: '""',
            width: '4px',
            height: '100%',
            position: 'absolute',
            backgroundColor: '#2AA1F7',
        },
    },
}, { name: 'OptionsSelection' });
export { useStyles };
