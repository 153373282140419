import React from 'react';
import ReactDOM from 'react-dom';
import cn from 'classnames';
import { ZIndex } from '~constants/view/ZIndex';
import { noop } from '~utils/noop';
import { getTestId } from '~/utils/getTestId';
import { useStyles } from './Portal.styles';
var Portal = function (_a) {
    var _b;
    var children = _a.children, _c = _a.display, display = _c === void 0 ? false : _c, _d = _a.transparent, transparent = _d === void 0 ? false : _d, _e = _a.enableWrapperPointerEvents, enableWrapperPointerEvents = _e === void 0 ? false : _e, _f = _a.zIndex, zIndex = _f === void 0 ? ZIndex.PORTAL : _f, _g = _a.onWrapperClick, onWrapperClick = _g === void 0 ? noop : _g;
    var rootNode = React.useState(document.getElementById('portal'))[0];
    var classnames = useStyles();
    if (rootNode === null || !display) {
        return null;
    }
    var handleWrapperClick = function (e) {
        if (e.target === e.currentTarget) {
            onWrapperClick();
        }
    };
    return ReactDOM.createPortal(
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    React.createElement("div", { "data-testid": getTestId('portal-wrapper'), onClick: handleWrapperClick, className: cn(classnames.container, (_b = {},
            _b[classnames.transparent] = transparent,
            _b[classnames.noPointerEvents] = !enableWrapperPointerEvents,
            _b)), style: { zIndex: zIndex } }, display && children), rootNode);
};
export { Portal };
