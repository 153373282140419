import makeStyles from '@material-ui/core/styles/makeStyles';
var useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        height: '68px',
        minHeight: '68px',
        maxHeight: '68px',
        marginRight: '32px',
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'end',
        height: '24%',
    },
    name: {
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '14px',
        color: '#FFFFFF',
        opacity: 0.6,
    },
    value: {
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '14px',
        color: '#FFFFFF',
    },
}, { name: 'BetInfo' });
export { useStyles };
