import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { betslipDataSelector, betslipInactiveEventsSelector, deleteDeactivatedOdds, } from '~slices/betslip';
import { Event } from '~organisms';
import { useIsDesktop, useStructuredSelector } from '~hooks';
import { Button } from '~atoms/button';
import { useAppDispatch } from '~store';
import { isAuthenticatedSelector } from '~slices/auth';
import { getPathWithLanguageCode } from '~utils/languageInURL/getPathWithLanguageCode';
import { DesktopTicketDetailsFooter, DesktopTicketDetailsHeader, } from '~pages/top-ranking';
import { useStyles } from '../topRanking.styles';
export var TicketDetails = function () {
    var _a, _b;
    var t = useTranslation('ticket').t;
    var classes = useStyles();
    var _c = useStructuredSelector({
        betslip: betslipDataSelector,
        isAuthenticated: isAuthenticatedSelector,
        inactiveEvents: betslipInactiveEventsSelector,
    }), betslip = _c.betslip, isAuthenticated = _c.isAuthenticated, inactiveEvents = _c.inactiveEvents;
    var isDesktop = useIsDesktop();
    var dispatch = useAppDispatch();
    var hasInactiveEvents = inactiveEvents.length > 0;
    var removeDeactivatedOdds = useCallback(function () {
        dispatch(deleteDeactivatedOdds({ eventsType: 'betSlip' })).then(function (data) {
            var url = getPathWithLanguageCode('/home/all/live');
            dispatch(push(url, {
                openBetslip: !isDesktop && data.payload,
            }));
        });
    }, [isDesktop, isAuthenticated]);
    return (React.createElement(React.Fragment, null,
        isDesktop && React.createElement(DesktopTicketDetailsHeader, { to: betslip.to }), (_b = (_a = betslip.to) === null || _a === void 0 ? void 0 : _a.evs) === null || _b === void 0 ? void 0 :
        _b.map(function (event) {
            var _a;
            return (React.createElement(Event, { key: event.id, 
                // @ts-expect-error
                event: event, ticketDetails: true, showBanker: !!((_a = event.sl) === null || _a === void 0 ? void 0 : _a.ba), checkDeactivatedOdds: true, deletableOdds: false }));
        }),
        hasInactiveEvents && (React.createElement("div", { className: classes.removeOddsWrapper },
            React.createElement(Button, { variant: "contained", fullWidth: true, onClick: removeDeactivatedOdds }, t('remove_inactive_with_redirect')))),
        isDesktop && React.createElement(DesktopTicketDetailsFooter, { to: betslip.to })));
};
