import React from 'react';
import classNames from 'classnames';
import { CompetitorColumn, CompetitorScores } from './Competitor';
import { useCompetitorBlockStyles } from './Event.styles';
export var CompetitorBlock = function (_a) {
    var _b;
    var c1 = _a.c1, c2 = _a.c2, isDesktop = _a.isDesktop, id = _a.id, toRedirectToSidebets = _a.toRedirectToSidebets, sportId = _a.sportId, pickActiveSidebetIdHandler = _a.pickActiveSidebetIdHandler, sr = _a.sr, currentScore = _a.currentScore, currentRedCards = _a.currentRedCards, temporaryRedCards = _a.temporaryRedCards;
    var classes = useCompetitorBlockStyles();
    return (React.createElement("div", { className: classNames(classes.flexRow, classes.competitorBlock, (_b = {},
            _b[classes.competitorBlockDesktop] = isDesktop,
            _b)) },
        isDesktop && (React.createElement("div", { className: classes.competeSimulate, role: "button", "aria-label": "show details", tabIndex: 0, onClick: pickActiveSidebetIdHandler })),
        React.createElement(CompetitorColumn, { c1: c1, c2: c2, eventId: id, toRedirectToSidebets: toRedirectToSidebets, currentRedCards: currentRedCards, temporaryRedCards: temporaryRedCards }),
        React.createElement("div", { className: classNames(classes.marginLeft, 'flex-column') },
            React.createElement(CompetitorScores, { isFirst: true, competitor: c1, style: { marginBottom: '7px' }, sr: sr, sportId: sportId, scoreToShow: currentScore.c1 }),
            React.createElement(CompetitorScores, { competitor: c2, sr: sr, sportId: sportId, scoreToShow: currentScore.c2 }))));
};
