var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { unwrapResult } from '@reduxjs/toolkit';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { Button, TextInput } from '~atoms';
import { editEmail as editEmailAsyncAction, errorSelector, resetErrors, } from '~slices/account';
import { useAppDispatch } from '~store/configureStore';
import { useIsDesktop, useStructuredSelector } from '~hooks';
import { checkForUpdateEmail, userIdSelector } from '~slices/auth';
import { getPathWithLanguageCode } from '~utils/languageInURL/getPathWithLanguageCode';
import { editEmailValidationSchema } from '../utils';
import { useEditAccountStyles } from '../MyAccount.styles';
export var EditEmailForm = function (_a) {
    var _b, _c;
    var handleCancel = _a.handleCancel, goToNextStep = _a.goToNextStep, inputClassName = _a.inputClassName, visibilityColor = _a.visibilityColor;
    var t = useTranslation('my-account').t;
    var tc = useTranslation('common').t;
    var te = useTranslation('errors').t;
    var dispatch = useAppDispatch();
    var classes = useEditAccountStyles();
    var _d = useStructuredSelector({
        error: errorSelector,
        userId: userIdSelector,
    }), error = _d.error, userId = _d.userId;
    var isDesktop = useIsDesktop();
    var formError = error === null || error === void 0 ? void 0 : error.errors;
    var errorConverted = Array.isArray(formError)
        ? formError
            .map(function (_a) {
            var code = _a.exceptionCode, field = _a.field;
            return te("".concat(code, "_").concat(field), te(code));
        })
            .join('. ')
        : '';
    useEffect(function () {
        return function () {
            dispatch(resetErrors());
        };
    }, []);
    var _e = useForm({
        validationSchema: editEmailValidationSchema,
        mode: 'onChange',
        submitFocusError: true,
    }), register = _e.register, handleSubmit = _e.handleSubmit, errors = _e.errors, setError = _e.setError;
    var redirectToAccountMenu = function () {
        dispatch(push(getPathWithLanguageCode('/my-account')));
    };
    var submit = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (errors.email) {
                setError('email', 'notUnique', te('ER0002_email'));
            }
            else {
                dispatch(editEmailAsyncAction(values))
                    .then(unwrapResult)
                    .then(function () {
                    return isDesktop
                        ? goToNextStep && goToNextStep(values.email, true)
                        : dispatch(push({
                            pathname: getPathWithLanguageCode('/my-account/email/code'),
                            state: {
                                email: values.email,
                            },
                        }));
                });
            }
            return [2 /*return*/];
        });
    }); };
    var validateEmailOnBlur = function (e) {
        if (!errors.email) {
            dispatch(checkForUpdateEmail({
                email: e.target.value,
                id: userId,
            }))
                .then(unwrapResult)
                .then(function (res) {
                if (Object.keys(res).length) {
                    setError('email', 'notUnique', te("".concat(res.email, "_").concat(Object.keys(res)[0])));
                }
                return null;
            });
        }
    };
    return (React.createElement("form", { className: classNames(classes.form, 'flex-column'), style: { paddingBottom: isDesktop ? '32px' : '' }, onSubmit: handleSubmit(submit), noValidate: true },
        React.createElement("div", { className: classes.flex },
            React.createElement(TextInput, { name: "email", inputType: "email", onBlur: validateEmailOnBlur, title: t('email.new_email'), placeholder: t('email.enter_new_email'), inputRef: register, error: !!errors.email, helperText: (_b = errors === null || errors === void 0 ? void 0 : errors.email) === null || _b === void 0 ? void 0 : _b.message, showAsterisk: true }),
            React.createElement(Divider, { classes: { root: classes.divider } }),
            React.createElement(TextInput, { name: "password", title: t('email.password'), placeholder: t('email.enter_password'), type: "password", inputRef: register, error: !!errors.password, helperText: (_c = errors === null || errors === void 0 ? void 0 : errors.password) === null || _c === void 0 ? void 0 : _c.message, showAsterisk: true, inputClassName: isDesktop ? classes.lastInput : '', visibilityColor: visibilityColor }),
            React.createElement(FormHelperText, { id: "component-error-text", error: !!formError }, errorConverted)),
        React.createElement(Grid, { container: true, style: { marginTop: 'auto' }, justify: "space-between", spacing: 2 },
            React.createElement(Grid, { key: "cancel", item: true },
                React.createElement(Button, { size: "large", variant: "outlined", classes: { root: classes.button }, onClick: handleCancel || redirectToAccountMenu, color: "secondary" }, tc('buttons.cancel'))),
            React.createElement(Grid, { key: "next", item: true },
                React.createElement(Button, { size: "large", variant: "contained", color: "primary", classes: { root: classes.button }, type: "submit" }, tc('buttons.done'))))));
};
