import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button } from '~atoms';
import { useStyles } from './TopUpButton.styles';
export var TopUpButton = function (_a) {
    var isTicketSelection = _a.isTicketSelection, onTopUpClick = _a.onTopUpClick;
    var t = useTranslation('tip-game').t;
    var classes = useStyles();
    return (React.createElement(Button, { color: "primary", variant: "contained", fullWidth: true, onClick: onTopUpClick, className: classNames(classes.button, isTicketSelection && classes.redBackground) }, t('payments.top_up')));
};
