var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { range } from '~utils/range';
import { getMaxCoordinates } from './getMaxCoordinates';
var sortOutcomesByRows = function (oc, optionName, idsMap) {
    var maxY = getMaxCoordinates(oc, idsMap[optionName]).y;
    var matrix = range(maxY).map(function (_, i) {
        var y = i + 1;
        return "1x".concat(y);
    });
    var foundOc = [];
    matrix.forEach(function (position) {
        var ids = idsMap[optionName][position] || [];
        var found = oc
            .filter(function (_a) {
            var id = _a.id;
            return ids.includes(Number(id));
        })
            .sort(function (a, b) { return Number(a.id) - Number(b.id); });
        foundOc.push.apply(foundOc, found);
    });
    var foundIds = foundOc.map(function (_a) {
        var id = _a.id;
        return id;
    });
    var unfoundOc = oc
        .filter(function (_a) {
        var id = _a.id;
        return !foundIds.includes(id);
    })
        .sort(function (a, b) { return Number(a.id) - Number(b.id); });
    return __spreadArray(__spreadArray([], foundOc, true), unfoundOc, true);
};
export { sortOutcomesByRows };
