import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Collapse from "@material-ui/core/Collapse";
import { formatLargeNumber } from "~utils/formatLargeNumber";
import CSCoinIcon from "~icons/balance/CS_Coin.svg";
import { Tip } from "~pages";
import { SmallLoader } from "~atoms";
import { useStyles } from './ArchiveTicket.styles';
export var ArchiveTicket = function (_a) {
    var date = _a.date, rank = _a.rank, amount = _a.amount, archiveTickets = _a.archiveTickets, isOpened = _a.isOpened, isDataLoading = _a.isDataLoading, onClick = _a.onClick, onScroll = _a.onScroll;
    var containerRef = useRef(null);
    var classes = useStyles();
    var t = useTranslation('tip-game').t;
    var formattedAmount = formatLargeNumber(amount);
    var formattedDate = moment(date).format('DD/MM/YYYY HH:mm');
    var nameInfo = moment(date).format('DD/MM/YYYY');
    var defaultTicketId = ((archiveTickets === null || archiveTickets === void 0 ? void 0 : archiveTickets.length) && archiveTickets[0].id) || 0;
    var handleScroll = function () {
        if (onScroll) {
            onScroll(containerRef);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { role: "button", tabIndex: 0, className: classes.wrapper, onClick: onClick },
            React.createElement("div", { className: classes.date },
                t('end_date'),
                ": ",
                formattedDate),
            React.createElement("div", { className: classes.contentContainer },
                React.createElement("div", { className: "flex-row" },
                    React.createElement("div", { className: classes.name }, nameInfo),
                    React.createElement("div", { className: classes.rank },
                        React.createElement("span", null,
                            t('rank'),
                            ": "), rank !== null && rank !== void 0 ? rank : '-')),
                React.createElement("div", { className: classes.credits },
                    formattedAmount,
                    React.createElement("img", { src: CSCoinIcon, alt: 'coin' }),
                    React.createElement("i", { className: "far fa-angle-down" })))),
        React.createElement(Collapse, { style: { position: 'relative' }, in: isOpened, timeout: "auto" },
            React.createElement("div", { ref: containerRef, className: classes.tipsWrapper, onScroll: handleScroll },
                isDataLoading && isOpened ? (React.createElement("div", { className: classes.smallLoader },
                    React.createElement(SmallLoader, null))) : null, archiveTickets === null || archiveTickets === void 0 ? void 0 :
                archiveTickets.map(function (ticket) { return (React.createElement(Tip, { key: ticket.id, ticket: ticket, defaultTicketId: defaultTicketId, isArchiveTicket: true })); })))));
};
