import * as yup from 'yup';
import moment from 'moment';
import validationMessages from '~utils/validationMessages';
export var editAccountValidationSchema = yup.object().shape({
    addresses: yup.array(yup.object().shape({
        countryCode: yup.string().when('countryCode', {
            is: function (value) { return value !== undefined; },
            then: yup.string().nullable().required(validationMessages.required),
        }),
        postalCode: yup.string().when('postalCode', {
            is: function (value) { return value !== undefined; },
            then: yup.string().required(validationMessages.required),
        }),
        city: yup.string().when('city', {
            is: function (value) { return value !== undefined; },
            then: yup.string().required(validationMessages.required),
        }),
        street: yup.string().when('street', {
            is: function (value) { return value !== undefined; },
            then: yup.string().required(validationMessages.required),
        }),
        homeNumber: yup.string().when('homeNumber', {
            is: function (value) { return value !== undefined; },
            then: yup.string().required(validationMessages.required),
        }),
    }, [
        ['postalCode', 'postalCode'],
        ['city', 'city'],
        ['street', 'street'],
        ['countryCode', 'countryCode'],
        ['homeNumber', 'homeNumber'],
    ])),
    name: yup.string().when('title', {
        is: function (value) { return value !== undefined; },
        then: yup
            .string()
            .required(validationMessages.required)
            .min(1, validationMessages.sizeFrom1to50)
            .max(50, validationMessages.sizeFrom1to50),
    }),
    surname: yup.string().when('surname', {
        is: function (value) { return value !== undefined; },
        then: yup
            .string()
            .required(validationMessages.required)
            .min(1, validationMessages.sizeFrom1to50)
            .max(50, validationMessages.sizeFrom1to50),
    }),
    birthDate: yup.string().when('birthDate', {
        is: function (value) { return value !== undefined; },
        then: yup
            .string()
            .nullable()
            .required(validationMessages.required)
            .test('isValid-date', validationMessages.dateFormat, function (v) {
            return moment(v).isValid();
        }),
    }),
    phoneCode: yup.string().when('phoneCode', {
        is: function (value) { return value !== undefined; },
        then: yup.string().required(validationMessages.required),
    }),
    phone: yup.string().when('phoneCode', {
        is: function (value) { return value !== undefined; },
        then: yup
            .string()
            .required(validationMessages.required)
            .test('isDigit', validationMessages.digitsOnly, function (v) {
            var re = /^\d+$/;
            // @ts-ignore
            return re.test(v);
        }),
    }),
    nationalityId: yup.string().when('nationalityId', {
        is: function (value) { return value !== undefined; },
        then: yup.string().required(validationMessages.required),
    }),
    passportNumber: yup.string().when('passportNumber', {
        is: function (value) { return value !== undefined; },
        then: yup.string().required(validationMessages.required),
    }),
}, [
    ['birthDate', 'birthDate'],
    ['passportNumber', 'passportNumber'],
    ['nationalityId', 'nationalityId'],
    ['surname', 'surname'],
    ['phoneCode', 'phoneCode'],
]);
export var editPasswordValidationSchema = yup.object().shape({
    password: yup.string().required(validationMessages.required),
    newPassword: yup
        .string()
        .required(validationMessages.required)
        .matches(/^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,100})$/g, validationMessages.passwordFormat)
        .required(validationMessages.required),
    repeatNewPassword: yup
        .string()
        .required(validationMessages.required)
        .oneOf([yup.ref('newPassword'), ''], validationMessages.passwordsDontMatch),
});
export var editEmailValidationSchema = yup.object().shape({
    password: yup.string().required(validationMessages.required),
    email: yup
        .string()
        .required(validationMessages.required)
        .email(validationMessages.emailFormat)
        .min(4, validationMessages.sizeFrom4to70)
        .max(70, validationMessages.sizeFrom4to70),
});
export var securityQuestionValidationSchema = yup.object().shape({
    secretAnswer: yup
        .string()
        .required(validationMessages.required)
        .min(1, validationMessages.sizeFrom1to50)
        .max(50, validationMessages.sizeFrom1to50),
    secretQuestionId: yup
        .string()
        .test('required', validationMessages.required, function (val) { return val !== '0'; }),
});
