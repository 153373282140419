import makeStyles from '@material-ui/core/styles/makeStyles';
export var useStyles = makeStyles({
    wrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: '100%',
    },
    title: {
        marginTop: '15px',
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: 300,
        color: '#F2F2F2',
    },
    subtitle: {
        textAlign: 'center',
        fontSize: '16px',
        fontWeight: 300,
        color: '#FFFFFF',
    },
    amount: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px',
        maxWidth: '100%',
        fontWeight: 700,
        fontSize: '64px',
        color: '#F2F2F2',
        '& > div': {
            marginLeft: '5px',
            fontWeight: 700,
            fontSize: '64px',
        },
    },
    button: {
        marginTop: '10px',
        fontSize: '16px',
    },
    code: {
        margin: 'auto',
        marginTop: '10px',
        marginBottom: '10px',
        maxWidth: '450px',
        backgroundColor: '#FFFFFF',
        '& img': {
            width: '100%',
        },
    },
    '@media (max-width:375px)': {
        amount: {
            marginTop: '5px',
            fontSize: '36px',
            color: '#F2F2F2',
            '& > div': {
                fontSize: '36px',
            },
        },
        button: {
            marginTop: '5px',
        },
    },
}, { name: 'PaymentCode' });
