import React from 'react';
import { matchEventSelector } from '~slices/sidebets';
import { useStructuredSelector } from '~hooks';
import { EventStatus } from '../EventStatus';
var SidebetsEventStatus = function (_a) {
    var favoritesTab = _a.favoritesTab;
    var event = useStructuredSelector({
        event: matchEventSelector,
    }).event;
    return React.createElement(EventStatus, { event: event, favoritesTab: favoritesTab });
};
export { SidebetsEventStatus };
