var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import makeStyles from '@material-ui/core/styles/makeStyles';
export var useStyles = makeStyles(function (theme) {
    var _a, _b, _c, _d, _e, _f;
    return ({
        barWrapper: __assign({}, theme.common.flexBar),
        bottomPadding: {
            bottom: '70px',
        },
        generalBanner: {
            display: 'block',
            width: '100%',
            height: '96px',
        },
        container: (_a = {
                display: 'flex',
                flexWrap: 'wrap',
                padding: '0 11px',
                justifyContent: 'center'
            },
            _a[theme.breakpoints.up('lg')] = {
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fit, minmax(83px, 1fr))',
                gridGap: 20,
                padding: '0 24px',
            },
            _a),
        label: (_b = {
                fontWeight: 'normal',
                fontSize: '12px',
                lineHeight: '14px',
                color: '#FFFFFF',
                padding: 16
            },
            _b[theme.breakpoints.up('lg')] = {
                padding: 24,
                marginBottom: 24,
                fontSize: 18,
                lineHeight: 1,
                fontWeight: 500,
                borderBottom: '1px solid rgba(142, 142, 142, 0.4)',
            },
            _b),
        switchButton: (_c = {
                marginLeft: 0,
                '& + &': {
                    marginTop: 3,
                }
            },
            _c[theme.breakpoints.up('lg')] = {
                marginRight: 32,
                '& + &': {
                    marginTop: 0,
                },
            },
            _c),
        switchButtonLabel: {
            fontWeight: 'normal',
            textAlign: 'center',
            fontSize: '12px',
            color: '#FFFFFF',
        },
        blockMargin: (_d = {
                padding: '0 16px',
                marginBottom: 16
            },
            _d[theme.breakpoints.up('lg')] = {
                padding: '0 24px',
                marginBottom: 24,
            },
            _d),
        optionsMainBlock: (_e = {
                position: 'absolute',
                width: '100%',
                maxHeight: 'calc(100% - 150px)',
                overflowY: 'auto'
            },
            _e[theme.breakpoints.up('lg')] = {
                position: 'static',
                maxHeight: 'initial',
                background: '#151515',
            },
            _e),
        button: (_f = {
                minWidth: '120px',
                width: '100%'
            },
            _f[theme.breakpoints.up('lg')] = {
                minWidth: '160px',
            },
            _f),
        moreLabel: {
            fontWeight: 'bold',
            fontSize: 16,
            lineHeight: '19px',
        },
    });
}, { name: 'Options' });
