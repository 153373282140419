import { LayoutOptions } from '~constants/view/LayoutOptions';
// returns id for groupping headers by
var getGroupId = function (optionName) {
    switch (optionName) {
        case LayoutOptions.OPTION_1:
        case LayoutOptions.OPTION_3: {
            return 1;
        }
        case LayoutOptions.OPTION_2:
        case LayoutOptions.OPTION_4: {
            return 2;
        }
        case LayoutOptions.OPTION_10:
        case LayoutOptions.OPTION_11: {
            return 3;
        }
        default: {
            return 0;
        }
    }
};
export { getGroupId };
