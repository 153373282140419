var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import classNames from "classnames";
import { useStyles } from './PaintedCircle.styles';
export var PaintedCircle = function (_a) {
    var paintedCount = _a.paintedCount, _b = _a.maxCount, maxCount = _b === void 0 ? 5 : _b, _c = _a.color, color = _c === void 0 ? '#FFFFFF' : _c, _d = _a.size, size = _d === void 0 ? 'md' : _d;
    var classes = useStyles();
    return (React.createElement("div", { className: classes.wrapper }, __spreadArray([], Array(maxCount), true).map(function (_, index) { return (React.createElement("div", { key: index, className: classNames(classes.circle, size === 'sm' && classes.small), style: index < paintedCount ? { background: color } : {} })); })));
};
