import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useStyles } from './Header.styles';
export var TournamentsDropDownTab;
(function (TournamentsDropDownTab) {
    TournamentsDropDownTab["TOURNAMENTS"] = "TOURNAMENTS";
    TournamentsDropDownTab["OUTRIGHTS"] = "OUTRIGHTS";
})(TournamentsDropDownTab || (TournamentsDropDownTab = {}));
var Header = function (_a) {
    var activeTab = _a.activeTab, onClick = _a.onClick, _b = _a.disabledTabs, disabledTabs = _b === void 0 ? [] : _b;
    var classes = useStyles();
    var t = useTranslation('common').t;
    var getTabName = function (tab) {
        switch (tab) {
            case TournamentsDropDownTab.OUTRIGHTS: {
                return t('outrights');
            }
            case TournamentsDropDownTab.TOURNAMENTS:
            default: {
                return t('tournaments');
            }
        }
    };
    var renderTab = function (tab) {
        var _a;
        var isTabDisabled = disabledTabs.includes(tab);
        var handleClick = function () {
            if (!isTabDisabled) {
                onClick(tab);
            }
        };
        return (React.createElement("div", { key: tab, className: classnames(classes.tab, (_a = {},
                _a[classes.active] = tab === activeTab,
                _a[classes.disabled] = isTabDisabled,
                _a)), role: "button", tabIndex: 0, onClick: handleClick },
            React.createElement("span", null, getTabName(tab))));
    };
    return (React.createElement("div", { className: classes.container },
        renderTab(TournamentsDropDownTab.TOURNAMENTS),
        renderTab(TournamentsDropDownTab.OUTRIGHTS)));
};
export { Header };
