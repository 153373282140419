var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useCallback, useEffect, useMemo, useRef, useState, } from 'react';
import Divider from '@material-ui/core/Divider';
import classNames from 'classnames';
import i18next from 'i18next';
import debounce from 'lodash.debounce';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';
import { API } from '~api';
import { Button, FullscreenLoader, HorizontalScroll, SmallLoader, } from '~atoms';
import { GameTypeButtons } from '~atoms/GameTypeButtons';
import { LOGIN_PRESS } from '~constants/cpa-message-codes';
import { useIsDesktop, useIsMobileDevise, usePlatofrmType, useStructuredSelector, useViewportHeight, } from '~hooks';
import { CSIcon } from '~icons/rankingList/CSIcon';
import { MODALS, closeModal, openModal } from '~modals';
import { SystemOverview } from '~molecules';
import { BetslipKeyboard } from '~molecules/BetslipKeyboard';
import { GameButtonSimple } from '~molecules/GameButtonSimple';
import { BetslipWagerArea } from '~organisms';
import { PaymentsURL } from '~pages/payments/payments.routes';
import { BetNowButton } from '~pages/ticketSelection/BetNowButton';
import { DeactivatePlaySessionButton } from '~pages/ticketSelection/DeactivatePlaySessionButton';
import { TopUpButton } from '~pages/ticketSelection/TopUpButton';
import { setActivePlaySession } from '~slices/activePlaySession/slice';
import { getActivePlaySession } from '~slices/activePlaySession/thunks';
import { isAuthenticatedSelector, isCookieAcceptedSelector, } from '~slices/auth';
import { addBanker, betslipDataSelector, connectSocket, deleteBanker, deleteFullBetslip, selectSelectedBetsCount, updateWager, } from '~slices/betslip';
import { BETSLIP_SOCKET_NAME, sendMessage } from '~slices/events';
import { getGames, setActiveGame, setGames, useActiveGameSelector, useGameTypesDataSelector, useSelectedGameDataSelector, useSelectedGroupSelector, useTournamentSelector, } from '~slices/gameTypes';
import { GAMES_MODE, GAME_PERIOD } from '~slices/gameTypes/constants';
import { changeHintState } from '~slices/hints';
import { sportNameSelector } from '~slices/options';
import { getDailyRanking, getWeeklyRanking } from '~slices/ranking';
import { resetShortcutBetlsip, shortcutCountSelector } from '~slices/shortcut';
import { selectSocketEnabledStatus } from '~slices/sockets';
import { PLATFORMS } from '~slices/teasers';
import { useAppDispatch } from '~store';
import { isBetCheckProject } from '~utils/constants/betCheckConstants';
import { Currencies, currencyConverter } from '~utils/converters';
import { getTestId } from '~utils/getTestId';
import { getTwoFirstGlobalGames } from '~utils/getTwoFirstGlobalGames';
import { getPathWithLanguageCode } from '~utils/languageInURL/getPathWithLanguageCode';
import { sortGamesByName } from '~utils/sortGamesByName';
import { focusWager } from '~utils/wager';
import { withSideEffects } from '~view/hoc/withSideEffects';
import { BetslipEvents } from '../BetslipEvents';
import { CheckNowButton } from '../CheckNowButton';
import ClearBetslipModal from '../components/clearConfirmation/ClearBetslipModal';
import { FastPlayButton } from '../FastPlayButton';
import { getWagerError } from '../getWagerError';
import { Nav } from '../Nav';
import { useStyles } from './TicketSelection.styles';
import { useLocationChange } from './useLocationChange';
var WEBSOCKET = process.env.REACT_APP_BASE_WEBSOCKET || '';
var MemoizedClearBetslipModal = React.memo(ClearBetslipModal);
var MemoizedBetslipEvents = React.memo(BetslipEvents);
var MemoizedNav = React.memo(Nav);
var TicketSelection = function (_a) {
    var _b, _c;
    var _d, _e, _f;
    var onClose = _a.onClose, t = _a.t, toRedirectToSidebets = _a.toRedirectToSidebets, parrent = _a.parrent, open = _a.open;
    var classes = useStyles();
    var tbj = useTranslation('tip-game').t;
    var isDesktop = useIsDesktop();
    var isMobile = useIsMobileDevise();
    var dispatch = useAppDispatch();
    var platform = usePlatofrmType();
    var _g = useStructuredSelector({
        activeGame: useActiveGameSelector,
        betslip: betslipDataSelector,
        cookieAccepted: isCookieAcceptedSelector,
        gameTypes: useGameTypesDataSelector,
        isAuthenticated: isAuthenticatedSelector,
        socketStatus: function (state) {
            return selectSocketEnabledStatus(state, BETSLIP_SOCKET_NAME);
        },
        selectedCount: selectSelectedBetsCount,
        selectedGame: useSelectedGameDataSelector,
        selectedGroup: useSelectedGroupSelector,
        shortcutCount: shortcutCountSelector,
        sportName: sportNameSelector,
        tournament: useTournamentSelector,
    }), activeGame = _g.activeGame, betslip = _g.betslip, cookieAccepted = _g.cookieAccepted, gameTypes = _g.gameTypes, isAuthenticated = _g.isAuthenticated, socketStatus = _g.socketStatus, selectedCount = _g.selectedCount, selectedGame = _g.selectedGame, selectedGroup = _g.selectedGroup, shortcutCount = _g.shortcutCount, sportName = _g.sportName, tournament = _g.tournament;
    var globalGames = useMemo(function () {
        var _a;
        return (_a = getTwoFirstGlobalGames(gameTypes)) === null || _a === void 0 ? void 0 : _a.filter(function (game) {
            return (tournament === null || tournament === void 0 ? void 0 : tournament.pathname) ? game.tournament : !game.tournament;
        });
    }, [gameTypes]);
    var vh = useViewportHeight();
    var ticketOffer = betslip.to;
    var _h = useState(false), clearModaIslOpen = _h[0], setClearModalIsOpen = _h[1];
    var _j = useState(false), isScrolled = _j[0], setIsScrolled = _j[1];
    var _k = useState(false), isPurchaseTicketLoading = _k[0], setIsPurchaseTicketLoading = _k[1];
    var _l = useState([null]), purchaseGamesTicket = _l[0], setPurchaseGamesTickets = _l[1];
    var redirectToPayments = function () {
        return dispatch(push(getPathWithLanguageCode("/".concat(PaymentsURL.TOP_IT_UP, "?type=").concat(activeGame))));
    };
    var inputWagerValueRef = useRef(typeof (ticketOffer === null || ticketOffer === void 0 ? void 0 : ticketOffer.wa) === 'number' ? String(ticketOffer.wa) : '');
    var _m = useState(false), shouldDisplayWagerError = _m[0], setShouldDisplayWagerError = _m[1];
    var mainBlockRef = useRef(null);
    var checkNowButtonRef = useRef(null);
    var convertedMaxPayout = currencyConverter(ticketOffer === null || ticketOffer === void 0 ? void 0 : ticketOffer.mp);
    var _o = useState(''), wagerError = _o[0], setWagerError = _o[1];
    var _p = useState(false), isActivePlaySession = _p[0], setIsActivePlaySession = _p[1];
    var _q = useState(NaN), activePlaySessionProviderId = _q[0], setActivePlaySessionProviderId = _q[1];
    var _r = useState(true), isBetslipKeyboardActive = _r[0], setIsBetslipKeyboardActive = _r[1];
    var _s = useState(undefined), mobileInputValue = _s[0], setMobileInputValue = _s[1];
    var _t = useState(null), gamesBalance = _t[0], setGamesBalance = _t[1];
    var isBetslipKeyboardDisplay = !isDesktop && isBetslipKeyboardActive;
    var _u = useState(false), isWagerLoading = _u[0], setIsWagerLoading = _u[1];
    var isNeedTopUpBalance = useMemo(function () {
        return isAuthenticated &&
            !isBetCheckProject &&
            (ticketOffer === null || ticketOffer === void 0 ? void 0 : ticketOffer.wa) &&
            activeGame &&
            gamesBalance
            ? (ticketOffer === null || ticketOffer === void 0 ? void 0 : ticketOffer.wa) > gamesBalance[activeGame]
            : false;
    }, [isAuthenticated, ticketOffer === null || ticketOffer === void 0 ? void 0 : ticketOffer.wa, activeGame]);
    var isGameTypeButtonsDisplay = !selectedGame && globalGames.length > 1;
    var isZeroBalance = !isBetCheckProject &&
        !!gamesBalance &&
        !!activeGame &&
        gamesBalance[activeGame] === 0;
    var filteredGames = gameTypes
        .filter(function (game) {
        return game.groupName || game.gameMode === GAMES_MODE.GLOBAL;
    })
        .filter(function (game) {
        return game.active &&
            (!game.tournament ||
                (game.tournament && !selectedGroup && game.name === selectedGame) ||
                (game.tournament &&
                    selectedGroup &&
                    game.groupName === selectedGroup));
    });
    var filteredGamesLength = filteredGames.filter(function (game) { return game.playerBalance !== 0; }).length;
    var sortedGames = sortGamesByName(filteredGames, selectedGroup || selectedGame || activeGame || '');
    useEffect(function () {
        var gameValue = open || isDesktop ? selectedGroup || selectedGame || activeGame : null;
        setPurchaseGamesTickets([gameValue]);
    }, [open, isDesktop, selectedGroup, selectedGame, activeGame]);
    useEffect(function () {
        if (!isBetCheckProject && globalGames) {
            var updatedGamesBalance_1 = __assign({}, gamesBalance);
            globalGames.forEach(function (game) {
                if (game.hasOwnProperty('playerBalance') &&
                    typeof game.playerBalance === 'number') {
                    updatedGamesBalance_1[game.name] = game.playerBalance;
                }
                else {
                    updatedGamesBalance_1[game.name] = game.startCredits;
                }
            });
            setGamesBalance(updatedGamesBalance_1);
        }
    }, [globalGames]);
    useEffect(function () {
        if (!isBetCheckProject && !selectedGame && globalGames.length) {
            if (!globalGames[0].active && Object.keys(globalGames).length > 1) {
                dispatch(setActiveGame(globalGames[1].name));
            }
        }
    }, [globalGames]);
    useEffect(function () {
        if (isAuthenticated && isBetCheckProject) {
            dispatch(getActivePlaySession()).then(function (_a) {
                var payload = _a.payload;
                var activePlaySessionData = payload !== null && payload !== void 0 ? payload : {};
                setActivePlaySession(activePlaySessionData);
                setActivePlaySessionProviderId(!!payload ? payload.id : NaN);
                setIsActivePlaySession(!!payload);
            });
        }
    }, []);
    useEffect(function () {
        if (!isBetCheckProject && (isDesktop || open)) {
            dispatch(getGames(i18next.language)).then(function (_a) {
                var payload = _a.payload;
                setGames(payload);
            });
        }
    }, [open, isAuthenticated]);
    useEffect(function () {
        var _a;
        if (ticketOffer === null || ticketOffer === void 0 ? void 0 : ticketOffer.wa) {
            setShouldDisplayWagerError(true);
            if (isMobile && mobileInputValue === undefined) {
                setMobileInputValue((_a = ticketOffer === null || ticketOffer === void 0 ? void 0 : ticketOffer.wa) === null || _a === void 0 ? void 0 : _a.toString());
            }
        }
    }, [ticketOffer === null || ticketOffer === void 0 ? void 0 : ticketOffer.wa]);
    useEffect(function () {
        if (!open && !isDesktop) {
            return;
        }
        var checkNowButton = checkNowButtonRef.current;
        var mainBlock = mainBlockRef.current;
        if (checkNowButton &&
            (mainBlock === null || mainBlock === void 0 ? void 0 : mainBlock.scrollHeight) &&
            !isScrolled &&
            !isDesktop) {
            if (parrent && parrent.current) {
                // eslint-disable-next-line no-param-reassign
                parrent.current.scrollTop += parrent.current.scrollHeight;
            }
            checkNowButton.scrollIntoView();
            setIsScrolled(true);
        }
    }, [open, isDesktop]);
    useEffect(function () {
        if (socketStatus) {
            dispatch(sendMessage({
                betSlipId: betslip.bs,
                eventsType: BETSLIP_SOCKET_NAME,
                url: "".concat(WEBSOCKET, "sport-events/v1/ws/betSlip"),
                langIso: i18next.language,
                withOutMessage: true,
                withOutInterval: true,
            }));
        }
    }, [i18next.language]);
    useEffect(function () {
        var tId;
        var shouldReopen = false;
        var handleVisibilityChange = function () {
            if (document.hidden) {
                tId = setTimeout(function () {
                    dispatch({ type: 'SOCKET_CLOSE', eventsType: BETSLIP_SOCKET_NAME });
                    shouldReopen = true;
                }, Number(process.env.REACT_APP_SOCKET_BACKGROUND_CLOSE_TIME) * 1000);
            }
            else if (shouldReopen) {
                dispatch(connectSocket({
                    betSlipId: betslip.bs,
                    eventsType: BETSLIP_SOCKET_NAME,
                    url: "".concat(WEBSOCKET, "sport-events/v1/ws/betSlip"),
                    langIso: i18next.language,
                    withOutMessage: true,
                    withOutInterval: true,
                }));
                shouldReopen = false;
            }
            else {
                clearTimeout(tId);
            }
        };
        if (typeof document.addEventListener === 'undefined') {
            console.log('This page requires a browser, that supports the Page Visibility API.');
        }
        else {
            document.addEventListener('visibilitychange', handleVisibilityChange, false);
        }
        return function () {
            document.removeEventListener('visibilitychange', handleVisibilityChange, false);
        };
    }, [betslip.bs]);
    useEffect(function () {
        if (open && selectedCount === 0) {
            setMobileInputValue('');
        }
    }, [selectedCount]);
    useEffect(function () {
        if (shortcutCount === 0) {
            setMobileInputValue('');
            dispatch(updateWager({
                wager: 0,
                wagerType: 'T',
                eventsType: BETSLIP_SOCKET_NAME,
            }));
        }
    }, [shortcutCount]);
    var detailsHeightChange = useCallback(function () {
        var mainBlock = mainBlockRef.current;
        if (mainBlock) {
            mainBlock.scrollIntoView();
        }
    }, [(_d = mainBlockRef.current) === null || _d === void 0 ? void 0 : _d.offsetHeight]);
    var clearBetslipClick = useCallback(function () {
        setClearModalIsOpen(true);
    }, []);
    var bankerClickHandler = useCallback(function (add, id) {
        if (add) {
            dispatch(addBanker({ eventId: id }));
        }
        else {
            dispatch(deleteBanker({ eventId: id }));
        }
    }, []);
    var closeClearModal = useCallback(function () {
        setClearModalIsOpen(false);
    }, []);
    var clearBetslip = useCallback(function () {
        var toTipPath = (tournament === null || tournament === void 0 ? void 0 : tournament.pathname) ||
            getPathWithLanguageCode("/home/all/live/".concat(sportName === null || sportName === void 0 ? void 0 : sportName.name));
        setClearModalIsOpen(false);
        setMobileInputValue('');
        dispatch(deleteFullBetslip({ eventsType: BETSLIP_SOCKET_NAME })).then(function () {
            if (onClose) {
                onClose();
            }
            dispatch(resetShortcutBetlsip());
            if (!isDesktop) {
                dispatch(push(toTipPath));
            }
        });
    }, [onClose, isDesktop, isAuthenticated]);
    var getRankingLists = function () {
        if (!globalGames || !Object.keys(globalGames).length) {
            return;
        }
        var dailyGame = globalGames.find(function (game) { return game.gamePeriod === GAME_PERIOD.DAILY; });
        if (dailyGame)
            dispatch(getDailyRanking({ baseURL: dailyGame.url, gameName: dailyGame === null || dailyGame === void 0 ? void 0 : dailyGame.name }));
        var weeklyGame = globalGames.find(function (game) { return game.gamePeriod === GAME_PERIOD.WEEKLY; });
        if (weeklyGame)
            dispatch(getWeeklyRanking({
                baseURL: weeklyGame.url,
                gameName: weeklyGame === null || weeklyGame === void 0 ? void 0 : weeklyGame.name,
            }));
    };
    var gameTicketsHandleConfirm = function () {
        dispatch(getGames(i18next.language)).then(function (_a) {
            var payload = _a.payload;
            setGames(payload);
        });
        if (isDesktop) {
            clearBetslip();
            getRankingLists();
        }
        else {
            setMobileInputValue('');
            dispatch(deleteFullBetslip({ eventsType: BETSLIP_SOCKET_NAME })).then(function () {
                dispatch(resetShortcutBetlsip());
                dispatch(push(getPathWithLanguageCode('/ranking-list')));
            });
        }
    };
    var onWagerInputClick = useCallback(function () {
        if (!isMobile) {
            return;
        }
        setIsBetslipKeyboardActive(true);
        setMobileInputValue('');
        inputWagerValueRef.current = '0';
        if ((ticketOffer === null || ticketOffer === void 0 ? void 0 : ticketOffer.wa) !== 0) {
            setIsWagerLoading(true);
            dispatch(updateWager({
                wager: 0,
                wagerType: 'T',
                eventsType: BETSLIP_SOCKET_NAME,
            })).then(function () {
                checkWagerValue('0');
                setIsWagerLoading(false);
            });
        }
    }, [isMobile, isBetslipKeyboardActive, ticketOffer === null || ticketOffer === void 0 ? void 0 : ticketOffer.wa]);
    var shouldDisplaySystemOverview = selectedCount > 1;
    var getWagerErrorText = function (value) {
        var _a = getWagerError({
            value: value,
            betlines: selectedCount,
        }), error = _a.error, highestValue = _a.highestValue, lowestValue = _a.lowestValue;
        var currency = (ticketOffer === null || ticketOffer === void 0 ? void 0 : ticketOffer.cu) !== 'CRD' ? ticketOffer === null || ticketOffer === void 0 ? void 0 : ticketOffer.cu : '';
        return t(error, {
            lowestValue: lowestValue,
            highestValue: highestValue,
            currency: currency,
            betlinesCount: selectedCount,
        });
    };
    var checkWagerValue = function (value) {
        var errorText = getWagerErrorText(value ? Number(value) : NaN);
        setWagerError(errorText);
        return errorText;
    };
    var onCopyTicketClick = function (gameName, isBlocked) {
        if (isBlocked) {
            dispatch(openModal({
                modalName: MODALS.ERROR_MODAL,
                modalProps: {
                    isOpen: true,
                    title: t('selection_game_error.title'),
                    subheader: t('selection_game_error.description', { gameName: gameName }),
                    handleConfirm: function () {
                        dispatch(closeModal({ key: MODALS.ERROR_MODAL }));
                    },
                },
            }));
            return;
        }
        setPurchaseGamesTickets(function (prevGames) {
            if (prevGames.includes(gameName)) {
                return prevGames.filter(function (name) { return name !== gameName; });
            }
            else {
                return __spreadArray(__spreadArray([], prevGames, true), [gameName], false);
            }
        });
    };
    var onSelectAllGamesClick = function () {
        var gameNames = [];
        if (filteredGamesLength === purchaseGamesTicket.length) {
            gameNames.push(purchaseGamesTicket[0]);
        }
        else {
            sortedGames
                .filter(function (game) { return game.playerBalance !== 0; })
                .map(function (game) {
                return game.groupName
                    ? gameNames.push(game.groupName)
                    : gameNames.push(game.name);
            });
        }
        setPurchaseGamesTickets(gameNames);
    };
    useEffect(function () {
        checkWagerValue(inputWagerValueRef.current);
    }, [selectedCount]);
    useEffect(function () {
        if (isMobile && mobileInputValue) {
            checkWagerValue(mobileInputValue);
        }
    }, [mobileInputValue]);
    var debouncedUpdateWager = useMemo(function () {
        return debounce(function (value) {
            setIsWagerLoading(true);
            dispatch(updateWager({
                wager: Number(value.replace(',', '.')) || undefined,
                wagerType: 'T',
                eventsType: BETSLIP_SOCKET_NAME,
            })).then(function () { return setIsWagerLoading(false); });
        }, 300);
    }, []);
    var handleChangeWager = function (value) {
        setShouldDisplayWagerError(true);
        debouncedUpdateWager(value);
    };
    useEffect(function () {
        if (!(ticketOffer === null || ticketOffer === void 0 ? void 0 : ticketOffer.wa)) {
            return;
        }
        handleChangeWager(ticketOffer.wa.toString());
    }, []);
    var handleValidate = function (value) {
        inputWagerValueRef.current = value;
        checkWagerValue(inputWagerValueRef.current);
    };
    var addGAEvent = function () {
        if (inputWagerValueRef.current) {
            ReactGA.event('add_amount');
            ReactPixel.trackCustom('add_amount');
        }
    };
    var openLoginModal = function () {
        if (cookieAccepted) {
            dispatch(openModal({
                modalName: MODALS.LOGIN_MODAL,
                modalProps: {
                    isOpen: true,
                    onClose: function () {
                        dispatch(closeModal({ key: MODALS.LOGIN_MODAL }));
                    },
                    onSubmit: function () {
                        dispatch(closeModal({ key: MODALS.LOGIN_MODAL }));
                    },
                },
            }));
        }
        else if (platform === PLATFORMS.CROSS_PLATFORM_MOBILE) {
            // @ts-expect-error
            window.ReactNativeWebView.postMessage(JSON.stringify({
                code: LOGIN_PRESS,
            }));
        }
        else {
            dispatch(changeHintState({ isCookiesConsentHintEnabled: true }));
        }
    };
    var redirectToTopRanking = function (isFastPlay, isDeactivationProcess) {
        if (isFastPlay === void 0) { isFastPlay = false; }
        if (isDeactivationProcess === void 0) { isDeactivationProcess = false; }
        return function () {
            var _a;
            var isWagerError = !!checkWagerValue(inputWagerValueRef.current);
            setShouldDisplayWagerError(true);
            if (!isAuthenticated && !isBetCheckProject) {
                openLoginModal();
                return;
            }
            if (isWagerError || wagerError) {
                focusWager();
                return;
            }
            if (!isBetCheckProject) {
                var maxOdd_1 = ((_a = betslip.to) === null || _a === void 0 ? void 0 : _a.mo) || NaN;
                var filteredGames_1 = gameTypes.filter(function (game) {
                    return purchaseGamesTicket.includes(game.groupName || game.name) && game.active;
                });
                if (!filteredGames_1.length) {
                    return;
                }
                var purchaseTicketsResult_1 = [];
                var app_version_1 = process.env.REACT_APP_VERSION || '';
                var lang_1 = i18next.language;
                var onPurchaseTicket_1 = function (gameUrl, maxOdds, gameName, groupName, game) { return __awaiter(void 0, void 0, void 0, function () {
                    var res, _a;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, API.postGameTickets({
                                        app_version: app_version_1,
                                        lang: lang_1,
                                        gameUrl: gameUrl,
                                        maxOdds: maxOdds,
                                        gameName: gameName,
                                        groupName: groupName,
                                    })];
                            case 1:
                                res = _b.sent();
                                return [2 /*return*/, {
                                        success: (res === null || res === void 0 ? void 0 : res.status) >= 200 && (res === null || res === void 0 ? void 0 : res.status) < 300,
                                        game: game,
                                    }];
                            case 2:
                                _a = _b.sent();
                                return [2 /*return*/, {
                                        success: false,
                                        game: game,
                                    }];
                            case 3: return [2 /*return*/];
                        }
                    });
                }); };
                setIsPurchaseTicketLoading(true);
                Promise.all(filteredGames_1.map(function (game) {
                    var gameUrl = game.url;
                    var gameName = game.name;
                    var groupName = game.groupName || '';
                    return onPurchaseTicket_1(gameUrl, maxOdd_1, gameName, groupName, game);
                }))
                    .then(function (results) {
                    purchaseTicketsResult_1.push.apply(purchaseTicketsResult_1, results);
                })
                    .finally(function () {
                    setIsPurchaseTicketLoading(false);
                    dispatch(openModal({
                        modalName: MODALS.PURCHASE_TICKETS_RESULT_MODAL,
                        modalProps: {
                            result: purchaseTicketsResult_1,
                            handleClose: function () { return gameTicketsHandleConfirm(); },
                        },
                    }));
                });
                return;
            }
            if (isDeactivationProcess) {
                dispatch(push(getPathWithLanguageCode('/top-ranking'), {
                    activePlaySessionProviderId: activePlaySessionProviderId,
                    isDeactivationProcess: isDeactivationProcess,
                }));
                return;
            }
            isFastPlay
                ? dispatch(push(getPathWithLanguageCode('/top-ranking'), {
                    isFastPlay: isFastPlay,
                    wagerValue: Number(inputWagerValueRef.current),
                    activePlaySessionProviderId: activePlaySessionProviderId,
                }))
                : dispatch(push(getPathWithLanguageCode('/top-ranking')));
        };
    };
    useEffect(function () {
        if (open && !isDesktop) {
            inputWagerValueRef.current =
                typeof (ticketOffer === null || ticketOffer === void 0 ? void 0 : ticketOffer.wa) === 'number' ? String(ticketOffer.wa) : '';
            checkWagerValue(inputWagerValueRef.current);
            setTimeout(function () {
                focusWager();
            }, 100);
        }
    }, [open]);
    return (React.createElement("div", { "data-testid": getTestId('FRM_BETSLIP') },
        isPurchaseTicketLoading && React.createElement(FullscreenLoader, null),
        React.createElement(MemoizedNav, { onClose: onClose, clearBetslipOnClick: clearBetslipClick, clearButtonActive: !!((_e = betslip.to) === null || _e === void 0 ? void 0 : _e.evs) }),
        React.createElement("div", { className: classes.root },
            React.createElement("div", { ref: mainBlockRef, className: classNames(classes.mainBlock, (_b = {},
                    _b[classes.mainBlockMobile] = !isDesktop,
                    _b)), style: {
                    maxHeight: "".concat(vh - 52, "px"),
                } },
                React.createElement(MemoizedBetslipEvents, { onClose: onClose, toRedirectToSidebets: toRedirectToSidebets, onBankerClick: bankerClickHandler }),
                betslip.ssua && (React.createElement("div", { className: classes.notification },
                    React.createElement("div", null,
                        React.createElement("i", { className: "fas fa-exclamation-triangle" })),
                    React.createElement("div", { className: classes.notificationText },
                        React.createElement("span", null, t('notification_text'))))),
                React.createElement("div", { className: classNames(classes.blackBackground, classes.stickyPosition, (_c = {}, _c[classes.desktopBackground] = isDesktop, _c), 'flex-column') },
                    React.createElement(BetslipWagerArea, { onChangeWager: handleChangeWager, onValidate: handleValidate, onBluer: addGAEvent, wa: ticketOffer === null || ticketOffer === void 0 ? void 0 : ticketOffer.wa, wagerError: shouldDisplayWagerError ? wagerError : '', wagerValue: mobileInputValue, onInputClick: onWagerInputClick }),
                    React.createElement("div", { className: classNames(classes.row, classes.dark, classes.noPaddingTop, classes.noPaddingBottom) },
                        React.createElement("div", { className: classes.column },
                            React.createElement("div", { className: classes.maxPayoutTitle },
                                t('max_payout'),
                                "\u00A0")),
                        React.createElement("div", { className: classes.column }, isWagerLoading ? (React.createElement("div", { className: classes.loaderWrapper },
                            React.createElement(SmallLoader, null))) : (React.createElement("div", { className: classNames(classes.maxPayout, !isBetCheckProject && classes.justifyCenter) },
                            convertedMaxPayout,
                            isBetCheckProject ? (React.createElement("small", null, (_f = Currencies[ticketOffer === null || ticketOffer === void 0 ? void 0 : ticketOffer.cu]) !== null && _f !== void 0 ? _f : ticketOffer === null || ticketOffer === void 0 ? void 0 : ticketOffer.cu)) : (React.createElement(CSIcon, { size: "md" })))))),
                    React.createElement("div", { className: classNames(
                        // classes.row,
                        classes.noPaddingTop, classes.noPaddingBottom, classes.dark) }, isBetslipKeyboardDisplay ? (React.createElement(BetslipKeyboard, { inputValue: mobileInputValue, setInputValue: setMobileInputValue, setIsKeyboardDisplay: setIsBetslipKeyboardActive, setIsWagerLoading: setIsWagerLoading })) : (React.createElement("div", { className: classNames(isMobile && classes.actionBlock) },
                        !isBetCheckProject && (React.createElement(React.Fragment, null,
                            sortedGames.length ? (React.createElement("div", { className: classNames(classes.gameCheckboxesWrapper, isDesktop && classes.gamesCheckboxMaxWidth) },
                                React.createElement("div", { role: "button", tabIndex: 0, className: classNames(classes.allButton, filteredGamesLength ===
                                        purchaseGamesTicket.length &&
                                        classes.allButtonActive), onClick: onSelectAllGamesClick },
                                    React.createElement("span", null, t('tickets_tab.all'))),
                                React.createElement(HorizontalScroll, { elementsCount: gameTypes.length, focusedIndex: 0, elementWidth: 74 }, sortedGames.map(function (game, index) {
                                    var isDisabled = (Boolean(selectedGroup) &&
                                        selectedGroup ===
                                            game.groupName) ||
                                        (Boolean(selectedGame) &&
                                            globalGames.some(function (game) { return game.name === selectedGame; }) &&
                                            selectedGame === game.name) ||
                                        (Boolean(activeGame) &&
                                            globalGames.some(function (game) { return game.name === activeGame; }) &&
                                            activeGame === game.name);
                                    var isSelected = purchaseGamesTicket.includes(game.groupName || game.name) || isDisabled;
                                    var isBlocked = game.playerBalance === 0;
                                    return (React.createElement("div", { key: index, className: classes.checkboxButtonWrapper },
                                        React.createElement(GameButtonSimple, { game: game, isActive: isSelected, isDisabled: isDisabled, isBlocked: isBlocked, onClick: onCopyTicketClick })));
                                })))) : null,
                            isGameTypeButtonsDisplay && (React.createElement("div", { className: classes.gameTypeButtonsWrapper },
                                React.createElement(GameTypeButtons, { games: globalGames, activeGame: activeGame || undefined, onClick: function (game) { return dispatch(setActiveGame(game)); } }))),
                            isZeroBalance ? (React.createElement("div", { className: classNames(classes.buttonWrapper, classes.fullWidth) },
                                React.createElement(Button, { color: "primary", variant: "contained", fullWidth: true, disabled: true }, tbj('wait_or_play_another_game')))) : (React.createElement("div", { ref: checkNowButtonRef, className: "".concat(classes.buttonWrapper, " ").concat(classes.fullWidth) }, isNeedTopUpBalance ? (React.createElement(TopUpButton, { isTicketSelection: true, onTopUpClick: redirectToPayments })) : (React.createElement(BetNowButton, { onBetNowClick: redirectToTopRanking() })))))),
                        isBetCheckProject && (React.createElement(React.Fragment, null,
                            !isActivePlaySession && (React.createElement("div", { ref: checkNowButtonRef, className: "".concat(classes.buttonWrapper, " ").concat(classes.fullWidth) },
                                React.createElement(CheckNowButton, { onClose: onClose, redirectToTopRanking: redirectToTopRanking() }))),
                            React.createElement("div", { className: "".concat(classes.buttonWrapper, " ").concat(classes.fullWidth) },
                                React.createElement(FastPlayButton, { redirectToTopRanking: redirectToTopRanking(true), isBPLogo: isActivePlaySession })),
                            isActivePlaySession && (React.createElement("div", { className: "".concat(classes.buttonWrapper, " ").concat(classes.fullWidth) },
                                React.createElement(DeactivatePlaySessionButton, { redirectToTopRanking: redirectToTopRanking(false, true) })))))))),
                    isDesktop && shouldDisplaySystemOverview && (React.createElement(Divider, { className: classes.divider })),
                    shouldDisplaySystemOverview && !isBetslipKeyboardActive && (React.createElement(SystemOverview, __assign({ onClickHandle: detailsHeightChange }, ticketOffer, { newClasses: true }))))),
            React.createElement(MemoizedClearBetslipModal, { isOpen: clearModaIslOpen, clearBetslip: clearBetslip, onClose: closeClearModal }))));
};
var TicketSelectionWithSideEffects = withSideEffects([useLocationChange])(TicketSelection);
export { TicketSelectionWithSideEffects as TicketSelection };
