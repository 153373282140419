var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { useStructuredSelector } from '~hooks';
import { CSIcon } from '~icons/rankingList/CSIcon';
import activeStar from '~icons/star-active.svg';
import star from '~icons/star.svg';
import { useIsEasyGameSelector, useIsEasyTipSelector } from '~slices/gameTypes';
import { useAppDispatch } from '~store';
import { formatLargeNumber } from '~utils/formatLargeNumber';
import { getPathWithLanguageCode } from '~utils/languageInURL';
import { numberWithDots } from '~utils/numberWithDots';
import { useStyles } from './UserCard.styles';
var mainPath = process.env.REACT_CDN_ORIGIN || '';
var MAX_STARS_NUMBER = 5;
export var UserCard = function (_a) {
    var level = _a.level, rank = _a.rank, userName = _a.userName, avatar = _a.avatar, avatarUrl = _a.avatarUrl, _b = _a.stars, stars = _b === void 0 ? 0 : _b, balance = _a.balance, background = _a.background, currentUserRank = _a.currentUserRank, currentUserLevel = _a.currentUserLevel, ranks = _a.ranks, gameInfo = _a.gameInfo, isGroupRanking = _a.isGroupRanking, isGlobalRanking = _a.isGlobalRanking, isRankingListEmpty = _a.isRankingListEmpty, showJoinGameModal = _a.showJoinGameModal;
    var classes = useStyles();
    var dispatch = useAppDispatch();
    var t = useTranslation('tip-game').t;
    var _c = useStructuredSelector({
        isEasyGame: useIsEasyGameSelector,
        isEasyTip: useIsEasyTipSelector,
    }), isEasyGame = _c.isEasyGame, isEasyTip = _c.isEasyTip;
    var isCurrentUser = (currentUserLevel && currentUserLevel === level) ||
        (currentUserRank && currentUserRank === rank);
    var balanceValue = isGlobalRanking
        ? formatLargeNumber(balance || 0)
        : numberWithDots(balance || 0);
    var hasNotPlayed = !balance && !level && !rank;
    var isBalance = balance || balance === 0;
    var renderText = function () {
        if (isGroupRanking) {
            return;
        }
        if (isRankingListEmpty) {
            return React.createElement("span", { className: classes.description }, t('coming_soon'));
        }
        if (hasNotPlayed) {
            return (React.createElement("span", { className: classes.description }, t('ranking-list.not_played')));
        }
    };
    var renderLevel = function () {
        var levelText = !level && !rank
            ? null
            : isGlobalRanking || isGroupRanking
                ? t('rank')
                : t('level');
        var levelNumber = !level && !rank ? null : isGlobalRanking || isGroupRanking ? rank : level;
        return levelText ? (React.createElement("div", { className: classes.level }, "".concat(levelText, " ").concat(levelNumber))) : (React.createElement("div", { className: classes.gameName, style: { background: gameInfo === null || gameInfo === void 0 ? void 0 : gameInfo.color } }, gameInfo === null || gameInfo === void 0 ? void 0 : gameInfo.name));
    };
    var isJoinGameButton = !hasNotPlayed &&
        !isGlobalRanking &&
        !isRankingListEmpty &&
        !level &&
        !!showJoinGameModal;
    var isShowTicketButton = !isJoinGameButton &&
        !isEasyGame &&
        !isEasyTip &&
        !isGlobalRanking &&
        !renderText();
    var onShowTipsClick = function (e) {
        e.stopPropagation();
        dispatch(push(getPathWithLanguageCode('/my-tips')));
    };
    return (React.createElement("div", { className: classNames(classes.container, isCurrentUser && classes.greenBorder, (ranks === null || ranks === void 0 ? void 0 : ranks.length) && classes.pointer) },
        React.createElement("div", { className: classes.avatarCard, style: { background: background } },
            React.createElement("div", { className: classes.avatarNameWrapper },
                React.createElement("span", { className: classes.avatarName }, avatar)),
            React.createElement("div", { className: classes.avatarWrapper },
                React.createElement("img", { src: avatarUrl ? "".concat(mainPath).concat(avatarUrl) : '', alt: "user avatar" })),
            React.createElement("div", { className: classes.starsWrapper },
                __spreadArray([], Array(stars), true).map(function (_, index) { return (React.createElement("img", { src: activeStar, alt: 'active star icon', key: index })); }),
                __spreadArray([], Array(MAX_STARS_NUMBER - stars), true).map(function (_, index) { return (React.createElement("img", { src: star, alt: 'star icon', key: index })); }))),
        React.createElement("div", { className: classes.wrapper, style: { background: background } },
            renderLevel(),
            React.createElement("div", { className: classes.fullWidth },
                React.createElement("div", { className: classes.userName }, userName),
                React.createElement(React.Fragment, null, renderText()),
                React.createElement("div", { className: classes.creditsContainer, style: isRankingListEmpty ? { marginTop: 4 } : undefined },
                    isBalance && (React.createElement("div", { className: classes.creditsWrapper },
                        React.createElement("div", { className: classes.creditsText }, isGlobalRanking
                            ? t('ranking-list.status_credits')
                            : !level
                                ? t('bonus_amount')
                                : t('ranking-list.my_credits')),
                        React.createElement("span", { className: classes.credits },
                            React.createElement("span", { className: classes.jackpotCount }, balanceValue),
                            React.createElement(CSIcon, { size: 'sm' })))),
                    isShowTicketButton && (React.createElement("div", { role: "button", tabIndex: 0, className: classes.tipsButton, onClick: function (e) { return onShowTipsClick(e); } }, t('show_ticket'))))),
            isJoinGameButton && (React.createElement("div", { tabIndex: 0, role: "button", className: classes.joinGameButton, onClick: showJoinGameModal }, t('join_the_game'))))));
};
