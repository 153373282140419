import makeStyles from '@material-ui/core/styles/makeStyles';
var useStyles = makeStyles(function () { return ({
    root: {
        color: '#fff',
    },
    stickyPosition: {
        position: 'sticky',
        bottom: 0,
    },
    dark: {
        backgroundColor: '#151515',
    },
    row: {
        padding: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    inputRow: {
        background: '#222222',
        justifyContent: 'center',
    },
    column: {
        padding: '8px',
        boxSizing: 'border-box',
    },
    buttonWrapper: {
        padding: '0px 8px 8px 8px',
        boxSizing: 'border-box',
        '& button:disabled': {
            color: '#FFFFFF',
        },
    },
    gameTypeButtonsWrapper: {
        minHeight: 74,
        padding: '0 8px 18px 8px',
    },
    justifyCenter: {
        display: 'flex',
        alignItems: 'center',
        '& img': {
            height: '22px',
            width: '22px',
            marginLeft: '5px',
        },
    },
    fullWidth: { width: '100%' },
    halfWidth: { width: '50%' },
    endIcon: {
        position: 'absolute',
        top: 0,
        right: 0,
        transform: 'translate(50%, -50%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '20px',
        height: '20px',
        background: '#FFFFFF',
        border: '1px solid rgba(33, 33, 33, 0.2)',
        boxSizing: 'border-box',
        borderRadius: '50%',
        textAlign: 'center',
        verticalAlign: 'middle',
        '& > *:first-child': {
            fontSize: '8px',
        },
    },
    maxPayout: {
        fontWeight: 500,
        fontSize: '24px',
        lineHeight: 1.25,
        '& small': {
            fontSize: '.75em',
            marginLeft: '5px',
        },
        '& svg': {
            height: '15px',
            width: '17px',
            marginLeft: '8px',
        },
    },
    maxPayoutTitle: {
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: 1.5,
    },
    loaderWrapper: {
        display: 'flex',
        height: '30px',
    },
    desktopIconSize: {
        '& > i': {
            fontSize: '22px',
        },
    },
    card: {
        cursor: 'pointer',
        marginBottom: '8px',
        padding: '12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: '#484848',
        border: '1px solid #3E3E3E',
        boxSizing: 'border-box',
        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.05)',
        color: '#8E8E8E',
        borderRadius: '4px',
        '&.blueOutline': {
            border: '2px solid #2AA1F7',
        },
    },
    errorBlock: { borderColor: '#FF0000' },
    cardContent: {
        width: '100%',
        paddingRight: '24px',
        '&:first-child': {
            paddingRight: 0,
        },
    },
    cardAmount: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '2px',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '19px',
        whiteSpace: 'nowrap',
        color: '#FFFFFF',
        '&:last-child': {
            marginBottom: 0,
        },
    },
    maskedInput: {
        border: 'none',
        background: 'transparent',
        width: '100%',
        '&:focus': {
            outline: 'none',
        },
    },
    cardAmountOnlyWager: {
        color: '#FFFFFF',
    },
    cardAmountPrefix: {
        paddingRight: '.25em',
    },
    cardAmountCurency: {
        fontSize: "".concat(14 / 20, "em"),
    },
    cardInputRoot: {
        paddingRight: '.25em',
        fontSize: 'inherit',
        color: 'inherit',
        lineHeight: '19px',
    },
    bwInput: {
        maxWidth: '60px',
    },
    cardTitle: {
        fontSize: '12px',
        lineHeight: '14px',
    },
    cardIconWrap: {
        fontSize: '32px',
        color: '#8E8E8E',
    },
    cardIconWrapWager: {
        padding: '0 12px 0 0',
        fontSize: '32px',
        color: '#8E8E8E',
    },
    cardIconWrapBetWager: {
        padding: '0 0 0 12px',
        fontSize: '32px',
        color: '#8E8E8E',
    },
    noPaddingTop: {
        paddingTop: '0 !important',
    },
    noPaddingBottom: {
        paddingBottom: '0 !important',
    },
    noPaddingLeft: {
        paddingLeft: '0 !important',
    },
    noPaddingRight: {
        paddingRight: '0 !important',
    },
    blackBackground: {
        background: '#151515',
    },
    desktopBackground: {
        background: '#222222',
        zIndex: 1,
    },
    colorPrimary: {
        color: '#2AA1F7',
    },
    colorSuccess: {
        color: '#00C150',
    },
    colorTextLight1: {
        color: '#707070',
    },
    colorTextLight2: {
        color: '#8E8E8E',
    },
    textRight: {
        textAlign: 'right',
    },
    mainBlock: {
        transition: 'all .2s',
        opacity: 1,
        '&.hidden': {
            opacity: 0,
        },
        '&.closing': {
            transition: 'all .1s',
            opacity: 0,
        },
    },
    actionBlock: {
        display: 'flex',
        flexDirection: 'column',
    },
    mainBlockMobile: {
        paddingTop: '52px',
        boxSizing: 'border-box',
    },
    loader: {
        opacity: 1,
        position: 'fixed',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        zIndex: 99,
        height: '100vh',
        background: 'rgba(34, 34, 34, 0.5)',
        visibility: 'visible',
        transition: 'all .2s',
        '&.hidden': {
            opacity: 0,
            visibility: 'hidden',
        },
    },
    errorText: {
        color: '#FF0000',
        minHeight: 14,
    },
    divider: {
        backgroundColor: '#151515',
        height: '8px',
    },
    lineColor: {
        color: '#8E8E8E',
        opacity: 0.2,
    },
    centerBlock: {
        display: 'flex',
        justifyContent: 'center',
    },
    wagerLabel: {
        opacity: '0.6',
    },
    oneBlockContainer: {
        justifyContent: 'center',
        padding: '12px 16px',
    },
    oneBlock: {
        width: '100%',
    },
    oneInput: {
        width: '24%',
    },
    marginLeft: {
        marginLeft: 'auto',
    },
    wagersInput: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    field: {
        paddingRight: '8px',
        width: '100%',
    },
    field1: {
        paddingLeft: '8px',
        width: '100%',
    },
    specialPaddings: {
        paddingTop: '0px !important',
        paddingLeft: '0px !important',
        paddingBottom: '8px !important',
    },
    notification: {
        display: 'flex',
        background: '#222',
        padding: '11px 16px 0 21px',
        color: '#ff0000',
        '& i': {
            fontSize: '14px',
        },
    },
    wagerInput: {
        textAlign: 'end',
        position: 'relative',
    },
    notificationText: {
        fontSize: '12px',
        textAlign: 'center',
        paddingLeft: '8px',
        letterSpacing: '0.015em',
        lineHeight: '14px',
    },
    gameCheckboxesWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 8px',
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
        scrollbarWidth: 'thin',
    },
    gamesCheckboxMaxWidth: {
        maxWidth: 425,
    },
    allButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 10px',
        width: 80,
        minWidth: 80,
        height: 76,
        marginRight: 8,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        color: '#F9F9F9',
        fontSize: 16,
        fontWeight: 600,
        borderRadius: 8,
        cursor: 'pointer',
        background: '#333333',
        '& span': {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
    },
    allButtonActive: {
        border: '1px solid #FFF',
        width: 78,
        minWidth: 78,
        height: 74,
    },
    checkboxButtonWrapper: {
        marginRight: 7,
    },
}); }, { name: 'TicketSelection' });
export { useStyles };
