var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect } from 'react';
import i18next from 'i18next';
import { useAppDispatch } from '~store';
import { getTournamentsOutrights, resetTournaments, tournamentsDataSelector, } from '~slices/outrights';
import { useStructuredSelector } from '~hooks';
import { changeLocalSportId, localSportIdSelector } from '~slices/options';
import { List } from './List';
export var OutrightsList = function (_a) {
    var _b, _c;
    var onClick = _a.onClick, onBackClick = _a.onBackClick, CATEGORY = _a.CATEGORY, rest = __rest(_a, ["onClick", "onBackClick", "CATEGORY"]);
    var dispatch = useAppDispatch();
    var _d = useStructuredSelector({
        tournaments: tournamentsDataSelector,
        localSportId: localSportIdSelector,
    }), tournaments = _d.tournaments, localSportId = _d.localSportId;
    var tournamentSportId = (_c = (_b = tournaments === null || tournaments === void 0 ? void 0 : tournaments[0]) === null || _b === void 0 ? void 0 : _b.sport) === null || _c === void 0 ? void 0 : _c.id;
    useEffect(function () {
        if (typeof tournamentSportId === 'number' &&
            tournamentSportId !== localSportId) {
            dispatch(changeLocalSportId({ id: tournamentSportId }));
        }
    }, [tournamentSportId]);
    useEffect(function () {
        if (i18next.language && CATEGORY) {
            dispatch(getTournamentsOutrights({
                // @ts-expect-error
                id: CATEGORY.tid,
                langIso: i18next.language,
            }));
        }
    }, [i18next.language, CATEGORY]);
    useEffect(function () {
        return function () {
            dispatch(resetTournaments());
        };
    }, []);
    var onClickHandler = function (item) {
        if (item && onClick) {
            onClick(item);
        }
    };
    return (React.createElement(List, __assign({ isTournaments: true, header: CATEGORY === null || CATEGORY === void 0 ? void 0 : CATEGORY.tn, list: tournaments, 
        // @ts-expect-error
        onClick: onClickHandler, onBackClick: onBackClick, withBackButton: true }, rest)));
};
