var Atom = /** @class */ (function () {
    function Atom(initialValue, callbacksMap) {
        this.initialValue = initialValue;
        this.value = initialValue;
        this.callbacksMap = callbacksMap;
        this.callbacksMap.set(this, []);
    }
    Atom.prototype.getValue = function () {
        return this.value;
    };
    Atom.prototype.setValue = function (newValue) {
        var _a;
        this.value = newValue;
        (_a = this.callbacksMap.get(this)) === null || _a === void 0 ? void 0 : _a.forEach(function (callback) {
            callback(newValue);
        });
    };
    Atom.prototype.getInitialValue = function () {
        return this.initialValue;
    };
    return Atom;
}());
export { Atom };
