import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { name } from './constants';
export var authSelector = function (state) { return state[name]; };
export var userIdSelector = createSelector(function (state) { return authSelector(state).data.userId; }, function (userId) { return userId; });
export var userEmailSelector = createSelector(function (state) { return authSelector(state).data.email; }, function (email) { return email; });
export var accessTokenSelector = function (state) {
    return authSelector(state).data.accessToken;
};
export var refreshTokenSelector = function (state) {
    return authSelector(state).data.refreshToken;
};
export var expiresAtSelector = function (state) {
    return authSelector(state).data.expiresAt;
};
var isLogOutMessageMetaSelector = function (state) {
    return authSelector(state).meta.isLogOutMessage;
};
export var isAuthenticatedSelector = createSelector(function (state) { return authSelector(state).data.accessToken; }, function (accessToken) { return !!accessToken; });
export var isCookieAcceptedSelector = createSelector(function (state) { return authSelector(state).data.cookieAccepted; }, function (cookieAccepted) { return cookieAccepted; });
export var errorSelector = function (state) {
    return authSelector(state).error;
};
export var timeToTokenExpiration = function (state) {
    var expiresAt = expiresAtSelector(state);
    if (!expiresAt)
        return 0;
    return expiresAt - Date.now();
};
export var needRefreshTokens = function (state) {
    return isAuthenticatedSelector(state) && timeToTokenExpiration(state) < 10000;
};
export var loadingSelector = function (state) {
    return authSelector(state).loading;
};
export var authIsPendingSelector = function (state) {
    return loadingSelector(state) === 'pending';
};
export var useIsLogOutMessageSelector = function () {
    return useSelector(isLogOutMessageMetaSelector);
};
/* @deprecated use useStructuredSelector instead */
export var useAccessTokenSelector = function () {
    return useSelector(accessTokenSelector);
};
export var registrationFormValueSelector = formValueSelector('registration-form');
// TODO: add return type
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export var countryCodeSelector = function (state) {
    return registrationFormValueSelector(state, 'userProfileDto.addresses.countryCode');
};
