var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// TODO: fix return type issues
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { requestWithoutAuth } from '~utils/request';
import { countryCodeSelector } from '~slices/auth';
import { PLATFORMS } from '~slices/teasers';
import { ratio } from '~theme';
var POSTAL_CODE = 'addresses[0].postalCode';
var CITY = 'addresses[0].city';
var STREET = 'addresses[0].street';
/**
 * Select bunch of data from store using one useSelector.
 * As value in object you can provide selector from reselect library or custom
 * function with store as first param, if you need to provide additional logic to selector
 * @param {Object} selectors - Object with name of selector as key and selector or func as value
 * @return {Object} object with name of selector as key and result of selector or func as value
 * @example
 * const { tournament, activeSidebetId } = useStructuredSelector({
 *  activeSidebetId: activeSidebetIdSelector,
 *  tournament: (state: RootState) =>
 *   eventsTournamentByIdSelector(state, tournamentId),
 * });
 * @returns {Object} Returns {Object} TournamentDto and {number} activeSidebetId
 */
export var useStructuredSelector = function (selectors) {
    // @ts-expect-error
    return useSelector(function (state) {
        return Object.keys(selectors).reduce(function (result, selectorKey) {
            var _a;
            var selectionFunc = selectors[selectorKey];
            return __assign(__assign({}, result), (_a = {}, _a[selectorKey] = selectionFunc(state), _a));
        }, {});
    }, shallowEqual);
};
export var useAddressAutocompleteHook = function (countryCodeValue) {
    if (countryCodeValue === void 0) { countryCodeValue = null; }
    var _a = useState([]), dataCities = _a[0], setDataCities = _a[1];
    var _b = useState(''), postCode = _b[0], setPostCode = _b[1];
    var _c = useState(''), city = _c[0], setCity = _c[1];
    var _d = useState(''), street = _d[0], setStreet = _d[1];
    var countyCodeData = useStructuredSelector({
        countyCodeData: countryCodeSelector,
    }).countyCodeData;
    var countryCode = (countyCodeData === null || countyCodeData === void 0 ? void 0 : countyCodeData.countryCode) || countryCodeValue;
    var requestForLocation = function (code, cityName, streetName) {
        var citySearch = cityName ? "&city=".concat(cityName) : '';
        var codeSearch = code ? "&postcode=".concat(code) : '';
        var streetSearch = streetName ? "&street=".concat(streetName) : '';
        requestWithoutAuth({
            url: "content/v1/registration/search?countryCode=".concat(countryCode, "&resultCount=100").concat(citySearch).concat(codeSearch).concat(streetSearch),
            method: 'post',
        }).then(function (_a) {
            var data = _a.data;
            setDataCities(data);
        });
    };
    var searchLocation = function (value, name) {
        switch (name) {
            case POSTAL_CODE:
            case 'userProfileDto.addresses.postalCode':
                setPostCode(value);
                if (!city && !street && value.length > 1 && value.length <= 5) {
                    requestForLocation(value, '', '');
                }
                break;
            case CITY:
            case 'userProfileDto.addresses.city':
                setCity(value);
                if (value.length && postCode) {
                    requestForLocation(postCode, value, '');
                }
                if (value && street && !postCode) {
                    requestForLocation('', value, street);
                }
                break;
            case STREET:
            case 'userProfileDto.addresses.street':
                setStreet(value);
                if (city && value && !postCode) {
                    requestForLocation('', city, value);
                }
                break;
            default:
        }
    };
    return { dataCities: dataCities, postCode: postCode, city: city, street: street, searchLocation: searchLocation };
};
export var useFirst = function (value) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    var ref = useRef();
    // Store current value in ref
    useEffect(function () {
        if (ref.current !== value) {
            ref.current = value;
        }
    }, []);
    return ref.current;
};
export var useBreakpoint = function (breakpoint) {
    var theme = useTheme();
    return useMediaQuery(theme.breakpoints.up(breakpoint), {
        noSsr: true,
    });
};
export var useIsDesktop = function (resolution) {
    var theme = useTheme();
    return useMediaQuery(theme.breakpoints.up(resolution || 'lg'), {
        noSsr: true,
    });
};
export var useIsMobileDevise = function () {
    var nav = navigator.userAgent || navigator.vendor;
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(nav) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(nav.substr(0, 4))) {
        return true;
    }
    return false;
};
export var usePlatofrmType = function () {
    var isWebView = window.isWebView;
    var isMobile = useIsMobileDevise();
    if (isWebView)
        return PLATFORMS.CROSS_PLATFORM_MOBILE;
    if (isMobile)
        return PLATFORMS.WEB_MOBILE;
    return PLATFORMS.WEB;
};
export var useGAMethod = function () {
    var platform = usePlatofrmType();
    return platform === PLATFORMS.CROSS_PLATFORM_MOBILE
        ? 'cpa'
        : platform === PLATFORMS.WEB_MOBILE
            ? 'mobile'
            : 'web';
};
export var useSelectionColumnsCount = function () {
    var options = {
        noSsr: true,
    };
    var columnsCount = [
        true,
        useMediaQuery("@media (min-width: ".concat(1550 / ratio(), "px)"), options),
        useMediaQuery("@media (min-width: ".concat(1700 / ratio(), "px)"), options),
        useMediaQuery("@media (min-width: ".concat(1900 / ratio(), "px)"), options),
        useMediaQuery("@media (min-width: ".concat(2100 / ratio(), "px)"), options),
        useMediaQuery("@media (min-width: ".concat(2300 / ratio(), "px)"), options),
    ].filter(Boolean);
    return columnsCount.length;
};
export function useFullHeight() {
    var _a;
    var _b = useState((_a = document.documentElement) === null || _a === void 0 ? void 0 : _a.clientHeight), height = _b[0], setHeight = _b[1];
    useEffect(function () {
        function setMeasuredHeight() {
            var _a;
            var measuredHeight = (_a = document.documentElement) === null || _a === void 0 ? void 0 : _a.clientHeight;
            setHeight(measuredHeight);
        }
        window.addEventListener('resize', setMeasuredHeight);
        return function () { return window.removeEventListener('resize', setMeasuredHeight); };
    }, []);
    return height || null;
}
export var useSelectedFlagId = function () {
    var _a;
    var match = useRouteMatch('/home/:flagId/:tournamentId');
    return parseInt(((_a = match === null || match === void 0 ? void 0 : match.params) === null || _a === void 0 ? void 0 : _a.flagId) || '', 10) || undefined;
};
export { useFormNormalization } from './useFormNormalization';
export { useIsLoadedOnce } from './useIsLoadedOnce';
export { useRecaptcha } from './useRecaptcha';
export { useIsFirstRender } from './useIsFirstRender';
export { useBeforeMount } from './useBeforeMount';
export { useSportSelection } from './useSportSelection';
export { usePrevious } from './usePrevious';
export { useValueChange } from './useValueChange';
export { useWSConnectionController } from './useWSConnectionController';
export { useWindowResize } from './useWindowResize';
export { useAutoScroll } from './useAutoScroll';
export { useOpenLogin } from './useOpenLogin';
export { useOpenSideBets } from './useOpenSideBets';
export { useViewportHeight } from './useViewportHeight';
