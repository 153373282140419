import React from 'react';
import Grid from '@material-ui/core/Grid';
import uniqBy from 'lodash.uniqby';
import { useTranslation } from 'react-i18next';
import { CustomSelectField } from '~molecules';
import { checkIfFieldIsRequired, required, validateField, } from '~utils/formValidators';
import { normalizeTextFieldValue } from '~utils/fieldNormalization';
export var City = function (_a) {
    var tabIndex = _a.tabIndex, registrationBlocks = _a.registrationBlocks, dataCities = _a.dataCities, postCode = _a.postCode, searchLocation = _a.searchLocation;
    var t = useTranslation('registration').t;
    return (React.createElement(Grid, { container: true, spacing: 2 },
        React.createElement(Grid, { key: "title", item: true, xs: 5 },
            React.createElement(CustomSelectField, { name: "userProfileDto.addresses.postalCode", title: t('form.post_code'), placeholder: t('form.post_code'), validators: function (value) {
                    return validateField(value, 'city', tabIndex, required, registrationBlocks);
                }, options: uniqBy(dataCities, 'postcode').map(function (item) { return item.postcode; }), onSelect: searchLocation, required: checkIfFieldIsRequired('city', tabIndex, registrationBlocks), normalize: normalizeTextFieldValue })),
        React.createElement(Grid, { key: "city", item: true, xs: 7 },
            React.createElement(CustomSelectField, { name: "userProfileDto.addresses.city", options: postCode.length === 5
                    ? uniqBy(dataCities, 'city').map(function (item) { return item.city; })
                    : [], title: t('form.city'), placeholder: t('form.city'), validators: function (value) {
                    return validateField(value, 'city', tabIndex, required, registrationBlocks);
                }, onSelect: searchLocation, required: checkIfFieldIsRequired('city', tabIndex, registrationBlocks), normalize: normalizeTextFieldValue }))));
};
