var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createAction, createSlice } from '@reduxjs/toolkit';
import { createAsyncReducers } from '~utils/createAsyncReducers';
import { checkUserPassword, confirmRegistrationEmail, confirmUpdateEmail, editEmail, editPassword, getAccount, getSecurityOptions, updateAccount, updateSecurityOptions, } from '~slices/account/thunks';
import { name } from './constants';
var initialState = {
    data: {},
    meta: {
        loading: 'idle',
    },
    loading: 'idle',
    error: null,
};
export var resetErrors = createAction("".concat(name, "/resetErrors"));
export var account = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        clearAccountData: function () {
            return initialState;
        },
    },
    extraReducers: function (builder) {
        var _a = createAsyncReducers(), pending = _a.pending, fulfilled = _a.fulfilled, rejected = _a.rejected;
        // @ts-ignore TODO: fix incompatible payload properties
        builder
            .addCase(getAccount.pending, pending)
            .addCase(getAccount.fulfilled, fulfilled)
            .addCase(getAccount.rejected, rejected)
            .addCase(updateAccount.pending, pending)
            .addCase(updateAccount.fulfilled, fulfilled)
            .addCase(updateAccount.rejected, rejected)
            .addCase(getSecurityOptions.pending, pending)
            .addCase(getSecurityOptions.fulfilled, function (state, _a) {
            var payload = _a.payload;
            return __assign(__assign({}, state), { data: __assign(__assign({}, state.data), payload), loading: 'fulfilled' });
        })
            .addCase(getSecurityOptions.rejected, rejected)
            .addCase(updateSecurityOptions.pending, pending)
            .addCase(updateSecurityOptions.fulfilled, fulfilled)
            .addCase(updateSecurityOptions.rejected, rejected)
            .addCase(checkUserPassword.pending, function (state) {
            return __assign(__assign({}, state), { meta: {
                    loading: 'pending',
                    invalidPassword: null,
                } });
        })
            .addCase(checkUserPassword.fulfilled, function (state, action) { return (__assign(__assign({}, state), { meta: {
                loading: 'fulfilled',
                invalidPassword: !action.payload,
            } })); })
            .addCase(checkUserPassword.rejected, function (state) { return (__assign(__assign({}, state), { meta: {
                loading: 'rejected',
                invalidPassword: true,
            } })); })
            .addCase(editEmail.pending, pending)
            .addCase(editEmail.fulfilled, function (state) { return (__assign(__assign({}, state), { loading: 'fulfilled' })); })
            .addCase(editEmail.rejected, function (state, action) { return (__assign(__assign({}, state), { loading: 'rejected', error: action.payload })); })
            .addCase(confirmUpdateEmail.pending, pending)
            .addCase(confirmUpdateEmail.fulfilled, function (state) { return (__assign(__assign({}, state), { loading: 'fulfilled' })); })
            .addCase(confirmUpdateEmail.rejected, function (state) { return (__assign(__assign({}, state), { loading: 'rejected' })); })
            .addCase(confirmRegistrationEmail.pending, pending)
            .addCase(confirmRegistrationEmail.fulfilled, function (state) { return (__assign(__assign({}, state), { loading: 'fulfilled' })); })
            .addCase(confirmRegistrationEmail.rejected, function (state, action) { return (__assign(__assign({}, state), { loading: 'rejected', error: action.payload })); })
            .addCase(editPassword.pending, pending)
            .addCase(editPassword.fulfilled, function (state) { return (__assign(__assign({}, state), { loading: 'fulfilled' })); })
            .addCase(editPassword.rejected, function (state, action) { return (__assign(__assign({}, state), { loading: 'rejected', error: action.payload })); })
            .addCase(resetErrors, function (state) { return (__assign(__assign({}, state), { error: null })); });
    },
});
export var clearAccountData = account.actions.clearAccountData;
export default account.reducer;
