import React, { useEffect, useRef, useState } from 'react';
import { Link, NavLink as RouterNavLink, matchPath, useLocation, useRouteMatch, } from 'react-router-dom';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { LOGIN_PRESS } from '~constants/cpa-message-codes';
import { MODALS, closeModal, openModal } from '~modals';
import { isAuthenticatedSelector, isCookieAcceptedSelector, resetRegistrationErrors, } from '~slices/auth';
import { changeLocalSportId, changeSportId, selectHeaderSportBid, sportNameSelector, } from '~slices/options';
import { cashOutDataSelector, loadingCashOutSelector } from '~slices/cashOut';
import { Button, Live } from '~atoms';
import { useAppDispatch } from '~store/configureStore';
import { availableSportsSelector, mapSportIdToSportIcon } from '~slices/sports';
import { LanguageSelector, UserDropdownMenu } from '~organisms';
import { currencyConverter } from '~utils/converters';
import logo from '~icons/logo.svg';
import TipGameLogo from '~icons/TipGame-logo.png';
import logoTurkey from '~icons/logoTurkey.svg';
import { defaultCountryCodeSelector, getLocale as getLocaleAsyncAction, } from '~slices/locale';
import { useBreakpoint, usePlatofrmType, useStructuredSelector } from '~hooks';
import { SoccerIcon } from '~icons';
import { accountInfoSelector } from '~slices/account';
import { PLATFORMS } from '~slices/teasers';
import { changeHintState } from '~slices/hints';
import { getTestId } from '~utils/getTestId';
import { getPathWithLanguageCode } from '~utils/languageInURL/getPathWithLanguageCode';
import { isBetCheckProject } from '~utils/constants/betCheckConstants';
import { CSIcon } from '~icons/rankingList/CSIcon';
import { PaymentsURL } from '~pages/payments/payments.routes';
import { useActiveGameSelector, useGameTypesDataSelector, useSelectedGroupSelector, } from '~slices/gameTypes';
import { useHeaderStyles } from './Header.styles';
import { MYBETS_FOR_UNLOGGED_BREAKPOINT } from './constants';
export var NavLink = function (_a) {
    var _b;
    var label = _a.label, to = _a.to, activeOnlyWhenExact = _a.activeOnlyWhenExact, icon = _a.icon, className = _a.className, additionalIcon = _a.additionalIcon, state = _a.state, active = _a.active, onClick = _a.onClick, dataTestId = _a.dataTestId;
    var match = useRouteMatch({
        path: to,
        exact: activeOnlyWhenExact,
    });
    var headerClasses = useHeaderStyles();
    return (React.createElement(RouterNavLink, { to: {
            pathname: to ? getPathWithLanguageCode(to) : undefined,
            state: state,
        }, onClick: onClick, "data-testid": getTestId(dataTestId), className: classNames(className, (_b = {},
            _b[headerClasses.active] = typeof active === 'boolean' ? active : match,
            _b), 'flex-row flex-center justify-center') },
        React.createElement("li", { className: "flex-row flex-center" },
            icon,
            label ? (React.createElement("span", { className: classNames(headerClasses.link, headerClasses.linkLabel, headerClasses.paddings) }, label)) : null,
            additionalIcon || null)));
};
export var DesktopHeader = function (_a) {
    var _b, _c;
    var t = _a.t;
    var dispatch = useAppDispatch();
    var platform = usePlatofrmType();
    var _d = useStructuredSelector({
        accountInfo: accountInfoSelector,
        countryCode: defaultCountryCodeSelector,
        isAuthenticated: isAuthenticatedSelector,
        currentSportBId: selectHeaderSportBid,
        cashOutLoadingStatus: loadingCashOutSelector,
        cashoutData: cashOutDataSelector,
        cookieAccepted: isCookieAcceptedSelector,
        games: useGameTypesDataSelector,
        activeGame: useActiveGameSelector,
        sports: availableSportsSelector,
        sportName: sportNameSelector,
        selectedGroup: useSelectedGroupSelector,
    }), accountInfo = _d.accountInfo, isAuthenticated = _d.isAuthenticated, currentSportBId = _d.currentSportBId, cashOutLoadingStatus = _d.cashOutLoadingStatus, _e = _d.cashoutData, _f = _e.cashout, cashout = _f === void 0 ? 0 : _f, _g = _e.currency, currency = _g === void 0 ? '' : _g, cookieAccepted = _d.cookieAccepted, games = _d.games, activeGame = _d.activeGame, countryCode = _d.countryCode, sports = _d.sports, sportName = _d.sportName, selectedGroup = _d.selectedGroup;
    var location = useLocation();
    var match = useRouteMatch();
    // TODO, change on data from BE
    var isBetCheck = isBetCheckProject;
    var tbj = useTranslation('tip-game').t;
    var shouldDisplayMybetsForUnlogged = useBreakpoint(MYBETS_FOR_UNLOGGED_BREAKPOINT);
    var isTopRankingPage = location.pathname === '/top-ranking';
    var isBetPartnersPage = location.pathname === '/bp-list';
    var getIsActiveLink = function (toPath) {
        // "toPath" is the path goal without a slash
        return window.location.pathname.split('/')[2] === toPath;
    };
    var _h = useState({
        cashOutBalance: cashout,
        cashOutCurrency: currency,
    }), localCashout = _h[0], setLocalCashout = _h[1];
    var _j = useState(undefined), gameBalance = _j[0], setGameBalance = _j[1];
    var cashOutBalance = localCashout.cashOutBalance, cashOutCurrency = localCashout.cashOutCurrency;
    var balance = isBetCheck ? cashOutBalance : gameBalance;
    var cashOut = currencyConverter(balance, cashOutCurrency);
    var Icon = mapSportIdToSportIcon[currentSportBId] || SoccerIcon;
    var headerClasses = useHeaderStyles();
    useEffect(function () {
        var selectedGame = games.find(function (game) {
            return selectedGroup
                ? game.groupName === selectedGroup
                : game.name === activeGame;
        });
        var balance = (selectedGame === null || selectedGame === void 0 ? void 0 : selectedGame.playerBalance) || (selectedGame === null || selectedGame === void 0 ? void 0 : selectedGame.playerBalance) === 0
            ? selectedGame.playerBalance
            : selectedGame === null || selectedGame === void 0 ? void 0 : selectedGame.startCredits;
        setGameBalance(balance !== null && balance !== void 0 ? balance : undefined);
    }, [activeGame, games]);
    var chooseToShow = function () {
        if (isBetCheck && countryCode && countryCode === 'TR')
            return logoTurkey;
        if (isBetCheck && countryCode !== 'TR')
            return logo;
        if (!isBetCheck)
            return TipGameLogo;
        return '';
    };
    var openRegistrationModal = function () {
        if (!cookieAccepted) {
            dispatch(changeHintState({ isCookiesConsentHintEnabled: true }));
        }
        if (cookieAccepted || platform === PLATFORMS.CROSS_PLATFORM_MOBILE) {
            var language = i18next.language;
            dispatch(getLocaleAsyncAction(language)).then(function () {
                dispatch(openModal({
                    modalName: MODALS.REGISTRATION_MODAL,
                    modalProps: {
                        isOpen: true,
                        onClose: function () {
                            dispatch(resetRegistrationErrors());
                            dispatch(closeModal({ key: MODALS.REGISTRATION_MODAL }));
                        },
                    },
                }));
            });
        }
    };
    var shouldRedirectToOptions = useRef(false);
    var openLoginModal = function (fromMyBets) {
        var pathToRedirect = (shouldRedirectToOptions.current && 'options') ||
            (fromMyBets && "".concat(PaymentsURL.TOP_IT_UP)) ||
            (isTopRankingPage && 'top-ranking') ||
            (isBetPartnersPage && 'bp-list') ||
            'home/all/live';
        if (cookieAccepted) {
            dispatch(openModal({
                modalName: MODALS.LOGIN_MODAL,
                modalProps: {
                    isOpen: true,
                    onClose: function () {
                        shouldRedirectToOptions.current = false;
                        dispatch(closeModal({ key: MODALS.LOGIN_MODAL }));
                    },
                    onSubmit: function () {
                        var path = '/:lng/home/:sportName/:flagName/:tournamentName';
                        var match = matchPath(window.location.pathname, {
                            path: path,
                        });
                        var sportName = match === null || match === void 0 ? void 0 : match.params.sportName;
                        var flagName = match === null || match === void 0 ? void 0 : match.params.flagName;
                        var matchedURL = '';
                        if (sportName && sportName !== 'all' && flagName) {
                            matchedURL = match.url;
                            var sportFromURL = sports.find(function (sport) {
                                return (sport === null || sport === void 0 ? void 0 : sport.name) === decodeURI(sportName || '') ||
                                    (sport === null || sport === void 0 ? void 0 : sport.id) === Number(sportName);
                            });
                            dispatch(changeSportId(sportFromURL === null || sportFromURL === void 0 ? void 0 : sportFromURL.id));
                        }
                        else {
                            dispatch(dispatch(changeLocalSportId({
                                id: null,
                                desktop: true,
                                langIso: i18next.language,
                            })));
                        }
                        dispatch(closeModal({ key: MODALS.LOGIN_MODAL }));
                        dispatch(push(getPathWithLanguageCode(matchedURL !== null && matchedURL !== void 0 ? matchedURL : "".concat(match === null || match === void 0 ? void 0 : match.url).concat(pathToRedirect))));
                    },
                },
            }));
        }
        else if (platform === PLATFORMS.CROSS_PLATFORM_MOBILE) {
            // @ts-expect-error
            window.ReactNativeWebView.postMessage(JSON.stringify({
                code: LOGIN_PRESS,
                target: "/".concat(pathToRedirect),
            }));
            shouldRedirectToOptions.current = false;
        }
        else {
            shouldRedirectToOptions.current = false;
            dispatch(changeHintState({ isCookiesConsentHintEnabled: true }));
        }
    };
    useEffect(function () {
        if (cashOutLoadingStatus === 'fulfilled') {
            setLocalCashout({
                cashOutBalance: cashout,
                cashOutCurrency: currency,
            });
        }
    }, [cashOutLoadingStatus]);
    var LogoClickHandler = function () {
        isBetCheck
            ? dispatch(push(getPathWithLanguageCode('/home')))
            : dispatch(push(getPathWithLanguageCode('/start')));
    };
    var myBetsLoginHandler = function () {
        openLoginModal(true);
    };
    var handleOptionsClick = function () {
        if (!isAuthenticated) {
            shouldRedirectToOptions.current = true;
            openLoginModal();
        }
    };
    useEffect(function () {
        if (isAuthenticated && shouldRedirectToOptions.current) {
            shouldRedirectToOptions.current = false;
            setTimeout(function () {
                dispatch(push(getPathWithLanguageCode("".concat(match === null || match === void 0 ? void 0 : match.url, "options"))));
            });
        }
    }, [isAuthenticated]);
    return (React.createElement(React.Fragment, null,
        React.createElement("img", { src: chooseToShow(), alt: "logo", "data-testid": getTestId('HAM_MENU'), className: classNames((_b = {},
                _b[headerClasses.desktopLogo] = true,
                _b.turkey = countryCode === 'TR' && isBetCheck,
                _b), !isBetCheck && headerClasses.tipGameDesktopLogo), onClick: LogoClickHandler }),
        isBetCheck && (React.createElement(NavLink, { label: t('our_bp_partners'), to: getPathWithLanguageCode('/bp-list'), icon: React.createElement(React.Fragment, null,
                React.createElement("i", { className: "far fa-chart-bar" })), activeOnlyWhenExact: true, className: classNames('relative', headerClasses.headerItem), dataTestId: "BTN_BP_PARTNERS_DESKTOP" })),
        React.createElement(NavLink, { label: t('options'), to: isAuthenticated ? getPathWithLanguageCode('/options') : undefined, onClick: handleOptionsClick, active: getIsActiveLink('options'), className: headerClasses.headerItem, icon: React.createElement("i", { className: "far fa-cog" }) }),
        React.createElement(NavLink, { active: isTopRankingPage || getIsActiveLink('home'), label: t('betting'), to: getPathWithLanguageCode("/home/all/live/".concat(sportName === null || sportName === void 0 ? void 0 : sportName.name)), icon: React.createElement(Icon, { viewBox: "0 0 18 18" }), className: classNames('relative', headerClasses.headerItem), additionalIcon: React.createElement(Live, { className: headerClasses.topIcon }), dataTestId: "BTN_SELECTION" }),
        !isAuthenticated ? (React.createElement(NavLink, { label: t('beton'), to: getPathWithLanguageCode('/about/betcheck/about_us'), state: {
                aboutVideoAutoplay: true,
            }, active: location.pathname.includes('/about/tipgame/') ||
                location.pathname.includes('/about/info/'), className: headerClasses.headerItem, icon: React.createElement("i", { className: "fas fa-play-circle" }) })) : null,
        React.createElement("div", { className: classNames(headerClasses.rigthContainer, (_c = {},
                _c[headerClasses.unlogged] = !isAuthenticated,
                _c)) },
            !isAuthenticated ? (React.createElement(Button, { className: headerClasses.registerButton, variant: "contained", onClick: openRegistrationModal, "data-testid": getTestId('BTN_REGISTER') }, t('register'))) : (React.createElement("span", null)),
            !isAuthenticated ? (React.createElement(Button, { className: headerClasses.loginButton, "data-testid": getTestId('BTN_LOGIN'), onClick: function () { return openLoginModal(); }, variant: "contained" }, t('login'))) : (React.createElement(UserDropdownMenu, { t: t })),
            React.createElement(LanguageSelector, null),
            isAuthenticated && accountInfo.purchased ? (React.createElement("div", { className: "relative" },
                React.createElement(Button, { variant: "contained", component: Link, to: getPathWithLanguageCode("/".concat(PaymentsURL.TOP_IT_UP)), className: headerClasses.desktopCashOutButton, "data-testid": getTestId('BTN_MY_BETS') },
                    React.createElement("span", { className: headerClasses.cashOutLabel }, "".concat(selectedGroup !== null && selectedGroup !== void 0 ? selectedGroup : tbj("game-name.".concat(activeGame)), ":")),
                    React.createElement("span", { className: headerClasses.cashOutButtonBalance },
                        React.createElement("span", null, cashOut),
                        isBetCheck ? cashOutCurrency : React.createElement(CSIcon, { size: "md" }))))) : null,
            isAuthenticated && !accountInfo.purchased ? (React.createElement("div", { className: "relative" },
                React.createElement(Button, { variant: "contained", component: Link, to: getPathWithLanguageCode("/".concat(PaymentsURL.TOP_IT_UP)), className: headerClasses.desktopCashOutButton, "data-testid": getTestId('BTN_MY_BETS') }, isBetCheck ? t('my_bets') : tbj('my-tips.my_tips')))) : null,
            !isAuthenticated && shouldDisplayMybetsForUnlogged ? (React.createElement(Button, { variant: "contained", onClick: myBetsLoginHandler, className: headerClasses.desktopCashOutButton, "data-testid": getTestId('BTN_MY_BETS') }, isBetCheck ? t('my_bets') : tbj('my-tips.my_tips'))) : null)));
};
