import { createSelector } from 'reselect';
import { name } from './constants';
export var sidebetsSelector = function (state) { return state[name]; };
export var matchEventSelector = createSelector(function (state) { return sidebetsSelector(state).data; }, function (event) { return event; });
export var activeSidebetIdSelector = createSelector(function (state) { return state.sidebets.data.activeSidebetId; }, function (activeSidebetId) { return activeSidebetId; });
export var selectIsTennis = function (state) {
    var _a, _b;
    var sportId = (_b = (_a = sidebetsSelector(state).data) === null || _a === void 0 ? void 0 : _a.sp) === null || _b === void 0 ? void 0 : _b.id;
    return sportId === 25 || sportId === 2;
};
export var selectIsEventSelected = createSelector(activeSidebetIdSelector, function (_, eventId) { return eventId; }, function (activeSidebetId, eventId) {
    return activeSidebetId === eventId;
});
