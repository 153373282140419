import React from 'react';
import { useStyles } from '~atoms/BetslipKeyboardButtons/BetslipKeyboardButtons.styles';
export var PlusNumberKeyboardButton = function (_a) {
    var count = _a.count, onClick = _a.onClick;
    var classes = useStyles();
    return (React.createElement("div", { role: "button", tabIndex: 0, className: classes.addCountButton, onClick: onClick },
        React.createElement("span", null,
            "+",
            count)));
};
