import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var SSBMIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, viewBox: "0 0 28 28" },
        React.createElement("svg", { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M28 14C28 6.26801 21.732 0 14 0C12.8796 0 11.7899 0.131613 10.7456 0.380223V17.0088H27.6759C27.8882 16.0396 28 15.0329 28 14Z", fill: "#EF3333" }),
            React.createElement("path", { d: "M4.87135 3.38518C1.88864 5.95262 0 9.75575 0 14C0 15.0329 0.11185 16.0396 0.324098 17.0088H4.87135V3.38518Z", fill: "#EF3333" }),
            React.createElement("path", { d: "M4.87135 19.8333H1.26943C2.11601 21.6779 3.35168 23.3067 4.87135 24.6148V19.8333Z", fill: "#EF3333" }),
            React.createElement("path", { d: "M10.7456 27.6198C11.7899 27.8684 12.8796 28 14 28C19.6502 28 24.5186 24.6529 26.7306 19.8333H10.7456V27.6198Z", fill: "#EF3333" }))));
};
