import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { unwrapResult } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { useAppDispatch } from '~store';
import { betslipDataSelector } from '~slices/betslip';
import { checkUserPassword } from '~slices/account';
import { MODALS, closeModal, openModal } from '~modals';
import { useIsDesktop, useStructuredSelector } from '~hooks';
import { Button, Currency } from '~atoms';
import starCup from '~icons/sturCup.svg';
import { numberWithDotsAndComma } from '~utils/numberWithDotsAndComma';
import { BankDetailsInput } from '~molecules/WithdrawTab/components/BankDetailsInput';
import { getPathWithLanguageCode } from '~utils/languageInURL';
import { PaymentsURL } from '~pages/payments/payments.routes';
import { useStyles } from './WithdrawOnline.styles';
export var WithdrawOnline = function () {
    var _a;
    var classes = useStyles();
    var t = useTranslation('tip-game').t;
    var _b = useState(''), BIC_SWIFT = _b[0], setBIC_SWIFT = _b[1];
    var _c = useState(''), accountNumber = _c[0], setAccountNumber = _c[1];
    var _d = useState(''), accountHolder = _d[0], setAccountHolder = _d[1];
    var isDesktop = useIsDesktop();
    var dispatch = useAppDispatch();
    var mockData = {
        shopBalance: 200,
        onlineBalance: 400,
        jackpot: 5500,
    };
    // TODO, replace with real data from BE
    var betslip = useStructuredSelector({
        betslip: betslipDataSelector,
    }).betslip;
    var ticketOffer = betslip.to;
    var currency = (_a = ticketOffer === null || ticketOffer === void 0 ? void 0 : ticketOffer.cu) !== null && _a !== void 0 ? _a : 'EUR';
    var withdrawOnline = function (password) {
        dispatch(checkUserPassword(password))
            .then(unwrapResult)
            .then(function (res) {
            if (res) {
                dispatch(closeModal({ key: MODALS.CHANGE_ACCOUNT_MODAL }));
                // TODO, replace with Withdraw function
                console.log('BIC / SWIF:', BIC_SWIFT, 'Account Number:', accountNumber, 'Account Holder:', accountHolder);
                dispatch(push(getPathWithLanguageCode("/".concat(PaymentsURL.PAYMENTS, "/").concat(PaymentsURL.WITHDRAW_STATUS, "?sum=").concat(mockData.jackpot))));
            }
        });
    };
    var onWithdrawButtonClick = function () {
        dispatch(openModal({
            modalName: MODALS.CHANGE_ACCOUNT_MODAL,
            modalProps: {
                formName: 'enter-password',
                title: t('payments.enter_password_to_continue'),
                saveButtonLabel: t('payments.withdraw'),
                cancelButtonLabel: t('payments.cancel'),
                titleClassName: classes.passTitle,
                actionsClassName: classes.actionsButtons,
                closable: isDesktop,
                onSubmit: withdrawOnline,
            },
        }));
    };
    return (React.createElement("div", { className: classes.container },
        React.createElement("div", { className: classes.container },
            React.createElement("div", { className: classes.congratulationsWrapper },
                React.createElement("div", null,
                    React.createElement("img", { src: starCup, alt: 'cup' }),
                    React.createElement("div", null, t('payments.congratulations')),
                    React.createElement("div", { className: classes.won },
                        t('payments.you_won'),
                        " ",
                        numberWithDotsAndComma(mockData.jackpot),
                        ' ',
                        React.createElement(Currency, { currency: currency })),
                    React.createElement("div", null,
                        t('jackpot'),
                        "!"))),
            React.createElement("form", null,
                React.createElement(BankDetailsInput, { label: t('payments.bic_swift'), placeholder: t('payments.enter_bic_swift'), value: BIC_SWIFT, onInputChange: function (value) { return setBIC_SWIFT(value); } }),
                React.createElement(BankDetailsInput, { label: t('payments.account_number'), placeholder: t('payments.enter_account_number'), value: accountNumber, onInputChange: function (value) { return setAccountNumber(value); } }),
                React.createElement(BankDetailsInput, { label: t('payments.account_holder_name'), placeholder: t('payments.enter_account_holder_name'), value: accountHolder, onlyLetters: true, onInputChange: function (value) { return setAccountHolder(value); } }))),
        React.createElement(Button, { color: "primary", variant: "contained", fullWidth: true, className: classes.button, onClick: onWithdrawButtonClick }, t('payments.claim_withdraw'))));
};
