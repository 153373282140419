import { Workbox } from 'workbox-window';
export var registerServiceWorker = function () {
    if ('serviceWorker' in navigator) {
        var wb_1 = new Workbox('/sw.js');
        wb_1.addEventListener('installed', function (e) {
            if (e.isUpdate) {
                console.log("new build v".concat(process.env.REACT_APP_VERSION, " here"), wb_1);
                window.location.reload();
            }
        });
        wb_1.register()
            .then(function (registration) {
            console.log('SW registered: ', registration);
        })
            .catch(function (registrationError) {
            console.log('SW registration failed: ', registrationError);
        });
    }
};
export var uregisterServiceWorker = function () {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then(function (registrations) {
            for (var _i = 0, registrations_1 = registrations; _i < registrations_1.length; _i++) {
                var registration = registrations_1[_i];
                registration.unregister();
            }
        });
    }
};
