import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { WithdrawTypeCard } from '~atoms/WithdrawTypeCard';
import { Button, Currency } from '~atoms';
import { useStyles } from '~molecules/WithdrawTab/components/JackpotBalance/JackpotBalance.styles';
import { getPathWithLanguageCode } from '~utils/languageInURL';
import { PaymentsURL } from '~pages/payments/payments.routes';
import { useAppDispatch } from '~store';
import starCup from '~icons/sturCup.svg';
import { numberWithDotsAndComma } from '~utils/numberWithDotsAndComma';
import { useStructuredSelector } from '~hooks';
import { betslipDataSelector } from '~slices/betslip';
export var JackpotBalance = function (_a) {
    var _b;
    var balance = _a.balance;
    var classes = useStyles();
    var dispatch = useAppDispatch();
    var t = useTranslation('tip-game').t;
    var _c = useState('online'), withdrawType = _c[0], setWithdrawType = _c[1];
    var betslip = useStructuredSelector({
        betslip: betslipDataSelector,
    }).betslip;
    var ticketOffer = betslip.to;
    var currency = (_b = ticketOffer === null || ticketOffer === void 0 ? void 0 : ticketOffer.cu) !== null && _b !== void 0 ? _b : 'EUR';
    var onContinueButtonClick = function () {
        if (withdrawType === 'inShop') {
            dispatch(push(getPathWithLanguageCode("/".concat(PaymentsURL.PAYMENTS, "/").concat(PaymentsURL.WITHDRAW_IN_SHOP))));
        }
        if (withdrawType === 'online') {
            dispatch(push(getPathWithLanguageCode("/".concat(PaymentsURL.PAYMENTS, "/").concat(PaymentsURL.WITHDRAW_ONLINE))));
        }
    };
    var onWithdrawTypeCardClick = function (type) {
        if (withdrawType === type) {
            return;
        }
        setWithdrawType(type);
    };
    return (React.createElement("div", { className: classes.container },
        React.createElement("div", { className: classes.container },
            React.createElement("div", { className: classes.congratulationsWrapper },
                React.createElement("div", null,
                    React.createElement("img", { src: starCup, alt: 'cup' }),
                    React.createElement("div", null, t('payments.congratulations')),
                    React.createElement("div", { className: classes.won },
                        t('payments.you_won'),
                        " ",
                        numberWithDotsAndComma(balance),
                        ' ',
                        React.createElement(Currency, { currency: currency })),
                    React.createElement("div", null,
                        t('jackpot'),
                        "!"))),
            React.createElement("div", { className: classes.withdrawType },
                React.createElement(WithdrawTypeCard, { type: 'inShop', onClick: function () { return onWithdrawTypeCardClick('inShop'); }, isActive: withdrawType === 'inShop' }),
                React.createElement(WithdrawTypeCard, { type: 'online', onClick: function () { return onWithdrawTypeCardClick('online'); }, isActive: withdrawType === 'online' }))),
        React.createElement(Button, { color: "primary", variant: "contained", fullWidth: true, className: classes.button, onClick: onContinueButtonClick }, t('continue'))));
};
