import makeStyles from '~utils/makeStyles';
var useStyles = makeStyles(function () { return ({
    wrapper: {
        position: 'absolute',
        top: '11px',
        right: '22px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 8px',
        minWidth: '73px',
        height: '36px',
        background: 'radial-gradient(100% 100% at 50.13% 0%, #303038 0%, #1B1B22 100%)',
        border: '1px solid #606060',
        borderRadius: '4px',
        fontSize: '12px',
        color: '#FFFFFF',
    },
    divider: {
        width: '1px',
        height: '22px',
        margin: '0 12px',
        background: '#8E8E8E',
        opacity: '22%',
    },
}); }, { name: 'BalanceInputLabel' });
export { useStyles };
