import { createSlice } from '@reduxjs/toolkit';
import { createAsyncReducers } from '~utils/createAsyncReducers';
import { name } from './constants';
import { getCashOut } from '~slices/cashOut/thunks';
var initialState = {
    data: {},
    loading: 'idle',
    error: null,
};
export var cashOut = createSlice({
    name: name,
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        var _a = createAsyncReducers(), pending = _a.pending, fulfilled = _a.fulfilled, rejected = _a.rejected;
        builder
            .addCase(getCashOut.pending, pending)
            .addCase(getCashOut.fulfilled, fulfilled)
            .addCase(getCashOut.rejected, rejected);
    },
});
