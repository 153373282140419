// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import i18next from 'i18next';
import { useAppDispatch } from '~store';
import { mapSportIdToSportIcon } from '~slices/sports';
import { getShortcutBetslip } from '~slices/shortcut';
import { pickActiveSidebetId } from '~slices/sidebets';
import { deleteOutcomeFromEvent } from '~slices/betslip';
import { changeLocalSportId, currentSportIdSelector } from '~slices/options';
import { useIsDesktop, usePrevious, useSelectedFlagId, useStructuredSelector, } from '~hooks';
import { FINISHED_STATUSES, NOT_STARTED_STATUSES, POSSIBLE_GOAL_IDS, POSSIBLE_RED_CARDS_IDS, isSelectionActive, } from '~utils/events';
import { SoccerIcon } from '~icons/sports';
import { WonLostIndicator } from '~atoms/WonLostIndicator/WonLostIndicator';
import { resetTournaments } from '~slices/tournamentsList';
import { isValidNumber } from '~utils/isValidNumber';
import { getTestId } from '~utils/getTestId';
import { getPathWithLanguageCode } from '~utils/languageInURL/getPathWithLanguageCode';
import useStyles from './Event.styles';
import { EventOdd } from './EventOdd';
import { EventDeactivatedOdd } from './EventDeactivatedOdd';
import { CompetitorBlock } from './CompetitorBlock';
var S3_RESOURCES = process.env.REACT_APP_CDN || '';
export var Event = function (_a) {
    var _b, _c;
    var _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8;
    var event = _a.event, showBanker = _a.showBanker, _9 = _a.deletable, deletable = _9 === void 0 ? false : _9, bankerClickHandler = _a.bankerClickHandler, onClose = _a.onClose, setIsGoal = _a.setIsGoal, ticketDetails = _a.ticketDetails, toRedirectToSidebets = _a.toRedirectToSidebets, checkDeactivatedOdds = _a.checkDeactivatedOdds, _10 = _a.deletableOdds, deletableOdds = _10 === void 0 ? true : _10, purchasedTicket = _a.purchasedTicket;
    var classes = useStyles();
    var t = useTranslation('ticket').t;
    var dispatch = useAppDispatch();
    var isDesktop = useIsDesktop();
    var flagId = useSelectedFlagId();
    var selectedSportId = useStructuredSelector({
        selectedSportId: currentSportIdSelector,
    }).selectedSportId;
    var date = event.dt && event.dt * 1000;
    var isFinished = FINISHED_STATUSES.includes(event.ms || 'BEFORE');
    var isOutrightEventActive = !event.c1 && !event.c2 && ((_d = event.sl) === null || _d === void 0 ? void 0 : _d.sel) && ((_f = (_e = event.sl) === null || _e === void 0 ? void 0 : _e.sel['1']) === null || _f === void 0 ? void 0 : _f.act);
    var isSoccer = Number(event === null || event === void 0 ? void 0 : event.si) === 1;
    var isBetstopReason = !!Number((_g = event.bsr) === null || _g === void 0 ? void 0 : _g.bsrl) && (event === null || event === void 0 ? void 0 : event.bs);
    var prevBetstopReason = usePrevious(event.bsr);
    var prevBetstopReasonId = usePrevious((_h = event.bsr) === null || _h === void 0 ? void 0 : _h.bid);
    var isPossibleGoal = POSSIBLE_GOAL_IDS.includes(((_j = event.bsr) === null || _j === void 0 ? void 0 : _j.bid) || 0) && event.bs;
    var prevIsPossibleGoal = POSSIBLE_GOAL_IDS.includes(prevBetstopReasonId || 0);
    var prevC1Score = usePrevious((_k = event === null || event === void 0 ? void 0 : event.c1) === null || _k === void 0 ? void 0 : _k.sc);
    var prevC2Score = usePrevious((_l = event === null || event === void 0 ? void 0 : event.c2) === null || _l === void 0 ? void 0 : _l.sc);
    var _11 = useState(false), goal = _11[0], setGoal = _11[1];
    var _12 = useState({
        c1: 0,
        c2: 0,
    }), scoreBeforePossibleGoal = _12[0], setScoreBeforePossibleGoal = _12[1];
    var _13 = useState(false), noGoal = _13[0], setNoGoal = _13[1];
    var _14 = useState({
        c1: ((_m = event === null || event === void 0 ? void 0 : event.c1) === null || _m === void 0 ? void 0 : _m.sc) || 0,
        c2: ((_o = event === null || event === void 0 ? void 0 : event.c2) === null || _o === void 0 ? void 0 : _o.sc) || 0,
    }), scoreToShow = _14[0], setScoreToShow = _14[1];
    var c1Goal = prevIsPossibleGoal && !isPossibleGoal
        ? scoreBeforePossibleGoal.c1 < Number((_p = event === null || event === void 0 ? void 0 : event.c1) === null || _p === void 0 ? void 0 : _p.sc)
        : !!(isValidNumber(prevC1Score) &&
            isValidNumber((_q = event === null || event === void 0 ? void 0 : event.c1) === null || _q === void 0 ? void 0 : _q.sc) &&
            prevC1Score < Number((_r = event === null || event === void 0 ? void 0 : event.c1) === null || _r === void 0 ? void 0 : _r.sc));
    var c2Goal = prevIsPossibleGoal && !isPossibleGoal
        ? scoreBeforePossibleGoal.c2 < Number((_s = event === null || event === void 0 ? void 0 : event.c2) === null || _s === void 0 ? void 0 : _s.sc)
        : !!(isValidNumber(prevC2Score) &&
            isValidNumber((_t = event === null || event === void 0 ? void 0 : event.c2) === null || _t === void 0 ? void 0 : _t.sc) &&
            prevC2Score < Number((_u = event === null || event === void 0 ? void 0 : event.c2) === null || _u === void 0 ? void 0 : _u.sc));
    var isPossibleCard = POSSIBLE_RED_CARDS_IDS.includes(((_v = event.bsr) === null || _v === void 0 ? void 0 : _v.bid) || 0) && event.bs;
    var prevPossCard = usePrevious(isPossibleCard);
    var c1RedCards = (((_w = event.c1) === null || _w === void 0 ? void 0 : _w.rc) || 0) + (((_x = event.c1) === null || _x === void 0 ? void 0 : _x.yrc) || 0);
    var c2RedCards = (((_y = event.c2) === null || _y === void 0 ? void 0 : _y.rc) || 0) + (((_z = event.c2) === null || _z === void 0 ? void 0 : _z.yrc) || 0);
    var _15 = useState({ c1: 0, c2: 0 }), tempRedCard = _15[0], setTempRedCard = _15[1];
    var _16 = useState({
        c1: c1RedCards,
        c2: c2RedCards,
    }), redCardsBeforePossCard = _16[0], setRedCardsBeforePossCard = _16[1];
    var _17 = useState({
        c1: c1RedCards,
        c2: c2RedCards,
    }), redCardsToShow = _17[0], setRedCardsToShow = _17[1];
    var _18 = useState(false), noCard = _18[0], setNoCard = _18[1];
    var localeTime = new Date(date !== null && date !== void 0 ? date : NaN).toLocaleTimeString(i18next.language, {
        hour: '2-digit',
        minute: '2-digit',
    });
    var localeDate = new Date(date !== null && date !== void 0 ? date : NaN).toLocaleDateString(i18next.language, {
        weekday: 'short',
        day: '2-digit',
        month: 'short',
    });
    var SportIcon = event.sbi ? mapSportIdToSportIcon[event.sbi] : SoccerIcon;
    var onGoal = function (isGoal) {
        setGoal(isGoal);
        if (setIsGoal) {
            setIsGoal(isGoal);
        }
    };
    useEffect(function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        var tId;
        if (isPossibleGoal && !prevIsPossibleGoal) {
            setScoreBeforePossibleGoal({
                c1: ((_a = event === null || event === void 0 ? void 0 : event.c1) === null || _a === void 0 ? void 0 : _a.sc) || 0,
                c2: ((_b = event === null || event === void 0 ? void 0 : event.c2) === null || _b === void 0 ? void 0 : _b.sc) || 0,
            });
            setScoreToShow({
                c1: ((_c = event === null || event === void 0 ? void 0 : event.c1) === null || _c === void 0 ? void 0 : _c.sc) || 0,
                c2: ((_d = event === null || event === void 0 ? void 0 : event.c2) === null || _d === void 0 ? void 0 : _d.sc) || 0,
            });
            return;
        }
        if (prevIsPossibleGoal && !isPossibleGoal) {
            if (isSoccer &&
                (scoreBeforePossibleGoal.c1 < Number((_e = event === null || event === void 0 ? void 0 : event.c1) === null || _e === void 0 ? void 0 : _e.sc) ||
                    scoreBeforePossibleGoal.c2 < Number((_f = event === null || event === void 0 ? void 0 : event.c2) === null || _f === void 0 ? void 0 : _f.sc))) {
                onGoal(true);
                setScoreToShow({
                    c1: ((_g = event === null || event === void 0 ? void 0 : event.c1) === null || _g === void 0 ? void 0 : _g.sc) || 0,
                    c2: ((_h = event === null || event === void 0 ? void 0 : event.c2) === null || _h === void 0 ? void 0 : _h.sc) || 0,
                });
                tId = setTimeout(function () {
                    onGoal(false);
                }, Number(process.env.GOAL_SCORE_BLINKING_DURATION) * 1000);
            }
            else if (isSoccer) {
                setNoGoal(true);
                tId = setTimeout(function () {
                    setNoGoal(false);
                }, 9000);
            }
            return;
        }
        if (!isPossibleGoal && !prevIsPossibleGoal && (c1Goal || c2Goal)) {
            onGoal(true);
            setScoreToShow({
                c1: ((_j = event === null || event === void 0 ? void 0 : event.c1) === null || _j === void 0 ? void 0 : _j.sc) || 0,
                c2: ((_k = event === null || event === void 0 ? void 0 : event.c2) === null || _k === void 0 ? void 0 : _k.sc) || 0,
            });
            tId = setTimeout(function () {
                onGoal(false);
            }, Number(process.env.GOAL_SCORE_BLINKING_DURATION) * 1000);
        }
        return function () {
            if (tId) {
                clearTimeout(tId);
            }
        };
    }, [isPossibleGoal, c1Goal, c2Goal]);
    useEffect(function () {
        var _a, _b;
        var tId;
        if (!prevPossCard && isPossibleCard) {
            setRedCardsBeforePossCard({
                c1: c1RedCards,
                c2: c2RedCards,
            });
        }
        if (!isPossibleCard && prevPossCard) {
            if (((prevBetstopReason === null || prevBetstopReason === void 0 ? void 0 : prevBetstopReason.ti) === '1' &&
                redCardsBeforePossCard.c1 >= c1RedCards) ||
                ((prevBetstopReason === null || prevBetstopReason === void 0 ? void 0 : prevBetstopReason.ti) === '2' &&
                    redCardsBeforePossCard.c2 >= c2RedCards)) {
                setNoCard(true);
                setRedCardsToShow({
                    c1: c1RedCards,
                    c2: c2RedCards,
                });
                tId = setTimeout(function () {
                    setNoCard(false);
                }, 5000);
                setTempRedCard({ c1: 0, c2: 0 });
            }
        }
        if (!prevPossCard && !isPossibleCard) {
            setRedCardsToShow({
                c1: c1RedCards,
                c2: c2RedCards,
            });
        }
        if (isPossibleCard) {
            setTempRedCard({
                c1: (prevBetstopReason === null || prevBetstopReason === void 0 ? void 0 : prevBetstopReason.ti) === '1' && !!Number((_a = event.bsr) === null || _a === void 0 ? void 0 : _a.itrc) ? 1 : 0,
                c2: (prevBetstopReason === null || prevBetstopReason === void 0 ? void 0 : prevBetstopReason.ti) === '2' && !!Number((_b = event.bsr) === null || _b === void 0 ? void 0 : _b.itrc) ? 1 : 0,
            });
        }
        if (prevPossCard === true && !isPossibleCard) {
            setNoCard(true);
            tId = setTimeout(function () {
                if (setNoCard) {
                    setNoCard(false);
                }
            }, 5000);
            setTempRedCard({ c1: 0, c2: 0 });
        }
        return function () {
            if (tId) {
                clearTimeout(tId);
                setNoCard(false);
            }
        };
    }, [isPossibleCard, c1RedCards, c2RedCards]);
    var handleClickDelete = function (eventId, selectionId) {
        return dispatch(deleteOutcomeFromEvent({ eventId: eventId, selectionId: selectionId, eventsType: 'betSlip' })).then(function () {
            dispatch(getShortcutBetslip());
        });
    };
    var bankerOnClick = function () {
        var _a;
        return bankerClickHandler
            ? bankerClickHandler(event && !((_a = event.sl) === null || _a === void 0 ? void 0 : _a.ba), event.id)
            : null;
    };
    var rootBackground = ticketDetails
        ? classes.greyBlueBackground
        : classes.blackBackground;
    var headerBackground = ticketDetails
        ? classes.darkGreyBackground
        : classes.lightGreyBackground;
    var redirectToCategory = function (flagId, tournamentID) {
        dispatch(push({
            pathname: getPathWithLanguageCode("/home/".concat(flagId, "/").concat(tournamentID)),
            state: {
                dt: event === null || event === void 0 ? void 0 : event.dt,
                scrollFlag: true,
            },
        }));
    };
    var pickIdAsync = function (id) {
        setTimeout(function () {
            dispatch(pickActiveSidebetId(id));
        }, 1000);
    };
    var pickActiveSidebetIdHandler = function () {
        if (selectedSportId !== event.si) {
            dispatch(changeLocalSportId({
                id: event.si,
                desktop: isDesktop,
                langIso: i18next.language,
            }));
        }
        if ((event === null || event === void 0 ? void 0 : event.flid) !== flagId) {
            dispatch(resetTournaments());
        }
        redirectToCategory(event === null || event === void 0 ? void 0 : event.flid, (event === null || event === void 0 ? void 0 : event.tpi) || (event === null || event === void 0 ? void 0 : event.ti));
        pickIdAsync(event.id);
    };
    var pickActiveOutrightHandler = function () {
        dispatch(changeLocalSportId({
            id: event.si,
            desktop: isDesktop,
            langIso: i18next.language,
        }));
        dispatch(push({
            pathname: getPathWithLanguageCode("/home/".concat(event === null || event === void 0 ? void 0 : event.flid, "/outrights/").concat(event.ti)),
            search: "?outrightId=".concat(event.id),
        }));
        if (onClose) {
            onClose();
        }
    };
    return (React.createElement("div", { className: "".concat(classes.root, " ").concat(rootBackground), "data-testid": getTestId('OBJ_MATCH_IN_TICKET') },
        React.createElement("div", { className: "".concat(classes.header, " ").concat(headerBackground) },
            event.fl && (React.createElement("img", { alt: "category-flag", src: "".concat(S3_RESOURCES).concat(event.fl), className: classes.headerFlag })),
            React.createElement("div", { className: classes.headerIcon },
                React.createElement(SportIcon, null)),
            React.createElement("div", { className: classes.tournamentTitle }, event.tn || event.cn),
            goal && (React.createElement("div", { className: classNames(classes.goalPanel) },
                React.createElement("div", { className: classes.goalPaneText }, t('goal')))),
            noCard && !goal && !isFinished && (React.createElement("div", { className: classNames(classes.bsr, classes.nocard, {
                    animation: false,
                }) },
                React.createElement("div", { className: classNames(classes.bsrText, { animation: false }) }, t('no_card')))),
            noGoal && !goal && !isFinished && (React.createElement("div", { className: classNames(classes.bsr, classes.nogoal, {
                    animation: false,
                }) },
                React.createElement("div", { className: classNames(classes.bsrText, { animation: false }) }, t('no_goal')))),
            isBetstopReason && !noGoal && !noCard && !isFinished && !goal && (React.createElement("div", { className: classNames(classes.bsr, { animation: false }) },
                React.createElement("div", { className: classNames(classes.bsrText, { animation: false }) }, t("betstop_reason.".concat((_0 = event.bsr) === null || _0 === void 0 ? void 0 : _0.br))))),
            React.createElement("div", { className: classes.headerSpacer }),
            showBanker ? (React.createElement("div", { role: "button", tabIndex: 0, onClick: bankerOnClick, className: "".concat(classes.badge, " ").concat(classes.banker, " ").concat(((_1 = event.sl) === null || _1 === void 0 ? void 0 : _1.ba) ? classes.badgeSuccess : '', " ").concat(classes.headerBadge) }, t('bankerSymbol'))) : null,
            event.pa || (!event.la && event.dt && !isFinished) ? (React.createElement("div", { className: classes.textRight },
                localeDate,
                React.createElement("br", null),
                localeTime)) : null,
            !NOT_STARTED_STATUSES.includes(event.ms || '') &&
                (event.lsd || event.ls) && (React.createElement("div", { className: "".concat(classes.badge, " ").concat(classes.badgePrimary, " ").concat(classes.headerBadge) }, ((_2 = event.lsd) === null || _2 === void 0 ? void 0 : _2.st)
                ? "".concat(event.tm ? "".concat(event.tm, "\u2019") : '')
                : "".concat(event.lsd ? (_3 = event.lsd) === null || _3 === void 0 ? void 0 : _3.tx : event.ls)))),
        event.c1 && event.c2 ? (React.createElement(CompetitorBlock, { toRedirectToSidebets: toRedirectToSidebets, isDesktop: isDesktop, id: (_4 = event.id) !== null && _4 !== void 0 ? _4 : NaN, sportId: (_5 = event.si) !== null && _5 !== void 0 ? _5 : NaN, 
            // @ts-expect-error
            sr: event.sr, c1: event.c1, c2: event.c2, currentScore: scoreToShow, currentRedCards: redCardsToShow, temporaryRedCards: tempRedCard, pickActiveSidebetIdHandler: pickActiveSidebetIdHandler })) : (React.createElement("div", { role: "button", "aria-label": "show details", tabIndex: 0, onClick: pickActiveOutrightHandler, className: classNames(classes.flexRow, classes.outrightsBlock, (_b = {},
                _b[classes.competitorBlockDesktop] = isDesktop,
                _b[classes.pointer] = isOutrightEventActive,
                _b)) },
            React.createElement("span", { className: classes.competitor }, (_7 = Object.values(((_6 = event.sl) === null || _6 === void 0 ? void 0 : _6.sel) || { 1: { na: 'Report Illia About that' } })[0]) === null || _7 === void 0 ? void 0 : _7.na))),
        React.createElement("div", { className: classNames('flex-column', (_c = {},
                _c[classes.competitorBlockDesktop] = isDesktop,
                _c)) }, Object.entries(((_8 = event.sl) === null || _8 === void 0 ? void 0 : _8.sel) || {}).map(function (_a) {
            var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
            var key = _a[0], selection = _a[1];
            return !checkDeactivatedOdds || isSelectionActive(selection) ? (React.createElement("div", { key: "".concat(key, "-").concat((_b = selection === null || selection === void 0 ? void 0 : selection.oc) === null || _b === void 0 ? void 0 : _b.id), className: classNames(classes.flexRow, classes.marketRow) },
                React.createElement("div", { className: classNames(classes.flexRow, classes.marketName) },
                    React.createElement("span", null,
                        key,
                        ".",
                        ' ',
                        ((_c = selection.ot) === null || _c === void 0 ? void 0 : _c.toUpperCase()) === 'OUTRIGHTS'
                            ? (_d = selection === null || selection === void 0 ? void 0 : selection.oc) === null || _d === void 0 ? void 0 : _d.na
                            : selection.na),
                    "\u00A0",
                    (event === null || event === void 0 ? void 0 : event.bs) &&
                        (((_e = event === null || event === void 0 ? void 0 : event.bsr) === null || _e === void 0 ? void 0 : _e.br) === 'UNDEFINED' || !!Number((_f = event === null || event === void 0 ? void 0 : event.bsr) === null || _f === void 0 ? void 0 : _f.bsi)) &&
                        !ticketDetails &&
                        !purchasedTicket ? (React.createElement("i", { className: "far fa-octagon ".concat(classes.colorDanger) })) : null),
                React.createElement("div", { className: "flex-row" },
                    ((_g = selection.ot) === null || _g === void 0 ? void 0 : _g.toUpperCase()) !== 'OUTRIGHTS' && (React.createElement("div", { className: classes.marketValue }, ((_h = selection.oc) === null || _h === void 0 ? void 0 : _h.sn) || ((_j = selection.oc) === null || _j === void 0 ? void 0 : _j.na) || '')),
                    React.createElement(EventOdd, { odd: (_l = (_k = selection.oc) === null || _k === void 0 ? void 0 : _k.odd) === null || _l === void 0 ? void 0 : _l.val, ticketDetails: ticketDetails, onClick: deletable
                            ? function () { return handleClickDelete(event.id, key); }
                            : undefined }),
                    React.createElement("div", { className: classes.deletableBlock },
                        !ticketDetails ? (deletable ? (React.createElement(IconButton, { className: classes.deleteButton, onClick: function () { return handleClickDelete(event.id, key); } },
                            React.createElement("i", { className: "far fa-times-circle" }))) : (React.createElement(WonLostIndicator, { status: (_o = (_m = selection.oc) === null || _m === void 0 ? void 0 : _m.odd) === null || _o === void 0 ? void 0 : _o.st }))) : null,
                        event.bs && ticketDetails ? (React.createElement("i", { className: "far fa-octagon ".concat(classes.colorDanger) })) : null)))) : (React.createElement(EventDeactivatedOdd, { key: key, onDeleteClick: deletableOdds
                    ? function () { return handleClickDelete(event.id, key); }
                    : undefined }));
        }))));
};
