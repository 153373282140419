var _a;
import { createSlice } from '@reduxjs/toolkit';
import { SOON_LIVE_SOCKET_NAME } from '~slices/events';
import { name } from './constants';
var initialState = {
    activeSoonLiveTab: SOON_LIVE_SOCKET_NAME,
    isMobileTopRankingHidden: false,
};
export var view = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        setIsMobileTopRankingHidden: function (state, action) {
            state.isMobileTopRankingHidden = action.payload;
        },
        resetActiveSoonLiveTab: function (state) {
            state.activeSoonLiveTab = initialState.activeSoonLiveTab;
        },
        setActiveSoonLiveTab: function (state, action) {
            state.activeSoonLiveTab = action.payload;
        },
    },
});
export var setIsMobileTopRankingHidden = (_a = view.actions, _a.setIsMobileTopRankingHidden), setActiveSoonLiveTab = _a.setActiveSoonLiveTab, resetActiveSoonLiveTab = _a.resetActiveSoonLiveTab;
