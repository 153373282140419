var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Popper } from '@material-ui/core';
import { ArrowPositionProvider } from './ArrowPositionProvider';
import { useStyles } from './Hint.styles';
var CustomPopper = function (props) {
    var _a, _b, _c;
    var arrowElement = (_c = (_b = (_a = props.popperOptions) === null || _a === void 0 ? void 0 : _a.modifiers) === null || _b === void 0 ? void 0 : _b.arrow) === null || _c === void 0 ? void 0 : _c.element;
    var classes = useStyles();
    return (React.createElement(ArrowPositionProvider, { element: arrowElement },
        React.createElement(Popper, __assign({}, props, { className: "".concat(props.className || '', " ").concat(classes.popper) }))));
};
export { CustomPopper };
