var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import FormGroup from '@material-ui/core/FormGroup';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Button } from '~atoms/button';
import { LOGOUT_PRESS } from '~constants/cpa-message-codes';
import { useAppDispatch } from '~store';
import userIcon from '~icons/userCircle.svg';
import { logout, useAccessTokenSelector } from '~slices/auth';
import { openModal } from '~modals';
import { usePlatofrmType, useStructuredSelector } from '~hooks';
import { parseJwt } from '~utils/parseJwt';
import { getTestId } from '~utils/getTestId';
import { getPathWithLanguageCode } from '~utils/languageInURL/getPathWithLanguageCode';
import { isBetCheckProject } from '~utils/constants/betCheckConstants';
import { PLATFORMS } from '~slices/teasers';
import { resetGames } from '~slices/gameTypes';
var useStyles = makeStyles({
    systemMultiselect: {
        paddingRight: '15px',
        '~media (min-width:370px)': {
            paddingRight: '37px',
        },
    },
    dropdownButton: {
        padding: '0 16px',
        justifyContent: 'space-between',
        width: '200px',
        border: '1px solid #FFFFFF',
        '& i': {
            color: 'white',
            fontSize: '14px !important',
        },
    },
    button: {
        color: '#fff',
        width: '100%',
        textAlign: 'start',
        padding: '8px 0 8px 25px',
        fontSize: '14px',
        lineHeight: '20px',
        backgroundColor: 'transparent',
        border: 'none',
        outline: 'none',
        cursor: 'pointer',
        '&:hover': {
            background: 'rgba(255, 255, 255, 0.08)',
        },
    },
    icon: {
        marginRight: '23px',
        width: '16px',
        height: '12px',
        borderRadius: '2px',
    },
    mainIcon: {
        width: '22px',
        height: '22px',
    },
    label: {
        fontWeight: 'normal',
    },
    activeLanguage: {
        color: '#2AA1F7',
    },
    activeButton: {
        borderTop: '1px solid #444444',
        borderBottom: '1px solid #444444',
    },
    systemModal: {
        marginTop: '30px',
        color: '#fff',
        position: 'relative',
        background: 'radial-gradient(102.51% 128.71% at 20.97% 0%, #303038 0%, #1B1B22 100%)',
        '&::before': {
            content: '""',
            position: 'absolute',
            right: '20px',
            top: '-16px',
            borderBottom: '8px solid #212128',
            border: '8px solid transparent',
        },
    },
    group: {
        width: '200px',
        padding: '10px 0',
    },
    helloLable: {
        width: '100%',
        maxWidth: '110px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '18px',
        color: '#FFFFFF',
    },
}, { name: 'UserDropdownMenu' });
export var UserDropdownMenu = function (_a) {
    var t = _a.t;
    var classes = useStyles();
    var dispatch = useAppDispatch();
    var platform = usePlatofrmType();
    var _b = React.useState(false), open = _b[0], setOpen = _b[1];
    var anchorRef = React.useRef(null);
    var token = useStructuredSelector({
        token: useAccessTokenSelector,
    }).token;
    var userName = parseJwt(token || '').user_name;
    var tbj = useTranslation('tip-game').t;
    var handleClose = function (event) {
        if (anchorRef.current &&
            anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    var handleToggle = function () {
        setOpen(function (prevOpen) { return !prevOpen; });
    };
    var openEditModal = function (key) { return function () {
        handleToggle();
        dispatch(openModal({
            modalName: key,
            modalProps: {},
        }));
    }; };
    var onLogout = function () {
        dispatch(logout());
        dispatch(resetGames());
        if (platform === PLATFORMS.CROSS_PLATFORM_MOBILE) {
            // @ts-expect-error
            window.ReactNativeWebView.postMessage(JSON.stringify({
                code: LOGOUT_PRESS,
            }));
        }
        dispatch(push(getPathWithLanguageCode(isBetCheckProject ? '/home' : '/start')));
    };
    return (React.createElement("div", null,
        React.createElement(Button, { ref: anchorRef, "aria-controls": open ? 'menu-list-grow' : undefined, "aria-haspopup": "true", "data-testid": getTestId('BTN_USER_OPTIONS'), onClick: handleToggle, className: classes.dropdownButton },
            React.createElement("img", { src: userIcon, alt: "user icon", className: classes.mainIcon }),
            React.createElement("span", { className: classes.helloLable },
                t('hello'),
                ",\u00A0",
                React.createElement("b", null, userName)),
            open ? (React.createElement("i", { className: "far fa-chevron-up" })) : (React.createElement("i", { className: "far fa-chevron-down" }))),
        React.createElement(Popper, { open: open, anchorEl: anchorRef.current, placement: "bottom-end", role: undefined, transition: true, disablePortal: true, style: {
                zIndex: 1,
            } }, function (_a) {
            var TransitionProps = _a.TransitionProps, placement = _a.placement;
            return (React.createElement(Grow, __assign({}, TransitionProps, { style: {
                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                } }),
                React.createElement(Paper, { className: classes.systemModal },
                    React.createElement(ClickAwayListener, { onClickAway: handleClose },
                        React.createElement(MenuList, { autoFocusItem: open, disablePadding: true, id: "menu-list-grow" },
                            React.createElement(FormGroup, { className: classes.group },
                                !isBetCheckProject && (React.createElement(React.Fragment, null,
                                    React.createElement("button", { type: "button", onClick: function () {
                                            return dispatch(push(getPathWithLanguageCode("/my-tips")));
                                        }, className: classes.button },
                                        React.createElement("span", { className: classes.label }, tbj('my-tips.my_tips'))),
                                    React.createElement("button", { type: "button", onClick: function () {
                                            return dispatch(push(getPathWithLanguageCode('/about')));
                                        }, className: classes.button },
                                        React.createElement("span", { className: classes.label }, t('betj_history'))))),
                                React.createElement("button", { type: "button", onClick: onLogout, "data-testid": getTestId('BTN_LOGOUT'), className: classes.button },
                                    React.createElement("span", { className: classes.label }, t('logout')))))))));
        })));
};
