import { useSelector } from 'react-redux';
import { name } from './constants';
export var rankingListsSelector = function (state) { return state[name]; };
export var rankingListsDataSelector = function (state) {
    return rankingListsSelector(state).data;
};
export var useRankingListsDataSelector = function () {
    return useSelector(rankingListsDataSelector);
};
export var rankingListsLoadingSelector = function (state) {
    return rankingListsSelector(state).loading;
};
export var useRankingLoadingSelector = function () {
    return useSelector(rankingListsLoadingSelector);
};
