import React from 'react';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { HighlightedText } from '~atoms';
import { noop } from '~utils/noop';
import { getPathWithLanguageCode } from '~utils/languageInURL/getPathWithLanguageCode';
import { useStructuredSelector } from "~hooks";
import { selectFlagsData, setSelectedFlag } from "~slices/flags";
import { sportNameSelector } from "~slices/options";
import { useStyles } from './CategoryCard.styles';
var S3_RESOURCES = process.env.REACT_APP_CDN || '';
var CategoryCard = function (_a) {
    var id = _a.id, _b = _a.active, active = _b === void 0 ? false : _b, _c = _a.highlightedValue, highlightedValue = _c === void 0 ? '' : _c, _d = _a.onClick, onClick = _d === void 0 ? noop : _d, fl = _a.fl, name = _a.name, defaultUrl = _a.defaultUrl;
    var _e = useStructuredSelector({
        flags: selectFlagsData,
        sportName: sportNameSelector,
    }), flags = _e.flags, sportName = _e.sportName;
    var classes = useStyles();
    var location = useLocation();
    var dispatch = useDispatch();
    var flag = flags === null || flags === void 0 ? void 0 : flags.find(function (fl) { return fl.id === id; });
    var url = defaultUrl && sportName
        ? getPathWithLanguageCode("".concat(defaultUrl, "/").concat(sportName === null || sportName === void 0 ? void 0 : sportName.name, "/").concat((flag === null || flag === void 0 ? void 0 : flag.name) || (flag === null || flag === void 0 ? void 0 : flag.id)))
        : getPathWithLanguageCode('/home/all/live');
    var isSelected = location.pathname === url || location.pathname.startsWith("".concat(url, "/"));
    var handleClick = function () {
        onClick(id);
        dispatch(setSelectedFlag({ id: flag === null || flag === void 0 ? void 0 : flag.id, name: (flag === null || flag === void 0 ? void 0 : flag.name) || (flag === null || flag === void 0 ? void 0 : flag.id) }));
        dispatch(push(getPathWithLanguageCode("".concat(defaultUrl, "/").concat(sportName === null || sportName === void 0 ? void 0 : sportName.name, "/").concat((flag === null || flag === void 0 ? void 0 : flag.name) || (flag === null || flag === void 0 ? void 0 : flag.id)))));
    };
    return (React.createElement(Link, { to: getPathWithLanguageCode(url), onClick: handleClick, className: classes.container },
        React.createElement("div", { className: classNames(classes.status, isSelected && classes.active) }),
        React.createElement("div", { className: classes.content },
            React.createElement("img", { style: {
                    maxWidth: '30px',
                    width: '100%',
                    height: '20px',
                    borderRadius: '2px',
                }, alt: "category-flag", src: "".concat(S3_RESOURCES).concat(fl) }),
            React.createElement("div", { className: classes.name },
                React.createElement(HighlightedText, { value: name, highlightValue: highlightedValue })))));
};
export { CategoryCard };
