import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import makeStyles from '@material-ui/core/styles/makeStyles';
var useStyles = makeStyles({
    helperText: {
        fontWeight: 300,
        fontSize: '14px',
        lineHeight: '21px',
        marginBottom: '61px',
    },
    radius: {
        borderRadius: '8px',
        position: 'relative',
    },
    title: {
        padding: '24px 24px 2px',
        color: '#212121',
        fontSize: '18px',
        lineHeight: '27px',
        fontWeight: 500,
    },
    text: {
        padding: '0 24px',
    },
    textPrimary: {
        color: '#2AA1F7',
    },
    content: {
        '& pre': {
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            whiteSpace: 'pre-wrap',
            fontWeight: 300,
            fontSize: '14px',
            lineHeight: '21px',
            color: '#212121',
        },
    },
    buttonLabel: {
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '21px',
    },
    closeIcon: {
        position: 'absolute',
        top: '8px',
        right: '10px',
        color: '#ECECEC',
        padding: '0',
        minWidth: '0',
        height: '16px',
    },
    shinyBack: { background: '#fff' },
}, { name: 'AlertDialogSlide' });
export var AlertDialogSlide = function (_a) {
    var open = _a.open, handleClose = _a.handleClose, title = _a.title, content = _a.content, needConfirm = _a.needConfirm, firstButtonText = _a.firstButtonText, secondButtonText = _a.secondButtonText, submitHandler = _a.submitHandler, additionalHandler = _a.additionalHandler;
    var classes = useStyles();
    var t = useTranslation('common').t;
    return (React.createElement("div", null,
        React.createElement(Dialog, { open: open, keepMounted: true, onClose: handleClose, "aria-labelledby": "alert-dialog-slide-title", "aria-describedby": "alert-dialog-slide-description", classes: { paper: classes.radius, paperScrollPaper: classes.shinyBack } },
            React.createElement(DialogTitle, { classes: { root: classes.title }, id: "alert-dialog-slide-title", disableTypography: true }, title),
            React.createElement(DialogContent, { classes: { root: classes.text } },
                React.createElement(DialogContentText, { id: "alert-dialog-slide-description", classes: { root: classes.content } },
                    React.createElement("pre", null, content))),
            React.createElement(DialogActions, { style: { padding: '8px 16px' } },
                needConfirm ? (React.createElement(Button, { onClick: additionalHandler || handleClose, classes: {
                        textPrimary: classes.textPrimary,
                        label: classes.buttonLabel,
                    }, color: "primary", style: { marginRight: 'auto' } }, (firstButtonText === null || firstButtonText === void 0 ? void 0 : firstButtonText.toUpperCase()) || t('close'))) : null,
                React.createElement(Button, { onClick: submitHandler || handleClose, classes: {
                        textPrimary: classes.textPrimary,
                        label: classes.buttonLabel,
                    }, color: "primary", style: needConfirm ? {} : { margin: '0 auto' } }, (secondButtonText === null || secondButtonText === void 0 ? void 0 : secondButtonText.toUpperCase()) || t('ok'))),
            React.createElement(Button, { className: classes.closeIcon, onClick: handleClose },
                React.createElement("i", { className: "far fa-times" })))));
};
