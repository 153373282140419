import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var PesapalloIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("svg", { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("g", { clipPath: "url(#clip0)" },
                React.createElement("path", { d: "M11.519 12.9005C12.6692 11.5976 13.835 10.2791 14.9976 8.96066C16.2039 7.59541 17.4071 6.22705 18.6102 4.86181C19.6045 3.73969 20.5957 2.61445 21.5901 1.49233C21.7802 1.28037 21.9579 1.05283 22.1729 0.865813C23.5631 -0.352933 25.4209 -0.278125 26.7393 1.03413C27.1539 1.44557 27.5404 1.87572 27.7617 2.42743C28.2667 3.67423 27.9425 5.14234 26.942 6.0338C25.1185 7.65152 23.2888 9.26301 21.4592 10.8776C19.5734 12.5421 17.6876 14.2097 15.8018 15.8742C15.5649 16.083 15.328 16.2918 15.1005 16.4944C13.9035 15.2944 12.716 14.1037 11.519 12.9005Z", fill: "#F9F9F9" }),
                React.createElement("path", { d: "M10.7998 13.7198C11.9655 14.8887 13.1188 16.042 14.2877 17.2109C11.2954 19.851 8.32797 22.4693 5.36059 25.0875C4.54394 24.2709 3.73663 23.4636 2.9231 22.65C5.53826 19.6827 8.15654 16.7122 10.7998 13.7198Z", fill: "#656D78" }),
                React.createElement("path", { d: "M26.4008 22.6652C24.9015 22.5499 23.5924 22.9863 22.5232 24.0523C21.4572 25.1183 21.0271 26.4212 21.1299 27.9392C20.1668 27.8394 19.319 27.4779 18.5834 26.8607C17.6015 26.0378 17.0404 24.9843 16.8752 23.6876C18.387 23.8029 19.6992 23.3665 20.7653 22.3036C21.8344 21.2376 22.2739 19.9254 22.1554 18.423C24.3373 18.5819 26.2481 20.4927 26.4008 22.6652Z", fill: "#EEFE39" }),
                React.createElement("path", { d: "M2.92627 27.9998C2.68938 27.9935 2.32469 27.8688 2.03481 27.5852C1.48933 27.0522 0.943858 26.5192 0.417085 25.9644C-0.0754006 25.447 -0.134624 24.649 0.242533 24.0474C0.613456 23.4521 1.3366 23.1653 2.00987 23.3524C2.08156 23.3711 2.15325 23.4147 2.20624 23.4646C2.98549 24.2407 3.76474 25.0168 4.54087 25.7961C4.70608 25.9644 4.70296 26.1857 4.70296 26.3945C4.71543 27.2922 3.98917 28.006 2.92627 27.9998Z", fill: "#656D78" }),
                React.createElement("path", { d: "M26.401 23.7531C26.1953 25.8851 24.3812 27.7179 22.2274 27.9268C21.8502 25.6544 23.8825 23.3884 26.401 23.7531Z", fill: "#EEFE39" }),
                React.createElement("path", { d: "M16.9028 22.6188C17.0774 20.5055 18.9071 18.6291 21.0827 18.4265C21.2199 19.6484 20.8739 20.705 20.0011 21.5684C19.144 22.4162 18.0966 22.7373 16.9028 22.6188Z", fill: "#EEFE39" })),
            React.createElement("defs", null,
                React.createElement("clipPath", { id: "clip0" },
                    React.createElement("rect", { width: "27.9969", height: "28", fill: "white" }))))));
};
