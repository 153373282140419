import makeStyles from '@material-ui/core/styles/makeStyles';
export var useStyles = makeStyles(function (theme) {
    var _a;
    return ({
        container: {
            background: 'radial-gradient(100% 100% at 50.13% 0%, #303038 0%, #1B1B22 100%)',
        },
        content: (_a = {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: '16px 15px 32px 15px',
                minHeight: 'calc(100vh - 148px)',
                background: '#151515'
            },
            _a[theme.breakpoints.up('lg')] = {
                minHeight: 'calc(100vh - 202px)',
            },
            _a),
        fullWidth: {
            width: '100%',
        },
    });
}, { name: 'Payments' });
