export var Currencies = {
    EUR: '€',
    USD: '$',
    UAH: '₴',
    GBP: '£',
    JPY: '¥',
    RUB: '₽',
    PLN: 'zł',
    NOK: 'kr',
    HUF: 'ft',
    CHF: 'CHF',
    CRD: 'CRD',
    BGN: 'лв',
    BYN: 'BYN',
    TRY: '₺',
    NONE: '',
};
export var currencyConverter = function (amount, currency, options) {
    var _a;
    if (currency === void 0) { currency = 'NONE'; }
    if (options === void 0) { options = {
        minimumFractionDigits: 2,
    }; }
    var currencyValue = currency === 'CRD' ? '' : (_a = Currencies[currency]) !== null && _a !== void 0 ? _a : currency;
    return "".concat(new Intl.NumberFormat(navigator.language, options).format(Number(amount) || 0), " ").concat(currencyValue);
};
export var wagerConverter = function (wager) {
    return Number(wager.replace(/[^0-9\\.]+/g, '')) || undefined;
};
