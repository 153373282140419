import makeStyles from '@material-ui/core/styles/makeStyles';
var useStyles = makeStyles({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#2AA1F7',
        width: '100%',
        height: '36px',
    },
}, { name: 'CategoryHeader' });
export { useStyles };
