import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CurrencyInput } from '~view/pages/ticketSelection/components/currencyInput/CurrencyInput';
import { noop } from '~utils/noop';
import { isBetCheckProject } from '~utils/constants/betCheckConstants';
import { CSIcon } from '~icons/rankingList/CSIcon';
import { Currency } from '../Currency';
import { useStyles } from './WagerView.styles';
var WagerView = React.forwardRef(function WagerView(_a, ref) {
    var value = _a.value, _b = _a.onChange, onChange = _b === void 0 ? noop : _b, onValidate = _a.onValidate, onBluer = _a.onBluer, wagerValue = _a.wagerValue, _c = _a.currency, currency = _c === void 0 ? 'NONE' : _c, onContainerClick = _a.onContainerClick;
    var classes = useStyles();
    var t = useTranslation('ticket').t;
    var inputRef = useRef(null);
    var handleRefChange = useCallback(function (node) {
        inputRef.current = node;
        if (!ref) {
            return;
        }
        if (typeof ref === 'function') {
            ref(node);
        }
        else {
            ref.current = node;
        }
    }, []);
    var handlecontainerClick = function () {
        if (inputRef.current) {
            inputRef.current.focus();
        }
        onContainerClick();
    };
    return (React.createElement("div", { tabIndex: 0, role: "button", className: classes.container, onClick: handlecontainerClick },
        React.createElement(CurrencyInput, { ref: handleRefChange, value: value, onChange: onChange, onValidate: onValidate, onBluer: onBluer, inputClassNames: classes.input, placeholder: t('wager'), wagerValue: wagerValue }),
        React.createElement("div", { className: classes.divider }),
        isBetCheckProject ? (React.createElement(Currency, { currency: currency })) : (React.createElement(CSIcon, { size: "lg" }))));
});
export { WagerView };
