import React, { useState } from 'react';
import { useStyles } from './BankDetailsInput.styles';
export var BankDetailsInput = function (_a) {
    var label = _a.label, placeholder = _a.placeholder, value = _a.value, onlyLetters = _a.onlyLetters, onInputChange = _a.onInputChange;
    var classes = useStyles();
    var reg = onlyLetters
        ? /^[a-zA-Z\s]{1,50}$/
        : /^\d{1,20}((\.|,)?)(\d{1,2})?$/;
    var _b = useState(''), inputValue = _b[0], setInputValue = _b[1];
    var onChange = function (event) {
        var eventValue = event.target.value;
        var newValue = eventValue.replace(',', '.');
        if (newValue.match(reg) || newValue === '') {
            setInputValue(newValue);
            onInputChange(newValue);
        }
    };
    return (React.createElement("div", { className: classes.inputWrapper },
        React.createElement("label", { className: classes.label }, label),
        React.createElement("input", { value: inputValue || value, placeholder: placeholder, className: classes.input, onChange: onChange, autoComplete: "off", 
            // onKeyDown={onKeyDown}
            type: "text" })));
};
