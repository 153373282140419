import { useSelector } from 'react-redux';
import { name } from '~slices/bp/constants';
export var bpSelector = function (state) { return state[name]; };
export var bpDataSelector = function (state) { return bpSelector(state).data; };
export var bpResponseObject = function (state) {
    return bpDataSelector(state).responseObject;
};
export var loadingSelector = function (state) { return bpSelector(state).loading; };
/* @deprecated use useStructuredSelector instead */
export var useBpSelector = function () { return useSelector(bpSelector); };
/* @deprecated use useStructuredSelector instead */
export var useBpDataSelector = function () { return useSelector(bpDataSelector); };
/* @deprecated use useStructuredSelector instead */
export var useResponseObjectSelector = function () { return useSelector(bpResponseObject); };
/* @deprecated use useStructuredSelector instead */
export var useLoadingSelector = function () { return useSelector(loadingSelector); };
