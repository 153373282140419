import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { CSIcon } from '~icons/rankingList/CSIcon';
import { numberWithDotsAndComma } from '~utils/numberWithDotsAndComma';
import { useStructuredSelector } from '~hooks';
import { isAuthenticatedSelector } from '~slices/auth';
import { useSelectedGameDataSelector } from "~slices/gameTypes";
import { useStyles } from './GameTypeButton.styles';
export var GameTypeButton = function (_a) {
    var _b;
    var name = _a.name, creditBalance = _a.creditBalance, playerBalance = _a.playerBalance, isActiveButton = _a.isActiveButton, isActiveGame = _a.isActiveGame, tournament = _a.tournament, onClick = _a.onClick;
    var _c = useStructuredSelector({
        isAuthenticated: isAuthenticatedSelector,
        selectedGame: useSelectedGameDataSelector,
    }), isAuthenticated = _c.isAuthenticated, selectedGame = _c.selectedGame;
    var classes = useStyles();
    var t = useTranslation('tip-game').t;
    var header = tournament || t("game-name.".concat(name));
    var creditBalanceWithDotsAndComma = creditBalance
        ? numberWithDotsAndComma(creditBalance)
        : null;
    var playerBalanceWithDotsAndComma = playerBalance || playerBalance === 0
        ? numberWithDotsAndComma(playerBalance)
        : null;
    var isInActive = !isActiveGame || (selectedGame && selectedGame !== name);
    var isActive = isAuthenticated && isActiveButton && !isInActive;
    var onButtonClick = function () {
        if (!isAuthenticated || isInActive)
            return;
        onClick();
    };
    return (React.createElement("div", { role: "button", tabIndex: 0, className: classNames(classes.wrapper, isActive && classes.activeButton, isAuthenticated && classes.cursorPointer, isInActive && classes.inActive), onClick: onButtonClick },
        React.createElement("div", { className: classes.header }, header),
        React.createElement("div", { className: classNames(classes.content, playerBalanceWithDotsAndComma && classes.spaceEvenly, !isActiveGame && classes.justifyContentCenter) }, !isActiveGame ? (React.createElement("span", null, t('the_game_will_start_soon'))) : (React.createElement(React.Fragment, null,
            playerBalanceWithDotsAndComma ? (React.createElement("span", null, t('balance'))) : (React.createElement("span", null, t('join_the_game'))),
            React.createElement("span", { className: classes.balance }, (_b = playerBalanceWithDotsAndComma !== null && playerBalanceWithDotsAndComma !== void 0 ? playerBalanceWithDotsAndComma : creditBalanceWithDotsAndComma) !== null && _b !== void 0 ? _b : '00.00',
                React.createElement(CSIcon, { size: "sm" })),
            !playerBalanceWithDotsAndComma && React.createElement("span", null, t('free'))))),
        isActive && (React.createElement("div", { className: classes.activeButtonCheckMark },
            React.createElement("i", { className: "fa fa-check fa-xs", "aria-hidden": "true" })))));
};
