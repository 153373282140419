var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import classNames from 'classnames';
import { reduxForm } from 'redux-form';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { goBack, push } from 'connected-react-router';
import { unwrapResult } from '@reduxjs/toolkit';
import Grid from '@material-ui/core/Grid';
import { accessTokenSelector, resendEmail, userEmailSelector, userIdSelector, } from '~slices/auth';
import { useStructuredSelector } from '~hooks';
import { validate } from '~utils/registrationFormValidation';
import asyncValidation from '~utils/asyncValidation';
import { useAppDispatch } from '~store';
import { Button, TextField } from '~atoms';
import { emailValidation, required } from '~utils/formValidators';
import { errorSelector as errorAccountSelector } from '~slices/account';
import { parseJwt } from '~utils/parseJwt';
import { getPathWithLanguageCode } from '~utils/languageInURL/getPathWithLanguageCode';
import { useRegisterStyles } from '../RegisterForm.styles';
var RegisterEmailScreen = function (_a) {
    var handleSubmit = _a.handleSubmit, isDesktop = _a.isDesktop, setStep = _a.setStep, backClick = _a.backClick, setEmail = _a.setEmail;
    var t = useTranslation('registration').t;
    var registerClasses = useRegisterStyles();
    var dispatch = useAppDispatch();
    var errors = useStructuredSelector({
        errors: errorAccountSelector,
    }).errors;
    var _b = useStructuredSelector({
        userId: userIdSelector,
        email: userEmailSelector,
        token: accessTokenSelector,
    }), userId = _b.userId, email = _b.email, token = _b.token;
    var accountError = errors === null || errors === void 0 ? void 0 : errors.errors;
    var location = useLocation();
    var userInfo = parseJwt(token || '');
    var sendLink = function (values) {
        var _a, _b, _c;
        return dispatch(resendEmail(__assign(__assign({}, values), { externalId: userId || ((_a = location.state) === null || _a === void 0 ? void 0 : _a.userId) || ((_b = accountError[0]) === null || _b === void 0 ? void 0 : _b.externalId), isOldEmail: ((userInfo === null || userInfo === void 0 ? void 0 : userInfo.email) || email || ((_c = accountError[0]) === null || _c === void 0 ? void 0 : _c.email)) === values.email })))
            .then(unwrapResult)
            .then(function () {
            if (isDesktop && setStep && setEmail) {
                setStep();
                setEmail(values.email || '');
            }
            else {
                dispatch(push({
                    pathname: getPathWithLanguageCode('/register/success'),
                    state: { email: values.email },
                }));
            }
        });
    };
    var goToThePrevious = function () { return dispatch(goBack()); };
    return (React.createElement("div", { className: classNames(registerClasses.root, { desktop: isDesktop }) },
        React.createElement("div", { className: classNames(registerClasses.title, { desktop: isDesktop }) }, t('email.header')),
        React.createElement("form", { className: registerClasses.emailForm },
            React.createElement("div", { className: classNames(registerClasses.formContainer, {
                    desktop: isDesktop,
                }) },
                React.createElement(TextField, { name: "email", title: t('email.email'), placeholder: t('email.enter_email'), validators: [required, emailValidation], required: true }),
                React.createElement(TextField, { name: "repeatedEmail", title: t('email.confirm'), placeholder: t('email.enter_confirm'), disablePaste: true, validators: [required], required: true }))),
        React.createElement(Grid, { container: true, style: { marginTop: 'auto' }, justify: "space-between", spacing: 2 },
            React.createElement(Grid, { key: "cancel", item: true },
                React.createElement(Button, { size: "large", variant: "outlined", color: "secondary", className: registerClasses.button, onClick: isDesktop ? backClick : goToThePrevious }, t('email.back'))),
            React.createElement(Grid, { key: "next", item: true },
                React.createElement(Button, { size: "large", variant: "contained", color: "primary", className: registerClasses.button, onClick: handleSubmit(sendLink) }, t('email.send_link'))))));
};
export var RegisterEmail = reduxForm({
    form: 'register-email',
    touchOnChange: true,
    asyncValidate: asyncValidation,
    asyncBlurFields: ['email'],
    validate: validate,
})(RegisterEmailScreen);
