// TODO fix max lines issue
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable max-lines */
import makeStyles from '@material-ui/core/styles/makeStyles';
export var useStyles = makeStyles(function (theme) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    return ({
        backdrop: {
            zIndex: 1,
            color: '#fff',
            position: 'absolute',
        },
        paper: {
            zIndex: 9999,
            display: 'flex',
            position: 'absolute',
            background: '#151515',
            width: '100%',
            // overflowY: 'scroll',
            top: '50px',
        },
        rounded: {
            borderRadius: '0',
        },
        listItem: {
            position: 'relative',
            backgroundColor: '#FFFFFF',
            borderRadius: '4px',
            justifyContent: 'space-between',
            cursor: 'pointer',
            '&:not(:last-child)': {
                marginBottom: '8px',
            },
        },
        hide: {
            opacity: 0,
        },
        emptyState: {
            height: '490px',
            width: '100%',
            display: 'grid',
            gridTemplateRows: 'repeat(3, max-content)',
            gridGap: '16px',
            justifyItems: 'center',
            alignContent: 'center',
            fontWeight: 'normal',
            fontSize: '16px',
            lineHeight: '18px',
            color: '#ABABAB',
            textAlign: 'center',
            padding: '0 20px',
            boxSizing: 'border-box',
            '&.mobile': {
                height: '330px',
                fontSize: '14px',
            },
        },
        emptyStateIcon: {
            marginBottom: '60px',
            '&.mobile': {
                marginBottom: '40px',
            },
        },
        emptyStateTitle: {
            fontWeight: 'bold',
            fontSize: '24px',
            lineHeight: '18px',
            color: '#D0D0D0',
            '&.mobile': {
                fontSize: '20px',
            },
        },
        wager: (_a = {
                marginRight: '16px'
            },
            _a[theme.breakpoints.up('lg')] = {
                fontSize: '14px',
            },
            _a),
        container: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '8px 16px 8px 8px',
        },
        bpLogo: {
            width: 341,
            marginRight: 16,
            boxSizing: 'border-box',
        },
        banners: {
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
            padding: '15px 0',
        },
        showMoreButton: {
            marginTop: 8,
            border: '1px solid #8e8e8e',
            color: '#FFFFFF',
        },
        showMoreButtonIcon: (_b = {
                display: 'inline-block',
                marginRight: 8,
                fontSize: 14,
                '& .far': {
                    fontWeight: 400,
                }
            },
            _b[theme.breakpoints.up('xl')] = {
                marginRight: 16,
            },
            _b),
        paymentIcon: (_c = {
                position: 'relative',
                boxSizing: 'border-box',
                borderRadius: '4px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '4px',
                flexDirection: 'column',
                margin: 'auto',
                padding: '3px 2px',
                minWidth: 27
            },
            _c[theme.breakpoints.up('xl')] = {
                padding: '6px 3px',
                fontSize: 6,
                minWidth: 40,
                '& + &': {
                    marginLeft: 16,
                },
            },
            _c),
        icon: (_d = {
                fontSize: '10px'
            },
            _d[theme.breakpoints.up('xl')] = {
                fontSize: 14,
            },
            _d),
        shield: {
            fontSize: '8px',
            position: 'absolute',
            top: 0,
            right: 0,
            transform: 'translate(50%, -50%)',
            display: 'flex',
            justifyContent: 'center',
            width: '12px',
            height: '12px',
            background: '#F2F2F2',
            borderRadius: '50%',
        },
        button: (_e = {
                outline: 'none',
                backgroundColor: 'transparent',
                borderRadius: '4px',
                boxSizing: 'border-box',
                border: '1px solid #d4d1d1',
                fontWeight: 500,
                fontFamily: 'Roboto, sans-serif',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'row',
                fontSize: '10px',
                padding: '10px 12px',
                cursor: 'pointer'
            },
            _e[theme.breakpoints.up('xl')] = {
                padding: 22,
            },
            _e),
        notAllowed: {
            cursor: 'not-allowed',
        },
        walletWrapper: (_f = {
                backgroundColor: '#F2F2F2',
                borderRadius: '0px 4px 4px 0px',
                padding: '6px 8px',
                '& > :first-child': {
                    marginBottom: '4px',
                }
            },
            _f[theme.breakpoints.up('xl')] = {
                boxSizing: 'border-box',
                minWidth: 128,
                margin: 0,
                padding: '0 16px',
                borderRadius: 4,
                '& > :first-child': {
                    marginBottom: 'auto',
                },
            },
            _f),
        number: (_g = {
                fontSize: '14px',
                marginRight: '4px'
            },
            _g[theme.breakpoints.up('xl')] = {
                fontSize: 16,
                marginRight: 8,
            },
            _g),
        emptyNumber: {
            height: '17px',
            width: '8px',
        },
        money: (_h = {
                margin: '0 0 7px 0',
                '& small': {
                    fontSize: '12px',
                }
            },
            _h[theme.breakpoints.up('xl')] = {
                margin: 0,
                fontSize: 18,
            },
            _h),
        coeficient: (_j = {
                backgroundColor: '#2AA1F7',
                color: '#fff',
                padding: '3px 8px',
                borderRadius: '4px'
            },
            _j[theme.breakpoints.up('xl')] = {
                padding: '11px 16px',
                marginRight: 20,
            },
            _j),
        header: {
            background: 'radial-gradient(100.54% 103.03% at 50.13% 0%, #3C3C46 0%, #1B1B22 100%)',
            color: '#fff',
            padding: '8px 30px',
        },
        subheader: {
            backgroundColor: '#151515',
            padding: '16px',
            position: 'sticky',
            top: 0,
            zIndex: 2,
        },
        subheaderDesktop: {
            padding: '8px 16px',
        },
        desktopButton: {
            width: '144px !important',
        },
        subheader_center: {
            padding: '8px 16px',
        },
        footer: {
            backgroundColor: '#34343E',
            color: '#FFF',
            padding: '16px',
            borderRadius: '0px 0px 4px 4px',
        },
        footerLabel: {
            fontSize: '14px',
        },
        menuButton: {
            border: '1px solid #444544',
            backgroundColor: 'transparent',
            outline: 'none',
            color: '#fff',
            padding: '6px',
            borderRadius: '4px',
            fontSize: '12px',
            lineHeight: '18px',
            width: '100px',
            height: 30,
            '& i': {
                marginRight: '8px',
            },
            '& div': {
                fontWeight: 400,
            },
            '& span': {
                fontWeight: 400,
            },
        },
        menuButton_big: {
            width: 267,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '7px 16px',
            fontSize: 14,
            lineHeight: '18px',
            '& div': {
                fontSize: 14,
                lineHeight: '18px',
            },
        },
        betstop: {
            color: '#FF0000',
        },
        trendIconHigher: {
            color: '#00C150',
        },
        trendIconLower: {
            color: '#FF0000',
        },
        trendIconEqual: {
            color: '#8E8E8E',
        },
        maxPayout: {
            '& i': {
                marginLeft: '8px',
                marginRight: 0,
            },
        },
        popover: {
            marginTop: '10px',
            color: '#fff',
            position: 'relative',
            background: 'radial-gradient(102.51% 128.71% at 20.97% 0%, #303038 0%, #1B1B22 100%)',
            '&::before': {
                content: '""',
                position: 'absolute',
                right: '19px',
                top: '-16px',
                borderBottom: '8px solid #212128',
                border: '8px solid transparent',
            },
        },
        checkedBlock: {
            width: 20,
        },
        menuItem: (_k = {
                height: '30px',
                minHeight: 'initial',
                paddingLeft: '8px',
                paddingRight: '20px',
                '&:not(:last-child)': {
                    marginBottom: '10px',
                }
            },
            _k[theme.breakpoints.up('lg')] = {
                minWidth: 267,
            },
            _k),
        itemText: (_l = {
                fontSize: '12px'
            },
            _l[theme.breakpoints.up('lg')] = {
                fontSize: 16,
                lineHeight: '18px',
            },
            _l),
        activeButton: {
            background: 'rgb(42, 161, 247, 0.1)',
            color: '#2aa1f7',
        },
        checkedIcon: {
            color: '#2aa1f7',
            fontSize: '14px',
        },
        blurredContainer: {
            position: 'relative',
        },
        capitalize: {
            textTransform: 'capitalize',
        },
        listWrapper: {
            padding: '8px 16px 24px',
        },
        removeOddsWrapper: (_m = {
                padding: '16px',
                backgroundColor: '#23232B',
                '& .MuiButtonBase-root': {
                    background: 'white',
                }
            },
            _m[theme.breakpoints.up('lg')] = {
                paddingBottom: 0,
            },
            _m),
    });
}, { name: 'TopRanking' });
