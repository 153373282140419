import React from 'react';
import { useStyles } from './ProviderLogo.styles';
var BASE_URL = process.env.REACT_APP_BASE_URL || '';
export var SmallProviderLogo = function (_a) {
    var id = _a.id, backgroundColor = _a.backgroundColor;
    var classes = useStyles();
    if (!id)
        return null;
    return (React.createElement("div", { className: classes.smallLogoWrapper, style: { backgroundColor: backgroundColor } },
        React.createElement("img", { alt: "provider logo", src: "".concat(BASE_URL, "bp/v1/bp-integrator/providers/logo/").concat(id, "/MAIN") })));
};
