var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from '@reduxjs/toolkit';
import { EVENTS_SOCKETS_NAMES } from '~slices/events';
import { RequestStatus } from '~constants/api/RequestStatus';
import { name } from './constants';
var getDefaultSocketState = function () { return ({
    enabled: false,
    requestStatus: RequestStatus.IDLE,
}); };
var initialState = EVENTS_SOCKETS_NAMES.reduce(function (acc, socketName) {
    acc[socketName] = getDefaultSocketState();
    return acc;
}, {});
var sockets = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        changeSocketState: function (state, _a) {
            var payload = _a.payload;
            var socket = payload.socket, newSocketState = payload.state;
            state[socket] = __assign(__assign({}, state[socket]), newSocketState);
        },
    },
});
export { sockets };
export var changeSocketState = sockets.actions.changeSocketState;
