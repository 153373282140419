import React from 'react';
import classNames from 'classnames';
import { BetStopIcon, FavoritesIcon } from '~atoms';
import { LOGIN_PRESS } from '~constants/cpa-message-codes';
import { FINISHED_STATUSES } from '~utils/events';
import { usePlatofrmType, useStructuredSelector } from '~hooks';
import { isAuthenticatedSelector, isCookieAcceptedSelector, } from '~slices/auth';
import { useAppDispatch } from '~store';
import { MODALS, closeModal, openModal } from '~modals';
import { changeHintState } from '~slices/hints';
import { PLATFORMS } from '~slices/teasers';
import { addToFavourite, removeFromFavourite } from '~slices/events';
import { useStyles } from './EventStatus.styles';
var EventStatus = function (_a) {
    var _b, _c, _d;
    var event = _a.event, _e = _a.favoritesTab, favoritesTab = _e === void 0 ? false : _e, _f = _a.keepSpace, keepSpace = _f === void 0 ? false : _f;
    var classes = useStyles();
    var eventId = event.bid;
    var dispatch = useAppDispatch();
    var _g = useStructuredSelector({
        isCookieAccepted: isCookieAcceptedSelector,
        isAuthenticated: isAuthenticatedSelector,
    }), isCookieAccepted = _g.isCookieAccepted, isAuthenticated = _g.isAuthenticated;
    var platform = usePlatofrmType();
    var isFavorite = event === null || event === void 0 ? void 0 : event.fav;
    var isTicketBought = event === null || event === void 0 ? void 0 : event.bet;
    var isFinishedEvent = FINISHED_STATUSES.includes((event === null || event === void 0 ? void 0 : event.ms) || '');
    var shouldDisplayFavoritesIcon = (!isFinishedEvent || (isFinishedEvent && favoritesTab)) &&
        (!((event === null || event === void 0 ? void 0 : event.bs) && !isFinishedEvent) ||
            ((event === null || event === void 0 ? void 0 : event.bs) && !Number((_b = event === null || event === void 0 ? void 0 : event.bsr) === null || _b === void 0 ? void 0 : _b.bsi)));
    var shouldDisplayBetStopIcon = (event === null || event === void 0 ? void 0 : event.bs) && (((_c = event === null || event === void 0 ? void 0 : event.bsr) === null || _c === void 0 ? void 0 : _c.br) === 'UNDEFINED' || !!Number((_d = event === null || event === void 0 ? void 0 : event.bsr) === null || _d === void 0 ? void 0 : _d.bsi));
    var showLoginPopup = function () {
        if (isCookieAccepted) {
            dispatch(openModal({
                modalName: MODALS.LOGIN_MODAL,
                modalProps: {
                    isOpen: true,
                    onClose: function () { return dispatch(closeModal({ key: MODALS.LOGIN_MODAL })); },
                },
            }));
        }
        else if (platform === PLATFORMS.CROSS_PLATFORM_MOBILE) {
            // @ts-expect-error
            window.ReactNativeWebView.postMessage(JSON.stringify({ code: LOGIN_PRESS }));
        }
        else {
            dispatch(changeHintState({ isCookiesConsentHintEnabled: true }));
        }
    };
    var handleFavoritesClick = function (e) {
        e.stopPropagation();
        if (isAuthenticated) {
            var toggleFunc = isFavorite ? removeFromFavourite : addToFavourite;
            if (eventId) {
                dispatch(toggleFunc({ eventId: eventId }));
            }
            return;
        }
        showLoginPopup();
    };
    if (shouldDisplayBetStopIcon) {
        return (React.createElement("div", { className: classes.container }, shouldDisplayBetStopIcon && React.createElement(BetStopIcon, null)));
    }
    if (shouldDisplayFavoritesIcon) {
        return (React.createElement("div", { role: "button", tabIndex: 0, className: classNames(classes.container, classes.clickable), onClick: handleFavoritesClick }, shouldDisplayFavoritesIcon && (React.createElement(FavoritesIcon, { icon: isTicketBought ? 'TICKET' : 'STAR', active: isFavorite }))));
    }
    if (keepSpace) {
        return React.createElement("div", { className: classes.container });
    }
    return null;
};
export { EventStatus };
