import { useEffect } from 'react';
import { windowResizeObserver } from '~utils/WindowResizeObserver';
var useWindowResize = function (callback) {
    useEffect(function () {
        windowResizeObserver.subscribe(callback);
        return function () {
            windowResizeObserver.unsubscribe(callback);
        };
    }, [callback]);
};
export { useWindowResize };
