import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var PoolIcon = function () {
    return (React.createElement(SvgIcon, { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("svg", { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M12.9096 3.88305C13.6522 3.87421 14.3329 4.28971 14.6777 4.94389L23.686 21.6697C24.2164 22.651 23.8451 23.8709 22.8638 24.4013C22.5721 24.5605 22.245 24.64 21.9179 24.64H3.90141C2.78753 24.64 1.88582 23.7295 1.89466 22.6156C1.89466 22.2885 1.97422 21.9614 2.13335 21.6697L11.1416 4.94389C11.4864 4.28971 12.1671 3.87421 12.9096 3.88305ZM12.9096 2.00007C11.4775 1.99123 10.1515 2.77802 9.47962 4.05102L0.471376 20.7768C-0.925388 23.3759 0.957592 26.523 3.90141 26.523H21.9179C24.8705 26.523 26.7447 23.3759 25.3479 20.7768L16.3397 4.05102C15.6678 2.77802 14.3418 1.99123 12.9096 2.00007Z", fill: "#E7EDFF" }),
            React.createElement("path", { d: "M11.1416 4.94389L2.13334 21.6697C1.60292 22.651 1.97421 23.8709 2.95549 24.4013C3.24721 24.5605 3.57431 24.64 3.9014 24.64H12.9096V26.523H3.9014C0.948743 26.523 -0.925397 23.3759 0.471368 20.7768L9.47961 4.05102C10.1515 2.77802 11.4775 1.99123 12.9096 2.00007V3.88305C12.1671 3.87421 11.4864 4.28971 11.1416 4.94389Z", fill: "white" }),
            React.createElement("path", { d: "M12.9097 13.3245C14.9945 13.3245 16.6845 11.6344 16.6845 9.54967C16.6845 7.46491 14.9945 5.77487 12.9097 5.77487C10.8249 5.77487 9.13489 7.46491 9.13489 9.54967C9.13489 11.6344 10.8249 13.3245 12.9097 13.3245Z", fill: "#F51E00" }),
            React.createElement("path", { d: "M16.6845 9.54967C16.6845 7.46336 14.996 5.77487 12.9097 5.77487V13.3245C14.996 13.3245 16.6845 11.636 16.6845 9.54967Z", fill: "#AF0A00" }),
            React.createElement("path", { d: "M16.5164 10.6547C16.2247 9.99168 15.5617 9.53198 14.7926 9.53198C13.7494 9.53198 12.9008 10.3806 12.9008 11.4238C12.9008 12.2017 13.3693 12.8648 14.0323 13.1565C15.2258 12.7852 16.154 11.8481 16.5164 10.6547Z", fill: "#FFF4F4" }),
            React.createElement("path", { d: "M9.13478 22.7571C11.2195 22.7571 12.9096 21.067 12.9096 18.9823C12.9096 16.8975 11.2195 15.2075 9.13478 15.2075C7.05002 15.2075 5.35999 16.8975 5.35999 18.9823C5.35999 21.067 7.05002 22.7571 9.13478 22.7571Z", fill: "#3162FF" }),
            React.createElement("path", { d: "M5.82852 17.1523C5.52795 17.6916 5.35999 18.3192 5.35999 18.9823C5.35999 19.6453 5.52795 20.2641 5.82852 20.8122C6.64183 20.6 7.24297 19.8663 7.24297 18.9823C7.24297 18.0982 6.64183 17.3645 5.82852 17.1523Z", fill: "white" }),
            React.createElement("path", { d: "M18.5674 24.64C20.6522 24.64 22.3422 22.95 22.3422 20.8652C22.3422 18.7804 20.6522 17.0904 18.5674 17.0904C16.4826 17.0904 14.7926 18.7804 14.7926 20.8652C14.7926 22.95 16.4826 24.64 18.5674 24.64Z", fill: "#F19D00" }),
            React.createElement("path", { d: "M24.2252 13.3245C26.31 13.3245 28.0001 11.6344 28.0001 9.54967C28.0001 7.46491 26.31 5.77487 24.2252 5.77487C22.1405 5.77487 20.4504 7.46491 20.4504 9.54967C20.4504 11.6344 22.1405 13.3245 24.2252 13.3245Z", fill: "#292E51" }),
            React.createElement("path", { d: "M22.8903 10.8845C22.1565 10.1508 22.1565 8.94853 22.8903 8.21478C23.624 7.48104 24.8263 7.48104 25.56 8.21478L22.8903 10.8845Z", fill: "#E7EDFF" }),
            React.createElement("path", { d: "M12.9097 18.9823C12.9097 16.896 11.2212 15.2075 9.13489 15.2075V22.7482C11.2124 22.7482 12.9009 21.0597 12.9097 18.9823Z", fill: "#0437D6" }),
            React.createElement("path", { d: "M20.3973 17.5589C19.858 17.2584 19.2304 17.0904 18.5674 17.0904C17.9043 17.0904 17.2855 17.2584 16.7374 17.5589C16.9496 18.3723 17.6833 18.9734 18.5674 18.9734C19.4514 18.9734 20.1851 18.3723 20.3973 17.5589Z", fill: "white" }),
            React.createElement("path", { d: "M20.3973 17.5589C19.8581 17.2584 19.2304 17.0904 18.5674 17.0904V18.9822C19.4514 18.9822 20.1852 18.3723 20.3973 17.5589Z", fill: "#FFFFAE" }))));
};
