var createBaseSelectors = function (key) {
    var _a;
    var capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
    var selectState = function (state) {
        return state[key];
    };
    return _a = {},
        _a["select".concat(capitalizedKey, "State")] = selectState,
        _a["select".concat(capitalizedKey, "Data")] = function (state) {
            return selectState(state).data;
        },
        _a["select".concat(capitalizedKey, "LoadingStatus")] = function (state) {
            return selectState(state).loading;
        },
        _a["select".concat(capitalizedKey, "Error")] = function (state) {
            return selectState(state).error;
        },
        _a;
};
export { createBaseSelectors };
