import { createAction } from '@reduxjs/toolkit';
import groupBy from 'lodash.groupby';
import { UPDATE_TYPE_MATCH, UPDATE_TYPE_ODD } from '../events/constants';
import { name } from './constants';
export var updateEvent = createAction("".concat(name, "/updateEvent"), function (data) {
    var _a;
    var _b = groupBy(data, 'ut'), _c = UPDATE_TYPE_MATCH, match = _b[_c], _d = UPDATE_TYPE_ODD, odd = _b[_d];
    return {
        payload: (_a = {},
            _a[UPDATE_TYPE_MATCH] = match || [],
            _a[UPDATE_TYPE_ODD] = odd || [],
            _a),
    };
});
export var connectedSidebets = createAction("".concat(name, "/connectedSidebets"));
export var socketMessage = createAction("".concat(name, "/socketMessage"));
