import { createSelector } from '@reduxjs/toolkit';
import { SOON_LIVE_SOCKET_NAME } from '~slices/events';
import { RequestStatus } from '~constants/api/RequestStatus';
import { name } from './constants';
var selectSocketsState = function (state) { return state[name]; };
var selectSocketsEnabledStatus = createSelector(selectSocketsState, function (sockets) {
    return Object.entries(sockets).reduce(function (acc, _a) {
        var socketName = _a[0], socketState = _a[1];
        acc[socketName] = socketState.enabled;
        return acc;
    }, {});
});
export var selectSocketEnabledStatus = createSelector(selectSocketsEnabledStatus, function (state, socketName) { return socketName; }, function (socketsStatus, socketName) { return socketsStatus[socketName]; });
var selectSocketsRequestStatus = createSelector(selectSocketsState, function (sockets) {
    return Object.entries(sockets).reduce(function (acc, _a) {
        var socketName = _a[0], socketState = _a[1];
        acc[socketName] = socketState.requestStatus;
        return acc;
    }, {});
});
export var selectSocketRequestStatus = createSelector(selectSocketsRequestStatus, function (state, socketName) { return socketName; }, function (sockets, socketName) { return sockets[socketName]; });
export var selectIsSoonLiveLoading = createSelector(function (state) {
    return selectSocketsRequestStatus(state)[SOON_LIVE_SOCKET_NAME];
}, function (soonLiveRequestStatus) { return soonLiveRequestStatus === RequestStatus.PENDING; });
export { selectSocketsEnabledStatus, selectSocketsRequestStatus };
