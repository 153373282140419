import makeStyles from '@material-ui/core/styles/makeStyles';
export var usePasswordRestoreFormStyles = makeStyles(function (theme) {
    var _a, _b;
    return ({
        root: (_a = {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                flex: 1,
                padding: '32px 16px',
                boxShadow: '0 0 20px rgba(0, 0, 0, 0.05)',
                borderRadius: 4,
                color: '#fff'
            },
            _a[theme.breakpoints.up('lg')] = {
                height: '370px',
                boxShadow: 'none',
            },
            _a),
        title: (_b = {
                fontFamily: 'Roboto, sans-serif',
                fontStyle: 'normal',
                fontWeight: 300,
                fontSize: 20,
                marginTop: 0,
                marginBottom: 24,
                lineHeight: '23px',
                letterSpacing: '0.005em'
            },
            _b[theme.breakpoints.up('lg')] = {
                color: '#212121',
                fontWeight: 500,
                paddingBottom: '8px',
            },
            _b),
        labelText: {
            marginLeft: 4,
        },
        main: {
            flex: 1,
            marginBottom: 32,
            width: '100%',
        },
        button: {
            width: '120px',
            '&:disabled': {
                backgroundColor: '#8e8e8e',
            },
        },
        formError: {
            marginTop: '-5px',
            marginLeft: '8px',
        },
    });
}, { name: 'PasswordRestoreForm' });
