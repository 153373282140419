var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import classNames from 'classnames';
import { AnimationDuration } from '~constants/view/AnimationDuration';
import { ZIndex } from '~constants/view/ZIndex';
import { noop } from '~utils/noop';
import { useStyles } from './EventsDropDown.styles';
var EventsDropDown = function (_a) {
    var _b;
    var _c = _a.open, open = _c === void 0 ? false : _c, _d = _a.zIndex, zIndex = _d === void 0 ? ZIndex.EVENTS_DROP_DOWN : _d, _e = _a.alignment, alignment = _e === void 0 ? 'right' : _e, anchorEl = _a.anchorEl, children = _a.children, _f = _a.onClose, onClose = _f === void 0 ? noop : _f;
    var classes = useStyles();
    return (React.createElement("div", { className: classes.container },
        React.createElement(Popper, { open: open, style: {
                zIndex: zIndex,
            }, anchorEl: anchorEl, transition: true, disablePortal: true, placement: "bottom-".concat(alignment === 'right' ? 'end' : 'start'), className: classNames(classes.popper, (_b = {},
                _b[classes.left] = alignment === 'left',
                _b)) }, function (_a) {
            var TransitionProps = _a.TransitionProps;
            return (React.createElement(Grow, __assign({}, TransitionProps, { timeout: AnimationDuration.SHORT.ms, style: {
                    transformOrigin: "".concat(alignment, " top"),
                } }),
                React.createElement("div", null,
                    React.createElement(ClickAwayListener, { onClickAway: onClose },
                        React.createElement(Paper, { className: classes.content }, children)))));
        })));
};
export { EventsDropDown };
