import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { closeModal } from '~modals';
import { resetErrors } from '~slices/account';
import { RegisterEmail } from '~pages/registration/components/RegisterEmail';
import success from '~icons/statusIcons/success.svg';
import { StatusScreen } from '~molecules';
import { EditModalContainer } from './EditModalContainer';
var useStyles = makeStyles({
    header: {
        color: '#212121',
        fontSize: '28px',
    },
    subHeader: {
        fontSize: '16px',
        color: '#212121',
        letterSpacing: '0.15px',
    },
    container: {
        padding: '32px 16px',
    },
}, { name: 'ResendEmail' });
export var ResendEmail = function (_a) {
    var modalName = _a.modalName;
    var dispatch = useDispatch();
    var t = useTranslation('registration').t;
    var classes = useStyles();
    var _b = useState(''), userEmail = _b[0], setUserEmail = _b[1];
    var _c = useState(false), step = _c[0], setStep = _c[1];
    var handleCancel = function () {
        dispatch(resetErrors());
        dispatch(closeModal({ key: modalName }));
    };
    var subheader = t('resend.link_was_sent', {
        email: userEmail,
    });
    return (React.createElement(EditModalContainer, { handleCancel: handleCancel },
        React.createElement("div", { className: classes.container }, step ? (React.createElement(StatusScreen, { isMobile: true, headerClassName: classes.header, subheaderClassName: classes.subHeader, header: t('resend.created'), subheader: subheader, logo: success, onClick: function () { return setStep(false); }, buttonLabel: t('resend.button') })) : (React.createElement(RegisterEmail, { isDesktop: true, backClick: handleCancel, setEmail: setUserEmail, setStep: function () { return setStep(true); } })))));
};
