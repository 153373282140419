import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var BasketBall3x3 = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, viewBox: "0 0 28 28" },
        React.createElement("svg", { width: "28", height: "28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M13.94 27.94a13.94 13.94 0 1 0 0-27.9 13.94 13.94 0 0 0 0 27.9Z", fill: "#FF7043" }),
            React.createElement("path", { d: "M22.91 3.33A13.88 13.88 0 1 0 5.33 24.8 13.88 13.88 0 0 0 22.9 3.33Zm2.8 5.81a16.55 16.55 0 0 0-4.94-3.08c.15-.79.7-1.44 1.46-1.71 1.52 1.3 2.7 2.94 3.48 4.8Zm-6.09-3.48c-.87-.29-1.76-.5-2.66-.65l.01-3.37c1.5.37 2.93 1 4.2 1.88a3.54 3.54 0 0 0-1.55 2.14ZM15.76 1.4l-.01 3.48c-.84-.08-1.68-.1-2.52-.08a4.28 4.28 0 0 0-1.4-2.63c-.26-.23-.56-.41-.88-.55a12.68 12.68 0 0 1 4.81-.22ZM8.57 2.46h.02c.87-.1 1.75.12 2.46.64.5.47.84 1.1.94 1.78a19.49 19.49 0 0 0-9.31 3.2 12.8 12.8 0 0 1 5.9-5.62Zm-6.93 8.3a.57.57 0 0 0 .08-.08c1.22-1.9 5.48-4.13 10.4-4.6v.03c0 2.58-3.14 6.18-5.67 9.08a25.44 25.44 0 0 0-3.7 4.85 12.57 12.57 0 0 1-1.1-9.28ZM3.61 21.4a.58.58 0 0 0 .03-.13c0-1.02 1.9-3.19 3.72-5.29 2.8-3.2 5.98-6.84 5.98-9.87l-.01-.1c.8-.03 1.6-.01 2.4.06-.13 12.33-.97 14.86-4.13 20.35a.57.57 0 0 0-.03.07 12.74 12.74 0 0 1-7.96-5.09Zm9.24 5.27c3.24-5.7 3.97-8.77 4.1-20.45.84.14 1.67.34 2.47.6l-.02.5c.17 2.5.84 4.95 1.95 7.2 1.3 3.1 2.51 6.02 1.16 8.86a12.67 12.67 0 0 1-9.66 3.29Zm11.44-5.28c.2-2.48-.81-4.94-1.82-7.34a18.17 18.17 0 0 1-1.86-6.73v-.05c2.27.96 4.26 2.45 5.83 4.35.67 3.41-.11 6.95-2.15 9.77Z", fill: "#000" }),
            React.createElement("path", { d: "M13.94 27.94a13.94 13.94 0 1 1 0-27.9 13.94 13.94 0 0 1 0 27.9Zm0-26.68a12.73 12.73 0 1 0 0 25.46 12.73 12.73 0 0 0 0-25.46Z", fill: "#000" }),
            React.createElement("path", { d: "M27.02 11.96a.6.6 0 0 1-.47-.22 15.98 15.98 0 0 0-13.78-5.71C7.57 6.35 3 8.69 1.73 10.68a.6.6 0 1 1-1.03-.65c1.44-2.26 6.14-4.85 12-5.22 5.64-.5 11.16 1.8 14.79 6.15a.6.6 0 0 1-.47 1Z", fill: "#000" }),
            React.createElement("path", { d: "M3.03 21.87a.6.6 0 0 1-.6-.6c0-1.48 1.77-3.51 4.02-6.08 2.53-2.9 5.67-6.5 5.67-9.08a3.76 3.76 0 0 0-1.07-3 3.43 3.43 0 0 0-2.46-.65.6.6 0 1 1-.2-1.2 4.6 4.6 0 0 1 3.45.92 4.9 4.9 0 0 1 1.5 3.93c0 3.03-3.18 6.67-5.98 9.87-1.83 2.1-3.72 4.27-3.72 5.29a.6.6 0 0 1-.61.6Z", fill: "#000" }),
            React.createElement("path", { fill: "#FF7043", d: "M7 11h4v5H7z" }),
            React.createElement("path", { d: "M12.12 27.33a.6.6 0 0 1-.52-.9c3.53-6.16 4.16-8.58 4.16-25.17a.6.6 0 1 1 1.21 0c0 16.2-.48 19.09-4.32 25.77a.6.6 0 0 1-.53.3ZM23.04 24.3a.6.6 0 0 1-.55-.87c1.4-2.86.16-5.8-1.13-8.91a19.06 19.06 0 0 1-1.96-7.2c0-3.94 2.95-4.24 2.98-4.24.33-.02.62.22.66.55a.6.6 0 0 1-.56.66c-.18.02-1.87.28-1.87 3.03.18 2.34.82 4.63 1.86 6.73 1.35 3.22 2.74 6.55 1.11 9.9a.6.6 0 0 1-.54.35Z", fill: "#000" }),
            React.createElement("path", { fill: "#FF7043", d: "M16 10h6v6h-6z" }),
            React.createElement("path", { d: "M8.76 11.5h.2l.04-.2.52-2.22.07-.3H6.25l-.07.04-1.8 1.38-.07.05-.02.09-.51 2.14-.03.12.08.1.8.94-1.27 1-.07.06-.02.08-.51 2.23-.03.11.08.1 1.13 1.39.08.09h3.37l.04-.2.53-2.2.07-.3H6.92l.2-.89h1.46l.05-.2.52-2.32.07-.3H7.76l.2-.8h.8ZM19.04 15.95h-.2l-.04.2-.52 2.22-.07.3h3.48l.07-.05 1.8-1.37.07-.06.02-.08.52-2.26.02-.12-.08-.1-.8-.93 1.27-.99.07-.05.02-.09.51-2.13.03-.12-.08-.1L24 8.85l-.07-.09H20.55l-.04.2-.53 2.19-.07.3h1.11l-.2.8h-1.46l-.05.2-.52 2.32-.07.3h1.46l-.2.9h-.94Z", fill: "#000", stroke: "#FF7043" }),
            React.createElement("path", { d: "m9.22 11.18-.08.3h1.05l4.59 7.13.07.11H18.02l.04-.2.54-2.22.07-.31h-1.06l-4.59-7.1-.07-.12H9.77l-.04.2-.51 2.2Z", fill: "#000", stroke: "#FF7043" }),
            React.createElement("path", { d: "M17.19 8.8h-.1l-.07.06-2.27 2.03-.16.14.12.18 1.42 2.19.16.24.22-.2 2.23-1.97h.98l.05-.2.51-2.17.08-.3h-3.17ZM7.64 18.37l-.08.3h3.14l.07-.06 2.26-2.01.15-.15-.1-.17-1.42-2.23-.16-.25-.22.2L9.1 16H8.2l-.04.19-.52 2.19Z", fill: "#000", stroke: "#FF7043" }))));
};
