import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useStructuredSelector } from '~hooks';
import { isOutrightsTabDisabledSelector } from '~slices/outrights';
import { isTournamentDropDownAvailableByCountSelector } from '~slices/tournamentsList';
import { Header, TournamentsDropDownTab } from './Header';
import { Tournaments } from './Tournaments';
import { Outrights } from './Outrights';
import { useStyles } from './Content.styles';
import { scrollParentAtom } from './scrollParentAtom';
var Content = function (_a) {
    var onSelect = _a.onSelect;
    var classes = useStyles();
    var location = useLocation();
    var _b = useState(location.pathname.includes('outrights')
        ? TournamentsDropDownTab.OUTRIGHTS
        : TournamentsDropDownTab.TOURNAMENTS), activeTab = _b[0], setActiveTab = _b[1];
    var _c = useStructuredSelector({
        isOutrightsTabDisabled: isOutrightsTabDisabledSelector,
        isTournamentDropDownAvailableByCount: isTournamentDropDownAvailableByCountSelector,
    }), isOutrightsTabDisabled = _c.isOutrightsTabDisabled, isTournamentDropDownAvailableByCount = _c.isTournamentDropDownAvailableByCount;
    var stateRef = useRef({
        activeTab: activeTab,
        isOutrightsTabDisabled: isOutrightsTabDisabled,
        isTournamentDropDownAvailableByCount: isTournamentDropDownAvailableByCount,
    });
    stateRef.current = {
        activeTab: activeTab,
        isOutrightsTabDisabled: isOutrightsTabDisabled,
        isTournamentDropDownAvailableByCount: isTournamentDropDownAvailableByCount,
    };
    useEffect(function () {
        if (!isTournamentDropDownAvailableByCount &&
            stateRef.current.activeTab === TournamentsDropDownTab.TOURNAMENTS &&
            !stateRef.current.isOutrightsTabDisabled) {
            setActiveTab(TournamentsDropDownTab.OUTRIGHTS);
        }
    }, [isTournamentDropDownAvailableByCount]);
    useEffect(function () {
        if (isOutrightsTabDisabled &&
            stateRef.current.activeTab === TournamentsDropDownTab.OUTRIGHTS &&
            stateRef.current.isTournamentDropDownAvailableByCount) {
            setActiveTab(TournamentsDropDownTab.TOURNAMENTS);
        }
    }, [isOutrightsTabDisabled]);
    var renderContent = function (tab) {
        switch (tab) {
            case TournamentsDropDownTab.OUTRIGHTS: {
                return React.createElement(Outrights, { onSelect: onSelect });
            }
            case TournamentsDropDownTab.TOURNAMENTS:
            default: {
                return React.createElement(Tournaments, { onSelect: onSelect });
            }
        }
    };
    var disabledTabs = useMemo(function () {
        var result = [];
        if (isOutrightsTabDisabled) {
            result.push(TournamentsDropDownTab.OUTRIGHTS);
        }
        if (!isTournamentDropDownAvailableByCount) {
            result.push(TournamentsDropDownTab.TOURNAMENTS);
        }
        return result;
    }, [isOutrightsTabDisabled, isTournamentDropDownAvailableByCount]);
    return (React.createElement("div", { className: classes.container },
        React.createElement(Header, { activeTab: activeTab, onClick: setActiveTab, disabledTabs: disabledTabs }),
        React.createElement("div", { ref: function (e) {
                scrollParentAtom.setValue(e);
            }, className: classes.scroll }, renderContent(activeTab))));
};
export { Content };
