import makeStyles from '@material-ui/core/styles/makeStyles';
export var useStyles = makeStyles({
    wrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: '63px',
        maxHeight: '145px',
        marginTop: '8px',
        borderRadius: '14px',
        background: 'linear-gradient(0deg, #FFF 0%, #1C1C23 0%, #000 15.76%, #5D5D65 100%)',
        boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.92) inset',
    },
    mainContentWrapper: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        width: '100%',
        maxWidth: '123px',
        minWidth: '105px',
        display: 'flex',
        justifyContent: 'center',
    },
    content: {
        width: '100%',
        maxWidth: '220px',
        display: 'flex',
        flexDirection: 'column',
        padding: '18px 24px 38px 21px',
        '@media (max-width:376px)': {
            padding: '3px 6px',
        },
    },
    divider: {
        width: '100%',
        height: '1px',
        backgroundColor: '#FFFFFF',
    },
    levelBlock: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '13px',
        fontSize: '25px',
        fontWeight: 700,
        textTransform: 'uppercase',
        '& span': {
            marginLeft: '5px',
        },
    },
    tipPool: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '5px 0',
        fontWeight: 700,
        color: '#FFFFFF',
        '& span:first-child': {
            fontSize: '12px',
            fontWeight: 400,
        },
        '& img': {
            marginLeft: '3px',
        },
        '& svg': {
            marginLeft: '3px',
        },
        '& div': {
            marginLeft: '3px',
        },
        '@media (max-width:340px)': {
            fontWeight: 400,
            fontSize: '12px',
        },
    },
    balance: {
        display: 'flex',
        justifyContent: 'space-between',
        minHeight: '30px',
        marginTop: '8px',
        fontSize: '12px',
        color: '#FFFFFF',
        '& span': {
            display: 'flex',
            alignItems: 'center',
            '& svg': {
                width: '11px',
                height: '11px',
                marginLeft: '5px',
            },
        },
    },
    rightBlock: {
        position: 'relative',
        height: '145px',
        width: '43px',
    },
    rightBlockBackground: {
        height: '100%',
        width: '43px',
        borderRadius: '0 14px 14px 0',
        background: 'linear-gradient(90deg, #000 0%, rgba(36, 36, 36, 0.58) 50%, rgba(84, 84, 84, 0.00) 100%)',
        opacity: '0.1225',
    },
    rightBlockWrapper: {
        position: 'absolute',
        top: 0,
        width: '43px',
        minHeight: '145px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '& img': {
            marginTop: '15px',
        },
    },
    progressWrapper: {
        height: '70.5px',
        width: '7px',
    },
    unlocked: {
        marginLeft: '4px',
    },
    title: {
        fontWeight: 700,
        color: '#FFFFFF',
        '& span': {
            marginLeft: '5px',
        },
    },
}, { name: 'LevelCardCartoon' });
