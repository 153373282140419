var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from '@reduxjs/toolkit';
import { name } from './constants';
var initialState = {
    online: window.navigator.onLine,
};
var application = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        changeOnlineStatus: function (state, _a) {
            var payload = _a.payload;
            return __assign(__assign({}, state), { online: payload });
        },
    },
});
export { application };
export var changeOnlineStatus = application.actions.changeOnlineStatus;
