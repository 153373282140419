import React from "react";
import { CSIcon } from "~icons/rankingList/CSIcon";
import makeStyles from "~utils/makeStyles";
var useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        width: '100%',
        height: 49,
        padding: '6px 8px',
        background: 'linear-gradient(90deg, #211A92 0%, #1B66B9 0.01%, #2C479A 100%)',
        borderRadius: 5,
    },
    title: {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        fontSize: 8,
        color: '#B6D7FF',
        overflow: 'hidden',
        whiteSpace: 'normal',
        textOverflow: 'ellipsis',
    },
    dataContainer: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 12,
        fontWeight: 500,
        color: '#F5F5F5',
        '& svg': {
            width: 12,
            height: 12,
            marginLeft: 5,
        },
    },
}, { name: 'InfoButton' });
export var InfoButton = function (_a) {
    var title = _a.title, data = _a.data, isIcon = _a.isIcon;
    var classes = useStyles();
    return (React.createElement("div", { className: classes.container },
        React.createElement("div", { className: classes.title }, title),
        React.createElement("div", { className: classes.dataContainer },
            React.createElement("div", null, data),
            isIcon && React.createElement(CSIcon, { size: "sm", color: "#F5F5F5" }))));
};
