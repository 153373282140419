import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { goBack } from 'connected-react-router';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { LOGIN_PRESS } from '~constants/cpa-message-codes';
import { MODALS, closeModal, openModal } from '~modals';
import { Button } from '~atoms/button';
import { useAppDispatch } from '~store';
import { useIsDesktop, usePlatofrmType, useStructuredSelector } from '~hooks';
import { isCookieAcceptedSelector } from '~slices/auth';
import { PLATFORMS } from '~slices/teasers';
import { changeHintState } from '~slices/hints';
var useUnloggedStyles = makeStyles(function (theme) {
    var _a, _b, _c;
    return ({
        container: (_a = {
                marginTop: '126px'
            },
            _a[theme.breakpoints.up('lg')] = {
                marginTop: 0,
                padding: 32,
                marginBottom: 24,
                background: '#151515',
            },
            _a),
        wrapper: (_b = {
                margin: 'auto',
                maxWidth: 343
            },
            _b[theme.breakpoints.up('lg')] = {
                maxWidth: 608,
            },
            _b),
        header: {
            fontWeight: 300,
            fontSize: '24px',
            lineHeight: '36px',
            color: '#FFFFFF',
            textAlign: 'center',
        },
        subHeader: {
            fontWeight: 'normal',
            fontSize: '12px',
            lineHeight: '18px',
            textAlign: 'center',
            color: '#FFFFFF',
            marginTop: '24px',
        },
        loginButton: (_c = {
                marginTop: '40px'
            },
            _c[theme.breakpoints.up('lg')] = {
                maxWidth: 343,
            },
            _c),
        closeButton: {
            marginTop: '129px',
            height: '32px',
            maxWidth: '93px',
            width: '100%',
        },
    });
}, { name: 'UnloggedBPList' });
export var UnloggedBPList = function () {
    var classes = useUnloggedStyles();
    var dispatch = useAppDispatch();
    var t = useTranslation('header').t;
    var isDesktop = useIsDesktop();
    var isCookieAccepted = useStructuredSelector({
        isCookieAccepted: isCookieAcceptedSelector,
    }).isCookieAccepted;
    var platform = usePlatofrmType();
    var openLoginModal = function () {
        if (isCookieAccepted) {
            dispatch(openModal({
                modalName: MODALS.LOGIN_MODAL,
                modalProps: {
                    isOpen: true,
                    onClose: function () { return dispatch(closeModal({ key: MODALS.LOGIN_MODAL })); },
                },
            }));
        }
        else if (platform === PLATFORMS.CROSS_PLATFORM_MOBILE) {
            // @ts-expect-error
            window.ReactNativeWebView.postMessage(JSON.stringify({
                code: LOGIN_PRESS,
                target: '/bp-list',
            }));
        }
        else {
            dispatch(changeHintState({ isCookiesConsentHintEnabled: true }));
        }
    };
    var handleGoBack = function () {
        dispatch(goBack());
    };
    return (React.createElement("div", { className: classes.container },
        React.createElement("div", { className: classNames('flex-column flex-center', classes.wrapper) },
            React.createElement("div", { className: classes.header }, t('unlogged_bp_text_header')),
            React.createElement("div", { className: classes.subHeader }, t('unlogged_bp_text')),
            React.createElement(Button, { className: classes.loginButton, color: "primary", variant: "contained", onClick: openLoginModal, fullWidth: true }, t('login_beton')),
            !isDesktop && (React.createElement(Button, { className: classes.closeButton, variant: "contained", onClick: handleGoBack }, t('close'))))));
};
