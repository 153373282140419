var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Backdrop from '@material-ui/core/Backdrop/Backdrop';
import classNames from 'classnames';
import { useIsDesktop, useStructuredSelector } from '~hooks';
import { templateInfoSelector } from '~slices/top-ranking';
import { Event } from '~organisms';
import { SystemOverview } from '~molecules';
import { activeTicketIdSelector, pickActiveId, setActiveArchiveTicket, useActiveArchiveTicketSelector } from '~slices/tickets';
import { getTestId } from '~utils/getTestId';
import { TipHeader } from './TipHeader';
import './ticket.scss';
var useStyles = makeStyles({
    root: {
        boxSizing: 'border-box',
        background: '#151515',
        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.05)',
        color: '#fff',
        fontSize: '12px',
        lineHeight: '18px',
        fontWeight: 300,
    },
    details: {
        padding: 0,
    },
    backdrop: {
        zIndex: 1,
        color: '#fff',
        position: 'absolute',
        background: 'rgba(50, 50, 50, 0.6)',
    },
    expanded: {
        overflow: 'hidden',
        transition: 'all .25s',
    },
}, { name: 'Tip' });
export var Tip = function (_a) {
    var _b, _c;
    var ticket = _a.ticket, defaultTicketId = _a.defaultTicketId, isArchiveTicket = _a.isArchiveTicket;
    var classes = useStyles();
    var dispatch = useDispatch();
    var isDesktop = useIsDesktop();
    var _d = useState(false), exp = _d[0], setExp = _d[1];
    var _e = useState({ exp: false, height: 0 }), detailsIsOpen = _e[0], setDetailsIsOpen = _e[1];
    var _f = useState(false), isGoal = _f[0], setIsGoal = _f[1];
    var contentRef = useRef(null);
    var _g = useStructuredSelector({
        templateInfo: templateInfoSelector,
        activeID: activeTicketIdSelector,
        archiveTicket: useActiveArchiveTicketSelector,
    }), _h = _g.templateInfo, providerId = _h.providerId, ticketId = _h.ticketId, activeID = _g.activeID, archiveTicket = _g.archiveTicket;
    useEffect(function () {
        if (isArchiveTicket)
            return;
        dispatch(pickActiveId(defaultTicketId));
        return function () {
            dispatch(pickActiveId(0));
        };
    }, []);
    useEffect(function () {
        if (contentRef.current) {
            contentRef.current.style.maxHeight = exp
                ? "".concat(contentRef.current.scrollHeight, "px")
                : '0px';
        }
    }, [exp]);
    useEffect(function () {
        if (detailsIsOpen.exp && contentRef.current) {
            contentRef.current.style.maxHeight = "".concat(contentRef.current.scrollHeight + detailsIsOpen.height, "px");
        }
    }, [detailsIsOpen]);
    var expChange = function () {
        setExp(!exp);
    };
    var openDetails = function (height) {
        setDetailsIsOpen({ exp: !detailsIsOpen.exp, height: height });
    };
    var showBackdrop = Boolean(providerId &&
        ticketId &&
        (ticket.providerId !== providerId || ticket.mtsTicketId !== ticketId));
    var pickActiveIdHandler = function (id) {
        if (id === activeID) {
            dispatch(pickActiveId(0));
        }
        else {
            dispatch(pickActiveId(id));
        }
    };
    var pickActiveArchiveTicketHandler = function (id) {
        if ((archiveTicket === null || archiveTicket === void 0 ? void 0 : archiveTicket.id) === id) {
            dispatch(setActiveArchiveTicket(null));
        }
        else {
            dispatch(setActiveArchiveTicket(ticket));
        }
    };
    var toggleActiveHandler = function () {
        isArchiveTicket
            ? pickActiveArchiveTicketHandler(ticket.id)
            : pickActiveIdHandler(ticket.id);
    };
    return (React.createElement("div", { className: classNames('ticket', { isMobile: !isDesktop }), "data-testid": getTestId('OBJ_BET') }, isDesktop ? (React.createElement(React.Fragment, null,
        React.createElement(Backdrop, { className: classes.backdrop, open: showBackdrop }),
        React.createElement(TipHeader, { ticket: ticket, isGoal: isGoal, toggleActiveHandler: toggleActiveHandler }))) : (React.createElement(React.Fragment, null,
        React.createElement(TipHeader, { ticket: ticket, isGoal: isGoal, toggleActiveHandler: expChange }),
        React.createElement("div", { className: classNames(classes.expanded, classes.root), ref: contentRef }, (_c = (_b = ticket.ticketDetails) === null || _b === void 0 ? void 0 : _b.evs) === null || _c === void 0 ? void 0 :
            _c.map(function (event) {
                var _a;
                return (React.createElement(Event, { key: event.id, showBanker: !!((_a = event.sl) === null || _a === void 0 ? void 0 : _a.ba), 
                    // @ts-expect-error
                    event: event, setIsGoal: function (isGoal) { return setIsGoal(isGoal); }, toRedirectToSidebets: true, purchasedTicket: true }));
            }),
            React.createElement(SystemOverview, __assign({ recalcParentHeight: openDetails }, ticket, ticket.ticketDetails)))))));
};
