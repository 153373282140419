var _a;
import { createSelector } from 'reselect';
import { createBaseSelectors } from '~store/utils';
import { name } from './constants';
export var selectMarketsData = (_a = createBaseSelectors(name), _a.selectMarketsData), selectMarketsError = _a.selectMarketsError, selectMarketsLoadingStatus = _a.selectMarketsLoadingStatus, selectMarketsState = _a.selectMarketsState;
var selectMarketsOrder = function (state) {
    return selectMarketsState(state).meta.order;
};
export var selectMarketsLanguage = function (state) {
    return selectMarketsState(state).meta.langIso;
};
export var selectOrderedMarketsData = createSelector(selectMarketsData, selectMarketsOrder, function (data, order) {
    return order
        .map(function (i) {
        return data[i];
    })
        .filter(Boolean);
});
