import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StatusScreen } from '~molecules';
import success from '~icons/statusIcons/success.svg';
import failed from '~icons/statusIcons/failed.svg';
import { FullscreenLoader } from '~atoms/loader';
import { confirmRegistrationEmail, errorSelector, loadingSelector, } from '~slices/account';
import { useAppDispatch } from '~store';
import { useStructuredSelector } from '~hooks';
export var CodeConfirmationModal = function (_a) {
    var confirmationEmailCode = _a.code, headerClassName = _a.headerClassName, subheaderClassName = _a.subheaderClassName, confirmClick = _a.confirmClick, resendClick = _a.resendClick;
    var t = useTranslation('registration').t;
    var te = useTranslation('errors').t;
    var tc = useTranslation('common').t;
    var dispatch = useAppDispatch();
    var _b = useStructuredSelector({
        loading: loadingSelector,
        responseError: errorSelector,
    }), loading = _b.loading, responseError = _b.responseError;
    var renderResponseError = function () {
        if (Array.isArray(responseError)) {
            return responseError.map(function (_a) {
                var code = _a.code;
                return te(code);
            }).join('. ');
        }
        return (responseError === null || responseError === void 0 ? void 0 : responseError.message) || te('unknown_error');
    };
    useEffect(function () {
        if (!responseError) {
            dispatch(confirmRegistrationEmail({ code: confirmationEmailCode }));
        }
    }, []);
    if (loading === 'pending') {
        return React.createElement(FullscreenLoader, null);
    }
    if (loading === 'fulfilled') {
        return (React.createElement(StatusScreen, { headerClassName: headerClassName, subheaderClassName: subheaderClassName, isMobile: true, header: t('status.welcome'), subheader: t('status.link_was_confirmed'), logo: success, onClick: confirmClick, buttonLabel: tc('buttons.continue') }));
    }
    return (React.createElement(StatusScreen, { headerClassName: headerClassName, subheaderClassName: subheaderClassName, isMobile: true, header: t('status.error'), subheader: renderResponseError(), logo: failed, onClick: resendClick, buttonLabel: tc('buttons.resend_link') }));
};
