import { useEffect, useState } from 'react';
import throttle from 'lodash.throttle';
var recaptchaToken = undefined;
var recapchaRendered = false;
var callbacks = {
    onTokenChange: [],
};
var render = function () {
    if (recapchaRendered) {
        return;
    }
    var callback = function (token) {
        if (token) {
            recaptchaToken = token;
            if (callbacks.onSuccess) {
                callbacks.onSuccess(token);
                callbacks.onSuccess = undefined;
            }
            callbacks.onTokenChange.forEach(function (cb) {
                cb(token);
            });
        }
    };
    var expiredCallback = function () {
        grecaptcha.reset();
        recaptchaToken = undefined;
        callbacks.onTokenChange.forEach(function (cb) {
            cb();
        });
    };
    grecaptcha.render('recaptcha', {
        sitekey: process.env.RECAPTCHA_KEY,
        size: 'invisible',
        callback: callback,
        'expired-callback': expiredCallback,
    });
    recapchaRendered = true;
};
var execute = function (onSuccess) {
    if (process.env.RECAPTCHA_KEY) {
        grecaptcha.execute();
        callbacks.onSuccess = onSuccess;
    }
};
var throttledExecute = throttle(execute, 500, { trailing: false });
var useRecaptcha = function () {
    var _a = useState(recaptchaToken), token = _a[0], setToken = _a[1];
    useEffect(function () {
        if (!process.env.RECAPTCHA_KEY) {
            return;
        }
        var handleTokenChange = function (token) {
            setToken(token);
        };
        if ((grecaptcha === null || grecaptcha === void 0 ? void 0 : grecaptcha.render) !== undefined) {
            render();
        }
        callbacks.onTokenChange.push(handleTokenChange);
        return function () {
            callbacks.onTokenChange = callbacks.onTokenChange.filter(function (cb) { return cb !== handleTokenChange; });
        };
    }, [grecaptcha === null || grecaptcha === void 0 ? void 0 : grecaptcha.render]);
    useEffect(function () {
        return function () {
            var recaptchaShadow = document.querySelector('body > div > div[style*="z-index: 2000000000"]:nth-child(1)');
            if (recaptchaShadow) {
                recaptchaShadow.dispatchEvent(new CustomEvent('click'));
            }
            callbacks.onSuccess = undefined;
        };
    }, []);
    return {
        execute: throttledExecute,
        isValidated: !!token,
        token: token,
    };
};
export { useRecaptcha };
