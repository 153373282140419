var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createAsyncThunk } from '@reduxjs/toolkit';
import { name } from '~slices/account/constants';
import { request, requestWithoutAuth } from '~utils/request';
import { getGmtTimezone } from '~utils/gmtTimezone';
export var getSecurityOptions = createAsyncThunk("".concat(name, "/getSecurityOptions"), function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, request({
                    method: 'get',
                    url: 'auth/v1/users/security',
                    params: params,
                })];
            case 1:
                data = (_a.sent()).data;
                return [2 /*return*/, data];
        }
    });
}); });
export var updateSecurityOptions = createAsyncThunk("".concat(name, "/updateSecurityOptions"), function (updateSecurityData, _a) {
    var rejectWithValue = _a.rejectWithValue;
    return __awaiter(void 0, void 0, void 0, function () {
        var data, error_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, request({
                            method: 'put',
                            url: 'auth/v1/users/security',
                            data: updateSecurityData,
                        })];
                case 1:
                    data = (_b.sent()).data;
                    return [2 /*return*/, data];
                case 2:
                    error_1 = _b.sent();
                    // @ts-expect-error
                    if (!error_1.response) {
                        throw error_1;
                    }
                    // @ts-expect-error
                    return [2 /*return*/, rejectWithValue(error_1.response.data)];
                case 3: return [2 /*return*/];
            }
        });
    });
});
export var getAccount = createAsyncThunk("".concat(name, "/getAccount"), function (_, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var data, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, request({
                        method: 'get',
                        url: 'auth-service/v1/users/me',
                    })];
            case 1:
                data = (_a.sent()).data;
                return [2 /*return*/, data];
            case 2:
                error_2 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(error_2)];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var updateAccount = createAsyncThunk("".concat(name, "/updateAccount"), function (updateUserInfoDto, _a) {
    var rejectWithValue = _a.rejectWithValue;
    return __awaiter(void 0, void 0, void 0, function () {
        var data, error_3;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, request({
                            method: 'put',
                            url: 'auth/v1/users',
                            data: updateUserInfoDto,
                        })];
                case 1:
                    data = (_b.sent()).data;
                    return [2 /*return*/, data];
                case 2:
                    error_3 = _b.sent();
                    // @ts-expect-error
                    if (!error_3.response) {
                        throw error_3;
                    }
                    // @ts-expect-error
                    return [2 /*return*/, rejectWithValue(error_3.response.data)];
                case 3: return [2 /*return*/];
            }
        });
    });
});
export var editEmail = createAsyncThunk("".concat(name, "/editEmail"), function (updateEmailData, _a) {
    var rejectWithValue = _a.rejectWithValue;
    return __awaiter(void 0, void 0, void 0, function () {
        var error_4;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, request({
                            method: 'put',
                            url: 'auth/v1/users/update-email',
                            data: updateEmailData,
                        })];
                case 1:
                    _b.sent();
                    return [2 /*return*/, {}];
                case 2:
                    error_4 = _b.sent();
                    // @ts-expect-error
                    if (!error_4.response) {
                        throw error_4;
                    }
                    // @ts-expect-error
                    return [2 /*return*/, rejectWithValue(error_4.response.data)];
                case 3: return [2 /*return*/];
            }
        });
    });
});
export var editPassword = createAsyncThunk("".concat(name, "/editPassword"), function (updatePasswordData, _a) {
    var rejectWithValue = _a.rejectWithValue;
    return __awaiter(void 0, void 0, void 0, function () {
        var data, error_5;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, request({
                            method: 'put',
                            url: 'auth/v1/users/update-pwd',
                            data: updatePasswordData,
                        })];
                case 1:
                    data = (_b.sent()).data;
                    return [2 /*return*/, data];
                case 2:
                    error_5 = _b.sent();
                    // @ts-expect-error
                    if (!error_5.response) {
                        throw error_5;
                    }
                    // @ts-expect-error
                    return [2 /*return*/, rejectWithValue(error_5.response.data)];
                case 3: return [2 /*return*/];
            }
        });
    });
});
export var confirmUpdateEmail = createAsyncThunk("".concat(name, "/confirmUpdateEmail"), function (values, _a) {
    var rejectWithValue = _a.rejectWithValue;
    return __awaiter(void 0, void 0, void 0, function () {
        var data, error_6;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, request({
                            method: 'get',
                            url: "auth/v1/users/confirm/email/".concat(values.code),
                        })];
                case 1:
                    data = (_b.sent()).data;
                    return [2 /*return*/, data];
                case 2:
                    error_6 = _b.sent();
                    // @ts-expect-error
                    if (!error_6.response) {
                        throw error_6;
                    }
                    // @ts-expect-error
                    return [2 /*return*/, rejectWithValue(error_6.response.data)];
                case 3: return [2 /*return*/];
            }
        });
    });
});
export var confirmRegistrationEmail = createAsyncThunk("".concat(name, "/confirmRegistrationEmail"), function (params, _a) {
    var rejectWithValue = _a.rejectWithValue;
    return __awaiter(void 0, void 0, void 0, function () {
        var error_7;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, requestWithoutAuth({
                            method: 'get',
                            url: "auth/v1/users/confirm/registration/".concat(params.code),
                            headers: {
                                timezone: getGmtTimezone(false),
                            },
                        })];
                case 1:
                    _b.sent();
                    return [2 /*return*/, {}];
                case 2:
                    error_7 = _b.sent();
                    // @ts-expect-error
                    if (!error_7.response) {
                        throw error_7;
                    }
                    // @ts-expect-error
                    return [2 /*return*/, rejectWithValue(error_7.response.data)];
                case 3: return [2 /*return*/];
            }
        });
    });
});
export var checkUserPassword = createAsyncThunk("".concat(name, "/checkPassword"), function (values) { return __awaiter(void 0, void 0, void 0, function () {
    var data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, request({
                    method: 'POST',
                    url: 'auth/v1/users/check-pwd',
                    data: values,
                })];
            case 1:
                data = (_a.sent()).data;
                return [2 /*return*/, data];
        }
    });
}); });
