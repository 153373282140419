import makeStyles from '@material-ui/core/styles/makeStyles';
var useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        width: '375px',
        maxHeight: '335px',
        borderRadius: '4px',
        color: '#212121',
        overflow: 'hidden',
        padding: '0px 8px',
        '& *::-webkit-scrollbar-track': {
            backgroundColor: '#FFFFFF',
        },
    },
    scroll: {
        marginTop: '8px',
        paddingBottom: '8px',
        overflowY: 'scroll',
        maxHeight: '100%',
    },
}, { name: 'Content' });
export { useStyles };
