import React, { useEffect } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { getTeasers, squareGallerySelector } from '~slices/teasers';
import { useAppDispatch } from '~store';
import { useIsMobileDevise, useStructuredSelector } from '~hooks';
import { isAuthenticatedSelector } from '~slices/auth';
import { Slider } from '~molecules';
import { useAdvertisingStyles } from './AdvertisingBanners.styles';
export var AdvertisingBanners = function () {
    var _a;
    var classes = useAdvertisingStyles();
    var isMobile = useIsMobileDevise();
    var t = useTranslation('ticket').t;
    var dispatch = useAppDispatch();
    var _b = useStructuredSelector({
        isAuthenticated: isAuthenticatedSelector,
        banners: function (state) { return squareGallerySelector(state); },
    }), isAuthenticated = _b.isAuthenticated, banners = _b.banners;
    useEffect(function () {
        if (i18next.language) {
            dispatch(getTeasers({
                language: i18next.language,
                pageName: 'ANY_PAGE',
                platformType: 'WEB',
                teaserStatus: isAuthenticated ? 'LOGGED_IN' : 'ANONYMOUS',
            }));
        }
    }, [i18next.language, isMobile, isAuthenticated]);
    return (React.createElement("div", { className: classes.advertisingBlock },
        React.createElement("span", { className: classes.header }, (Array.isArray(banners) &&
            Boolean(banners.length) &&
            ((_a = banners[0]) === null || _a === void 0 ? void 0 : _a.advertising)) ||
            t('advertising_banners')),
        React.createElement(Slider, { position: "RIGHT", slideshows: banners })));
};
