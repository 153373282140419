import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { useLocation } from 'react-router-dom';
import { Button, Currency } from '~atoms';
import { getPathWithLanguageCode } from '~utils/languageInURL';
import { PaymentsURL } from '~pages/payments/payments.routes';
import { useAppDispatch } from '~store';
import { useStyles } from './PaymentCode.styles';
export var PaymentCode = function () {
    var t = useTranslation('tip-game').t;
    var classes = useStyles();
    var dispatch = useAppDispatch();
    var search = useLocation().search;
    var params = new URLSearchParams(search);
    var processType = params.get('process');
    var amount = params.get('sum');
    var isWithdrawProcess = processType === 'withdraw';
    var isDepositProcess = processType === 'deposit';
    var subtitle = isDepositProcess
        ? t('payments.show_code_to_agent')
        : isWithdrawProcess
            ? t('payments.show_code_to_agent_and_receive_money')
            : '';
    var mockData = {
        currency: 'EUR',
        QRCodeURL: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/QR_code_for_mobile_English_Wikipedia.svg/1200px-QR_code_for_mobile_English_Wikipedia.svg.png',
    };
    useEffect(function () {
        if (isDepositProcess) {
            console.log('Send the request to get a QR code for deposit here!');
        }
        if (isWithdrawProcess) {
            console.log('Send the request to get a QR code for withdraw money here!');
        }
    }, []);
    var onCancelClick = function () {
        if (isDepositProcess) {
            dispatch(push(getPathWithLanguageCode("/".concat(PaymentsURL.PAYMENTS, "/").concat(PaymentsURL.DEPOSIT_SHOP_BALANCE, "/").concat(PaymentsURL.STATUS, "?sum=").concat(amount))));
        }
        if (isWithdrawProcess) {
            dispatch(push(getPathWithLanguageCode("/".concat(PaymentsURL.PAYMENTS, "/").concat(PaymentsURL.WITHDRAW_SHOP_BALANCE, "/").concat(PaymentsURL.STATUS, "?sum=").concat(amount))));
        }
    };
    return (React.createElement("div", { className: classes.wrapper },
        React.createElement("div", null,
            React.createElement("div", { className: classes.title },
                t('payments.payment_code_generated'),
                "!"),
            React.createElement("div", { className: classes.amount },
                amount,
                ' ',
                React.createElement(Currency, { currency: mockData.currency })),
            React.createElement("div", { className: classes.code },
                React.createElement("img", { src: mockData.QRCodeURL, alt: 'QR-code' })),
            React.createElement("div", { className: classes.subtitle },
                subtitle,
                ".")),
        React.createElement(Button, { color: "primary", variant: "contained", fullWidth: true, className: classes.button, onClick: onCancelClick }, t('payments.cancel_on_leave_page'))));
};
