import makeStyles from '@material-ui/core/styles/makeStyles';
var useStyles = makeStyles({
    shadow: {
        position: 'fixed',
        width: '100%',
        height: 'calc(100% - 78px)',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
        position: 'absolute',
        top: '106px',
        right: '30px',
        width: 'calc(100% - 60px)',
        maxWidth: '450px',
        paddingBottom: '8px',
    },
}, { name: 'Modal' });
export { useStyles };
