import { createSelector } from 'reselect';
import { name } from './constants';
export var outrightsDataSelector = function (state) { return state[name].data; };
export var outrightsLoadingSelector = function (state) {
    return state[name].loading;
};
export var categoriesDataSelector = createSelector(outrightsDataSelector, function (data) { return data.categories; });
export var isOutrightsTabDisabledSelector = createSelector(function (state) { var _a; return !((_a = categoriesDataSelector(state)) === null || _a === void 0 ? void 0 : _a.length); }, function (isOutrightsTabDisabled) { return isOutrightsTabDisabled; });
export var tournamentsDataSelector = createSelector(outrightsDataSelector, function (data) { return data.tournaments; });
export var outrightsOutrightsDataSelector = createSelector(outrightsDataSelector, function (data) { return data.outrights; });
