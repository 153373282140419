import React, { useEffect, useRef, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import BScroll from '@better-scroll/core';
var useStyles = makeStyles({
    container: {
        flex: 'auto',
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
    },
    content: {
        display: 'inline-block',
    },
}, { name: 'HorizontalScrollWrapper' });
export var HorizontalScrollWrapper = function (_a) {
    var children = _a.children, elementsCount = _a.elementsCount, selectedElementIndex = _a.selectedElementIndex, isCup = _a.isCup;
    var containerRef = useRef(null);
    var classes = useStyles();
    var _b = useState(), bs = _b[0], setBs = _b[1];
    var _c = useState(), initialLineWidth = _c[0], setInitialLineWidth = _c[1];
    useEffect(function () {
        if (containerRef.current && elementsCount) {
            /* Create new Scroll instance */
            setBs(new BScroll(containerRef.current, {
                scrollX: true,
                click: true,
            }));
        }
        if (bs) {
            /* Umnount scroll instance when elementsCount = 0 */
            bs.scrollTo(0, 0);
            bs.destroy();
        }
    }, [containerRef, elementsCount]);
    useEffect(function () {
        var _a;
        var wisibleCount = (((_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.clientWidth) || 20) / 62;
        var restCount = elementsCount - ((selectedElementIndex || 0) + 1);
        var scrollToValue = restCount > wisibleCount
            ? -1 * Number(selectedElementIndex) * 62
            : -1 * (elementsCount - wisibleCount) * 62;
        if (bs &&
            !Number.isNaN(selectedElementIndex) &&
            (selectedElementIndex && selectedElementIndex >= Math.floor(wisibleCount) || selectedElementIndex === -1)) {
            bs.scrollTo(scrollToValue, 0);
        }
    }, [bs, selectedElementIndex]);
    useEffect(function () {
        if (bs) {
            /* set maxScrollX when new bs is created */
            setInitialLineWidth(bs.maxScrollX);
        }
    }, [bs]);
    useEffect(function () {
        if (bs && initialLineWidth) {
            /* Change maxScrollX depending on cup tournament
            when initiaLineWidth is changed after creating new bs */
            bs.maxScrollX = isCup ? initialLineWidth - 60 : initialLineWidth - 5;
        }
    }, [isCup, initialLineWidth]);
    return (React.createElement("div", { className: classes.container, ref: containerRef },
        React.createElement("div", { className: classes.content }, children)));
};
