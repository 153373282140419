import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var BeachVolleyIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg", className: className },
        React.createElement("g", { clipPath: "url(#clip0)" },
            React.createElement("path", { d: "M14.0001 3.29426V1.64722H0.82373V3.29426H3.2943V11.5295H0.82373V13.1765H14.0001V11.5295H12.8472V9.88245H14.0001V8.2354H12.8472V6.58835H14.0001V4.94131H12.8472V3.29426H14.0001ZM7.24721 11.5295H4.94135V9.88245H7.24721V11.5295ZM7.24721 8.2354H4.94135V6.58835H7.24721V8.2354ZM7.24721 4.94131H4.94135V3.29426H7.24721V4.94131ZM11.2001 11.5295H8.89426V9.88245H11.2001V11.5295ZM11.2001 8.2354H8.89426V6.58835H11.2001V8.2354ZM11.2001 4.94131H8.89426V3.29426H11.2001V4.94131Z", fill: "#E0E0E2" }),
            React.createElement("path", { d: "M27.1764 3.29426V1.64722H14V3.29426H15.1529V4.94131H14V6.58835H15.1529V8.2354H14V9.88245H15.1529V11.5295H14V13.1765H27.1764V11.5295H24.7058V3.29426H27.1764ZM19.1058 11.5295H16.8V9.88245H19.1058V11.5295ZM19.1058 8.2354H16.8V6.58835H19.1058V8.2354ZM19.1058 4.94131H16.8V3.29426H19.1058V4.94131ZM23.0588 11.5295H20.7529V9.88245H23.0588V11.5295ZM23.0588 8.2354H20.7529V6.58835H23.0588V8.2354ZM23.0588 4.94131H20.7529V3.29426H23.0588V4.94131Z", fill: "#C6C5CA" }),
            React.createElement("path", { d: "M1.64802 19.7648L0.00119617 19.6508L0.000976562 0.000244141H1.64802V19.7648Z", fill: "#00DDC1" }),
            React.createElement("path", { d: "M28.0003 19.6544L26.353 19.7648V0.000244141H28.0001L28.0003 19.6544Z", fill: "#00B5BC" }),
            React.createElement("path", { d: "M9.33452 21.299C9.33419 21.299 9.33397 21.299 9.33364 21.299C7.93497 21.2988 7.17848 20.8358 6.57061 20.4639C6.02934 20.1326 5.60182 19.871 4.66701 19.8709C4.66685 19.8709 4.66652 19.8709 4.66636 19.8709C3.73188 19.8709 3.30452 20.1324 2.76325 20.4634C2.15543 20.8351 1.39884 21.2977 0.000878424 21.2978C0.000549015 21.2978 0.000329409 21.2978 0 21.2978L0.000219606 19.6507H0.000933326C0.935302 19.6507 1.36277 19.3893 1.90398 19.0583C2.5118 18.6865 3.26851 18.2239 4.66641 18.2239H4.66723C6.06591 18.224 6.82239 18.687 7.43026 19.059C7.97154 19.3902 8.39906 19.6518 9.33386 19.6519H9.33458C10.2689 19.6519 10.6944 19.3917 11.2331 19.0622L12.0924 20.4673C11.4866 20.8378 10.7325 21.299 9.33452 21.299Z", fill: "#FFDD90" }),
            React.createElement("path", { d: "M28.0003 21.3014C26.6017 21.3012 25.8452 20.8383 25.2373 20.4663C24.6961 20.1351 24.2686 19.8735 23.334 19.8734C23.3337 19.8734 23.3334 19.8734 23.3331 19.8734C23.0848 19.8734 22.8646 19.8918 22.6598 19.9296L22.3604 18.31C22.6649 18.2537 22.9832 18.2263 23.333 18.2263H23.3342C24.7327 18.2265 25.4891 18.6895 26.097 19.0614C26.6382 19.3926 27.0657 19.6543 28.0004 19.6544L28.0003 21.3014Z", fill: "#E7B964" }),
            React.createElement("path", { d: "M23.8821 21.4119C23.8821 22.4016 23.6628 23.3409 23.2702 24.184L22.5138 24.7187L22.3841 25.5902C22.0255 26.0263 21.6121 26.4157 21.1544 26.7477L20.0269 26.7821L19.5807 27.5909C18.868 27.8555 18.0975 28.0001 17.2939 28.0001V14.8237C17.9101 14.8237 18.5067 14.9087 19.0728 15.0677L19.224 16.0853L20.6679 15.7549C21.2687 16.1146 21.8077 16.5671 22.2649 17.0926C22.2997 17.1335 22.3599 18.1486 22.3599 18.1486L23.2122 18.5184C23.6411 19.3923 23.8821 20.3745 23.8821 21.4119Z", fill: "#1982BF" }),
            React.createElement("path", { d: "M17.2942 14.8237V28.0001C13.6614 28.0001 10.7061 25.0447 10.7061 21.4119C10.7061 21.0812 10.7305 20.7562 10.7778 20.4385L11.2853 19.8645L11.2796 18.7239C11.5358 18.1529 11.8709 17.6248 12.271 17.1535L13.4137 17.2256L13.4967 16.0311C14.5707 15.2708 15.8812 14.8237 17.2942 14.8237Z", fill: "#4BB9EC" }),
            React.createElement("path", { d: "M17.2939 17.1919L17.5635 19.4231L17.2939 21.3436L12.271 17.1535C12.6306 16.7302 13.0424 16.3525 13.4964 16.0308L16.2986 18.3685L17.2939 17.1919Z", fill: "#FFDB00" }),
            React.createElement("path", { d: "M23.27 24.1838C23.0339 24.6905 22.7352 25.1627 22.3839 25.5898L17.2939 21.3437V17.1921L19.0881 15.0718C19.6481 15.231 20.1785 15.4621 20.6676 15.7553L17.5635 19.4233L19.1397 20.7382L22.2647 17.0927C22.6386 17.5226 22.9581 18.0013 23.2118 18.5185L20.4041 21.7934L23.27 24.1838Z", fill: "#EF9B14" }),
            React.createElement("path", { d: "M17.2941 23.6107L17.9529 24.706L17.2941 25.7326L10.7778 20.4385C10.8673 19.8367 11.0386 19.2614 11.2796 18.7239L17.2941 23.6107Z", fill: "#FFDB00" }),
            React.createElement("path", { d: "M21.1541 26.7471C20.6742 27.0952 20.1455 27.3807 19.5806 27.5904L17.2939 25.7325V23.6106L21.1541 26.7471Z", fill: "#EF9B14" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0" },
                React.createElement("rect", { width: "28", height: "28", fill: "white" })))));
};
