import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var ESportIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, viewBox: "0 0 28 28" },
        React.createElement("path", { d: "M13.999 12.0314C13.7571 12.0314 13.5615 11.8358 13.5615 11.5939V10.2822C13.5615 10.0403 13.7571 9.84473 13.999 9.84473C14.241 9.84473 14.4365 10.0403 14.4365 10.2822V11.5939C14.4365 11.8358 14.241 12.0314 13.999 12.0314Z", fill: "#656D78" }),
        React.createElement("path", { d: "M13.999 10.7196C13.7571 10.7196 13.5615 10.5241 13.5615 10.2821C13.5615 9.0755 14.5433 8.09375 15.7499 8.09375C15.9918 8.09375 16.1874 8.28931 16.1874 8.53125C16.1874 8.77319 15.9918 8.96875 15.7499 8.96875C15.0258 8.96875 14.4365 9.55806 14.4365 10.2821C14.4365 10.5241 14.241 10.7196 13.999 10.7196Z", fill: "#656D78" }),
        React.createElement("path", { d: "M21.0029 8.96874C20.761 8.96874 20.5654 8.77318 20.5654 8.53124C20.5654 8.28931 20.761 8.09374 21.0029 8.09374C21.7266 8.09374 22.3154 7.50487 22.3154 6.78125C22.3154 6.05762 21.7266 5.46875 21.0029 5.46875C20.761 5.46875 20.5654 5.27319 20.5654 5.03125C20.5654 4.78931 20.761 4.59375 21.0029 4.59375C22.2091 4.59375 23.1904 5.57506 23.1904 6.78125C23.1904 7.98743 22.2091 8.96874 21.0029 8.96874Z", fill: "#656D78" }),
        React.createElement("path", { d: "M6.99706 5.46875C5.79088 5.46875 4.80957 4.48744 4.80957 3.28125C4.80957 2.07506 5.79088 1.09375 6.99706 1.09375C7.239 1.09375 7.43456 1.28975 7.43456 1.53125C7.43456 1.77275 7.239 1.96875 6.99706 1.96875C6.27344 1.96875 5.68457 2.55762 5.68457 3.28125C5.68457 4.00487 6.27344 4.59375 6.99706 4.59375C7.239 4.59375 7.43456 4.78975 7.43456 5.03125C7.43456 5.27275 7.239 5.46875 6.99706 5.46875Z", fill: "#656D78" }),
        React.createElement("path", { d: "M21.0018 8.96875H15.75C15.5081 8.96875 15.3125 8.77319 15.3125 8.53125C15.3125 8.28931 15.5081 8.09375 15.75 8.09375H21.0018C21.2437 8.09375 21.4393 8.28931 21.4393 8.53125C21.4393 8.77319 21.2437 8.96875 21.0018 8.96875Z", fill: "#656D78" }),
        React.createElement("path", { d: "M21.0014 5.46875H6.99707C6.75513 5.46875 6.55957 5.27319 6.55957 5.03125C6.55957 4.78931 6.75513 4.59375 6.99707 4.59375H21.0014C21.2434 4.59375 21.4389 4.78931 21.4389 5.03125C21.4389 5.27319 21.2434 5.46875 21.0014 5.46875Z", fill: "#656D78" }),
        React.createElement("path", { d: "M14.4376 1.96875H6.99707C6.75513 1.96875 6.55957 1.77275 6.55957 1.53125C6.55957 1.28975 6.75513 1.09375 6.99707 1.09375H14.4376C14.6796 1.09375 14.8751 1.28975 14.8751 1.53125C14.8751 1.77275 14.6796 1.96875 14.4376 1.96875Z", fill: "#656D78" }),
        React.createElement("path", { d: "M21.4375 11.6152V11.5938H14H7C3.37313 11.5938 0.4375 14.5333 0.4375 18.1562C0.4375 21.7827 3.37313 24.7188 7 24.7188C9.85688 24.7188 12.2806 22.8944 13.1819 20.3438H14.8181C15.7194 22.8944 18.1431 24.7188 21 24.7188C24.6225 24.7188 27.5625 21.7827 27.5625 18.1562C27.5625 14.6825 24.8587 11.8436 21.4375 11.6152Z", fill: "#E6E9ED" }),
        React.createElement("path", { d: "M21.0002 24.7197C24.6227 24.7197 27.5627 21.7837 27.5627 18.1572V20.3447C27.5627 23.9712 24.6227 26.9072 21.0002 26.9072C18.1434 26.9072 15.7196 25.0829 14.8184 22.5322V20.3447C15.7196 22.8954 18.1434 24.7197 21.0002 24.7197Z", fill: "#CCD1D9" }),
        React.createElement("path", { d: "M13.1816 20.3447H14.8179V22.5322H13.1816V20.3447Z", fill: "#AAB2BD" }),
        React.createElement("path", { d: "M13.1819 22.5322C12.2806 25.0829 9.85688 26.9072 7 26.9072C3.37313 26.9072 0.4375 23.9712 0.4375 20.3447V18.1572C0.4375 21.7837 3.37313 24.7197 7 24.7197C9.85688 24.7197 12.2806 22.8954 13.1819 20.3447V22.5322Z", fill: "#CCD1D9" }),
        React.createElement("path", { d: "M14.875 15.9688H13.125C12.8831 15.9688 12.6875 15.7732 12.6875 15.5312C12.6875 15.2893 12.8831 15.0938 13.125 15.0938H14.875C15.1169 15.0938 15.3125 15.2893 15.3125 15.5312C15.3125 15.7732 15.1169 15.9688 14.875 15.9688Z", fill: "#AAB2BD" }),
        React.createElement("path", { d: "M9.1875 17.7197C9.66919 17.7197 10.0625 18.1135 10.0625 18.5947C10.0625 19.0799 9.66919 19.4697 9.1875 19.4697H7.875V20.7822C7.875 21.2674 7.48169 21.6572 7 21.6572C6.51481 21.6572 6.125 21.2674 6.125 20.7822V19.4697H4.8125C4.32731 19.4697 3.9375 19.0799 3.9375 18.5947C3.9375 18.1135 4.32731 17.7197 4.8125 17.7197H6.125V16.4072C6.125 15.926 6.51481 15.5322 7 15.5322C7.48169 15.5322 7.875 15.926 7.875 16.4072V17.7197H9.1875Z", fill: "#545C66" }),
        React.createElement("path", { d: "M18.8252 17.7197C19.3064 17.7197 19.7002 18.1135 19.7002 18.5947C19.7002 19.0799 19.3064 19.4697 18.8252 19.4697C18.3435 19.4697 17.9502 19.0799 17.9502 18.5947C17.9502 18.1135 18.3439 17.7197 18.8252 17.7197Z", fill: "#545C66" }),
        React.createElement("path", { d: "M21.0127 15.5322C21.4939 15.5322 21.8877 15.926 21.8877 16.4072C21.8877 16.8924 21.4939 17.2822 21.0127 17.2822C20.531 17.2822 20.1377 16.8924 20.1377 16.4072C20.1377 15.926 20.5314 15.5322 21.0127 15.5322Z", fill: "#545C66" }),
        React.createElement("path", { d: "M21.0127 19.9072C21.4939 19.9072 21.8877 20.301 21.8877 20.7822C21.8877 21.2674 21.4939 21.6572 21.0127 21.6572C20.531 21.6572 20.1377 21.2674 20.1377 20.7822C20.1377 20.301 20.5314 19.9072 21.0127 19.9072Z", fill: "#545C66" }),
        React.createElement("path", { d: "M23.2002 17.7197C23.6814 17.7197 24.0752 18.1135 24.0752 18.5947C24.0752 19.0799 23.6814 19.4697 23.2002 19.4697C22.7185 19.4697 22.3252 19.0799 22.3252 18.5947C22.3252 18.1135 22.7189 17.7197 23.2002 17.7197Z", fill: "#545C66" }),
        React.createElement("path", { d: "M3.9375 18.1572H10.0625V18.5947H3.9375V18.1572Z", fill: "#545C66" }),
        React.createElement("path", { d: "M17.9502 18.1572H19.7002V18.5947H17.9502V18.1572Z", fill: "#545C66" }),
        React.createElement("path", { d: "M22.3252 18.1572H24.0752V18.5947H22.3252V18.1572Z", fill: "#545C66" }),
        React.createElement("path", { d: "M20.1377 20.3447H21.8877V20.7822H20.1377V20.3447Z", fill: "#545C66" }),
        React.createElement("path", { d: "M20.1377 15.9697H21.8877V16.4072H20.1377V15.9697Z", fill: "#545C66" }),
        React.createElement("path", { d: "M18.8252 17.2822C19.3064 17.2822 19.7002 17.676 19.7002 18.1572C19.7002 18.6424 19.3064 19.0322 18.8252 19.0322C18.3435 19.0322 17.9502 18.6424 17.9502 18.1572C17.9502 17.676 18.3439 17.2822 18.8252 17.2822Z", fill: "#656D78" }),
        React.createElement("path", { d: "M21.0127 15.0947C21.4939 15.0947 21.8877 15.4885 21.8877 15.9697C21.8877 16.4549 21.4939 16.8447 21.0127 16.8447C20.531 16.8447 20.1377 16.4549 20.1377 15.9697C20.1377 15.4885 20.5314 15.0947 21.0127 15.0947Z", fill: "#656D78" }),
        React.createElement("path", { d: "M21.0127 19.4697C21.4939 19.4697 21.8877 19.8635 21.8877 20.3447C21.8877 20.8299 21.4939 21.2197 21.0127 21.2197C20.531 21.2197 20.1377 20.8299 20.1377 20.3447C20.1377 19.8635 20.5314 19.4697 21.0127 19.4697Z", fill: "#656D78" }),
        React.createElement("path", { d: "M23.2002 17.2822C23.6814 17.2822 24.0752 17.676 24.0752 18.1572C24.0752 18.6424 23.6814 19.0322 23.2002 19.0322C22.7185 19.0322 22.3252 18.6424 22.3252 18.1572C22.3252 17.676 22.7189 17.2822 23.2002 17.2822Z", fill: "#656D78" }),
        React.createElement("path", { d: "M9.1875 17.2822C9.66919 17.2822 10.0625 17.676 10.0625 18.1572C10.0625 18.6424 9.66919 19.0322 9.1875 19.0322H7.875V20.3447C7.875 20.8299 7.48169 21.2197 7 21.2197C6.51481 21.2197 6.125 20.8299 6.125 20.3447V19.0322H4.8125C4.32731 19.0322 3.9375 18.6424 3.9375 18.1572C3.9375 17.676 4.32731 17.2822 4.8125 17.2822H6.125V15.9697C6.125 15.4885 6.51481 15.0947 7 15.0947C7.48169 15.0947 7.875 15.4885 7.875 15.9697V17.2822H9.1875Z", fill: "#656D78" })));
};
