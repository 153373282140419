function parseJwt(token) {
    if (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64)
            .split('')
            .map(function (c) {
            return "%".concat("00".concat(c.charCodeAt(0).toString(16)).slice(-2));
        })
            .join(''));
        return JSON.parse(jsonPayload);
    }
    return null;
}
export { parseJwt };
