import React from 'react';
import classNames from 'classnames';
import { useStyles } from './styles';
var SmallLoader = function (_a) {
    var className = _a.className;
    var classes = useStyles();
    return (React.createElement("div", { className: classNames(classes.smallDots, className) },
        React.createElement("div", { className: "".concat(classes.dot, " ").concat(classes.firstDot) }),
        React.createElement("div", { className: "".concat(classes.dot, " ").concat(classes.secondDot) }),
        React.createElement("div", { className: "".concat(classes.dot, " ").concat(classes.thirdDot) })));
};
export { SmallLoader };
