import i18next from 'i18next';
var getMessage = function (msgKey) {
    return i18next.t("errors:validation.".concat(msgKey), {
        lngs: [i18next.language],
        lng: i18next.language,
    });
};
export default {
    required: function () { return getMessage('required'); },
    dateFormat: function () { return getMessage('dateFormat'); },
    sizeFrom1to50: function () { return getMessage('sizeFrom1to50'); },
    sizeFrom4to70: function () { return getMessage('sizeFrom4to70'); },
    emailFormat: function () { return getMessage('emailFormat'); },
    emailsDontMatch: function () { return getMessage('emailsDontMatch'); },
    digitsOnly: function () { return getMessage('digitsOnly'); },
    passwordsDontMatch: function () { return getMessage('passwordsDontMatch'); },
    passwordFormat: function () { return getMessage('passwordFormat'); },
    passwordLikeUserNameOrSurname: function () {
        return getMessage('passwordLikeUserNameOrSurname');
    },
};
