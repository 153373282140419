import React from 'react';
import { useTranslation } from 'react-i18next';
import { CountrySelectField } from '~atoms';
import { checkIfFieldIsRequired, required, validateField, } from '~utils/formValidators';
export var CountryCode = function (_a) {
    var countries = _a.countries, tabIndex = _a.tabIndex, registrationBlocks = _a.registrationBlocks;
    var t = useTranslation('registration').t;
    return (React.createElement(CountrySelectField, { name: "userProfileDto.addresses.countryCode", title: t('form.country'), options: countries, validators: function (value) {
            return validateField(value, 'country', tabIndex, required, registrationBlocks);
        }, required: checkIfFieldIsRequired('country', tabIndex, registrationBlocks) }));
};
