import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import i18next from "i18next";
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { useLocation } from 'react-router-dom';
import { StyledTab, StyledTabs } from '~atoms';
import { isBetCheckProject } from '~utils/constants/betCheckConstants';
import { getPathWithLanguageCode } from '~utils/languageInURL';
import { useStructuredSelector } from '~hooks';
import { isAuthenticatedSelector } from '~slices/auth';
import { useAppDispatch } from '~store';
import { dispatchBetSlipWS } from '~utils/dispatchBetSlipWS';
import { BETSLIP_SOCKET_NAME } from '~slices/events';
import { getShortcutBetslip } from '~slices/shortcut';
import { PaymentsURL } from '~pages/payments/payments.routes';
import { getPlayerAccounts, setPlayerAccounts } from "~slices/playerAccounts";
import { getGames, setActiveGame, setGames, useActiveGameSelector } from "~slices/gameTypes";
import { useStyles } from './Payments.styles';
var Payments = function (_a) {
    var children = _a.children;
    var _b = useStructuredSelector({
        isAuthenticated: isAuthenticatedSelector,
        activeGame: useActiveGameSelector,
    }), isAuthenticated = _b.isAuthenticated, activeGame = _b.activeGame;
    var dispatch = useAppDispatch();
    var classes = useStyles();
    var t = useTranslation('tip-game').t;
    var _c = useState('top-up'), activeTab = _c[0], setActiveTab = _c[1];
    var pathname = useLocation().pathname;
    useEffect(function () {
        if (!isAuthenticated || isBetCheckProject) {
            dispatch(push(getPathWithLanguageCode('/home')));
        }
    }, []);
    useEffect(function () {
        dispatch(getGames(i18next.language))
            .then(function (_a) {
            var _b;
            var payload = _a.payload;
            setGames(payload);
            if (activeGame === null) {
                dispatch(setActiveGame((_b = payload[0]) === null || _b === void 0 ? void 0 : _b.name));
            }
        });
    }, [isAuthenticated]);
    useEffect(function () {
        dispatch(getPlayerAccounts()).then(function (_a) {
            var payload = _a.payload;
            setPlayerAccounts(payload);
        });
    }, [isAuthenticated]);
    //TODO, temporary solution while 'deposit' and 'withdraw' are hidden
    useEffect(function () {
        var regex = /\/payments\/([^\/]+)/;
        var match = pathname.match(regex);
        var tabName = match ? match[1] : '';
        if (tabName !== activeTab) {
            dispatch(push(getPathWithLanguageCode("/".concat(PaymentsURL.PAYMENTS, "/").concat(PaymentsURL.TOP_UP))));
        }
    }, [pathname]);
    // useEffect(() => {
    //   const regex = /\/payments\/([^\/]+)/;
    //   const match = pathname.match(regex);
    //   const tabName = match ? match[1] : '';
    //
    //   if (tabName && tabName !== activeTab) {
    //     setActiveTab(tabName as 'top-up' | 'deposit' | 'withdraw');
    //   } else if (!tabName) {
    //     dispatch(
    //       push(
    //         getPathWithLanguageCode(
    //           `/${PaymentsURL.PAYMENTS}/${PaymentsURL.DEPOSIT_SHOP_BALANCE}`
    //         )
    //       )
    //     );
    //   }
    // }, [pathname]);
    useEffect(function () {
        if (isAuthenticated) {
            dispatchBetSlipWS();
            dispatch(getShortcutBetslip());
        }
        return function () {
            dispatch({ type: 'SOCKET_CLOSE', eventsType: BETSLIP_SOCKET_NAME });
        };
    }, [isAuthenticated]);
    var onTabClick = function (type) {
        if (type === activeTab) {
            return;
        }
        if (type === 'top-up') {
            dispatch(push(getPathWithLanguageCode("/".concat(PaymentsURL.PAYMENTS, "/").concat(PaymentsURL.TOP_UP))));
        }
        if (type === 'deposit') {
            dispatch(push(getPathWithLanguageCode("/".concat(PaymentsURL.PAYMENTS, "/").concat(PaymentsURL.DEPOSIT_SHOP_BALANCE))));
        }
        if (type === 'withdraw') {
            dispatch(push(getPathWithLanguageCode("/".concat(PaymentsURL.PAYMENTS, "/").concat(PaymentsURL.WITHDRAW_SHOP_BALANCE))));
        }
        setActiveTab(type);
    };
    return (React.createElement("div", { className: 'flex-row' },
        React.createElement("div", { className: classNames('flex-column', classes.fullWidth) },
            React.createElement("div", { className: classNames('flex-row flex-center', classes.container) },
                React.createElement(StyledTabs, { variant: "fullWidth", value: activeTab },
                    React.createElement(StyledTab, { style: { cursor: "auto" }, label: t('payments.top_up'), value: 'top-up', onClick: function () { return onTabClick('top-up'); } }))),
            React.createElement("div", { className: classes.content }, children))));
};
export default Payments;
