import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';
var useStyles = makeStyles({
    terminalButton: {
        position: 'fixed',
        zIndex: 9999,
        bottom: '20px',
        right: '20px',
        height: '50px',
        width: '50px',
        background: '#2aa1f7',
        border: '2px solid black',
        borderRadius: '10px',
        color: '#ffffff',
        outline: 'none',
        cursor: 'pointer',
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        '& i': {
            fontWeight: 'bold',
        },
    },
    terminal: {
        display: 'grid',
        gridTemplateRows: 'max-content max-content 1fr max-content max-content',
        position: 'fixed',
        zIndex: 9998,
        bottom: '20px',
        right: '20px',
        left: '20px',
        height: '450px',
        minWidth: '230px',
        width: 'calc(100vw - 110px)',
        maxWidth: 'calc(100vw - 110px)',
        resize: 'horizontal',
        overflow: 'hidden',
        background: 'white',
        border: '2px solid #34343e',
        borderRadius: '5px',
        opacity: '.8',
        '&:hover,&:focus': {
            opacity: 1,
        },
        '&.close': {
            display: 'none',
        },
        '&::-webkit-resizer': {
            background: '#2aa1f7',
            borderRadius: '22px 0 0 0',
        },
    },
    terminalHeader: {
        display: 'grid',
        gridTemplateColumns: 'max-content 1fr max-content',
        gridGap: '10px',
        alignItems: 'center',
        justifyContent: 'start',
        background: 'black',
        height: '40px',
        color: '#2aa1f7',
        padding: '0 20px',
        '& i': {
            fontWeight: 'bold',
        },
        '& span': {
            fontWeight: 'bold',
        },
    },
    terminalSubheader: {
        display: 'grid',
        gridTemplateColumns: 'max-content',
        gridGap: '10px',
        alignItems: 'center',
        justifyContent: 'start',
        background: 'black',
        height: '25px',
        color: '#2aa1f7',
        padding: '0 20px',
        borderTop: '1px solid #34343e',
        borderBottom: '1px solid #34343e',
        '& i': {
            fontWeight: 'bold',
        },
        '& button': {
            background: 'transparent',
            border: 'none',
            outline: 'none',
            cursor: 'pointer',
            color: '#2aa1f7',
            padding: 0,
            '& i': {
                fontWeight: 100,
            },
        },
    },
    terminalContent: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        alignItems: 'start',
        alignContent: 'start',
        justifyContent: 'start',
        background: '#26262e',
        color: 'white',
        fontSize: '12px',
        overflow: 'auto',
    },
    terminalMessage: {
        padding: '7px 20px 3px 20px',
        borderBottom: '1px solid black',
        wordBreak: 'break-all',
        '&.warning': {
            background: '#332B00',
            color: '#E4E3DD',
            borderBottom: '1px solid #665500',
        },
        '&.error': {
            background: '#290000',
            color: '#E77272',
            borderBottom: '1px solid #5C0000',
        },
    },
    closeButton: {
        background: 'transparent',
        border: 'none',
        outline: 'none',
        cursor: 'pointer',
        color: '#2aa1f7',
        fontSize: '20px',
        '& i': {
            fontWeight: 100,
        },
    },
    inputArea: {
        display: 'grid',
        gridTemplateColumns: '1fr max-content',
        background: 'black',
        height: '25px',
        borderTop: '1px solid #34343e',
        borderBottom: '1px solid #34343e',
        '& button': {
            background: 'transparent',
            width: '50px',
            border: 'none',
            outline: 'none',
            cursor: 'pointer',
            color: '#2aa1f7',
            padding: 0,
            '& i': {
                fontWeight: 'bold',
            },
        },
        '& input': {
            outline: 'none',
            background: 'black',
            color: 'white',
            border: 'none',
        },
    },
    memory: {
        display: 'grid',
        alignContent: 'center',
        background: 'black',
        height: '20px',
        borderTop: '1px solid #34343e',
        borderBottom: '1px solid #34343e',
        color: 'white',
        fontSize: '12px',
        paddingLeft: '20px',
    },
}, { name: 'Terminal' });
export var Terminal = function () {
    var classes = useStyles();
    var mainRef = useRef(null);
    var _a = React.useState(false), terminalIsOpen = _a[0], setTerminalIsOpen = _a[1];
    var _b = React.useState([
        { messageType: 'log', message: '...console started' },
    ]), messages = _b[0], setMessages = _b[1];
    var _c = React.useState(''), inputValue = _c[0], setInputValue = _c[1];
    useEffect(function () {
        if (mainRef.current)
            mainRef.current.scrollTop = mainRef.current.scrollHeight;
    }, [messages]);
    // (function () {
    //   window.onerror = (message, url, linenumber) =>
    //     setMessages([
    //       ...messages,
    //       {
    //         messageType: 'error',
    //         message: `${message} , ${url} , ${linenumber}`,
    //       },
    //     ]);
    //
    //   console.log = (message: object) => {
    //     setMessages([
    //       ...messages,
    //       {
    //         messageType: 'log',
    //         message: JSON.stringify(message),
    //       },
    //     ]);
    //   };
    //   console.warn = (message: object) => {
    //     setMessages([
    //       ...messages,
    //       {
    //         messageType: 'warning',
    //         message: JSON.stringify(message),
    //       },
    //     ]);
    //   };
    //   console.error = (message: object) => {
    //     setMessages([
    //       ...messages,
    //       {
    //         messageType: 'error',
    //         message: JSON.stringify(message),
    //       },
    //     ]);
    //   };
    // })();
    var evalFromIput = function (event) {
        if (event.charCode === 13) {
            try {
                // eslint-disable-next-line no-eval
                eval(inputValue);
                setInputValue('');
            }
            catch (e) {
                console.error("can't eval ".concat(inputValue));
            }
        }
    };
    var evalFromButton = function () {
        try {
            // eslint-disable-next-line no-eval
            eval(inputValue);
            setInputValue('');
        }
        catch (e) {
            console.error("can't eval ".concat(inputValue));
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "debugDiv", role: "button", tabIndex: 0, className: classNames(classes.terminalButton), onClick: function () { return setTerminalIsOpen(!terminalIsOpen); } },
            React.createElement("i", { className: classNames('fal fa-terminal') })),
        React.createElement("div", { className: classNames(classes.terminal, { close: !terminalIsOpen }) },
            React.createElement("div", { className: classes.terminalHeader },
                React.createElement("i", { className: classNames('fal fa-terminal') }),
                React.createElement("span", null, "Terminal"),
                React.createElement("button", { type: "button", className: classes.closeButton, onClick: function () { return setTerminalIsOpen(false); } },
                    React.createElement("i", { className: classNames('far fa-times') }))),
            React.createElement("div", { className: classes.terminalSubheader },
                React.createElement("button", { type: "button", onClick: function () {
                        return setMessages([
                            { messageType: 'log', message: '...console started' },
                        ]);
                    } },
                    React.createElement("i", { className: classNames('far fa-ban') }))),
            React.createElement("div", { ref: mainRef, className: classes.terminalContent }, messages.map(function (item, i) { return (React.createElement("div", { 
                // eslint-disable-next-line react/no-array-index-key
                key: "".concat(item.messageType, ".").concat(item.message, ".").concat(i), className: classNames(classes.terminalMessage, {
                    log: item.messageType === 'log',
                    warning: item.messageType === 'warning',
                    error: item.messageType === 'error',
                }) }, item.message)); })),
            React.createElement("div", { className: classes.inputArea },
                React.createElement("input", { type: "text", placeholder: "     type code here", value: inputValue, onChange: function (e) { return setInputValue(e.target.value); }, onKeyPress: function (e) { return evalFromIput(e); } }),
                React.createElement("button", { type: "button", onClick: function () { return evalFromButton(); } },
                    React.createElement("i", { className: classNames('far fa-play-circle') }))),
            React.createElement("div", { className: classes.memory }))));
};
