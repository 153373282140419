import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var GolfIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, viewBox: "0 0 28 28" },
        React.createElement("path", { d: "M14 26.7033C21.732 26.7033 28 24.0336 28 20.7403C28 17.4471 21.732 14.7773 14 14.7773C6.26801 14.7773 0 17.4471 0 20.7403C0 24.0336 6.26801 26.7033 14 26.7033Z", fill: "#88C057" }),
        React.createElement("path", { d: "M9.85189 25.1482C15.0065 25.1482 19.1852 23.3683 19.1852 21.1727C19.1852 18.9771 15.0065 17.1973 9.85189 17.1973C4.69723 17.1973 0.518555 18.9771 0.518555 21.1727C0.518555 23.3683 4.69723 25.1482 9.85189 25.1482Z", fill: "#659C35" }),
        React.createElement("path", { d: "M10.9253 22.7856C12.6638 22.7856 14.0732 21.6894 14.0732 20.3371C14.0732 18.9849 12.6638 17.8887 10.9253 17.8887C9.18672 17.8887 7.77734 18.9849 7.77734 20.3371C7.77734 21.6894 9.18672 22.7856 10.9253 22.7856Z", fill: "#38454F" }),
        React.createElement("path", { d: "M9.85156 1.81445L19.1849 4.40705L9.85156 7.51816V1.81445Z", fill: "#E64C3C" }),
        React.createElement("path", { d: "M20.7411 23.5926C21.6002 23.5926 22.2967 22.8961 22.2967 22.037C22.2967 21.1779 21.6002 20.4814 20.7411 20.4814C19.882 20.4814 19.1855 21.1779 19.1855 22.037C19.1855 22.8961 19.882 23.5926 20.7411 23.5926Z", fill: "white" }),
        React.createElement("path", { d: "M9.85156 19.9626V1.81445", stroke: "#ECF0F1", strokeWidth: "2", strokeMiterlimit: "10", strokeLinecap: "round" })));
};
