import React from 'react';
import { useTranslation } from 'react-i18next';
import { StatusScreen } from '~molecules';
import success from '~icons/statusIcons/success.svg';
var SuccessRegistrationModal = function (_a) {
    var headerClassName = _a.headerClassName, subheaderClassName = _a.subheaderClassName, onClick = _a.onClick, userEmail = _a.userEmail, t = _a.t;
    var tc = useTranslation('common').t;
    var subheader = t('status.link_was_sent', {
        email: userEmail,
    });
    return (React.createElement(StatusScreen, { isMobile: true, headerClassName: headerClassName, subheaderClassName: subheaderClassName, header: t('status.created'), subheader: subheader, logo: success, onClick: onClick, buttonLabel: tc('buttons.resend_email'), formTestId: "FRM_ACCOUNT_CREATED", buttonTestId: "BTN_RESEND_EMAIL" }));
};
export default SuccessRegistrationModal;
