import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var DartsIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, viewBox: "0 0 28 28" },
        React.createElement("path", { d: "M7.39911 7.39941C5.71063 9.0879 4.66699 11.4212 4.66699 14.0006H14.0003L7.39911 7.39941Z", fill: "#2D2D30" }),
        React.createElement("path", { d: "M7.39941 20.6012C9.0879 22.2897 11.4212 23.3333 14.0006 23.3333V14L7.39941 20.6012Z", fill: "#2D2D30" }),
        React.createElement("path", { d: "M14.0006 4.66699C11.4212 4.66699 9.0879 5.71063 7.39941 7.39911L14.0006 14.0003V4.66699Z", fill: "#D8D7DA" }),
        React.createElement("path", { d: "M4.66699 14C4.66699 16.5794 5.71063 18.9127 7.39911 20.6012L14.0003 14H4.66699Z", fill: "#D8D7DA" }),
        React.createElement("path", { d: "M14 23.3333C16.5794 23.3333 18.9127 22.2897 20.6012 20.6012L14 14V23.3333Z", fill: "#C6C5CA" }),
        React.createElement("path", { d: "M14 14.0006H23.3333C23.3333 11.4212 22.2897 9.0879 20.6012 7.39941L14 14.0006Z", fill: "#C6C5CA" }),
        React.createElement("path", { d: "M20.6012 20.6012C22.2897 18.9127 23.3333 16.5794 23.3333 14H14L20.6012 20.6012Z", fill: "#3E3D42" }),
        React.createElement("path", { d: "M20.6012 7.39911C18.9127 5.71063 16.5794 4.66699 14 4.66699V14.0003L20.6012 7.39911Z", fill: "#3E3D42" }),
        React.createElement("path", { d: "M7.39826 20.6006L4.09766 23.9012C6.6301 26.4336 10.1304 27.9994 13.9995 27.9994V23.3327C11.4201 23.3327 9.08674 22.2891 7.39826 20.6006Z", fill: "#57565C" }),
        React.createElement("path", { d: "M7.39879 7.39826L4.09818 4.09766C1.56574 6.6301 0 10.1304 0 13.9995H4.66667C4.66667 11.4201 5.7103 9.08674 7.39879 7.39826Z", fill: "#57565C" }),
        React.createElement("path", { d: "M7.39879 20.6012C5.7103 18.9127 4.66667 16.5794 4.66667 14H0C0 17.8691 1.56574 21.3694 4.09818 23.9018L7.39879 20.6012Z", fill: "#2D2D30" }),
        React.createElement("path", { d: "M7.39826 7.39879C9.08674 5.7103 11.4201 4.66667 13.9995 4.66667V0C10.1304 0 6.6301 1.56574 4.09766 4.09818L7.39826 7.39879Z", fill: "#2D2D30" }),
        React.createElement("path", { d: "M20.6012 20.6006C18.9127 22.2891 16.5794 23.3327 14 23.3327V27.9994C17.8691 27.9994 21.3694 26.4336 23.9018 23.9012L20.6012 20.6006Z", fill: "#3E3D42" }),
        React.createElement("path", { d: "M20.6006 7.39826C22.2891 9.08674 23.3327 11.4201 23.3327 13.9995H27.9994C27.9994 10.1304 26.4336 6.6301 23.9012 4.09766L20.6006 7.39826Z", fill: "#3E3D42" }),
        React.createElement("path", { d: "M23.3327 14C23.3327 16.5794 22.2891 18.9127 20.6006 20.6012L23.9012 23.9018C26.4336 21.3694 27.9994 17.8691 27.9994 14H23.3327Z", fill: "#28282D" }),
        React.createElement("path", { d: "M20.6012 7.39879L23.9018 4.09818C21.3694 1.56574 17.8691 0 14 0V4.66667C16.5794 4.66667 18.9127 5.7103 20.6012 7.39879Z", fill: "#28282D" })));
};
