import React from 'react';
import classNames from 'classnames';
import { useStyles } from './BottomButtons.styles';
var BottomButtons = function (_a) {
    var children = _a.children, _b = _a.withBackground, withBackground = _b === void 0 ? true : _b, className = _a.className;
    var classes = useStyles();
    return (React.createElement("div", { className: classNames(className, classes.controlButtons), style: { backgroundColor: withBackground ? '#212121' : 'transparent' } },
        React.createElement("div", { className: classNames('flex-row', 'space-between', 'flex-center', classes.blockMargin) }, children)));
};
export { BottomButtons };
