export var getOverrides = function (breakpoint) {
    var _a, _b, _c, _d, _e, _f;
    return {
        MuiOutlinedInput: {
            root: (_a = {
                    background: '#323232',
                    border: '1px solid #414141',
                    boxSizing: 'border-box',
                    borderRadius: '2px',
                    breakPoint: 'lg',
                    '&$error': {
                        borderColor: '#FF0000',
                    }
                },
                _a[breakpoint.up('lg')] = {
                    border: '1px solid #F2F2F2',
                    background: '#F9F9F9',
                    color: '#212121',
                },
                _a),
            notchedOutline: {
                border: 'none',
            },
            input: {
                padding: '12px 24px 12px 16px',
                '&:-webkit-autofill': {
                    '-webkit-box-shadow': '0 0 0 100px #323232 inset',
                    '-webkit-text-fill-color': '#888',
                },
            },
            inputMarginDense: {
                padding: '12px 32px 12px 16px',
            },
        },
        MuiListItem: {
            root: {
                '&$selected': {
                    color: '#2AA1F7',
                    backgroundColor: 'unset',
                },
                '&$selected i': {
                    color: '#2AA1F7',
                },
            },
        },
        MuiButton: {
            root: (_b = {
                    color: '#B4B4B4',
                    height: '44px',
                    letterSpacing: 'normal'
                },
                _b[breakpoint.up('lg')] = {
                    fontSize: 16,
                },
                _b),
            contained: (_c = {},
                _c[breakpoint.up('lg')] = {
                    background: 'white',
                    color: '#212121',
                },
                _c),
            containedPrimary: {
                backgroundColor: '#2AA1F7',
                boxShadow: 'none',
                '&:hover': {
                    backgroundColor: '#2AA1F7',
                    '@media (hover: none)': {
                        backgroundColor: '#2AA1F7',
                    }
                },
            },
            outlinedSecondary: {
                backgroundColor: '#FFFFFF',
                border: '1px solid rgba(0, 0, 0, 0.8)',
                color: '#000000',
                '&:hover': {
                    backgroundColor: '#FFFFFF',
                    border: '1px solid rgba(0, 0, 0, 0.8)',
                    color: '#000000',
                    '~media (hover: none)': {
                        backgroundColor: '#fff',
                    },
                },
            },
            outlined: {
                padding: '13px 16px',
                background: '#FFFFFF',
                border: '1px solid rgba(0, 0, 0, 0.8)',
                color: '#000000',
            },
            label: {
                textTransform: 'none',
                fontStyle: 'normal',
                fontWeight: 'bold',
                lineHeight: 1.1875,
            },
        },
        MuiInputBase: {
            root: {
                height: '48px',
                padding: 0,
                fontSize: '16px',
                lineHeight: '24px',
            },
            input: (_d = {
                    padding: '12px 32px 12px 16px',
                    height: '46px',
                    boxSizing: 'border-box',
                    color: 'rgba(255, 255, 255, 1)'
                },
                _d[breakpoint.up('lg')] = {
                    color: '#212121',
                },
                _d),
        },
        MuiCheckbox: {
            root: {
                color: '#2AA1F7',
                padding: 0,
            },
        },
        MuiInputLabel: {
            root: {
                color: 'rgba(255, 255, 255, 0.4)',
            },
            shrink: {
                display: 'none',
            },
            outlined: {
                '&$marginDense': {
                    transform: 'translate(14px, 16px) scale(1)',
                },
            },
        },
        MuiSelect: {
            root: (_e = {
                    color: '#FFFFFF',
                    padding: '12px 32px 12px 16px'
                },
                _e[breakpoint.up('lg')] = {
                    color: '#212121',
                },
                _e),
        },
        MuiSvgIcon: {
            fontSizeSmall: {
                fontSize: '16px',
            },
        },
        MuiMenu: {
            paper: (_f = {
                    background: '#323232',
                    color: '#FFFFFF'
                },
                _f[breakpoint.up('lg')] = {
                    background: '#f9f9f9',
                    color: 'rgba(0, 0, 0, 0.6)',
                },
                _f),
        },
        MuiPaper: {
            root: {
                backgroundColor: '#fff',
            },
        },
        MuiFormHelperText: {
            root: {
                '&$error': {
                    color: '#FF0000',
                },
            },
            contained: {
                marginLeft: '8px',
            },
        },
        MuiFormLabel: {
            root: {
                '&$error': {
                    color: 'rgba(255, 255, 255, 0.4)',
                },
            },
        },
        MuiTableCell: {
            sizeSmall: {
                padding: '6px 16px',
                fontSize: '0.75rem',
                lineHeight: 1.5,
            },
        },
    };
};
