import makeStyles from '@material-ui/core/styles/makeStyles';
var useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '210px',
        overflow: 'auto',
        '&::-webkit-scrollbar-track': {
            background: 'radial-gradient(100% 100% at 50.13% 0%, #3C3C46 0%, #1B1B22 100%)',
        },
    },
    lightContainer: {
        '&::-webkit-scrollbar-track': {
            background: '#ffffff',
        },
    },
}, { name: 'OptionsSelection' });
export { useStyles };
