export var initializeAvatarStyles = function (t) {
    return {
        'joker': {
            avatarName: t('ranking-list.joker'),
            background: 'linear-gradient(270deg, #2D4597 0.07%, #0291E6 225.9%)',
        },
        'gladiator': {
            avatarName: t('ranking-list.gladiator'),
            background: 'linear-gradient(271deg, #166481 1.31%, #221393 102.76%)',
        },
        'general': {
            avatarName: t('ranking-list.general'),
            background: 'linear-gradient(272deg, #142767 1.48%, #221393 108%)',
        },
        'genesis': {
            avatarName: t('ranking-list.genesis'),
            background: 'linear-gradient(270deg, #143296 0.25%, #8E1818 143.09%)',
        },
        'genius diamond': {
            avatarName: t('ranking-list.genius_diamante'),
            background: 'linear-gradient(270deg, #0D0127 -14.59%, #DE0906 141.25%)',
        },
        'genius gold': {
            avatarName: t('ranking-list.genius_gold'),
            background: 'linear-gradient(270deg, #4B0000 -23.39%, #DE0906 112.17%)',
        },
        'genius blue': {
            avatarName: t('ranking-list.genius_blue'),
            background: 'linear-gradient(270deg, #3C088E -1.63%, #CD0806 135.88%)',
        },
        'genius': {
            avatarName: t('ranking-list.genius'),
            background: 'linear-gradient(270deg, #3C088E -1.63%, #CD0806 135.88%)',
        },
        'the club': {
            avatarName: t('ranking-list.club'),
            background: 'linear-gradient(270deg, #02337C 0.07%, #01233C 225.9%)',
        },
        'billionaires': {
            avatarName: t('ranking-list.billionaires'),
            background: 'linear-gradient(270deg, #111E4B 0.07%, #000101 225.9%)',
        },
        'millionaires': {
            avatarName: t('ranking-list.millionaires'),
            background: 'linear-gradient(270deg, #04315A 0.07%, #001A29 225.9%)',
        },
    };
};
