var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import { Controller, useForm } from 'react-hook-form';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import { unwrapResult } from '@reduxjs/toolkit';
import { Button, FullscreenLoader, TextInput } from '~atoms';
import { MODALS, closeModal, openModal } from '~modals';
import { useAppDispatch } from '~store/configureStore';
import { accountInfoSelector, errorSelector, getSecurityOptions, loadingSelector, updateSecurityOptions, } from '~slices/account';
import { questionsSelector } from '~slices/locale/selectors';
import SelectBox from '~atoms/inputs/SelectBox/SelectBox';
import { useIsDesktop, useStructuredSelector } from '~hooks';
import { getPathWithLanguageCode } from '~utils/languageInURL/getPathWithLanguageCode';
import { useEditAccountStyles } from './MyAccount.styles';
import { securityQuestionValidationSchema } from './utils';
var useEditSecurityStyles = makeStyles({
    input: {
        '&::placeholder': {
            color: '#fff',
        },
    },
}, { name: 'EditSecurity' });
export var EditSecurity = function (_a) {
    var _b;
    var handleClose = _a.handleClose, titleClassName = _a.titleClassName;
    var _c = useState(true), displayEmpty = _c[0], setDisplayEmpty = _c[1];
    var editSecurityClasses = useEditSecurityStyles();
    var t = useTranslation('my-account').t;
    var tc = useTranslation('common').t;
    var classes = useEditAccountStyles();
    var _d = useStructuredSelector({
        loading: loadingSelector,
        accountInfo: accountInfoSelector,
        formError: errorSelector,
        questions: questionsSelector,
    }), loading = _d.loading, accountInfo = _d.accountInfo, formError = _d.formError, questions = _d.questions;
    var dispatch = useAppDispatch();
    var isDesktop = useIsDesktop();
    var redirectToAccountMenu = function () {
        dispatch(push(getPathWithLanguageCode('/my-account')));
    };
    var _e = useForm({
        validationSchema: securityQuestionValidationSchema,
        mode: 'onChange',
        submitFocusError: true,
        defaultValues: __assign({}, accountInfo),
    }), register = _e.register, control = _e.control, handleSubmit = _e.handleSubmit, reset = _e.reset, errors = _e.errors;
    var submit = function (values) {
        var secretAnswer = values.secretAnswer, secretQuestionId = values.secretQuestionId;
        dispatch(updateSecurityOptions({
            secretAnswer: secretAnswer,
            secretQuestionId: Number(secretQuestionId),
        }))
            .then(unwrapResult)
            .then(function () {
            return isDesktop
                ? (dispatch(closeModal({ key: MODALS.EDIT_QUESTION })),
                    dispatch(openModal({
                        modalName: MODALS.SUCCESS_MODAL_NOTIFICATION,
                        modalProps: {
                            title: t('updated_messages.security.header'),
                        },
                    })))
                : dispatch(push(getPathWithLanguageCode('/my-account/security/updated')));
        });
    };
    useEffect(function () {
        if (i18next.language) {
            dispatch(getSecurityOptions({ lang: i18next.language }));
        }
    }, [i18next.language]);
    useEffect(function () {
        reset(__assign({}, accountInfo));
        // @ts-ignore
        if (accountInfo.secretQuestionId > 0) {
            setDisplayEmpty(false);
        }
    }, [accountInfo]);
    if (loading === 'pending') {
        return React.createElement(FullscreenLoader, null);
    }
    if (loading === 'fulfilled') {
        return (React.createElement("div", { className: classNames('flex-column', classes.root) },
            React.createElement("div", { className: titleClassName || classes.title }, t('securityQuestion.title')),
            React.createElement("form", { className: classNames(classes.form, 'flex-column'), onSubmit: handleSubmit(submit) },
                React.createElement("div", { className: classes.flex },
                    React.createElement(Controller, { as: SelectBox, control: control, name: "secretQuestionId", options: questions, title: t('securityQuestion.question'), error: !!errors.secretQuestionId, onChange: function (_a) {
                            var selected = _a[0];
                            setDisplayEmpty(false);
                            return selected;
                        }, helperText: errors.secretQuestionId
                            ? errors.secretQuestionId.message
                            : '', showAsterisk: true, displayEmpty: displayEmpty, optionRenderer: function (option) { return (React.createElement(MenuItem, { classes: { root: isDesktop ? classes.modalInputes : '' }, key: option.id, value: option.id }, option.value)); }, placeholder: t('securityQuestion.select_security_question') }),
                    React.createElement(TextInput, { name: "secretAnswer", title: t('securityQuestion.answer'), placeholder: t('securityQuestion.enter_something'), inputRef: register, InputProps: {
                            classes: {
                                input: isDesktop
                                    ? classes.modalInputes
                                    : editSecurityClasses.input,
                            },
                        }, error: !!errors.secretAnswer, helperText: (_b = errors === null || errors === void 0 ? void 0 : errors.secretAnswer) === null || _b === void 0 ? void 0 : _b.message, showAsterisk: true }),
                    React.createElement(FormHelperText, { id: "component-error-text", error: !!formError }, formError)),
                React.createElement(Grid, { container: true, style: { marginTop: isDesktop ? '102px' : 'auto' }, justify: "space-between", spacing: 2 },
                    React.createElement(Grid, { key: "cancel", item: true },
                        React.createElement(Button, { size: "large", variant: "outlined", classes: { root: classes.button }, onClick: handleClose || redirectToAccountMenu, color: "secondary" }, tc('buttons.cancel'))),
                    React.createElement(Grid, { key: "next", item: true },
                        React.createElement(Button, { size: "large", variant: "contained", color: "primary", classes: { root: classes.button }, type: "submit" }, tc('buttons.done')))))));
    }
    return null;
};
