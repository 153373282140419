import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import ReactGA from 'react-ga4';
import ReactPixel from "react-facebook-pixel";
import { Button, Hint, Portal } from '~atoms';
import { useIsDesktop, useStructuredSelector } from '~hooks';
import { useAppDispatch } from '~store/configureStore';
import { setCookieAccepatation } from '~slices/auth';
import { changeHintState, selectIsCookiesConsentHintEnabled, } from '~slices/hints';
import { ZIndex } from '~constants/view/ZIndex';
import { getTestId } from '~utils/getTestId';
import { getPathWithLanguageCode } from '~utils/languageInURL/getPathWithLanguageCode';
import { useStyles } from './CookieConsent.styles';
export var CookieConsent = function () {
    var t = useTranslation('my-account').t;
    var classes = useStyles();
    var isDesktop = useIsDesktop();
    var dispatch = useAppDispatch();
    var isCookiesConsentHintEnabled = useStructuredSelector({
        isCookiesConsentHintEnabled: selectIsCookiesConsentHintEnabled,
    }).isCookiesConsentHintEnabled;
    var handleWrapperClick = useCallback(function () {
        dispatch(changeHintState({ isCookiesConsentHintEnabled: false }));
    }, []);
    var redirectToGdpr = function () {
        var pathname = '/about';
        if (isDesktop) {
            pathname = '/about/info/GDPR';
        }
        var route = {
            pathname: pathname,
            state: {
                type: 'info',
                mobileTab: 'data_security_policy',
            },
        };
        dispatch(push(getPathWithLanguageCode(route.pathname), route.state));
    };
    var acceptCookie = function () {
        ReactGA.event('accept_cookies');
        ReactPixel.trackCustom('accept_cookies');
        dispatch(setCookieAccepatation());
        dispatch(changeHintState({ isCookiesConsentHintEnabled: false }));
    };
    return (React.createElement(Portal, { display: true, zIndex: ZIndex.COOKIE_CONSENT, onWrapperClick: handleWrapperClick, transparent: !isCookiesConsentHintEnabled, enableWrapperPointerEvents: isCookiesConsentHintEnabled },
        React.createElement("div", { className: classes.wrapper, "data-testid": getTestId('FRM_COOKIE') },
            React.createElement("span", null,
                t('accept_cookie_message'),
                React.createElement("button", { type: "button", tabIndex: 0, onClick: redirectToGdpr, className: classes.link }, t('accept_cookie_more'))),
            React.createElement(Hint, { open: isCookiesConsentHintEnabled, title: t('accept_cookie_hint') },
                React.createElement(Button, { variant: "contained", onClick: acceptCookie, className: classes.acceptButton, "data-testid": getTestId('BTN_COOKIE') }, t('accept_cookie_button'))))));
};
