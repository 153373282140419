import React from 'react';
import { useStructuredSelector } from '~hooks';
import { TipDetails } from "~pages";
import { useActiveArchiveTicketSelector } from "~slices/tickets/selectors";
export var ArchiveTipDetailsBlock = function () {
    var ticketData = useStructuredSelector({
        ticketData: useActiveArchiveTicketSelector,
    }).ticketData;
    return React.createElement(React.Fragment, null, ticketData && React.createElement(TipDetails, { ticket: ticketData }));
};
