import makeStyles from '@material-ui/core/styles/makeStyles';
import { MOBILE_SPORT_CARD_WIDTH, SPORT_CARD_WIDTH, } from '~constants/view/sizes';
var CELL_WIDTH = SPORT_CARD_WIDTH;
var MOBILE_CELL_WIDTH = MOBILE_SPORT_CARD_WIDTH;
var GAP = 4;
var LEFT_PADDING = 16;
var RIGHT_PADDING = 8;
var SCROLL_SPACE = 12;
var MVEC = Number(process.env.MINIMAL_VISIBLE_EVENT_CONTENT);
var NORMALIZED_MVEC = !Number.isNaN(MVEC) && MVEC % 1 === 0 ? MVEC : 5;
var VISIBLE_LEFT_CONTENT_PADDING = 20 + NORMALIZED_MVEC;
var getContentWidth = function (cellWidth, cellCount) {
    var gapCount = cellCount - 1;
    return cellWidth * cellCount + GAP * gapCount;
};
var FOUR_COLUMN_CONTENT_WIDTH = getContentWidth(CELL_WIDTH, 4);
var MOBILE_THREE_COLUMN_CONTENT_WIDTH = getContentWidth(MOBILE_CELL_WIDTH, 3);
var MOBILE_FOUR_COLUMN_CONTENT_WIDTH = getContentWidth(MOBILE_CELL_WIDTH, 4);
var getBlockWidth = function (content) {
    return content + LEFT_PADDING + RIGHT_PADDING + SCROLL_SPACE;
};
var MOBILE_FOUR_COLUMN_BLOCK_WIDTH = getBlockWidth(MOBILE_FOUR_COLUMN_CONTENT_WIDTH);
var THREE_TO_FOUR_COLUMNS_BREAKPOINT = MOBILE_FOUR_COLUMN_BLOCK_WIDTH + VISIBLE_LEFT_CONTENT_PADDING;
export var useStyles = makeStyles(function (theme) {
    var _a, _b;
    return {
        selectContent: {
            maxHeight: '368px',
            background: 'linear-gradient(160deg, #333038 0%, #1B1B22 100%)',
            borderRadius: '4px',
            padding: "16px ".concat(RIGHT_PADDING, "px 16px ").concat(LEFT_PADDING, "px"),
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            overflow: 'hidden',
        },
        input: (_a = {
                maxHeight: '36px',
                marginBottom: '4px',
                marginRight: "".concat(SCROLL_SPACE, "px"),
                width: "".concat(MOBILE_THREE_COLUMN_CONTENT_WIDTH, "px"),
                '& input': {
                    backgroundColor: '#151515',
                    color: '#2AA1F7',
                },
                '& *': {
                    maxHeight: '36px',
                    border: 'none',
                }
            },
            _a[theme.breakpoints.up(THREE_TO_FOUR_COLUMNS_BREAKPOINT)] = {
                width: "".concat(MOBILE_FOUR_COLUMN_CONTENT_WIDTH, "px"),
            },
            _a[theme.breakpoints.up('lg')] = {
                width: "".concat(FOUR_COLUMN_CONTENT_WIDTH, "px"),
            },
            _a),
        sports: (_b = {
                display: 'grid',
                width: "".concat(MOBILE_THREE_COLUMN_CONTENT_WIDTH + SCROLL_SPACE, "px"),
                gridTemplateColumns: "repeat(auto-fit, ".concat(MOBILE_CELL_WIDTH, "px)"),
                gap: "".concat(GAP, "px"),
                overflowX: 'hidden',
                overflowY: 'auto',
                maxHeight: '296px'
            },
            _b[theme.breakpoints.up(THREE_TO_FOUR_COLUMNS_BREAKPOINT)] = {
                width: "".concat(MOBILE_FOUR_COLUMN_CONTENT_WIDTH + SCROLL_SPACE, "px"),
            },
            _b[theme.breakpoints.up('lg')] = {
                width: "".concat(FOUR_COLUMN_CONTENT_WIDTH + SCROLL_SPACE, "px"),
                gridTemplateColumns: "repeat(auto-fit, ".concat(CELL_WIDTH, "px)"),
            },
            _b),
    };
}, { name: 'CategorySelect' });
