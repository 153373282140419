var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Backdrop from '@material-ui/core/Backdrop/Backdrop';
import { PayIcon } from '~pages/top-ranking/components/PayIcon';
import { Button } from '~atoms/button';
import '../bp-list-styles.scss';
import { currencyConverter } from '~utils/converters';
import { useIsDesktop } from '~hooks';
import { LinkType } from '~slices/bp';
import { useAppDispatch } from '~store';
import { resetTemplate } from '~slices/top-ranking';
import { useStyles } from '../BPList.styles';
import { BetProviderLogo } from './BetProviderLogo';
export var itemRenderer = function (item) {
    return React.createElement(BpItem, __assign({}, item));
};
var BpItem = function (_a) {
    var _b, _c;
    var depositUrl = _a.depositUrl, advertisement = _a.advertisement, bonusUrl = _a.bonusUrl, betProviderUser = _a.betProviderUser, cashOut = _a.cashOut, bppmo = _a.bppmo, id = _a.id, welcomeBonus = _a.welcomeBonus, withdrawalUrl = _a.withdrawalUrl, backgroundColor = _a.backgroundColor, redirectToSdk = _a.redirectToSdk, selectedProviderId = _a.selectedProviderId;
    var classes = useStyles();
    var isDesktop = useIsDesktop();
    var isXLDesktop = useIsDesktop('xl');
    var t = useTranslation('bpList').t;
    var dispatch = useAppDispatch();
    var onRegisterClick = function () {
        if (redirectToSdk) {
            redirectToSdk('registration', id);
        }
    };
    var onWithdrawalClick = function () {
        if (redirectToSdk) {
            redirectToSdk('withdrawal', id);
        }
    };
    var getExternalLink = function (linkType) { return function () {
        if (redirectToSdk) {
            dispatch(resetTemplate());
            redirectToSdk('redirection-link', id, linkType);
        }
    }; };
    return (React.createElement("li", { key: id, className: classes.listItem },
        isDesktop && (React.createElement(Backdrop, { className: classes.backdrop, open: !!selectedProviderId && id !== selectedProviderId })),
        React.createElement("div", { className: classNames('flex-row', classes.container) },
            React.createElement(Button, { onClick: onWithdrawalClick, className: classes.logoButton },
                React.createElement(BetProviderLogo, { id: id, betProviderUser: betProviderUser, welcomeBonus: welcomeBonus, backgroundColor: backgroundColor })),
            React.createElement("div", { className: classNames(classes.buttonBlock, (_b = {},
                    _b[classes.buttonBlock_central] = isXLDesktop,
                    _b)) },
                React.createElement(Button, { className: classNames(classes.button, classes.bonusButton), onClick: getExternalLink(LinkType.BONUS), href: "#" }, t('bonus')),
                React.createElement("div", { className: classNames('flex-row', (_c = {},
                        _c[classes.payIcons] = isXLDesktop,
                        _c)) },
                    React.createElement(PayIcon, null),
                    cashOut && (React.createElement(PayIcon, { providedTextKey: "cash_out", iconClassName: "fal fa-wallet" })))),
            React.createElement("div", { className: classes.buttonBlock }, betProviderUser ? (React.createElement(React.Fragment, null,
                React.createElement(Button, { className: classes.button, onClick: getExternalLink(LinkType.TOP_UP) }, t('deposit')),
                React.createElement(Button, { className: classNames(classes.button, classes.withdrawal), onClick: getExternalLink(LinkType.WITHDRAW) },
                    React.createElement("div", { className: "flex-column flex-center" },
                        t('withdraw'),
                        React.createElement("div", { className: classes.withdrawalBalance }, t('max_amount', {
                            amount: currencyConverter(betProviderUser.availableWithdrawalAmount, betProviderUser.currency),
                        })))))) : (React.createElement(Button, { onClick: onRegisterClick, className: classNames(classes.button, classes.register) },
                React.createElement("div", null, t('one_click_registration')))))),
        React.createElement("div", { className: classes.maraqueWrapper },
            React.createElement("div", { className: classes.advertisement }, advertisement))));
};
