import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { RankingListItem } from "~atoms/RankingListItem";
export var useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 10,
    },
    rankingListWrapper: {
        position: 'relative',
        padding: '0 11px',
    }
}, { name: 'RankingList' });
export var RankingList = function (_a) {
    var _b, _c, _d;
    var rankingListData = _a.rankingListData;
    var classes = useStyles();
    var _e = useState(null), openedLevel = _e[0], setOpenedLevel = _e[1];
    var _f = useState(null), trendBalance = _f[0], setTrendBalance = _f[1];
    var getTrendBalance = function () {
        var _a, _b;
        var mySelfLevel = (_a = rankingListData === null || rankingListData === void 0 ? void 0 : rankingListData.myselfRanking) === null || _a === void 0 ? void 0 : _a.level;
        var mySelfBalance = (_b = rankingListData === null || rankingListData === void 0 ? void 0 : rankingListData.myselfRanking) === null || _b === void 0 ? void 0 : _b.balance;
        if (!mySelfLevel || !mySelfBalance) {
            setTrendBalance(null);
            return;
        }
        var nextLevel = rankingListData === null || rankingListData === void 0 ? void 0 : rankingListData.levelRankings.find(function (item) { return (item === null || item === void 0 ? void 0 : item.level) === mySelfLevel + 1; });
        (nextLevel === null || nextLevel === void 0 ? void 0 : nextLevel.minBalance) ? setTrendBalance(nextLevel.minBalance - mySelfBalance) : setTrendBalance(null);
    };
    useEffect(function () {
        getTrendBalance();
    }, [(_b = rankingListData === null || rankingListData === void 0 ? void 0 : rankingListData.myselfRanking) === null || _b === void 0 ? void 0 : _b.level, (_c = rankingListData === null || rankingListData === void 0 ? void 0 : rankingListData.myselfRanking) === null || _c === void 0 ? void 0 : _c.balance]);
    useEffect(function () {
        var _a, _b;
        var highestLevel = (rankingListData === null || rankingListData === void 0 ? void 0 : rankingListData.levelRankings) && ((_a = rankingListData === null || rankingListData === void 0 ? void 0 : rankingListData.levelRankings[0]) === null || _a === void 0 ? void 0 : _a.level) ? (_b = rankingListData === null || rankingListData === void 0 ? void 0 : rankingListData.levelRankings[0]) === null || _b === void 0 ? void 0 : _b.level : null;
        setOpenedLevel(highestLevel);
    }, [rankingListData === null || rankingListData === void 0 ? void 0 : rankingListData.levelRankings]);
    return (React.createElement("div", { className: classes.container }, (_d = rankingListData === null || rankingListData === void 0 ? void 0 : rankingListData.levelRankings) === null || _d === void 0 ? void 0 : _d.map(function (item, index) {
        var _a, _b, _c;
        if (index !== (rankingListData === null || rankingListData === void 0 ? void 0 : rankingListData.levelRankings.length) - 1) {
            return (React.createElement("div", { className: classes.rankingListWrapper, key: index },
                React.createElement(RankingListItem, { key: index, level: item.level, count: (_a = item.ranks) === null || _a === void 0 ? void 0 : _a.length, winAmount: item.winAmount, currentUserRank: (_b = rankingListData === null || rankingListData === void 0 ? void 0 : rankingListData.myselfRanking) === null || _b === void 0 ? void 0 : _b.rank, currentUserLevel: (_c = rankingListData === null || rankingListData === void 0 ? void 0 : rankingListData.myselfRanking) === null || _c === void 0 ? void 0 : _c.level, avatar: item.expLevelName || 'Joker', avatarUrl: item.avatarPath || 'avatars/joker.svg', stars: item.expSubLevel, place: item.place, ranks: item.ranks, openedLevel: openedLevel, trendBalance: trendBalance, onItemListClick: setOpenedLevel })));
        }
    })));
};
