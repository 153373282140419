import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '~atoms';
import { checkIfFieldIsRequired, minLengthValidation, required, validateField, } from '~utils/formValidators';
import { normalizeTextFieldValue } from '~utils/fieldNormalization';
export var SurName = function (_a) {
    var tabIndex = _a.tabIndex, registrationBlocks = _a.registrationBlocks;
    var t = useTranslation('registration').t;
    return (React.createElement(TextField, { name: "userProfileDto.surname", title: t('form.surname'), placeholder: t('form.enter_surname'), validators: [
            function (value) {
                return validateField(value, 'surname', tabIndex, required, registrationBlocks);
            },
            function (value) {
                return validateField(value, 'surname', tabIndex, minLengthValidation, registrationBlocks, 50);
            },
        ], required: checkIfFieldIsRequired('surname', tabIndex, registrationBlocks), normalize: normalizeTextFieldValue }));
};
