import makeStyles from '@material-ui/core/styles/makeStyles';
export var useStyles = makeStyles({
    wrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: '63px',
        maxHeight: '145px',
        marginTop: '8px',
        borderRadius: '14px',
        background: 'linear-gradient(270deg, #00A5FF -39.68%, #0C104A 57.49%, #0C0F49 78.73%)',
        boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.16) inset',
    },
    greenBorder: {
        border: '3px solid #6CFF00',
    },
    greenText: {
        color: '#6CFF00',
    },
    whiteText: {
        color: '#FFFFFF',
    },
    blockWrapper: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    userName: {
        fontStyle: 'Rowdies',
        fontWeight: 700,
        fontSize: '12px',
        color: '#FFFFFF',
        textTransform: 'capitalize',
    },
    centerBlock: {
        width: '100%',
        maxWidth: '220px',
        display: 'flex',
        flexDirection: 'column',
        padding: '18px 24px 18px 33px',
        '@media (max-width:376px)': {
            padding: '3px 6px',
        },
    },
    level: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '13px',
        fontSize: '25px',
        fontWeight: 700,
        textTransform: 'uppercase',
        '& span': {
            marginRight: '5px',
        },
        '@media (max-width:420px)': {
            flexDirection: 'column',
            marginBottom: '5px',
        },
    },
    avatarName: {
        fontSize: '25px',
        fontWeight: 700,
        color: '#FFFFFF',
    },
    divider: {
        width: '100%',
        height: '1px',
        backgroundColor: '#FFFFFF',
    },
    credits: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '5px 0',
        fontWeight: 700,
        color: '#FFFFFF',
        '& span:first-child': {
            fontSize: '12px',
            fontWeight: 400,
        },
        '& img': {
            marginLeft: '3px',
        },
        '& svg': {
            marginLeft: '3px',
        },
        '& div': {
            marginLeft: '3px',
        },
        '@media (max-width:340px)': {
            fontWeight: 400,
            fontSize: '12px',
        },
    },
    balance: {
        minHeight: '30px',
        marginTop: '8px',
        fontSize: '12px',
        color: '#FFFFFF',
        '& div': {
            display: 'flex',
            justifyContent: 'space-between',
            '& span': {
                display: 'flex',
                alignItems: 'center',
                '& svg': {
                    width: '11px',
                    height: '11px',
                    marginLeft: '5px',
                },
                '& img': {
                    width: '16px',
                    height: '16px',
                    marginLeft: '5px',
                },
            },
        },
        '& div:first-child': {
            marginBottom: '5px',
        },
    },
    jackpotTitle: {
        textAlign: 'end',
        fontSize: '9px',
        fontWeight: 600,
        color: '#FFFFFF',
    },
    progressWrapper: {
        height: '70.5px',
        width: '7px',
    },
    rightBlock: {
        position: 'relative',
        height: '145px',
        width: '43px',
    },
    rightBlockBackground: {
        height: '100%',
        width: '43px',
        borderRadius: '0 14px 14px 0',
        background: 'linear-gradient(90deg, #000 0%, rgba(36, 36, 36, 0.58) 50%, rgba(84, 84, 84, 0.00) 100%)',
        opacity: '0.1225',
    },
    rightBlockWrapper: {
        position: 'absolute',
        top: 0,
        width: '43px',
        minHeight: '145px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '& img': {
            marginTop: '15px',
        },
    },
    listWrapper: {
        maxHeight: '710px',
        overflowY: 'auto',
        marginTop: '6px',
    },
    globalListWrapper: {
        maxHeight: '460px',
        overflowY: 'auto',
        marginTop: '6px',
    },
    nestedWrapper: {
        display: 'flex',
        padding: '10px 0 13px 10px',
        marginTop: '3px',
        background: 'linear-gradient(0deg, #FFF 0%, #1C1C23 0%, #000 15.76%, #5D5D65 100%)',
        boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.92) inset',
        borderRadius: '18px',
    },
    nestedCenterBlock: {
        width: '100%',
        maxWidth: '193px',
        padding: '0 33px',
        '@media (max-width:412px)': {
            paddingRight: '6px',
        },
        '@media (max-width:376px)': {
            padding: '3px 6px',
        },
        '@media (max-width:340px)': {
            padding: '0 10px',
        },
    },
    nestedRank: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '14px',
        fontSize: '25px',
        fontWeight: 700,
        color: '#FFFFFF',
        '& span:first-child': {
            marginRight: '5px',
        },
        '@media (max-width:340px)': {
            flexDirection: 'column',
            fontSize: '16px',
        },
    },
    nestedCredits: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '10px 0',
        fontSize: '12px',
        color: '#FFFFFF',
        '@media (max-width:340px)': {
            fontSize: '8px',
        },
    },
    nestedBalance: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '16px',
        fontWeight: 700,
        '& >svg': {
            marginLeft: '8px',
        },
        '@media (max-width:340px)': {
            fontSize: '12px',
            '& >svg': {
                marginLeft: '3px',
            },
        },
    },
    jackpotAmount: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '10px',
        fontSize: '12px',
        fontWeight: 900,
        color: '#FFFFFF',
        '& >div': {
            fontSize: '12px',
            marginLeft: '3px',
        },
        '& >svg': {
            marginLeft: '3px',
        },
    },
    unlocked: {
        marginLeft: '4px',
    },
    pointer: {
        cursor: 'pointer',
    },
    jackpotCount: {
        marginRight: '3px',
    },
    goldText: {
        color: '#F4B71B',
    },
    silverText: {
        color: '#C9C8C8',
    },
    bronzeText: {
        color: '#F87F2B',
    },
    goldBorder: {
        border: '1px solid #F4B71B',
    },
    silverBorder: {
        border: '1px solid #C9C8C8',
    },
    bronzeBorder: {
        border: '1px solid #F87F2B',
    },
    lockedItem: {
        opacity: 0.2465,
    },
    globalListBackground: {
        height: '47px',
        background: 'linear-gradient(0deg, #000 0%, rgba(36, 36, 36, 0.58) 50%, rgba(84, 84, 84, 0.00) 100%)',
        opacity: '0.189'
    },
    globalListNickname: {
        width: '110px',
        position: 'absolute',
        bottom: '50%',
        right: '50%',
        transform: 'translate(50%, 50%)',
        fontWeight: 700,
        color: '#FFFFFF',
        textAlign: 'center',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    globalListStars: {
        height: '40px',
        padding: '0 20px',
        '& div > div': {
            width: '10px',
            height: '10px',
        },
        '@media (max-width:376px)': {
            padding: 0,
        },
    },
    nicknameWrapper: {
        height: '88px',
        width: '123px',
        minWidth: '123px',
        borderRadius: '17px',
    },
    globalListRank: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '8px 0',
        fontSize: '25px',
        fontWeight: 700,
        color: '#FFFFFF',
        '& span:first-child': {
            marginRight: '5px',
        },
        '& div': {
            display: 'flex',
            alignItems: 'center',
        },
        '@media (max-width:340px)': {
            flexDirection: 'column',
            fontSize: '16px',
        },
    },
    zeroPadding: {
        padding: 0,
    },
    borderRadius14: {
        borderRadius: '14px',
    },
    smallLoader: {
        position: 'absolute',
        minHeight: '470px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        background: 'rgba(15,15,15,0.5)',
        zIndex: 10,
    },
    paddingTop20: {
        paddingTop: '20px',
    },
    paddingBottom20: {
        paddingBottom: '20px',
    },
    stickyBottom: {
        position: 'sticky',
        bottom: 0,
    },
}, { name: 'RankingListCartoonItem' });
