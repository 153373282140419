import makeStyles from '@material-ui/core/styles/makeStyles';
export var useAdvertisingStyles = makeStyles({
    advertisingBlock: {
        background: '#151515',
        color: '#8E8E8E',
        display: 'flex',
        borderRadius: '4px',
        flexDirection: 'column',
        fontSize: '42px',
        fontWeight: 'bold',
        marginTop: '24px',
        height: '467px',
        width: '440px',
    },
    header: {
        fontSize: '18px',
        lineHeight: '18px',
        color: '#8E8E8E',
        textAlign: 'center',
        paddingTop: '16px',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: '8px',
        background: '#151515',
        height: '433px',
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    item: {
        margin: '8px',
        width: '196px',
        height: '180px',
        backgroundColor: '#323232',
        borderRadius: '4px',
        padding: '20px',
        fontSize: '30px',
        textAlign: 'center',
    },
}, { name: 'AdvertisingBanners' });
