import makeStyles from '@material-ui/core/styles/makeStyles';
var useStyles = makeStyles(function (theme) {
    var _a, _b, _c;
    return ({
        wrapper: (_a = {
                backgroundColor: '#26272d',
                position: 'fixed',
                left: 0,
                bottom: 0,
                height: '70px',
                width: '100%',
                display: 'grid',
                gridTemplateColumns: '1fr max-content',
                placeItems: 'center',
                zIndex: 5,
                color: '#e2e2e2',
                padding: '0 30px',
                boxSizing: 'border-box'
            },
            _a[theme.breakpoints.down('sm')] = {
                padding: '0 8px',
                fontSize: 12,
            },
            _a),
        text: {},
        link: (_b = {
                fontWeight: 'normal',
                fontSize: 16,
                lineHeight: '18px',
                backgroundColor: 'transparent',
                outline: 'none',
                border: 'none',
                color: '#2aa1f6',
                textDecoration: 'underline',
                cursor: 'pointer'
            },
            _b[theme.breakpoints.down('sm')] = {
                fontSize: 12,
            },
            _b),
        acceptButton: (_c = {
                backgroundColor: '#151515',
                border: '1px solid #FFFFFF',
                borderRadius: '4px',
                color: '#FFFFFF',
                display: 'inline-block',
                marginLeft: '4px',
                minWidth: '10.5vw',
                '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.08)',
                }
            },
            _c[theme.breakpoints.down('sm')] = {
                fontSize: 12,
            },
            _c),
    });
}, { name: 'CookieConsent' });
export { useStyles };
