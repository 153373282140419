import React from 'react';
import classNames from 'classnames';
import FormHelperText from '@material-ui/core/FormHelperText';
import MaterialCheckbox from '@material-ui/core/Checkbox';
import makeStyles from '@material-ui/core/styles/makeStyles';
export var useStyles = makeStyles(function (theme) { return ({
    checkbox: {
        '& .title': {
            marginLeft: '14px',
            fontFamily: 'Roboto, sans-serif',
            textAlign: 'left',
            fontSize: '14px',
            lineHeight: '21px',
            letterSpacing: '0.15px',
            opacity: '0.8',
            userSelect: 'none',
        },
        '& .checkbox': {
            display: 'flex',
            alignItems: 'flex-start',
        },
        '& .MuiCheckbox-root': {
            padding: 0,
        },
        ' & .MuiCheckbox-colorPrimary.Mui-checked': {
            color: theme.variables.mainBlueColor.color,
        },
        '& .MuiFormHelperText-root': {
            color: theme.variables.mainRedColor.color,
        },
    },
}); }, { name: 'Checkbox' });
function Checkbox(_a) {
    var checked = _a.checked, title = _a.title, required = _a.required, onChange = _a.onChange, error = _a.error, helperText = _a.helperText, className = _a.className;
    var classes = useStyles();
    return (React.createElement("div", { className: classNames(classes.checkbox, className) },
        React.createElement("div", { className: "checkbox" },
            React.createElement(MaterialCheckbox, { checked: checked, onChange: onChange, checkedIcon: React.createElement("i", { className: "far fa-check-square" }), icon: React.createElement("i", { className: "far fa-square" }), style: { fontSize: '18px' }, color: "primary", required: required }),
            title && (React.createElement("div", { className: "title" },
                title,
                " ",
                required && '*'))),
        error && React.createElement(FormHelperText, null, helperText)));
}
export default Checkbox;
