import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useAutoScroll } from '~hooks';
import { Button } from '~atoms';
import { AnimationDuration } from '~constants/view/AnimationDuration';
import { scrollParentAtom } from './scrollParentAtom';
import { useStyles } from './Option.styles';
var Option = function (_a) {
    var _b;
    var _c = _a.active, active = _c === void 0 ? false : _c, _d = _a.palette, palette = _d === void 0 ? 'dark' : _d, onClick = _a.onClick, children = _a.children;
    var classes = useStyles();
    var _e = useAutoScroll(active, { threshold: 30, delay: AnimationDuration.SHORT.ms }), elementRef = _e.elementRef, scrollParentRef = _e.scrollParentRef;
    useEffect(function () {
        scrollParentRef.current = scrollParentAtom.getValue();
    }, []);
    return (React.createElement(Button, { ref: elementRef, className: classNames(classes.item, (_b = {},
            _b[classes.active] = active,
            _b[classes.light] = palette === 'light',
            _b)), onClick: onClick }, children));
};
export { Option };
