import { createSlice } from '@reduxjs/toolkit';
import { createAsyncReducers } from '~utils/createAsyncReducers';
import { name } from './constants';
import { getIdsMap } from './thunks';
import { getInitialState } from './getInitialState';
var idsMap = createSlice({
    name: name,
    initialState: getInitialState(),
    reducers: {},
    extraReducers: function (builder) {
        var _a = createAsyncReducers(), pending = _a.pending, fulfilled = _a.fulfilled, rejected = _a.rejected;
        builder
            .addCase(getIdsMap.pending, pending)
            .addCase(getIdsMap.fulfilled, fulfilled)
            .addCase(getIdsMap.rejected, rejected);
    },
});
export { idsMap, getInitialState };
