import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var LolIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("svg", { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("circle", { cx: "13.5", cy: "14.5", r: "13", stroke: "black" }),
            React.createElement("circle", { cx: "13.5", cy: "14.5", r: "12", stroke: "#DCB71E" }),
            React.createElement("circle", { cx: "13.5", cy: "14.5", r: "11", fill: "#06AFF7", stroke: "black" }),
            React.createElement("circle", { cx: "13", cy: "16", r: "8", fill: "#06DAF7" }),
            React.createElement("path", { d: "M10.5 0.5H1.5L4 4V23.4718L1 27.5H21L24.5 23H10.5V0.5Z", fill: "#DCB71E", stroke: "black" }),
            React.createElement("path", { d: "M4.75 3.8V3.71988L4.70343 3.65469L2.9858 1.25H9.75V23.5V23.75H10H22.9798L20.5798 26.75H2.4858L4.70343 23.6453L4.75 23.5801V23.5V3.8Z", stroke: "#FCE45B", strokeWidth: "0.5" }))));
};
