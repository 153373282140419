var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import './list-body.scss';
export var ListBody = function (_a) {
    var items = _a.items, renderItem = _a.renderItem, redirectToSdk = _a.redirectToSdk, selectedProviderId = _a.selectedProviderId;
    return (React.createElement("div", { className: "list-body" },
        React.createElement("ul", null, items.map(function (item, index) {
            return renderItem(__assign(__assign({}, item), { selectedProviderId: selectedProviderId, redirectToSdk: redirectToSdk }), index);
        }))));
};
