import React from 'react';
import classnames from 'classnames';
import { AnimationDuration } from '~constants/view/AnimationDuration';
import { TournamentLabel } from '~atoms';
import { useStyles } from './Item.styles';
import { useTournamentsSelectAutoscroll } from './useTournamentsSelectAutoscroll';
var Item = function (_a) {
    var _b;
    var _c = _a.small, small = _c === void 0 ? false : _c, _d = _a.active, active = _d === void 0 ? false : _d, label = _a.label, className = _a.className, onClick = _a.onClick, children = _a.children, autoScrollDelay = _a.autoScrollDelay;
    var classes = useStyles();
    var cn = classnames(classes.container, className, (_b = {},
        _b[classes.active] = active,
        _b[classes.small] = small,
        _b));
    var elementRef = useTournamentsSelectAutoscroll(active, { threshold: 36, delay: autoScrollDelay ? AnimationDuration.SHORT.ms : 0 }).elementRef;
    if (typeof onClick === 'function') {
        return (React.createElement("div", { ref: elementRef, className: cn, tabIndex: 0, role: "button", onClick: onClick },
            children,
            React.createElement(TournamentLabel, { label: label, shifted: true })));
    }
    return (React.createElement("div", { ref: elementRef, className: cn },
        children,
        React.createElement(TournamentLabel, { label: label, shifted: true })));
};
export { Item };
