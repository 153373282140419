import makeStyles from '@material-ui/core/styles/makeStyles';
var useStyles = makeStyles(function () { return ({
    mainBoardButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '4px',
        marginBottom: '6px',
        width: '44px',
        height: '44px',
        backgroundColor: '#484848',
        fontSize: '14px',
    },
    addCountButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '4px',
        width: '59px',
        height: '34px',
        borderRadius: '4px',
        backgroundColor: '#8E8E8E',
        color: '#FFF',
        fontSize: '14px',
    },
    minusPlusButton: {
        display: 'flex',
        width: '92px',
        height: '34px',
    },
    minusButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '50%',
        fontSize: '14px',
        backgroundColor: '#E73C45',
        borderRadius: '4px 0 0 4px',
    },
    plusButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '50%',
        fontSize: '14px',
        backgroundColor: '#4CAF50',
        borderRadius: '0 4px 4px 0',
    },
}); }, { name: 'BetslipKeyboardButtons' });
export { useStyles };
