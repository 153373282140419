var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { createSelector } from 'reselect';
import { FullscreenLoader } from '~atoms';
import { useStructuredSelector } from '~hooks';
import { MODALS_VIEWS } from './modal-views';
import { MODALS } from './modals';
export var useModalDataSelector = createSelector(function (state) { return state.modal; }, function (modal) { return modal; });
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export var RootModal = function () {
    var modals = useStructuredSelector({
        modals: useModalDataSelector,
    }).modals;
    var renderModal = function (modalName) {
        if (!modals[modalName].isVisible) {
            return null;
        }
        return React.createElement(MODALS_VIEWS[MODALS[modalName]], __assign(__assign({}, modals[modalName]), { modalName: modalName, key: modalName }));
    };
    return (React.createElement(React.Suspense, { fallback: React.createElement(FullscreenLoader, { delay: 500 }) }, Object.keys(modals).map(renderModal)));
};
