import React from 'react';
import { Currencies } from '~utils/converters';
import { useStyles } from './Currency.styles';
var Currency = function (_a) {
    var _b;
    var currency = _a.currency;
    var classes = useStyles();
    return (React.createElement("div", { className: classes.currency }, (_b = Currencies[currency]) !== null && _b !== void 0 ? _b : currency));
};
export { Currency };
