import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var CurlingIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0)" },
            React.createElement("path", { d: "M22.2068 12.658V13.5173L13.9999 14.4829L5.79297 13.5173V12.5518C5.79297 11.2187 6.87366 10.138 8.20676 10.138H17.9585C18.3711 10.1379 18.7603 9.94618 19.0118 9.61904C19.2633 9.2919 19.3486 8.8665 19.2426 8.4677C18.5977 6.03544 16.3955 4.34263 13.8792 4.34494H8.03297C7.39684 4.35839 6.85127 3.89373 6.76331 3.26357C6.72772 2.92319 6.83831 2.58371 7.06756 2.3296C7.2968 2.0755 7.62315 1.93067 7.96538 1.93115H13.8695C17.4802 1.93152 20.6404 4.35713 21.5744 7.84494C21.9941 9.41487 22.2067 11.033 22.2068 12.658Z", fill: "#E0E0E2" }),
            React.createElement("path", { d: "M23.6552 13.5173H4.34483C1.94525 13.5173 0 15.4626 0 17.8622V21.7242C0 24.1238 1.94525 26.0691 4.34483 26.0691H23.6552C26.0548 26.0691 28 24.1238 28 21.7242V17.8622C28 15.4626 26.0548 13.5173 23.6552 13.5173Z", fill: "#E64C3C" }),
            React.createElement("path", { d: "M28 21.5572V19.7786V18H0V21.5572H28Z", fill: "#FB7B76" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0" },
                React.createElement("rect", { width: "28", height: "28", fill: "white" })))));
};
