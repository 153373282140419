import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var AmericanFootballIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { viewBox: "0 0 28 28", className: className },
        React.createElement("g", { clipPath: "url(#clip0)" },
            React.createElement("path", { d: "M27.4778 0.521484L22.835 5.16494L27.5474 9.87741C28.1725 6.7912 28.1493 3.59978 27.4778 0.521484Z", fill: "#EE8700" }),
            React.createElement("path", { d: "M18.6398 9.36078C18.6401 9.36107 20.7839 11.5049 20.7839 11.5049L19.1842 13.1046L17.8398 11.7602L15.6981 13.9022L17.0421 15.2461L15.4424 16.8458L14.0985 15.5019L11.7602 17.8398L13.1046 19.1842L11.5049 20.7839L9.36106 18.6401C9.36078 18.6398 5.16504 22.8355 5.16504 22.8355L9.87751 27.548C14.0888 26.6956 18.105 24.636 21.3705 21.3705C24.636 18.105 26.6955 14.0888 27.548 9.87751L22.8355 5.16504L18.6398 9.36078Z", fill: "#FFA733" }),
            React.createElement("path", { d: "M27.479 0.52159C24.4007 -0.149842 21.2093 -0.173034 18.123 0.452014L22.8361 5.16504L27.479 0.52159Z", fill: "#FF9811" }),
            React.createElement("path", { d: "M9.36092 18.6399L7.21624 16.4952L8.81591 14.8956L10.1604 16.2401L12.4987 13.9021L11.1543 12.5577L12.754 10.958L14.0984 12.3024L16.2401 10.1604L14.8956 8.81592L16.4952 7.21624L18.6399 9.36092L22.8356 5.16518L18.1226 0.452148C13.9113 1.30459 9.89518 3.36414 6.62966 6.62966C3.36414 9.89518 1.30459 13.9113 0.452148 18.1226L5.16518 22.8357L9.36092 18.6399Z", fill: "#FFB655" }),
            React.createElement("path", { d: "M0.521484 27.4788C3.59977 28.1502 6.7912 28.1734 9.87741 27.5484L5.16494 22.8359L0.521484 27.4788Z", fill: "#EE8700" }),
            React.createElement("path", { d: "M0.452015 18.1221C-0.173034 21.2083 -0.149842 24.3997 0.52159 27.478L5.16505 22.8351L0.452015 18.1221Z", fill: "#FF9811" }),
            React.createElement("path", { d: "M17.0407 10.9607L19.1846 13.1045L20.7842 11.5048L18.6404 9.36101L17.0407 10.9607Z", fill: "#FFF5CC" }),
            React.createElement("path", { d: "M14.8951 8.81549L16.2396 10.16L14.098 12.302L12.7536 10.9576L11.1539 12.5573L12.4983 13.9017L10.16 16.2396L8.81549 14.8951L7.21582 16.4948L9.3605 18.6395C9.36078 18.6398 10.9604 17.0401 10.9604 17.0401L11.7599 17.8395L14.0982 15.5016L13.2983 14.7017L14.898 13.102L15.6979 13.9019L17.8396 11.7599L17.0402 10.9605L18.6398 9.36078C18.6395 9.3605 16.4949 7.21582 16.4949 7.21582L14.8951 8.81549Z", fill: "white" }),
            React.createElement("path", { d: "M13.2988 14.7022L15.4427 16.846L17.0423 15.2464L15.6984 13.9024L14.8985 13.1025L13.2988 14.7022Z", fill: "#FFF5CC" }),
            React.createElement("path", { d: "M9.36106 18.6404L11.5049 20.7842L13.1045 19.1845L10.9607 17.0407L9.36106 18.6404Z", fill: "#FFF5CC" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0" },
                React.createElement("rect", { width: "28", height: "28", fill: "white" })))));
};
