import { LayoutOptions } from '~constants/view/LayoutOptions';
var namedHeaders = [
    LayoutOptions.OPTION_1,
    LayoutOptions.OPTION_2,
    LayoutOptions.OPTION_3,
    LayoutOptions.OPTION_4,
    LayoutOptions.OPTION_7,
    LayoutOptions.OPTION_10,
    LayoutOptions.OPTION_11,
];
var getIsHeaderNamed = function (optionName) {
    return namedHeaders.includes(optionName);
};
export { getIsHeaderNamed };
