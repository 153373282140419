import React, { useRef, useState, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsDesktop } from '~hooks';
import { Button } from '~atoms/button';
import { useVideoPlayerStyles } from './VideoPlayer.styles';
import { DesktopVideoPlayer } from './DesktopVideoPlayer';
export var VideoPlayer = memo(function (_a) {
    var sources = _a.sources, autoplay = _a.autoplay;
    var desktop = useIsDesktop();
    var t = useTranslation('errors').t;
    var _b = useState(autoplay), showControls = _b[0], setShowControls = _b[1];
    var videoRef = useRef(null);
    var videoPlayerClasses = useVideoPlayerStyles();
    var onPlayClick = useCallback(function () {
        if (videoRef.current) {
            videoRef.current.play();
            setShowControls(true);
        }
    }, [videoRef.current]);
    var onVideoEnded = useCallback(function () {
        setShowControls(false);
    }, []);
    return (React.createElement(React.Fragment, null, desktop ? (React.createElement(DesktopVideoPlayer, { sources: sources, autoplay: autoplay })) : (React.createElement("div", { className: videoPlayerClasses.root },
        React.createElement("div", null,
            React.createElement("video", { ref: videoRef, className: "video", controls: showControls, muted: true, playsInline: true, autoPlay: autoplay, onEnded: onVideoEnded },
                sources.map(function (source) { return (React.createElement("source", { key: source.url, src: source.url, type: source.type })); }),
                React.createElement("track", { default: true, kind: "captions" }),
                t('no_video_support')),
            !showControls && (React.createElement("div", { className: videoPlayerClasses.controls },
                React.createElement(Button, { type: "button", className: videoPlayerClasses.actionButton, onClick: onPlayClick, "aria-label": "marketing video" }))))))));
});
