import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var BobsleighIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0)" },
            React.createElement("path", { d: "M11.3811 5.61352C11.3811 6.9015 10.337 7.94552 9.04928 7.94552C7.7613 7.94552 6.71729 6.9015 6.71729 5.61352C6.71729 4.32576 7.7613 3.28174 9.04928 3.28174C10.337 3.28174 11.3811 4.32576 11.3811 5.61352Z", fill: "#C9CED1" }),
            React.createElement("path", { d: "M10.0877 3.53613L9.24812 4.69215C9.07383 4.93286 9.00545 5.23434 9.05863 5.52692C9.11181 5.81929 9.2822 6.07736 9.53029 6.24102L10.8306 7.09967C11.2914 6.55705 11.4757 5.83188 11.3299 5.13515C11.184 4.43841 10.7243 3.84825 10.0844 3.53613H10.0877Z", fill: "#586270" }),
            React.createElement("path", { d: "M17.4446 8.41186C17.4446 9.69984 16.4005 10.7439 15.1126 10.7439C13.8248 10.7439 12.7808 9.69984 12.7808 8.41186C12.7808 7.12409 13.8248 6.08008 15.1126 6.08008C16.4005 6.08008 17.4446 7.12409 17.4446 8.41186Z", fill: "#C9CED1" }),
            React.createElement("path", { d: "M16.1507 6.33398L15.3113 7.49022C15.1368 7.73093 15.0684 8.03241 15.1216 8.32478C15.175 8.61715 15.3454 8.87522 15.5935 9.03888L16.8936 9.89753C17.3544 9.35491 17.5387 8.62995 17.3928 7.93322C17.247 7.23627 16.7873 6.6461 16.1474 6.33398H16.1507Z", fill: "#586270" }),
            React.createElement("path", { d: "M23.0402 11.6765C23.0402 12.9645 21.9962 14.0085 20.7085 14.0085C19.4205 14.0085 18.3765 12.9645 18.3765 11.6765C18.3765 10.3887 19.4205 9.34473 20.7085 9.34473C21.9962 9.34473 23.0402 10.3887 23.0402 11.6765Z", fill: "#C9CED1" }),
            React.createElement("path", { d: "M21.7473 9.59863L20.9078 10.7549C20.7335 10.9956 20.6651 11.2971 20.7183 11.5894C20.7715 11.8818 20.9419 12.1399 21.19 12.3037L22.4903 13.1622C22.9511 12.6196 23.1354 11.8946 22.9895 11.1979C22.8437 10.5009 22.384 9.91075 21.7441 9.59863H21.7473Z", fill: "#586270" }),
            React.createElement("path", { d: "M9.17428 7.93309C9.13174 7.93526 9.09202 7.94568 9.04926 7.94568C7.9627 7.94459 7.02135 7.19273 6.7802 6.1333C5.96669 6.41156 5.24608 6.90969 4.69824 7.57257L9.17884 10.2607C9.28585 9.48848 9.28433 8.70492 9.17428 7.93309Z", fill: "#DC6C33" }),
            React.createElement("path", { d: "M13.0096 9.4043C11.9187 9.60876 10.8951 10.0785 10.0288 10.7722L14.2309 13.2934C14.5801 12.4754 14.7926 11.6054 14.8601 10.7185C14.0564 10.6324 13.3555 10.1347 13.0096 9.4043Z", fill: "#DC6C33" }),
            React.createElement("path", { d: "M20.2039 13.9501C19.2615 13.7387 18.5478 12.9669 18.4106 12.0107C17.3692 12.228 16.1183 13.4099 15.4995 14.0541L20.0379 16.7772C20.215 15.8458 20.2708 14.8956 20.2039 13.9501Z", fill: "#DC6C33" }),
            React.createElement("path", { d: "M21.6416 5.1472C21.5726 5.14698 21.5047 5.13092 21.4426 5.10075L12.5813 0.903197C12.4163 0.841554 12.3002 0.692006 12.2811 0.517062C12.262 0.341902 12.3432 0.170865 12.491 0.0751458C12.6388 -0.0207909 12.8278 -0.0251319 12.98 0.063642L21.8413 4.2612C22.0365 4.35562 22.1406 4.57202 22.0931 4.78342C22.0454 4.99483 21.8585 5.14568 21.6416 5.1472Z", fill: "#95C2D5" }),
            React.createElement("path", { d: "M23.9734 4.26346C23.9044 4.26281 23.8362 4.24675 23.7742 4.2168L19.5768 2.22817C19.3502 2.11444 19.2558 1.84052 19.3643 1.61153C19.4728 1.38232 19.7446 1.28205 19.976 1.38536L24.1735 3.37399C24.3726 3.46645 24.4802 3.68524 24.432 3.89947C24.3839 4.11348 24.1929 4.2652 23.9734 4.26346Z", fill: "#95C2D5" }),
            React.createElement("path", { d: "M24.6317 26.6341C24.2454 26.6341 23.866 26.5313 23.5328 26.3357L3.21679 14.4107C3.06767 14.3291 2.97456 14.173 2.97412 14.0028C2.97369 13.8329 3.06572 13.6762 3.2144 13.5937C3.36308 13.5112 3.54475 13.5162 3.68866 13.6067L24.0044 25.5311C24.4915 25.8163 25.1097 25.7373 25.5095 25.3386C25.6925 25.1619 25.9833 25.1643 26.1632 25.3442C26.3432 25.5241 26.3456 25.815 26.1689 25.998C25.7613 26.4056 25.2082 26.6346 24.6317 26.6341Z", fill: "#394F63" }),
            React.createElement("path", { d: "M5.38423 15.6087C5.20321 15.6087 5.03869 15.5041 4.96207 15.3402C4.88523 15.1764 4.91019 14.983 5.0261 14.8438L6.35901 13.2437C6.52375 13.0458 6.81807 13.0188 7.01602 13.1836C7.21419 13.3485 7.24111 13.6427 7.07636 13.8408L5.7428 15.4405C5.65446 15.5473 5.52293 15.6092 5.38423 15.6087Z", fill: "#394F63" }),
            React.createElement("path", { d: "M12.286 19.66C12.1246 19.6597 11.9746 19.576 11.8899 19.4386C11.8051 19.3012 11.7972 19.1297 11.8695 18.9851L12.7655 17.1927C12.8814 16.9624 13.1621 16.8695 13.3926 16.9854C13.6229 17.1014 13.7158 17.3822 13.5999 17.6125L12.7039 19.4045C12.6242 19.5619 12.4625 19.6606 12.286 19.66Z", fill: "#394F63" }),
            React.createElement("path", { d: "M19.5749 23.9385C19.4251 23.9383 19.2845 23.8662 19.1968 23.7446C19.1093 23.6231 19.0854 23.4668 19.1328 23.3246L19.7195 21.5639C19.7722 21.4057 19.9055 21.2876 20.0689 21.2542C20.2324 21.2206 20.4012 21.277 20.5119 21.4018C20.6226 21.5266 20.6584 21.7009 20.6057 21.8593L20.0194 23.6179C19.9563 23.8098 19.7768 23.9391 19.5749 23.9385Z", fill: "#394F63" }),
            React.createElement("path", { d: "M22.1081 27.9999C22.0236 27.9999 21.9407 27.9769 21.8682 27.933L0.881112 15.3408C0.66558 15.206 0.59786 14.9232 0.728742 14.7053C0.859841 14.4873 1.14114 14.4144 1.36145 14.5414L22.3488 27.1338C22.5283 27.2417 22.6138 27.4564 22.5578 27.6582C22.5016 27.8603 22.3178 27.9999 22.1081 27.9999Z", fill: "#95C2D5" }),
            React.createElement("path", { d: "M5.78474 23.8024C5.70009 23.8024 5.61739 23.7794 5.54489 23.7357L0.881112 20.9375C0.66558 20.8025 0.59786 20.5199 0.728742 20.3018C0.859841 20.0839 1.14114 20.0111 1.36145 20.1381L6.02523 22.9363C6.20495 23.0444 6.29046 23.2589 6.23425 23.4609C6.17825 23.6628 5.99419 23.8026 5.78474 23.8024Z", fill: "#95C2D5" }),
            React.createElement("path", { d: "M8.89308 28.0004C8.80865 28.0004 8.72574 27.9774 8.65346 27.9335L1.65768 23.7362C1.5116 23.652 1.42239 23.4955 1.42435 23.3268C1.42608 23.1582 1.51898 23.0036 1.66701 22.9229C1.81504 22.8419 1.99498 22.8473 2.13801 22.9368L9.13379 27.1343C9.3133 27.2422 9.39881 27.4569 9.34281 27.6587C9.2866 27.8608 9.10276 28.0004 8.89308 28.0004Z", fill: "#95C2D5" }),
            React.createElement("path", { d: "M27.439 20.23C26.0197 21.2819 24.1151 21.4023 22.5747 20.5378C19.3892 18.9475 6.35378 10.8977 2.604 8.57045C2.60683 8.32996 2.62723 8.0899 2.66478 7.85223C2.71188 7.54466 2.90918 7.28073 3.19113 7.14855C3.47286 7.01658 3.80213 7.03394 4.06845 7.19456L21.1847 17.4644C21.2999 17.5332 21.4391 17.549 21.5667 17.508C21.6945 17.467 21.7983 17.3728 21.8517 17.2499L22.8171 14.9971C22.8723 14.8669 22.984 14.769 23.1206 14.7317C23.2569 14.6943 23.4029 14.7217 23.5167 14.8059C24.7293 15.6735 27.7887 18.0986 27.439 20.23Z", fill: "#F4CD1E" }),
            React.createElement("path", { d: "M27.4386 20.2299C27.3551 20.7044 27.1213 21.1393 26.7716 21.4705C25.3725 22.8697 22.5743 23.3361 19.7759 21.4705C16.9776 19.605 4.38541 12.1428 4.38541 12.1428C4.38541 12.1428 2.51508 11.2053 2.60363 8.57031C6.35341 10.8975 19.3889 18.9473 22.5743 20.5377C24.1147 21.4022 26.0193 21.2817 27.4386 20.2299Z", fill: "#EC9130" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0" },
                React.createElement("rect", { width: "28", height: "28", fill: "white" })))));
};
