import React from 'react';
import { useStyles } from './HighlightedText.styles';
var HighlightedText = function (_a) {
    var value = _a.value, highlightValue = _a.highlightValue;
    var classes = useStyles();
    var index = value.toLowerCase().indexOf(highlightValue.toLowerCase());
    var length = highlightValue.length;
    if (index === -1) {
        return React.createElement(React.Fragment, null, value);
    }
    return (React.createElement("div", { className: classes.container },
        value.slice(0, index),
        React.createElement("span", { className: classes.highlighted }, value.slice(index, index + length)),
        value.slice(index + length)));
};
export { HighlightedText };
