import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var TennisIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { viewBox: "0 0 28 28", className: className },
        React.createElement("g", { clipPath: "url(#clip0)" },
            React.createElement("path", { d: "M14.0003 18.1685C17.7566 18.1685 20.8126 15.1125 20.8126 11.3562C20.8126 10.1941 20.5937 9.10107 20.382 8.04403C19.9733 6.00269 19.5518 3.89257 20.9861 1.86508C18.93 0.678844 16.5443 0 14 0C14 0 14 18.1685 14.0003 18.1685Z", fill: "#FFCD00" }),
            React.createElement("path", { d: "M22.8477 3.15039C21.9995 4.3316 22.1904 5.55166 22.6006 7.59979C22.823 8.71068 23.0752 9.96984 23.0752 11.3562C23.0752 16.3602 19.0042 20.4311 14.0003 20.4311C14 20.4311 14 27.9996 14 27.9996C21.732 27.9996 28.0003 21.7317 28.0003 14C28.0003 9.62405 25.9922 5.71762 22.8477 3.15039Z", fill: "#FFCD00" }),
            React.createElement("path", { d: "M4.92495 11.3563C4.92495 9.96989 5.17712 8.71074 5.39953 7.59985C5.80969 5.55172 6.0006 4.3316 5.1524 3.15039C2.00808 5.71762 0 9.62411 0 14C0 21.7317 6.26798 27.9996 13.9996 27.9996V20.4312C8.99581 20.431 4.92495 16.3601 4.92495 11.3563Z", fill: "#FFDE55" }),
            React.createElement("path", { d: "M7.61785 8.04403C7.40618 9.10108 7.18727 10.1941 7.18727 11.3562C7.18727 15.1124 10.2431 18.1684 13.9993 18.1685V0C11.4552 0 9.06966 0.678844 7.01367 1.86503C8.44801 3.89251 8.02665 6.00269 7.61785 8.04403Z", fill: "#FFDE55" }),
            React.createElement("path", { d: "M22.6003 7.60003C22.1902 5.55184 21.9993 4.33172 22.8475 3.15052C22.6541 2.99259 22.4564 2.83975 22.2547 2.69211C21.8479 2.39446 21.4245 2.11825 20.9859 1.86523C19.5516 3.89277 19.9729 6.0029 20.3817 8.04429C20.5934 9.10133 20.8123 10.1944 20.8123 11.3564C20.8123 15.1127 17.7564 18.1687 14 18.1687V20.4314C19.0039 20.4314 23.0749 16.3604 23.0749 11.3564C23.0749 9.97007 22.8228 8.71092 22.6003 7.60003Z", fill: "#FFF5CC" }),
            React.createElement("path", { d: "M7.18743 11.3565C7.18743 10.1945 7.40634 9.10145 7.61801 8.04441C8.02681 6.00301 8.44816 3.89283 7.01377 1.86523C6.70215 2.04506 6.39811 2.23659 6.10233 2.43921C5.77538 2.66309 5.45844 2.90055 5.1522 3.15057C6.00051 4.33178 5.8096 5.5519 5.39939 7.60014C5.17692 8.71103 4.9248 9.97018 4.9248 11.3565C4.9248 16.3605 8.99578 20.4315 13.9997 20.4315V18.1689C10.2434 18.1689 7.18743 15.1128 7.18743 11.3565Z", fill: "white" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0" },
                React.createElement("rect", { width: "28", height: "28", fill: "white" })))));
};
