import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import { selectOrderedMarketsData } from '~slices/markets';
import { useSelectionColumnsCount, useStructuredSelector } from '~hooks';
import { ZIndex } from '~constants/view/ZIndex';
import { MarketFiltersList } from './MarketFiltersList';
var useStyles = makeStyles({
    filtersContainer: {
        position: 'sticky',
        top: '80px',
        zIndex: ZIndex.MARKET_FILTERS_ROW,
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '6px 16px 6px 0',
        background: '#212121',
        paddingLeft: '20%',
    },
}, { name: 'MarketFiltersRow' });
export var MarketFiltersRow = function (_a) {
    var className = _a.className;
    var classes = useStyles();
    var markets = useStructuredSelector({
        markets: selectOrderedMarketsData,
    }).markets;
    var columnsCount = useSelectionColumnsCount();
    return (React.createElement("div", { className: classNames(classes.filtersContainer, className) }, markets.slice(0, columnsCount).map(function (market, listIndex) { return (React.createElement(MarketFiltersList, { key: market.id, markets: markets, listIndex: listIndex })); })));
};
