import { useRef } from 'react';
var useIsFirstRender = function () {
    var isFirstRender = useRef(true);
    var result = isFirstRender.current;
    if (isFirstRender.current) {
        isFirstRender.current = false;
    }
    return result;
};
export { useIsFirstRender };
