import { invlerp, lerp } from '~utils/math';
var scrollSmoothlyBy = function (element, scrollAmount, options) {
    var initialScrollTop = element.scrollTop;
    var _a = options || {}, _b = _a.timingFunction, timingFunction = _b === void 0 ? function (t) { return lerp(0, 1, t); } : _b, _c = _a.animationDuration, animationDuration = _c === void 0 ? 160 : _c;
    var start = 0;
    var end = animationDuration;
    var step = function (timestamp) {
        if (!start) {
            start = timestamp;
            end = start + animationDuration;
        }
        var t = invlerp(start, end, timestamp);
        element.scrollTop = initialScrollTop + timingFunction(t) * scrollAmount;
        if (t < 1) {
            window.requestAnimationFrame(step);
        }
    };
    window.requestAnimationFrame(step);
};
export { scrollSmoothlyBy };
