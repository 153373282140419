import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var TableTennisIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, viewBox: "0 0 28 28" },
        React.createElement("path", { d: "M24.3474 3.64355C24.3469 3.64355 11.8125 16.1779 11.8125 16.1779L19.6621 24.0276C21.3817 23.5705 22.9554 22.6366 24.3474 21.2445C26.6978 18.8942 27.9925 15.7684 27.9925 12.444C27.9925 9.11968 26.6978 5.99386 24.3474 3.64355Z", fill: "#3D9AE2" }),
        React.createElement("path", { d: "M24.3488 3.64566C21.998 1.29479 18.8727 0 15.5484 0C12.224 0 9.09874 1.29479 6.74787 3.64509C5.35579 5.03717 4.42189 6.61083 3.96484 8.33043L11.8145 16.18L24.3488 3.64566Z", fill: "#78B9EB" }),
        React.createElement("path", { d: "M1.16211 26.8406C1.91047 27.5884 2.9049 28.0002 3.96211 28.0002C5.02045 28.0002 6.01488 27.5884 6.76267 26.8406L8.89463 24.7082C9.72276 23.8807 10.6165 23.5113 11.7936 23.5113C12.6365 23.5113 13.5313 23.6929 14.4782 23.8846C15.5021 24.0922 16.561 24.3066 17.6499 24.3066C18.1013 24.3066 18.5193 24.2704 18.9277 24.1963C19.1788 24.1505 19.4266 24.095 19.6721 24.03L11.8225 16.1807L1.16211 26.8406Z", fill: "#FFA733" }),
        React.createElement("path", { d: "M3.96851 8.33276C3.90345 8.57826 3.84802 8.82602 3.8022 9.07717C3.52277 10.6112 3.82313 12.0932 4.11388 13.5266C4.61109 15.9804 4.83735 17.5626 3.29028 19.1102L1.15776 21.2422C-0.385919 22.7864 -0.385919 25.2985 1.15776 26.8427L11.8181 16.1824L3.96851 8.33276Z", fill: "#FFB655" })));
};
