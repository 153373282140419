import React, { useCallback, useRef, useState } from 'react';
import { NestedRankingListCartoonItem } from "~atoms/RankingListCartoonItem/components/NestedRankingListCartoonItem";
import { useStyles } from '../RankingListCartoonItem.styles';
export var NestedRankingListCartoon = function (_a) {
    var rankingList = _a.rankingList, currentUserRank = _a.currentUserRank, trendBalance = _a.trendBalance, isGroupRank = _a.isGroupRank;
    var classes = useStyles();
    var _b = useState(10), visibleCount = _b[0], setVisibleCount = _b[1]; // Initial number of items to render
    var containerRef = useRef(null);
    var handleScroll = useCallback(function () {
        var container = containerRef.current;
        if (container &&
            container.scrollTop + container.clientHeight + 150 >=
                container.scrollHeight) {
            setVisibleCount(function (prevCount) { return prevCount + 1; }); // Increase the number of items to render by 10
        }
    }, []);
    return (React.createElement("div", { ref: containerRef, onScroll: handleScroll, className: classes.listWrapper }, rankingList.slice(0, visibleCount).map(function (item) { return (React.createElement(NestedRankingListCartoonItem, { key: item.rank, currentUserRank: currentUserRank, trendBalance: trendBalance, rankingItem: item, isGroupRank: isGroupRank })); })));
};
