var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useSwipeable } from 'react-swipeable';
import { GlobalRankingList } from 'view/components/molecules/GlobalRankingList';
import { SmallLoader, StyledTab, StyledTabs } from '~atoms';
import { DateSwitcher } from '~atoms/DateSwitcher';
import { LevelCardCartoon } from '~atoms/LevelCardCartoon';
import { RankingList } from '~atoms/RankingList';
import { UserCard } from '~atoms/UserCard';
import { useIsDesktop, useStructuredSelector } from '~hooks';
import { MODALS, openModal } from '~modals';
import { EmptyBetslipBanner } from '~molecules/EmptyBetslipBanner';
import { accountInfoSelector } from '~slices/account';
import { isAuthenticatedSelector } from '~slices/auth';
import { useActiveGameSelector, useGameTypesDataSelector, useSelectedGameDataSelector, useTournamentSelector, } from '~slices/gameTypes';
import { GAMES, GAME_PERIOD, GLOBAL, globalStyles, } from '~slices/gameTypes/constants';
import { sportNameSelector } from '~slices/options';
import { getDailyRanking, getGlobalRankingMaster, getWeeklyRanking, useRankingListsDataSelector, useRankingLoadingSelector, } from '~slices/ranking';
import { AccumulationType } from '~slices/ranking/constants';
import { squareGallerySelector } from '~slices/teasers';
import { useAppDispatch } from '~store';
import { getTwoFirstGlobalGames } from '~utils/getTwoFirstGlobalGames';
import { getPathWithLanguageCode } from '~utils/languageInURL';
import { PageMetadata } from '~view/hoc/PageMetadata';
import { useStyles } from './RankingListComponents.styles';
export var RankingListTabs = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31;
    var classes = useStyles();
    var dispatch = useAppDispatch();
    var t = useTranslation('tip-game').t;
    var ts = useTranslation('seo').t;
    var _32 = useStructuredSelector({
        isAuthenticated: isAuthenticatedSelector,
        banners: function (state) { return squareGallerySelector(state); },
        gameTypes: useGameTypesDataSelector,
        activeGame: useActiveGameSelector,
        selectedGame: useSelectedGameDataSelector,
        rankingLists: useRankingListsDataSelector,
        rankingLoadingStatus: useRankingLoadingSelector,
        accountInfo: accountInfoSelector,
        tournament: useTournamentSelector,
        sportName: sportNameSelector,
    }), isAuthenticated = _32.isAuthenticated, banners = _32.banners, gameTypes = _32.gameTypes, selectedGame = _32.selectedGame, activeGame = _32.activeGame, rankingLists = _32.rankingLists, rankingLoadingStatus = _32.rankingLoadingStatus, accountInfo = _32.accountInfo, tournament = _32.tournament, sportName = _32.sportName;
    var games = useMemo(function () { return getTwoFirstGlobalGames(gameTypes); }, [gameTypes]);
    var currentGame = selectedGame
        ? gameTypes.find(function (game) { return game.name === selectedGame; })
        : games.find(function (game) { return game.name === activeGame; });
    var isDesktop = useIsDesktop();
    var _33 = useState(null), activeTab = _33[0], setActiveTab = _33[1];
    var _34 = useState([]), tabsSet = _34[0], setTabsSet = _34[1];
    // variable to check if there is a message about the selected game from the CPA
    var selectedGameLocalValue;
    var currentUserName = accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.username;
    var isEmptyBetslipBanner = !isAuthenticated && !!banners.length && !isDesktop;
    var isRankingListLoading = rankingLoadingStatus === 'pending';
    var isDailyGameRankingList = activeTab === 'daily' ||
        activeTab === 'daily-weekly' ||
        activeTab === 'daily-total';
    var toTipPath = (tournament === null || tournament === void 0 ? void 0 : tournament.pathname) ||
        getPathWithLanguageCode("/home/all/live/".concat(sportName === null || sportName === void 0 ? void 0 : sportName.name));
    var getDailyRankingList = function (accumulation, date) {
        var dailyGame;
        if (selectedGame) {
            dailyGame =
                (currentGame === null || currentGame === void 0 ? void 0 : currentGame.gamePeriod) === GAME_PERIOD.DAILY ? currentGame : undefined;
        }
        else {
            dailyGame = games.find(function (game) { return game.gamePeriod === GAME_PERIOD.DAILY; });
        }
        var gameName = dailyGame === null || dailyGame === void 0 ? void 0 : dailyGame.name;
        if (dailyGame)
            dispatch(getDailyRanking({
                baseURL: dailyGame.url,
                accumulation: accumulation,
                date: date,
                gameName: gameName,
            }));
    };
    var getWeeklyRankingList = function (accumulation, date) {
        var weeklyGame;
        if (selectedGame) {
            weeklyGame =
                (currentGame === null || currentGame === void 0 ? void 0 : currentGame.gamePeriod) === GAME_PERIOD.WEEKLY ||
                    (currentGame === null || currentGame === void 0 ? void 0 : currentGame.gamePeriod) === GAME_PERIOD.TOURNAMENT
                    ? currentGame
                    : undefined;
        }
        else {
            weeklyGame = games.find(function (game) {
                return game.gamePeriod === GAME_PERIOD.WEEKLY ||
                    game.gamePeriod === GAME_PERIOD.TOURNAMENT;
            });
        }
        var gameName = weeklyGame === null || weeklyGame === void 0 ? void 0 : weeklyGame.name;
        if (weeklyGame)
            dispatch(getWeeklyRanking({
                baseURL: weeklyGame.url,
                accumulation: accumulation,
                date: date,
                gameName: gameName,
            }));
    };
    var getGlobalRankingListMaster = function () {
        dispatch(getGlobalRankingMaster());
    };
    var onTabClick = function (game, accumulation) {
        if (activeTab === game) {
            return;
        }
        setActiveTab(game);
        if (game === 'weekly') {
            getWeeklyRankingList();
        }
        else if (game === 'daily') {
            getDailyRankingList(accumulation);
        }
        else if (game === 'tournament') {
            getWeeklyRankingList(AccumulationType.ALL_TIME);
        }
        else if (game === 'daily-weekly') {
            getDailyRankingList(accumulation);
        }
        else if (game === 'daily-total') {
            getDailyRankingList(accumulation);
        }
    };
    var getLastLevelRankingsItem = function (levelRankings) {
        return (levelRankings === null || levelRankings === void 0 ? void 0 : levelRankings.length) ? levelRankings[(levelRankings === null || levelRankings === void 0 ? void 0 : levelRankings.length) - 1] : null;
    };
    var isRankingListDisplay = function () {
        var rankingListName = (currentGame === null || currentGame === void 0 ? void 0 : currentGame.gamePeriod) === GAME_PERIOD.DAILY
            ? GAMES.DAILY
            : GAMES.WEEKLY;
        return ((currentGame === null || currentGame === void 0 ? void 0 : currentGame.active) &&
            Object.keys(rankingLists[rankingListName]).length > 0);
    };
    var handleRNMessage = function (event) {
        var _a;
        try {
            if (event.data) {
                var parsedData = JSON.parse(event.data);
                var code = parsedData === null || parsedData === void 0 ? void 0 : parsedData.code;
                if (code === 'RN-WEB::SELECTED_WEEKLY' ||
                    code === 'RN-WEB::SELECTED_DAILY') {
                    var game = (_a = code.split('_')[1]) === null || _a === void 0 ? void 0 : _a.toLowerCase();
                    if (game === selectedGameLocalValue || game === activeGame) {
                        return;
                    }
                    selectedGameLocalValue = game;
                    onTabClick(game, AccumulationType.ALL_TIME);
                }
            }
        }
        catch (error) { }
    };
    useEffect(function () {
        window.addEventListener('message', handleRNMessage, false);
        return function () {
            window.removeEventListener('message', handleRNMessage);
        };
    }, []);
    useEffect(function () {
        if (gameTypes &&
            (Object.keys(rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.daily).length ||
                Object.keys(rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.weekly).length)) {
            return;
        }
        switch (activeTab) {
            case 'daily':
                getDailyRankingList();
                break;
            case 'weekly':
                getWeeklyRankingList();
                break;
            default:
                return;
        }
    }, [isAuthenticated, gameTypes]);
    useEffect(function () {
        if (!activeGame) {
            return;
        }
        if (isAuthenticated) {
            var tabName = (currentGame === null || currentGame === void 0 ? void 0 : currentGame.gamePeriod) === GAME_PERIOD.DAILY
                ? GAMES.DAILY
                : GAMES.WEEKLY;
            setActiveTab(tabName);
            if ((currentGame === null || currentGame === void 0 ? void 0 : currentGame.gamePeriod) === GAME_PERIOD.DAILY)
                getDailyRankingList();
            if ((currentGame === null || currentGame === void 0 ? void 0 : currentGame.gamePeriod) === GAME_PERIOD.WEEKLY ||
                (currentGame === null || currentGame === void 0 ? void 0 : currentGame.gamePeriod) === GAME_PERIOD.TOURNAMENT)
                getWeeklyRankingList();
        }
        else {
            setActiveTab('global-ranking');
            getGlobalRankingListMaster();
        }
    }, [activeGame, isAuthenticated]);
    useEffect(function () {
        if (!activeGame) {
            return;
        }
        var set;
        var weeklyTabs = ['weekly'];
        var tournamentTabs = ['weekly', 'tournament'];
        var dailyTabs = ['daily', 'daily-weekly', 'daily-total'];
        if (currentGame === null || currentGame === void 0 ? void 0 : currentGame.tournament) {
            set = tournamentTabs;
        }
        else if ((currentGame === null || currentGame === void 0 ? void 0 : currentGame.gamePeriod) === GAME_PERIOD.DAILY) {
            set = dailyTabs;
        }
        else {
            set = weeklyTabs;
        }
        setTabsSet(set);
    }, [activeGame]);
    var showJoinGameModal = function () {
        dispatch(openModal({
            modalName: MODALS.JOIN_GAME_DESCRIPTION_MODAL,
            modalProps: {
                handleClose: function () { return dispatch(push(toTipPath)); },
            },
        }));
    };
    var onAccumulationClick = function (type) {
        var date = activeTab === 'weekly'
            ? rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.weekly[type]
            : rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.daily[type];
        if (activeTab === 'weekly') {
            getWeeklyRankingList(undefined, date);
        }
        else if (activeTab === 'daily') {
            getDailyRankingList(undefined, date);
        }
        else if (activeTab === 'daily-weekly') {
            getDailyRankingList(AccumulationType.WEEKLY, date);
        }
    };
    var onSwipe = function (direction) {
        var tabIndex = tabsSet.indexOf(activeTab);
        if (direction === 'left' && tabsSet.length > tabIndex + 1) {
            var accumulation = tabsSet[tabIndex + 1] === 'daily-weekly'
                ? AccumulationType.WEEKLY
                : undefined;
            onTabClick(tabsSet[tabIndex + 1], accumulation);
        }
        if (direction === 'right' && tabIndex > 0) {
            var accumulation = tabsSet[tabIndex - 1] === 'daily-weekly'
                ? AccumulationType.WEEKLY
                : undefined;
            onTabClick(tabsSet[tabIndex - 1], accumulation);
        }
    };
    var swipeHandlers = useSwipeable({
        onSwipedLeft: function () { return onSwipe('left'); },
        onSwipedRight: function () { return onSwipe('right'); },
    });
    var globalBackground = function () {
        var _a = globalStyles[GLOBAL], startColor = _a.startColor, endColor = _a.endColor;
        return "linear-gradient(210.09deg, ".concat(startColor, " -8.72%, ").concat(endColor, " 128.29%)");
    };
    var rankingTabs;
    if (currentGame === null || currentGame === void 0 ? void 0 : currentGame.tournament) {
        rankingTabs = (React.createElement(StyledTabs, { variant: "fullWidth", value: activeTab },
            React.createElement(StyledTab, { label: (currentGame === null || currentGame === void 0 ? void 0 : currentGame.tournament) || t("game-name.".concat(currentGame === null || currentGame === void 0 ? void 0 : currentGame.name)), value: 'weekly', textColorClassNames: classes.greenText, onClick: function () { return onTabClick('weekly'); } }),
            React.createElement(StyledTab, { label: t('ranking-list.total-accumulation'), value: 'tournament', textColorClassNames: classes.greenText, onClick: function () { return onTabClick('tournament', AccumulationType.ALL_TIME); } })));
    }
    else if ((currentGame === null || currentGame === void 0 ? void 0 : currentGame.gamePeriod) === GAME_PERIOD.DAILY) {
        rankingTabs = (React.createElement(StyledTabs, { variant: "fullWidth", value: activeTab },
            React.createElement(StyledTab, { label: currentGame.tournament || t("game-name.".concat(currentGame.name)), value: 'daily', textColorClassNames: classes.greenText, onClick: function () { return onTabClick('daily'); } }),
            React.createElement(StyledTab, { label: t('ranking-list.weekly-accumulation'), value: 'daily-weekly', textColorClassNames: classes.greenText, onClick: function () { return onTabClick('daily-weekly', AccumulationType.WEEKLY); } }),
            React.createElement(StyledTab, { label: t('ranking-list.total-accumulation'), value: 'daily-total', textColorClassNames: classes.greenText, onClick: function () { return onTabClick('daily-total', AccumulationType.ALL_TIME); } })));
    }
    else {
        rankingTabs = (React.createElement(StyledTabs, { variant: "fullWidth", value: activeTab },
            React.createElement(StyledTab, { label: (currentGame === null || currentGame === void 0 ? void 0 : currentGame.tournament) || t("game-name.".concat(currentGame === null || currentGame === void 0 ? void 0 : currentGame.name)), value: 'weekly', textColorClassNames: classes.greenText, onClick: function () { return onTabClick('weekly'); } })));
    }
    return (React.createElement(React.Fragment, null,
        !isDesktop && (React.createElement(PageMetadata, { lang: i18next.language, titleSuffix: t('ranking_list'), description: ts('page-description.ranking_list') })),
        React.createElement("div", __assign({ className: classNames('flex-row', !isDesktop && classes.componentWrapper) }, swipeHandlers),
            React.createElement("div", { className: classNames('flex-column', classes.fullWidth) },
                activeTab && (React.createElement("div", { className: classNames('flex-row flex-center', classes.container, !isDesktop && classes.positionFixed) }, isAuthenticated ? (React.createElement(React.Fragment, null, rankingTabs)) : (React.createElement(StyledTabs, { variant: "fullWidth", value: activeTab },
                    React.createElement(StyledTab, { label: t('game-type.global_ranking'), value: 'global-ranking', textColorClassNames: classes.greenText, onClick: function () { return onTabClick('global-ranking'); } }))))),
                isRankingListLoading ? (React.createElement(SmallLoader, { className: classes.loaderHeight })) : (React.createElement("div", { className: classNames(!isDesktop && classes.wrapper, !isDesktop &&
                        activeTab === 'global-ranking' &&
                        classes.paddingTop48) },
                    isEmptyBetslipBanner && React.createElement(EmptyBetslipBanner, null),
                    (activeTab === 'weekly' || activeTab === 'tournament') &&
                        currentGame && (React.createElement(React.Fragment, null,
                        isAuthenticated && (React.createElement(React.Fragment, null,
                            React.createElement(DateSwitcher, { prev: (_a = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.weekly) === null || _a === void 0 ? void 0 : _a.prev, current: (_b = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.weekly) === null || _b === void 0 ? void 0 : _b.current, next: (_c = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.weekly) === null || _c === void 0 ? void 0 : _c.next, nextName: (_d = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.weekly) === null || _d === void 0 ? void 0 : _d.nextName, name: currentGame.tournament ||
                                    t("game-name.".concat(currentGame.name)), currentName: (_e = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.weekly) === null || _e === void 0 ? void 0 : _e.currentName, onClick: function (type) { return onAccumulationClick(type); } }),
                            React.createElement("div", { className: classNames(classes.userCard, isDesktop && classes.mt24) },
                                React.createElement(UserCard, { userName: currentUserName, balance: (_g = (_f = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.weekly) === null || _f === void 0 ? void 0 : _f.myselfRanking) === null || _g === void 0 ? void 0 : _g.balance, level: (_j = (_h = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.weekly) === null || _h === void 0 ? void 0 : _h.myselfRanking) === null || _j === void 0 ? void 0 : _j.level, rank: (_l = (_k = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.weekly) === null || _k === void 0 ? void 0 : _k.myselfRanking) === null || _l === void 0 ? void 0 : _l.rank, stars: (_o = (_m = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.weekly) === null || _m === void 0 ? void 0 : _m.myselfRanking) === null || _o === void 0 ? void 0 : _o.expSubLevel, avatar: ((_q = (_p = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.weekly) === null || _p === void 0 ? void 0 : _p.myselfRanking) === null || _q === void 0 ? void 0 : _q.expLevelName) || 'Joker', avatarUrl: ((_s = (_r = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.weekly) === null || _r === void 0 ? void 0 : _r.myselfRanking) === null || _s === void 0 ? void 0 : _s.avatarUrl) ||
                                        'avatars/joker.svg', gameInfo: {
                                        name: currentGame.tournament ||
                                            t("game-name.".concat(currentGame.name)),
                                        color: globalStyles[GAMES.WEEKLY].mainColor,
                                    }, isRankingListEmpty: !isRankingListDisplay(), showJoinGameModal: showJoinGameModal })),
                            React.createElement("div", { className: classes.dividerContainer },
                                React.createElement("div", { className: classes.divider })))),
                        isRankingListDisplay() ? (React.createElement(React.Fragment, null,
                            React.createElement(RankingList, { rankingListData: rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.weekly }),
                            React.createElement("div", { className: classes.levels },
                                getLastLevelRankingsItem(rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.weekly.levelRankings) && (React.createElement(LevelCardCartoon, { level: (_t = getLastLevelRankingsItem(rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.weekly.levelRankings)) === null || _t === void 0 ? void 0 : _t.level, progress: (_u = getLastLevelRankingsItem(rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.weekly.levelRankings)) === null || _u === void 0 ? void 0 : _u.progress, isLocked: (_v = getLastLevelRankingsItem(rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.weekly.levelRankings)) === null || _v === void 0 ? void 0 : _v.locked, levelLimit: (_w = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.weekly) === null || _w === void 0 ? void 0 : _w.qualificationRankings[0].levelLimit, isLevelRanking: true })), (_y = (_x = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.weekly) === null || _x === void 0 ? void 0 : _x.qualificationRankings) === null || _y === void 0 ? void 0 :
                                _y.map(function (item) {
                                    var _a, _b;
                                    return (React.createElement(LevelCardCartoon, { key: item.level, level: item.level, isDone: item.done, levelLimit: item.levelLimit, userBalance: (_b = (_a = rankingLists.weekly) === null || _a === void 0 ? void 0 : _a.myselfRanking) === null || _b === void 0 ? void 0 : _b.balance }));
                                })))) : (React.createElement("div", { className: classes.emptyRankingList }, t('the_game_will_start_soon'))))),
                    currentGame && isDailyGameRankingList && (React.createElement(React.Fragment, null,
                        isAuthenticated && (React.createElement(React.Fragment, null,
                            React.createElement(DateSwitcher, { prev: (_z = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.daily) === null || _z === void 0 ? void 0 : _z.prev, current: (_0 = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.daily) === null || _0 === void 0 ? void 0 : _0.current, next: (_1 = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.daily) === null || _1 === void 0 ? void 0 : _1.next, nextName: (_2 = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.daily) === null || _2 === void 0 ? void 0 : _2.nextName, name: (currentGame === null || currentGame === void 0 ? void 0 : currentGame.tournament) ||
                                    t("game-name.".concat(currentGame === null || currentGame === void 0 ? void 0 : currentGame.name)), currentName: (_3 = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.daily) === null || _3 === void 0 ? void 0 : _3.currentName, onClick: function (type) { return onAccumulationClick(type); } }),
                            React.createElement("div", { className: classNames(classes.userCard, isDesktop && classes.mt24) },
                                React.createElement(UserCard, { userName: currentUserName, balance: (_5 = (_4 = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.daily) === null || _4 === void 0 ? void 0 : _4.myselfRanking) === null || _5 === void 0 ? void 0 : _5.balance, level: (_7 = (_6 = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.daily) === null || _6 === void 0 ? void 0 : _6.myselfRanking) === null || _7 === void 0 ? void 0 : _7.level, rank: (_9 = (_8 = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.daily) === null || _8 === void 0 ? void 0 : _8.myselfRanking) === null || _9 === void 0 ? void 0 : _9.rank, stars: (_11 = (_10 = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.daily) === null || _10 === void 0 ? void 0 : _10.myselfRanking) === null || _11 === void 0 ? void 0 : _11.expSubLevel, avatar: ((_13 = (_12 = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.daily) === null || _12 === void 0 ? void 0 : _12.myselfRanking) === null || _13 === void 0 ? void 0 : _13.expLevelName) ||
                                        'Joker', avatarUrl: ((_15 = (_14 = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.daily) === null || _14 === void 0 ? void 0 : _14.myselfRanking) === null || _15 === void 0 ? void 0 : _15.avatarUrl) ||
                                        'avatars/joker.svg', gameInfo: {
                                        name: currentGame.tournament ||
                                            t("game-name.".concat(currentGame.name)),
                                        color: globalStyles[GAMES.DAILY].mainColor,
                                    }, isRankingListEmpty: !isRankingListDisplay(), showJoinGameModal: showJoinGameModal })),
                            React.createElement("div", { className: classes.dividerContainer },
                                React.createElement("div", { className: classes.divider })))),
                        isRankingListDisplay() ? (React.createElement(React.Fragment, null,
                            React.createElement(RankingList, { rankingListData: rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.daily }),
                            React.createElement("div", { className: classes.levels },
                                getLastLevelRankingsItem(rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.daily.levelRankings) ? (React.createElement(LevelCardCartoon, { level: (_16 = getLastLevelRankingsItem(rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.daily.levelRankings)) === null || _16 === void 0 ? void 0 : _16.level, progress: (_17 = getLastLevelRankingsItem(rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.daily.levelRankings)) === null || _17 === void 0 ? void 0 : _17.progress, levelLimit: (_18 = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.daily) === null || _18 === void 0 ? void 0 : _18.qualificationRankings[0].levelLimit, isLevelRanking: true })) : null, (_20 = (_19 = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.daily) === null || _19 === void 0 ? void 0 : _19.qualificationRankings) === null || _20 === void 0 ? void 0 :
                                _20.map(function (item) {
                                    var _a, _b;
                                    return (React.createElement(LevelCardCartoon, { key: item.level, level: item.level, isDone: item.done, levelLimit: item.levelLimit, userBalance: (_b = (_a = rankingLists.daily) === null || _a === void 0 ? void 0 : _a.myselfRanking) === null || _b === void 0 ? void 0 : _b.balance }));
                                })))) : (React.createElement("div", { className: classes.emptyRankingList }, t('the_game_will_start_soon'))))),
                    activeTab === 'global-ranking' && (React.createElement(React.Fragment, null,
                        ((_21 = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.globalMaster) === null || _21 === void 0 ? void 0 : _21.myself) && (React.createElement(React.Fragment, null,
                            React.createElement("div", { className: classNames(classes.userCard, classes.mt16) },
                                React.createElement(UserCard, { userName: currentUserName, balance: (_23 = (_22 = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.globalMaster) === null || _22 === void 0 ? void 0 : _22.myself) === null || _23 === void 0 ? void 0 : _23.balance, rank: (_25 = (_24 = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.globalMaster) === null || _24 === void 0 ? void 0 : _24.myself) === null || _25 === void 0 ? void 0 : _25.rank, stars: (_27 = (_26 = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.globalMaster) === null || _26 === void 0 ? void 0 : _26.myself) === null || _27 === void 0 ? void 0 : _27.subLevel, avatar: ((_29 = (_28 = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.globalMaster) === null || _28 === void 0 ? void 0 : _28.myself) === null || _29 === void 0 ? void 0 : _29.expLevelName) ||
                                        'Joker', avatarUrl: ((_31 = (_30 = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.globalMaster) === null || _30 === void 0 ? void 0 : _30.myself) === null || _31 === void 0 ? void 0 : _31.avatarUrl) ||
                                        'avatars/joker.svg', background: globalBackground(), isGlobalRanking: true })),
                            React.createElement("div", { className: classes.dividerContainer },
                                React.createElement("div", { className: classes.divider })))),
                        React.createElement(GlobalRankingList, { rankingListData: rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.globalMaster, isLastPage: rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.globalDetails.last, userName: currentUserName })))))))));
};
