var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { DateRangeDelimiter, MobileDateRangePicker, } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import { createTheme } from '@material-ui/core/styles';
import { Button } from '~atoms/button';
var useOverwriteStyles = makeStyles({
    root: {
        height: 'auto',
    },
    underline: {
        '&&&:before': {
            borderBottom: 'none',
        },
        '&&:after': {
            borderBottom: 'none',
        },
    },
    input: {
        padding: '8px 2px 8px 4px',
        fontSize: '11px',
        lineHeight: '18px',
        height: 'auto',
        textAlign: 'center',
        color: '#ffffff',
    },
}, { name: 'Overwrite' });
var useStyles = makeStyles({
    dateInput: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        backgroundColor: '#323232',
        border: '1px solid #414141',
        boxSizing: 'border-box',
        borderRadius: '2px',
    },
    delimiter: {
        alignSelf: 'center',
        margin: 0,
    },
    text: {
        minWidth: '12px',
        padding: '0 8px 0 8px',
        height: 'auto',
        marginLeft: 'auto',
    },
    icon: {
        fontSize: '16px',
        color: 'rgba(255, 255, 255, 0.4);',
    },
}, { name: 'CustomDateRangePicker' });
var rangePickerTheme = createTheme({
    overrides: {
        // @ts-ignore
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: '#2AA1F7',
            },
        },
        MuiPickersDesktopDateRangeCalendar: {
            calendar: {
                minHeight: 255,
            },
        },
        MuiPickersCalendarHeader: {
            switchHeader: {
                position: 'relative',
                paddingLeft: 12,
                '& > div:last-child': {
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                },
            },
            monthTitleContainer: {
                position: 'absolute',
                left: '50%',
                top: 0,
                transform: 'translateX(-50%)',
            },
        },
        MuiPickersDateRangePickerToolbarProps: {
            penIcon: {
                display: 'none',
            },
        },
        MuiPickersDay: {
            day: {
                color: 'rgba(0, 0, 0, 0.87)',
            },
            daySelected: {
                backgroundColor: '#2AA1F7',
            },
            range: {
                color: 'red',
            },
        },
        MuiPickersModal: {
            dialogAction: {
                color: '#2AA1F7',
            },
        },
        MuiPickersDateRangeDay: {
            dayInsideRangeInterval: {
                color: 'rgba(0, 0, 0, 0.87)',
            },
            rangeIntervalDayHighlight: {
                backgroundColor: 'rgba(42, 161, 247, 0.3)',
            },
        },
        MuiButton: {
            textPrimary: {
                color: '#2AA1F7',
            },
        },
    },
});
function getDaysAfter(date) {
    return date ? moment(date).add(30, 'days') : undefined;
}
export var CustomDateRangePicker = function (_a) {
    var mask = _a.mask, inputFormat = _a.inputFormat, minDate = _a.minDate, value = _a.value, onChange = _a.onChange, clearDateToDefault = _a.clearDateToDefault;
    var startInput = useRef(null);
    var endInput = useRef(null);
    var _b = useState(getDaysAfter(value[0])), maxDate = _b[0], setMaxDate = _b[1];
    var _c = useState(value[0]), startDate = _c[0], setStartDate = _c[1];
    var _d = useState(value[1]), endDate = _d[0], setEndDate = _d[1];
    var classes = useOverwriteStyles();
    var styles = useStyles();
    var unBlurFields = function () {
        setTimeout(function () {
            var _a, _b;
            (_a = startInput.current) === null || _a === void 0 ? void 0 : _a.blur();
            (_b = endInput.current) === null || _b === void 0 ? void 0 : _b.blur();
        }, 0);
    };
    useEffect(function () {
        var possibleMaxDate = getDaysAfter(startDate);
        if (possibleMaxDate.isAfter(moment())) {
            possibleMaxDate = moment();
        }
        setMaxDate(possibleMaxDate);
        if (moment(endDate).isAfter(possibleMaxDate)) {
            setEndDate(possibleMaxDate);
            onChange([
                startDate,
                possibleMaxDate,
            ]);
        }
    }, [startDate, endDate]);
    useEffect(function () {
        if (value[0]) {
            setStartDate(value[0]);
        }
        if (value[1]) {
            setEndDate(value[1]);
        }
        else {
            setEndDate(value[0]);
        }
    }, [value]);
    return (React.createElement(ThemeProvider, { theme: rangePickerTheme },
        React.createElement(MobileDateRangePicker, { mask: mask, inputFormat: inputFormat, minDate: minDate, maxDate: maxDate, disableFuture: true, value: [startDate, endDate], onAccept: unBlurFields, onChange: function (dateRange) {
                setStartDate(dateRange[0]);
                setEndDate(dateRange[1]);
                onChange(dateRange);
            }, 
            // @ts-ignore
            onDateChange: function (dateRange) {
                setStartDate(dateRange[0]);
                setEndDate(dateRange[1]);
                onChange(dateRange);
            }, onClose: unBlurFields, renderInput: function (startProps, endProps) { return (React.createElement("div", { className: styles.dateInput },
                React.createElement(TextField, __assign({}, startProps, { variant: "standard", label: "", helperText: "", InputProps: { classes: classes }, inputRef: startInput })),
                React.createElement(DateRangeDelimiter, { className: styles.delimiter }, "-"),
                React.createElement(TextField, __assign({}, endProps, { variant: "standard", label: "", helperText: "", InputProps: { classes: classes }, inputRef: endInput })),
                React.createElement(Button, { classes: { text: styles.text }, onClick: clearDateToDefault },
                    React.createElement("i", { className: "fal fa-times ".concat(styles.icon) })))); } })));
};
