import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Currency } from '~atoms';
import { BalanceInputButton } from '~atoms/BalanceInput/components/BalanceInputButton';
import { CSIcon } from '~icons/rankingList/CSIcon';
import { BalanceInputLabel } from '~atoms/BalanceInput/components/BalanceInputLabel';
import { useStyles } from './BalanceInput.styles';
var DEFAULT_VALUE = 10;
var MIN_VALUE = 5;
var MAX_VALUE = 1000;
export var BalanceInput = function (_a) {
    var amount = _a.amount, currency = _a.currency, labelValue = _a.labelValue, setValue = _a.setValue;
    var classes = useStyles();
    var t = useTranslation('tip-game').t;
    var balanceRange = "".concat(MIN_VALUE, " - ").concat(MAX_VALUE);
    var buttonValues = ['10', '20', '50', '100', '200', '500'];
    var reg = /^\d{1,6}((\.|,)?)(\d{1,2})?$/;
    var labelText = "".concat(t('payments.amount'), " ").concat(balanceRange);
    var _b = useState(DEFAULT_VALUE), inputValue = _b[0], setInputValue = _b[1];
    useEffect(function () {
        if (amount) {
            setInputValue(amount);
        }
    }, [amount]);
    var onChange = function (event) {
        var eventValue = event.target.value;
        var newValue = eventValue.replace(',', '.');
        if ((newValue.match(reg) || newValue === '') &&
            newValue !== '0' &&
            Number(newValue) <= MAX_VALUE) {
            setInputValue(newValue);
            if (setValue) {
                setValue(newValue);
            }
        }
    };
    var onSetInputValue = function (value) {
        setInputValue(value);
        if (setValue) {
            setValue(value);
        }
    };
    return (React.createElement("div", { className: classes.container },
        React.createElement("div", { className: classes.inputWrapper },
            React.createElement("input", { value: amount || inputValue, className: classes.input, onChange: onChange, autoComplete: "off", 
                // onKeyDown={onKeyDown}
                type: "text", inputMode: "decimal" }),
            React.createElement("label", { className: classes.label },
                labelText,
                currency && !labelValue ? (React.createElement(Currency, { currency: currency })) : (React.createElement(CSIcon, { size: "sm" }))),
            labelValue && (React.createElement(BalanceInputLabel, { value: labelValue, currency: currency }))),
        React.createElement("div", { className: classes.buttonsWrapper }, buttonValues.map(function (num) { return (React.createElement(BalanceInputButton, { key: num, value: num, isActive: inputValue == num, onClick: function () { return onSetInputValue(num); } })); }))));
};
