import makeStyles from '@material-ui/core/styles/makeStyles';
export var useStyles = makeStyles(function (theme) {
    var _a;
    return ({
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
        },
        balanceCardWrapper: {
            display: 'flex',
            justifyContent: 'space-between',
            columnGap: '10px',
            marginBottom: '24px',
        },
        gameTypeButtons: (_a = {
                marginTop: '20px'
            },
            _a[theme.breakpoints.up('lg')] = {
                '& > div': {
                    justifyContent: 'flex-start',
                    '& > div': {
                        marginRight: '19px',
                    },
                },
            },
            _a),
        description: {
            marginTop: '30px',
            fontSize: '18px',
            color: '#F2F2F2',
            '& span': {
                display: 'inline-flex',
                textTransform: 'capitalize',
                fontWeight: 700,
            },
        },
    });
}, { name: 'TopUpTab' });
