import makeStyles from '@material-ui/core/styles/makeStyles';
export var useFooterStyles = makeStyles(function (theme) { return ({
    footer: {
        backgroundColor: '#151515',
        zIndex: 5,
        position: 'sticky',
        minHeight: 72,
        paddingTop: 48,
        paddingBottom: 32,
    },
    align: {
        maxWidth: 1228,
        margin: '0 auto',
    },
    desktopLogo: {
        width: 139,
        height: 40,
        padding: '12px 81px 12px 32px',
        position: 'absolute',
        left: 0,
        top: 36,
        cursor: 'pointer',
    },
    tipGameDesktopLogo: {
        width: '164px',
        height: '32px',
    },
    footerGrid: {
        display: 'flex',
    },
    footerColumn: {
        width: '25%',
        paddingRight: 110,
    },
    footerColumnTitle: {
        fontWeight: 500,
        fontSize: 16,
        lineHeight: '18px',
        color: '#fff',
        marginBottom: 24,
    },
    footerColumnLi: {
        marginBottom: 12,
        '& :hover': {
            color: '#e5e5e5',
        },
    },
    footerColumnLink: {
        fontWeight: 'normal',
        fontSize: 16,
        lineHeight: '18px',
        color: theme.variables.linkColor.color,
        '&.active': {
            color: '#fff',
        },
        textDecoration: 'none',
    },
    footerSocialList: {
        display: 'flex',
        padding: 0,
    },
    footerSocial: {
        marginLeft: 24,
        fontSize: 30,
        '&:first-child': {
            marginLeft: 0,
        },
        '& a': {
            color: '#fff',
        },
        '& img': {
            width: '100%',
            height: '30px',
            verticalAlign: 'sub',
        }
    },
    hasImage: {
        fontSize: 'inherit',
        display: 'flex',
        alignItems: 'center',
    },
    iconListWrap: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 32,
    },
    iconList: {
        display: 'inline-flex',
        justifyContent: 'center',
        padding: 0,
    },
    iconListItem: {
        margin: '8px 20px 0',
        color: '#fff',
        fontSize: 28,
        '& a': {
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
        },
    },
    copyBox: {
        borderTop: '1px solid #8e8e8e66',
        paddingTop: 32,
        color: '#8E8E8E',
        textAlign: 'center',
    },
    copyBoxText: {
        fontSize: 14,
        lineHeight: '18px',
        marginBottom: 20,
    },
    copyBoxVersion: {
        fontSize: 12,
    },
    toggleBtn: {
        color: '#fff',
        marginTop: 38,
        fontSize: 25,
        display: 'flex',
        alignItems: 'center',
        outline: 'none',
    },
    toggleBtnText: {
        color: '#8E8E8E',
        marginLeft: 15,
        fontSize: 16,
    },
    chat: {
        margin: 0,
    },
}); }, { name: 'Footer' });
