import React from 'react';
import classNames from 'classnames';
import { useStyles } from './FavoritesIcon.styles';
var IconMap = {
    STAR: 'fa-star',
    TICKET: 'fa-receipt',
};
var FavoritesIcon = function (_a) {
    var _b = _a.icon, icon = _b === void 0 ? 'STAR' : _b, _c = _a.active, active = _c === void 0 ? false : _c;
    var classes = useStyles();
    return (React.createElement("i", { className: classNames('far', IconMap[icon], classes.icon, active && classes.active) }));
};
export { FavoritesIcon };
