import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './InvisibleRecaptcha.styles';
var InvisibleRecaptcha = function () {
    var classes = useStyles();
    var t = useTranslation('registration').t;
    var makeMessage = function (_a) {
        var message = _a.message, privacyPolicy = _a.privacyPolicy, termsOfService = _a.termsOfService;
        var privacyPolicyIndex = message.indexOf('$1');
        var termsOfServiceIndex = message.indexOf('$2');
        var isPrivacyPolicyFirst = privacyPolicyIndex < termsOfServiceIndex;
        var privacyPolicyLink = (React.createElement("a", { key: "privacyPolicyLink", href: "https://policies.google.com/privacy" }, privacyPolicy));
        var termsOfServiceLink = (React.createElement("a", { key: "termsOfServiceLink", href: "https://policies.google.com/terms" }, termsOfService));
        var links = isPrivacyPolicyFirst
            ? [privacyPolicyLink, termsOfServiceLink]
            : [termsOfServiceLink, privacyPolicyLink];
        var textParts = [privacyPolicyIndex, termsOfServiceIndex]
            .sort()
            .reduce(function (acc, partIndex, i, arr) {
            if (partIndex === -1) {
                return acc;
            }
            var prevPartIndex = i === 0 ? 0 : arr[i - 1] + 2;
            var isLast = arr.length - 1 === i;
            var part = message.slice(prevPartIndex, partIndex);
            acc.push(part);
            if (isLast) {
                acc.push(message.slice(partIndex + 2));
            }
            return acc;
        }, []);
        return (React.createElement(React.Fragment, null, textParts.map(function (part, i) { return (React.createElement(React.Fragment, { key: i },
            React.createElement("span", { key: part }, part),
            links[i])); })));
    };
    makeMessage({
        message: t('recaptcha.message', {
            privacyPolicy: '$1',
            termsOfService: '$2',
        }),
        privacyPolicy: t('recaptcha.privacyPolicy'),
        termsOfService: t('recaptcha.termsOfService'),
    });
    if (!process.env.RECAPTCHA_KEY) {
        return null;
    }
    return (React.createElement("div", { className: classes.container }, makeMessage({
        message: t('recaptcha.message', {
            privacyPolicy: '$1',
            termsOfService: '$2',
        }),
        privacyPolicy: t('recaptcha.privacyPolicy'),
        termsOfService: t('recaptcha.termsOfService'),
    })));
};
export { InvisibleRecaptcha };
