import makeStyles from '@material-ui/core/styles/makeStyles';
var useStyles = makeStyles(function () { return ({
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        width: '98.5vw',
    },
    border: {
        width: '100%',
        maxWidth: '415px',
        marginBottom: '1px',
        border: '3px solid #8E8E8E',
    },
    content: {
        maxWidth: '343px',
        margin: 'auto',
        marginTop: '16px',
        marginBottom: '16px',
    },
    mainContent: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    topContent: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '12px',
    },
    numberButtons: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '255px',
    },
    okButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '54px',
        height: '92px',
        marginLeft: '1px',
        border: '1px solid #2AA1F7',
        textTransform: 'uppercase',
        fontSize: '14px',
    },
}); }, { name: 'BetslipKeyboard' });
export { useStyles };
