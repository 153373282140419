var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { useTranslation } from 'react-i18next';
import { noop } from '~utils/noop';
import { selectBetslipCombinations } from '~slices/betslip';
import { useStructuredSelector } from '~hooks';
import { GreenCheckbox } from './GreenCheckbox';
import { useStyles } from './CombinationDropDown.styles';
var CombinationDropDown = function (_a) {
    var _b = _a.open, open = _b === void 0 ? false : _b, _c = _a.anchorEl, anchorEl = _c === void 0 ? null : _c, _d = _a.onClose, onClose = _d === void 0 ? noop : _d, _e = _a.onListKeyDown, onListKeyDown = _e === void 0 ? noop : _e, _f = _a.onToggleCombination, onToggleCombination = _f === void 0 ? noop : _f;
    var classes = useStyles();
    var t = useTranslation('ticket').t;
    var combinations = useStructuredSelector({
        combinations: selectBetslipCombinations,
    }).combinations;
    var getCombinationName = function (_a) {
        var _b = _a.bss, _c = _b === void 0 ? {} : _b, _d = _c.bnk, bnk = _d === void 0 ? 0 : _d, _e = _c.bssn, bssn = _e === void 0 ? 'SINGLE' : _e, _f = _c.num, num = _f === void 0 ? 0 : _f, _g = _c.den, den = _g === void 0 ? 0 : _g, _h = _a.nbet, nbet = _h === void 0 ? 0 : _h;
        var betsCount = "(".concat(t('counters.bet', { count: nbet }), ")");
        var bankersCount = bnk ? "+ ".concat(t('counters.banker', { count: bnk })) : '';
        var betTypes = {
            SINGLE: function () { return "".concat(t('bet_type.SINGLE'), " ").concat(betsCount); },
            BANKER_ONLY: function () { return "".concat(t('bet_type.BANKER_ONLY'), " ").concat(betsCount); },
            COMBINATION: function () {
                return "".concat(den + bnk, "x ").concat(t('bet_type.COMBINATION'), " ").concat(betsCount);
            },
            SYSTEM: function () {
                return "".concat(t('system_fraction', {
                    num: num,
                    den: den,
                }), " ").concat(bankersCount, " ").concat(betsCount);
            },
        };
        return betTypes[bssn]();
    };
    return (React.createElement(Popper, { open: open, anchorEl: anchorEl, placement: "bottom-start", role: undefined, transition: true, disablePortal: true, style: {
            zIndex: 1,
        } }, function (_a) {
        var TransitionProps = _a.TransitionProps, placement = _a.placement;
        return (React.createElement(Grow, __assign({}, TransitionProps, { style: {
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            } }),
            React.createElement(Paper, { className: classes.systemModal },
                React.createElement(ClickAwayListener, { onClickAway: onClose },
                    React.createElement(MenuList, { autoFocusItem: open, disablePadding: true, id: "menu-list-grow", onKeyDown: onListKeyDown },
                        React.createElement(FormGroup, null, combinations.map(function (combination, index) { return (React.createElement(FormControlLabel
                        // eslint-disable-next-line react/no-array-index-key
                        , { 
                            // eslint-disable-next-line react/no-array-index-key
                            key: index, className: classes.checkboxLabel, control: React.createElement(GreenCheckbox, { checked: combination.ssel || combination.usel, onChange: function () { return onToggleCombination(combination); } }), label: getCombinationName(combination) })); })))))));
    }));
};
export { CombinationDropDown };
