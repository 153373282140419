var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import get from 'lodash.get';
import { Field } from 'redux-form';
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import { useAutoCompleteStyles } from './Autocomplete.styles';
// TODO refactor after rewriting registration form
export var CustomAutocomplete = function (_a) {
    var input = _a.input, meta = _a.meta, title = _a.title, options = _a.options, name = _a.name, required = _a.required, onInputSelect = _a.onInputSelect, placeholder = _a.placeholder, normalize = _a.normalize;
    var classes = useAutoCompleteStyles();
    var methods = useFormContext();
    var value = input ? input.value : '';
    var helperText = meta && meta.touched ? meta.error : '';
    if (name && methods) {
        value = methods.getValues(name);
        helperText = get(methods.errors, name)
            ? get(methods.errors, name).message
            : '';
    }
    var _b = useState(value), inputValue = _b[0], setInputValue = _b[1];
    return (React.createElement("div", null,
        React.createElement("div", { className: classes.selectBoxTitle },
            title,
            " ",
            required && '*'),
        React.createElement(Autocomplete, { fullWidth: true, freeSolo: true, options: options, inputValue: inputValue, classes: {
                option: classes.option,
                inputRoot: classes.input,
                input: classes.input,
                popper: classes.popper,
                endAdornment: classes.endAdornment,
            }, value: value, disableClearable: true, onInputChange: function (event, rawNewValue) {
                var newValue = normalize ? normalize(rawNewValue) : rawNewValue;
                setInputValue(newValue);
                if (name && methods) {
                    methods.setValue(name, newValue);
                    methods.triggerValidation(name);
                    onInputSelect(newValue, name || input.name);
                }
                else {
                    onInputSelect(newValue, name || input.name);
                    input.onChange(newValue);
                }
            }, autoHighlight: false, popupIcon: React.createElement("i", { className: "far fa-chevron-down icon" }), getOptionLabel: function (option) { return option; }, renderOption: function (option) { return React.createElement("span", null, option); }, renderInput: function (params) { return (React.createElement(TextField, __assign({}, params, { style: { width: '100%' }, name: name, onBlur: input === null || input === void 0 ? void 0 : input.onBlur, error: methods
                    ? !!get(methods.errors, name)
                    : meta.touched && meta.invalid, helperText: helperText, label: placeholder, placeholder: placeholder, variant: "outlined", InputLabelProps: {
                    shrink: true,
                }, inputRef: methods ? methods.register : undefined }))); } })));
};
export var CustomSelectField = function (_a) {
    var name = _a.name, title = _a.title, placeholder = _a.placeholder, required = _a.required, _b = _a.options, options = _b === void 0 ? [] : _b, validators = _a.validators, onSelect = _a.onSelect, normalize = _a.normalize;
    return (React.createElement(Field, { name: name, component: CustomAutocomplete, props: { normalize: normalize }, required: required, title: title, options: options, placeholder: placeholder, validate: validators, onInputSelect: onSelect }));
};
