var _a;
import { createSelector } from 'reselect';
import { createBaseSelectors } from '~store/utils';
import { NON_DROPDOWN_TOURNAMENTS_COUNT } from '~constants/view/quantities';
import { isOutrightsTabDisabledSelector } from '~slices/outrights';
import { name } from './constants';
export var selectTournamentsListData = (_a = createBaseSelectors(name), _a.selectTournamentsListData), selectTournamentsListError = _a.selectTournamentsListError, selectTournamentsListLoadingStatus = _a.selectTournamentsListLoadingStatus, selectTournamentsListState = _a.selectTournamentsListState;
export var isTournamentDropDownAvailableByCountSelector = function (state) {
    return selectTournamentsListData(state).length > NON_DROPDOWN_TOURNAMENTS_COUNT;
};
export var shouldDisplayTournamentsDropDownSelector = createSelector(isOutrightsTabDisabledSelector, isTournamentDropDownAvailableByCountSelector, function (isOutrightsTabDisabled, isAvailableByCount) {
    return !isOutrightsTabDisabled || isAvailableByCount;
});
