var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
import { createAsyncReducers } from '~utils/createAsyncReducers';
import { getPlayerAccounts } from "~slices/playerAccounts/thunks";
import { name } from './constants';
var initialState = {
    data: [],
    loading: 'idle',
    error: null,
    meta: {
        rewardedLoaded: false,
    },
};
export var playerAccounts = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        setPlayerAccounts: function (state, _a) {
            var payload = _a.payload;
            return __assign(__assign({}, state), { data: __assign(__assign({}, state.data), { data: __assign(__assign({}, state.data), payload) }) });
        },
        setRewardedLoadedStatus: function (state, _a) {
            var payload = _a.payload;
            return __assign(__assign({}, state), { meta: {
                    rewardedLoaded: payload,
                } });
        }
    },
    extraReducers: function (builder) {
        var _a = createAsyncReducers(), pending = _a.pending, fulfilled = _a.fulfilled, rejected = _a.rejected;
        builder
            .addCase(getPlayerAccounts.pending, pending)
            .addCase(getPlayerAccounts.fulfilled, fulfilled)
            .addCase(getPlayerAccounts.rejected, rejected);
    },
});
export var setPlayerAccounts = (_a = playerAccounts.actions, _a.setPlayerAccounts), setRewardedLoadedStatus = _a.setRewardedLoadedStatus;
