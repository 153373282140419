import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { CheckboxField } from '~atoms';
import { checkIfFieldIsRequired, requiredCheckbox, validateField, } from '~utils/formValidators';
export var TransferDataToBPAgreement = function (_a) {
    var tabIndex = _a.tabIndex, registrationBlocks = _a.registrationBlocks, checkboxClassName = _a.checkboxClassName;
    var t = useTranslation('registration').t;
    return (React.createElement(Grid, { item: true, style: { width: '100%' } },
        React.createElement(CheckboxField, { className: checkboxClassName, name: "userProfileDto.dataTransferToBp", title: t('form.bp_agree'), validators: function (value) {
                return validateField(value, 'transferDataToBPAccepted', tabIndex, requiredCheckbox, registrationBlocks);
            }, required: checkIfFieldIsRequired('transferDataToBPAccepted', tabIndex, registrationBlocks) })));
};
