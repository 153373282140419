import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var BandyIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, viewBox: "0 0 28 28" },
        React.createElement("svg", { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M28 14C28 14.1948 28 14.3896 27.9878 14.5843C27.68 21.8556 21.8556 27.68 14.5844 27.9878C14.3896 28 14.1948 28 14 28C13.8053 28 13.5861 28 13.3792 27.9878C6.13679 27.6602 0.339823 21.8632 0.0121549 14.6209C0 14.414 0 14.207 0 14C0 13.793 0 13.5983 0.0121549 13.4035C0.324986 6.13652 6.14837 0.318367 13.4156 0.0121549C13.6104 0 13.8052 0 14 0C14.2191 0 14.4261 0 14.633 0.0121549C21.8669 0.343704 27.6562 6.13309 27.9878 13.367C28 13.574 28 13.7809 28 14Z", fill: "#FF7E47" }),
            React.createElement("path", { d: "M27.9878 14.5843C28 14.3896 28 14.1948 28 14C28 13.7809 28 13.574 27.9878 13.367C27.926 12.0192 27.6748 10.7215 27.26 9.5C25.9799 18.8459 17.7864 25.675 8.36281 25.2504L5.5 25.1214C7.70208 26.807 10.42 27.854 13.3792 27.9878C13.5861 28 13.8052 28 13.9999 28H14H14.0001C14.1948 28 14.3896 28 14.5844 27.9878C21.8556 27.68 27.68 21.8556 27.9878 14.5843Z", fill: "#E26634" }),
            React.createElement("circle", { cx: "14.5", cy: "2.5", r: "1.5", fill: "#D95723" }),
            React.createElement("ellipse", { cx: "10.35", cy: "6.5", rx: "1.35", ry: "1.5", transform: "rotate(35.8184 10.35 6.5)", fill: "#D95723" }),
            React.createElement("ellipse", { cx: "6.84494", cy: "9.92059", rx: "1.35", ry: "1.5", transform: "rotate(24.1088 6.84494 9.92059)", fill: "#D95723" }),
            React.createElement("ellipse", { cx: "2.84494", cy: "13.9206", rx: "1.35", ry: "1.5", transform: "rotate(24.1088 2.84494 13.9206)", fill: "#D95723" }),
            React.createElement("circle", { cx: "7.96362", cy: "15.5", r: "1.5", fill: "#D95723" }),
            React.createElement("circle", { cx: "11.5", cy: "19.5", r: "1.5", fill: "#D95723" }),
            React.createElement("ellipse", { cx: "3.81365", cy: "18.5", rx: "1.35", ry: "1.5", transform: "rotate(-22.0029 3.81365 18.5)", fill: "#D95723" }),
            React.createElement("ellipse", { cx: "7.01299", cy: "22.9869", rx: "1.35", ry: "1.5", transform: "rotate(-52.0692 7.01299 22.9869)", fill: "#D95723" }),
            React.createElement("ellipse", { cx: "20.4811", cy: "4.01131", rx: "1.35", ry: "1.5", transform: "rotate(-46.6679 20.4811 4.01131)", fill: "#D95723" }),
            React.createElement("ellipse", { cx: "12.5", cy: "25.163", rx: "1.5", ry: "1.35", fill: "#D95723" }),
            React.createElement("ellipse", { cx: "19.9443", cy: "24.9448", rx: "1.25", ry: "1.5", transform: "rotate(44.9189 19.9443 24.9448)", fill: "#D95723" }),
            React.createElement("ellipse", { cx: "24.9443", cy: "19.9448", rx: "1.25", ry: "1.5", transform: "rotate(44.9189 24.9443 19.9448)", fill: "#D95723" }),
            React.createElement("ellipse", { cx: "7.94426", cy: "2.94479", rx: "1.25", ry: "1.5", transform: "rotate(44.9189 7.94426 2.94479)", fill: "#D95723" }),
            React.createElement("ellipse", { cx: "3.94426", cy: "6.94479", rx: "1.25", ry: "1.5", transform: "rotate(44.9189 3.94426 6.94479)", fill: "#D95723" }),
            React.createElement("ellipse", { cx: "18.0064", cy: "22.018", rx: "1.35", ry: "1.5", transform: "rotate(41.8483 18.0064 22.018)", fill: "#D95723" }),
            React.createElement("ellipse", { cx: "22.9114", cy: "16.9688", rx: "1.35", ry: "1.5", transform: "rotate(29.3065 22.9114 16.9688)", fill: "#D95723" }),
            React.createElement("ellipse", { cx: "23.7923", cy: "6.87973", rx: "1.35", ry: "1.5", transform: "rotate(-20.6512 23.7923 6.87973)", fill: "#D95723" }),
            React.createElement("ellipse", { cx: "25.3627", cy: "11.5248", rx: "1", ry: "1.5", transform: "rotate(1.44843 25.3627 11.5248)", fill: "#D95723" }),
            React.createElement("circle", { cx: "16.5", cy: "7.5", r: "1.5", fill: "#D95723" }),
            React.createElement("circle", { cx: "20.5", cy: "11.5", r: "1.5", fill: "#D95723" }),
            React.createElement("circle", { cx: "12.5", cy: "11.5", r: "1.5", fill: "#D95723" }),
            React.createElement("circle", { cx: "16.5", cy: "15.5", r: "1.5", fill: "#D95723" }),
            React.createElement("path", { d: "M14 28C6.26799 28 0 21.732 0 14C0 6.26799 6.26799 0 14 0C21.732 0 28 6.26799 28 14C27.9913 21.7284 21.7284 27.9913 14 28ZM14 1.21737C6.94034 1.21737 1.21737 6.94034 1.21737 14C1.21737 21.0597 6.94034 26.7826 14 26.7826C21.0597 26.7826 26.7826 21.0597 26.7826 14C26.7746 6.9437 21.0564 1.22542 14 1.21737Z", fill: "black" }))));
};
