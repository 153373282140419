import makeStyles from '@material-ui/core/styles/makeStyles';
var useStyles = makeStyles({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '15px',
        minWidth: '15px',
        maxWidth: '15px',
        height: '15px',
        minHeight: '15px',
        maxHeight: '15px',
        marginRight: '8px',
    },
    clickable: {
        cursor: 'pointer',
    },
}, { name: 'EventStatus' });
export { useStyles };
