import { useSelector } from 'react-redux';
import { name } from './constants';
export var gameTypesSelector = function (state) { return state[name]; };
export var gameTypesDataSelector = function (state) {
    return gameTypesSelector(state).data;
};
export var activeGameDataSelector = function (state) {
    return gameTypesSelector(state).meta.activeGame;
};
export var selectedGameDataSelector = function (state) {
    return gameTypesSelector(state).meta.selectedGame;
};
export var selectedGroupDataSelector = function (state) {
    return gameTypesSelector(state).meta.selectedGroup;
};
export var selectedGameTypeSelector = function (state) {
    return gameTypesSelector(state).meta.selectedGameType;
};
export var selectedTournamentSelector = function (state) {
    return gameTypesSelector(state).meta.tournament;
};
export var isEasyGameSelector = function (state) {
    return gameTypesSelector(state).meta.isEasyGame;
};
export var isEasyTipSelector = function (state) {
    return gameTypesSelector(state).meta.isEasyTip;
};
export var gameTypesLoadingSelector = function (state) {
    return gameTypesSelector(state).loading;
};
export var useGameTypesDataSelector = function () {
    return useSelector(gameTypesDataSelector);
};
export var useActiveGameSelector = function () {
    return useSelector(activeGameDataSelector);
};
export var useSelectedGroupSelector = function () {
    return useSelector(selectedGroupDataSelector);
};
export var useSelectedGameDataSelector = function () {
    return useSelector(selectedGameDataSelector);
};
export var useSelectedGameTypeSelector = function () {
    return useSelector(selectedGameTypeSelector);
};
export var useTournamentSelector = function () {
    return useSelector(selectedTournamentSelector);
};
export var useIsEasyGameSelector = function () {
    return useSelector(isEasyGameSelector);
};
export var useIsEasyTipSelector = function () {
    return useSelector(isEasyTipSelector);
};
export var useGameTypesLoadingSelector = function () {
    return useSelector(gameTypesLoadingSelector);
};
