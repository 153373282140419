export var randomColor = function () {
    var r = Math.floor(Math.random() * 256);
    var g = Math.floor(Math.random() * 256);
    var b = Math.floor(Math.random() * 256);
    // HSP (Highly Sensitive Poo)
    var hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
    if (hsp < 127.5) {
        return "rgb(".concat(r, ",").concat(g, ",").concat(b, ")");
    }
    return randomColor();
};
