import { MAX_WAGER_VALUE, MIN_WAGER_PER_BET_LINE_VALUE, MIN_WAGER_VALUE, } from '~constants/view/wagerValues';
import { round } from '~utils/math';
var noError = {
    error: '',
    lowestValue: MIN_WAGER_VALUE,
    highestValue: MAX_WAGER_VALUE,
};
export var getWagerError = function (_a) {
    var value = _a.value, betlines = _a.betlines;
    var minValueForBetlines = round(MIN_WAGER_PER_BET_LINE_VALUE * betlines, 2);
    var highestValue = MAX_WAGER_VALUE;
    if (Number.isNaN(value)) {
        return {
            error: 'emptyWager',
            lowestValue: MIN_WAGER_VALUE,
            highestValue: highestValue,
        };
    }
    if (value > MAX_WAGER_VALUE) {
        return {
            error: 'highWager',
            lowestValue: MIN_WAGER_VALUE,
            highestValue: highestValue,
        };
    }
    if (value < MIN_WAGER_VALUE) {
        return {
            error: 'lowWager',
            lowestValue: MIN_WAGER_VALUE,
            highestValue: highestValue,
        };
    }
    if (value < minValueForBetlines) {
        return {
            error: 'lowWagerMultiBetlines',
            lowestValue: minValueForBetlines,
            highestValue: highestValue,
        };
    }
    return noError;
};
