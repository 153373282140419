import { getLocation } from 'connected-react-router';
import { createSelector } from '@reduxjs/toolkit';
import { matchPath } from 'react-router-dom';
import i18next from 'i18next';
import { selectedTournamentSelector } from "~slices/flags";
/**
 * Matchers
 */
var matchActiveTournamentName = function (pathname) {
    var _a, _b;
    var currentLang = i18next.language;
    var path = "/".concat(currentLang, "/home/:sportName/:flagName/:tournamentName");
    var match = matchPath(pathname, path);
    return ((_a = match === null || match === void 0 ? void 0 : match.params) === null || _a === void 0 ? void 0 : _a.tournamentName) === 'outrights'
        ? 'outrights'
        : ((_b = match === null || match === void 0 ? void 0 : match.params) === null || _b === void 0 ? void 0 : _b.tournamentName) || '';
};
var matchActiveOutrightsTournamentName = function (pathname) {
    var _a;
    var currentLang = i18next.language;
    var path = "/".concat(currentLang, "/home/:flagName/outrights/:tournamentName");
    var match = matchPath(pathname, path);
    return ((_a = match === null || match === void 0 ? void 0 : match.params) === null || _a === void 0 ? void 0 : _a.tournamentName) || '';
};
/**
 * Selectors
 */
// export const selectUrl = (state: RootState) => getLocation(state).
export var selectPathname = function (state) { return getLocation(state).pathname; };
export var selectActiveTournamentId = function (state) { var _a, _b; return ((_a = selectedTournamentSelector(state)) === null || _a === void 0 ? void 0 : _a.id) || ((_b = selectedTournamentSelector(state)) === null || _b === void 0 ? void 0 : _b.name) || ''; };
export var selectActiveOutrightsTournamentName = createSelector(selectPathname, matchActiveOutrightsTournamentName);
export var selectSpecifiedActiveTournamentId = createSelector(selectPathname, function (state, isOutrights) { return isOutrights; }, function (pathname, isOutrights) {
    return isOutrights
        ? matchActiveOutrightsTournamentName(pathname)
        : matchActiveTournamentName(pathname);
});
export var selectIsTournamentActive = createSelector(function (state, tournamentName, isOutrights) {
    return selectSpecifiedActiveTournamentId(state, isOutrights);
}, function (state, tournamentName) { return tournamentName; }, function (activeTournamentName, tournamentName) { return activeTournamentName === tournamentName; });
