export var name = 'events';
export var UPDATE_TYPE_MATCH = 'MATCH';
export var UPDATE_TYPE_ODD = 'ODD';
export var SPORT_EVENTS_SOCKET_PATH = 'sport-events/v1/ws/';
export var LIVE_SOCKET_NAME = 'liveScoreStatistic';
export var LIVE_SOCKET_SOON_LIVE_SECTION_NAME = 'liveScoreStatistic2';
export var SOON_LIVE_SOCKET_NAME = 'soonLive';
export var HIGHLIGHTS_SOCKET_NAME = 'highlightsStatistic';
export var MATCHDAY_SOCKET_NAME = 'matchday';
export var FAVOURITES_SOCKET_NAME = 'favorites';
export var BETSLIP_SOCKET_NAME = 'betSlip';
export var EVENTS_SOCKETS_NAMES = [
    LIVE_SOCKET_NAME,
    LIVE_SOCKET_SOON_LIVE_SECTION_NAME,
    SOON_LIVE_SOCKET_NAME,
    HIGHLIGHTS_SOCKET_NAME,
    MATCHDAY_SOCKET_NAME,
    FAVOURITES_SOCKET_NAME,
    BETSLIP_SOCKET_NAME,
];
export var ENDED_EVENT_LIFETIME = 30;
export var ENDED_EVENT_WAITING_FOR_ACTION = 'waitingForAction';
export var ENDED_EVENT_AFTER_THE_ACTION = 'afterTheAction';
