import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { LOGOUT_PRESS } from '~constants/cpa-message-codes';
import { useAppDispatch } from '~store';
import { logout, refresh } from '~slices/auth';
import { acceptTerms, resetTermsState } from '~slices/terms';
import { BottomButtons } from '~atoms/bottomButton';
import { getPathWithLanguageCode } from '~utils/languageInURL/getPathWithLanguageCode';
import { PLATFORMS } from '~slices/teasers';
import { usePlatofrmType } from '~hooks';
import { resetGames } from '~slices/gameTypes';
var useStyles = makeStyles(function (theme) {
    var _a, _b;
    return ({
        termsBlock: (_a = {},
            _a[theme.breakpoints.up('lg')] = {
                marginLeft: '-20px',
                width: 'calc(100% + 42px)',
                position: 'sticky',
            },
            _a),
        button: (_b = {
                minWidth: '120px',
                width: '100%'
            },
            _b[theme.breakpoints.up('lg')] = {
                minWidth: '160px',
            },
            _b),
    });
}, { name: 'TermsButtons' });
export var TermsButtons = function () {
    var classes = useStyles();
    var tc = useTranslation('header').t;
    var dispatch = useAppDispatch();
    var platform = usePlatofrmType();
    var onAccept = function () {
        dispatch(acceptTerms()).then(function () { return dispatch(refresh()); });
    };
    var onLogout = function () {
        dispatch(logout());
        dispatch(resetGames());
        if (platform === PLATFORMS.CROSS_PLATFORM_MOBILE) {
            // @ts-expect-error
            window.ReactNativeWebView.postMessage(JSON.stringify({ code: LOGOUT_PRESS }));
        }
        dispatch(resetTermsState());
        dispatch(push(getPathWithLanguageCode('/home')));
    };
    return (React.createElement(BottomButtons, { className: classes.termsBlock },
        React.createElement(Grid, { container: true, justify: "space-between", spacing: 2 },
            React.createElement(Grid, { key: "cancel", item: true },
                React.createElement(Button, { variant: "contained", classes: { root: classes.button }, onClick: onLogout }, tc('logout'))),
            React.createElement(Grid, { key: "next", item: true },
                React.createElement(Button, { variant: "contained", classes: { root: classes.button }, color: "primary", onClick: onAccept }, tc('accept'))))));
};
