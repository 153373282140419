var _a;
import { reducer as formReducer } from 'redux-form';
import { connectRouter } from 'connected-react-router';
import { combineReducers } from '@reduxjs/toolkit';
import { achievements } from '~slices/achievements/slice';
import { auth, authPersistedReducer } from '~slices/auth';
import { application } from '~slices/application';
import { betslip } from '~slices/betslip';
import { account } from '~slices/account';
import { events } from '~slices/events';
import { locale } from '~slices/locale';
import { outrights } from '~slices/outrights';
import { sport } from '~slices/sports';
import { options, optionsPersistedReducer } from '~slices/options';
import { shortcut } from '~slices/shortcut';
import { terms } from '~slices/terms';
import { bp } from '~slices/bp';
import { about } from '~slices/about';
import modal from '~modals/modalSlice';
import { history } from '~utils/history';
import { sidebets } from '~slices/sidebets';
import { ticket } from '~slices/tickets';
import { topRanking } from '~slices/top-ranking';
import { PPMO } from '~slices/selectPPMO';
import { cashOut } from '~slices/cashOut';
import { teasers } from '~slices/teasers';
import { markets } from '~slices/markets';
import { sockets } from '~slices/sockets';
import { hints } from '~slices/hints';
import { view } from '~slices/view';
import { idsMap } from '~slices/idsMap';
import { tournamentsInfo } from '~slices/tournamentsInfo';
import { tournamentsList } from '~slices/tournamentsList';
import { flags } from '~slices/flags';
import { activePlaySession } from '~slices/activePlaySession/slice';
import { userCountryLanguage } from '~slices/userCountryLanguage';
import { gameTypes } from '~slices/gameTypes';
import { rankingList } from '~slices/ranking';
import { playerAccounts } from '~slices/playerAccounts';
var rootReducer = combineReducers((_a = {
        form: formReducer,
        router: connectRouter(history),
        modal: modal
    },
    _a[account.name] = account.reducer,
    _a[application.name] = application.reducer,
    _a[auth.name] = authPersistedReducer,
    _a[about.name] = about.reducer,
    _a[betslip.name] = betslip.reducer,
    _a[outrights.name] = outrights.reducer,
    _a[events.name] = events.reducer,
    _a[locale.name] = locale.reducer,
    _a[shortcut.name] = shortcut.reducer,
    _a[sport.name] = sport.reducer,
    _a[options.name] = optionsPersistedReducer,
    _a[terms.name] = terms.reducer,
    _a[bp.name] = bp.reducer,
    _a[sidebets.name] = sidebets.reducer,
    _a[ticket.name] = ticket.reducer,
    _a[topRanking.name] = topRanking.reducer,
    _a[PPMO.name] = PPMO.reducer,
    _a[cashOut.name] = cashOut.reducer,
    _a[teasers.name] = teasers.reducer,
    _a[markets.name] = markets.reducer,
    _a[sockets.name] = sockets.reducer,
    _a[hints.name] = hints.reducer,
    _a[view.name] = view.reducer,
    _a[idsMap.name] = idsMap.reducer,
    _a[tournamentsInfo.name] = tournamentsInfo.reducer,
    _a[tournamentsList.name] = tournamentsList.reducer,
    _a[flags.name] = flags.reducer,
    _a[activePlaySession.name] = activePlaySession.reducer,
    _a[userCountryLanguage.name] = userCountryLanguage.reducer,
    _a[gameTypes.name] = gameTypes.reducer,
    _a[rankingList.name] = rankingList.reducer,
    _a[playerAccounts.name] = playerAccounts.reducer,
    _a[achievements.name] = achievements.reducer,
    _a));
export default rootReducer;
