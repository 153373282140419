import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import MuiList from '@material-ui/core/List/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction/ListItemSecondaryAction';
import useStyles from '../Outrights.styles';
export var List = function (_a) {
    var list = _a.list, onClick = _a.onClick, onBackClick = _a.onBackClick, renderValue = _a.renderValue, header = _a.header, className = _a.className, isTournaments = _a.isTournaments, _b = _a.withBackButton, withBackButton = _b === void 0 ? false : _b;
    var classes = useStyles();
    var t = useTranslation('common').t;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classes.header },
            withBackButton && (React.createElement(Button, { className: classes.backButton, onClick: onBackClick, size: "small", startIcon: React.createElement("i", { className: "far fa-chevron-left ".concat(classes.backButtonIcon) }) }, t('back'))),
            React.createElement(Typography, { align: "center", variant: "h5", className: classes.title }, header || t('outrights'))),
        React.createElement(MuiList, { disablePadding: true }, list === null || list === void 0 ? void 0 : list.map(
        // @ts-expect-error
        function (item) {
            var _a;
            var categoryClick = function () {
                if (onClick) {
                    return onClick(item);
                }
                return undefined;
            };
            return (React.createElement(ListItem, { component: "div", button: true, key: item.id, classes: {
                    container: classes.listItemContainer,
                    root: classNames(className, classes.listItem),
                    button: classes.button,
                } },
                React.createElement(ListItemText, { classes: { primary: classes.listItemText } }, isTournaments ? item.on : item.tn || item.on || item.cn),
                React.createElement(ListItemSecondaryAction, null, renderValue ? (renderValue(item)) : (React.createElement(Button, { variant: "contained", size: "small", onClick: categoryClick, className: classes.listItemButton }, (item === null || item === void 0 ? void 0 : item.oc) || ((_a = item === null || item === void 0 ? void 0 : item.cmp) === null || _a === void 0 ? void 0 : _a.length) || (item === null || item === void 0 ? void 0 : item.vl))))));
        }))));
};
