import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import success from '~icons/statusIcons/success.svg';
import failed from '~icons/statusIcons/failed.svg';
import { getPathWithLanguageCode } from '~utils/languageInURL';
import { StatusScreen } from '~molecules';
import { useIsMobileDevise, useStructuredSelector } from '~hooks';
import { cashOutDataSelector } from '~slices/cashOut';
import { useStyles } from './DepositStatus.styles';
export var DepositStatus = function () {
    var t = useTranslation('tip-game').t;
    var classes = useStyles();
    var isMobileDevice = useIsMobileDevise();
    var search = useLocation().search;
    var params = new URLSearchParams(search);
    var amount = params.get('sum');
    // TODO, replace with real data from BE
    var _a = useStructuredSelector({ cashoutData: cashOutDataSelector }).cashoutData.currency, currency = _a === void 0 ? 'EUR' : _a;
    var isSuccess = Math.random() * 10 > 5;
    var logo = isSuccess ? success : failed;
    var header = isSuccess
        ? t('payments.deposit_successful')
        : t('payments.deposit_declined');
    var subheader = isSuccess
        ? "".concat(amount)
        : "".concat(t('payments.do_not_pay_to_agent'), ".");
    return (React.createElement("div", { className: classes.wrapper },
        React.createElement(StatusScreen, { header: header, subheader: subheader, currency: isSuccess ? currency : undefined, logo: logo, target: getPathWithLanguageCode('/home/all/live'), buttonLabel: t('continue'), subheaderClassName: isSuccess ? classes.subheader : '', isMobile: isMobileDevice })));
};
