import { createSlice } from '@reduxjs/toolkit';
import { createAsyncReducers } from '~utils/createAsyncReducers';
import { name } from './constants';
import { getDefaultPPMO } from './thunks';
var initialState = {
    data: {},
    loading: 'idle',
    error: null,
};
export var PPMO = createSlice({
    name: name,
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        var _a = createAsyncReducers(), pending = _a.pending, fulfilled = _a.fulfilled, rejected = _a.rejected;
        builder
            .addCase(getDefaultPPMO.pending, pending)
            .addCase(getDefaultPPMO.fulfilled, fulfilled)
            .addCase(getDefaultPPMO.rejected, rejected);
    },
});
