import React from 'react';
import classNames from 'classnames';
import { ListBody } from './ListBody';
import './list-body.scss';
export var List = function (_a) {
    var items = _a.items, renderItem = _a.renderItem, redirectToSdk = _a.redirectToSdk, children = _a.children, className = _a.className, selectedProviderId = _a.selectedProviderId;
    return (React.createElement("div", { className: classNames('flex-column main-list', className) },
        React.createElement(ListBody, { selectedProviderId: selectedProviderId, redirectToSdk: redirectToSdk, items: items, renderItem: renderItem }),
        children));
};
