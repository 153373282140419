import makeStyles from '@material-ui/core/styles/makeStyles';
export var useStyles = makeStyles({
    wrapper: {
        position: 'absolute',
        background: '#000',
        zIndex: 3,
        left: 0,
        top: 0,
        height: '100%',
        right: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    main: {
        overflowY: 'auto',
    },
    logoBox: {
        height: 52,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#000',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: '#F2F2F2',
        height: 40,
        padding: '0 16px',
        fontSize: 14,
    },
    info: {
        color: '#fff',
        padding: '12px 16px',
        fontSize: 14,
        margin: 0,
        '& .bold': {
            fontWeight: 700,
            marginBottom: 6,
        },
    },
    type: {
        textTransform: 'capitalize',
        marginRight: 8,
    },
    bets: {
        marginRight: 30,
    },
    indicators: {
        paddingBottom: '4px',
    },
    footer: {
        background: '#34343E',
    },
    cashoutBtn: {
        color: '#fff',
    },
    status: {
        fontWeight: 700,
        textTransform: 'capitalize',
    },
    stickyContainer: {
        position: 'sticky',
        top: 0,
        zIndex: 100,
    },
}, { name: 'TipDetails' });
