import React from 'react';
import { push } from 'connected-react-router';
import { API } from '~api';
import { useAppDispatch } from '~store';
import { checkIsExternalLink } from '~utils/checkIsExternalLink';
import { getPathWithLanguageCode } from '~utils/languageInURL';
import { useStyles } from './ConversionTeaser.styles';
var BACKGROUND_COLOR = process.env.TEASER_BACKGROUND_COLOR || '';
var duration = 1000;
export var ConversionTeaser = function (_a) {
    var id = _a.id, mediaUrl = _a.mediaUrl, linkUrl = _a.linkUrl;
    var classes = useStyles();
    var dispatch = useAppDispatch();
    var onTeaserClick = function () {
        if (!linkUrl) {
            return;
        }
        API.putConversionTeaserClick(id);
        checkIsExternalLink(linkUrl)
            ? window.open(linkUrl, '_blank')
            : dispatch(push(getPathWithLanguageCode("".concat(linkUrl))));
    };
    return (React.createElement("div", { className: classes.container, style: { background: BACKGROUND_COLOR } },
        React.createElement("img", { src: mediaUrl, className: classes.fadeIn, style: { animationDuration: "".concat(duration, "ms") }, onClick: onTeaserClick, alt: "teaser" })));
};
