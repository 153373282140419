var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import cn from 'classnames';
import { AnimationDuration } from '~constants/view/AnimationDuration';
import { useStyles } from './DropDown.styles';
var noop = function () { return undefined; };
var DropDown = function (_a) {
    var _b = _a.open, open = _b === void 0 ? false : _b, anchorEl = _a.anchorEl, children = _a.children, _c = _a.palette, palette = _c === void 0 ? 'dark' : _c, _d = _a.onClose, onClose = _d === void 0 ? noop : _d;
    var classes = useStyles();
    return (React.createElement(Popper, { open: open, style: {
            zIndex: 30,
        }, anchorEl: anchorEl, transition: true, placement: "bottom-end" }, function (_a) {
        var TransitionProps = _a.TransitionProps;
        return (React.createElement(Grow, __assign({}, TransitionProps, { timeout: AnimationDuration.SHORT.ms, style: {
                transformOrigin: 'right top',
            } }),
            React.createElement("div", null,
                React.createElement(ClickAwayListener, { onClickAway: onClose },
                    React.createElement(Paper, { className: cn(classes.content, palette === 'light' && classes.light) }, children)))));
    }));
};
export { DropDown };
