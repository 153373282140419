var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var withSideEffects = function (hooks) {
    return function (Component) {
        var sideEffects = hooks.map(function (hook) {
            var SideEffect = function (props) {
                hook(props);
                return null;
            };
            SideEffect.displayName = "".concat(hook.name, "SideEffect");
            return SideEffect;
        });
        var WithSideEffects = function (props) {
            return (React.createElement(React.Fragment, null,
                sideEffects.map(function (SideEffect) { return (React.createElement(SideEffect, __assign({ key: SideEffect.displayName }, props))); }),
                React.createElement(Component, __assign({}, props))));
        };
        return WithSideEffects;
    };
};
export { withSideEffects };
