import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, useFormContext } from 'react-hook-form';
import * as yup from 'yup';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { FormModal } from '~modals/components/FormModal';
import { TextInput } from '~atoms/inputs';
import { checkPasswordSelector } from '~slices/account';
import validationMessages from '~utils/validationMessages';
import { useStructuredSelector } from '~hooks';
var ChangeAccountModal = function (_a) {
    var modalName = _a.modalName, modalProps = _a.modalProps, isVisible = _a.isVisible;
    var methods = useForm({
        validationSchema: yup.object().shape({
            password: yup.string().required(validationMessages.required),
        }),
        defaultValues: {
            password: '',
        },
        mode: 'onChange',
        submitFocusError: true,
    });
    var error = useStructuredSelector({
        error: checkPasswordSelector,
    }).error;
    return (React.createElement(FormModal, { methods: methods, modalName: modalName, modalProps: modalProps, isVisible: isVisible },
        React.createElement(EnterPasswordFields, { error: error })));
};
var useStyles = makeStyles({
    title: {
        color: '#212121',
    },
    root: {
        background: '#F9F9F9',
        border: '1px solid #F2F2F2',
        boxSizing: 'border-box',
    },
    input: {
        color: 'rgba(0, 0, 0, 0.6)',
    },
}, { name: 'EnterPasswordFields' });
var EnterPasswordFields = function (_a) {
    var _b;
    var error = _a.error;
    var t = useTranslation('my-account').t;
    var classes = useStyles();
    var _c = useFormContext(), register = _c.register, errors = _c.errors, setError = _c.setError, clearError = _c.clearError;
    var _d = React.useState(false), isChanged = _d[0], setIsChanged = _d[1];
    var onChangePassword = function () {
        setIsChanged(true);
    };
    useEffect(function () {
        if (error && isChanged) {
            setError('password', '', 'Wrong password');
        }
        else {
            clearError('password');
        }
        return function () {
            clearError('password');
        };
    }, [error]);
    return (React.createElement(React.Fragment, null,
        React.createElement(TextInput, { fullWidth: true, titleClassName: classes.title, InputProps: {
                classes: {
                    root: classes.root,
                    input: classes.input,
                },
            }, name: "password", title: t('password.label'), type: "password", inputRef: register, placeholder: t('password.enter_password'), error: !!errors.password, helperText: (_b = errors === null || errors === void 0 ? void 0 : errors.password) === null || _b === void 0 ? void 0 : _b.message, onChange: onChangePassword, showAsterisk: true, visibilityColor: "#212121" })));
};
export { ChangeAccountModal };
