var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createAction, createSlice } from '@reduxjs/toolkit';
import { name } from './constants';
import { getFlagOutrights, getOutrights, getTournamentsOutrights, } from './thunks';
export var connectOutrights = createAction("".concat(name, "/connectSocketOutrights"));
var initialState = {
    data: {},
    loading: 'idle',
    error: null,
};
export var outrights = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        updateOutrights: function (state, _a) {
            var payload = _a.payload;
            return __assign(__assign({}, state), { data: __assign(__assign({}, state.data), { outrights: payload }) });
        },
        resetOutrights: function (state) {
            return __assign(__assign({}, state), { data: __assign(__assign({}, state.data), { outrights: undefined }) });
        },
        resetTournaments: function (state) {
            return __assign(__assign({}, state), { data: __assign(__assign({}, state.data), { tournaments: undefined }) });
        },
        resetCategories: function (state) {
            return __assign(__assign({}, state), { data: __assign(__assign({}, state.data), { categories: undefined }) });
        },
    },
    extraReducers: function (builder) {
        builder
            .addCase(getFlagOutrights.pending, function (state) { return (__assign(__assign({}, state), { loading: 'pending' })); })
            .addCase(getFlagOutrights.fulfilled, function (state, _a) {
            var payload = _a.payload;
            return (__assign(__assign({}, state), { data: __assign(__assign({}, state.data), { categories: payload }), loading: 'fulfilled' }));
        })
            .addCase(getTournamentsOutrights.pending, function (state) { return (__assign(__assign({}, state), { loading: 'pending' })); })
            .addCase(getTournamentsOutrights.fulfilled, function (state, _a) {
            var payload = _a.payload;
            return (__assign(__assign({}, state), { data: __assign(__assign({}, state.data), { tournaments: payload }), loading: 'fulfilled' }));
        })
            .addCase(getOutrights.pending, function (state) { return (__assign(__assign({}, state), { loading: 'pending' })); })
            .addCase(getOutrights.fulfilled, function (state, _a) {
            var payload = _a.payload;
            return (__assign(__assign({}, state), { data: __assign(__assign({}, state.data), { outrights: payload }), loading: 'fulfilled' }));
        });
    },
});
export var resetTournaments = (_a = outrights.actions, _a.resetTournaments), resetCategories = _a.resetCategories, resetOutrights = _a.resetOutrights, updateOutrights = _a.updateOutrights;
export default outrights.reducer;
