import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Collapse from '@material-ui/core/Collapse';
import CSCoin from '~icons/balance/CS_Coin.svg';
import { RankingListCartoonNickname } from "~atoms/RankingListCartoonNickname";
import { NestedGlobalRankingList, NestedGlobalRankingListItem } from "~atoms/RankingListCartoonItem/components";
import { formatLargeNumber } from "~utils/formatLargeNumber";
import { SmallLoader } from "~atoms";
import { initializeAvatarStyles } from "~pages/ranking-list/constants";
import { useStyles } from './RankingListCartoonItem.styles';
export var GlobalRankingListItem = function (_a) {
    var _b, _c;
    var itemIndex = _a.itemIndex, level = _a.level, minBalance = _a.minBalance, maxBalance = _a.maxBalance, userName = _a.userName, isCurrentUser = _a.isCurrentUser, isRankingListDetailsLoading = _a.isRankingListDetailsLoading, isMyRankDisplay = _a.isMyRankDisplay, avatar = _a.avatar, avatarUrl = _a.avatarUrl, myselfData = _a.myselfData, playersCount = _a.playersCount, topRankedPlayers = _a.topRankedPlayers, rankedPlayersList = _a.rankedPlayersList, ranks = _a.ranks, openedLevel = _a.openedLevel, trendBalance = _a.trendBalance, onItemListClick = _a.onItemListClick, onNestedListScroll = _a.onNestedListScroll;
    var classes = useStyles();
    var t = useTranslation('tip-game').t;
    var userAvatar = avatar === null || avatar === void 0 ? void 0 : avatar.toLowerCase();
    var backgrounds = [
        'linear-gradient(270deg, #00A5FF -94.93%, #0C104A 47.58%, #0C0F49 78.73%)',
        'linear-gradient(270deg, #31B2F8 -48.53%, #0C104A 50.51%, #0C0F49 102.45%)',
        'linear-gradient(270deg, #31B2F8 -47.74%, #0C104A 55.2%, #0C0F49 102.61%)',
        'linear-gradient(270deg, #31B2F8 -48.9%, #0C104A 62.47%, #0C0F49 103.43%)',
        'linear-gradient(270deg, #31B2F8 -48.9%, #0C104A 77.15%, #0C0F49 104.17%)',
        'linear-gradient(270deg, #31B2F8 -49.26%, #0C104A 94.57%, #0C0F49 103.43%)',
        'linear-gradient(270deg, #31B2F8 -34.53%, #0C0F49 103.06%, #0C104A 103.06%)',
        'linear-gradient(270deg, #31B2F8 -7.89%, #0C104A 99.69%, #0C0F49 100%)',
        'linear-gradient(270deg, #31B2F8 -0.7%, #224A95 76.39%, #0C104A 78.73%, #0C0F49 78.73%)',
        'linear-gradient(270deg, #62C3F7 16%, #224A95 78.73%, #0C104A 78.73%, #0C0F49 78.73%)'
    ];
    var getBackground = function () {
        var index = itemIndex < backgrounds.length ? itemIndex : backgrounds.length - 1;
        return backgrounds[index];
    };
    var isNestedListOpen = openedLevel === level;
    var checkIfNarrowScreen = function (maxWith) { return window.innerWidth < maxWith; };
    var avatarStyles = initializeAvatarStyles(t);
    var avatarName = userAvatar ? (_b = avatarStyles[userAvatar]) === null || _b === void 0 ? void 0 : _b.avatarName : '';
    var avatarBackground = userAvatar ? (_c = avatarStyles[userAvatar]) === null || _c === void 0 ? void 0 : _c.background : '';
    var isNestedListAvailable = playersCount && !(topRankedPlayers === null || topRankedPlayers === void 0 ? void 0 : topRankedPlayers.length) ? true : playersCount > Number(topRankedPlayers === null || topRankedPlayers === void 0 ? void 0 : topRankedPlayers.length);
    var onItemClick = function (level) {
        if (!isNestedListAvailable)
            return;
        var activeLevel = openedLevel === level ? null : level;
        onItemListClick(activeLevel);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { role: "button", tabIndex: 0, className: classNames(classes.wrapper, isCurrentUser && classes.greenBorder, (ranks === null || ranks === void 0 ? void 0 : ranks.length) && classes.pointer), style: { background: "".concat(getBackground()) }, onClick: function () { return onItemClick(level); } },
            React.createElement("div", { className: classes.blockWrapper, style: { height: '109px' } },
                React.createElement(RankingListCartoonNickname, { nickname: userName, avatar: avatar, avatarUrl: avatarUrl, isGlobalRanking: true }),
                React.createElement("div", { className: classes.centerBlock },
                    React.createElement("div", { className: classes.avatarName },
                        React.createElement("span", null, avatarName)),
                    React.createElement("div", { className: classes.divider, style: { marginTop: '10px' } }),
                    React.createElement("div", { className: classes.balance },
                        maxBalance ? (React.createElement("div", null,
                            React.createElement("span", null, checkIfNarrowScreen(375) ? t('ranking-list.max') : t('ranking-list.max_balance')),
                            React.createElement("span", null,
                                formatLargeNumber(maxBalance),
                                React.createElement("img", { src: CSCoin, alt: 'coin' })))) : null,
                        minBalance ? (React.createElement("div", null,
                            React.createElement("span", null, checkIfNarrowScreen(375) ? t('ranking-list.min') : t('ranking-list.min_balance')),
                            React.createElement("span", null,
                                formatLargeNumber(minBalance),
                                React.createElement("img", { src: CSCoin, alt: 'coin' })))) : null)))),
        (topRankedPlayers === null || topRankedPlayers === void 0 ? void 0 : topRankedPlayers.length) && (topRankedPlayers.map(function (player, index) { return (React.createElement(NestedGlobalRankingListItem, { key: index, currentUserRank: myselfData === null || myselfData === void 0 ? void 0 : myselfData.rank, trendBalance: trendBalance, rankingItem: player, backgroundColor: avatarBackground })); })),
        isNestedListAvailable && isNestedListOpen ? (React.createElement(React.Fragment, null,
            isRankingListDetailsLoading && (React.createElement("div", { className: classes.smallLoader },
                React.createElement(SmallLoader, null))),
            (rankedPlayersList === null || rankedPlayersList === void 0 ? void 0 : rankedPlayersList.length) ? (React.createElement(Collapse, { in: isNestedListOpen, timeout: "auto", unmountOnExit: true },
                React.createElement(NestedGlobalRankingList, { rankedPlayersList: rankedPlayersList, myselfData: myselfData, userName: userName, trendBalance: trendBalance, backgroundColor: avatarBackground, isMyRankDisplay: isMyRankDisplay, onScroll: onNestedListScroll }))) : null)) : null));
};
