import React from 'react';
import { useStructuredSelector } from '~hooks';
import { selectTournamentsListData } from '~slices/tournamentsList';
import { checkIsTournamentGroup } from '~utils/checkIsTournamentGroup';
import { getTournamentLabel } from '~utils/getTournamentLabel';
import { TournamentLink } from '../TournamentLink';
import { useStyles } from './Content.styles';
import { ItemsList } from './ItemsList';
import { Item } from './Item';
import { scrollParentAtom } from './scrollParentAtom';
var SubtournamentsContent = function (_a) {
    var parentId = _a.parentId, onSelect = _a.onSelect;
    var classes = useStyles();
    var tournaments = useStructuredSelector({
        tournaments: selectTournamentsListData,
    }).tournaments;
    var parentTournament = tournaments.find(function (_a) {
        var id = _a.id;
        return id === parentId;
    }) || {};
    var subtournaments = checkIsTournamentGroup(parentTournament)
        ? parentTournament.grt || []
        : [];
    return (React.createElement("div", { className: classes.container },
        React.createElement("div", { className: classes.scroll, ref: function (e) {
                scrollParentAtom.setValue(e);
            } },
            React.createElement(ItemsList, null, subtournaments.map(function (_a) {
                var id = _a.id, nm = _a.nm, live = _a.live, np = _a.np;
                return (React.createElement(TournamentLink, { key: id, onSelect: onSelect, tournamentId: id }, function (isSelected) {
                    return (React.createElement(Item, { autoScrollDelay: true, active: isSelected, label: getTournamentLabel(live, np) }, nm || ''));
                }));
            })))));
};
export { SubtournamentsContent };
