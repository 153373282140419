import makeStyles from '@material-ui/core/styles/makeStyles';
var useStyles = makeStyles({
    container: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#151515',
        borderRadius: '0px',
        height: '44px',
        padding: '0px 0px 0px 16px',
        boxSizing: 'border-box',
    },
    singleLineContainer: {
        width: 'initial',
        padding: '0px',
    },
    light: {
        backgroundColor: '#fff',
    },
    nonClickable: {
        pointerEvents: 'none',
        width: '78px',
        textAlign: 'center',
        margin: '0px 16px',
    },
    placeholder: {
        pointerEvents: 'none',
        width: '130px',
        marginLeft: '38px',
        textAlign: 'center',
    },
    balanceContainer: {
        pointerEvents: 'none',
        minWidth: 106,
        margin: '0px 16px',
        '@media (max-width:350px)': {
            minWidth: 76,
        },
        '& span': {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
        },
    },
    balanceTitle: {
        alignSelf: 'flex-start',
        fontSize: 10,
        color: '#939393',
    },
    balance: {
        display: 'flex',
        alignSelf: 'flex-end',
        fontSize: 12,
        color: '#FFFFFF',
        fontWeight: 500,
        '& svg': {
            height: 12,
            marginLeft: 3,
            alignSelf: 'flex-end',
        },
    },
    closed: {
        textAlign: 'center',
        fontSize: 12,
        color: '#FFFFFF',
        fontWeight: 500,
    },
}, {
    name: 'MatchDayPicker',
});
export { useStyles };
