import makeStyles from '@material-ui/core/styles/makeStyles';
import { ZIndex } from '~constants/view/ZIndex';
export var useStyles = makeStyles(function (theme) {
    var _a, _b, _c, _d, _e, _f;
    return ({
        option: (_a = {
                fontSize: 15,
                background: '#323232',
                borderBottom: '1px solid #D3D3D3',
                color: '#ffffff'
            },
            _a[theme.breakpoints.up('lg')] = {
                background: '#f9f9f9',
                color: 'rgba(0, 0, 0, 0.6)',
            },
            _a['&:hover,&:focus'] = (_b = {
                    background: '#2e2e2e'
                },
                _b[theme.breakpoints.up('lg')] = {
                    background: '#f2f2f2',
                },
                _b),
            _a['& > span'] = {
                marginRight: 10,
                fontSize: 18,
            },
            _a),
        selectBox: {
            marginBottom: '16px',
        },
        selectBoxTitle: (_c = {
                fontSize: '12px',
                lineHeight: '16px',
                letterSpacing: '0.4px',
                marginBottom: '4px',
                textAlign: 'left'
            },
            _c[theme.breakpoints.up('lg')] = {
                color: '#212121',
            },
            _c),
        input: {
            '&[class*="MuiOutlinedInput-root"]': {
                padding: 0,
                '& .MuiAutocomplete-input': {
                    padding: '9.5px 4px 9.5px 13px',
                },
            },
        },
        flagIcon: {
            marginLeft: '16px',
        },
        countryItem: {
            display: 'grid',
            gridTemplateColumns: 'max-content 1fr',
            alignItems: 'center',
            '& img': {
                filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))',
                marginRight: '20px',
            },
        },
        chevronIcon: (_d = {
                fontSize: '16px',
                lineHeight: '24px',
                color: '#FFFFFF'
            },
            _d[theme.breakpoints.up('lg')] = {
                color: '#212121',
                paddingRight: '5px',
            },
            _d),
        hidden: {
            visibility: 'hidden',
        },
        popper: (_e = {
                zIndex: ZIndex.MODAL_POPER
            },
            _e[theme.breakpoints.up('lg')] = {
                '& ::-webkit-scrollbar-thumb': {
                    background: '#7d7c7c',
                },
            },
            _e['& > div'] = (_f = {
                    background: '#323232',
                    border: '1px solid #D3D3D3'
                },
                _f[theme.breakpoints.up('lg')] = {
                    background: '#f9f9f9',
                },
                _f),
            _e['& .MuiAutocomplete-listbox'] = {
                padding: '0px 0px',
            },
            _e),
    });
}, { name: 'CountryAutocomplete' });
