import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import success from '~icons/statusIcons/success.svg';
import failed from '~icons/statusIcons/failed.svg';
import { getPathWithLanguageCode } from '~utils/languageInURL';
import { StatusScreen } from '~molecules';
import { useIsMobileDevise } from '~hooks';
import { useStyles } from './TopUpStatus.styles';
export var TopUpStatus = function () {
    var t = useTranslation('tip-game').t;
    var classes = useStyles();
    var isMobileDevice = useIsMobileDevise();
    var search = useLocation().search;
    var params = new URLSearchParams(search);
    var amount = params.get('sum');
    // TODO, replace with real data from BE
    var roundedValue = Math.ceil(Number(amount));
    var isSuccess = Math.random() * 10 > 5;
    var logo = isSuccess ? success : failed;
    var header = isSuccess
        ? t('payments.top_up_successful')
        : // TODO, mock text for declining
            'Top-up has been declined.';
    var subheader = isSuccess ? "".concat(roundedValue) : '';
    return (React.createElement("div", { className: classes.wrapper },
        React.createElement(StatusScreen, { header: header, subheader: subheader, CSSymbol: true, logo: logo, target: getPathWithLanguageCode('/home/all/live'), buttonLabel: t('continue'), isMobile: isMobileDevice })));
};
