import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var SkijumpingIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M11.9736 16.0913L13.5485 13.1055C13.6843 12.864 13.0114 12.4698 13.2879 12.4879C13.3733 12.4935 15.9225 12.8823 15.9225 12.8823L14.1367 16.7903L11.9736 16.0913Z", fill: "#328777" }),
        React.createElement("path", { d: "M14.6456 20.6288L10.7876 19.602L11.8295 15.6875C11.891 15.6837 13.2243 16.2427 13.2243 16.2427L14.6456 20.6288Z", fill: "#1F544A" }),
        React.createElement("path", { d: "M18.6441 21.4306L9.15973 18.9062L8.69385 20.4807L18.3026 22.9929L18.6441 21.4306Z", fill: "#FFD422" }),
        React.createElement("path", { d: "M20.2041 8.50733L23.1736 8.38221L24.5378 5.77963L21.908 5.15625L19.2056 7.0072V8.67566L20.2041 8.50733Z", fill: "#FF9478" }),
        React.createElement("path", { d: "M10.033 7.12109C9.36739 7.35778 8.30088 8.19696 8.12445 8.9412C7.85304 10.0864 8.5771 11.234 9.71438 11.5366L14.7288 12.8713C14.9966 12.9425 15.1252 13.2474 14.9894 13.4889L13.3271 16.3765L15.1323 17.1297L17.5518 13.3386C17.9565 12.6989 17.7949 11.8548 17.1824 11.4099L13.6503 8.57742L10.033 7.12109Z", fill: "#4BCDB2" }),
        React.createElement("path", { d: "M10.7075 6.47508C12.2479 5.57 15.2881 4.58683 19.9429 6.522L20.5126 12.1079L23.3667 11.6485L23.6837 13.4261L19.02 14.0576L18.0914 9.2012L10.7075 6.47508Z", fill: "#4BCDB2" }),
        React.createElement("path", { d: "M22.8263 12.1001L18.4451 10.4827L5.61865 5.74744L5.89761 4.69946L18.2484 9.25909L24.7236 11.6496L22.8263 12.1001Z", fill: "#D7D5D9" }),
        React.createElement("path", { d: "M28 23.3831C28 23.3831 26.9334 24.6993 26.1081 24.4796L0.288258 17.6074L0 18.6905L25.8198 25.5627C26.645 25.7823 27.4921 25.2914 27.7117 24.4662L28 23.3831Z", fill: "#FFE477" }),
        React.createElement("path", { d: "M24.5835 6.02726C25.1997 4.80499 24.7236 3.31017 23.5068 2.6724C22.278 2.02829 20.7597 2.50232 20.1157 3.73121C19.5073 4.89189 19.8969 6.31049 20.9775 7.0071L22.5349 5.41968L24.5835 6.02726Z", fill: "#1F544A" }),
        React.createElement("path", { d: "M22.6938 11.7566L25.6579 11.3101L25.1553 13.1982L22.7833 13.5479", fill: "#FF9478" }),
        React.createElement("path", { d: "M13.2694 16.0708C13.3309 16.067 15.8983 17.1173 15.8983 17.1173L14.6896 18.9881L16.2308 20.4664L16.0856 21.0122L12.2275 19.9853L13.2694 16.0708Z", fill: "#328777" }),
        React.createElement("path", { d: "M23.3663 11.6482L20.5123 12.1077L20.1114 8.17798H15.3203L18.0911 9.20086L19.0196 14.0573L23.6834 13.4259L23.3663 11.6482Z", fill: "#4BCDB2" }),
        React.createElement("g", { opacity: "0.35" },
            React.createElement("path", { opacity: "0.35", d: "M24.4977 5.85161L24.345 6.14731L21.9814 5.65807L22.6318 5.10059L24.4977 5.85161Z", fill: "#1F544A" })),
        React.createElement("g", { opacity: "0.35" },
            React.createElement("path", { opacity: "0.35", d: "M20.5228 6.08862L20.1035 6.39187C20.1035 6.39187 20.2453 6.76429 20.8969 6.78846L20.5228 6.08862Z", fill: "#1F544A" })),
        React.createElement("g", { opacity: "0.39" },
            React.createElement("path", { opacity: "0.39", d: "M9.71957 7.26123C9.71957 7.26123 9.36 7.43525 9.0625 7.69873L14.0359 8.8866L13.9762 8.83268L9.71957 7.26123Z", fill: "#1F544A" }))));
};
