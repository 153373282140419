var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
import { getTopRanking } from '~slices/top-ranking/thunks';
import { name } from './constants';
var initialState = {
    data: {},
    loading: 'idle',
    error: null,
};
export var topRanking = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        getTopRankingPoll: function (state, _a) {
            var payload = _a.payload;
            return __assign(__assign({}, state), { loading: 'pending' });
        },
        getTopRankingPollSuccess: function (state, _a) {
            var payload = _a.payload;
            return __assign(__assign({}, state), { data: __assign(__assign({}, state.data), { rankingList: payload }), loading: 'fulfilled' });
        },
        getTopRankingPollStop: function (state) {
            return __assign(__assign({}, state), { loading: 'fulfilled' });
        },
        getExcludedOffersSuccess: function (state, _a) {
            var payload = _a.payload;
            return __assign(__assign({}, state), { data: __assign(__assign({}, state.data), { excludedOffers: payload }), loading: 'fulfilled' });
        },
        saveProviderId: function (state, _a) {
            var payload = _a.payload;
            return __assign(__assign({}, state), { data: __assign(__assign({}, state.data), { providerId: payload.providerId, template: payload.template, ticketId: payload === null || payload === void 0 ? void 0 : payload.ticketId, linkType: payload === null || payload === void 0 ? void 0 : payload.linkType }) });
        },
        resetTemplate: function (state) {
            return __assign(__assign({}, state), { data: __assign(__assign({}, state.data), { template: undefined }) });
        },
        resetTopRanking: function () {
            return initialState;
        },
        resetProviderId: function (state) {
            return __assign(__assign({}, state), { data: __assign(__assign({}, state.data), { providerId: undefined }) });
        },
    },
    extraReducers: function (builder) {
        builder
            .addCase(getTopRanking.pending, function (state) { return (__assign(__assign({}, state), { error: false, loading: 'pending' })); })
            .addCase(getTopRanking.fulfilled, function (state, _a) {
            var payload = _a.payload;
            return (__assign(__assign({}, state), { data: __assign(__assign({}, state.data), { requestId: payload.requestId }), error: false, loading: 'fulfilled' }));
        })
            .addCase(getTopRanking.rejected, function (state) {
            return __assign(__assign({}, state), { error: true, loading: 'rejected' });
        });
    },
});
export var saveProviderId = (_a = topRanking.actions, _a.saveProviderId), getTopRankingPoll = _a.getTopRankingPoll, getTopRankingPollSuccess = _a.getTopRankingPollSuccess, getTopRankingPollStop = _a.getTopRankingPollStop, resetTopRanking = _a.resetTopRanking, getExcludedOffersSuccess = _a.getExcludedOffersSuccess, resetProviderId = _a.resetProviderId, resetTemplate = _a.resetTemplate;
export default topRanking.reducer;
