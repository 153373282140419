import React from 'react';
import classNames from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';
export var useStyles = makeStyles(function (theme) { return ({
    progressBar: {
        display: 'flex',
        width: '160px',
        marginBottom: '36px',
    },
    step: {
        width: '62px',
        height: '4px',
        background: '#e1e1e1',
        borderRadius: '4px',
        marginRight: '10px',
        '&:nth-last-of-type(1)': {
            marginRight: '0',
        },
    },
    active: {
        background: theme.variables.mainRedColor.color,
    },
}); }, { name: 'ProgressBar' });
function ProgressBar(_a) {
    var steps = _a.steps, step = _a.step;
    var classes = useStyles();
    var progressSteps = function () {
        var _a;
        var barSteps = [];
        for (var i = 0; i < steps; i++) {
            barSteps.push(React.createElement("div", { key: "bar-".concat(i), className: classNames(classes.step, (_a = {}, _a[classes.active] = i < step, _a)) }));
        }
        return barSteps;
    };
    return React.createElement("div", { className: classes.progressBar }, progressSteps());
}
export default ProgressBar;
