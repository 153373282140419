import React, { useRef, useState } from 'react';
import { DropDown, OptionsSelection } from '~atoms';
import { OptionsDropDownControlls } from './OptionsDropDownControlls';
var noop = function () { return undefined; };
var OptionsDropDown = function (_a) {
    var options = _a.options, selected = _a.selected, _b = _a.palette, palette = _b === void 0 ? 'dark' : _b, _c = _a.onChange, onChange = _c === void 0 ? noop : _c;
    var _d = useState(false), isOpen = _d[0], setIsOpen = _d[1];
    var anchorRef = useRef(null);
    var handleOptionsClick = function () {
        setIsOpen(function (prevIsOpen) { return !prevIsOpen; });
    };
    var handleClose = function () { return setIsOpen(false); };
    var i = options.findIndex(function (_a) {
        var id = _a.id;
        return id === selected;
    });
    var selectedIndex = i === -1 ? 0 : i;
    var option = options[selectedIndex];
    var selectedOptionPos = selectedIndex + 1;
    var optionsCount = options.length;
    var shouldDisplayLeftArrow = selectedIndex > 0;
    var shouldDisplayRightArrow = selectedOptionPos < optionsCount;
    var handleArrowClick = function (arrow) {
        var _a;
        var newIndex = selectedIndex;
        if (arrow === 'left') {
            newIndex -= 1;
        }
        if (arrow === 'right') {
            newIndex += 1;
        }
        var selectedId = (_a = options[newIndex]) === null || _a === void 0 ? void 0 : _a.id;
        if (newIndex !== selectedIndex && selectedId !== undefined) {
            onChange(selectedId);
        }
    };
    var handleSelectionChange = function (selectedIds) {
        if (selectedIds[0] !== undefined) {
            onChange(selectedIds[0]);
            setIsOpen(false);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(OptionsDropDownControlls, { optionName: option ? option.value : '', open: isOpen, palette: palette, displayLeftArrow: shouldDisplayLeftArrow, displayRightArrow: shouldDisplayRightArrow, onOptionsClick: handleOptionsClick, onArrowClick: handleArrowClick, anchorRef: anchorRef }),
        React.createElement(DropDown, { open: isOpen, onClose: handleClose, palette: palette, anchorEl: anchorRef.current },
            React.createElement(OptionsSelection, { palette: palette, options: options, selected: option ? [option.id] : [], onChange: handleSelectionChange }))));
};
export { OptionsDropDown };
