import makeStyles from '@material-ui/core/styles/makeStyles';
var useStyles = makeStyles({
    container: {
        display: 'inline-flex',
        '& i': {
            fontSize: '12px',
        },
    },
    arrow: {
        minWidth: '34px',
        width: '34px',
        transition: 'opacity 300ms ease-in-out',
    },
    arrowPlaceholder: {
        width: '34px',
        height: '34px',
    },
    chosenOption: {
        width: '130px',
        margin: '0 4px',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        padding: '6px 16px',
        '& i': {
            transition: 'transform 300ms ease-in-out',
        },
        '& > span': {
            display: 'inline-flex',
            width: '100%',
            justifyContent: 'space-between',
            lineHeight: '15px',
        },
    },
    open: {
        transform: 'rotateX(180deg)',
    },
    disabled: {
        cursor: 'not-allowed',
        '& span': {
            color: '#8e8e8e',
        }
    },
    optionName: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
}, { name: 'OptionsDropDownControlls' });
export { useStyles };
