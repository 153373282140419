import makeStyles from '@material-ui/core/styles/makeStyles';
var useStyles = makeStyles({
    button: {
        fontSize: '16px',
    },
    redBackground: {
        backgroundColor: '#FF0000',
        '&:hover': {
            backgroundColor: '#FF0000',
        },
    },
}, { name: 'TopUpButton' });
export { useStyles };
