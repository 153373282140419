import { useCallback, useEffect, useRef, useState } from 'react';
import { useBeforeMount } from '~hooks';
import { callbacksMap } from './callbacksMap';
var useAtom = function (atom) {
    var atomRef = useRef(atom);
    var _a = useState(atom.getValue()), value = _a[0], setValue = _a[1];
    useBeforeMount(function () {
        var _a;
        (_a = callbacksMap.get(atom)) === null || _a === void 0 ? void 0 : _a.push(setValue);
    });
    var handleChange = useCallback(function (newValue) {
        atomRef.current.setValue(newValue);
    }, []);
    useEffect(function () {
        var a = atomRef.current;
        return function () {
            var callbacks = callbacksMap.get(a);
            if (callbacks) {
                var index = callbacks.findIndex(function (callback) { return callback === setValue; });
                if (index !== -1) {
                    callbacks.splice(index, 1);
                }
            }
        };
    }, []);
    return [value, handleChange];
};
export { useAtom };
