import React from "react";
import { Helmet } from "react-helmet";
export var PageMetadata = function (_a) {
    var titleSuffix = _a.titleSuffix, canonicalHref = _a.canonicalHref, description = _a.description, _b = _a.lang, lang = _b === void 0 ? 'de' : _b;
    return (React.createElement(Helmet, { htmlAttributes: { lang: lang } },
        canonicalHref &&
            React.createElement("link", { rel: "canonical", href: "".concat(canonicalHref) }),
        description &&
            React.createElement("meta", { name: "description", content: description }),
        React.createElement("title", null,
            "TipGame ",
            titleSuffix ? "- ".concat(titleSuffix) : '')));
};
