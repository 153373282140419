import makeStyles from '@material-ui/core/styles/makeStyles';
export var usePPMOStyles = makeStyles(function (theme) { return ({
    container: {
        padding: '0 16px',
    },
    header: {
        textAlign: 'center',
        padding: '32px 0 24px',
        margin: '0',
        fontSize: '20px',
        color: '#F2F2F2',
        fontWeight: 'normal',
    },
    ppmoTypes: {
        paddingTop: '16px',
    },
    button: {
        minWidth: '120px',
        width: '100%',
    },
}); }, { name: 'SelectPPMO' });
