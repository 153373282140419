var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from '@reduxjs/toolkit';
import { createAsyncReducers } from '~utils/createAsyncReducers';
import { name } from './constants';
import { acceptTerms, checkTermsHaveUpdate, getTerms, } from '~slices/terms/thunks';
var initialState = {
    data: {
        document: {},
        termsAcceptStatus: false,
        termsHaveUpdateStatus: false,
    },
    loading: 'idle',
    error: null,
};
export var terms = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        resetTermsState: function (state) {
            return __assign({}, initialState);
        },
    },
    extraReducers: function (builder) {
        var _a = createAsyncReducers(), pending = _a.pending, rejected = _a.rejected;
        builder
            .addCase(getTerms.pending, pending)
            .addCase(getTerms.fulfilled, function (state, action) { return (__assign(__assign({}, state), { data: __assign(__assign({}, state.data), { document: action.payload }), loading: 'fulfilled' })); })
            .addCase(getTerms.rejected, rejected)
            .addCase(acceptTerms.pending, pending)
            .addCase(acceptTerms.fulfilled, function (state, action) { return (__assign(__assign({}, state), { data: __assign(__assign({}, state.data), { termsAcceptStatus: action.payload, termsHaveUpdateStatus: false }), loading: 'fulfilled' })); })
            .addCase(acceptTerms.rejected, rejected)
            .addCase(checkTermsHaveUpdate.pending, pending)
            .addCase(checkTermsHaveUpdate.fulfilled, function (state, action) { return (__assign(__assign({}, state), { data: __assign(__assign({}, state.data), { termsHaveUpdateStatus: action.payload }), loading: 'fulfilled' })); })
            .addCase(checkTermsHaveUpdate.rejected, rejected);
    },
});
export var resetTermsState = terms.actions.resetTermsState;
