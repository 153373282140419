import React from 'react';
import Button from '@material-ui/core/Button/Button';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useIsDesktop } from '~hooks';
import { AlertDialogSlide, StatusScreen } from '~molecules';
import failed from '~icons/statusIcons/failed.svg';
export var useModalStyles = makeStyles({
    dialog: {
        width: '408px',
    },
    closeIcon: {
        fontSize: '20px',
        background: '#fff',
        color: 'rgba(142, 142, 142, 0.2)',
        position: 'absolute',
        top: '4px',
        right: '6px',
        '& i': {
            fontWeight: 300,
        },
    },
    subheader: {
        marginBottom: '40px',
        fontSize: '16px',
        color: '#212121',
    },
    header: { color: '#212121' },
    logo: { paddingTop: '40px' },
}, { name: 'BanModal' });
export var BanModal = function (_a) {
    var t = _a.t, handleCloseBanModal = _a.handleCloseBanModal;
    var isDesktop = useIsDesktop();
    var classes = useModalStyles();
    return isDesktop ? (React.createElement(Dialog, { open: true, onClose: handleCloseBanModal, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description" },
        React.createElement(Button, { onClick: handleCloseBanModal, className: classes.closeIcon },
            React.createElement("i", { className: "fas fa-times" })),
        React.createElement(DialogContent, { className: classes.dialog },
            React.createElement(StatusScreen, { header: t('all_attemps'), headerClassName: classes.header, logoClasses: classes.logo, subheaderClassName: classes.subheader, subheader: t('locked_for_30_minutes'), onClick: handleCloseBanModal, logo: failed, buttonLabel: t('ok') })))) : (React.createElement(AlertDialogSlide, { handleClose: handleCloseBanModal, open: true, title: t('all_attemps'), content: t('locked_for_30_minutes'), needConfirm: false }));
};
