import React from 'react';
import { activeTicketIdSelector, ticketDataSelector } from '~slices/tickets';
import { useStructuredSelector } from '~hooks';
import { TipDetails } from "~pages";
export var TipDetailsBlock = function () {
    var _a = useStructuredSelector({
        ticketsData: ticketDataSelector,
        activeTicketId: activeTicketIdSelector,
    }), ticketsData = _a.ticketsData, activeTicketId = _a.activeTicketId;
    var tickets = ticketsData.content;
    var activeTicket = tickets === null || tickets === void 0 ? void 0 : tickets.find(function (item) { return item.id === activeTicketId; });
    return React.createElement(React.Fragment, null, activeTicket && React.createElement(TipDetails, { ticket: activeTicket }));
};
