// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable max-lines */
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Live } from '~atoms/live';
import { FINISHED_STATUSES, POSSIBLE_GOAL_IDS, POSSIBLE_RED_CARDS_IDS, } from '~utils/events';
import { usePrevious } from '~hooks';
import { SidebetsEventStatus } from '~molecules';
import { history } from '~utils/history';
import { useCompetitorStyles } from '../Sidebets.styles';
import { SoonLiveHeader } from './SoonLiveHeader';
import { CompetitorScores } from './CompetitorScore';
export var Header = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0;
    var event = _a.event, competitor1 = _a.competitor1, competitor2 = _a.competitor2, matchStatus = _a.matchStatus, sr = _a.sr, sportId = _a.sportId, date = _a.date;
    var styles = useCompetitorStyles();
    var t = useTranslation('ticket').t;
    var prevEventId = usePrevious(event.bid);
    var isTennis = sportId === 25 || sportId === 2;
    var isSoccer = ((_b = event === null || event === void 0 ? void 0 : event.sp) === null || _b === void 0 ? void 0 : _b.id) === 1;
    var isFinishedEvent = FINISHED_STATUSES.includes((event === null || event === void 0 ? void 0 : event.ms) || '');
    var isBetstopReason = !!Number((_c = event.bsr) === null || _c === void 0 ? void 0 : _c.bsrl) && (event === null || event === void 0 ? void 0 : event.bs);
    var prevBetstopReason = usePrevious(event.bsr);
    var prevBetstopReasonId = usePrevious((_d = event.bsr) === null || _d === void 0 ? void 0 : _d.bid);
    var isPossibleGoal = POSSIBLE_GOAL_IDS.includes(((_e = event.bsr) === null || _e === void 0 ? void 0 : _e.bid) || 0) && event.bs;
    var prevIsPossibleGoal = POSSIBLE_GOAL_IDS.includes(prevBetstopReasonId || 0);
    var prevC1Score = usePrevious((_f = event === null || event === void 0 ? void 0 : event.c1) === null || _f === void 0 ? void 0 : _f.sc);
    var prevC2Score = usePrevious((_g = event === null || event === void 0 ? void 0 : event.c2) === null || _g === void 0 ? void 0 : _g.sc);
    var _1 = useState(false), goal = _1[0], setGoal = _1[1];
    var _2 = useState({
        c1: 0,
        c2: 0,
    }), scoreBeforePossibleGoal = _2[0], setScoreBeforePossibleGoal = _2[1];
    var _3 = useState(false), noGoal = _3[0], setNoGoal = _3[1];
    var _4 = useState({
        c1: ((_h = event === null || event === void 0 ? void 0 : event.c1) === null || _h === void 0 ? void 0 : _h.sc) || 0,
        c2: ((_j = event === null || event === void 0 ? void 0 : event.c2) === null || _j === void 0 ? void 0 : _j.sc) || 0,
    }), scorToShow = _4[0], setScoreToShow = _4[1];
    var c1Goal = prevIsPossibleGoal && !isPossibleGoal
        ? scoreBeforePossibleGoal.c1 < Number((_k = event === null || event === void 0 ? void 0 : event.c1) === null || _k === void 0 ? void 0 : _k.sc)
        : prevC1Score && ((_l = event === null || event === void 0 ? void 0 : event.c1) === null || _l === void 0 ? void 0 : _l.sc) && prevC1Score < ((_m = event === null || event === void 0 ? void 0 : event.c1) === null || _m === void 0 ? void 0 : _m.sc);
    var c2Goal = prevIsPossibleGoal && !isPossibleGoal
        ? scoreBeforePossibleGoal.c2 < Number((_o = event === null || event === void 0 ? void 0 : event.c2) === null || _o === void 0 ? void 0 : _o.sc)
        : prevC2Score && ((_p = event === null || event === void 0 ? void 0 : event.c2) === null || _p === void 0 ? void 0 : _p.sc) && prevC2Score < ((_q = event === null || event === void 0 ? void 0 : event.c2) === null || _q === void 0 ? void 0 : _q.sc);
    var isPossibleCard = POSSIBLE_RED_CARDS_IDS.includes(((_r = event.bsr) === null || _r === void 0 ? void 0 : _r.bid) || 0);
    var prevPossCard = usePrevious(isPossibleCard);
    var c1RedCards = ((competitor1 === null || competitor1 === void 0 ? void 0 : competitor1.rc) || 0) + ((competitor1 === null || competitor1 === void 0 ? void 0 : competitor1.yrc) || 0);
    var c2RedCards = ((competitor2 === null || competitor2 === void 0 ? void 0 : competitor2.rc) || 0) + ((competitor2 === null || competitor2 === void 0 ? void 0 : competitor2.yrc) || 0);
    var _5 = useState({ c1: 0, c2: 0 }), tempRedCard = _5[0], setTempRedCard = _5[1];
    var _6 = useState({
        c1: c1RedCards,
        c2: c2RedCards,
    }), redCardsBeforePossCard = _6[0], setRedCardsBeforePossCard = _6[1];
    var _7 = useState({
        c1: c1RedCards,
        c2: c2RedCards,
    }), redCardsToShow = _7[0], setRedCardsToShow = _7[1];
    var _8 = useState(false), noCard = _8[0], setNoCard = _8[1];
    var localeTime = date &&
        new Date(date * 1000).toLocaleTimeString(i18next.language, {
            hour: '2-digit',
            minute: '2-digit',
        });
    var localeDate = date &&
        new Date(date * 1000).toLocaleDateString(i18next.language, {
            weekday: 'short',
            day: '2-digit',
            month: 'short',
        });
    var isFavoriteTab = history.location.pathname === '/home/all/favorites';
    var text = ((_s = event.lsd) === null || _s === void 0 ? void 0 : _s.st)
        ? "".concat(event.ti ? "".concat(event.ti, "\u2019") : '')
        : "".concat(event.lsd ? (_t = event.lsd) === null || _t === void 0 ? void 0 : _t.tx : event.ls);
    useEffect(function () {
        var _a, _b;
        if (typeof event.act === 'boolean')
            setScoreBeforePossibleGoal({
                c1: ((_a = event === null || event === void 0 ? void 0 : event.c1) === null || _a === void 0 ? void 0 : _a.sc) || 0,
                c2: ((_b = event === null || event === void 0 ? void 0 : event.c2) === null || _b === void 0 ? void 0 : _b.sc) || 0,
            });
    }, [event.act]);
    useEffect(function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        var tId;
        if (prevEventId === event.bid) {
            if (isPossibleGoal && !prevIsPossibleGoal) {
                setScoreBeforePossibleGoal({
                    c1: ((_a = event === null || event === void 0 ? void 0 : event.c1) === null || _a === void 0 ? void 0 : _a.sc) || 0,
                    c2: ((_b = event === null || event === void 0 ? void 0 : event.c2) === null || _b === void 0 ? void 0 : _b.sc) || 0,
                });
                setScoreToShow({
                    c1: ((_c = event === null || event === void 0 ? void 0 : event.c1) === null || _c === void 0 ? void 0 : _c.sc) || 0,
                    c2: ((_d = event === null || event === void 0 ? void 0 : event.c2) === null || _d === void 0 ? void 0 : _d.sc) || 0,
                });
                return;
            }
            if (prevIsPossibleGoal && !isPossibleGoal) {
                if (isSoccer &&
                    (scoreBeforePossibleGoal.c1 < Number((_e = event === null || event === void 0 ? void 0 : event.c1) === null || _e === void 0 ? void 0 : _e.sc) ||
                        scoreBeforePossibleGoal.c2 < Number((_f = event === null || event === void 0 ? void 0 : event.c2) === null || _f === void 0 ? void 0 : _f.sc))) {
                    setGoal(true);
                    setScoreToShow({
                        c1: ((_g = event === null || event === void 0 ? void 0 : event.c1) === null || _g === void 0 ? void 0 : _g.sc) || 0,
                        c2: ((_h = event === null || event === void 0 ? void 0 : event.c2) === null || _h === void 0 ? void 0 : _h.sc) || 0,
                    });
                    tId = setTimeout(function () {
                        setGoal(false);
                    }, Number(process.env.GOAL_SCORE_BLINKING_DURATION) * 1000);
                }
                else if (isSoccer) {
                    setNoGoal(true);
                    tId = setTimeout(function () {
                        setNoGoal(false);
                    }, 9000);
                }
                return;
            }
            if ((!isPossibleGoal && !prevIsPossibleGoal && c1Goal) || c2Goal) {
                setGoal(true);
                setScoreToShow({
                    c1: ((_j = event === null || event === void 0 ? void 0 : event.c1) === null || _j === void 0 ? void 0 : _j.sc) || 0,
                    c2: ((_k = event === null || event === void 0 ? void 0 : event.c2) === null || _k === void 0 ? void 0 : _k.sc) || 0,
                });
                tId = setTimeout(function () {
                    setGoal(false);
                }, Number(process.env.GOAL_SCORE_BLINKING_DURATION) * 1000);
            }
        }
        return function () {
            if (tId) {
                clearTimeout(tId);
            }
        };
    }, [isPossibleGoal, c1Goal, c2Goal]);
    useEffect(function () {
        var _a, _b;
        var tId;
        if (!prevPossCard && isPossibleCard) {
            setRedCardsBeforePossCard({
                c1: c1RedCards,
                c2: c2RedCards,
            });
        }
        if (!isPossibleCard && prevPossCard) {
            if (((prevBetstopReason === null || prevBetstopReason === void 0 ? void 0 : prevBetstopReason.ti) === '1' &&
                redCardsBeforePossCard.c1 >= c1RedCards) ||
                ((prevBetstopReason === null || prevBetstopReason === void 0 ? void 0 : prevBetstopReason.ti) === '2' &&
                    redCardsBeforePossCard.c2 >= c2RedCards)) {
                setNoCard(true);
                setRedCardsToShow({
                    c1: c1RedCards,
                    c2: c2RedCards,
                });
                tId = setTimeout(function () {
                    setNoCard(false);
                }, 5000);
                setTempRedCard({ c1: 0, c2: 0 });
            }
        }
        if (!prevPossCard && !isPossibleCard) {
            setRedCardsToShow({
                c1: c1RedCards,
                c2: c2RedCards,
            });
        }
        if (isPossibleCard) {
            setTempRedCard({
                c1: (prevBetstopReason === null || prevBetstopReason === void 0 ? void 0 : prevBetstopReason.ti) === '1' && !!Number((_a = event.bsr) === null || _a === void 0 ? void 0 : _a.itrc) ? 1 : 0,
                c2: (prevBetstopReason === null || prevBetstopReason === void 0 ? void 0 : prevBetstopReason.ti) === '2' && !!Number((_b = event.bsr) === null || _b === void 0 ? void 0 : _b.itrc) ? 1 : 0,
            });
        }
        if (prevPossCard === true && !isPossibleCard) {
            setNoCard(true);
            tId = setTimeout(function () {
                if (setNoCard) {
                    setNoCard(false);
                }
            }, 5000);
            setTempRedCard({ c1: 0, c2: 0 });
        }
        return function () {
            if (tId) {
                clearTimeout(tId);
                setNoCard(false);
            }
        };
    }, [isPossibleCard, c1RedCards, c2RedCards]);
    var liveRef = useRef(null);
    var liveBlockWidth = liveRef.current
        ? +getComputedStyle(liveRef.current).width.replace('px', '') || 22
        : 22;
    var renderLiveStatus = function () {
        if (event.ls || event.lsd) {
            return React.createElement(Live, { className: styles.liveLabel, label: text });
        }
        return React.createElement(Live, { className: styles.liveLabel });
    };
    return (React.createElement("div", { className: classNames(styles.competitorsContainer, { isTennis: isTennis }) },
        React.createElement("div", { className: styles.namesContainer, style: { width: "calc(50% - ".concat(23 + liveBlockWidth / 2, "px)") } },
            React.createElement("span", { className: styles.teamName }, (competitor1 === null || competitor1 === void 0 ? void 0 : competitor1.nm) || ''),
            React.createElement("span", { className: styles.teamName }, (competitor2 === null || competitor2 === void 0 ? void 0 : competitor2.nm) || '')),
        React.createElement(SidebetsEventStatus, { favoritesTab: isFavoriteTab }),
        React.createElement("div", { className: styles.liveIcon, ref: liveRef },
            matchStatus === 'active' && renderLiveStatus(),
            matchStatus === 'await' && React.createElement(SoonLiveHeader, { label: t('soon_live') }),
            matchStatus === 'running' && React.createElement(SoonLiveHeader, { label: t('betstop_reason.POSSIBLE_RUN') })),
        React.createElement("div", { className: styles.bsrWrapper },
            goal && (React.createElement("div", { className: classNames(styles.goalPanel) },
                React.createElement("div", { className: styles.goalPaneText }, t('goal')))),
            noCard && !isFinishedEvent && (React.createElement("div", { className: classNames(styles.bsr, styles.nocard, {
                    animation: false,
                }) },
                React.createElement("div", { className: classNames(styles.bsrText, { animation: false }) }, t('no_card')))),
            noGoal && !goal && !isFinishedEvent && (React.createElement("div", { className: classNames(styles.bsr, styles.nogoal, {
                    animation: true,
                }) },
                React.createElement("div", { className: classNames(styles.bsrText, { animation: true }) }, t('no_goal')))),
            isBetstopReason && !noGoal && !noCard && !isFinishedEvent && (React.createElement("div", { className: classNames(styles.bsr, { animation: false }) },
                React.createElement("div", { className: classNames(styles.bsrText, { animation: false }) }, t("betstop_reason.".concat((_u = event.bsr) === null || _u === void 0 ? void 0 : _u.br)))))),
        matchStatus === 'await' && (React.createElement("div", { className: classNames(styles.date, { isTennis: isTennis }) },
            localeDate,
            React.createElement("br", null),
            localeTime)),
        matchStatus === 'active' || matchStatus === 'end' ? (React.createElement("div", { className: classNames(styles.scoreBlock, { isTennis: isTennis }) },
            React.createElement("div", { className: styles.cards },
                React.createElement("div", { className: "flex-row", style: { marginLeft: 'auto' } },
                    !!(redCardsToShow.c1 + tempRedCard.c1) && (React.createElement("div", { className: classNames(styles.card, styles.redCard) }, redCardsToShow.c1 + tempRedCard.c1)),
                    !!(competitor1 === null || competitor1 === void 0 ? void 0 : competitor1.yc) && (React.createElement("div", { className: classNames(styles.card, styles.yellowCard) }, competitor1 === null || competitor1 === void 0 ? void 0 : competitor1.yc))),
                React.createElement("div", { className: "flex-row", style: { marginLeft: 'auto' } },
                    !!(redCardsToShow.c2 + tempRedCard.c2) && (React.createElement("div", { className: classNames(styles.card, styles.redCard) }, redCardsToShow.c2 + tempRedCard.c2)),
                    !!(competitor2 === null || competitor2 === void 0 ? void 0 : competitor2.yc) && (React.createElement("div", { className: classNames(styles.card, styles.yellowCard) }, competitor2 === null || competitor2 === void 0 ? void 0 : competitor2.yc)))),
            matchStatus === 'active' || matchStatus === 'end' ? (React.createElement("div", { className: styles.score },
                React.createElement(CompetitorScores, { increaseGoal: Number((_v = event.bsr) === null || _v === void 0 ? void 0 : _v.itg), competitor: competitor1, currentScore: scorToShow.c1, showGoalAnimation: goal, isFirst: true, sr: sr, showTeamIndicator: !!Number((_w = event.bsr) === null || _w === void 0 ? void 0 : _w.std), teamIndicator: Number((_x = event.bsr) === null || _x === void 0 ? void 0 : _x.ti), sportId: sportId }),
                React.createElement(CompetitorScores, { increaseGoal: Number((_y = event.bsr) === null || _y === void 0 ? void 0 : _y.itg), competitor: competitor2, currentScore: scorToShow.c2, showGoalAnimation: goal, sr: sr, showTeamIndicator: !!Number((_z = event.bsr) === null || _z === void 0 ? void 0 : _z.std), teamIndicator: Number((_0 = event.bsr) === null || _0 === void 0 ? void 0 : _0.ti), sportId: sportId }))) : null)) : null));
};
