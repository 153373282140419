import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var BikeIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M10.308 16.8233C10.308 19.5566 8.09233 21.7725 5.35883 21.7725C5.00232 21.7725 4.65506 21.7348 4.31988 21.6626C2.08562 21.1857 0.410156 19.2 0.410156 16.8233C0.410156 14.446 2.08562 12.4603 4.31988 11.9835C4.65506 11.9113 5.00232 11.8735 5.35883 11.8735C8.09238 11.8735 10.308 14.0895 10.308 16.8233Z", fill: "#EEF7FF" }),
        React.createElement("path", { d: "M10.308 16.8233C10.308 19.5566 8.09228 21.7725 5.35878 21.7725C5.00227 21.7725 4.655 21.7348 4.31982 21.6626C6.55468 21.1857 8.23009 19.2 8.23009 16.8233C8.23009 14.446 6.55468 12.4603 4.31982 11.9835C4.655 11.9113 5.00227 11.8735 5.35878 11.8735C8.09233 11.8735 10.308 14.0895 10.308 16.8233Z", fill: "#D8E5EF" }),
        React.createElement("path", { d: "M5.35888 11.4639C2.40401 11.4639 0 13.8681 0 16.8233C0 19.7785 2.40401 22.1828 5.35888 22.1828C8.31376 22.1828 10.7178 19.7785 10.7178 16.8233C10.7178 13.8681 8.31376 11.4639 5.35888 11.4639ZM5.35888 21.3625C2.85627 21.3625 0.820203 19.3263 0.820203 16.8233C0.820203 14.3204 2.85622 12.2842 5.35888 12.2842C7.86155 12.2842 9.89756 14.3204 9.89756 16.8233C9.89756 19.3263 7.86155 21.3625 5.35888 21.3625Z", fill: "#46637F" }),
        React.createElement("path", { d: "M27.5898 16.8233C27.5898 19.5566 25.3741 21.7725 22.6411 21.7725C22.2944 21.7725 21.9559 21.737 21.6295 21.6686C19.381 21.2021 17.6919 19.2099 17.6919 16.8233C17.6919 14.4362 19.381 12.4439 21.6295 11.9774C21.9559 11.9091 22.2944 11.8735 22.6411 11.8735C25.3741 11.8735 27.5898 14.0895 27.5898 16.8233Z", fill: "#EEF7FF" }),
        React.createElement("path", { d: "M27.5897 16.8233C27.5897 19.5566 25.374 21.7725 22.641 21.7725C22.2943 21.7725 21.9558 21.737 21.6294 21.6686C23.8774 21.2021 25.5665 19.2099 25.5665 16.8233C25.5665 14.4362 23.8774 12.4439 21.6294 11.9774C21.9558 11.9091 22.2943 11.8735 22.641 11.8735C25.374 11.8735 27.5897 14.0895 27.5897 16.8233Z", fill: "#D8E5EF" }),
        React.createElement("path", { d: "M22.6411 11.4639C19.6862 11.4639 17.2822 13.8681 17.2822 16.8233C17.2822 19.7785 19.6862 22.1828 22.6411 22.1828C25.596 22.1828 28 19.7785 28 16.8233C28 13.8681 25.596 11.4639 22.6411 11.4639ZM22.6411 21.3625C20.1385 21.3625 18.1024 19.3263 18.1024 16.8233C18.1024 14.3204 20.1384 12.2842 22.6411 12.2842C25.1438 12.2842 27.1798 14.3204 27.1798 16.8233C27.1798 19.3263 25.1437 21.3625 22.6411 21.3625Z", fill: "#46637F" }),
        React.createElement("path", { d: "M13.7087 15.1274L10.1417 6.87402L9.38975 7.2016L10.6052 10.0558L5.0708 15.4982L5.64584 16.0831L10.956 10.8613L12.9567 15.455L13.7087 15.1274Z", fill: "#33E279" }),
        React.createElement("path", { d: "M14.0016 18.4947C14.9245 18.4947 15.6726 17.7465 15.6726 16.8235C15.6726 15.9006 14.9245 15.1523 14.0016 15.1523C13.0787 15.1523 12.3306 15.9006 12.3306 16.8235C12.3306 17.7465 13.0787 18.4947 14.0016 18.4947Z", fill: "#EEF7FF" }),
        React.createElement("path", { d: "M14.0085 14.7422L5.32864 15.3818C5.10278 15.3985 4.93314 15.5951 4.94987 15.8211C4.96578 16.0366 5.14565 16.201 5.35844 16.201C5.36861 16.201 5.37878 16.2006 5.38906 16.1998L12.2551 15.6925C12.0434 16.0184 11.9204 16.4068 11.9204 16.8235C11.9204 17.2401 12.0435 17.6286 12.2551 17.9544L5.38906 17.4471C5.1626 17.4312 4.96649 17.6001 4.94987 17.826C4.93314 18.0519 5.10278 18.2486 5.32864 18.2652L14.0015 18.9048H14.0019H14.0024C15.1495 18.9043 16.0827 17.9708 16.0827 16.8234C16.0827 15.6782 15.1529 14.746 14.0085 14.7422ZM14.0154 18.0845L13.8013 18.0687C13.2008 17.9725 12.7406 17.4507 12.7406 16.8235C12.7406 16.1963 13.2008 15.6746 13.8013 15.5784L14.0154 15.5626C14.7043 15.57 15.2625 16.1328 15.2625 16.8235C15.2625 17.5142 14.7043 18.077 14.0154 18.0845Z", fill: "#46637F" }),
        React.createElement("path", { d: "M8.3819 5.81738C8.08659 5.81738 7.84717 6.05566 7.84717 6.34955C7.84717 6.9603 8.34466 7.45544 8.95836 7.45544H10.7272C11.3409 7.45544 11.8384 6.9603 11.8384 6.34955C11.8384 6.05566 11.599 5.81738 11.3037 5.81738H8.3819Z", fill: "#F7904B" }),
        React.createElement("path", { d: "M22.6131 15.7255L19.0724 6.63769H21.3943C21.463 6.63769 21.5189 6.69244 21.5189 6.75965C21.5189 7.14328 21.2044 7.45538 20.8178 7.45538C20.5913 7.45538 20.4077 7.63897 20.4077 7.86554C20.4077 8.09211 20.5914 8.27569 20.8178 8.27569C21.6567 8.27569 22.3392 7.5956 22.3392 6.75965C22.3392 6.24006 21.9153 5.81738 21.3943 5.81738H18.4726C18.3371 5.81738 18.2104 5.88427 18.134 5.9961C18.0575 6.10794 18.0412 6.25023 18.0904 6.37645L19.4319 9.81957L13.6879 16.5572C13.5409 16.7296 13.5615 16.9885 13.7339 17.1354C13.8111 17.2013 13.9057 17.2335 13.9998 17.2335C14.1157 17.2335 14.2309 17.1845 14.312 17.0894L19.7699 10.6873L21.8489 16.0233C21.912 16.1855 22.0669 16.2846 22.2311 16.2846C22.2806 16.2846 22.3309 16.2756 22.3799 16.2565C22.5909 16.1743 22.6954 15.9365 22.6131 15.7255Z", fill: "#33E279" }),
        React.createElement("path", { d: "M5.35921 18.2661C6.156 18.2661 6.80193 17.6201 6.80193 16.8232C6.80193 16.0264 6.156 15.3804 5.35921 15.3804C4.56243 15.3804 3.9165 16.0264 3.9165 16.8232C3.9165 17.6201 4.56243 18.2661 5.35921 18.2661Z", fill: "#46637F" }),
        React.createElement("path", { d: "M22.6414 18.2661C23.4382 18.2661 24.0842 17.6201 24.0842 16.8232C24.0842 16.0264 23.4382 15.3804 22.6414 15.3804C21.8447 15.3804 21.1987 16.0264 21.1987 16.8232C21.1987 17.6201 21.8447 18.2661 22.6414 18.2661Z", fill: "#46637F" })));
};
