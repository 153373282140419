import * as React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import { useForm } from 'react-hook-form';
import { unwrapResult } from '@reduxjs/toolkit';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Button, FullscreenLoader, TextInput } from '~atoms';
import { MODALS, closeModal, openModal } from '~modals';
import { useAppDispatch } from '~store/configureStore';
import { editPassword as editPasswordAsyncAction, errorSelector, loadingSelector, } from '~slices/account';
import { useIsDesktop, useStructuredSelector } from '~hooks';
import { getPathWithLanguageCode } from '~utils/languageInURL/getPathWithLanguageCode';
import { editPasswordValidationSchema } from './utils';
import { useEditAccountStyles } from './MyAccount.styles';
export var EditPassword = function (_a) {
    var _b, _c, _d;
    var handleCancel = _a.handleCancel, titleClassName = _a.titleClassName, inputClasses = _a.inputClasses, visibilityColor = _a.visibilityColor;
    var t = useTranslation('my-account').t;
    var tc = useTranslation('common').t;
    var te = useTranslation('errors').t;
    var isDesktop = useIsDesktop();
    var _e = useStructuredSelector({
        loading: loadingSelector,
        responseError: errorSelector,
    }), loading = _e.loading, responseError = _e.responseError;
    var dispatch = useAppDispatch();
    var classes = useEditAccountStyles();
    var renderResponseError = function () {
        if (Array.isArray(responseError)) {
            return responseError.map(function (_a) {
                var code = _a.code;
                return te(code);
            }).join('. ');
        }
        return (responseError === null || responseError === void 0 ? void 0 : responseError.message) || te('unknown_error');
    };
    var redirectToAccountMenu = function () {
        dispatch(push(getPathWithLanguageCode('/my-account')));
    };
    var _f = useForm({
        validationSchema: editPasswordValidationSchema,
        mode: 'onChange',
        submitFocusError: true,
    }), register = _f.register, handleSubmit = _f.handleSubmit, errors = _f.errors;
    var submit = function (values) {
        dispatch(editPasswordAsyncAction(values))
            .then(unwrapResult)
            .then(function () {
            return isDesktop
                ? (dispatch(closeModal({ key: MODALS.EDIT_PASSWORD })),
                    dispatch(openModal({
                        modalName: MODALS.SUCCESS_MODAL_NOTIFICATION,
                        modalProps: {
                            title: t('updated_messages.password.header'),
                            subheader: t('updated_messages.password.subheader'),
                        },
                    })))
                : dispatch(push(getPathWithLanguageCode('/my-account/password/updated')));
        });
    };
    return (React.createElement("div", { className: classNames('flex-column', classes.root) },
        React.createElement("div", { className: titleClassName || classes.title }, t('password.header')),
        React.createElement("form", { className: classNames(classes.form, 'flex-column'), onSubmit: handleSubmit(submit) },
            React.createElement("div", { className: classes.flex },
                React.createElement(TextInput, { name: "password", showAsterisk: true, title: t('password.current_password'), placeholder: t('password.enter_password'), type: "password", inputRef: register, error: !!errors.password, helperText: (_b = errors === null || errors === void 0 ? void 0 : errors.password) === null || _b === void 0 ? void 0 : _b.message, className: inputClasses, visibilityColor: visibilityColor }),
                React.createElement(Divider, { classes: { root: classes.divider } }),
                React.createElement(TextInput, { showAsterisk: true, name: "newPassword", title: t('password.new_password'), placeholder: t('password.enter_new'), type: "password", inputRef: register, error: !!errors.newPassword, helperText: (_c = errors === null || errors === void 0 ? void 0 : errors.newPassword) === null || _c === void 0 ? void 0 : _c.message, visibilityColor: visibilityColor }),
                React.createElement(TextInput, { name: "repeatNewPassword", title: t('password.repeat_new'), showAsterisk: true, placeholder: t('password.enter_repeat'), type: "password", inputRef: register, error: !!errors.repeatNewPassword, helperText: (_d = errors === null || errors === void 0 ? void 0 : errors.repeatNewPassword) === null || _d === void 0 ? void 0 : _d.message, className: inputClasses, visibilityColor: visibilityColor }),
                responseError && (React.createElement(FormHelperText, { id: "component-error-text", error: !!responseError }, renderResponseError()))),
            React.createElement(Grid, { container: true, style: { marginTop: isDesktop ? '8px' : 'auto' }, justify: "space-between", spacing: 2 },
                React.createElement(Grid, { key: "cancel", item: true },
                    React.createElement(Button, { size: "large", variant: "outlined", classes: { root: classes.button }, onClick: handleCancel || redirectToAccountMenu, color: "secondary" }, tc('buttons.cancel'))),
                React.createElement(Grid, { key: "next", item: true },
                    React.createElement(Button, { size: "large", variant: "contained", type: "submit", classes: { root: classes.button }, color: "primary" }, tc('buttons.save'))))),
        loading === 'pending' ? React.createElement(FullscreenLoader, null) : null));
};
