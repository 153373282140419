import React, { useEffect, useState } from 'react';
import { GameTypeButton } from '~molecules/GameTypeButton';
import { useStyles } from './GameTypeButtons.styles';
export var GameTypeButtons = function (_a) {
    var games = _a.games, activeGame = _a.activeGame, onClick = _a.onClick;
    var classes = useStyles();
    var _b = useState(null), activeGameType = _b[0], setActiveGameType = _b[1];
    useEffect(function () {
        if (activeGame) {
            setActiveGameType(activeGame);
        }
    }, [activeGame]);
    var onButtonClick = function (type) {
        setActiveGameType(type);
        if (onClick) {
            onClick(type);
        }
    };
    return (React.createElement("div", { className: classes.wrapper }, games === null || games === void 0 ? void 0 : games.map(function (game) { return (React.createElement(GameTypeButton, { key: game.name, name: game.name, isActiveButton: activeGameType === game.name, isActiveGame: game.active, creditBalance: game.startCredits, playerBalance: game.playerBalance, tournament: game.tournament, onClick: function () { return onButtonClick(game.name); } })); })));
};
