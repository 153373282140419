import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { isAuthenticatedSelector } from '~slices/auth';
import { useStructuredSelector } from '~hooks';
import { useStyles } from '../topRanking.styles';
export var PayIcon = function (_a) {
    var providedTextKey = _a.providedTextKey, _b = _a.iconClassName, iconClassName = _b === void 0 ? 'fa fa-link' : _b, isLink = _a.isLink, isDeepLink = _a.isDeepLink;
    var t = useTranslation('ticket').t;
    var isAuthenticated = useStructuredSelector({
        isAuthenticated: isAuthenticatedSelector,
    }).isAuthenticated;
    var isDisplayAnonymousRankingList = process.env.ANONYMOUS_RANKING_LIST === 'true';
    var isLinkItem = (!isAuthenticated && isDisplayAnonymousRankingList && isLink) ||
        (isAuthenticated && isLink);
    var isCrownDisplay = (!isLinkItem && !isDeepLink) ||
        (!isAuthenticated && !isDisplayAnonymousRankingList);
    var text = isLinkItem ? t('forward') : t('integration');
    var borderColor = isLinkItem ? '1px solid #D3D3D3' : '1px solid #2AA1F7';
    if (providedTextKey) {
        text = t(providedTextKey);
    }
    var classes = useStyles();
    return (React.createElement("div", { className: classNames(classes.paymentIcon), style: { border: borderColor } },
        React.createElement("i", { className: classNames(iconClassName, classes.icon) }),
        isCrownDisplay && (React.createElement("div", { className: "shield", style: { color: 'rgb(42, 161, 247)', fontSize: '8px' } },
            React.createElement("i", { className: classNames('fas fa-crown', classes.shield) }))),
        React.createElement("span", { style: { marginTop: '2px' } }, text)));
};
