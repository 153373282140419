import makeStyles from '@material-ui/core/styles/makeStyles';
export var useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '12px 10px 8px 10px',
        height: 52,
        width: 80,
        minWidth: 80,
        background: '#333333',
        borderRadius: 8,
        cursor: 'pointer',
    },
    active: {
        border: '1px solid #FFF',
        height: 50,
        width: 78,
        minWidth: 78,
    },
    title: {
        width: '100%',
        marginTop: 6,
        fontWeight: 600,
        fontSize: 12,
        color: '#F9F9F9',
        textAlign: 'center',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        overflow: 'hidden',
        whiteSpace: 'normal',
        textOverflow: 'ellipsis',
    },
    balance: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 14,
        fontWeight: 400,
        color: '#F9F9F9',
        '& svg': {
            marginLeft: 4,
            width: 12,
            height: 12,
        },
    },
}, { name: 'GameButtonSimple' });
