import makeStyles from '@material-ui/core/styles/makeStyles';
export var useStyles = makeStyles({
    container: {
        height: '48px',
        width: '100%',
        background: 'radial-gradient(100% 100% at 50.13% 0%, #303038 0%, #1B1B22 100%)',
        paddingRight: '15px',
        paddingLeft: '4px ',
        fontSize: '12px',
        flexShrink: 0,
    },
    positionFixed: {
        position: 'fixed',
        top: '51px',
        zIndex: 11,
    },
    componentWrapper: {
        maxHeight: 'calc(100vh - 52px)',
        overflowY: 'auto',
    },
    wrapper: {
        paddingTop: 119,
        paddingBottom: 20,
        boxSizing: 'border-box',
    },
    desktopWrapper: {
        marginTop: '4px',
        paddingTop: '16px',
        background: '#151515',
        borderRadius: '4px',
        overflowX: 'hidden',
    },
    title: {
        textAlign: 'center',
        fontSize: '18px',
        color: '#FFFFFF',
        marginBottom: '16px',
    },
    maxWidth: {
        maxWidth: '346px',
        margin: 'auto',
    },
    loaderHeight: {
        height: '500px',
    },
    fullWidth: {
        width: '100%',
    },
    greenText: {
        '& span': {
            fontSize: '14px',
            fontWeight: 400,
            color: '#6CFF00',
        },
    },
    levels: {
        padding: '0 11px',
        marginBottom: '10px',
    },
    emptyRankingList: {
        minHeight: '220px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '22px',
        fontWeight: 700,
        color: '#FFFFFF',
    },
    userCard: {
        padding: '0 11px',
        boxSizing: 'border-box',
    },
    listWrapper: {
        padding: '0 11px',
    },
    dividerContainer: {
        width: '100%',
        margin: '20px 0',
        padding: '0 11px',
        borderRadius: 4,
        boxSizing: 'border-box',
    },
    divider: {
        width: '100%',
        height: 1,
        background: '#3C3B3B',
    },
    top99: {
        top: 99,
    },
    mt16: {
        marginTop: 16,
    },
    mt24: {
        marginTop: 24,
    },
    mt42: {
        marginTop: 42,
    },
    paddingTop48: {
        paddingTop: 48,
    },
}, { name: 'RankingListComponents' });
