var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { useLocation } from 'react-router-dom';
import { BalanceCard } from '~atoms/BalanceCard';
import { useStructuredSelector } from '~hooks';
import { GameTypeButtons } from '~atoms/GameTypeButtons';
import { betslipDataSelector } from '~slices/betslip';
import { GAMES_MODE } from "~slices/gameTypes/constants";
import { getPathWithLanguageCode } from '~utils/languageInURL';
import { PaymentsURL } from '~pages/payments/payments.routes';
import { FullscreenLoader } from '~atoms';
import { useAppDispatch } from '~store';
import { toCapitalize } from '~utils/toCapitalize';
import { isBetCheckProject } from "~utils/constants/betCheckConstants";
import { setActiveGame, useActiveGameSelector, useGameTypesDataSelector, } from "~slices/gameTypes";
import { usePlayerAccountsDataSelector } from "~slices/playerAccounts";
import { useStyles } from './TopUpTab.styles';
var DEFAULT_TOP_UP_VALUE = 10;
export var TopUpTab = function () {
    var _a;
    var classes = useStyles();
    var dispatch = useAppDispatch();
    var t = useTranslation('tip-game').t;
    var _b = useState(null), activeCard = _b[0], setActiveCard = _b[1];
    var _c = useState(DEFAULT_TOP_UP_VALUE), topUpValue = _c[0], setTopUpValue = _c[1];
    var _d = useState(DEFAULT_TOP_UP_VALUE / 10), labelValue = _d[0], setLabelValue = _d[1];
    var _e = useState(false), isDataLoading = _e[0], setIsDataLoading = _e[1];
    var search = useLocation().search;
    var params = new URLSearchParams(search);
    var gameTypeParams = params.get('type');
    var onsetTopUpValue = function (value) {
        setTopUpValue(value);
        setLabelValue(Number(value) / 10);
    };
    var _f = useStructuredSelector({
        betslip: betslipDataSelector,
        gameTypes: useGameTypesDataSelector,
        activeGame: useActiveGameSelector,
        playerAccounts: usePlayerAccountsDataSelector,
    }), betslip = _f.betslip, gameTypes = _f.gameTypes, activeGame = _f.activeGame, playerAccounts = _f.playerAccounts;
    var games = useMemo(function () { return gameTypes.filter(function (game) { return (game === null || game === void 0 ? void 0 : game.gameMode) === GAMES_MODE.GLOBAL; }); }, [gameTypes]);
    var ticketOffer = betslip.to;
    var currency = (_a = ticketOffer === null || ticketOffer === void 0 ? void 0 : ticketOffer.cu) !== null && _a !== void 0 ? _a : 'NONE';
    var _g = useState(null), gamesBalance = _g[0], setGamesBalance = _g[1];
    useEffect(function () {
        var activeAccount = playerAccounts.find(function (account) { return account.type !== 'STATUS'; });
        if (activeAccount) {
            setActiveCard(activeAccount.type);
        }
    }, [playerAccounts]);
    useEffect(function () {
        if (!isBetCheckProject && games) {
            var updatedGamesBalance_1 = __assign({}, gamesBalance);
            games.forEach(function (game) {
                if (game.hasOwnProperty('playerBalance') && typeof game.playerBalance === 'number') {
                    updatedGamesBalance_1[game.name] = game.playerBalance;
                }
                else {
                    updatedGamesBalance_1[game.name] = game.startCredits;
                }
            });
            setGamesBalance(updatedGamesBalance_1);
        }
    }, [games]);
    useEffect(function () {
        if (gameTypeParams && gamesBalance) {
            var calculatedRoundedValue = Math.ceil((ticketOffer === null || ticketOffer === void 0 ? void 0 : ticketOffer.wa) ? (ticketOffer === null || ticketOffer === void 0 ? void 0 : ticketOffer.wa) - gamesBalance[gameTypeParams] : 0);
            if (calculatedRoundedValue > 0) {
                setTopUpValue(calculatedRoundedValue);
                setLabelValue(calculatedRoundedValue / 10);
            }
        }
    }, [ticketOffer === null || ticketOffer === void 0 ? void 0 : ticketOffer.wa, search, gamesBalance]);
    var onBalanceCardClick = function (type) {
        setActiveCard(type);
    };
    var onGameTypeButtonClick = function (type) {
        dispatch(setActiveGame(type));
    };
    var topUpBalance = function () {
        setIsDataLoading(true);
        setTimeout(function () {
            setIsDataLoading(false);
        }, 2000);
        setTimeout(function () {
            dispatch(push(getPathWithLanguageCode("/".concat(PaymentsURL.PAYMENTS, "/").concat(PaymentsURL.TOP_UP_STATUS, "?sum=").concat(topUpValue)))
            // TODO, desktop version?
            // openModal({
            //   modalName: MODALS.SUCCESS_MODAL_NOTIFICATION,
            //   modalProps: {
            //     title: t('updated_messages.data.header'),
            //   },
            // })
            );
        }, 2100);
    };
    return (React.createElement("div", { className: classes.container },
        isDataLoading && React.createElement(FullscreenLoader, null),
        React.createElement("div", null,
            React.createElement("div", { className: classes.balanceCardWrapper }, playerAccounts.map(function (account, index) { return (React.createElement(BalanceCard, { key: index, type: account.type, currency: account.currency, isActive: activeCard === account.type, balance: account.balance, onClick: account.type === 'STATUS' ? undefined : function () { return onBalanceCardClick(account.type); } })); })),
            React.createElement("div", { className: classes.gameTypeButtons },
                React.createElement(GameTypeButtons, { activeGame: activeGame || '', games: games, onClick: onGameTypeButtonClick })),
            React.createElement("div", { className: classes.description }, activeCard ? (t('payments.you-are-topping-up-game-by-credits', {
                gameType: toCapitalize(activeGame || ''),
                credits: topUpValue,
                currency: "".concat(labelValue, " ").concat(currency),
                balanceType: toCapitalize(activeCard !== null && activeCard !== void 0 ? activeCard : ''),
            })) : null))));
};
