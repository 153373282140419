import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './BetInfo.styles';
var BetInfo = function (_a) {
    var eventsCount = _a.eventsCount, maxOdd = _a.maxOdd, combinationName = _a.combinationName, combinationValue = _a.combinationValue;
    var t = useTranslation('ticket').t;
    var classes = useStyles();
    return (React.createElement("div", { className: classes.container },
        React.createElement("div", { className: classes.row },
            React.createElement("div", { className: classes.name }, t('events')),
            React.createElement("div", { className: classes.value }, eventsCount)),
        React.createElement("div", { className: classes.row },
            React.createElement("div", { className: classes.name }, t('max_odd')),
            React.createElement("div", { className: classes.value }, maxOdd === null || maxOdd === void 0 ? void 0 : maxOdd.toFixed(2))),
        React.createElement("div", { className: classes.row },
            React.createElement("div", { className: classes.name }, combinationName),
            React.createElement("div", { className: classes.value }, combinationValue))));
};
export { BetInfo };
