import { useSelector } from 'react-redux';
import { name } from './constants';
export var PPMOSelector = function (state) { return state[name]; };
export var PPMODataSelector = function (state) { return PPMOSelector(state).data; };
export var isPendingSelector = function (state) {
    return PPMOSelector(state).loading === 'pending';
};
export var isFulfilledSelector = function (state) {
    return PPMOSelector(state).loading === 'fulfilled';
};
export var PPMOLoadingSelector = function (state) {
    return PPMOSelector(state).loading;
};
/* @deprecated use useStructuredSelector instead */
export var usePPMOLoadingSelector = function () { return useSelector(PPMOLoadingSelector); };
/* @deprecated use useStructuredSelector instead */
export var usePPMOSelector = function () { return useSelector(PPMODataSelector); };
/* @deprecated use useStructuredSelector instead */
export var usePendingSelector = function () { return useSelector(isPendingSelector); };
/* @deprecated use useStructuredSelector instead */
export var useFulfilledSelector = function () { return useSelector(isFulfilledSelector); };
