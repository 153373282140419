import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '~atoms';
import { checkIfFieldIsRequired, required, usernameValidation, validateField, } from '~utils/formValidators';
export var UserName = function (_a) {
    var tabIndex = _a.tabIndex, registrationBlocks = _a.registrationBlocks;
    var t = useTranslation('registration').t;
    return (React.createElement(TextField, { name: "username", title: t('form.username'), placeholder: t('form.enter_username'), validators: [
            function (value) {
                return validateField(value, 'username', tabIndex, required, registrationBlocks);
            },
            function (value) {
                return validateField(value, 'username', tabIndex, usernameValidation, registrationBlocks);
            },
        ], required: checkIfFieldIsRequired('username', tabIndex, registrationBlocks) }));
};
