var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect } from 'react';
import i18next from 'i18next';
import { useRouteMatch } from 'react-router-dom';
import { useAppDispatch } from '~store';
import { categoriesDataSelector, getFlagOutrights } from '~slices/outrights';
import { useStructuredSelector } from '~hooks';
import { currentSportIdSelector } from '~slices/options';
import { List } from './List';
export var TournamentsList = function (_a) {
    var _b, _c;
    var onClick = _a.onClick, onBackClick = _a.onBackClick, rest = __rest(_a, ["onClick", "onBackClick"]);
    var dispatch = useAppDispatch();
    var match = useRouteMatch([
        '/home/:flagId/outrights/:tournamentId',
        '/home/:flagId/outrights',
    ]);
    var _d = useStructuredSelector({
        categories: categoriesDataSelector,
        selectedSportId: currentSportIdSelector,
    }), categories = _d.categories, selectedSportId = _d.selectedSportId;
    var tournamentId = (_b = match === null || match === void 0 ? void 0 : match.params) === null || _b === void 0 ? void 0 : _b.tournamentId;
    useEffect(function () {
        if (Array.isArray(categories) && categories.length && tournamentId) {
            var selectedCategory = categories.find(function (_a) {
                var tid = _a.tid;
                return tid === parseInt(tournamentId, 10);
            });
            if (selectedCategory && onClick) {
                onClick(selectedCategory);
            }
        }
    }, [categories, tournamentId]);
    useEffect(function () {
        var _a, _b;
        if (i18next.language && ((_a = match === null || match === void 0 ? void 0 : match.params) === null || _a === void 0 ? void 0 : _a.flagId)) {
            dispatch(getFlagOutrights({
                flagId: Number((_b = match === null || match === void 0 ? void 0 : match.params) === null || _b === void 0 ? void 0 : _b.flagId),
                langIso: i18next.language,
                sportId: selectedSportId,
            }));
        }
    }, [i18next.language, (_c = match === null || match === void 0 ? void 0 : match.params) === null || _c === void 0 ? void 0 : _c.flagId, selectedSportId]);
    var onClickHandler = function (item) {
        if (item && onClick) {
            onClick(item);
        }
    };
    return (React.createElement(List, __assign({ list: categories, 
        // @ts-expect-error
        onClick: onClickHandler, onBackClick: onBackClick }, rest)));
};
