import makeStyles from '@material-ui/core/styles/makeStyles';
var useStyles = makeStyles({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 'calc(100% - 22px)',
        position: 'relative',
        padding: '10px',
        background: '#2D2D2D',
        border: '1px solid transparent',
        borderRadius: '4px',
        fontSize: '12px',
        color: '#FFFFFF',
        '& img': {
            maxHeight: '24px',
            maxWidth: '24px',
            marginBottom: '12px',
        },
    },
    amount: {
        display: 'flex',
        marginTop: '3px',
        '& svg': {
            marginLeft: '3px',
        },
        '& div': {
            marginLeft: '3px',
            fontSize: '12px',
        },
    },
    active: {
        border: '1px solid #00C150',
        background: 'radial-gradient(100% 100% at 50.13% 0%, #303038 0%, #1B1B22 100%)',
    },
    checkMark: {
        position: 'absolute',
        top: '0',
        right: '0',
        width: '0',
        height: '0',
        borderTop: '23px solid #00C150',
        borderLeft: '23px solid transparent',
        '& i': {
            position: 'absolute',
            bottom: '10px',
            right: '0',
            color: '#FFFFFF',
            fontWeight: '400',
        },
    },
    cursorPointer: {
        cursor: 'pointer',
    },
    text: {
        textAlign: 'center',
    },
}, { name: 'BalanceCard' });
export { useStyles };
