import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import classNames from "classnames";
import Dialog from "@material-ui/core/Dialog/Dialog";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import { closeModal } from "~modals";
import { useModalStyles } from "~modals/components/Modal.styles";
var JoinGameDescriptionModal = function (_a) {
    var modalName = _a.modalName, _b = _a.isVisible, isVisible = _b === void 0 ? false : _b, handleClose = _a.modalProps.handleClose;
    var t = useTranslation(['common', 'tip-game']).t;
    var dispatch = useDispatch();
    var classes = useModalStyles();
    var handleCancel = function () {
        dispatch(closeModal({ key: modalName }));
    };
    var onTipNowClick = function () {
        if (handleClose) {
            handleClose();
        }
        handleCancel();
    };
    return (React.createElement(Dialog, { open: isVisible, onClose: handleCancel, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description", className: classes.root },
        React.createElement(Button, { onClick: handleCancel, className: classNames(classes.closeIcon, classes.closeIconGray) },
            React.createElement("i", { className: "fas fa-times" })),
        React.createElement(DialogContent, { className: classNames(classes.dialog, classes.dialogContainer) },
            React.createElement("div", { className: classes.joinGameTitle }, t('tip-game:join-game-description.title')),
            React.createElement("div", { className: classes.joinGameContent },
                React.createElement("div", { className: classes.joinGameItem }, t('tip-game:join-game-description.p1')),
                React.createElement("div", { className: classes.divider }),
                React.createElement("div", { className: classes.joinGameItem }, t('tip-game:join-game-description.p2')),
                React.createElement("div", { className: classes.divider }),
                React.createElement("div", { className: classes.joinGameItem }, t('tip-game:join-game-description.p3')),
                React.createElement("div", { className: classes.divider }),
                React.createElement("div", { className: classes.joinGameItem }, t('tip-game:join-game-description.p4'))),
            React.createElement(Button, { fullWidth: true, size: "large", color: "primary", variant: "contained", style: { margin: '20px 0' }, onClick: onTipNowClick },
                t('tip-game:payments.tip_now'),
                "!"))));
};
export { JoinGameDescriptionModal };
