import React from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from '~modals';
import { EditPassword } from '~pages/my-account/EditPassword';
import { resetErrors } from '~slices/account';
import { useStyles } from './EditModals.styles';
import { EditModalContainer } from './EditModalContainer';
export var EditPasswordModal = function (_a) {
    var modalName = _a.modalName;
    var dispatch = useDispatch();
    var classes = useStyles();
    var handleCancel = function () {
        dispatch(resetErrors());
        dispatch(closeModal({ key: modalName }));
    };
    return (React.createElement(EditModalContainer, { handleCancel: handleCancel },
        React.createElement(EditPassword, { titleClassName: "".concat(classes.title, " ").concat(classes.editPasswordTitle), handleCancel: handleCancel, inputClasses: classes.inputClasses, visibilityColor: "#212121" })));
};
