var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { FormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Button } from '~atoms/button';
import { closeModal } from '../modalSlice';
import { useModalStyles } from './Modal.styles';
export var FormModal = function (_a) {
    var _b;
    var modalName = _a.modalName, _c = _a.isVisible, isVisible = _c === void 0 ? false : _c, methods = _a.methods, _d = _a.modalProps, message = _d.message, onSubmit = _d.onSubmit, dialogClassName = _d.dialogClassName, hideBackdrop = _d.hideBackdrop, title = _d.title, titleClassName = _d.titleClassName, saveButtonLabel = _d.saveButtonLabel, cancelButtonLabel = _d.cancelButtonLabel, closable = _d.closable, actionsClassName = _d.actionsClassName, children = _a.children;
    var dispatch = useDispatch();
    var modalClasses = useModalStyles();
    var handleCancel = function () {
        dispatch(closeModal({ key: modalName }));
    };
    return (React.createElement(FormContext, __assign({}, methods),
        React.createElement(Dialog, { open: isVisible, 
            // onClose={this.handleClose}
            classes: {
                paperWidthSm: classNames((_b = {},
                    _b[modalClasses.shinyBack] = true,
                    _b[modalClasses.containerForClosableModal] = closable,
                    _b)),
            }, disableAutoFocus: true, disableBackdropClick: true, className: dialogClassName, hideBackdrop: hideBackdrop },
            title && (React.createElement(DialogTitle, { disableTypography: true, className: titleClassName, classes: { root: modalClasses.title } }, title)),
            closable && (React.createElement(Button, { onClick: handleCancel, className: modalClasses.closeIcon },
                React.createElement("i", { className: classNames('far fa-times') }))),
            React.createElement(DialogContent, { classes: { root: modalClasses.content } },
                message && React.createElement("div", null, message),
                React.createElement("form", null, children)),
            React.createElement(DialogActions, { classes: {
                    root: classNames(actionsClassName, modalClasses.formModalDialogAction, modalClasses.dialogAction),
                } },
                React.createElement(Button, { classes: { root: modalClasses.formButton }, color: "secondary", variant: "outlined", fullWidth: true, onClick: handleCancel }, cancelButtonLabel),
                React.createElement(Button, { classes: { root: modalClasses.formButton }, variant: "contained", fullWidth: true, type: "submit", onClick: methods.handleSubmit(onSubmit), color: "primary" }, saveButtonLabel)))));
};
