// TODO: fix max lines issue
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable max-lines */
import makeStyles from '@material-ui/core/styles/makeStyles';
export default makeStyles({
    root: {
        '& p': {
            margin: '8px',
            fontSize: '12px',
            lineHeight: 1.5,
        },
    },
    header: {
        position: 'relative',
        padding: '2px 16px',
        display: 'flex',
        alignItems: 'center',
        minHeight: '28px',
        boxSizing: 'border-box',
        color: '#ffffff',
        fontSize: '10px',
        lineHeight: 1.2,
        backgroundColor: '#2D2D2D',
    },
    headerSpacer: {
        margin: '0 auto',
    },
    headerFlag: {
        marginRight: '8px',
        display: 'block',
        maxHeight: '14px',
        width: '19px',
    },
    headerIcon: {
        marginLeft: '8px',
        position: 'relative',
        '& svg': {
            marginRight: '8px',
            width: '14px',
            height: 'auto',
        },
        '&:before': {
            content: '""',
            position: 'absolute',
            left: '-8px',
            top: 0,
            display: 'block',
            height: '15px',
            borderLeft: '1px solid rgba(142, 142, 142, 0.2);',
        },
    },
    headerBadge: {
        margin: '0 8px',
        '&:last-child': {
            marginRight: 0,
        },
    },
    badge: {
        minWidth: '20px',
        boxSizing: 'border-box',
        padding: '4px',
        background: '#FFFFFF',
        borderRadius: '2px',
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '12px',
        lineHeight: 1,
        color: '#212121',
    },
    banker: {
        cursor: 'pointer',
        outline: 'none',
        position: 'absolute',
        right: '107px',
    },
    badgePrimary: {
        background: '#2AA1F7',
        color: '#FFFFFF',
    },
    badgeSuccess: {
        background: '#00C150',
        color: '#FFFFFF',
    },
    noSr: {
        width: '3px',
        height: '3px',
        borderRadius: '50%',
        marginLeft: '4px',
        marginRight: '8px',
    },
    sr: {
        backgroundColor: '#F9DC1C',
    },
    oddValue: {
        borderBottomColor: '#000000',
        background: '#151515',
        backgroundClip: 'padding-box',
        color: '#ffffff',
        position: 'relative',
        outline: 'none',
        width: '64px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    oddValueDesktop: {
        borderLeft: '1px solid rgba(142, 142, 142, 0.2)',
        background: '#151515',
        borderRight: '1px solid rgba(142, 142, 142, 0.2)',
    },
    fullWidth: {
        width: '100%',
    },
    deleteButton: {
        padding: 0,
        fontSize: 'inherit',
        color: 'inherit',
        width: '100%',
        height: '100%',
        borderRadius: 0,
        background: 'transparent',
    },
    colorPrimary: {
        color: '#2AA1F7',
        fontWeight: 'bold',
    },
    colorDanger: {
        color: '#FF0000',
    },
    textRight: {
        textAlign: 'right',
    },
    triangleIncrease: {
        top: '2px',
        right: '2px',
        position: 'absolute',
    },
    triangleDecrease: {
        bottom: '2px',
        left: '2px',
        position: 'absolute',
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    alignCenter: {
        alignItems: 'center',
    },
    competitor: {
        fontSize: '12px',
        lineHeight: '18px',
        color: '#ffffff',
        alignItems: 'center',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        '& > i': {
            marginRight: '6px',
        },
    },
    competitorScore: {
        fontSize: '12px',
        lineHeight: '18px',
        display: 'flex',
        color: '#8E8E8E',
        justifyContent: 'center',
        fontStyle: 'normal',
        fontWeight: 'normal',
        paddingRight: '4px',
        width: '14px',
    },
    competitorTennisScore: {
        fontSize: '8px',
        lineHeight: '18px',
    },
    competitorScoreResult: {
        color: '#fff',
        width: '39px',
        paddingRight: 0,
        '&:last-child': {
            borderLeft: '1px solid rgba(255,255,255, 0.2)',
        },
    },
    outrightsBlock: {
        padding: '8px 0 8px 16px',
        width: '100%',
        boxSizing: 'border-box',
        outline: 'none',
    },
    autoMargin: {
        marginLeft: 'auto',
    },
    marketRow: {
        minHeight: '30px',
        alignItems: 'stretch',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '18px',
        color: '#FFFFFF',
        paddingLeft: '16px',
        borderTop: '1px solid rgba(142,142,142, 0.2)',
    },
    marketName: {
        flex: '1 0 50%',
        alignItems: 'center',
        '& > span': {
            opacity: '0.6',
        },
    },
    marketValue: {
        width: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderLeft: '1px solid rgba(142, 142, 142, 0.2);',
    },
    deletableBlock: {
        width: '39px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    card: {
        width: '11px',
        height: '15px',
        fontWeight: 'bold',
        fontSize: '8px',
        lineHeight: '9px',
        borderRadius: '1px',
        marginRight: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    yellowCard: {
        color: '#212121',
        background: '#FFEB3B',
    },
    redCard: {
        color: '#ffffff',
        background: '#FF0000',
    },
    trendIconHigher: {
        color: '#00C150',
    },
    trendIconLower: {
        color: '#FF0000',
    },
    trendIconEqual: {
        color: '#8E8E8E',
    },
    blackBackground: {
        background: '#151515',
    },
    greyBlueBackground: {
        background: '#23232B',
    },
    lightGreyBackground: {
        background: '#2D2D2D',
    },
    darkGreyBackground: {
        background: '#34343E',
    },
    shadowColor: {
        color: '#8E8E8E',
    },
    blue: {
        background: '#2AA1F7',
        borderRadius: '2px',
        padding: '3px 4px',
    },
    sets: {
        fontSize: '12px',
        lineHeight: '18px',
        color: '#fff',
        marginLeft: '6px',
        fontWeight: 'bold',
        minWidth: '28px',
    },
    tournamentTitle: {
        paddingRight: '5px',
        width: '221px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    competeSimulate: {
        position: 'absolute',
        left: 0,
        top: 0,
        height: '100%',
        cursor: 'pointer',
        right: '90px',
        zIndex: 1,
        '&:focus': {
            outline: 'none',
        },
    },
    competitorBlockDesktop: {
        backgroundColor: '#151515',
        position: 'relative',
    },
    pointer: {
        cursor: 'pointer',
    },
    '@keyframes goalPaneMoving': {
        '6%': {
            transform: 'scaleX(1) translateY(-50%)',
        },
        '60%': {
            transform: 'scaleX(1) translateY(-50%)',
        },
        '66%': {
            transform: 'scaleX(0) translateY(-50%)',
        },
    },
    '@keyframes nogoalPaneMoving': {
        '5%': {
            transform: 'scaleX(1) translateY(-50%)',
        },
        '60%': {
            transform: 'scaleX(1) translateY(-50%)',
        },
        '95%': {
            transform: 'scaleX(1) translateY(-50%)',
        },
        '100%': {
            transform: 'scaleX(0) translateY(-50%)',
        },
    },
    '@keyframes nocardPaneMoving': {
        '5%': {
            transform: 'scaleX(1) translateY(-50%)',
        },
        '60%': {
            transform: 'scaleX(1) translateY(-50%)',
        },
        '95%': {
            transform: 'scaleX(1) translateY(-50%)',
        },
        '100%': {
            transform: 'scaleX(0) translateY(-50%)',
        },
    },
    '@keyframes textPaneBlinking': {
        '16%': {
            transform: 'scale(1)',
        },
        '26%': {
            transform: 'scale(1)',
        },
        '36%': {
            transform: 'scale(0.72)',
        },
        '46%': {
            transform: 'scale(1)',
        },
        '56%': {
            transform: 'scale(1)',
        },
        '66%': {
            transform: 'scale(0)',
        },
    },
    bsr: {
        position: 'absolute',
        right: '145px',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 48,
        height: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#FFFFFF',
        color: '#212121',
        borderRadius: 2,
    },
    goalPanel: {
        position: 'absolute',
        right: '145px',
        top: '50%',
        transform: 'scaleX(0) translateY(-50%)',
        width: 48,
        height: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#2AA1F7',
        borderRadius: 2,
        textAlign: 'center',
        animation: "$goalPaneMoving ".concat(process.env.GOAL_ANIMATION_FLASH_DURATION, "s linear ").concat(process.env.GOAL_ANIMATION_FLASHES_COUNT),
        transformOrigin: 'right',
        zIndex: 10,
    },
    goalPaneText: {
        fontSize: 8,
        lineHeight: 1,
        letterSpacing: '0.4em',
        textTransform: 'uppercase',
        transform: 'scale(0)',
        animation: "$textPaneBlinking ".concat(process.env.GOAL_ANIMATION_FLASH_DURATION, "s linear 3"),
    },
    nogoal: {
        animation: '$nogoalPaneMoving 5s linear 1',
        transform: 'scaleX(0) translateY(-50%)',
        transformOrigin: 'right',
    },
    nocard: {
        animation: '$nocardPaneMoving 5s linear 1',
        transform: 'scaleX(0) translateY(-50%)',
        transformOrigin: 'right',
    },
    bsrText: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: '8px',
        textAlign: 'center',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        padding: '0 3px',
        '&.animation': {
            textTransform: 'uppercase',
            transform: 'scale(0)',
            animation: '$textPaneBlinking 3s linear 3',
        },
    },
}, { name: 'Event' });
export var useCompetitorBlockStyles = makeStyles({
    competitorBlock: {
        padding: '8px 0 8px 16px',
        width: '100%',
        boxSizing: 'border-box',
    },
    competitorBlockDesktop: {
        backgroundColor: '#151515',
        position: 'relative',
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    marginLeft: {
        marginLeft: '20px',
    },
    competeSimulate: {
        position: 'absolute',
        left: 0,
        top: 0,
        height: '100%',
        cursor: 'pointer',
        right: '90px',
        zIndex: 1,
        '&:focus': {
            outline: 'none',
        },
    },
}, { name: 'CompetitorBlock' });
