import { useCallback, useRef, useState } from 'react';
import { AnimationDuration } from '~constants/view/AnimationDuration';
var useSportSelection = function () {
    var _a = useState(false), isSportSelectionOpen = _a[0], setIsSportSelectionOpen = _a[1];
    var disableDropDownRef = useRef(false);
    var anchorRef = useRef(null);
    var closeDropdown = useCallback(function () {
        if (disableDropDownRef.current) {
            return;
        }
        setIsSportSelectionOpen(false);
        disableDropDownRef.current = true;
        setTimeout(function () {
            disableDropDownRef.current = false;
        }, AnimationDuration.SHORT.ms);
    }, []);
    var openDropdown = useCallback(function () {
        if (!disableDropDownRef.current) {
            setIsSportSelectionOpen(true);
        }
    }, []);
    return {
        isSportSelectionOpen: isSportSelectionOpen,
        anchorRef: anchorRef,
        closeDropdown: closeDropdown,
        openDropdown: openDropdown,
    };
};
export { useSportSelection };
