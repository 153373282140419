import get from 'lodash.get';
var ENDED_EVENT_STATUS = 'ENDED';
var CLEARED_EVENT_STATUS = 'CLEARED';
var BEFORE_EVENT_STATUS = 'BEFORE';
var CANCELLED_EVENT_STATUS = 'CANCELLED';
export var FINISHED_STATUSES = [ENDED_EVENT_STATUS, CLEARED_EVENT_STATUS];
export var POSSIBLE_GOAL_IDS = [1, 4, 5];
export var POSSIBLE_RED_CARDS_IDS = [2, 6, 7];
export var NOT_STARTED_STATUSES = [
    BEFORE_EVENT_STATUS,
    CANCELLED_EVENT_STATUS,
];
export var isOddActive = function (outcome) { return get(outcome, 'od.act'); };
export var isSelectionActive = function (selection) { var _a, _b; return (_b = (_a = selection.oc) === null || _a === void 0 ? void 0 : _a.odd) === null || _b === void 0 ? void 0 : _b.act; };
export var isEventActive = function (event) {
    var _a;
    return Object.values(((_a = event.sl) === null || _a === void 0 ? void 0 : _a.sel) || {}).every(function (selection) {
        return isSelectionActive(selection);
    });
};
