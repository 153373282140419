var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import i18next from 'i18next';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useAppDispatch } from '~store';
import { setLanguage } from '~slices/options';
import { usePlatofrmType, useStructuredSelector } from '~hooks';
import { isAuthenticatedSelector } from '~slices/auth';
import { setLanguageCodeInURL } from '~utils/languageInURL';
import { PLATFORMS } from '~slices/teasers';
export var CDN_BETCHECK_PATH = 'cdn.tipgame.com';
var fullLanguagesList = [
    {
        id: 'en',
        icon: "https://".concat(CDN_BETCHECK_PATH, "/flags/category/country/gbr.png"),
        name: 'English',
    },
    {
        id: 'de',
        icon: "https://".concat(CDN_BETCHECK_PATH, "/flags/category/country/deu.png"),
        name: 'Deutsch',
    },
    {
        id: 'pl',
        icon: "https://".concat(CDN_BETCHECK_PATH, "/flags/category/country/pol.png"),
        name: 'Polski',
    },
    {
        id: 'uk',
        icon: "https://".concat(CDN_BETCHECK_PATH, "/flags/category/country/ukr.png"),
        name: 'Українська',
    },
    {
        id: 'nl',
        icon: "https://".concat(CDN_BETCHECK_PATH, "/flags/category/country/nld.png"),
        name: 'Dutch',
    },
    {
        id: 'es',
        icon: "https://".concat(CDN_BETCHECK_PATH, "/flags/category/country/esp.png"),
        name: 'Español',
    },
    {
        id: 'fr',
        icon: "https://".concat(CDN_BETCHECK_PATH, "/flags/category/country/fra.png"),
        name: 'French',
    },
    {
        id: 'ru',
        icon: "https://".concat(CDN_BETCHECK_PATH, "/flags/category/country/rus.png"),
        name: 'Русский',
    },
    {
        id: 'tr',
        icon: "https://".concat(CDN_BETCHECK_PATH, "/flags/category/country/tur.png"),
        name: 'Turkish',
    },
];
var shortListLanguages = [
    {
        id: 'en',
        icon: "https://".concat(CDN_BETCHECK_PATH, "/flags/category/country/gbr.png"),
        name: 'English',
    },
    {
        id: 'de',
        icon: "https://".concat(CDN_BETCHECK_PATH, "/flags/category/country/deu.png"),
        name: 'Deutsch',
    },
];
var useStyles = makeStyles(function (theme) { return ({
    wrapper: __assign({}, theme.common.flexColumn),
    arrow: {
        color: '#2AA1F7',
        marginRight: '23px',
    },
    title: {
        marginTop: '14px',
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        color: '#fff',
        width: '100%',
        textAlign: 'start',
        padding: '8px 0 8px 25px',
        fontSize: '14px',
        lineHeight: '20px',
        backgroundColor: 'transparent',
        border: 'none',
        outline: 'none',
    },
    icon: {
        marginRight: '23px',
        width: '16px',
        height: '12px',
    },
    label: {
        fontWeight: 'normal',
    },
    activeLanguage: {
        color: '#2AA1F7',
    },
    activeButton: {
        borderTop: '1px solid #444444',
        borderBottom: '1px solid #444444',
        margin: '9px 0',
    },
    whiteFlag: {
        background: '#FFF',
    },
}); }, { name: 'Languages' });
export var Languages = function (_a) {
    var _b;
    var onCloseLanguages = _a.onCloseLanguages, onCloseDrawer = _a.onCloseDrawer;
    var classes = useStyles();
    var t = useTranslation('header').t;
    var dispatch = useAppDispatch();
    var platform = usePlatofrmType();
    var isAuthenticated = useStructuredSelector({
        isAuthenticated: isAuthenticatedSelector,
    }).isAuthenticated;
    var languages = platform === PLATFORMS.CROSS_PLATFORM_MOBILE
        ? shortListLanguages
        : fullLanguagesList;
    var onClick = function (newLanguage) {
        setLanguageCodeInURL(i18next.language, newLanguage);
        i18next.changeLanguage(newLanguage);
        try {
            var language = newLanguage === 'uk' ? 'ukr' : newLanguage;
            window.SIR('changeLanguage', language);
        }
        catch (e) {
            console.error('SIR Error:', e);
        }
        if (isAuthenticated) {
            dispatch(setLanguage());
        }
        onCloseLanguages();
        onCloseDrawer();
    };
    return (React.createElement("div", { className: classes.wrapper },
        React.createElement("button", { type: "button", onClick: onCloseLanguages, className: classnames((_b = {},
                _b[classes.button] = true,
                _b[classes.title] = true,
                _b)) },
            React.createElement("i", { className: classnames('far fa-arrow-left', classes.arrow) }),
            t('language')),
        languages.map(function (language) {
            var _a, _b;
            return (React.createElement("button", { type: "button", key: language.id, onClick: function () { return onClick(language.id); }, className: classnames((_a = {},
                    _a[classes.button] = true,
                    _a[classes.activeButton] = i18next.language === language.id,
                    _a)) },
                language.id === 'ru' ? (React.createElement("div", { className: classnames(classes.icon, classes.whiteFlag) })) : (React.createElement("img", { src: language.icon, alt: language.name, className: classes.icon })),
                React.createElement("span", { className: classnames((_b = {},
                        _b[classes.label] = true,
                        _b[classes.activeLanguage] = i18next.language === language.id,
                        _b)) }, language.name)));
        })));
};
