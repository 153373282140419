import React from 'react';
import { useTranslation } from "react-i18next";
import makeStyles from "~utils/makeStyles";
var useStyles = makeStyles({
    container: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        rowGap: 24,
        width: '100%',
        padding: '40px 0',
        alignItems: 'center',
        textAlign: 'center',
        color: '#C1C1C1',
        fontSize: 12,
        borderRadius: 8,
        background: '#333333',
    },
    joinButton: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        columnGap: 8,
        padding: '6px 12px',
        background: '#FFFFFF',
        fontSize: 12,
        fontWeight: 500,
        color: '#242425',
        borderRadius: 4,
        cursor: 'pointer',
    },
}, { name: 'NoTickets' });
export var NoTickets = function (_a) {
    var onClick = _a.onClick;
    var classes = useStyles();
    var t = useTranslation(['ticket', 'tip-game']).t;
    return (React.createElement("div", { className: classes.container },
        React.createElement("div", { className: classes.contentWrapper },
            React.createElement("div", null, t('ticket:no_tickets')),
            React.createElement("div", { role: "button", tabIndex: 0, className: classes.joinButton, onClick: onClick },
                React.createElement("span", null, t('tip-game:payments.tip_now'))))));
};
