var getPositionsMapper = function (oc, positionsMap) {
    var mapPosition = function (position) {
        var ids = positionsMap[position] || [];
        var od = oc.find(function (_a) {
            var id = _a.id;
            return ids.includes(Number(id));
        });
        return od || position;
    };
    return mapPosition;
};
export { getPositionsMapper };
