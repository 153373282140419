import { LayoutOptions } from '~constants/view/LayoutOptions';
var getInitialData = function () {
    var _a;
    return _a = {},
        _a[LayoutOptions.OPTION_1] = {},
        _a[LayoutOptions.OPTION_2] = {},
        _a[LayoutOptions.OPTION_3] = {},
        _a[LayoutOptions.OPTION_4] = {},
        _a[LayoutOptions.OPTION_5] = {},
        _a[LayoutOptions.OPTION_6] = {},
        _a[LayoutOptions.OPTION_7] = {},
        _a[LayoutOptions.OPTION_10] = {},
        _a[LayoutOptions.OPTION_11] = {},
        _a;
};
export { getInitialData };
