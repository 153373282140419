import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var LacrosseIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0)" },
            React.createElement("path", { d: "M1.45854 27.9993C1.06889 27.9993 0.703184 27.8472 0.427226 27.572C0.152089 27.2969 0 26.9312 0 26.5416C0 26.1519 0.151268 25.7862 0.427226 25.5094L15.3022 10.6353C15.3585 10.5789 15.25 11.8995 15.7917 12.4173C16.3044 12.9061 17.475 12.5865 17.364 12.6975L2.48904 27.572C2.2139 27.8472 1.8482 27.9993 1.45854 27.9993Z", fill: "#E6E9ED" }),
            React.createElement("path", { d: "M25.9312 2.78882H18.9912V3.95604H25.9312V2.78882Z", fill: "#F6BB42" }),
            React.createElement("path", { d: "M27.3823 5.12231H18.0781V6.2886H27.3823V5.12231Z", fill: "#F6BB42" }),
            React.createElement("path", { d: "M26.5373 7.45605H16.0601V8.6224H26.5373V7.45605Z", fill: "#F6BB42" }),
            React.createElement("path", { d: "M21.7728 9.78955H15.2944V10.9559H21.7728V9.78955Z", fill: "#F6BB42" }),
            React.createElement("path", { d: "M25.2103 2.02344H24.0439V9.052H25.2103V2.02344Z", fill: "#F6BB42" }),
            React.createElement("path", { d: "M22.8783 0.747559H21.7119V9.921H22.8783V0.747559Z", fill: "#F6BB42" }),
            React.createElement("path", { d: "M20.5443 1.42334H19.3779V12.0225H20.5443V1.42334Z", fill: "#F6BB42" }),
            React.createElement("path", { d: "M18.2116 6.39111H17.0444V12.665H18.2116V6.39111Z", fill: "#F6BB42" }),
            React.createElement("path", { d: "M16.8069 13.3042C15.925 13.3042 15.3056 13.1504 15.2799 13.1436C15.0715 13.0906 14.9092 12.9282 14.8566 12.7197C14.8463 12.6821 14.6173 11.7542 14.725 10.5379C14.8686 8.90418 15.5533 7.51225 16.7035 6.51424C17.5673 5.76402 17.8664 4.82584 18.1826 3.83297C18.5141 2.79395 18.8568 1.71904 19.8214 0.754339C20.3222 0.253612 20.9289 0.000732422 21.6244 0.000732422C23.5495 0.000732422 25.5233 2.04203 25.7343 2.26587C25.9556 2.47435 27.9619 4.41398 27.9995 6.32031C28.0132 7.03979 27.7602 7.66439 27.2459 8.17791C26.2812 9.14349 25.2063 9.48612 24.1673 9.81764C23.1744 10.1338 22.2361 10.4328 21.486 11.2967C20.0461 12.9556 18.1467 13.3042 16.8069 13.3042ZM15.9233 12.0756C16.1421 12.1063 16.448 12.1371 16.8069 12.1371C17.904 12.1371 19.4532 11.8585 20.605 10.5319C21.5697 9.42109 22.7095 9.05714 23.8125 8.70593C24.7611 8.40345 25.6565 8.11808 26.4212 7.35332C26.7058 7.06883 26.8408 6.73813 26.8323 6.34334C26.8083 5.11208 25.448 3.58944 24.9251 3.10747C24.914 3.09637 24.9029 3.08615 24.8926 3.07504C24.8884 3.0699 24.4252 2.57344 23.769 2.08808C22.9556 1.48569 22.2138 1.16696 21.6243 1.16696C21.2432 1.16696 20.9236 1.30199 20.6468 1.57882C19.882 2.34353 19.5967 3.239 19.2942 4.18746C18.943 5.28971 18.579 6.43045 17.4682 7.3951C15.6892 8.93923 15.8012 11.2052 15.9233 12.0756Z", fill: "#FFCE54" }),
            React.createElement("path", { d: "M14.4095 15.6522L12.3477 13.5895L13.1722 12.7659L15.2344 14.8268L14.4095 15.6522Z", fill: "#ED5564" }),
            React.createElement("path", { d: "M12.7595 17.3014L10.6968 15.2405L11.5222 14.415L13.5849 16.4777L12.7595 17.3014Z", fill: "#ED5564" }),
            React.createElement("path", { d: "M1.45854 24.4788L0.427226 25.5092C0.151268 25.786 0 26.1518 0 26.5414C0 26.931 0.152089 27.2967 0.427226 27.5719C0.703239 27.847 1.06894 27.9991 1.45854 27.9991C1.8482 27.9991 2.2139 27.847 2.48904 27.5719L3.52123 26.5414L1.45854 24.4788Z", fill: "#ED5564" }),
            React.createElement("path", { d: "M23.6455 19.521C23.0952 18.969 22.3629 18.6665 21.5836 18.6665C20.8044 18.6665 20.0721 18.969 19.521 19.521C18.9707 20.0712 18.6665 20.8044 18.6665 21.5819C18.6665 22.3613 18.9707 23.0943 19.521 23.6446C20.0721 24.1967 20.8044 24.4991 21.5836 24.4991C22.3629 24.4991 23.0952 24.1967 23.6455 23.6446C24.1965 23.0944 24.4999 22.3613 24.4999 21.5819C24.4999 20.8044 24.1965 20.0712 23.6455 19.521Z", fill: "#ED5564" }),
            React.createElement("path", { d: "M24.0705 20.0596C23.9517 19.8665 23.8099 19.6853 23.645 19.5213C23.516 19.3914 23.3767 19.2769 23.2289 19.1743C23.0136 19.6015 22.5265 20.3296 21.4277 21.4284C20.3305 22.5256 19.6025 23.0126 19.1753 23.2296C19.2761 23.3766 19.3915 23.5167 19.5205 23.6449C19.6845 23.809 19.8657 23.9509 20.0588 24.0705C20.621 23.7287 21.3413 23.1647 22.253 22.2539C23.1648 21.3412 23.7278 20.6201 24.0705 20.0596Z", fill: "#DA4453" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0" },
                React.createElement("rect", { width: "28", height: "28", fill: "white" })))));
};
