import makeStyles from '@material-ui/core/styles/makeStyles';
var useStyles = makeStyles({
    container: {
        flex: 'auto',
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
    },
    content: {
        display: 'inline-flex',
    },
}, { name: 'HorizontalScroll' });
export { useStyles };
