var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from '@reduxjs/toolkit';
import { name } from '~slices/activePlaySession/constants';
import { createAsyncReducers } from '~utils/createAsyncReducers';
import { getActivePlaySession } from '~slices/activePlaySession/thunks';
var initialState = {
    data: {
        id: NaN,
        bppmo: 'D',
        cashOut: false,
        providerName: '',
    },
    loading: 'idle',
    error: null,
};
export var activePlaySession = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        setActivePlaySession: function (state, _a) {
            var payload = _a.payload;
            return __assign(__assign({}, state), { data: __assign(__assign({}, state.data), { data: __assign(__assign({}, state.data), payload) }) });
        },
    },
    extraReducers: function (builder) {
        var _a = createAsyncReducers(), pending = _a.pending, fulfilled = _a.fulfilled, rejected = _a.rejected;
        builder
            .addCase(getActivePlaySession.pending, pending)
            .addCase(getActivePlaySession.fulfilled, fulfilled)
            .addCase(getActivePlaySession.rejected, rejected);
    },
});
export var setActivePlaySession = activePlaySession.actions.setActivePlaySession;
