var chars = {
    apostrophe: "'",
    umlauts: 'ÄÖÜäöü',
    hyphenMinus: '-‐−–',
    space: ' ',
};
var checkIsLatin = function (char) {
    return /[a-zA-Z0-9]/.test(char);
};
var checkIsDecimal = function (char) {
    return /\d/.test(char);
};
var checkIsInCharset = function (char, charset) {
    return charset.includes(char);
};
var filters = {
    checkIsLatin: checkIsLatin,
    checkIsInCharset: checkIsInCharset,
    checkIsDecimal: checkIsDecimal,
};
var filterChars = function (value, filter) {
    return value.split('').filter(filter).join('');
};
export { filterChars, filters, chars };
