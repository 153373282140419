import makeStyles from '@material-ui/core/styles/makeStyles';
import { ZIndex } from '~constants/view/ZIndex';
import { ratio } from '~theme';
import { MYBETS_FOR_UNLOGGED_BREAKPOINT } from './constants';
export var useHeaderStyles = makeStyles(function (theme) {
    var _a, _b, _c, _d, _e;
    return ({
        header: (_a = {
                backgroundColor: theme.variables.headerBackgroundColor.color,
                color: theme.variables.headerColor.color,
                position: 'fixed',
                top: 0,
                width: "".concat(100 * ratio(), "vw"),
                height: '48px',
                zIndex: ZIndex.HEADER,
                padding: '2px 0'
            },
            _a[theme.breakpoints.up('lg')] = {
                height: '72px',
            },
            _a['& i'] = {
                fontSize: '20px',
            },
            _a['& ul'] = {
                listStyleType: 'none',
                display: 'flex',
                flex: 1,
                padding: 0,
                margin: 0,
                height: '100%',
            },
            _a['& a'] = {
                '&:visited': {
                    textDecoration: 'inherit',
                    color: 'inherit',
                    cursor: 'pointer',
                },
                '&:link': {
                    textDecoration: 'inherit',
                    color: 'inherit',
                    cursor: 'pointer',
                },
            },
            _a),
        overlay: {
            position: 'fixed',
            height: '75px',
            width: '100vw',
            backgroundColor: 'rgb(0 0 0 / 19%)',
            top: 0,
            zIndex: ZIndex.HEADER_OVERLAY,
            left: 0,
            bottom: 0,
            right: 0,
        },
        icon: {
            fontSize: '20px',
        },
        headerEmptyItem: {
            width: '75px',
            height: '38px',
        },
        headerItem: (_b = {
                maxWidth: '64px',
                margin: '0 5px',
                width: '100%',
                outline: 'none',
                borderBottom: '2px solid transparent'
            },
            _b[theme.breakpoints.up('lg')] = {
                maxWidth: '160px',
                width: '100%',
                margin: '0px',
            },
            _b[theme.breakpoints.down('md')] = {
                '& li': {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: 35,
                },
            },
            _b['& svg'] = {
                height: '19px',
                width: '19px',
            },
            _b['&.cashOut'] = {
                minWidth: '75px',
            },
            _b),
        link: {
            fontStyle: 'normal',
            color: '#ffffff',
        },
        linkValue: {
            fontWeight: 'bold',
            fontSize: '9px',
        },
        groupName: {
            position: 'absolute',
            bottom: '15px',
            maxWidth: '70px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            fontWeight: 400,
        },
        mbMin6: {
            marginBottom: '-6px',
        },
        linkLabel: (_c = {
                fontWeight: 'normal',
                fontSize: '9px',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
                overflowWrap: 'normal',
                whiteSpace: 'pre-wrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden'
            },
            _c[theme.breakpoints.up('lg')] = {
                fontSize: '16px',
            },
            _c),
        linkLabelWithMargin: {
            marginTop: '8px',
        },
        cashOut: {
            position: 'relative',
            justifyContent: 'space-between',
            minWidth: 75,
            height: 35,
            '& svg': {
                height: '11px',
                width: '11px',
            },
            '& img': {
                height: 20,
            },
        },
        underline: {
            borderBottom: '2px solid #FFFFFF',
        },
        lockLogo: {
            position: 'absolute',
            fontSize: '10px !important',
            bottom: '11px',
            left: 'calc(50% + 5px)',
            transform: 'translateX(-50%)',
            textShadow: '-3px 1px 2px #000',
        },
        cashOnLogo: {
            display: 'flex',
            border: '2px solid #ffffff',
            borderRadius: '2px',
            backgroundColor: '#ffffff',
        },
        topIcon: (_d = {
                fontSize: '8px',
                position: 'absolute',
                top: '10px',
                left: '55%',
                transform: 'translate(0, -50%)',
                boxShadow: '0 0 0 2pt #151515'
            },
            _d[theme.breakpoints.up('lg')] = {
                position: 'relative',
                width: '25px',
                height: '13px',
                left: '4px',
                top: '-6px',
            },
            _d),
        loginItem: {
            height: '100%',
            justifyContent: 'center',
        },
        loginMobileButton: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            width: '100%',
            maxWidth: '64px',
            margin: '4px',
            '& span': {
                fontWeight: '400',
                fontSize: '9px',
                lineHeight: '14px',
                color: '#FFFFFF',
                marginBottom: '-4px',
            },
        },
        headerBackground: {
            backgroundColor: '#2E2E36',
        },
        logo: {
            width: '45px',
            height: '39px',
            padding: '5px 5px 5px 15px',
        },
        TGLogo: {
            height: 25,
            width: 25,
        },
        desktopLogo: {
            width: '139px',
            height: '40px',
            padding: '12px 81px 12px 32px',
            cursor: 'pointer',
            '&.turkey': {
                width: '111px',
            },
        },
        tipGameDesktopLogo: {
            width: '164px',
            height: '32px',
        },
        active: {
            borderBottom: '2px solid #FFFFFF',
        },
        rigthContainer: {
            position: 'absolute',
            right: '30px',
            width: '40%',
            maxWidth: '750px',
            display: 'grid',
            gridTemplateColumns: '3fr 3fr 1fr 3fr',
            gridGap: '20px',
            alignItems: 'center',
            '&$unlogged': (_e = {
                    gridTemplateColumns: '3fr 3fr 1fr',
                    width: '28%',
                    maxWidth: '500px'
                },
                _e[theme.breakpoints.up(MYBETS_FOR_UNLOGGED_BREAKPOINT)] = {
                    gridTemplateColumns: '3fr 3fr 1fr 3fr',
                    width: '40%',
                    maxWidth: '750px',
                },
                _e),
        },
        unlogged: {},
        cashOutLabel: {
            marginRight: 3,
            fontWeight: 'normal',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
        registerButton: {
            background: '#212121',
            border: '1px solid #FFFFFF',
            borderRadius: '4px',
            color: '#FFFFFF',
            '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.08)',
            },
        },
        languageButton: {
            background: '#212121',
            border: '1px solid #525252;',
            borderRadius: '4px',
            color: '#FFFFFF',
        },
        loginButton: {
            background: '#FFFFFF',
        },
        desktopActive: {
            position: 'absolute',
            width: '100%',
            height: '2px',
            background: '#fff',
            bottom: '-15px',
        },
        desktopCashOutButton: {
            backgroundColor: '#151515',
            border: '1px solid #FFFFFF',
            borderRadius: '4px',
            color: '#FFFFFF',
            width: '100%',
            '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.08)',
            },
            '& .MuiButton-label': {
                justifyContent: 'space-between',
            },
        },
        cashOutButtonBalance: {
            display: 'flex',
            alignItems: 'center',
            '& span': {
                marginRight: '3px',
            },
        },
        paddings: {
            display: 'flex',
            height: '69px',
            paddingLeft: '12px',
            alignItems: 'center',
        },
    });
}, { name: 'Header' });
