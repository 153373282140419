import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { useLocation } from 'react-router-dom';
import { Currency } from '~atoms';
import { getPathWithLanguageCode } from '~utils/languageInURL';
import { PaymentsURL } from '~pages/payments/payments.routes';
import { useAppDispatch } from '~store';
import { useStyles } from '~molecules/WithdrawTab/components/WithdrawPaymentCode/WithdrawPaymentCode.styles';
export var WithdrawPaymentCode = function () {
    var t = useTranslation('tip-game').t;
    var classes = useStyles();
    var dispatch = useAppDispatch();
    var search = useLocation().search;
    var params = new URLSearchParams(search);
    var amount = params.get('sum');
    var mockData = {
        currency: 'EUR',
        QRCodeURL: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/QR_code_for_mobile_English_Wikipedia.svg/1200px-QR_code_for_mobile_English_Wikipedia.svg.png',
    };
    useEffect(function () {
        console.log('Send the request to get a QR code for withdraw money here!');
    }, []);
    useEffect(function () {
        var checkMoneyStatus = function () {
            // TODO, make a request to check if money in a shop
            var isQRCodeScanned = Math.random() * 10 > 5;
            console.log('Make a request to check if the money in a shop, running every 10 seconds');
            console.log('isQRCodeScanned:', isQRCodeScanned);
            if (isQRCodeScanned) {
                dispatch(push(getPathWithLanguageCode("/".concat(PaymentsURL.PAYMENTS, "/").concat(PaymentsURL.WITHDRAW_CONFIRMATION, "?sum=").concat(amount))));
            }
        };
        checkMoneyStatus();
        // Schedule the function to run every 10 seconds
        var intervalId = setInterval(checkMoneyStatus, 10000);
        return function () {
            clearInterval(intervalId);
        };
    }, []);
    return (React.createElement("div", { className: classes.wrapper },
        React.createElement("div", { className: classes.title },
            t('payments.payment_code_generated'),
            "!"),
        React.createElement("div", { className: classes.amount },
            amount,
            " ",
            React.createElement(Currency, { currency: mockData.currency })),
        React.createElement("div", { className: classes.code },
            React.createElement("img", { src: mockData.QRCodeURL, alt: 'QR-code' })),
        React.createElement("div", { className: classes.subtitle }, t('payments.show_code_to_agent_and_receive_money'))));
};
