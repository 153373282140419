export var getRoundsData = function (tournament, roundIndex) {
    var getCupRounds = function (stage) {
        var _a;
        var result = [];
        (_a = stage === null || stage === void 0 ? void 0 : stage.crnds) === null || _a === void 0 ? void 0 : _a.forEach(function (item) {
            if (item.cr) {
                result.push(item.cr);
            }
        });
        return result;
    };
    var currentRound = (tournament.stg || [])[roundIndex];
    var rounds = (currentRound === null || currentRound === void 0 ? void 0 : currentRound.rt) === "R" && (currentRound === null || currentRound === void 0 ? void 0 : currentRound.r)
        ? [currentRound === null || currentRound === void 0 ? void 0 : currentRound.r]
        : undefined;
    var cupRounds = (currentRound === null || currentRound === void 0 ? void 0 : currentRound.rt) === "C" && (currentRound === null || currentRound === void 0 ? void 0 : currentRound.cr)
        ? [currentRound === null || currentRound === void 0 ? void 0 : currentRound.cr]
        : (currentRound === null || currentRound === void 0 ? void 0 : currentRound.rt) === "S"
            ? getCupRounds(currentRound)
            : undefined;
    return { rounds: rounds, cupRounds: cupRounds };
};
