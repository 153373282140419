import React from "react";
import classNames from "classnames";
import makeStyles from "~utils/makeStyles";
export var useStyles = makeStyles({
    container: {
        position: 'relative',
        display: 'flex',
        alignItems: 'flex-end',
        width: 'calc(38vw - 19px)',
        height: 54,
        padding: '0 10px 10px 10px',
        background: '#2D2D2E',
        border: '1px solid #565656',
        borderRadius: 8,
        boxSizing: 'border-box',
    },
    smallButton: {
        width: 'calc(24vw - 18px)',
        paddingRight: 16,
        textAlign: 'center',
    },
    odd: {
        position: 'absolute',
        top: 0,
        right: 12,
        padding: '0 10px 2px 10px',
        fontSize: 11,
        fontWeight: 500,
        background: '#565656',
        borderRadius: '0px 0px 3px 3px',
    },
    centred: {
        right: '50%',
        transform: 'translateX(50%)',
    },
    name: {
        width: '100%',
        fontSize: 10,
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 1,
        overflowWrap: 'normal',
        whiteSpace: 'pre-wrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
}, { name: 'EventButton' });
export var EventButton = function (_a) {
    var value = _a.value, name = _a.name, isSmall = _a.isSmall, columnsCount = _a.columnsCount;
    var classes = useStyles();
    var width = columnsCount === 2 ? 'calc(50vw - 22px)' : undefined;
    return (React.createElement("div", { className: classNames(classes.container, isSmall && classes.smallButton), style: { width: width } },
        React.createElement("div", { className: classNames(classes.odd, isSmall && classes.centred) }, value),
        React.createElement("div", { className: classes.name }, name)));
};
