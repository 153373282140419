var StoreContainer = /** @class */ (function () {
    function StoreContainer() {
        this._store = {};
    }
    Object.defineProperty(StoreContainer.prototype, "store", {
        get: function () {
            return this._store;
        },
        enumerable: false,
        configurable: true
    });
    StoreContainer.prototype.setStore = function (store) {
        this._store = store;
    };
    return StoreContainer;
}());
var storeContainer = new StoreContainer();
export { storeContainer };
