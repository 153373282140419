import React from 'react';
import classNames from 'classnames';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Button from '@material-ui/core/Button';
import { useStyles } from './EditModals.styles';
export var EditModalContainer = function (_a) {
    var _b;
    var handleCancel = _a.handleCancel, children = _a.children, title = _a.title, specialPaddings = _a.specialPaddings;
    var classes = useStyles();
    return (React.createElement(Dialog, { open: true, onClose: handleCancel, classes: {
            paperWidthSm: classes.modalsWidth,
        }, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description" },
        React.createElement(Button, { onClick: handleCancel, className: classes.closeIcon },
            React.createElement("i", { className: classNames('far fa-times') })),
        React.createElement(DialogContent, { classes: {
                root: classNames(classes.dialog, (_b = {},
                    _b[classes.specialPaddings] = specialPaddings,
                    _b[classes.specialRoot] = specialPaddings,
                    _b[classes.root] = !specialPaddings,
                    _b)),
            } },
            title && React.createElement("div", { className: classes.modalTitle }, title),
            children)));
};
