var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Field } from 'redux-form';
import { TextInput } from '~atoms';
var renderTextField = function (_a) {
    var input = _a.input, meta = _a.meta, title = _a.title, placeholder = _a.placeholder, disablePaste = _a.disablePaste, rest = __rest(_a, ["input", "meta", "title", "placeholder", "disablePaste"]);
    return (React.createElement(TextInput, __assign({}, input, rest, { title: title, error: meta.touched && meta.invalid, helperText: meta.touched ? meta.error : '', placeholder: placeholder || "".concat(title && title.toLocaleLowerCase()), disablePaste: disablePaste })));
};
var TextField = function (_a) {
    var name = _a.name, title = _a.title, type = _a.type, required = _a.required, validators = _a.validators, className = _a.className, titleClassName = _a.titleClassName, InputProps = _a.InputProps, disablePaste = _a.disablePaste, onBlur = _a.onBlur, autoCompleteType = _a.autoCompleteType, visibilityColor = _a.visibilityColor, placeholder = _a.placeholder, normalize = _a.normalize;
    return (React.createElement(Field, { className: className, InputProps: InputProps, titleClassName: titleClassName, name: name, title: title, type: type, onBlur: onBlur, component: renderTextField, validate: validators, required: required, disablePaste: disablePaste, autoCompleteType: autoCompleteType, visibilityColor: visibilityColor, placeholder: placeholder, normalize: normalize }));
};
export default TextField;
