export var name = 'teasers';
export var SLIDESHOW_TYPE = 'SLIDESHOW';
export var HORIZON_GALLERY_TYPE = 'HORIZONTAL_GALLERY';
export var GALLERY_TYPE = 'SQUARE_GALLERY';
export var CONVERSION_TYPE = 'CONVERSION';
export var PLATFORMS = {
    WEB: 'WEB',
    WEB_MOBILE: 'WEB_MOBILE',
    CROSS_PLATFORM_MOBILE: 'CROSS_PLATFORM_MOBILE',
    MOBILE: 'MOBILE',
    TERMINAL: 'TERMINAL',
    CASHDESK: 'CASHDESK',
};
