var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { unwrapResult } from '@reduxjs/toolkit';
import { SIDEBETS_UPDATES_NAME, getEvent, updateEvent as updateSideBetEvent, } from '~slices/sidebets';
import { TICKET_UPDATES_NAME, setResponseUpdates, update, } from '~slices/tickets';
import { Socket } from '~utils/socket';
import { EVENTS_SOCKETS_NAMES, FAVOURITES_SOCKET_NAME, HIGHLIGHTS_SOCKET_NAME, LIVE_SOCKET_NAME, LIVE_SOCKET_SOON_LIVE_SECTION_NAME, MATCHDAY_SOCKET_NAME, SOON_LIVE_SOCKET_NAME, addEvent, getEvents, resetEvents, updateEvent, } from '~slices/events';
import { clearAccountData } from '~slices/account';
import { updateBetslip, updateEvents, updateMaxOdd, updateSsua, } from '~slices/betslip';
import { OUTRIGHTS_SOCKET_NAME, updateOutrights } from '~slices/outrights';
import store from '~store/configureStore';
import { changeSocketState } from '~slices/sockets';
import { RequestStatus } from '~constants/api/RequestStatus';
import { accessTokenSelector, checkTokens, logout } from '~slices/auth';
import { storeContainer } from '~utils/storeContainer';
var checkIsSocketName = function (value) {
    return EVENTS_SOCKETS_NAMES.includes(value);
};
var setSocketRequestStatus = function (socket, requestStatus) {
    if (checkIsSocketName(socket)) {
        store.dispatch(changeSocketState({ socket: socket, state: { requestStatus: requestStatus } }));
    }
};
function sendParams() {
    if (!this.socketParams) {
        return;
    }
    var _a = this.socketParams, url = _a.url, et = _a.eventsType, restSocketParams = __rest(_a, ["url", "eventsType"]);
    this.sendMessage(restSocketParams);
}
var sockets = new Proxy({}, {
    defineProperty: function (target, property, descriptor) {
        if (checkIsSocketName(property)) {
            store.dispatch(changeSocketState({ socket: property, state: { enabled: true } }));
        }
        Reflect.defineProperty(target, property, descriptor);
        return true;
    },
    deleteProperty: function (target, property) {
        if (checkIsSocketName(property)) {
            store.dispatch(changeSocketState({ socket: property, state: { enabled: false } }));
        }
        Reflect.deleteProperty(target, property);
        return true;
    },
});
var getMessageParams = function (eventsType) {
    var _a = sockets[eventsType].socketParams, url = _a.url, et = _a.eventsType, restSocketParams = __rest(_a, ["url", "eventsType"]);
    return restSocketParams;
};
export var socketMiddleware = function () {
    return function (store) {
        return function (next) {
            return function (action) {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
                if (action.type.includes('connectSocket')) {
                    var _w = action.payload, url = _w.url, eventsType_1 = _w.eventsType, withOutMessage_1 = _w.withOutMessage, withOutInterval_1 = _w.withOutInterval, rest_1 = __rest(_w, ["url", "eventsType", "withOutMessage", "withOutInterval"]);
                    if (sockets[eventsType_1]) {
                        return next(action);
                        // sockets[eventsType].socketParams = null;
                        //
                        // clearTimeout(sockets[eventsType]?.socketTimeout);
                        // clearInterval(sockets[eventsType]?.socketInterval);
                        // delete sockets[eventsType];
                    }
                    var socket = new Socket(url);
                    setSocketRequestStatus(eventsType_1, RequestStatus.PENDING);
                    socket.add('SOCKET_CONNECTED', function () {
                        var _a = sockets[eventsType_1].socketParams, urlSocketParams = _a.url, et = _a.eventsType, restSocketParams = __rest(_a, ["url", "eventsType"]);
                        if (!withOutMessage_1) {
                            sockets[eventsType_1].sendMessage(__assign(__assign({}, restSocketParams), rest_1));
                            sockets[eventsType_1].socketTimeout = window.setTimeout(function () {
                                sockets[eventsType_1].sendMessage(getMessageParams(eventsType_1));
                                sockets[eventsType_1].socketInterval = window.setInterval(function () {
                                    var _a, _b;
                                    if (eventsType_1 !== SIDEBETS_UPDATES_NAME) {
                                        sockets[eventsType_1].sendMessage(getMessageParams(eventsType_1));
                                    }
                                    else {
                                        var sidebets = store.getState().sidebets;
                                        var currentTime = new Date();
                                        if (((_a = sidebets === null || sidebets === void 0 ? void 0 : sidebets.data) === null || _a === void 0 ? void 0 : _a.updateTime) && ((currentTime.getTime() - ((_b = sidebets === null || sidebets === void 0 ? void 0 : sidebets.data) === null || _b === void 0 ? void 0 : _b.updateTime.getTime())) / 1000) > 30) {
                                            sockets[eventsType_1].sendMessage(getMessageParams(eventsType_1));
                                        }
                                    }
                                }, 30000);
                            }, (60 - new Date().getSeconds()) * 1000);
                        }
                        if (withOutInterval_1) {
                            sockets[eventsType_1].sendMessage(restSocketParams);
                        }
                        sockets[eventsType_1].sendParams = sendParams;
                    });
                    socket.add('SOCKET_RECONNECT', function (data) {
                        var _a, _b;
                        clearTimeout((_a = sockets[eventsType_1]) === null || _a === void 0 ? void 0 : _a.socketTimeout);
                        clearInterval((_b = sockets[eventsType_1]) === null || _b === void 0 ? void 0 : _b.socketInterval);
                    });
                    socket.add('SOCKET_DISCONNECTED', function (data) {
                        var _a, _b;
                        if (sockets[eventsType_1])
                            sockets[eventsType_1].socketParams = null;
                        clearTimeout((_a = sockets[eventsType_1]) === null || _a === void 0 ? void 0 : _a.socketTimeout);
                        clearInterval((_b = sockets[eventsType_1]) === null || _b === void 0 ? void 0 : _b.socketInterval);
                        delete sockets[eventsType_1];
                    });
                    socket.add('SOCKET_MESSAGE_SEND', function () {
                        setSocketRequestStatus(eventsType_1, RequestStatus.PENDING);
                    });
                    socket.add('SOCKET_MESSAGE', 
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    function (data) {
                        var _a;
                        switch (data.ty) {
                            case 'r': {
                                setSocketRequestStatus(eventsType_1, RequestStatus.FULFILLED);
                                if (data.ms) {
                                    if (action.payload.eventsType === TICKET_UPDATES_NAME) {
                                        store.dispatch(setResponseUpdates(data.ms));
                                    }
                                    if (action.payload.eventsType === OUTRIGHTS_SOCKET_NAME) {
                                        store.dispatch(updateOutrights(data.ms));
                                    }
                                    if (action.payload.eventsType === SIDEBETS_UPDATES_NAME) {
                                        store.dispatch(getEvent(data.ms));
                                    }
                                    if (action.payload.eventsType === LIVE_SOCKET_NAME ||
                                        action.payload.eventsType === MATCHDAY_SOCKET_NAME ||
                                        action.payload.eventsType === HIGHLIGHTS_SOCKET_NAME ||
                                        action.payload.eventsType === FAVOURITES_SOCKET_NAME ||
                                        action.payload.eventsType === SOON_LIVE_SOCKET_NAME ||
                                        action.payload.eventsType ===
                                            LIVE_SOCKET_SOON_LIVE_SECTION_NAME) {
                                        store.dispatch(getEvents(data.ms, action.payload.eventsType));
                                    }
                                    if (action.payload.eventsType === 'betSlip') {
                                        store.dispatch(updateBetslip(data.ms));
                                    }
                                }
                                if (data.bs) {
                                    store.dispatch(updateMaxOdd(data.bs.to.mo));
                                }
                                break;
                            }
                            case 'u': {
                                if (data.ms) {
                                    if (action.payload.eventsType === TICKET_UPDATES_NAME) {
                                        store.dispatch(update(data.ms));
                                    }
                                    if (action.payload.eventsType === SIDEBETS_UPDATES_NAME) {
                                        store.dispatch(updateSideBetEvent(data.ms));
                                    }
                                    if (action.payload.eventsType === OUTRIGHTS_SOCKET_NAME) {
                                        store.dispatch(updateOutrights(data.ms));
                                    }
                                    if (action.payload.eventsType === 'betSlip') {
                                        store.dispatch(updateEvents(data.ms));
                                    }
                                    if (action.payload.eventsType === HIGHLIGHTS_SOCKET_NAME ||
                                        action.payload.eventsType === MATCHDAY_SOCKET_NAME ||
                                        action.payload.eventsType === LIVE_SOCKET_NAME ||
                                        action.payload.eventsType === FAVOURITES_SOCKET_NAME ||
                                        action.payload.eventsType === SOON_LIVE_SOCKET_NAME ||
                                        action.payload.eventsType ===
                                            LIVE_SOCKET_SOON_LIVE_SECTION_NAME) {
                                        store.dispatch(updateEvent(data.ms, (_a = store.getState().shortcut.data) === null || _a === void 0 ? void 0 : _a.ps, eventsType_1));
                                    }
                                }
                                if (data.bs && data.bs.ODD[0]) {
                                    store.dispatch(updateMaxOdd(data.bs.ODD[0].to.mo));
                                }
                                break;
                            }
                            case 'n': {
                                if (data.ms) {
                                    if (action.payload.eventsType === LIVE_SOCKET_NAME) {
                                        store.dispatch(addEvent(data.ms, eventsType_1));
                                    }
                                }
                                break;
                            }
                            default: {
                                break;
                            }
                        }
                    });
                    sockets[eventsType_1] = {
                        socket: socket,
                        socketParams: __assign({ url: url, eventsType: eventsType_1 }, rest_1),
                        sendParams: function () { return undefined; },
                        sendMessage: function (data) {
                            var _this = this;
                            var authToken = accessTokenSelector(store.getState());
                            storeContainer.store
                                .dispatch(checkTokens())
                                .then(unwrapResult)
                                .then(function (refreshedTokenData) {
                                var token = (refreshedTokenData && refreshedTokenData.accessToken) ||
                                    authToken;
                                _this.socket.sendMessage(__assign(__assign({}, data), (token ? { authToken: token } : {})));
                            });
                        },
                    };
                    sockets[eventsType_1].socket.connect();
                }
                if (action.type.includes('SOCKET_CLOSE')) {
                    if (sockets[action.eventsType])
                        sockets[action.eventsType].socketParams = null;
                    (_a = sockets[action.eventsType]) === null || _a === void 0 ? void 0 : _a.socket.disconnect();
                    clearTimeout((_b = sockets[action.eventsType]) === null || _b === void 0 ? void 0 : _b.socketTimeout);
                    clearInterval((_c = sockets[action.eventsType]) === null || _c === void 0 ? void 0 : _c.socketInterval);
                    delete sockets[action.eventsType];
                }
                if (action.type === logout.toString()) {
                    if (sockets[FAVOURITES_SOCKET_NAME]) {
                        sockets[FAVOURITES_SOCKET_NAME].socketParams = null;
                    }
                    (_d = sockets[FAVOURITES_SOCKET_NAME]) === null || _d === void 0 ? void 0 : _d.socket.disconnect();
                    clearTimeout((_e = sockets[FAVOURITES_SOCKET_NAME]) === null || _e === void 0 ? void 0 : _e.socketTimeout);
                    clearInterval((_f = sockets[FAVOURITES_SOCKET_NAME]) === null || _f === void 0 ? void 0 : _f.socketInterval);
                    delete sockets[FAVOURITES_SOCKET_NAME];
                    store.dispatch(resetEvents(FAVOURITES_SOCKET_NAME));
                }
                if (action.type.includes('sendMessage')) {
                    if (sockets[action.payload.eventsType])
                        if (((_g = sockets[action.payload.eventsType]) === null || _g === void 0 ? void 0 : _g.socket.getReadyState()) === 0) {
                            sockets[action.payload.eventsType].socket.add('SOCKET_CONNECTED', function () {
                                var _a = action.payload, eventsType = _a.eventsType, restParams = __rest(_a, ["eventsType"]);
                                sockets[action.payload.eventsType].socketParams = __assign(__assign({}, restParams), { eventsType: eventsType });
                                sockets[action.payload.eventsType].sendMessage(restParams);
                            });
                        }
                        else {
                            var _x = action.payload, eventsType = _x.eventsType, restParams = __rest(_x, ["eventsType"]);
                            sockets[action.payload.eventsType].socketParams = __assign(__assign({}, restParams), { eventsType: eventsType });
                            (_h = sockets[action.payload.eventsType]) === null || _h === void 0 ? void 0 : _h.sendMessage(restParams);
                        }
                }
                if ([
                    'shortcut/selectOutrightOdd/fulfilled',
                    'shortcut/deleteOutrightOdd/fulfilled',
                    'shortcut/selectOdd/fulfilled',
                    'shortcut/deleteOdd/fulfilled',
                ].indexOf(action.type) !== -1) {
                    var _y = action.meta.arg, eventsType = _y.eventsType, lc = _y.lc, ssua = action.payload.ssua;
                    var _z = sockets[eventsType].socketParams, url = _z.url, userId = _z.userId, rest = __rest(_z, ["url", "userId"]);
                    if (ssua !== undefined) {
                        store.dispatch(updateSsua(action.payload));
                    }
                    if (action.payload.mo) {
                        store.dispatch(updateMaxOdd(action.payload.mo));
                    }
                    if (eventsType !== 'soonLive') {
                        sockets[eventsType].sendMessage(__assign(__assign({}, rest), { calcBetSlip: true }));
                    }
                    if (sockets.betSlip) {
                        var _0 = sockets.betSlip.socketParams, urlBetSlip = _0.url, restBetSlip = __rest(_0, ["url"]);
                        var betslip = store.getState().betslip;
                        var betSlipId = (_j = betslip === null || betslip === void 0 ? void 0 : betslip.data) === null || _j === void 0 ? void 0 : _j.bs;
                        sockets.betSlip.sendMessage(__assign(__assign({}, restBetSlip), { langIso: lc, betSlipId: betSlipId, calcBetSlip: true }));
                    }
                }
                if ([
                    'betslip/updateWager/fulfilled',
                    'betslip/deleteOutcomeFromEvent/fulfilled',
                    'betslip/addCombination/fulfilled',
                    'betslip/deleteCombination/fulfilled',
                    'betslip/deleteFullBetslip/fulfilled',
                    'betslip/addBanker/fulfilled',
                    'betslip/deleteBanker/fulfilled',
                    'betslip/deleteDeactivatedOdds/fulfilled',
                ].indexOf(action.type) !== -1) {
                    var eventsType = action.meta.arg.eventsType, bs = action.payload.bs;
                    if (sockets[eventsType]) {
                        var _1 = sockets[eventsType].socketParams, url = _1.url, betSlipId = _1.betSlipId, rest = __rest(_1, ["url", "betSlipId"]);
                        sockets[eventsType].sendMessage(__assign(__assign({}, rest), { betSlipId: betSlipId || bs, calcBetSlip: false }));
                    }
                }
                if ([
                    'events/addToFavourite/fulfilled',
                    'events/removeFromFavourite/fulfilled',
                ].indexOf(action.type) !== -1) {
                    (_k = sockets[LIVE_SOCKET_NAME]) === null || _k === void 0 ? void 0 : _k.sendParams();
                    (_l = sockets[SOON_LIVE_SOCKET_NAME]) === null || _l === void 0 ? void 0 : _l.sendParams();
                    (_m = sockets[FAVOURITES_SOCKET_NAME]) === null || _m === void 0 ? void 0 : _m.sendParams();
                    (_o = sockets[HIGHLIGHTS_SOCKET_NAME]) === null || _o === void 0 ? void 0 : _o.sendParams();
                    (_p = sockets[LIVE_SOCKET_SOON_LIVE_SECTION_NAME]) === null || _p === void 0 ? void 0 : _p.sendParams();
                }
                if (['auth/logout', 'auth/login/fulfilled'].indexOf(action.type) !== -1) {
                    setTimeout(function () {
                        var _a, _b, _c, _d, _e;
                        (_a = sockets[LIVE_SOCKET_NAME]) === null || _a === void 0 ? void 0 : _a.sendParams();
                        (_b = sockets[SOON_LIVE_SOCKET_NAME]) === null || _b === void 0 ? void 0 : _b.sendParams();
                        (_c = sockets[FAVOURITES_SOCKET_NAME]) === null || _c === void 0 ? void 0 : _c.sendParams();
                        (_d = sockets[HIGHLIGHTS_SOCKET_NAME]) === null || _d === void 0 ? void 0 : _d.sendParams();
                        (_e = sockets[LIVE_SOCKET_SOON_LIVE_SECTION_NAME]) === null || _e === void 0 ? void 0 : _e.sendParams();
                    });
                }
                if (['options/changeSoonLiveSportId'].indexOf(action.type) !== -1 && ((_q = action === null || action === void 0 ? void 0 : action.payload) === null || _q === void 0 ? void 0 : _q.id)) {
                    var payload = action.payload;
                    sockets[SOON_LIVE_SOCKET_NAME].socketParams.sportId = payload.id;
                }
                if (['events/resetEvents'].indexOf(action.type) !== -1) {
                    var payload = action.payload;
                    setSocketRequestStatus(payload, RequestStatus.IDLE);
                }
                if (['options/syncSoonLiveSportId'].indexOf(action.type) !== -1) {
                    var sportId = (_t = (_s = (_r = store.getState()) === null || _r === void 0 ? void 0 : _r.options) === null || _s === void 0 ? void 0 : _s.data) === null || _t === void 0 ? void 0 : _t.localSportId;
                    if (((_u = sockets[SOON_LIVE_SOCKET_NAME]) === null || _u === void 0 ? void 0 : _u.socketParams) && sportId) {
                        sockets[SOON_LIVE_SOCKET_NAME].socketParams.sportId = sportId;
                    }
                }
                if (['options/changeLocalSportId'].indexOf(action.type) !== -1 && ((_v = action === null || action === void 0 ? void 0 : action.payload) === null || _v === void 0 ? void 0 : _v.id)) {
                    var payload_1 = action.payload;
                    var message_1 = {
                        desktop: payload_1.desktop,
                        sportId: payload_1.id,
                        langIso: payload_1.langIso,
                    };
                    Object.values(sockets).forEach(function (socket) {
                        var _a;
                        if (socket.socketParams.eventsType === LIVE_SOCKET_NAME && ((_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.id)) {
                            sockets[LIVE_SOCKET_NAME].socketParams.sportId = payload_1.id;
                            clearTimeout(sockets[LIVE_SOCKET_NAME].socketTimeout);
                            clearInterval(sockets[LIVE_SOCKET_NAME].socketInterval);
                            sockets[LIVE_SOCKET_NAME].socketTimeout = window.setTimeout(function () {
                                sockets[LIVE_SOCKET_NAME].sendMessage(message_1);
                                sockets[LIVE_SOCKET_NAME].socketInterval = window.setInterval(function () {
                                    sockets[LIVE_SOCKET_NAME].sendMessage(message_1);
                                }, 60000);
                            }, (60 - new Date().getSeconds()) * 1000);
                        }
                    });
                    [
                        LIVE_SOCKET_NAME,
                        LIVE_SOCKET_SOON_LIVE_SECTION_NAME,
                        SOON_LIVE_SOCKET_NAME,
                        FAVOURITES_SOCKET_NAME,
                        HIGHLIGHTS_SOCKET_NAME,
                    ].forEach(function (socketName) {
                        var socketInfo = sockets[socketName];
                        if (!(socketInfo === null || socketInfo === void 0 ? void 0 : socketInfo.socketParams)) {
                            return;
                        }
                        socketInfo.socketParams = __assign(__assign({}, socketInfo.socketParams), message_1);
                    });
                }
                return next(action);
            };
        };
    };
};
export var authMiddleware = function () {
    return function (store) {
        return function (next) {
            return function (action) {
                if (action.type === 'auth/logout') {
                    store.dispatch(clearAccountData());
                }
                return next(action);
            };
        };
    };
};
