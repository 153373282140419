import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
var useStyles = makeStyles({
    root: {
        margin: '2px',
        lineHeight: 1,
    },
    WON: {
        color: '#00C150',
    },
    LOST: {
        color: '#F44336',
    },
    NONE: {
        color: '#8E8E8E',
    },
    smallCircle: {
        fontSize: '6px',
    },
    bigCircle: {
        fontSize: '8px',
    },
}, { name: 'WonLostIndicator' });
export var WonLostIndicator = function (_a) {
    var status = _a.status, _b = _a.smallCircle, smallCircle = _b === void 0 ? false : _b;
    var classes = useStyles();
    return (React.createElement("i", { className: "fas fa-circle ".concat(classes.root, " ").concat(smallCircle ? classes.smallCircle : classes.bigCircle, " ").concat(classes[status || 'NONE']) }));
};
