var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
import { createAsyncReducers } from '~utils/createAsyncReducers';
import { getGames } from './thunks';
import { GAMES_MODE, GAME_CLASS, GAME_PERIOD, name } from './constants';
var initialState = {
    data: [
        {
            name: '',
            url: '',
            gameMode: GAMES_MODE.GLOBAL,
            active: false,
            startCredits: NaN,
            backgroundColor: '',
            gamePeriod: GAME_PERIOD.DAILY,
            gameClass: GAME_CLASS.PRO,
            gameUid: '',
        },
    ],
    meta: {
        activeGame: null,
        selectedGame: null,
        selectedGameType: GAMES_MODE.GLOBAL,
        isEasyGame: false,
        isEasyTip: false,
    },
    loading: 'idle',
    error: null,
};
export var gameTypes = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        setGames: function (state, _a) {
            var payload = _a.payload;
            return __assign(__assign({}, state), { data: __assign(__assign({}, state.data), { data: __assign(__assign({}, state.data), payload) }) });
        },
        setActiveGame: function (state, _a) {
            var payload = _a.payload;
            return __assign(__assign({}, state), { meta: __assign(__assign({}, state.meta), { activeGame: state.meta.selectedGame || payload }) });
        },
        setSelectedGame: function (state, _a) {
            var payload = _a.payload;
            var selectedGame = payload.selectedGame, selectedGroup = payload.selectedGroup;
            return __assign(__assign({}, state), { meta: __assign(__assign({}, state.meta), { activeGame: selectedGame, selectedGame: selectedGame, selectedGroup: selectedGroup, selectedGameType: selectedGroup
                        ? GAMES_MODE.GROUP
                        : GAMES_MODE.GLOBAL }) });
        },
        setTournamentParams: function (state, _a) {
            var payload = _a.payload;
            var pathname = payload.pathname;
            return __assign(__assign({}, state), { meta: __assign(__assign({}, state.meta), { tournament: { pathname: pathname } }) });
        },
        resetGames: function (state) {
            return __assign(__assign({}, state), { meta: __assign(__assign({}, state.meta), { activeGame: null, selectedGame: null, selectedGroup: undefined, selectedGameType: GAMES_MODE.GLOBAL, isEasyGame: false, isEasyTip: false }) });
        },
        setIsEasyGame: function (state, _a) {
            var payload = _a.payload;
            return __assign(__assign({}, state), { meta: __assign(__assign({}, state.meta), { isEasyGame: payload }) });
        },
        setIsEasyTip: function (state, _a) {
            var payload = _a.payload;
            return __assign(__assign({}, state), { meta: __assign(__assign({}, state.meta), { isEasyTip: payload }) });
        },
    },
    extraReducers: function (builder) {
        var _a = createAsyncReducers(), pending = _a.pending, fulfilled = _a.fulfilled, rejected = _a.rejected;
        builder
            .addCase(getGames.pending, pending)
            .addCase(getGames.fulfilled, fulfilled)
            .addCase(getGames.rejected, rejected);
    },
});
export var setGames = (_a = gameTypes.actions, _a.setGames), setActiveGame = _a.setActiveGame, setSelectedGame = _a.setSelectedGame, setTournamentParams = _a.setTournamentParams, resetGames = _a.resetGames, setIsEasyGame = _a.setIsEasyGame, setIsEasyTip = _a.setIsEasyTip;
