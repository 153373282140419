import makeStyles from '@material-ui/core/styles/makeStyles';
export var useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },
    balanceCardWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        columnGap: '10px',
    },
}, { name: 'DepositTab' });
