import makeStyles from '~utils/makeStyles';
export var useStyles = makeStyles(function (theme) {
    var _a, _b, _c;
    return ({
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            background: '#151515',
        },
        button: (_a = {
                marginTop: '18px',
                fontSize: '16px'
            },
            _a[theme.breakpoints.up('lg')] = {
                marginTop: '30px',
            },
            _a),
        passTitle: {
            padding: '16px 24px',
            fontSize: '20px',
        },
        actionsButtons: {
            padding: '0 24px 16px',
        },
        congratulationsWrapper: (_b = {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                marginTop: '10px',
                textAlign: 'center',
                fontSize: '18px',
                fontWeight: 700,
                color: '#FFFFFF'
            },
            _b[theme.breakpoints.up('lg')] = {
                marginTop: '40px',
            },
            _b['& img:first-child'] = (_c = {
                    marginBottom: '5px'
                },
                _c[theme.breakpoints.up('lg')] = {
                    marginBottom: '25px',
                },
                _c),
            _b),
        won: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: '10px',
            '& > div': {
                marginLeft: '5px',
                fontSize: '18px',
                fontWeight: 700,
                color: '#FFFFFF',
            },
        },
    });
}, { name: 'WithdrawOnline' });
