import makeStyles from '@material-ui/core/styles/makeStyles';
var useModalStyles = makeStyles({
    dialog: {
        width: 'auto',
        maxWidth: '408px',
        borderRadius: 16,
    },
    dialogRoot: {
        borderRadius: 32,
    },
    dialogContainer: {
        background: '#242425',
        boxShadow: '1px 1px 20px 0px #161616',
    },
    title: {
        padding: '12px 24px 16px',
        color: '#212121',
        fontSize: '18px',
        lineHeight: '27px',
        fontWeight: 500,
        letterSpacing: '0.15px',
    },
    content: {
        color: '#212121',
        padding: '0 24px 16px',
        fontWeight: 300,
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: '0.15px',
    },
    dialogAction: {
        padding: '0 16px 16px',
        justifyContent: 'center',
    },
    columnDialogAction: {
        flexDirection: 'column',
        '& > :not(:first-child)': {
            marginLeft: 0,
            marginTop: '16px',
        },
    },
    formModalDialogAction: {
        justifyContent: 'space-between',
    },
    formButton: {
        maxWidth: '120px',
        width: '100%',
    },
    button: {
        padding: 0,
        height: '21px',
        minWidth: 'auto',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '21px',
        textAlign: 'right',
        textTransform: 'uppercase',
    },
    secondary: {
        color: '#2AA1F7',
    },
    hugeButton: {
        height: '44px',
        fontSize: '16px',
        lineHeight: '24px',
    },
    shinyBack: { background: '#fff' },
    header: {
        color: '#212121',
        fontSize: '28px',
        lineHeight: '36px',
        marginBottom: '38px',
        maxWidth: '100%',
        padding: '0 20px',
    },
    logo: {
        paddingTop: '32px !important',
    },
    closeIcon: {
        position: 'absolute',
        top: '4px',
        fontSize: '20px',
        color: 'rgba(142, 142, 142, 0.2)',
        right: '6px',
        '& i': {
            fontWeight: 300,
        },
    },
    closeIconGray: {
        background: '#242425',
    },
    lgModalWidth: {
        maxWidth: '450px',
    },
    subheader: {
        color: '#212121',
        marginBottom: '40px',
        marginTop: '-24px !important',
        fontSize: '16px !important',
        maxWidth: '100% !important',
        lineHeight: '24px',
    },
    containerForClosableModal: {
        padding: '44px 16px 24px',
    },
    // JoinGameDescriptionModal
    joinGameTitle: {
        maxWidth: 300,
        marginTop: 35,
        color: '#FFF',
        fontSize: 20,
        fontWeight: 600,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    joinGameContent: {
        marginBottom: 20,
        overflow: 'hidden',
        overflowY: 'auto',
    },
    joinGameItem: {
        marginTop: 25,
        fontSize: 14,
        color: '#FFF',
        fontWeight: 400,
    },
    // GameClassDescriptionModal
    gameClassTitle: {
        marginTop: 12,
        maxWidth: 300,
        color: '#FFF',
        fontSize: 20,
        fontWeight: 600,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    gameClassItem: {
        marginTop: 24,
        fontSize: 16,
        color: '#FFF',
        fontWeight: 400,
    },
    // PurchaseTicketsResultModal
    purchaseTitle: {
        maxWidth: 282,
        minWidth: 260,
        marginTop: 12,
        marginBottom: 24,
        color: '#FFFFFF',
        fontSize: 16,
        fontWeight: 600,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    purchaseContent: {
        marginBottom: 20,
        minHeight: 310,
        maxHeight: 'calc(100vh - 288px)',
        overflow: 'hidden',
        overflowY: 'auto',
    },
    purchaseItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        columnGap: 18,
        minHeight: 40,
    },
    purchaseDescription: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        flexDirection: 'column',
        textAlign: 'end',
        maxWidth: '50%',
    },
    statusContainer: {
        display: 'flex',
        fontSize: 12,
        color: '#FFF',
    },
    status: {
        display: 'flex',
        alignItems: 'center',
        '& i': {
            marginRight: 8,
        }
    },
    description: {
        marginTop: 4,
        fontSize: 10,
        color: '#7F7F7F',
    },
    gameName: {
        fontSize: 14,
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        overflow: 'hidden',
        whiteSpace: 'normal',
        textOverflow: 'ellipsis',
    },
    root: {
        '& .MuiPaper-root': {
            borderRadius: '16px !important',
            backgroundColor: '#242425',
            boxShadow: '1px 1px 20px 0px #161616',
            border: '1px solid #6E6E6E',
            color: '#FFFFFF',
        },
        '& .MuiPaper-rounded': {
            borderRadius: 16,
        },
    },
    divider: {
        width: '100%',
        height: 1,
        margin: '10px 0',
        borderRadius: 4,
        background: '#3E3E3E',
    },
    //DeletePurchasedTicketModal
    deleteTicketTitle: {
        marginTop: 12,
        marginBottom: 24,
        color: '#FFFFFF',
        fontSize: 16,
        fontWeight: 600,
    },
    deleteTicketActionBlock: {
        display: 'flex',
        justifyContent: 'space-between',
        columnGap: 20,
        marginTop: 24,
    },
}, { name: 'Modal' });
export { useModalStyles };
