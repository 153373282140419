import makeStyles from '@material-ui/core/styles/makeStyles';
export var useStyles = makeStyles({
    cashOnBtnDesktop: {
        width: '201px',
        flex: '1 0 auto',
        '& .cash-on-button__label': {
            fontSize: '14px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            '& > div': {
                margin: '0 4px',
            },
        },
        '&:focus': {
            outline: 'none',
        },
    },
    btnWrapper: {
        borderTop: '1px solid rgba(242, 242, 242, 0.2)',
        padding: '16px',
    },
}, { name: 'Tip' });
