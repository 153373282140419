var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { unwrapResult } from '@reduxjs/toolkit';
import axios from 'axios';
import { LOGOUT_PRESS } from '~constants/cpa-message-codes';
import { storeContainer } from '~utils/storeContainer';
import { accessTokenSelector, checkTokens, logout } from '~slices/auth';
var BASE_URL = process.env.REACT_APP_BASE_URL || '';
var axiosInstance = axios.create({
    baseURL: BASE_URL,
    timeout: 25000,
    withCredentials: false,
});
var handleReject = function (error) {
    var _a;
    var isWebView = window.isWebView;
    if (((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status) === 401) {
        if (isWebView) {
            // @ts-expect-error
            window.ReactNativeWebView.postMessage(JSON.stringify({
                code: LOGOUT_PRESS,
            }));
        }
        else {
            storeContainer.store.dispatch(logout());
        }
    }
    throw error;
};
var authorizationHeader = function (token) {
    return token ? { authorization: "Bearer ".concat(token) } : {};
};
export var request = function (options) {
    var accessToken = accessTokenSelector(storeContainer.store.getState());
    var baseURL = options.baseURL, restOptions = __rest(options, ["baseURL"]);
    return storeContainer.store
        .dispatch(checkTokens())
        .then(unwrapResult)
        .then(function (refreshedTokenData) {
        // @ts-expect-error
        return axiosInstance(__assign(__assign({}, restOptions), { baseURL: baseURL || axiosInstance.defaults.baseURL, headers: __assign(__assign({}, authorizationHeader((refreshedTokenData && refreshedTokenData.accessToken) ||
                accessToken)), restOptions.headers) })).catch(handleReject);
    });
};
export var requestWithoutAuth = function (options) {
    return axiosInstance(options).catch(handleReject);
};
