import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var FieldHockeyIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { viewBox: "0 0 28 28", className: className },
        React.createElement("svg", { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M8.99485 2.09009C8.49232 1.26451 7.41567 1.00262 6.59009 1.50515C5.76451 2.00768 5.50262 3.08433 6.00515 3.90991L8.99485 2.09009ZM6.00515 3.90991L11.9284 13.641L14.9181 11.8212L8.99485 2.09009L6.00515 3.90991Z", fill: "#AF903F" }),
            React.createElement("path", { d: "M26.75 21C26.75 20.0335 25.9665 19.25 25 19.25C24.0335 19.25 23.25 20.0335 23.25 21H26.75ZM26.75 22.4937V21H23.25V22.4937H26.75ZM11.885 13.5698L18.6923 24.7532L21.682 22.9334L14.8747 11.7499L11.885 13.5698ZM24.5604 26.2668C25.9144 25.4931 26.75 24.0532 26.75 22.4937H23.25C23.25 22.7972 23.0874 23.0773 22.8239 23.2279L24.5604 26.2668ZM22.8239 23.2279C22.4263 23.4551 21.9201 23.3245 21.682 22.9334L18.6923 24.7532C19.9159 26.7633 22.5172 27.4343 24.5604 26.2668L22.8239 23.2279Z", fill: "#BF5050" }),
            React.createElement("path", { d: "M15.1356 18.9099L15.7443 19.9099L18.734 18.0901L18.1253 17.0901L15.1356 18.9099Z", fill: "#D9D9D9" }),
            React.createElement("path", { d: "M16.3529 20.9099L16.9616 21.9099L19.9513 20.0901L19.3426 19.0901L16.3529 20.9099Z", fill: "#D9D9D9" }),
            React.createElement("path", { d: "M17.5703 22.9099L18.179 23.9099L21.1687 22.0901L20.56 21.0901L17.5703 22.9099Z", fill: "#D9D9D9" }),
            React.createElement("path", { d: "M19.0052 2.09009C19.5077 1.26451 20.5843 1.00262 21.4099 1.50515C22.2355 2.00768 22.4974 3.08433 21.9948 3.90991L19.0052 2.09009ZM21.9948 3.90991L16.0716 13.641L13.0819 11.8212L19.0052 2.09009L21.9948 3.90991Z", fill: "#FFC42E" }),
            React.createElement("path", { d: "M1.25003 21C1.25003 20.0335 2.03353 19.25 3.00003 19.25C3.96653 19.25 4.75003 20.0335 4.75003 21H1.25003ZM1.25003 22.4937V21H4.75003V22.4937H1.25003ZM16.115 13.5698L9.30766 24.7532L6.31796 22.9334L13.1253 11.7499L16.115 13.5698ZM3.43961 26.2668C2.08563 25.4931 1.25003 24.0532 1.25003 22.4937H4.75003C4.75003 22.7972 4.91263 23.0773 5.1761 23.2279L3.43961 26.2668ZM5.1761 23.2279C5.57368 23.4551 6.07987 23.3245 6.31796 22.9334L9.30766 24.7532C8.0841 26.7633 5.48279 27.4343 3.43961 26.2668L5.1761 23.2279Z", fill: "#FE3535" }),
            React.createElement("path", { d: "M12.8644 18.9099L12.2557 19.9099L9.26602 18.0901L9.87472 17.0901L12.8644 18.9099Z", fill: "white" }),
            React.createElement("path", { d: "M11.6471 20.9099L11.0384 21.9099L8.04868 20.0901L8.65737 19.0901L11.6471 20.9099Z", fill: "white" }),
            React.createElement("path", { d: "M10.4297 22.9099L9.82097 23.9099L6.83127 22.0901L7.43997 21.0901L10.4297 22.9099Z", fill: "white" }),
            React.createElement("path", { d: "M9.42195 1.83012C8.77584 0.768656 7.39158 0.431944 6.33012 1.07805C5.26866 1.72416 4.93195 3.10842 5.57805 4.16988L11.366 13.6786L5.89083 22.6734C5.79352 22.8333 5.58664 22.8866 5.42414 22.7938C5.31646 22.7322 5.25 22.6177 5.25 22.4937V21C5.25 19.7574 4.24264 18.75 3 18.75C1.75736 18.75 0.75 19.7574 0.75 21V22.4937C0.75 24.2326 1.68175 25.8382 3.19152 26.7009C5.46978 28.0027 8.37038 27.2546 9.73473 25.0132L14 18.0059L18.2653 25.0132C19.6296 27.2546 22.5302 28.0027 24.8085 26.7009C26.3183 25.8381 27.25 24.2326 27.25 22.4937V21C27.25 19.7574 26.2426 18.75 25 18.75C23.7574 18.75 22.75 19.7574 22.75 21V22.4937C22.75 22.6177 22.6835 22.7322 22.5759 22.7938C22.4134 22.8866 22.2065 22.8333 22.1092 22.6734L16.634 13.6786L22.4219 4.16988C23.0681 3.10842 22.7313 1.72416 21.6699 1.07805C20.6084 0.431944 19.2242 0.768656 18.5781 1.83012L14 9.35121L9.42195 1.83012Z", stroke: "black" }))));
};
