import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import clearIndicator from '~icons/clearIndicator.svg';
import { Button } from '~atoms/button';
import { useIsDesktop } from '~hooks';
import { useStyles } from './ClearBetslipModal.styles';
var ClearBetslipModal = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, clearBetslip = _a.clearBetslip;
    var classes = useStyles();
    var isDesktop = useIsDesktop();
    var t = useTranslation('ticket').t;
    return (React.createElement(Dialog, { open: isOpen, disablePortal: isDesktop, style: isDesktop ? { position: 'absolute' } : {}, BackdropProps: isDesktop
            ? {
                classes: { root: classes.backdropClass },
            }
            : undefined, keepMounted: true, onClose: onClose, "aria-labelledby": "alert-dialog-slide-title", "aria-describedby": "alert-dialog-slide-description", classes: {
            root: classes.rootModal,
            paper: classes.radius,
            paperScrollPaper: classes.shinyBack,
        } },
        React.createElement(DialogTitle, { id: "alert-dialog-slide-title", disableTypography: true, className: classNames('flex-column flex-center', classes.title) },
            React.createElement("img", { src: clearIndicator, alt: "status-logo" })),
        React.createElement(DialogContent, { className: classNames('flex-column flex-center', classes.content) },
            React.createElement(DialogContentText, { classes: { root: classes.text }, id: "alert-dialog-slide-description", className: classNames('flex-column flex-center') },
                React.createElement("span", { className: classes.clearTitle }, t('question_clear_betslip'))),
            React.createElement(Button, { color: "primary", variant: "contained", onClick: clearBetslip, fullWidth: true }, t('yes')),
            React.createElement(Button, { className: classes.cancelButton, fullWidth: true, variant: "contained", onClick: onClose }, t('no'))),
        React.createElement(Button, { className: classes.closeIcon, onClick: onClose },
            React.createElement("i", { className: "far fa-times" }))));
};
export default ClearBetslipModal;
