var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { push } from "connected-react-router";
import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSwipeable } from "react-swipeable";
import i18next from "i18next";
import { SmallLoader, StyledTab, StyledTabs } from '~atoms';
import { NestedRankingList } from "~atoms/RankingListItem/components";
import { UserCard } from "~atoms/UserCard";
import { useIsDesktop, useStructuredSelector } from '~hooks';
import { MODALS, openModal } from "~modals";
import { EmptyBetslipBanner } from '~molecules/EmptyBetslipBanner';
import { isAuthenticatedSelector } from '~slices/auth';
import { sportNameSelector } from "~slices/options";
import { squareGallerySelector } from '~slices/teasers';
import { getRankingListAsync, resetRankingLists, useRankingListsDataSelector, useRankingLoadingSelector } from "~slices/ranking";
import { useAppDispatch } from "~store";
import { accountInfoSelector } from "~slices/account";
import { useGameTypesDataSelector, useSelectedGroupSelector, useTournamentSelector } from "~slices/gameTypes";
import { getPathWithLanguageCode } from "~utils/languageInURL";
import { PageMetadata } from "~view/hoc/PageMetadata";
import { GAMES, GAMES_MODE, globalStyles } from "~slices/gameTypes/constants";
import { AccumulationType } from "~slices/ranking/constants";
import { DateSwitcher } from "~atoms/DateSwitcher";
import { useStyles } from './RankingListComponents.styles';
export var RankingListTabsGroup = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
    var classes = useStyles();
    var dispatch = useAppDispatch();
    var t = useTranslation('tip-game').t;
    var ts = useTranslation('seo').t;
    var _v = useStructuredSelector({
        isAuthenticated: isAuthenticatedSelector,
        banners: function (state) { return squareGallerySelector(state); },
        gamesData: useGameTypesDataSelector,
        selectedGroup: useSelectedGroupSelector,
        rankingLists: useRankingListsDataSelector,
        rankingLoadingStatus: useRankingLoadingSelector,
        accountInfo: accountInfoSelector,
        tournament: useTournamentSelector,
        sportName: sportNameSelector,
    }), isAuthenticated = _v.isAuthenticated, banners = _v.banners, gamesData = _v.gamesData, selectedGroup = _v.selectedGroup, rankingLists = _v.rankingLists, rankingLoadingStatus = _v.rankingLoadingStatus, accountInfo = _v.accountInfo, tournament = _v.tournament, sportName = _v.sportName;
    var groupGame = useMemo(function () {
        return gamesData.find(function (game) { return (game === null || game === void 0 ? void 0 : game.gameMode) === GAMES_MODE.GROUP && game.groupName === selectedGroup; });
    }, [gamesData, selectedGroup]);
    var isDesktop = useIsDesktop();
    var _w = useState('group'), activeTab = _w[0], setActiveTab = _w[1];
    var currentUserName = accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.username;
    var isEmptyBetslipBanner = !isAuthenticated && !!banners.length && !isDesktop;
    var isRankingListLoading = rankingLoadingStatus === 'pending';
    var toTipPath = (tournament === null || tournament === void 0 ? void 0 : tournament.pathname) || getPathWithLanguageCode("/home/all/live/".concat(sportName === null || sportName === void 0 ? void 0 : sportName.name));
    var getRankingList = function (baseURL, groupName, accumulation, date) {
        dispatch(getRankingListAsync({ baseURL: baseURL, groupName: groupName, accumulation: accumulation, date: date }));
    };
    var onTabClick = function (game) {
        if (activeTab === game) {
            return;
        }
        setActiveTab(game);
        if (game === 'group-total' && groupGame) {
            getRankingList(groupGame.url, groupGame.groupName, AccumulationType.ALL_TIME);
        }
        else if (groupGame) {
            getRankingList(groupGame.url, groupGame.groupName);
        }
    };
    useEffect(function () {
        var _a;
        if (groupGame && !((_a = rankingLists.group.levelRankings) === null || _a === void 0 ? void 0 : _a.length)) {
            var accumulation = activeTab === 'group-total' ? AccumulationType.ALL_TIME : undefined;
            getRankingList(groupGame.url, groupGame.groupName, accumulation);
        }
    }, [groupGame]);
    useEffect(function () {
        return function () {
            dispatch(resetRankingLists());
        };
    }, []);
    var showJoinGameModal = function () {
        dispatch(openModal({
            modalName: MODALS.JOIN_GAME_DESCRIPTION_MODAL,
            modalProps: {
                handleClose: function () { return dispatch(push(toTipPath)); },
            },
        }));
    };
    var onAccumulationClick = function (type) {
        if (!groupGame) {
            return;
        }
        var date = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.group[type];
        var accumulation = activeTab === 'group-total' ? AccumulationType.ALL_TIME : undefined;
        getRankingList(groupGame.url, groupGame.groupName, accumulation, date);
    };
    var onSwipe = function (direction) {
        var tabsSet = ['group', 'group-total'];
        var tabIndex = (tabsSet).indexOf(activeTab);
        if (direction === 'left' && tabsSet.length > tabIndex + 1) {
            onTabClick(tabsSet[tabIndex + 1]);
        }
        if (direction === 'right' && tabIndex > 0) {
            onTabClick(tabsSet[tabIndex - 1]);
        }
    };
    var swipeHandlers = useSwipeable({
        onSwipedLeft: function () { return onSwipe('left'); },
        onSwipedRight: function () { return onSwipe('right'); }
    });
    return (React.createElement(React.Fragment, null,
        !isDesktop && React.createElement(PageMetadata, { lang: i18next.language, titleSuffix: t('ranking_list'), description: ts('page-description.ranking_list') }),
        React.createElement("div", __assign({ className: classNames('flex-row', !isDesktop && classes.componentWrapper) }, swipeHandlers),
            React.createElement("div", { className: classNames('flex-column', classes.fullWidth) },
                React.createElement("div", { className: classNames('flex-row flex-center', classes.container, !isDesktop && classes.positionFixed) }, activeTab && (React.createElement(StyledTabs, { variant: "fullWidth", value: activeTab },
                    React.createElement(StyledTab, { label: t('group-game.group'), value: 'group', textColorClassNames: classes.greenText, onClick: function () { return onTabClick('group'); } }),
                    React.createElement(StyledTab, { label: t('ranking-list.total-accumulation'), value: 'group-total', textColorClassNames: classes.greenText, onClick: function () { return onTabClick('group-total'); } })))),
                isRankingListLoading ? (React.createElement(SmallLoader, { className: classes.loaderHeight })) : (React.createElement("div", { className: !isDesktop ? classes.wrapper : undefined },
                    isEmptyBetslipBanner && React.createElement(EmptyBetslipBanner, null),
                    (activeTab === 'group' || activeTab === 'group-total') && (React.createElement(React.Fragment, null, isAuthenticated && (React.createElement(React.Fragment, null,
                        React.createElement(DateSwitcher, { prev: (_a = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.group) === null || _a === void 0 ? void 0 : _a.prev, current: (_b = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.group) === null || _b === void 0 ? void 0 : _b.current, next: (_c = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.group) === null || _c === void 0 ? void 0 : _c.next, nextName: (_d = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.group) === null || _d === void 0 ? void 0 : _d.nextName, name: groupGame === null || groupGame === void 0 ? void 0 : groupGame.groupName, currentName: (_e = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.group) === null || _e === void 0 ? void 0 : _e.currentName, onClick: function (type) { return onAccumulationClick(type); } }),
                        React.createElement("div", { className: classNames(classes.userCard, isDesktop && classes.mt24) },
                            React.createElement(UserCard, { userName: currentUserName, balance: (_g = (_f = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.group) === null || _f === void 0 ? void 0 : _f.myselfRanking) === null || _g === void 0 ? void 0 : _g.balance, level: (_j = (_h = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.group) === null || _h === void 0 ? void 0 : _h.myselfRanking) === null || _j === void 0 ? void 0 : _j.level, rank: (_l = (_k = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.group) === null || _k === void 0 ? void 0 : _k.myselfRanking) === null || _l === void 0 ? void 0 : _l.rank, stars: (_o = (_m = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.group) === null || _m === void 0 ? void 0 : _m.myselfRanking) === null || _o === void 0 ? void 0 : _o.expSubLevel, avatar: ((_q = (_p = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.group) === null || _p === void 0 ? void 0 : _p.myselfRanking) === null || _q === void 0 ? void 0 : _q.expLevelName) || 'Joker', avatarUrl: ((_s = (_r = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.group) === null || _r === void 0 ? void 0 : _r.myselfRanking) === null || _s === void 0 ? void 0 : _s.avatarUrl) || 'avatars/joker.svg', gameInfo: { name: (groupGame === null || groupGame === void 0 ? void 0 : groupGame.groupName) || '', color: globalStyles[GAMES.GROUP].mainColor }, isGroupRanking: true, showJoinGameModal: showJoinGameModal })),
                        React.createElement("div", { className: classes.dividerContainer },
                            React.createElement("div", { className: classes.divider })),
                        React.createElement("div", { className: classes.listWrapper }, (_u = (_t = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.group) === null || _t === void 0 ? void 0 : _t.levelRankings) === null || _u === void 0 ? void 0 : _u.map(function (item, index) {
                            var _a;
                            return (React.createElement(NestedRankingList, { key: index, rankingList: item.ranks, currentUserRank: (_a = rankingLists === null || rankingLists === void 0 ? void 0 : rankingLists.group.myselfRanking) === null || _a === void 0 ? void 0 : _a.rank, place: item.place, isGroupRanking: true }));
                        }))))))))))));
};
