var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from '@reduxjs/toolkit';
import { createAsyncReducers } from '~utils/createAsyncReducers';
import { name } from './constants';
import { deleteOdd, deleteOutrightOdd, getShortcutBetslip, selectOdd, selectOutrightOdd, } from './thunks';
var initialState = {
    data: {},
    loading: 'idle',
};
export var shortcut = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        resetShortcutBetlsip: function () {
            return initialState;
        },
    },
    extraReducers: function (builder) {
        var _a = createAsyncReducers(), pending = _a.pending, fulfilled = _a.fulfilled, rejected = _a.rejected;
        builder
            .addCase(getShortcutBetslip.pending, pending)
            .addCase(getShortcutBetslip.fulfilled, fulfilled)
            .addCase(getShortcutBetslip.rejected, function (state) { return (__assign(__assign({}, state), { loading: 'rejected', data: {} })); })
            .addCase(selectOdd.pending, pending)
            .addCase(selectOdd.fulfilled, fulfilled)
            .addCase(selectOdd.rejected, rejected)
            .addCase(selectOutrightOdd.pending, pending)
            .addCase(selectOutrightOdd.fulfilled, fulfilled)
            .addCase(selectOutrightOdd.rejected, rejected)
            .addCase(deleteOutrightOdd.pending, pending)
            .addCase(deleteOutrightOdd.fulfilled, fulfilled)
            .addCase(deleteOutrightOdd.rejected, rejected)
            .addCase(deleteOdd.pending, pending)
            .addCase(deleteOdd.fulfilled, fulfilled)
            .addCase(deleteOdd.rejected, rejected);
    },
});
export var resetShortcutBetlsip = shortcut.actions.resetShortcutBetlsip;
