import makeStyles from '@material-ui/core/styles/makeStyles';
var useStyles = makeStyles({
    container: {
        display: 'flex',
        width: '100%',
        backgroundColor: '#e6e6e6',
        padding: '10px 8px 6px 8px',
        boxSizing: 'border-box',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        boxShadow: '0px 0px 15px rgba(0, 193, 80, 0.05)',
        rowGap: '8px',
        borderRadius: '4px',
    },
}, { name: 'ItemsGroup' });
export { useStyles };
