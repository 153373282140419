import * as React from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button/Button';
import { resetProviderId } from '~slices/top-ranking';
import { useAppDispatch } from '~store';
var useStyles = makeStyles({
    header: {
        height: 52,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    logoImg: {
        width: 60,
        height: 23,
    },
    buttonBack: {
        position: 'absolute',
        height: 32,
        right: '16px',
        top: '10px',
    },
    iconSizeSmall: {
        '& > *:first-child': {
            fontSize: '0.8333333em',
            fontWeight: 900,
        },
    },
}, { name: 'BpHeader' });
var BASE_URL = process.env.REACT_APP_BASE_URL || '';
var BpHeader = function (_a) {
    var provider = _a.provider;
    var classes = useStyles();
    var t = useTranslation('bpList').t;
    var dispatch = useAppDispatch();
    var closeFrame = function () {
        dispatch(resetProviderId());
    };
    return (React.createElement("div", { className: classes.header, style: { background: provider === null || provider === void 0 ? void 0 : provider.backgroundColor } },
        React.createElement("img", { alt: "logo", className: classes.logoImg, src: "".concat(BASE_URL, "bp/v1/bp-integrator/providers/logo/").concat(provider === null || provider === void 0 ? void 0 : provider.id, "/MAIN") }),
        React.createElement(Button, { className: classes.buttonBack, classes: { iconSizeSmall: classes.iconSizeSmall }, variant: "contained", endIcon: React.createElement("i", { className: "far fa-times" }), size: "small", onClick: closeFrame }, t('close'))));
};
export { BpHeader };
