import React from 'react';
import classNames from 'classnames';
import { getScoresOfSets } from '~organisms/event/Competitor';
import { useCompetitorStyles } from '../Sidebets.styles';
export var CompetitorScores = function (_a) {
    var _b, _c, _d;
    var competitor = _a.competitor, sportId = _a.sportId, sr = _a.sr, isFirst = _a.isFirst, showGoalAnimation = _a.showGoalAnimation, showTeamIndicator = _a.showTeamIndicator, teamIndicator = _a.teamIndicator, increaseGoal = _a.increaseGoal, currentScore = _a.currentScore;
    var classes = useCompetitorStyles();
    if (!competitor) {
        return null;
    }
    var scoresOfSets = getScoresOfSets(competitor);
    var scoreToShow = currentScore || competitor.sc || 0;
    var isTennis = sportId === 25 || sportId === 2;
    var firstHalfTeamScore = Number.isInteger(competitor.s1) &&
        Number.isInteger(competitor.s2) &&
        competitor.s1;
    return (React.createElement("div", { className: classNames('flex-row', 'flex-center') },
        sportId === 1 ? (React.createElement("div", { className: classNames(classes.competitorScore, classes.competitorScoreResult, classes.shadowColor) }, firstHalfTeamScore)) : (React.createElement(React.Fragment, null, scoresOfSets.length > 0
            ? scoresOfSets.map(function (score, index) { return (React.createElement("div", { 
                // eslint-disable-next-line react/no-array-index-key
                key: index, className: classNames(classes.competitorScore) }, score)); })
            : null)),
        isTennis && (React.createElement("div", { className: classNames(classes.competitorScore, classes.competitorTennisScore) }, competitor.gs > 40 ? 'AD' : competitor.gs)),
        isTennis && (React.createElement("div", { className: classNames(classes.noSr, 'small', (_b = {},
                _b[classes.sr] = (isFirst && "".concat(sr) === '1') || (!isFirst && "".concat(sr) === '2'),
                _b)) })),
        React.createElement("div", { className: classNames(classes.competitorScore, classes.competitorScoreResult, classes.lastScore, (_c = {},
                _c[classes.competitorGoal] = showGoalAnimation,
                _c.isTennis = isTennis,
                _c)) }, scoreToShow + Number(increaseGoal || 0)),
        showTeamIndicator && (React.createElement("div", { className: classNames(classes.noSr, (_d = {},
                _d[classes.sr] = (isFirst && "".concat(teamIndicator) === '1') ||
                    (!isFirst && "".concat(teamIndicator) === '2'),
                _d)) }))));
};
