import makeStyles from '@material-ui/core/styles/makeStyles';
var useStyles = makeStyles({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '32px',
        height: '12px',
        boxSizing: 'border-box',
        border: '1px solid #2AA1F7',
        borderRadius: '5px',
        cursor: 'pointer',
    },
    icon: {
        fontSize: '30px',
        color: '#2AA1F7',
    },
}, { name: 'MoreInfo' });
export { useStyles };
