import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import Dialog from "@material-ui/core/Dialog/Dialog";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import { WarningIcon } from "~icons/WarningIcon";
import { closeModal } from "~modals";
import { useModalStyles } from "~modals/components/Modal.styles";
var PurchaseTicketsResultModal = function (_a) {
    var modalName = _a.modalName, _b = _a.isVisible, isVisible = _b === void 0 ? false : _b, _c = _a.modalProps, result = _c.result, successesDescription = _c.successesDescription, errorDescription = _c.errorDescription, handleClose = _c.handleClose;
    var t = useTranslation(['common', 'tip-game']).t;
    var dispatch = useDispatch();
    var classes = useModalStyles();
    var handleCancel = function () {
        if (handleClose) {
            handleClose();
        }
        dispatch(closeModal({ key: modalName }));
    };
    return (React.createElement(Dialog, { open: isVisible, onClose: handleCancel, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description", className: classes.root },
        React.createElement(DialogContent, { className: classes.dialog },
            React.createElement("div", { className: classes.purchaseTitle }, t('tip-game:creating_tips_title')),
            React.createElement("div", { className: classes.purchaseContent }, result === null || result === void 0 ? void 0 : result.map(function (item, index) {
                var _a;
                var groupName = (_a = item.game) === null || _a === void 0 ? void 0 : _a.groupName;
                var gameName = groupName ? groupName : item.game.tournament ? item.game.tournament : t("tip-game:game-name.".concat(item.game.name));
                return (React.createElement(React.Fragment, { key: index },
                    React.createElement("div", { className: classes.purchaseItem },
                        React.createElement("div", { className: classes.gameName }, gameName),
                        React.createElement("div", { className: classes.purchaseDescription },
                            React.createElement("div", { className: classes.statusContainer }, item.success ? (React.createElement("div", { className: classes.status },
                                React.createElement("i", { className: "far fa-check", style: { color: '#00C150' } }),
                                t('tip-game:done'))) : (React.createElement("div", { className: classes.status },
                                React.createElement(WarningIcon, { styles: { width: 16, height: 16, marginRight: 8 } }),
                                t('tip-game:warning')))),
                            React.createElement("div", { className: classes.description }, item.success ? (React.createElement("div", null, successesDescription || t('tip-game:tip_accepted_successfully'))) : (React.createElement("div", null, errorDescription || t('tip-game:error_accepting_the_tip')))))),
                    result.length - 1 > index && React.createElement("div", { className: classes.divider })));
            })),
            React.createElement(Button, { fullWidth: true, size: "large", color: "primary", variant: "contained", style: { marginBottom: '32px' }, onClick: handleCancel }, t('common:buttons.close')))));
};
export { PurchaseTicketsResultModal };
