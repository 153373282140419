import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { goBack, push } from 'connected-react-router';
import i18next from 'i18next';
import { useAppDispatch } from '~store';
import { useStructuredSelector } from '~hooks';
import { currentSportIdSelector } from '~slices/options';
import { useStatisticsHeaderStyles } from './Home.styles';
export var Statistic = function () {
    var iframe = useRef(null);
    var classes = useStatisticsHeaderStyles();
    var dispatch = useAppDispatch();
    var location = useLocation();
    var t = useTranslation('common').t;
    var _a = useParams(), season = _a.season, eventId = _a.eventId;
    var currentSportId = useStructuredSelector({
        currentSportId: currentSportIdSelector,
    }).currentSportId;
    useEffect(function () {
        var _a, _b, _c;
        if (iframe.current) {
            var elements = [
                (_a = iframe.current.contentWindow) === null || _a === void 0 ? void 0 : _a.document.getElementById('banner'),
                (_b = iframe.current.contentWindow) === null || _b === void 0 ? void 0 : _b.document.getElementsByClassName('popup-navigation')[0],
                (_c = iframe.current.contentWindow) === null || _c === void 0 ? void 0 : _c.document.getElementsByClassName('menu-wrapper')[0],
            ];
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            elements.forEach(function (element) {
                if (element) {
                    // eslint-disable-next-line no-param-reassign
                    element.style.display = 'none';
                }
            });
        }
    }, []);
    var goBackClick = function () {
        var _a, _b, _c;
        if (location.state) {
            dispatch(push({
                pathname: (_a = location.state) === null || _a === void 0 ? void 0 : _a.prevPath,
                state: {
                    round: (_b = location.state) === null || _b === void 0 ? void 0 : _b.round,
                    scrollPosition: (_c = location.state) === null || _c === void 0 ? void 0 : _c.scrollPosition,
                    scrollFlag: true,
                },
            }));
        }
        else
            dispatch(goBack());
    };
    if (!season || !eventId) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classes.header },
            React.createElement(Button, { className: classes.backButton, classes: { root: classes.hover, label: classes.height }, onClick: goBackClick, size: "small", startIcon: React.createElement("i", { className: "far fa-chevron-left ".concat(classes.backButtonIcon) }) }, t('back'))),
        React.createElement("iframe", { ref: iframe, src: "".concat(process.env.REACT_APP_BR_URL, "/").concat(process.env.REACT_APP_BR_BRAND_NAME, "/").concat(i18next.language, "/").concat(currentSportId, "/season/").concat(season, "/headtohead/match/").concat(eventId), style: { flexGrow: 1, border: 'none' }, id: "iframe", title: "statistics" })));
};
