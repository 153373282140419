import { useSelector } from 'react-redux';
import { name } from './constants';
export var playerAccountsSelector = function (state) { return state[name]; };
export var playerAccountsDataSelector = function (state) {
    return playerAccountsSelector(state).data;
};
export var playerAccountsLoadingSelector = function (state) {
    return playerAccountsSelector(state).loading;
};
export var rewardedLoadedSelector = function (state) {
    return playerAccountsSelector(state).meta.rewardedLoaded;
};
export var usePlayerAccountsDataSelector = function () {
    return useSelector(playerAccountsDataSelector);
};
export var useRewardedLoadedSelector = function () {
    return useSelector(rewardedLoadedSelector);
};
export var usePlayerAccountsLoadingSelector = function () {
    return useSelector(playerAccountsLoadingSelector);
};
