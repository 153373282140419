import makeStyles from '@material-ui/core/styles/makeStyles';
var useStyles = makeStyles({
    container: {
        fontSize: '12px',
        lineHeight: '15px',
        color: '#FF0000',
        fontWeight: 400,
    },
}, { name: 'BetStopIcon' });
export { useStyles };
