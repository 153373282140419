var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/es/storage';
import { createAsyncReducers } from '~utils/createAsyncReducers';
import { name } from './constants';
import { getOptions, setOptions } from './thunks';
var initialState = {
    data: {
        autoAccept: false,
        wantNewslettersOnEmail: false,
        wantPushNotification: true,
        sportId: NaN,
        localSportId: NaN,
        soonLiveSportId: NaN,
        sportName: { id: NaN, name: '' },
        wasLoadedOptions: false,
        view: 'default',
    },
    loading: 'idle',
    error: null,
};
export var options = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        changeView: function (state, _a) {
            var payload = _a.payload;
            return __assign(__assign({}, state), { data: __assign(__assign({}, state.data), { view: payload }) });
        },
        changeSportId: function (state, _a) {
            var payload = _a.payload;
            return __assign(__assign({}, state), { data: __assign(__assign({}, state.data), { sportId: payload, localSportId: payload, soonLiveSportId: payload }) });
        },
        changeLocalSportId: function (state, _a) {
            var payload = _a.payload;
            return __assign(__assign({}, state), { data: __assign(__assign({}, state.data), { localSportId: payload.id, soonLiveSportId: payload.id }) });
        },
        setSportName: function (state, _a) {
            var payload = _a.payload;
            return __assign(__assign({}, state), { data: __assign(__assign({}, state.data), { sportName: {
                        id: payload.id,
                        name: encodeURI(payload.name)
                    } }) });
        },
        changeSoonLiveSportId: function (state, _a) {
            var payload = _a.payload;
            state.data.soonLiveSportId = payload.id;
        },
        syncSoonLiveSportId: function (state) {
            state.data.soonLiveSportId = state.data.localSportId;
        },
    },
    extraReducers: function (builder) {
        var _a = createAsyncReducers(), pending = _a.pending, rejected = _a.rejected;
        builder
            .addCase(getOptions.pending, pending)
            .addCase(getOptions.fulfilled, function (state, _a) {
            var payload = _a.payload;
            return __assign(__assign({}, state), { data: __assign(__assign({}, state.data), payload), loading: 'fulfilled' });
        })
            .addCase(getOptions.rejected, rejected)
            .addCase(setOptions.pending, pending)
            .addCase(setOptions.fulfilled, function (state, _a) {
            var arg = _a.meta.arg;
            return __assign(__assign({}, state), { data: __assign(__assign({}, state.data), arg), loading: 'fulfilled', error: null });
        })
            .addCase(setOptions.rejected, rejected)
            .addCase('auth/logout', function () {
            return initialState;
        });
    },
});
export var changeSportId = (_a = options.actions, _a.changeSportId), changeLocalSportId = _a.changeLocalSportId, setSportName = _a.setSportName, changeView = _a.changeView, changeSoonLiveSportId = _a.changeSoonLiveSportId, syncSoonLiveSportId = _a.syncSoonLiveSportId;
export var optionsPersistedReducer = persistReducer({
    key: name,
    whitelist: ['data'],
    storage: storage,
}, options.reducer);
