import makeStyles from '@material-ui/core/styles/makeStyles';
var useStyles = makeStyles(function (theme) {
    var _a;
    return ({
        container: {
            position: 'relative',
            width: '100%',
            height: '0px',
            display: 'flex',
            justifyContent: 'flex-end',
        },
        popper: {
            transform: 'none !important',
            left: 'unset !important',
        },
        left: (_a = {
                left: '0px !important'
            },
            _a[theme.breakpoints.up('lg')] = {
                left: '61px !important',
            },
            _a),
        content: {
            backgroundColor: 'transparent',
        },
    });
}, { name: 'EventsDropDown' });
export { useStyles };
