import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { useStyles } from '~molecules/WithdrawTab/components/WithdrawInShop/WithdrawInShop.styles';
import { getPathWithLanguageCode } from '~utils/languageInURL';
import { PaymentsURL } from '~pages/payments/payments.routes';
import { Button, FullscreenLoader } from '~atoms';
import { useAppDispatch } from '~store';
import { WithdrawTypeCard } from '~atoms/WithdrawTypeCard';
import { useStructuredSelector } from '~hooks';
import { betslipDataSelector } from '~slices/betslip';
import { Currencies } from '~utils/converters';
import { numberWithDotsAndComma } from '~utils/numberWithDotsAndComma';
import { numberWithDots } from '~utils/numberWithDots';
export var PaymentStatus = function () {
    var _a, _b;
    var t = useTranslation('tip-game').t;
    var classes = useStyles();
    var dispatch = useAppDispatch();
    var _c = useState(false), isDataLoading = _c[0], setIsDataLoading = _c[1];
    var mockData = {
        jackpot: 5500,
    };
    var withdrawSumWithDotsAndComma = numberWithDotsAndComma(mockData.jackpot);
    var withdrawSumWithDots = numberWithDots(mockData.jackpot);
    // TODO, replace with real data from BE
    var betslip = useStructuredSelector({
        betslip: betslipDataSelector,
    }).betslip;
    var ticketOffer = betslip.to;
    var currency = (_a = ticketOffer === null || ticketOffer === void 0 ? void 0 : ticketOffer.cu) !== null && _a !== void 0 ? _a : 'EUR';
    var onReCheckStatus = function () {
        // TODO, replace with real data from BE
        var isMoneyWithAgent = Math.random() * 10 > 5;
        console.log('isMoneyWithAgent:', isMoneyWithAgent);
        setIsDataLoading(true);
        setTimeout(function () {
            setIsDataLoading(false);
        }, 2000);
        if (isMoneyWithAgent) {
            setTimeout(function () {
                dispatch(push(getPathWithLanguageCode("/".concat(PaymentsURL.PAYMENTS, "/").concat(PaymentsURL.WITHDRAW_JACKPOT_BALANCE, "/").concat(PaymentsURL.PAYMENT_CODE, "?sum=").concat(withdrawSumWithDots))));
            }, 2100);
        }
    };
    return (React.createElement("div", { className: classes.container },
        isDataLoading && React.createElement(FullscreenLoader, null),
        React.createElement("div", null,
            React.createElement("div", { className: classes.title }, t('payments.payment_status')),
            React.createElement("div", { className: classes.subtitle },
                React.createElement("p", null, t('payments.we_send_your_jackpot_to_agent', {
                    amount: withdrawSumWithDotsAndComma,
                    currency: (_b = Currencies[currency]) !== null && _b !== void 0 ? _b : currency,
                })),
                React.createElement("p", null, t('payments.your_money_is_still_on_the_way_to_the_agent')),
                React.createElement("p", null,
                    t('payments.we_need_to_bring_the_money_to_your_agent'),
                    ' ',
                    t('payments.you_can_re-check_to_see_the_arrival_of_money')),
                React.createElement("p", null, t('payments.collect_after_24h')))),
        React.createElement("div", { className: classes.actionBlock },
            React.createElement(WithdrawTypeCard, { type: 'inShop', isActive: true }),
            React.createElement(Button, { color: "primary", variant: "contained", fullWidth: true, className: classes.button, onClick: onReCheckStatus }, t('payments.re-check_status')))));
};
