import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { Redirect, Route, Switch, useLocation, } from 'react-router-dom';
import classNames from 'classnames';
import { reset } from 'redux-form';
import { unwrapResult } from '@reduxjs/toolkit';
import ReactGA from 'react-ga4';
import i18next from 'i18next';
import ReactPixel from "react-facebook-pixel";
import { errorSelector, registration } from '~slices/auth';
import { getLocale as getLocaleAsyncAction, localeDataSelector, localeLoadingSelector, } from '~slices/locale';
import { MODALS, openModal } from '~modals';
import { FullscreenLoader, ProgressBar } from '~atoms';
import { useGAMethod, useIsMobileDevise, useRecaptcha, useStructuredSelector, } from '~hooks';
import { useAppDispatch } from '~store';
import { prepareDataForRequest, } from '~utils/registration/prepareDataForRequest';
import { getTestId } from '~utils/getTestId';
import { addExternalScript } from '~utils/addExternalScript';
import { getPathWithLanguageCode } from '~utils/languageInURL/getPathWithLanguageCode';
import RegistrationSteps from '~pages/registration/components/RegistrationSteps';
import { RegistrationStep } from '~constants/steps/RegistrationStep';
import { getFromStorageWithLifetime, setToStorageWithLifetime, } from '~utils/localStorage';
import { useRegisterStyles } from './RegisterForm.styles';
export var PTAG_KEY = 'PTAG';
var RegisterForm = function (_a) {
    var match = _a.match;
    var _b = useStructuredSelector({
        isLoading: localeLoadingSelector,
        errorsData: errorSelector,
        localeData: localeDataSelector,
    }), isLoading = _b.isLoading, errorsData = _b.errorsData, _c = _b.localeData, countries = _c.countries, nationalities = _c.nationalities, titles = _c.titles, defaultCountry = _c.defaultCountry, registrationBlocks = _c.registrationBlocks;
    var t = useTranslation('registration').t;
    var root = match.url;
    var location = useLocation();
    var registerClasses = useRegisterStyles();
    var errors = errorsData === null || errorsData === void 0 ? void 0 : errorsData.errors;
    var dispatch = useAppDispatch();
    var currentStep = Number.isNaN(+location.pathname.slice(-1)) ||
        +location.pathname.slice(-1) > 3
        ? 1
        : +location.pathname.slice(-1);
    var _d = useState(currentStep), step = _d[0], setStep = _d[1];
    var _e = useState(NaN), externalUserId = _e[0], setExternalUserId = _e[1];
    var _f = useState(undefined), pTag = _f[0], setPTag = _f[1];
    var isMobileDevice = useIsMobileDevise();
    var methodName = useGAMethod();
    var language = i18next.language;
    var numberSteps = registrationBlocks.length;
    var searchParams = new URLSearchParams(location.search);
    var pTagCode = searchParams.get(PTAG_KEY);
    var activePTagCode = getFromStorageWithLifetime(PTAG_KEY);
    if (!isMobileDevice) {
        dispatch(push({
            pathname: getPathWithLanguageCode('/home'),
            state: { openRegistrationModal: true, registrationStep: 1 },
        }));
    }
    var initialValues = {
        userProfileDto: {
            addresses: {
                countryCode: defaultCountry,
            },
        },
    };
    useEffect(function () {
        if (activePTagCode) {
            setPTag(activePTagCode);
            return;
        }
        if (pTagCode) {
            setPTag(pTagCode);
            // lifetime - 24h / 1440 min.
            setToStorageWithLifetime(PTAG_KEY, pTagCode, 1440);
        }
    }, []);
    useEffect(function () {
        if (language) {
            dispatch(getLocaleAsyncAction(language));
        }
    }, [language]);
    useEffect(function () {
        if (step === RegistrationStep.FIRST_STEP) {
            ReactGA.event('sign_up', {
                method: methodName,
            });
            ReactPixel.trackCustom('sign_up', {
                method: methodName,
            });
        }
    }, []);
    useEffect(function () {
        if (step === RegistrationStep.SECOND_STEP) {
            var scriptBody = "\n        srtmCommands.push({\n          event: \"track.user.registration\",\n          payload: {\n              action: \"start\"\n          }\n        });\n      ";
            addExternalScript({ body: scriptBody });
        }
    }, [step]);
    useEffect(function () {
        var scriptComplete = "\n    srtmCommands.push({\n    event: \"track.user.registration\",\n    payload: {\n        action: \"complete\",\n        userId: \"".concat(externalUserId, "\",\n    }\n});\n  ");
        if (externalUserId) {
            addExternalScript({ body: scriptComplete });
        }
    }, [externalUserId]);
    var nextStep = function () {
        setStep(step + 1);
        dispatch(push("".concat(root, "/step-").concat(step + 1)));
    };
    var previousStep = function () {
        dispatch(push("".concat(root, "/step-").concat(step - 1)));
        setStep(step - 1);
    };
    var cancel = function () {
        dispatch(push(getPathWithLanguageCode('/home')));
    };
    var _g = useRecaptcha(), token = _g.token, isValidated = _g.isValidated, execute = _g.execute;
    var register = function (data, recaptchaToken) {
        dispatch(registration(prepareDataForRequest({
            data: data,
            nationalities: nationalities,
            titles: titles,
            recaptchaToken: recaptchaToken,
            pTag: pTag,
        })))
            .then(unwrapResult)
            .then(function (_a) {
            var externalId = _a.externalId;
            setExternalUserId(externalId);
        })
            .then(function () {
            dispatch(reset('registration-form'));
            dispatch(push({
                pathname: getPathWithLanguageCode("".concat(root, "/success")),
                state: { email: data.email },
            }));
        });
    };
    var handleSubmit = function (data) {
        if (registrationBlocks.length === 1 || registrationBlocks.length === step) {
            if (isValidated && token) {
                register(data, token);
                return;
            }
            execute(function (recaptchaToken) {
                register(data, recaptchaToken);
            });
        }
        else {
            nextStep();
        }
    };
    useEffect(function () {
        var _a, _b;
        if (errors && ((_a = errors[0]) === null || _a === void 0 ? void 0 : _a.type) !== 'AUTHENTICATION' && ((_b = errors[0]) === null || _b === void 0 ? void 0 : _b.type) !== 'VALIDATION') {
            dispatch(openModal({
                modalName: MODALS.REGISTRATION_ERRORS_MODAL,
                modalProps: {
                    closeButtonLabel: t('continue_button'),
                    cancelVariant: 'contained',
                    fullWidth: true,
                    hugeButton: true,
                    cancelColor: 'primary',
                    columnDialog: false,
                },
            }));
        }
    }, [errors]);
    return isLoading !== 'fulfilled' ? (React.createElement(FullscreenLoader, null)) : (React.createElement("div", { className: registerClasses.root, "data-testid": getTestId('FRM_REGISTER') },
        React.createElement("h1", { className: registerClasses.title }, t('header')),
        numberSteps > 1 && React.createElement(ProgressBar, { steps: numberSteps, step: step }),
        React.createElement("div", { className: classNames('flex-auto flex-column', registerClasses.registerFormMain) },
            React.createElement(Switch, null,
                React.createElement(Route, { path: "".concat(root, "/step-").concat(step), render: function () { return (React.createElement(RegistrationSteps, { initialValues: initialValues, step: step, countries: countries, registrationBlocks: registrationBlocks, titles: titles, isDesktop: false, 
                        // @ts-expect-error*/
                        onSubmit: handleSubmit, onCancel: cancel, onBack: previousStep })); } }),
                React.createElement(Redirect, { to: "".concat(root, "/step-1") })))));
};
export default RegisterForm;
