import makeStyles from '@material-ui/core/styles/makeStyles';
export var useStyles = makeStyles(function (theme) { return ({
    manualPay: {
        '& span': {
            paddingTop: '5px',
            color: '#212121',
            fontWeight: 'bold',
            fontSize: '12px',
            paddingLeft: '12px',
        },
    },
    direct: {
        position: 'relative',
        borderRadius: '4px',
        lineHeight: '12px',
    },
    icon: {
        fontSize: '14px',
        padding: '6px 8px',
        borderRadius: '4px',
    },
    helpfulIcon: {
        '& i': {
            fontSize: '8px',
            position: 'absolute',
            top: '2px',
            right: '1px',
            transform: 'translate(50%, -50%)',
            display: 'flex',
            justifyContent: 'center',
            width: '10px',
            height: '10px',
            background: '#323232',
            borderRadius: '50%',
            paddingBottom: '3px',
            paddingLeft: '4px',
        },
    },
    ppmo: {
        color: '#fff',
        borderRadius: '4px',
        border: '1px solid #3E3E3E',
        background: '#323232',
        padding: '16px 20px 16px 16px',
        marginBottom: '16px',
        cursor: 'pointer',
    },
    title: {
        paddingLeft: '16px',
        fontSize: '12px',
        fontWeight: 'bold',
    },
    information: {
        marginLeft: 'auto',
    },
    info: {
        fontSize: '12px',
    },
}); }, { name: 'Wallet' });
