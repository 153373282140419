var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { push } from "connected-react-router";
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import i18next from 'i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslation } from "react-i18next";
import { useIsDesktop, useStructuredSelector } from '~hooks';
import { MODALS, openModal } from "~modals";
import { InfoButton } from "~pages/tips-list/components/infoButton/InfoButton";
import { sportNameSelector } from "~slices/options";
import { TICKETS_SOCKET_PATH_NAME, TICKET_UPDATES_NAME, getTickets, lazyGetTickets, pickActiveId, resetTickets, setActiveArchiveTicket, ticketDataSelector, useLoadingSelector, } from '~slices/tickets';
import { FullscreenLoader } from '~atoms/loader';
import { connectSocket } from '~slices/events';
import { isTopTeasersHorizonGalleryShownSelector } from '~slices/teasers';
import { formatLargeNumber } from "~utils/formatLargeNumber";
import { getTwoFirstGlobalGames } from "~utils/getTwoFirstGlobalGames";
import { getPathWithLanguageCode } from "~utils/languageInURL";
import { Tip } from "~view/pages";
import { getDailyRanking, getRankingListAsync, getWeeklyRanking } from "~slices/ranking";
import { useActiveGameSelector, useGameTypesDataSelector, useSelectedGameDataSelector, useSelectedGroupSelector, useTournamentSelector } from "~slices/gameTypes";
import { useAppDispatch } from "~store";
import { NoTickets } from "~pages/tips-list/components/noTickets";
import { API } from "~api";
import { ArchiveTicket } from "~pages/tips-list/components/archiveTicket";
import { GAMES_MODE, GAME_PERIOD } from "~slices/gameTypes/constants";
var gap = 40;
var MOBILE_HEADER_HEIGHT = 52;
var MOBILE_TEASERS_HEIGHT = 126;
var TABS_HEIGHT = 48;
var useStyles = makeStyles({
    ticketsList: {
        padding: '16px',
        paddingTop: 0,
        overflow: 'auto',
        boxSizing: 'border-box',
    },
    ticketsListDesktop: {
        padding: 0,
        margin: 0,
        marginTop: 0,
    },
    collapseWrapper: {
        height: '50vh',
        overflow: 'auto',
    },
    archiveTicketHeader: {
        margin: '20px 0',
        fontSize: 16,
        fontWeight: 600,
        textAlign: 'start',
        color: '#FFFFFF',
    },
    title: {
        marginTop: 20,
        marginBottom: 20,
        fontSize: 16,
        fontWeight: 600,
        color: '#FFFFFF',
    },
    infoButtonsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        columnGap: 5,
        marginBottom: 20,
        marginTop: 20,
    },
    divider: {
        width: '100%',
        height: 1,
        background: '#323232',
        borderRadius: 4,
    },
}, { name: 'Tips' });
var WEBSOCKET = process.env.REACT_APP_BASE_WEBSOCKET || '';
export var Tips = React.memo(function Tips() {
    var _a;
    var classes = useStyles();
    var isDesktop = useIsDesktop();
    var archiveRef = useRef(null);
    var t = useTranslation(['ticket', 'tip-game']).t;
    var _b = useStructuredSelector({
        activeGame: useActiveGameSelector,
        gameTypes: useGameTypesDataSelector,
        isTopTeasersHorizonGalleryShown: isTopTeasersHorizonGalleryShownSelector,
        loading: useLoadingSelector,
        ticketsData: ticketDataSelector,
        tournament: useTournamentSelector,
        selectedGame: useSelectedGameDataSelector,
        selectedGroup: useSelectedGroupSelector,
        sportName: sportNameSelector,
    }), activeGame = _b.activeGame, gameTypes = _b.gameTypes, isTopTeasersHorizonGalleryShown = _b.isTopTeasersHorizonGalleryShown, loading = _b.loading, ticketsData = _b.ticketsData, tournament = _b.tournament, selectedGame = _b.selectedGame, selectedGroup = _b.selectedGroup, sportName = _b.sportName;
    var gamesData = useMemo(function () {
        var _a;
        if (selectedGame) {
            return gameTypes.filter(function (game) { return game.name === selectedGame; });
        }
        else {
            return (_a = getTwoFirstGlobalGames(gameTypes)) === null || _a === void 0 ? void 0 : _a.filter(function (game) {
                return (tournament === null || tournament === void 0 ? void 0 : tournament.pathname)
                    ? game.tournament
                    : !game.tournament;
            });
        }
    }, [gameTypes]);
    var tickets = (_a = ticketsData.content) === null || _a === void 0 ? void 0 : _a.filter(function (ticket) { return ticket.gameId; });
    var dispatch = useAppDispatch();
    var tabName = useParams().tabName;
    var defaultTicketId = ((tickets === null || tickets === void 0 ? void 0 : tickets.length) && tickets[0].id) || 0;
    var _c = useState(undefined), myselfRanking = _c[0], setMyselfRanking = _c[1];
    var _d = useState([]), gamesHistory = _d[0], setGamesHistory = _d[1];
    var _e = useState(false), isGameHistoryLoading = _e[0], setIsGameHistoryLoading = _e[1];
    var _f = useState(undefined), archiveTickets = _f[0], setArchiveTickets = _f[1];
    var _g = useState(false), isArchiveTicketsLoading = _g[0], setIsArchiveTicketsLoading = _g[1];
    var _h = useState(null), openedArchiveTicket = _h[0], setOpenedArchiveTicket = _h[1];
    var getCurrentGame = function (name, games) { return games.find(function (game) { return game.name === name; }); };
    var handleScroll = function (e) {
        var _a, _b, _c, _d;
        var archiveTicketsHeight = ((_a = archiveRef === null || archiveRef === void 0 ? void 0 : archiveRef.current) === null || _a === void 0 ? void 0 : _a.clientHeight) ? ((_b = archiveRef === null || archiveRef === void 0 ? void 0 : archiveRef.current) === null || _b === void 0 ? void 0 : _b.clientHeight) + gap : 0;
        var archiveTicketsTopPosition = (_d = (_c = archiveRef === null || archiveRef === void 0 ? void 0 : archiveRef.current) === null || _c === void 0 ? void 0 : _c.getBoundingClientRect().top) !== null && _d !== void 0 ? _d : 0;
        var archiveTicketsGap = isDesktop ? 400 : 250;
        var isDataLoadNeedCheck = e.currentTarget.scrollHeight - e.currentTarget.scrollTop - (gap + archiveTicketsHeight) <=
            e.currentTarget.clientHeight ||
            e.currentTarget.clientHeight + archiveTicketsGap >= archiveTicketsTopPosition;
        if (isDataLoadNeedCheck && ticketsData) {
            if (loading === 'pending' || ticketsData.last) {
                return;
            }
            var tabId = tabName === 'archive' ? tabName : 'GAME';
            var gameName = tabName !== 'archive' ? tabName : undefined;
            var name_1 = gameName || activeGame;
            var currentGame = getCurrentGame(name_1 || '', gamesData);
            dispatch(lazyGetTickets({
                tabId: tabId,
                ticketSearchStatus: 'ALL',
                gameName: gameName,
                groupKey: currentGame === null || currentGame === void 0 ? void 0 : currentGame.groupKey,
                size: 20,
                // @ts-expect-error
                page: ticketsData.pageable.pageNumber + 1,
            }));
        }
    };
    var getDailyRankingList = function () {
        var dailyGame = gamesData.find(function (game) { return game.gamePeriod === GAME_PERIOD.DAILY; });
        if (dailyGame)
            dispatch(getDailyRanking({ baseURL: dailyGame.url, gameName: dailyGame === null || dailyGame === void 0 ? void 0 : dailyGame.name }))
                .then(function (_a) {
                var payload = _a.payload;
                var rankingList = payload;
                setMyselfRanking(rankingList === null || rankingList === void 0 ? void 0 : rankingList.myselfRanking);
            });
    };
    var getWeeklyRankingList = function () {
        var weeklyGame = gamesData.find(function (game) { return game.gamePeriod === GAME_PERIOD.WEEKLY; });
        if (weeklyGame)
            dispatch(getWeeklyRanking({ baseURL: weeklyGame.url, gameName: weeklyGame === null || weeklyGame === void 0 ? void 0 : weeklyGame.name }))
                .then(function (_a) {
                var payload = _a.payload;
                var rankingList = payload;
                setMyselfRanking(rankingList === null || rankingList === void 0 ? void 0 : rankingList.myselfRanking);
            });
    };
    var getGroupGameRankingList = function () {
        var groupGame = gameTypes.find(function (game) { return (game === null || game === void 0 ? void 0 : game.gameMode) === GAMES_MODE.GROUP && game.groupName === selectedGroup; });
        if (groupGame) {
            dispatch(getRankingListAsync({ baseURL: groupGame === null || groupGame === void 0 ? void 0 : groupGame.url, groupName: groupGame === null || groupGame === void 0 ? void 0 : groupGame.groupName }))
                .then(function (_a) {
                var payload = _a.payload;
                var rankingList = payload;
                setMyselfRanking(rankingList === null || rankingList === void 0 ? void 0 : rankingList.myselfRanking);
            });
        }
    };
    var getTicketsList = function (tab) {
        var tabId = tab === 'archive' ? tab : 'GAME';
        var gameName = tab !== 'archive' ? tab : undefined;
        var name = gameName || activeGame;
        var currentGame = getCurrentGame(name || '', gamesData);
        dispatch(getTickets({
            tabId: tabId,
            ticketSearchStatus: 'ALL',
            gameName: gameName,
            groupKey: currentGame === null || currentGame === void 0 ? void 0 : currentGame.groupKey,
        }));
    };
    var getGamesHistoryList = function (tab) {
        setIsGameHistoryLoading(true);
        var gameName = tabName !== 'archive' ? tabName : activeGame;
        var currentGame = getCurrentGame(gameName || '', gamesData);
        API.getGamesHistory({ gameName: tab, groupKey: currentGame === null || currentGame === void 0 ? void 0 : currentGame.groupKey, })
            .then(function (res) {
            if (res.status === 200) {
                setGamesHistory(res.data);
            }
        })
            .finally(function () { return setIsGameHistoryLoading(false); });
    };
    var getTicketsHistoryList = function (game, date, page) {
        setIsArchiveTicketsLoading(true);
        var gameName = tabName !== 'archive' ? tabName : activeGame;
        var currentGame = getCurrentGame(gameName || '', gamesData);
        var tabId = 'ARCHIVE';
        API.getArchiveTickets({
            tabId: tabId,
            ticketSearchStatus: 'ALL',
            gameName: game,
            groupKey: currentGame === null || currentGame === void 0 ? void 0 : currentGame.groupKey,
            page: page,
            size: 10,
            sort: 'asc',
            gameValidUntil: date,
        })
            .then(function (res) {
            var _a;
            if (res.status === 200) {
                if (page > 0 && (archiveTickets === null || archiveTickets === void 0 ? void 0 : archiveTickets.content)) {
                    setArchiveTickets(function (prevState) { return (__assign(__assign({}, res.data), { content: __spreadArray(__spreadArray([], prevState === null || prevState === void 0 ? void 0 : prevState.content, true), res.data.content, true) })); });
                }
                else {
                    setArchiveTickets(res.data);
                    dispatch(setActiveArchiveTicket(((_a = res.data) === null || _a === void 0 ? void 0 : _a.content) ? res.data.content[0] : null));
                }
            }
        })
            .finally(function () { return setIsArchiveTicketsLoading(false); });
    };
    var onArchiveTicketClick = function (game, date, index) {
        setArchiveTickets(undefined);
        if (openedArchiveTicket === index) {
            setOpenedArchiveTicket(null);
            dispatch(setActiveArchiveTicket(null));
            return;
        }
        getTicketsHistoryList(game, date, 0);
        setOpenedArchiveTicket(index);
        dispatch(pickActiveId(0));
    };
    var archiveTicketsHandleScroll = function (containerRef) {
        var container = containerRef.current;
        if (container &&
            container.scrollTop + container.clientHeight + gap >=
                container.scrollHeight &&
            !(archiveTickets === null || archiveTickets === void 0 ? void 0 : archiveTickets.last) &&
            openedArchiveTicket !== null &&
            !isArchiveTicketsLoading) {
            var _a = gamesHistory[openedArchiveTicket], gameName = _a.gameName, validUntil = _a.validUntil;
            getTicketsHistoryList(gameName, validUntil, (archiveTickets === null || archiveTickets === void 0 ? void 0 : archiveTickets.number) + 1);
        }
    };
    var onNoTicketsClick = function () {
        var path = (tournament === null || tournament === void 0 ? void 0 : tournament.pathname) || getPathWithLanguageCode("/home/all/live/".concat(sportName === null || sportName === void 0 ? void 0 : sportName.name));
        dispatch(openModal({
            modalName: MODALS.JOIN_GAME_DESCRIPTION_MODAL,
            modalProps: {
                handleClose: function () { return dispatch(push(path)); },
            },
        }));
    };
    useEffect(function () {
        dispatch(resetTickets());
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        setOpenedArchiveTicket(null);
        setArchiveTickets(undefined);
        dispatch(setActiveArchiveTicket(null));
        var gameName = tabName || activeGame;
        if (!gameName)
            return;
        getTicketsList(gameName);
        getGamesHistoryList(gameName);
        var selectedGame = gamesData.find(function (game) { return game.name === gameName; });
        if (selectedGroup) {
            getGroupGameRankingList();
        }
        else if ((selectedGame === null || selectedGame === void 0 ? void 0 : selectedGame.gamePeriod) === GAME_PERIOD.WEEKLY || (selectedGame === null || selectedGame === void 0 ? void 0 : selectedGame.gamePeriod) === GAME_PERIOD.TOURNAMENT) {
            getWeeklyRankingList();
        }
        else if ((selectedGame === null || selectedGame === void 0 ? void 0 : selectedGame.gamePeriod) === GAME_PERIOD.DAILY) {
            getDailyRankingList();
        }
    }, [tabName]);
    useEffect(function () {
        var gameName = tabName || activeGame;
        var name = tabName !== 'archive' ? tabName : activeGame;
        var currentGame = getCurrentGame(name || '', gamesData);
        dispatch(connectSocket({
            eventsType: TICKET_UPDATES_NAME,
            url: "".concat(WEBSOCKET).concat(TICKETS_SOCKET_PATH_NAME),
            langIso: i18next.language,
            withOutInterval: true,
            withOutMessage: true,
            gameName: selectedGame || gameName || undefined,
            groupKey: currentGame === null || currentGame === void 0 ? void 0 : currentGame.groupKey,
        }));
        return function () {
            dispatch({ type: 'SOCKET_CLOSE', eventsType: TICKET_UPDATES_NAME });
        };
    }, [i18next.language]);
    useEffect(function () {
        var tId;
        var shouldReopen = false;
        var handleVisibilityChange = function () {
            if (document.hidden) {
                tId = setTimeout(function () {
                    dispatch({ type: 'SOCKET_CLOSE', eventsType: TICKET_UPDATES_NAME });
                    shouldReopen = true;
                }, Number(process.env.REACT_APP_SOCKET_BACKGROUND_CLOSE_TIME) * 1000);
            }
            else if (shouldReopen) {
                var gameName = tabName || activeGame;
                var name_2 = tabName !== 'archive' ? tabName : activeGame;
                var currentGame = getCurrentGame(name_2 || '', gamesData);
                dispatch(connectSocket({
                    eventsType: TICKET_UPDATES_NAME,
                    url: "".concat(WEBSOCKET).concat(TICKETS_SOCKET_PATH_NAME),
                    langIso: i18next.language,
                    withOutInterval: true,
                    withOutMessage: true,
                    gameName: selectedGame || gameName || undefined,
                    groupKey: currentGame === null || currentGame === void 0 ? void 0 : currentGame.groupKey,
                }));
                shouldReopen = false;
            }
            else {
                clearTimeout(tId);
            }
        };
        if (typeof document.addEventListener === 'undefined') {
            console.log('This page requires a browser, that supports the Page Visibility API.');
        }
        else {
            document.addEventListener('visibilitychange', handleVisibilityChange, false);
        }
        return function () {
            dispatch(resetTickets());
            clearTimeout(tId);
            document.removeEventListener('visibilitychange', handleVisibilityChange, false);
        };
    }, []);
    var topTeasersHeight = isTopTeasersHorizonGalleryShown
        ? MOBILE_TEASERS_HEIGHT
        : 0;
    var restContentHeight = MOBILE_HEADER_HEIGHT + topTeasersHeight + TABS_HEIGHT;
    return (React.createElement(React.Fragment, null,
        loading === 'pending' && React.createElement(FullscreenLoader, null),
        React.createElement("div", { onScroll: handleScroll, style: {
                height: isDesktop
                    ? '100%'
                    : "calc(100vh - ".concat(restContentHeight, "px)"),
            }, className: classNames(classes.ticketsList, isDesktop && classes.ticketsListDesktop) },
            React.createElement("div", { className: classes.infoButtonsContainer },
                React.createElement(InfoButton, { title: t('tip-game:my-tips.tickets'), data: (tickets === null || tickets === void 0 ? void 0 : tickets.length) || 0 }),
                React.createElement(InfoButton, { title: t('tip-game:level'), data: (myselfRanking === null || myselfRanking === void 0 ? void 0 : myselfRanking.level) || '-' }),
                React.createElement(InfoButton, { title: t('tip-game:rank'), data: (myselfRanking === null || myselfRanking === void 0 ? void 0 : myselfRanking.rank) || '-' }),
                React.createElement(InfoButton, { title: t('tip-game:ranking-list.my_credits'), data: formatLargeNumber((myselfRanking === null || myselfRanking === void 0 ? void 0 : myselfRanking.balance) || 0), isIcon: true })),
            React.createElement("div", { className: classes.divider }),
            React.createElement("div", { className: classes.title }, t('ticket:current_tips')),
            !(tickets === null || tickets === void 0 ? void 0 : tickets.length) && loading !== 'pending' ? React.createElement(NoTickets, { onClick: onNoTicketsClick }) : null, tickets === null || tickets === void 0 ? void 0 :
            tickets.map(function (ticket) { return (React.createElement(Tip, { key: ticket.id, ticket: ticket, defaultTicketId: defaultTicketId })); }),
            gamesHistory.length && !isGameHistoryLoading ? (React.createElement("div", { ref: archiveRef },
                React.createElement("div", { className: classes.archiveTicketHeader }, t('tip-game:history')),
                gamesHistory.map(function (game, index) { return (React.createElement(ArchiveTicket, { key: index, date: game.validUntil, rank: game.rank, amount: game.statusAmount, archiveTickets: archiveTickets === null || archiveTickets === void 0 ? void 0 : archiveTickets.content, isOpened: index === openedArchiveTicket, isDataLoading: isArchiveTicketsLoading, onClick: function () { return onArchiveTicketClick(game.gameName, game.validUntil, index); }, onScroll: archiveTicketsHandleScroll })); }))) : null),
        loading === 'pending' && React.createElement(FullscreenLoader, null)));
});
