import { createSlice } from '@reduxjs/toolkit';
import { createAsyncReducers } from '~utils/createAsyncReducers';
import { getLocale } from '~slices/locale/thunks';
import { name } from './constants';
var initialState = {
    data: {
        en: {
            countries: [],
            nationalities: [],
            questions: [],
            titles: [],
            defaultCountry: {
                countryCode: '',
                country: '',
                playerAge: 0,
                phoneCode: '',
            },
            registrationBlocks: [[]],
        },
    },
    loading: 'idle',
    error: null,
};
export var locale = createSlice({
    name: name,
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        var _a = createAsyncReducers(), pending = _a.pending, fulfilled = _a.fulfilled, rejected = _a.rejected;
        builder
            .addCase(getLocale.pending, pending)
            .addCase(getLocale.fulfilled, fulfilled)
            .addCase(getLocale.rejected, rejected);
    },
});
