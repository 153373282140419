import makeStyles from '@material-ui/core/styles/makeStyles';
export var useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },
    withdrawType: {
        display: 'flex',
        margin: '20px 0',
        '& > div:first-child': {
            marginRight: '10px',
        },
    },
    congratulationsWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginTop: '40px',
        textAlign: 'center',
        fontSize: '18px',
        fontWeight: 700,
        color: '#FFFFFF',
        '& img:first-child': {
            marginBottom: '25px',
        },
    },
    won: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px',
        '& > div': {
            marginLeft: '5px',
            fontSize: '18px',
            fontWeight: 700,
            color: '#FFFFFF',
        },
    },
    button: {
        fontSize: '16px',
    },
}, { name: 'JackpotBalance' });
