import React, { useMemo } from 'react';
import { betslipDataSelector, updateWager } from '~slices/betslip';
import { useStructuredSelector } from '~hooks';
import { useAppDispatch } from '~store';
import { BETSLIP_SOCKET_NAME } from '~slices/events';
import { KeyboardButton, MinusPlusKeyboardButton, PlusNumberKeyboardButton, } from '~atoms/BetslipKeyboardButtons';
import { useStyles } from './BetslipKeyboard.styles';
export var BetslipKeyboard = function (_a) {
    var inputValue = _a.inputValue, setInputValue = _a.setInputValue, setIsKeyboardDisplay = _a.setIsKeyboardDisplay, setIsWagerLoading = _a.setIsWagerLoading;
    var betslip = useStructuredSelector({ betslip: betslipDataSelector }).betslip;
    var dispatch = useAppDispatch();
    var classes = useStyles();
    var ticketOffer = betslip.to;
    var keyboardNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];
    var topKeyBoardNumbers = [100, 200, 500, 1000];
    var updateWagerFunction = useMemo(function () {
        return function (value) {
            setIsWagerLoading(true);
            dispatch(updateWager({
                wager: value,
                wagerType: 'T',
                eventsType: BETSLIP_SOCKET_NAME,
            })).then(function () { return setIsWagerLoading(false); });
        };
    }, []);
    var onNumberButtonClick = function (num) {
        var newInputValue = inputValue === undefined ? num.toString() : inputValue + num.toString();
        if (newInputValue.charAt(newInputValue.length - 4) === '.') {
            return;
        }
        if (newInputValue.length === 10) {
            return;
        }
        if (newInputValue.slice(-2) === '.0' || newInputValue.slice(-3) === '.00') {
            return setInputValue(newInputValue);
        }
        if (newInputValue === '00') {
            return setInputValue('0');
        }
        if (newInputValue.charAt(0) === '0' && newInputValue.charAt(1) !== '.') {
            setInputValue(num.toString());
            updateWagerFunction(num);
            return;
        }
        updateWagerFunction(Number(newInputValue));
        setInputValue(newInputValue);
    };
    var addNumber = function (num) {
        var _a;
        var value = (_a = ticketOffer === null || ticketOffer === void 0 ? void 0 : ticketOffer.wa) !== null && _a !== void 0 ? _a : 0;
        var newValue = value + num;
        updateWagerFunction(newValue);
        setInputValue(newValue.toString());
    };
    var onBackspaceButtonClick = function () {
        if (!(ticketOffer === null || ticketOffer === void 0 ? void 0 : ticketOffer.wa) && !inputValue) {
            return;
        }
        if ((inputValue === null || inputValue === void 0 ? void 0 : inputValue.slice(-1)) === '.' ||
            (inputValue === null || inputValue === void 0 ? void 0 : inputValue.slice(-2)) === '.0' ||
            (inputValue === null || inputValue === void 0 ? void 0 : inputValue.slice(-3)) === '.00') {
            var newValue = inputValue === null || inputValue === void 0 ? void 0 : inputValue.slice(0, -1);
            return setInputValue(newValue);
        }
        var newInputValue = inputValue ? inputValue === null || inputValue === void 0 ? void 0 : inputValue.slice(0, -1) : '';
        updateWagerFunction(Number(newInputValue));
        if (newInputValue.includes('.')) {
            setInputValue(newInputValue);
            return;
        }
        else {
            setInputValue(newInputValue);
        }
    };
    var onComaButtonClicked = function () {
        if ((!(ticketOffer === null || ticketOffer === void 0 ? void 0 : ticketOffer.wa) && !inputValue) ||
            String(ticketOffer === null || ticketOffer === void 0 ? void 0 : ticketOffer.wa).includes('.') ||
            (inputValue === null || inputValue === void 0 ? void 0 : inputValue.includes('.'))) {
            return;
        }
        var newWagerValue = inputValue
            ? inputValue + '.'
            : String(ticketOffer === null || ticketOffer === void 0 ? void 0 : ticketOffer.wa) + '.';
        setInputValue(newWagerValue);
    };
    var onMinusPlusButtonClick = function (operation) {
        if (operation === 'minus') {
            if (!Number(inputValue)) {
                return;
            }
            else {
                var newInputValue = Number(inputValue) - 1;
                updateWagerFunction(newInputValue);
                setInputValue(newInputValue.toString());
            }
        }
        else {
            var newInputValue = Number(inputValue) ? Number(inputValue) + 1 : 1;
            updateWagerFunction(newInputValue);
            setInputValue(newInputValue.toString());
        }
    };
    return (React.createElement("div", { className: classes.wrapper },
        React.createElement("div", { className: classes.border },
            React.createElement("div", { className: classes.content },
                React.createElement("div", { className: classes.topContent },
                    React.createElement(MinusPlusKeyboardButton, { onMinusClick: function () { return onMinusPlusButtonClick('minus'); }, onPlusClick: function () { return onMinusPlusButtonClick('plus'); } }),
                    topKeyBoardNumbers.map(function (num) { return (React.createElement(PlusNumberKeyboardButton, { count: num, onClick: function () { return addNumber(num); }, key: num })); })),
                React.createElement("div", { className: classes.mainContent },
                    React.createElement("div", { className: classes.numberButtons }, keyboardNumbers.map(function (num) { return (React.createElement(KeyboardButton, { value: num, key: num, onClick: function () { return onNumberButtonClick(num); } })); })),
                    React.createElement("div", null,
                        React.createElement(KeyboardButton, { value: ',', onClick: onComaButtonClicked }),
                        React.createElement(KeyboardButton, { icon: React.createElement("i", { className: "fa fa-backspace" }), onClick: onBackspaceButtonClick })),
                    React.createElement("div", { role: "button", tabIndex: 0, className: classes.okButton, onClick: function () { return setIsKeyboardDisplay(false); } }, "ok"))))));
};
