import makeStyles from '@material-ui/core/styles/makeStyles';
export var useStyles = makeStyles({
    listWrapper: {
        '&:not(:last-child)': {
            marginRight: 20,
        },
    },
    dropdownButton: {
        padding: '0 16px',
        justifyContent: 'space-between',
        width: '176px',
        height: '32px',
        border: '1px solid #414141',
        background: '#323232',
        '& i': {
            color: 'white',
            fontSize: '14px !important',
        },
    },
    button: {
        color: '#fff',
        width: '100%',
        textAlign: 'start',
        fontSize: '16px',
        background: 'transparent',
        lineHeight: '20px',
        border: 'none',
        outline: 'none',
        cursor: 'pointer',
        '& span': {
            fontWeight: 'normal',
            textAlign: 'start',
            display: 'block',
            paddingLeft: '18px',
        },
        '&:hover': {
            background: 'rgba(255, 255, 255, 0.08)',
        },
    },
    buttonRoot: {
        height: '35px',
    },
    activeButton: {
        borderTop: '1px solid #444444',
        borderBottom: '1px solid #444444',
    },
    systemModal: {
        outline: 'none',
        marginTop: '20px',
        color: '#fff',
        position: 'relative',
        background: 'radial-gradient(102.51% 128.71% at 20.97% 0%, #303038 0%, #1B1B22 100%)',
        '&::before': {
            content: '""',
            position: 'absolute',
            right: '20px',
            top: '-16px',
            borderBottom: '8px solid #212128',
            border: '8px solid transparent',
        },
    },
    group: {
        width: '200px',
        padding: '15px 0',
    },
    value: {
        width: '100%',
        maxWidth: '110px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'left',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '18px',
        color: '#FFFFFF',
    },
    selected: {
        background: 'rgba(42,161,247,0.1)',
        color: '#2AA1F7 !important',
        '&:hover': {
            background: 'rgba(42,161,247,0.1)',
        },
    },
    selectedIcon: {
        fontSize: '10px',
        paddingRight: '8px',
        position: 'absolute',
        left: '8px',
        top: '11px',
    },
}, { name: 'MarketFilters' });
