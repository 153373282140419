import React, { useEffect, useRef, useState } from 'react';
import { NavLink as RouterNavLink, useLocation, useRouteMatch, } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import i18next from 'i18next';
import { LOGIN_PRESS, LOGOUT_PRESS } from '~constants/cpa-message-codes';
import { isAuthenticatedSelector, isCookieAcceptedSelector, setIsLogOutMessage, } from '~slices/auth';
import { GAMES_MODE } from '~slices/gameTypes/constants';
import { changeLocalSportId, selectHeaderSportBid, setSportName, sportNameSelector, } from '~slices/options';
import { cashOutDataSelector, loadingCashOutSelector } from '~slices/cashOut';
import { useIsPurchasePendingSelector } from '~slices/tickets';
import { gameIcons } from '~utils/constants/gameIcons';
import { currencyConverter } from '~utils/converters';
import { useAppDispatch } from '~store';
import { usePlatofrmType, useStructuredSelector } from '~hooks';
import TGLogo from '~icons/TG-logo.png';
import smallLogo from '~icons/small-logo.svg';
import { Drawer } from '~mainGrid';
import { Live } from '~atoms/live';
import { SoccerIcon } from '~icons';
import { mapSportIdToSportIcon } from '~slices/sports';
import { MODALS, closeModal, openModal } from '~modals';
import { accountInfoSelector } from '~slices/account';
import { PLATFORMS } from '~slices/teasers';
import { changeHintState } from '~slices/hints';
import { getTestId } from '~utils/getTestId';
import { getPathWithLanguageCode } from '~utils/languageInURL/getPathWithLanguageCode';
import { isBetCheckProject } from '~utils/constants/betCheckConstants';
import { CSIcon } from '~icons/rankingList/CSIcon';
import RankingListIcon from '~icons/rankingList/ranking-list.svg';
import userCircle from '~icons/userCircle.svg';
import { PaymentsURL } from '~pages/payments/payments.routes';
import { useActiveGameSelector, useGameTypesDataSelector, useIsEasyGameSelector, useIsEasyTipSelector, useSelectedGameTypeSelector, useSelectedGroupSelector, useTournamentSelector, } from '~slices/gameTypes';
import { useHeaderStyles } from './Header.styles';
export var NavLink = function (_a) {
    var label = _a.label, to = _a.to, activeOnlyWhenExact = _a.activeOnlyWhenExact, isActive = _a.isActive, icon = _a.icon, className = _a.className, additionalIcon = _a.additionalIcon, state = _a.state, onClick = _a.onClick, dataTestId = _a.dataTestId, hideActiveClass = _a.hideActiveClass;
    var match = useRouteMatch({
        path: to,
        exact: activeOnlyWhenExact,
    });
    var headerClasses = useHeaderStyles();
    var activeClassName = hideActiveClass ? undefined : headerClasses.active;
    return (React.createElement(RouterNavLink, { to: {
            pathname: to ? getPathWithLanguageCode(to) : undefined,
            state: state,
        }, onClick: onClick, activeClassName: activeClassName, "data-testid": getTestId(dataTestId), className: classNames(className, { active: match }, isActive && headerClasses.active, 'flex-row flex-center justify-center') },
        React.createElement("li", { className: "flex-column flex-center" },
            icon,
            label ? (React.createElement("span", { className: classNames(headerClasses.link, headerClasses.linkLabel), style: { marginTop: '4px' } }, label)) : null,
            additionalIcon || null)));
};
export var MobileHeader = function () {
    var _a;
    var dispatch = useAppDispatch();
    var platform = usePlatofrmType();
    var headerClasses = useHeaderStyles();
    var _b = useStructuredSelector({
        accountInfo: accountInfoSelector,
        isAuthenticated: isAuthenticatedSelector,
        isEasyGame: useIsEasyGameSelector,
        isEasyTip: useIsEasyTipSelector,
        isPurchasePending: useIsPurchasePendingSelector,
        cashOutLoadingStatus: loadingCashOutSelector,
        cashoutData: cashOutDataSelector,
        currentSportBId: selectHeaderSportBid,
        cookieAccepted: isCookieAcceptedSelector,
        games: useGameTypesDataSelector,
        activeGame: useActiveGameSelector,
        selectedGroup: useSelectedGroupSelector,
        selectedGameType: useSelectedGameTypeSelector,
        sportName: sportNameSelector,
        tournament: useTournamentSelector,
    }), accountInfo = _b.accountInfo, isAuthenticated = _b.isAuthenticated, isEasyGame = _b.isEasyGame, isEasyTip = _b.isEasyTip, isPurchasePending = _b.isPurchasePending, cashOutLoadingStatus = _b.cashOutLoadingStatus, _c = _b.cashoutData, _d = _c.cashout, cashout = _d === void 0 ? 0 : _d, _e = _c.currency, currency = _e === void 0 ? '' : _e, currentSportBId = _b.currentSportBId, cookieAccepted = _b.cookieAccepted, games = _b.games, activeGame = _b.activeGame, selectedGroup = _b.selectedGroup, selectedGameType = _b.selectedGameType, sportName = _b.sportName, tournament = _b.tournament;
    var location = useLocation();
    var match = useRouteMatch();
    // TODO, change on data from BE
    var isBetCheck = isBetCheckProject;
    var _f = useState({
        cashOutBalance: cashout,
        cashOutCurrency: currency,
    }), localCashout = _f[0], setLocalCashout = _f[1];
    var _g = useState(undefined), gameBalance = _g[0], setGameBalance = _g[1];
    var cashOutBalance = localCashout.cashOutBalance, cashOutCurrency = localCashout.cashOutCurrency;
    var balance = isBetCheck ? cashOutBalance : gameBalance;
    var cashOut = currencyConverter(balance, cashOutCurrency);
    var isTopRankingPage = location.pathname === '/top-ranking';
    var isMyTipsButton = !isBetCheck && !isEasyGame && !isEasyTip;
    var cashOnBalance = ''; // TODO: replace with real data
    var isAuthenticatedWithCashOn = false; // TODO: replace with real data
    var t = useTranslation('header').t;
    var tbj = useTranslation('tip-game').t;
    var _h = React.useState(false), openSidebar = _h[0], setOpenSidebar = _h[1];
    var Icon = mapSportIdToSportIcon[currentSportBId] || SoccerIcon;
    var getIsActiveLink = function (toPath) {
        // "toPath" is the path goal without a slash
        return window.location.pathname.split('/')[2] === toPath;
    };
    var selectedGame = games.find(function (game) {
        return selectedGroup
            ? game.groupName === selectedGroup
            : game.name === activeGame;
    });
    var activeGameIcon = selectedGameType === GAMES_MODE.GROUP
        ? gameIcons[GAMES_MODE.GROUP]
        : (selectedGame === null || selectedGame === void 0 ? void 0 : selectedGame.gamePeriod)
            ? gameIcons[selectedGame === null || selectedGame === void 0 ? void 0 : selectedGame.gamePeriod]
            : null;
    var toTipPath = (tournament === null || tournament === void 0 ? void 0 : tournament.pathname) ||
        getPathWithLanguageCode("/home/all/live/".concat(sportName === null || sportName === void 0 ? void 0 : sportName.name));
    useEffect(function () {
        var balance = (selectedGame === null || selectedGame === void 0 ? void 0 : selectedGame.playerBalance) || (selectedGame === null || selectedGame === void 0 ? void 0 : selectedGame.playerBalance) === 0
            ? selectedGame.playerBalance
            : selectedGame === null || selectedGame === void 0 ? void 0 : selectedGame.startCredits;
        setGameBalance(balance || undefined);
    }, [activeGame, games]);
    useEffect(function () {
        if (cashOutLoadingStatus === 'fulfilled') {
            setLocalCashout({
                cashOutBalance: cashout,
                cashOutCurrency: currency,
            });
        }
    }, [cashOutLoadingStatus]);
    var handleToggle = function () {
        if (platform === PLATFORMS.CROSS_PLATFORM_MOBILE) {
            if (isPurchasePending) {
                dispatch(setIsLogOutMessage(true));
                return;
            }
            // @ts-expect-error
            window.ReactNativeWebView.postMessage(JSON.stringify({
                code: LOGOUT_PRESS,
            }));
        }
        else {
            setOpenSidebar(!openSidebar);
        }
    };
    var closeSideBar = function () {
        setOpenSidebar(false);
    };
    var shouldRedirectToOptions = useRef(false);
    var openLoginModal = function (fromMyBets) {
        if (isAuthenticated) {
            return;
        }
        var pathToRedirect = (shouldRedirectToOptions.current && 'options') ||
            (fromMyBets && 'my-tips/weekly') ||
            (isTopRankingPage && 'top-ranking') ||
            "".concat(isBetCheck ? 'home/all/live' : 'ranking-list');
        if (cookieAccepted) {
            dispatch(openModal({
                modalName: MODALS.LOGIN_MODAL,
                modalProps: {
                    isOpen: true,
                    onClose: function () {
                        shouldRedirectToOptions.current = false;
                        dispatch(closeModal({ key: MODALS.LOGIN_MODAL }));
                    },
                    onSubmit: function () {
                        dispatch(closeModal({ key: MODALS.LOGIN_MODAL }));
                        dispatch(push(getPathWithLanguageCode("".concat(match === null || match === void 0 ? void 0 : match.url).concat(pathToRedirect))));
                        dispatch(changeLocalSportId({
                            id: null,
                            desktop: false,
                            langIso: i18next.language,
                        }));
                        dispatch(setSportName({
                            id: null,
                            name: '',
                        }));
                    },
                },
            }));
        }
        else if (platform === PLATFORMS.CROSS_PLATFORM_MOBILE) {
            // @ts-expect-error
            window.ReactNativeWebView.postMessage(JSON.stringify({
                code: LOGIN_PRESS,
                target: "/".concat(pathToRedirect),
            }));
            shouldRedirectToOptions.current = false;
        }
        else {
            shouldRedirectToOptions.current = false;
            dispatch(changeHintState({ isCookiesConsentHintEnabled: true }));
        }
    };
    var handleOptionsClick = function () {
        if (!isAuthenticated) {
            shouldRedirectToOptions.current = true;
            openLoginModal();
        }
    };
    var handleShouldRedirectOptionsChange = function (shouldRedirect) {
        shouldRedirectToOptions.current = shouldRedirect;
    };
    useEffect(function () {
        if (isAuthenticated && shouldRedirectToOptions.current) {
            shouldRedirectToOptions.current = false;
            setTimeout(function () {
                dispatch(push(getPathWithLanguageCode("".concat(match === null || match === void 0 ? void 0 : match.url, "options"))));
            });
        }
    }, [isAuthenticated]);
    var renderButton = function () {
        if (isAuthenticated) {
            return (React.createElement(NavLink, { to: getPathWithLanguageCode('/top-it-up'), icon: React.createElement("div", { className: classNames('flex-column flex-center justify-center', headerClasses.cashOut) },
                    activeGameIcon && React.createElement("img", { src: activeGameIcon, alt: "active game" }),
                    React.createElement("span", { className: classNames(headerClasses.link, headerClasses.linkValue, 'flex-row flex-center', (location === null || location === void 0 ? void 0 : location.pathname.includes('/payments/')) &&
                            headerClasses.mbMin6), style: { marginTop: 4 } },
                        cashOut,
                        "\u00A0",
                        React.createElement(CSIcon, null)),
                    React.createElement("span", { className: classNames(headerClasses.link, headerClasses.linkLabel) })), className: classNames('cashOut', headerClasses.headerItem, (location === null || location === void 0 ? void 0 : location.pathname.includes('/payments/')) && headerClasses.underline), dataTestId: "BTN_MY_BETS" }));
        }
        if (accountInfo.purchased && isBetCheck) {
            return (React.createElement(NavLink, { label: t('my_bets'), to: getPathWithLanguageCode("/".concat(PaymentsURL.TOP_IT_UP)), hideActiveClass: true, className: headerClasses.headerItem, icon: React.createElement("i", { className: "far fa-receipt" }), dataTestId: "BTN_MY_BETS" }));
        }
        if (isBetCheck && !isAuthenticated) {
            return (React.createElement(NavLink, { label: t('beton'), to: getPathWithLanguageCode('/about'), state: {
                    aboutVideoAutoplay: true,
                }, activeOnlyWhenExact: true, className: headerClasses.headerItem, icon: React.createElement("i", { className: "fas fa-play-circle" }) }));
        }
        return (React.createElement(NavLink, { to: "#", icon: React.createElement("div", { className: classNames('flex-column flex-center justify-center') }, ' '), activeOnlyWhenExact: true, className: classNames('cashOut', headerClasses.headerItem), dataTestId: "BTN_MY_BETS" }));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classNames('relative', headerClasses.headerItem) },
            React.createElement("div", { className: classNames('flex-column flex-center', headerClasses.loginItem) },
                React.createElement("img", { src: isBetCheck ? smallLogo : TGLogo, alt: "logo", className: classNames((_a = {},
                        _a[headerClasses.logo] = isBetCheck,
                        _a[headerClasses.TGLogo] = !isBetCheck,
                        _a[headerClasses.headerBackground] = openSidebar,
                        _a)), onClick: handleToggle, "data-testid": getTestId('HAM_MENU') }),
                !isBetCheck && (React.createElement("span", { className: classNames(headerClasses.link, headerClasses.linkLabel) }, t('menu'))))),
        React.createElement("ul", { className: "navigation-bar space-between" },
            !isBetCheck && (React.createElement(NavLink, { label: t('ranking_list'), to: getPathWithLanguageCode('/ranking-list'), icon: React.createElement("img", { src: RankingListIcon, alt: 'ranking-list icon' }), activeOnlyWhenExact: true, className: classNames('relative', headerClasses.headerItem), dataTestId: "BTJ_RANKING_LIST_MOBILE" })),
            isAuthenticated && isAuthenticatedWithCashOn && (React.createElement(NavLink, { label: t('balance'), to: getPathWithLanguageCode('/balance'), icon: React.createElement("span", { className: classNames(headerClasses.link, headerClasses.linkValue) }, cashOnBalance), activeOnlyWhenExact: true, className: classNames('relative', headerClasses.headerItem), dataTestId: "BTN_BALANCE_MOBILE" })),
            React.createElement(NavLink, { label: t('betting'), to: toTipPath, icon: React.createElement(Icon, { viewBox: "0 0 18 18" }), isActive: getIsActiveLink('home'), className: classNames('relative', headerClasses.headerItem), additionalIcon: React.createElement(Live, { className: headerClasses.topIcon }), dataTestId: "BTN_SELECTION" }),
            isBetCheck && (React.createElement(NavLink, { label: t('options'), to: isAuthenticated ? getPathWithLanguageCode('/options') : undefined, activeOnlyWhenExact: true, className: headerClasses.headerItem, icon: React.createElement("i", { className: "far fa-cog" }), onClick: handleOptionsClick })),
            isMyTipsButton && (React.createElement(NavLink, { label: isAuthenticated ? tbj('my-tips.my_tips') : t('beton'), to: getPathWithLanguageCode(isAuthenticated ? '/my-tips' : '/about'), state: { aboutVideoAutoplay: true }, activeOnlyWhenExact: true, className: headerClasses.headerItem, icon: React.createElement("i", { className: isAuthenticated ? 'far fa-receipt' : 'fas fa-play-circle' }) })),
            isBetCheck && renderButton(),
            !isBetCheck &&
                (!isAuthenticated ? (React.createElement("div", { role: "button", id: "login-button", tabIndex: 0, onClick: function () { return openLoginModal(); }, className: classNames(headerClasses.headerItem, headerClasses.loginMobileButton), "data-testid": getTestId('BTN_LOGIN') },
                    React.createElement("img", { src: userCircle, alt: "user login" }),
                    React.createElement("span", null, t('login')))) : (renderButton()))),
        React.createElement(Drawer, { open: openSidebar, onClose: closeSideBar, onShouldRedirectOptionsChange: handleShouldRedirectOptionsChange })));
};
