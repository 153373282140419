import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from "classnames";
import { PaintedCircle } from "~atoms/WhiteCircle/PaintedCircle";
import { initializeAvatarStyles } from "~pages/ranking-list/constants";
import { useStyles } from './RankingListCartoonNickname.styles';
var mainPath = process.env.REACT_CDN_ORIGIN || '';
export var RankingListCartoonNickname = function (_a) {
    var _b = _a.nickname, nickname = _b === void 0 ? '' : _b, stars = _a.stars, _c = _a.avatar, avatar = _c === void 0 ? 'Joker' : _c, avatarUrl = _a.avatarUrl, isNestedList = _a.isNestedList, isGlobalRanking = _a.isGlobalRanking;
    var classes = useStyles();
    var t = useTranslation('tip-game').t;
    var userAvatar = avatar === null || avatar === void 0 ? void 0 : avatar.toLowerCase();
    var avatarStyles = initializeAvatarStyles(t);
    var userStyle = avatarStyles[userAvatar];
    var specialStyles = avatar === 'gladiator'
        ? { right: '55%' }
        : (userAvatar === null || userAvatar === void 0 ? void 0 : userAvatar.includes('genius')) && !isGlobalRanking
            ? { top: -3 }
            : {};
    var starsValue = stars ? stars : nickname ? 0 : null;
    return (React.createElement("div", { className: classNames(classes.wrapper, isNestedList && classes.nestedWrapper, isGlobalRanking && classes.globalWrapper), style: { background: userStyle === null || userStyle === void 0 ? void 0 : userStyle.background } },
        !isGlobalRanking && (React.createElement("div", { className: classNames(classes.nickname, isNestedList && classes.nestedNickname, !nickname && classes.minHeight20, isNestedList && !nickname && classes.minHeight14) }, nickname)),
        React.createElement("div", { className: 'relative' },
            React.createElement("div", { className: classNames(classes.avatarContainer, isNestedList && classes.nestedAvatarContainer, isGlobalRanking && classes.globalRankingNickname) }),
            React.createElement("div", { className: classNames(classes.avatar, isNestedList && classes.nestedAvatar, isGlobalRanking && classes.globalRankingAvatar), style: specialStyles },
                React.createElement("img", { src: avatarUrl ? "".concat(mainPath).concat(avatarUrl) : '', alt: 'user avatar' }),
                !isGlobalRanking && (React.createElement("span", { style: userAvatar === 'gladiator' ? { textAlign: 'end' } : {} }, userStyle === null || userStyle === void 0 ? void 0 : userStyle.avatarName)))),
        !isGlobalRanking && (React.createElement("div", { className: classNames(classes.stars, isNestedList && classes.nestedStars) }, starsValue || starsValue === 0 ? (React.createElement(PaintedCircle, { paintedCount: starsValue, size: isNestedList ? 'sm' : 'md' })) : null))));
};
