var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
import { createAsyncReducers } from '~utils/createAsyncReducers';
import { getFlags } from './thunks';
import { name } from './constants';
var initialState = {
    data: [],
    meta: {},
    loading: 'idle',
    error: null,
};
export var flags = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        setSelectedFlag: function (state, _a) {
            var payload = _a.payload;
            return __assign(__assign({}, state), { meta: {
                    selectedFlag: payload,
                } });
        },
        resetSelectedFlag: function (state) {
            return __assign(__assign({}, state), { meta: {
                    selectedFlag: undefined,
                } });
        },
        setSelectedTournament: function (state, _a) {
            var payload = _a.payload;
            return __assign(__assign({}, state), { meta: __assign(__assign({}, state.meta), { selectedTournament: payload }) });
        },
    },
    extraReducers: function (builder) {
        var _a = createAsyncReducers(), pending = _a.pending, fulfilled = _a.fulfilled, rejected = _a.rejected;
        builder
            .addCase(getFlags.pending, pending)
            .addCase(getFlags.fulfilled, fulfilled)
            .addCase(getFlags.rejected, rejected);
    },
});
export var setSelectedFlag = (_a = flags.actions, _a.setSelectedFlag), resetSelectedFlag = _a.resetSelectedFlag, setSelectedTournament = _a.setSelectedTournament;
