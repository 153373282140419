import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import Dialog from "@material-ui/core/Dialog/Dialog";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import { closeModal } from "~modals";
import { useModalStyles } from "~modals/components/Modal.styles";
var DeletePurchasedTicketModal = function (_a) {
    var modalName = _a.modalName, _b = _a.isVisible, isVisible = _b === void 0 ? false : _b, _c = _a.modalProps, handleConfirm = _c.handleConfirm, handleClose = _c.handleClose;
    var t = useTranslation(['common', 'tip-game']).t;
    var dispatch = useDispatch();
    var classes = useModalStyles();
    var handleCancel = function () {
        if (handleClose) {
            handleClose();
        }
        dispatch(closeModal({ key: modalName }));
    };
    var onConfirm = function () {
        handleConfirm();
    };
    return (React.createElement(Dialog, { open: isVisible, onClose: handleCancel, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description", className: classes.root },
        React.createElement(DialogContent, { className: classes.dialog },
            React.createElement("div", { className: classes.deleteTicketTitle }, t('tip-game:delete-ticket.title')),
            React.createElement("div", null, t('tip-game:delete-ticket.description')),
            React.createElement("div", { className: classes.deleteTicketActionBlock },
                React.createElement(Button, { fullWidth: true, size: "medium", color: "default", variant: "contained", style: { marginBottom: '32px' }, onClick: handleCancel }, t('common:buttons.cancel')),
                React.createElement(Button, { fullWidth: true, size: "medium", color: "primary", variant: "contained", style: { marginBottom: '32px' }, onClick: onConfirm }, t('common:buttons.continue'))))));
};
export { DeletePurchasedTicketModal };
