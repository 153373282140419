import { useEffect } from 'react';
import { useAutoScroll } from '~hooks';
import { scrollParentAtom } from './scrollParentAtom';
var useTournamentsSelectAutoscroll = function (isEnabled, options) {
    var _a = useAutoScroll(isEnabled, options), elementRef = _a.elementRef, scrollParentRef = _a.scrollParentRef;
    useEffect(function () {
        scrollParentRef.current = scrollParentAtom.getValue();
    }, []);
    return {
        elementRef: elementRef,
    };
};
export { useTournamentsSelectAutoscroll };
