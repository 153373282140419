import makeStyles from '@material-ui/core/styles/makeStyles';
export var useStyles = makeStyles(function (theme) {
    var _a;
    return ({
        paddingBottom: {
            paddingBottom: '3px',
        },
        headerContainer: (_a = {
                userSelect: 'none',
                cursor: 'pointer',
                outline: 'none',
                position: 'fixed',
                bottom: '0',
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                padding: '0 16px',
                zIndex: 2,
                background: 'radial-gradient(100% 100% at 50.13% 0%, #3C3C46 0%, #1B1B22 100%)',
                boxSizing: 'border-box'
            },
            _a[theme.breakpoints.up('lg')] = {
                position: 'relative',
            },
            _a),
        pointsContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 0,
            width: '88px',
            flexWrap: 'wrap',
        },
        betType: {
            fontWeight: 'normal',
            fontSize: '10px',
            color: '#fff',
        },
        currency: {
            fontSize: '12px',
            lineHeight: '18px',
            color: '#fff',
        },
        pointsRow: {
            display: 'flex',
            fontSize: '16px',
            lineHeight: '18px',
            color: '#FFFFFF',
        },
        icon: {
            fontSize: '8px',
            marginRight: '8px',
            color: '#8e8e8e',
        },
        moIcon: {
            color: '#00C150',
            marginRight: '8px',
        },
        active: {
            color: '#2AA1F7',
        },
        bettingSlip: {
            perspective: '200px',
            display: 'inline-block',
            width: '129px',
            margin: '-15px 15px 0 13px',
            '&:before': {
                transform: 'rotateX(0deg)',
                transformOrigin: '50% 100%',
                borderRadius: '25px 25px 0 0',
                height: '100%',
                width: '100%',
                position: 'absolute',
                content: '""',
                backgroundColor: '#151515',
            },
        },
        text: {
            padding: '5px 0 10px',
            position: 'relative',
            fontSize: '10px',
            color: '#2AA1F7',
            left: '30px',
        },
        iconStyles: {
            fontSize: '8px',
            marginLeft: '5px',
        },
        count: {
            fontSize: '16px',
            lineHeight: '18px',
            color: '#FFFFFF',
        },
        paper: {
            opacity: 1,
            zIndex: 2,
            position: 'absolute',
            background: '#151515',
            width: '100%',
            height: 'calc(100vh - 52px)',
            top: '50px',
            '&.closing': {
                transition: 'all .2s',
                opacity: 0,
            },
        },
        rounded: {
            borderRadius: '0',
        },
        capitalize: {
            textTransform: 'capitalize',
        },
    });
}, { name: 'BpListShortcut' });
