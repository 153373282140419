var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button } from '~atoms/button';
import { useAppDispatch } from '~store';
import { addCombination, deleteCombination, selectBetType, selectSelectedCombinationsCount, } from '~slices/betslip';
import { wagerAtoms } from '~utils/wager/wagerAtoms';
import { useAtom } from '~utils/atoms';
import { useStructuredSelector } from '~hooks';
import { CombinationDropDown } from './CombinationDropDown';
import { useStyles } from './CombinationSelect.styles';
var CombinationSelect = function () {
    var t = useTranslation('ticket').t;
    var dispatch = useAppDispatch();
    var anchorRef = React.useRef(null);
    var _a = useAtom(wagerAtoms.isCombinationDDLOpen), open = _a[0], setOpen = _a[1];
    var classes = useStyles();
    var _b = useStructuredSelector({
        selectedCount: selectSelectedCombinationsCount,
        selectedBetType: selectBetType,
    }), selectedBetType = _b.selectedBetType, selectedCount = _b.selectedCount;
    var selectedTitle = selectedCount === 1 &&
        ['SINGLE', 'BANKER_ONLY', 'COMBINATION'].includes(selectedBetType)
        ? t("bet_type.".concat(selectedBetType))
        : t('bet_type.SYSTEM');
    var toggleCombination = function (combination) {
        return combination.ssel
            ? dispatch(addCombination(__assign(__assign({}, combination), { eventsType: 'betSlip' })))
            : dispatch(combination.usel
                ? deleteCombination(__assign(__assign({}, combination), { eventsType: 'betSlip' }))
                : addCombination(__assign(__assign({}, combination), { eventsType: 'betSlip' })));
    };
    var handleClose = function (event) {
        if (anchorRef.current &&
            anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    var handleToggle = function () {
        setOpen(!wagerAtoms.isCombinationDDLOpen.getValue());
    };
    var handleListKeyDown = function (event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { ref: anchorRef, "aria-controls": open ? 'menu-list-grow' : undefined, "aria-haspopup": "true", onClick: handleToggle, className: classNames(classes.button), classes: {
                label: classes.labelButton,
            } },
            React.createElement("div", { className: classNames(classes.systemMultiselect, classes.greenText) }, selectedTitle),
            open ? (React.createElement("i", { className: classNames('far fa-chevron-up', classes.openIcon) })) : (React.createElement("i", { className: classNames('far fa-chevron-down', classes.openIcon) }))),
        React.createElement(CombinationDropDown, { open: open, anchorEl: anchorRef.current, onClose: handleClose, onListKeyDown: handleListKeyDown, onToggleCombination: toggleCombination })));
};
export { CombinationSelect };
