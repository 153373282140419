import React from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from '~modals';
import PasswordRestoreForm from '~pages/passwordRestoreForm/PasswordRestoreForm';
import { EditModalContainer } from './EditModalContainer';
export var RestorePassword = function (_a) {
    var modalName = _a.modalName;
    var dispatch = useDispatch();
    var handleCancel = function () {
        dispatch(closeModal({ key: modalName }));
    };
    return (React.createElement(EditModalContainer, { handleCancel: handleCancel },
        React.createElement(PasswordRestoreForm, { onModalClose: handleCancel })));
};
