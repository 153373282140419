import makeStyles from '@material-ui/core/styles/makeStyles';
export var useStyles = makeStyles(function (theme) { return ({
    root: {
        minWidth: '408px',
        minHeight: '450px',
        background: '#fff',
        '&:first-child': {
            paddingTop: '8px',
        },
    },
    specialRoot: {
        minWidth: '376px',
    },
    dialog: {
        minHeight: '100%',
    },
    modalTitle: {
        padding: '32px 0',
        color: '#212121',
        textAlign: 'center',
        fontSize: '20px',
        lineHeight: '27px',
    },
    title: {
        color: '#212121',
        textAlign: 'center',
        fontSize: '20px',
        paddingBottom: '24px',
    },
    closeIcon: {
        fontSize: '20px',
        background: '#fff',
        color: 'rgba(142, 142, 142, 0.2)',
        position: 'absolute',
        top: '4px',
        right: '6px',
        '& i': {
            fontWeight: 300,
        },
    },
    subheaderSuccessEmailEdit: {
        color: '#212121',
        fontSize: '16px',
        lineHeight: '24px',
        textAlign: 'center',
    },
    editPasswordTitle: {
        paddingBottom: '32px',
        lineHeight: '27px',
    },
    inputClasses: {
        marginBottom: '24px',
    },
    editInputClasses: {
        marginBottom: '8px',
    },
    modalsWidth: {
        maxWidth: '456px !important',
        '& .MuiOutlinedInput-root': {
            border: '1px solid #F2F2F2',
            background: '#F9F9F9',
            color: '#212121',
            boxSizing: 'border-box',
            borderRadius: '2px',
            breakPoint: 'lg',
            '&$error': {
                borderColor: '#FF0000',
            },
        },
        '& .MuiInputBase-input': {
            color: '#212121',
        },
        '& .text-input__title': {
            color: '#212121',
        },
        '& .MuiOutlinedInput-input:-webkit-autofill': {
            '-webkit-box-shadow': '0 0 0 100px #e8f0fe inset',
        },
    },
    specialPaddings: {
        padding: '8px 40px !important',
    },
}); }, { name: 'EditModals' });
