import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ReactGA from 'react-ga4';
import { unwrapResult } from '@reduxjs/toolkit';
import { reset } from 'redux-form';
import ReactPixel from "react-facebook-pixel";
import { useRegisterStyles } from '~pages/registration/RegisterForm.styles';
import { RegisterEmail } from '~pages/registration/components/RegisterEmail';
import { FullscreenLoader, Modal, ProgressBar } from '~atoms';
import { localeDataSelector, localeLoadingSelector } from '~slices/locale';
import { useGAMethod, useRecaptcha, useStructuredSelector } from '~hooks';
import { RegistrationStep } from '~/constants/steps/RegistrationStep';
import { getTestId } from '~utils/getTestId';
import { addExternalScript } from '~utils/addExternalScript';
import { CodeConfirmationModal } from '~view/modals';
import { StepModal } from '~modals/components/registrationModals/StepModal';
import { registration, resetRegistrationErrors } from '~slices/auth';
import { prepareDataForRequest } from '~utils/registration/prepareDataForRequest';
import { useAppDispatch } from '~store';
import { getFromStorageWithLifetime } from '~utils/localStorage';
import { PTAG_KEY } from '~pages/registration/RegisterForm';
import { selectIsModalOpened } from '../selectors';
import { MODALS } from '../modals';
import SuccessRegistrationModal from './registrationModals/SuccessRegistrationModal';
import EmailSentModal from './registrationModals/EmailResentModal';
export var useModalStyles = makeStyles({
    root: {
        background: 'white',
    },
    title: {
        color: '#212121',
    },
    closeIcon: {
        position: 'absolute',
        top: '16px',
        right: '16px',
        background: 'transparent',
        border: 'none',
        outline: 'none',
        cursor: 'pointer',
        fontSize: '20px',
        color: '#8E8E8E',
        opacity: '.5',
        '& i': {
            fontWeight: 100,
        },
    },
    modal: {
        background: 'white',
        color: '#212121',
        padding: '40px',
        boxShadow: '0 0 20px rgba(0, 0, 0, 0.05)',
        borderRadius: '8px',
        width: '100%',
        boxSizing: 'border-box',
        outline: 'none',
        '&.fixedHeight': {
            minHeight: '760px',
        },
        '& .MuiOutlinedInput-root': {
            border: '1px solid #F2F2F2',
            background: '#F9F9F9',
            color: '#212121',
            boxSizing: 'border-box',
            borderRadius: '2px',
            breakPoint: 'lg',
            '&$error': {
                borderColor: '#FF0000',
            },
        },
        '& .MuiInputBase-input': {
            color: '#212121',
        },
        '& .text-input__title': {
            color: '#212121',
        },
        '& .MuiOutlinedInput-input:-webkit-autofill': {
            '-webkit-box-shadow': '0 0 0 100px #e8f0fe inset',
        },
    },
}, { name: 'RegistrationModal' });
var RegistrationModal = function (_a) {
    var _b;
    var _c = _a.modalProps, isOpen = _c.isOpen, onClose = _c.onClose, confirmationCode = _c.confirmationCode, registrationStep = _c.registrationStep;
    var _d = useStructuredSelector({
        isLoading: localeLoadingSelector,
        isRegistrationErrorModalOpened: function (state) {
            return selectIsModalOpened(state, MODALS.REGISTRATION_ERRORS_MODAL);
        },
    }), isLoading = _d.isLoading, isRegistrationErrorModalOpened = _d.isRegistrationErrorModalOpened;
    var registrationBlocks = useStructuredSelector({
        localeData: localeDataSelector,
    }).localeData.registrationBlocks;
    var registerClasses = useRegisterStyles();
    var modalStyles = useModalStyles();
    var methodName = useGAMethod();
    var dispatch = useAppDispatch();
    var _e = useRecaptcha(), token = _e.token, isValidated = _e.isValidated, execute = _e.execute;
    var _f = useStructuredSelector({ localeData: localeDataSelector }).localeData, nationalities = _f.nationalities, titles = _f.titles;
    var t = useTranslation('registration').t;
    var _g = useState(function () {
        var isRegistrationStepValid = typeof registrationStep === 'number' &&
            registrationStep in RegistrationStep;
        return isRegistrationStepValid
            ? registrationStep
            : RegistrationStep.FIRST_STEP;
    }), step = _g[0], setStep = _g[1];
    var _h = useState(''), userEmail = _h[0], setUserEmail = _h[1];
    var numberSteps = registrationBlocks.length;
    var pTag = (_b = getFromStorageWithLifetime(PTAG_KEY)) !== null && _b !== void 0 ? _b : undefined;
    useEffect(function () {
        ReactGA.event('sign_up', {
            method: methodName,
        });
        ReactPixel.trackCustom('sign_up', {
            method: methodName,
        });
    }, []);
    useEffect(function () {
        if (step === RegistrationStep.SECOND_STEP) {
            var scriptBody = "\n        srtmCommands.push({\n          event: \"track.user.registration\",\n          payload: {\n              action: \"start\"\n          }\n        });\n      ";
            addExternalScript({ body: scriptBody });
        }
    }, [step]);
    var nextStep = function () {
        setStep(step + 1);
    };
    var previousStep = function () {
        dispatch(resetRegistrationErrors());
        setStep(step - 1);
    };
    var cancel = function () {
        onClose();
    };
    var renderSuccessRegistrationSteps = function () {
        switch (step) {
            default:
                return null;
            case RegistrationStep.SUCCESS_REGISTRATION:
                return (React.createElement(SuccessRegistrationModal, { t: t, headerClassName: modalStyles.title, subheaderClassName: modalStyles.title, userEmail: userEmail, onClick: function () { return setStep(RegistrationStep.RESEND_EMAIL); } }));
            case RegistrationStep.RESEND_EMAIL:
                return (React.createElement(RegisterEmail, { isDesktop: true, setEmail: function (email) { return setUserEmail(email); }, setStep: function () { return setStep(RegistrationStep.EMAIL_RESENT); }, backClick: function () { return setStep(RegistrationStep.SUCCESS_REGISTRATION); } }));
            case RegistrationStep.EMAIL_RESENT:
                return (React.createElement(EmailSentModal, { t: t, headerClassName: modalStyles.title, subheaderClassName: modalStyles.title, userEmail: userEmail, onClick: function () { return setStep(RegistrationStep.RESEND_EMAIL); } }));
            case RegistrationStep.EMAIL_CONFIRMED:
                return (React.createElement(CodeConfirmationModal, { headerClassName: modalStyles.title, subheaderClassName: modalStyles.title, code: confirmationCode || '', confirmClick: cancel, resendClick: function () { return setStep(RegistrationStep.RESEND_EMAIL); } }));
        }
    };
    var handleClickAway = function () {
        if (!isRegistrationErrorModalOpened) {
            onClose();
        }
    };
    var register = function (data, recaptchaToken) {
        dispatch(registration(prepareDataForRequest({
            data: data,
            nationalities: nationalities,
            titles: titles,
            recaptchaToken: recaptchaToken,
            pTag: pTag,
        })))
            .then(unwrapResult)
            .then(function (_a) {
            var externalId = _a.externalId;
            var scriptComplete = "\n          srtmCommands.push({\n            event: \"track.user.registration\",\n            payload: {\n                action: \"complete\",\n                userId: \"".concat(externalId, "\",\n            }\n          });\n        ");
            if (externalId) {
                addExternalScript({ body: scriptComplete });
            }
        })
            .then(function () {
            dispatch(reset('registration-form'));
            setStep(RegistrationStep.SUCCESS_REGISTRATION);
            setUserEmail(data.email);
        });
    };
    var handleSubmit = function (data) {
        if (registrationBlocks.length === 1 || registrationBlocks.length === step) {
            if (isValidated && token) {
                register(data, token);
                return;
            }
            execute(function (recaptchaToken) {
                register(data, recaptchaToken);
            });
        }
        else {
            nextStep();
        }
    };
    return isLoading === 'pending' ? (React.createElement(FullscreenLoader, null)) : (React.createElement(Modal, { display: isOpen, onClickAway: handleClickAway },
        React.createElement("div", { className: classNames(registerClasses.root, modalStyles.modal, {
                fixedHeight: step < RegistrationStep.SUCCESS_REGISTRATION,
            }) },
            React.createElement("button", { type: "button", onClick: onClose, className: modalStyles.closeIcon },
                React.createElement("i", { className: classNames('far fa-times') })),
            step < RegistrationStep.SUCCESS_REGISTRATION ? (React.createElement(React.Fragment, null,
                React.createElement("h1", { className: classNames(registerClasses.title, modalStyles.title) }, t('header')),
                numberSteps > RegistrationStep.FIRST_STEP && (React.createElement(ProgressBar, { steps: numberSteps, step: step })))) : null,
            React.createElement("div", { className: classNames('flex-auto flex-column', registerClasses.registerFormMain), "data-testid": step < RegistrationStep.SUCCESS_REGISTRATION
                    ? getTestId('FRM_REGISTER')
                    : undefined },
                step <= registrationBlocks.length && (React.createElement(StepModal, { onCancel: onClose, 
                    //@ts-ignore
                    onSubmit: handleSubmit, isDesktop: true, onBack: previousStep, step: step })),
                renderSuccessRegistrationSteps()))));
};
export default RegistrationModal;
