var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Field } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '~atoms';
var renderDatePicker = function (_a) {
    var input = _a.input, meta = _a.meta, title = _a.title, placeholder = _a.placeholder, dateFormat = _a.dateFormat, disablePaste = _a.disablePaste, rest = __rest(_a, ["input", "meta", "title", "placeholder", "dateFormat", "disablePaste"]);
    var t = useTranslation('common').t;
    return (React.createElement(DatePicker, __assign({ title: title, dateFormat: dateFormat }, rest, input, { value: input.value || null, 
        // @ts-ignore
        onChange: function (_, value) { return input.onChange(value); }, error: meta.touched && meta.invalid, helperText: meta.touched ? meta.error : '', placeholder: placeholder || "".concat(t('enter'), " ").concat(title && title.toLocaleLowerCase()) })));
};
export var DatePickerField = function (_a) {
    var name = _a.name, title = _a.title, dateFormat = _a.dateFormat, fullWidth = _a.fullWidth, placeholder = _a.placeholder, required = _a.required, validators = _a.validators;
    return (React.createElement(Field, { name: name, title: title, component: renderDatePicker, dateFormat: dateFormat, fullWidth: fullWidth, required: required, placeholder: placeholder || "Enter ".concat(title && title.toLocaleLowerCase()), validate: validators }));
};
