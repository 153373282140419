import React from 'react';
import classNames from 'classnames';
import { HORIZON_GALLERY_HEIGHT, MOBILE_HORIZON_GALLERY_HEIGHT, } from '~constants/view/sizes';
import { useIsDesktop } from '~hooks';
import { useStyles } from './HorizonGalleryPlaceholder.styles';
var HorizonGalleryPlaceholder = function (_a) {
    var display = _a.display, className = _a.className;
    var classes = useStyles();
    var isDesktop = useIsDesktop();
    if (!display) {
        return null;
    }
    return (React.createElement("div", { className: classNames(classes.container, className), style: {
            height: isDesktop
                ? HORIZON_GALLERY_HEIGHT
                : MOBILE_HORIZON_GALLERY_HEIGHT,
        } }));
};
export { HorizonGalleryPlaceholder };
