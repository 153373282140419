import makeStyles from '@material-ui/core/styles/makeStyles';
var useStyles = makeStyles({
    container: {
        width: '100%',
        height: '50px',
        minHeight: '50px',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '18px 8px 0px 8px',
        boxSizing: 'border-box',
    },
    tab: {
        position: 'relative',
        width: '168px',
        height: '100%',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#212121',
        cursor: 'pointer',
        fontWeight: 700,
        fontSize: '12px',
    },
    active: {
        color: '#2AA1F7',
        '&::after': {
            content: '""',
            position: 'absolute',
            bottom: '0px',
            width: '50px',
            height: '2px',
            backgroundColor: '#2AA1F7',
        },
    },
    disabled: {
        color: '#8E8E8E',
        cursor: 'default',
    },
}, { name: 'Header' });
export { useStyles };
