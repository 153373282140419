import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var ShortTrackIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, viewBox: "0 0 28 28" },
        React.createElement("svg", { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M12.817 0.5H6.50684C7.18849 1.7854 8.56932 4.64835 8.63944 5.8169C8.70955 6.98544 7.35403 11.8477 6.50684 14.6327L11.9406 15.2462C12.7294 12.2664 14.3653 7.70075 14.3653 5.46633C14.3653 4.67756 13.8395 3.18766 12.817 0.5Z", fill: "#80E5FF" }),
            React.createElement("path", { d: "M8 0.5H6.50684C7.18849 1.7854 8.56932 4.64835 8.63944 5.8169C8.70955 6.98544 7.35403 11.8477 6.50684 14.6327H8C8.78877 11.6529 10.2848 8.05131 10.2848 5.8169C10.2848 5.02812 10 3 8 0.5Z", fill: "#64BEF0" }),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M19.4118 23.0281C19.6784 23.1256 19.8243 23.4479 19.7378 23.7481L19.1337 25.842H22.5518L22.5762 25.8447C22.6994 25.8581 22.9232 25.8659 23.1603 25.8404C23.4072 25.8139 23.6113 25.7568 23.7338 25.6802C23.8826 25.5872 24.1362 25.3474 24.4224 25.0376C24.6955 24.7419 24.9578 24.4271 25.1137 24.2303C25.3003 23.9947 25.6211 23.9741 25.8303 24.1842C26.0395 24.3942 26.0578 24.7554 25.8712 24.9909C25.701 25.2058 25.4207 25.5421 25.126 25.8613C24.8443 26.1662 24.5049 26.5052 24.2267 26.6792C23.9222 26.8697 23.553 26.9462 23.2568 26.9781C22.9647 27.0095 22.688 27.0029 22.5041 26.9848H2.5075C2.22722 26.9848 2 26.729 2 26.4134C2 26.0979 2.22722 25.842 2.5075 25.842H5.55322L6.07447 23.4361C6.1409 23.1296 6.41549 22.9416 6.68779 23.0164C6.96009 23.0912 7.12699 23.4004 7.06056 23.707L6.59799 25.842H18.0665L18.7724 23.3951C18.859 23.0949 19.1453 22.9306 19.4118 23.0281Z", fill: "#C2C2C2" }),
            React.createElement("path", { d: "M4.92931 13.9833C5.34999 13.4457 5.9031 13.584 6.12708 13.7203L12.7294 14.5675C12.856 14.6454 13.1734 14.9064 13.4305 15.3271C13.6876 15.7478 13.635 16.0282 13.5766 16.1159C13.5084 16.8365 13.3838 18.1962 13.4305 18.5C13.4772 18.8038 13.9953 18.9204 14.2485 18.8619L19.9452 19.0372C20.2762 19.0957 21.0962 19.3177 21.7272 19.7384C22.516 20.2642 22.7205 22.7474 22.6328 23.0979C22.5627 23.3784 21.6493 23.468 21.2013 23.4777C15.3391 23.4972 3.55043 23.4836 3.29335 23.2732C3.03627 23.0629 2.99147 22.7182 3.00121 22.5721C3.02069 21.5107 3.35436 19 3.6147 18.6574C4.05875 18.0732 4.24766 16.7975 4.28661 16.2327C4.32556 15.7069 4.50864 14.5208 4.92931 13.9833Z", fill: "#FFC764" }),
            React.createElement("path", { d: "M7 20.5C6.72386 20.5 6.5 20.7239 6.5 21C6.5 21.2761 6.72386 21.5 7 21.5L7 20.5ZM9 21.5C9.27614 21.5 9.5 21.2761 9.5 21C9.5 20.7239 9.27614 20.5 9 20.5L9 21.5ZM7 21.5L9 21.5L9 20.5L7 20.5L7 21.5Z", fill: "black" }),
            React.createElement("path", { d: "M3.5 19L3.7764 16.7888C3.92344 15.6124 4.34237 14.4864 5 13.5L12.7808 14.5822L9 16L3.5 19Z", fill: "#FF637D" }))));
};
