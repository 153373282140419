import * as React from 'react';
import classNames from 'classnames';
import './ticket-header.scss';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import AccordionSummary from '@material-ui/core/AccordionSummary/AccordionSummary';
import { useBreakpoint, useIsDesktop, useStructuredSelector } from '~hooks';
import { activeTicketIdSelector, useActiveArchiveTicketSelector } from '~slices/tickets';
import { WonLostIndicator } from '~atoms/WonLostIndicator/WonLostIndicator';
import { currencyConverter } from '~utils/converters';
import { FINISHED_STATUSES } from '~utils/events';
import { TipPayoutStatus } from '~view/pages';
var useStyles = makeStyles({
    root: {
        minHeight: 'auto',
        padding: 0,
        margin: 0,
        background: '#323232',
        borderTopLeftRadius: '4px',
        paddingRight: '8px',
        borderTopRightRadius: '4px',
        '&$expanded': {
            minHeight: 'auto',
            margin: 0,
        },
    },
    '@keyframes goalPaneMoving': {
        '6%': {
            transform: 'scaleX(1) translateY(-50%)',
        },
        '60%': {
            transform: 'scaleX(1) translateY(-50%)',
        },
        '66%': {
            transform: 'scaleX(0) translateY(-50%)',
        },
    },
    '@keyframes nogoalPaneMoving': {
        '5%': {
            transform: 'scaleX(1) translateY(-50%)',
        },
        '60%': {
            transform: 'scaleX(1) translateY(-50%)',
        },
        '95%': {
            transform: 'scaleX(1) translateY(-50%)',
        },
        '100%': {
            transform: 'scaleX(0) translateY(-50%)',
        },
    },
    '@keyframes nocardPaneMoving': {
        '5%': {
            transform: 'scaleX(1) translateY(-50%)',
        },
        '60%': {
            transform: 'scaleX(1) translateY(-50%)',
        },
        '95%': {
            transform: 'scaleX(1) translateY(-50%)',
        },
        '100%': {
            transform: 'scaleX(0) translateY(-50%)',
        },
    },
    '@keyframes textPaneBlinking': {
        '16%': {
            transform: 'scale(1)',
        },
        '26%': {
            transform: 'scale(1)',
        },
        '36%': {
            transform: 'scale(0.72)',
        },
        '46%': {
            transform: 'scale(1)',
        },
        '56%': {
            transform: 'scale(1)',
        },
        '66%': {
            transform: 'scale(0)',
        },
    },
    bsr: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 76,
        height: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#FFFFFF',
        color: '#212121',
        borderRadius: 2,
        opacity: 1,
        transition: 'opacity .5s ease-in-out',
        '&.outlined': {
            outline: '1px solid black',
        },
        '&.active': {
            opacity: 0,
        },
    },
    goalPanel: {
        position: 'absolute',
        top: '50%',
        transform: 'scaleX(0) translateY(-50%)',
        width: 76,
        height: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#2AA1F7',
        color: 'white',
        borderRadius: 2,
        textAlign: 'center',
        animation: "$goalPaneMoving ".concat(process.env.GOAL_ANIMATION_FLASH_DURATION, "s linear ").concat(process.env.GOAL_ANIMATION_FLASHES_COUNT),
        transformOrigin: 'right',
        transition: 'opacity .5s ease-in-out',
        opacity: 1,
        '&.outlined': {
            outline: '1px solid black',
        },
        '&.active': {
            opacity: 0,
        },
    },
    goalPaneText: {
        fontSize: 8,
        lineHeight: 1,
        letterSpacing: '0.4em',
        textTransform: 'uppercase',
        transform: 'scale(0)',
        animation: "$textPaneBlinking ".concat(process.env.GOAL_ANIMATION_FLASH_DURATION, "s linear 3"),
    },
    nogoal: {
        animation: '$nogoalPaneMoving 5s linear 1',
        transform: 'scaleX(0) translateY(-50%)',
        transformOrigin: 'right',
    },
    nocard: {
        animation: '$nocardPaneMoving 5s linear 1',
        transform: 'scaleX(0) translateY(-50%)',
        transformOrigin: 'right',
    },
    bsrText: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: '8px',
        textAlign: 'center',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        padding: '0 3px',
        '&.animation': {
            textTransform: 'uppercase',
            transform: 'scale(0)',
            animation: '$textPaneBlinking 3s linear 3',
        },
    },
    btn: {
        '&:focus': {
            outline: 'none',
        },
    },
    expanded: {
        minHeight: 'auto',
        margin: 0,
    },
    content: {
        margin: 0,
        '&$expanded': {
            minHeight: 'auto',
            margin: 0,
        },
    },
    expandIcon: {
        margin: 0,
        padding: 0,
    },
    expandIconDesktop: {
        margin: 0,
        padding: 0,
        transform: 'rotate(-90deg)',
        '& svg': {
            width: 20,
            height: 26,
        },
    },
    icon: {
        color: '#fff',
        fontSize: '14px',
        padding: 0,
    },
    betType: {
        textTransform: 'capitalize',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    indicators: {
        position: 'relative',
        paddingBottom: '4px',
        paddingRight: '8px',
    },
    desktopGrid: {
        width: '100%',
        flexBasis: '100%',
    },
    letterBlock: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '6px',
        fontSize: '24px',
    },
    ticketHeaderDesktop: {
        position: 'relative',
        outline: 'none',
        '& #panel-header': {
            paddingRight: '15px',
        },
        '& .ticket-header': {
            fontSize: '14px',
            minHeight: '30px',
            padding: '10px 15px',
        },
        '& .bold': {
            marginBottom: '8px',
        },
        '& .ticket-header__main': {
            padding: '15px',
            margin: 0,
            alignItems: 'center',
        },
        '&::before': {
            content: '""',
            position: 'absolute',
            border: '1px solid transparent',
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            borderRadius: '4px',
            pointerEvents: 'none',
            transition: '0.2s ease',
        },
    },
    active: {
        '& #panel-header': {
            background: '#fff',
        },
        '& .ticket-header__header': {
            color: '#212121',
        },
        '&::before': {
            borderColor: '#fff',
        },
        '& svg': {
            color: '#212121',
        },
        '& $expandIconDesktop': {
            transform: 'rotate(90deg)',
        },
    },
    emptyBlock: {
        minWidth: '170px',
    },
    wagerWrapper: {
        paddingLeft: '22px',
        '@media(max-width: 370px)': {
            paddingLeft: '18px',
        }
    },
    right20: {
        right: 20,
    }
}, { name: 'TipHeader' });
export var TipHeader = function (_a) {
    var _b, _c;
    var ticket = _a.ticket, isGoal = _a.isGoal, toggleActiveHandler = _a.toggleActiveHandler;
    var t = useTranslation('ticket').t;
    var isDesktop = useIsDesktop();
    var classes = useStyles();
    var cn = function (className) {
        return classNames('ticket-header', "ticket-header".concat(className));
    };
    var id = ticket.id, betType = ticket.betType, maxOdds = ticket.maxOdds, maxPayout = ticket.maxPayout, mtsTicketId = ticket.mtsTicketId, status = ticket.status, ticketDetails = ticket.ticketDetails, wager = ticket.wager, cashedOutAmount = ticket.cashedOutAmount, cashout = ticket.cashout, winAmount = ticket.winAmount, currency = ticket.currency;
    var _d = useStructuredSelector({
        activeID: activeTicketIdSelector,
        archiveTicket: useActiveArchiveTicketSelector,
    }), activeID = _d.activeID, archiveTicket = _d.archiveTicket;
    var _e = useState({ text: '', type: '' }), bsrToShow = _e[0], setBsrToShow = _e[1];
    var bets = 0;
    var statuses = (_b = ticketDetails === null || ticketDetails === void 0 ? void 0 : ticketDetails.evs) === null || _b === void 0 ? void 0 : _b.map(function (event) {
        var _a;
        // @ts-expect-error
        return Object.values((_a = event.sl) === null || _a === void 0 ? void 0 : _a.sel).map(function (selection) {
            var _a, _b;
            return (_b = (_a = selection === null || selection === void 0 ? void 0 : selection.oc) === null || _a === void 0 ? void 0 : _a.odd) === null || _b === void 0 ? void 0 : _b.st;
        });
    }).flat();
    (_c = ticketDetails === null || ticketDetails === void 0 ? void 0 : ticketDetails.bts) === null || _c === void 0 ? void 0 : _c.map(function (bet) {
        var _a;
        bets += ((_a = bet.otccl) === null || _a === void 0 ? void 0 : _a.length) || 0;
    });
    var convertedMaxPayout = currencyConverter(maxPayout, currency);
    var convertedWagerAmount = currencyConverter(wager, currency);
    var shouldDisplayMaxOddColumn = useBreakpoint(1600);
    var betTypeLetterBackgrounds = {
        SINGLE: '#021A6D',
        COMBINATION: '#41A901',
        SYSTEM: '#EAB704',
        BANKER_ONLY: '#FF8C00',
    };
    useEffect(function () {
        var _a, _b, _c, _d;
        if (status === 'OPEN') {
            var noGoals = (_a = ticketDetails === null || ticketDetails === void 0 ? void 0 : ticketDetails.evs) === null || _a === void 0 ? void 0 : _a.filter(function (e) {
                return e === null || e === void 0 ? void 0 : e.noGoal;
            }).sort(function (a, b) { return Number(a === null || a === void 0 ? void 0 : a.lu) - Number(b === null || b === void 0 ? void 0 : b.lu); });
            var noCards = (_b = ticketDetails === null || ticketDetails === void 0 ? void 0 : ticketDetails.evs) === null || _b === void 0 ? void 0 : _b.filter(function (e) {
                return e === null || e === void 0 ? void 0 : e.noCard;
            }).sort(function (a, b) { return Number(a === null || a === void 0 ? void 0 : a.lu) - Number(b === null || b === void 0 ? void 0 : b.lu); });
            var bsrs = (_c = ticketDetails === null || ticketDetails === void 0 ? void 0 : ticketDetails.evs) === null || _c === void 0 ? void 0 : _c.filter(function (e) {
                var _a, _b;
                return (((_a = e === null || e === void 0 ? void 0 : e.bsr) === null || _a === void 0 ? void 0 : _a.br) !== 'UNDEFINED' &&
                    // @ts-expect-error
                    !FINISHED_STATUSES.includes(e.ms) &&
                    ((_b = e === null || e === void 0 ? void 0 : e.bsr) === null || _b === void 0 ? void 0 : _b.bsrl) &&
                    e.bs);
            }).map(function (e) {
                var _a;
                return {
                    text: (_a = e === null || e === void 0 ? void 0 : e.bsr) === null || _a === void 0 ? void 0 : _a.br,
                    relevance: (new Date().getTime() - Number(e.lu) * 1000) / 1000,
                    ms: e.ms,
                };
            }).sort(function (a, b) { return a.relevance - b.relevance; });
            var hasNoGoal = !!(noGoals === null || noGoals === void 0 ? void 0 : noGoals.filter(function (e) {
                return (e.noGoal && (new Date().getTime() - Number(e === null || e === void 0 ? void 0 : e.lu) * 1000) / 1000 < 10);
            }).length);
            var hasNoCards = !!(noCards === null || noCards === void 0 ? void 0 : noCards.filter(function (e) {
                return (e.noCard && (new Date().getTime() - Number(e === null || e === void 0 ? void 0 : e.lu) * 1000) / 1000 < 10);
            }).length);
            var bsrText = ((_d = bsrs === null || bsrs === void 0 ? void 0 : bsrs.filter(function (e) {
                return e.text;
            })[0]) === null || _d === void 0 ? void 0 : _d.text) || '';
            var bsrToShowType = (hasNoGoal && 'noGoal') ||
                (hasNoCards && 'noCard') ||
                (bsrText && 'bsr') ||
                '';
            if (bsrToShow.type !== 'goal') {
                setBsrToShow({
                    text: bsrToShowType === 'bsr' ? bsrText : '',
                    type: bsrToShowType,
                });
            }
        }
    }, [ticketDetails === null || ticketDetails === void 0 ? void 0 : ticketDetails.evs]);
    useEffect(function () {
        var tId;
        if (bsrToShow.type === 'noGoal' ||
            bsrToShow.type === 'noCard') {
            tId = setTimeout(function () {
                setBsrToShow({ text: '', type: '' });
            }, 10000);
        }
        return function () {
            if (tId) {
                clearTimeout(tId);
            }
        };
    }, [bsrToShow]);
    return (React.createElement("div", { className: classNames(classes.btn, isDesktop && classes.ticketHeaderDesktop, (id === activeID || id === (archiveTicket === null || archiveTicket === void 0 ? void 0 : archiveTicket.id)) && isDesktop && classes.active), onClick: toggleActiveHandler, role: "button", tabIndex: -1 },
        React.createElement(AccordionSummary, { classes: {
                root: classes.root,
                expanded: classes.expanded,
                content: classes.content,
                expandIcon: isDesktop
                    ? classes.expandIconDesktop
                    : classes.expandIcon,
            }, expandIcon: React.createElement(ExpandMoreIcon, { classes: { root: classes.icon } }), "aria-controls": "panel-content", id: "panel-header" },
            React.createElement("header", { className: cn('__header'), style: { padding: isDesktop ? '10px 15px' : '4px 8px' } },
                React.createElement(Grid, { container: true, alignItems: "center", spacing: 1, xs: 12 },
                    React.createElement(Grid, { item: true, xs: 3 },
                        React.createElement("div", { className: classes.betType }, betType === null || betType === void 0 ? void 0 : betType.toLowerCase())),
                    React.createElement(Grid, { item: true, xs: 3 },
                        bets,
                        " ",
                        t('bets', { count: bets })),
                    React.createElement(Grid, { item: true, xs: 3 },
                        React.createElement("div", { className: classes.indicators }, statuses === null || statuses === void 0 ? void 0 :
                            statuses.map(function (statuss, index) { return (React.createElement(WonLostIndicator, { key: (statuss || 'UNABLE') + index, status: statuss, smallCircle: !isDesktop })); }),
                            isGoal && (React.createElement("div", { className: classNames(classes.goalPanel, !isDesktop && classes.right20) },
                                React.createElement("div", { className: classes.goalPaneText }, t('goal')))),
                            bsrToShow.type === 'noCard' && (React.createElement("div", { className: classNames(classes.bsr, classes.nocard, {
                                    animation: false,
                                    outlined: id === activeID,
                                    active: id === activeID,
                                }) },
                                React.createElement("div", { className: classNames(classes.bsrText, {
                                        animation: false,
                                    }) }, t('no_card')))),
                            bsrToShow.type === 'noGoal' && (React.createElement("div", { className: classNames(classes.bsr, classes.nogoal, {
                                    animation: false,
                                    outlined: id === activeID,
                                    active: id === activeID,
                                }) },
                                React.createElement("div", { className: classNames(classes.bsrText, {
                                        animation: false,
                                    }) }, t('no_goal')))),
                            bsrToShow.type === 'bsr' && (React.createElement("div", { className: classNames(classes.bsr, {
                                    animation: false,
                                    outlined: id === activeID,
                                    active: id === activeID,
                                }) },
                                React.createElement("div", { className: classNames(classes.bsrText, {
                                        animation: false,
                                    }) }, t("betstop_reason.".concat(bsrToShow.text))))))),
                    !isDesktop && (React.createElement(Grid, { item: true, xs: 3, className: "max-payout-mobile" },
                        React.createElement("div", { className: "max-payout-block max-payout-mobile__wrapper" },
                            React.createElement("div", { className: "max-payout-block__header" }, t('max_payout')),
                            React.createElement("div", null, convertedMaxPayout))))),
                isDesktop && (React.createElement("div", { className: classes.emptyBlock })))),
        React.createElement("main", { className: cn('__main') },
            React.createElement(Grid, { classes: { root: classes.desktopGrid }, container: true, alignItems: "center", spacing: 1, xs: 12 },
                React.createElement(Grid, { item: true, container: true, alignItems: "center", xs: 3 },
                    React.createElement("div", { className: classes.letterBlock, style: {
                            height: isDesktop ? '56px' : '39px',
                            width: isDesktop ? '96px' : '61px',
                            background: betType ? betTypeLetterBackgrounds[betType === null || betType === void 0 ? void 0 : betType.toUpperCase()] : undefined,
                        } }, betType ? t("bet_type.letter.".concat(betType === null || betType === void 0 ? void 0 : betType.toUpperCase())) : '')),
                React.createElement(Grid, { item: true, xs: 3 },
                    React.createElement("div", { className: !isDesktop ? classes.wagerWrapper : undefined },
                        React.createElement("div", { className: "bold" }, t('wager')),
                        React.createElement("div", null, convertedWagerAmount))),
                shouldDisplayMaxOddColumn && (React.createElement(Grid, { item: true, xs: 3 },
                    React.createElement("div", { className: "bold" }, t('max_odd')),
                    React.createElement("div", null, maxOdds))),
                isDesktop && (React.createElement(Grid, { item: true, xs: 3 },
                    React.createElement("div", { className: "max-payout-block" },
                        React.createElement("div", { className: "bold" }, t('max_payout')),
                        React.createElement("div", null, convertedMaxPayout))))),
            React.createElement(TipPayoutStatus, { ticketId: mtsTicketId, cashedOutAmount: cashedOutAmount, status: status, cashout: cashout, winAmount: winAmount, currency: currency }))));
};
