import React, { useEffect, useState } from 'react';
import { useStyles } from './styles';
export var FullscreenLoader = function (_a) {
    var _b = _a.delay, delay = _b === void 0 ? 0 : _b;
    var classes = useStyles();
    var _c = useState(delay === 0), showLoader = _c[0], setShowLoader = _c[1];
    useEffect(function () {
        var tId;
        tId = setTimeout(function () {
            setShowLoader(true);
        }, delay);
        return function () {
            if (tId) {
                clearTimeout(tId);
            }
        };
    }, []);
    if (!showLoader) {
        return null;
    }
    return (React.createElement("div", { className: classes.loaderWrapper },
        React.createElement("div", { className: classes.dots },
            React.createElement("div", { className: "".concat(classes.dot, " ").concat(classes.firstDot) }),
            React.createElement("div", { className: "".concat(classes.dot, " ").concat(classes.secondDot) }),
            React.createElement("div", { className: "".concat(classes.dot, " ").concat(classes.thirdDot) }))));
};
