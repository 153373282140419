var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Tooltip as MaterialTooltip } from '@material-ui/core';
import { TooltipContent } from './TooltipContent';
import { useStyles } from './Hint.styles';
import { CustomPopper } from './CustomPopper';
var Hint = function (props) {
    var classes = useStyles();
    return (React.createElement(MaterialTooltip, __assign({}, props, { PopperComponent: CustomPopper, arrow: true, classes: {
            arrow: classes.arrow,
            tooltip: classes.tooltip,
        }, title: React.createElement(TooltipContent, null, props.title) })));
};
export { Hint };
