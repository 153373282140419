import { name } from './constants';
export var aboutPageSelector = function (state) { return state[name]; };
export var isPendingSelector = function (state) {
    return aboutPageSelector(state).loading === 'pending';
};
export var isFulfilledSelector = function (state) {
    return aboutPageSelector(state).loading === 'fulfilled';
};
export var isRejectedSelector = function (state) {
    return aboutPageSelector(state).loading === 'rejected';
};
export var aboutPageContent = function (state) {
    return aboutPageSelector(state).data;
};
