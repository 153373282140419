import React, { useEffect, useState } from 'react';
import { useStructuredSelector } from '~hooks';
import { checkIsTournamentGroup } from '~utils/checkIsTournamentGroup';
import { checkIsTournamentInGroup } from '~/utils/checkIsTournamentInGroup';
import { selectSpecifiedActiveTournamentId } from '~slices/router';
import { getTournamentLabel } from '~utils/getTournamentLabel';
import { TournamentLink } from '../TournamentLink';
import { ExpandableItem } from './ExpandableItem';
import { CategoryHeader } from './CategoryHeader';
import { Item } from './Item';
import { ItemsGroup } from './ItemsGroup';
import { scrollParentAtom } from './scrollParentAtom';
var Category = function (_a) {
    var _b = _a.categoryName, categoryName = _b === void 0 ? '' : _b, onSelect = _a.onSelect, tournaments = _a.tournaments, _c = _a.outrights, outrights = _c === void 0 ? false : _c;
    var _d = useState(null), expandedId = _d[0], setExpandedId = _d[1];
    var _e = useState(false), isExpanded = _e[0], setIsExpanded = _e[1];
    var selectedId = useStructuredSelector({
        selectedId: function (state) { return selectSpecifiedActiveTournamentId(state, outrights); },
    }).selectedId;
    var isExpandable = tournaments.length > 10;
    var tournamentsToRender = isExpanded
        ? tournaments
        : tournaments.slice(0, isExpandable ? 9 : 10);
    var handleDotsClick = function () { return setIsExpanded(true); };
    var isCategoryIncludesSelectedId = tournaments.some(function (tn) {
        return checkIsTournamentGroup(tn)
            ? checkIsTournamentInGroup(tn, selectedId)
            : tn.id === selectedId;
    });
    var isRenderedTournamentsIncludesSelectedId = tournamentsToRender.some(function (tn) {
        return checkIsTournamentGroup(tn)
            ? checkIsTournamentInGroup(tn, selectedId)
            : tn.id === selectedId;
    });
    var expandedTournament = tournaments.find(function (tn) { return tn.id === expandedId; });
    var isExpandedTournamentIncludesSelectedId = !!expandedTournament &&
        checkIsTournamentGroup(expandedTournament) &&
        checkIsTournamentInGroup(expandedTournament, selectedId);
    useEffect(function () {
        var scrollParent = scrollParentAtom.getValue();
        if (!expandedId && scrollParent) {
            var initialScroll = scrollParent.scrollTop;
            scrollParent.scrollTop = initialScroll - 1;
            scrollParent.scrollTop = initialScroll;
        }
    }, [expandedId]);
    var renderTournamentLabel = function (tournament) {
        var nm = tournament.nm, id = tournament.id, live = tournament.live, np = tournament.np;
        var label = getTournamentLabel(live, np);
        if (checkIsTournamentGroup(tournament)) {
            return (React.createElement(ExpandableItem, { key: id, expanded: expandedId === id, label: label, autoScrollDelay: !isExpanded, active: checkIsTournamentInGroup(tournament, selectedId) &&
                    expandedId !== id, onClick: function () {
                    if (typeof id !== 'number') {
                        return;
                    }
                    expandedId === id ? setExpandedId(null) : setExpandedId(id);
                } }, nm || ''));
        }
        return (React.createElement(TournamentLink, { key: id, toOutrights: outrights, onSelect: onSelect, tournamentId: id }, function (isSelected) {
            return (React.createElement(Item, { autoScrollDelay: !isExpanded, active: isSelected, label: label }, nm || ''));
        }));
    };
    var renderGRT = function (grt) {
        if (grt === void 0) { grt = []; }
        return (React.createElement(React.Fragment, null, grt === null || grt === void 0 ? void 0 : grt.map(function (_a) {
            var id = _a.id, nm = _a.nm, live = _a.live, np = _a.np;
            return (React.createElement(TournamentLink, { key: id, toOutrights: outrights, onSelect: onSelect, tournamentId: id }, function (isSelected) {
                return (React.createElement(Item, { active: isSelected, small: true, label: getTournamentLabel(live, np) }, nm || ''));
            }));
        })));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(CategoryHeader, { name: categoryName }),
        tournamentsToRender.map(function (tournament, i, a) {
            var prevTournament = a[i - 1];
            var isEven = Boolean(i % 2);
            var isLast = i === a.length - 1;
            return (React.createElement(React.Fragment, null,
                renderTournamentLabel(tournament),
                isExpandable && !isExpanded && isLast && (React.createElement(Item, { key: "dots", autoScrollDelay: true, active: isCategoryIncludesSelectedId &&
                        !isRenderedTournamentsIncludesSelectedId, onClick: handleDotsClick }, "\u2022 \u2022 \u2022")),
                isEven &&
                    prevTournament &&
                    checkIsTournamentGroup(prevTournament) &&
                    prevTournament.id === expandedId && (React.createElement(ItemsGroup, { autoScroll: !isExpandedTournamentIncludesSelectedId, key: "".concat(prevTournament.id, "-subgroup") }, renderGRT(prevTournament.grt))),
                (isEven || isLast) &&
                    checkIsTournamentGroup(tournament) &&
                    tournament.id === expandedId && (React.createElement(ItemsGroup, { autoScroll: !isExpandedTournamentIncludesSelectedId, key: "".concat(tournament.id, "-subgroup") }, renderGRT(tournament.grt)))));
        })));
};
export { Category };
