import makeStyles from '@material-ui/core/styles/makeStyles';
var useStyles = makeStyles({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 16px',
        width: '100%',
        height: 48,
        background: '#151515',
        fontSize: '16px',
        fontWeight: 600,
        color: '#FFF',
        boxSizing: 'border-box',
        zIndex: 12,
    },
    positionFixed: {
        position: 'fixed',
        top: 99,
    },
    buttonsContainer: {
        display: 'flex',
        alignItems: 'center',
        columnGap: 4,
    },
    button: {
        padding: '11px 14px',
        borderRadius: 4,
        background: 'radial-gradient(100% 100% at 50.13% 0%, #3C3C46 0%, #1B1B22 100%)',
        fontSize: 12,
        color: '#FFF',
        cursor: 'pointer',
    },
    dropDownButton: {
        padding: '9px 16px',
        fontSize: 14,
        fontWeight: 500,
        color: '#FFFFFF',
        background: 'radial-gradient(100% 100% at 50.13% 0%, #3C3C46 0%, #1B1B22 100%)',
        borderRadius: 4,
        boxSizing: 'border-box',
        '& span': {
            marginRight: 16,
        },
        '& i': {
            fontSize: 12,
        },
    },
    lastButton: {
        color: '#8e8e8e',
        cursor: 'not-allowed',
    },
    name: {
        padding: '9px 7px',
        fontSize: 14,
        fontWeight: 500,
        color: '#FFFFFF',
        background: 'radial-gradient(100% 100% at 50.13% 0%, #3C3C46 0%, #1B1B22 100%)',
        borderRadius: 4,
        boxSizing: 'border-box',
    },
    ml12: {
        marginLeft: 12,
    },
    mr12: {
        marginRight: 12,
    },
}, { name: 'DateSwitcher' });
export { useStyles };
