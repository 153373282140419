import React from 'react';
import classNames from 'classnames';
import { getOptionName } from '~utils/getOptionName';
import { selectIdsMap } from '~slices/idsMap';
import { useStructuredSelector } from '~hooks';
import { Row } from './Row';
import { MarketsHeader } from './MarketsHeader';
import { getShouldMarketsBeGrouped } from './getShouldMarketsBeGrouped';
var MemoizedRow = React.memo(Row, function (prevProps, nextProps) {
    return (prevProps.defaultMarket === nextProps.defaultMarket &&
        prevProps.eventId === nextProps.eventId &&
        prevProps.eventsType === nextProps.eventsType &&
        prevProps.marketId === nextProps.marketId &&
        prevProps.mk === nextProps.mk &&
        prevProps.optionName === nextProps.optionName &&
        prevProps.rowName === nextProps.rowName &&
        prevProps.tournamentId === nextProps.tournamentId &&
        prevProps.roundIndex === nextProps.roundIndex &&
        prevProps.prevPath === nextProps.prevPath &&
        prevProps.oc.length === nextProps.oc.length &&
        !prevProps.oc.some(function (prevOc, i) {
            var _a, _b;
            var nextOc = nextProps.oc[i];
            return (prevOc.bid !== nextOc.bid ||
                prevOc.id !== nextOc.id ||
                prevOc.nm !== nextOc.nm ||
                ((_a = prevOc.od) === null || _a === void 0 ? void 0 : _a.vl) !== ((_b = nextOc.od) === null || _b === void 0 ? void 0 : _b.vl));
        }));
});
var Group = function (_a) {
    var eventId = _a.eventId, tournamentId = _a.tournamentId, eventsType = _a.eventsType, roundIndex = _a.roundIndex, prevPath = _a.prevPath, ticket = _a.ticket, markets = _a.markets, isDisabled = _a.isDisabled, isMakeTipButton = _a.isMakeTipButton, setIsLoading = _a.setIsLoading;
    var idsMap = useStructuredSelector({
        idsMap: selectIdsMap,
    }).idsMap;
    var sortedMrs = markets
        .sort(function (a, b) {
        if (a.si === 0)
            return 1;
        if (b.si === 0)
            return -1;
        // @ts-expect-error
        return a.si - b.si;
    })
        .sort(function (a, b) {
        if (a.si === b.si) {
            var sfResult = [a.sf, b.sf].sort();
            return sfResult.indexOf(a.sf) === 0 ? -1 : 1;
        }
        return 0;
    });
    var group = function (mrs) {
        var result = [];
        var temp = [];
        mrs.forEach(function (mr) {
            if (result.length === 0 && temp.length === 0) {
                temp.push(mr);
            }
            else if (temp.length !== 0) {
                var prevMr = temp === null || temp === void 0 ? void 0 : temp[temp.length - 1];
                if (getShouldMarketsBeGrouped(idsMap, mr, prevMr)) {
                    temp.push(mr);
                }
                else {
                    result[result.length] = temp;
                    temp = [];
                    temp.push(mr);
                }
            }
        });
        result[result.length] = temp;
        return result;
    };
    var marketGroupsByOutcomeNames = Object.values(group(sortedMrs));
    var getMasterKey = function (mr) {
        return mr.scf ? "".concat(mr.bid, "_").concat(mr.scf) : "".concat(mr.bid);
    };
    return (React.createElement("div", { className: classNames('flex-center') }, marketGroupsByOutcomeNames.map(function (marketSubGroup) {
        var _a, _b;
        var optionName = getOptionName(((_a = marketSubGroup[0].spec) === null || _a === void 0 ? void 0 : _a.lay) || ((_b = marketSubGroup[0]) === null || _b === void 0 ? void 0 : _b.lay));
        return (React.createElement(React.Fragment, { key: "".concat(marketSubGroup[0].nm, " ").concat(getMasterKey(marketSubGroup[0]), " ").concat(optionName) },
            React.createElement(MarketsHeader, { markets: marketSubGroup, optionName: optionName }),
            marketSubGroup.map(function (mr) {
                var _a;
                var optionName = getOptionName(((_a = mr.spec) === null || _a === void 0 ? void 0 : _a.lay) || (mr === null || mr === void 0 ? void 0 : mr.lay));
                return (React.createElement(MemoizedRow, { eventsType: eventsType, mk: getMasterKey(mr), key: "".concat(mr.nm, " ").concat(getMasterKey(mr)), rowName: mr.nm, oc: mr.oc || [], marketId: mr.bid || 0, defaultMarket: mr.dm, tournamentId: tournamentId, eventId: eventId, optionName: optionName, roundIndex: roundIndex, ticket: ticket, prevPath: prevPath, isDisabled: isDisabled, isMakeTipButton: isMakeTipButton, setIsLoading: setIsLoading }));
            })));
    })));
};
export { Group };
