import React from 'react';
import Grid from '@material-ui/core/Grid';
import uniqBy from 'lodash.uniqby';
import { useTranslation } from 'react-i18next';
import { CustomSelectField } from '~molecules';
import { checkIfFieldIsRequired, required, validateField, } from '~utils/formValidators';
import { normalizeTextFieldValue } from '~utils/fieldNormalization';
import { TextField } from '~atoms';
export var Street = function (_a) {
    var tabIndex = _a.tabIndex, registrationBlocks = _a.registrationBlocks, street = _a.street, dataCities = _a.dataCities, searchLocation = _a.searchLocation;
    var t = useTranslation('registration').t;
    return (React.createElement(Grid, { container: true, spacing: 2 },
        React.createElement(Grid, { key: "street", item: true, xs: 8 },
            React.createElement(CustomSelectField, { name: "userProfileDto.addresses.street", title: t('form.home_street'), placeholder: t('form.enter_street'), validators: function (value) {
                    return validateField(value, 'street', tabIndex, required, registrationBlocks);
                }, options: street.length > 1
                    ? uniqBy(dataCities, 'street')
                        .map(function (item) { return item.street; })
                        .filter(function (item) {
                        return item.toLowerCase().startsWith(street.toLowerCase());
                    })
                    : [], onSelect: searchLocation, required: checkIfFieldIsRequired('street', tabIndex, registrationBlocks), normalize: normalizeTextFieldValue })),
        React.createElement(Grid, { key: "homeNumber", item: true, xs: 4 },
            React.createElement(TextField, { name: "userProfileDto.addresses.homeNumber", title: t('form.home_house'), placeholder: t('form.home_house'), validators: function (value) {
                    return validateField(value, 'street', tabIndex, required, registrationBlocks);
                }, required: checkIfFieldIsRequired('street', tabIndex, registrationBlocks), normalize: normalizeTextFieldValue }))));
};
