import makeStyles from '@material-ui/core/styles/makeStyles';
export var useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },
    balanceCardWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        columnGap: '10px',
    },
    currentBalanceAmount: {
        display: 'flex',
        fontSize: '35px',
        color: '#FFFFFF',
        '& div': {
            marginLeft: '5px',
            fontSize: '35px',
        },
    },
    currentBalanceTitle: {
        marginTop: '16px',
        fontSize: '14px',
        color: '#FFFFFF',
    },
    bankDetailsInput: {
        marginTop: '16px',
    },
    fullWidth: {
        width: '100%',
    },
    button: {
        fontSize: '16px',
    },
    passTitle: {
        padding: '16px 24px',
        fontSize: '20px',
    },
    actionsButtons: {
        padding: '0 24px 16px',
    },
}, { name: 'WithdrawTab' });
