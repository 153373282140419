import makeStyles from '@material-ui/core/styles/makeStyles';
var useStyles = makeStyles({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '16px',
    },
    wagerError: {
        marginTop: '4px',
        color: '#FF0000',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '0.4px',
        maxWidth: '164px',
        '& span': {
            marginRight: '3px',
        },
    },
}, { name: 'BetslipWagerArea' });
export { useStyles };
