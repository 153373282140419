import { useSelector } from 'react-redux';
import { name } from './constants';
export var ticketSelector = function (state) { return state[name]; };
export var ticketDataSelector = function (state) {
    return ticketSelector(state).data;
};
var ticketMetaSelector = function (state) {
    return ticketSelector(state).meta.activeArchiveTicket;
};
var purchasedTicketsMetaSelector = function (state) {
    return ticketSelector(state).meta.purchasedTickets;
};
var isPurchasePendingMetaSelector = function (state) {
    return ticketSelector(state).meta.isPurchasePending;
};
export var loadingSelector = function (state) {
    return ticketSelector(state).loading;
};
export var ticketContentSelector = function (state) {
    return ticketDataSelector(state).content;
};
export var ticketsCashedOutSelector = function (state) {
    var _a;
    return (_a = ticketDataSelector(state).content) === null || _a === void 0 ? void 0 : _a.filter(function (ticket) { return ticket.status === 'CASHED_OUT'; }).length;
};
export var activeTicketIdSelector = function (state) {
    return ticketDataSelector(state).activeTicketId;
};
export var useActiveArchiveTicketSelector = function () {
    return useSelector(ticketMetaSelector);
};
export var usePurchasedTicketsSelector = function () {
    return useSelector(purchasedTicketsMetaSelector);
};
export var useIsPurchasePendingSelector = function () {
    return useSelector(isPurchasePendingMetaSelector);
};
/* @deprecated use useStructuredSelector instead */
export var useTicketDataSelector = function () { return useSelector(ticketDataSelector); };
/* @deprecated use useStructuredSelector instead */
export var useLoadingSelector = function () { return useSelector(loadingSelector); };
/* @deprecated use useStructuredSelector instead */
export var useTicketContentSelector = function () {
    return useSelector(ticketContentSelector);
};
/* @deprecated use useStructuredSelector instead */
export var useActiveTicketIdSelector = function () {
    return useSelector(activeTicketIdSelector);
};
