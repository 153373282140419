import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var RampIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0)" },
            React.createElement("path", { d: "M22.7939 20.9802H24.4343V25.1541H22.7939V20.9802Z", fill: "#D8F1FB" }),
            React.createElement("path", { d: "M22.7939 20.9802H24.4343V25.1541H22.7939V20.9802Z", fill: "#0A7598" }),
            React.createElement("path", { d: "M22.7939 5.39648H24.4343V9.57036H22.7939V5.39648Z", fill: "#D8F1FB" }),
            React.createElement("path", { d: "M22.7939 5.39648H24.4343V9.57036H22.7939V5.39648Z", fill: "#0A7598" }),
            React.createElement("path", { d: "M3.56494 13.4622H5.20533V17.636H3.56494V13.4622Z", fill: "#22B4E4" }),
            React.createElement("path", { d: "M18.9927 23.7413C17.9761 22.7247 17.8823 21.108 18.7742 19.9806L21.2339 16.8717C22.7264 14.9852 22.4679 12.2775 20.6453 10.7073L17.8353 8.28676C17.2317 7.76676 16.8829 7.04193 16.8533 6.24579C16.8238 5.44965 17.1177 4.70092 17.6811 4.1375L21.8187 0H12.991L8.91473 3.36318C7.98769 4.12804 7.41673 5.20841 7.30704 6.40523C7.19735 7.60206 7.56239 8.76816 8.33491 9.68881L9.85145 11.4961C10.8171 12.6469 10.7157 14.3538 9.62043 15.3821L4.98517 19.7342C4.17165 20.498 3.67532 21.5312 3.58767 22.6437C3.49996 23.7561 3.82826 24.8544 4.51203 25.7362L6.26741 28H23.2514L18.9927 23.7413Z", fill: "#C8FFFF" }),
            React.createElement("path", { d: "M22.7939 17.062V21.8009H24.679C26.5081 21.8009 27.9962 20.3128 27.9962 18.4837V17.062H22.7939Z", fill: "#FF6326" }),
            React.createElement("path", { d: "M22.7939 1.47803V6.21695H24.679C26.5081 6.21695 27.9962 4.72884 27.9962 2.8997V1.47803H22.7939Z", fill: "#FF6326" }),
            React.createElement("path", { d: "M0.00341797 9.54346V10.9651C0.00341797 12.7943 1.49153 14.2824 3.32067 14.2824H5.20564V9.54346H0.00341797Z", fill: "#FF6326" }),
            React.createElement("path", { d: "M22.7939 17.062V21.8009H24.679C26.5081 21.8009 27.9962 20.3128 27.9962 18.4837V17.062H22.7939Z", fill: "#FF2929" }),
            React.createElement("path", { d: "M22.7939 1.47803V6.21695H24.679C26.5081 6.21695 27.9962 4.72884 27.9962 2.8997V1.47803H22.7939Z", fill: "#FF2929" }),
            React.createElement("path", { d: "M18.9927 23.7413C17.9761 22.7247 17.8823 21.108 18.7743 19.9806L21.2339 16.8717C22.7265 14.9852 22.4679 12.2775 20.6453 10.7073L17.8353 8.28676C17.2317 7.76676 16.8829 7.04193 16.8534 6.24579C16.8238 5.44965 17.1177 4.70092 17.6811 4.1375L21.8187 0H14V28H23.2514L18.9927 23.7413Z", fill: "#9DDFF6" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0" },
                React.createElement("rect", { width: "28", height: "28", fill: "white" })))));
};
