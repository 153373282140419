var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from '@reduxjs/toolkit';
import { getConversionTeaser, getTeasers } from '~slices/teasers/thunks';
import { GALLERY_TYPE, name } from './constants';
var initialState = {
    data: [],
    loading: 'idle',
    error: null,
    isTopTeasersLoadedEmpty: false,
};
export var teasers = createSlice({
    name: name,
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder
            .addCase(getTeasers.pending, function (state) { return (__assign(__assign({}, state), { loading: 'pending' })); })
            .addCase(getTeasers.fulfilled, function (state, _a) {
            var payload = _a.payload;
            return __assign(__assign({}, state), { data: payload, loading: 'fulfilled', isTopTeasersLoadedEmpty: !payload.filter(function (_a) {
                    var slideshowType = _a.slideshowType;
                    return slideshowType !== GALLERY_TYPE;
                }).length });
        })
            .addCase(getConversionTeaser.pending, function (state) { return (__assign(__assign({}, state), { loading: 'pending' })); })
            .addCase(getConversionTeaser.fulfilled, function (state, _a) {
            var payload = _a.payload;
            var id = payload.id, conversionTeaser = payload.conversionTeaser;
            return __assign(__assign({}, state), { data: state.data.map(function (slideshow) {
                    return slideshow.id === id
                        ? __assign(__assign({}, slideshow), { conversionTeaser: conversionTeaser }) : slideshow;
                }) });
        })
            .addCase(getConversionTeaser.rejected, function (state) { return (__assign(__assign({}, state), { loading: 'rejected' })); });
    },
});
