import React, { useCallback, useRef, useState } from 'react';
import { NestedRankingListItem } from './NestedRankingListItem';
import { useStyles } from './NestedRankingListItem.styles';
export var NestedRankingList = function (_a) {
    var rankingList = _a.rankingList, currentUserRank = _a.currentUserRank, place = _a.place, isGroupRanking = _a.isGroupRanking;
    var classes = useStyles();
    var _b = useState(15), visibleCount = _b[0], setVisibleCount = _b[1]; // Initial number of items to render
    var containerRef = useRef(null);
    var handleScroll = useCallback(function () {
        var container = containerRef.current;
        if (container &&
            container.scrollTop + container.clientHeight + 150 >=
                container.scrollHeight) {
            setVisibleCount(function (prevCount) { return prevCount + 1; }); // Increase the number of items to render by 10
        }
    }, []);
    return (React.createElement("div", { ref: containerRef, onScroll: handleScroll, className: classes.listWrapper }, rankingList.slice(0, visibleCount).map(function (item) {
        var isPrizePlace = isGroupRanking &&
            ((item === null || item === void 0 ? void 0 : item.rank) === 1 || (item === null || item === void 0 ? void 0 : item.rank) === 2 || (item === null || item === void 0 ? void 0 : item.rank) === 3);
        var medal = isPrizePlace && place ? place : undefined;
        return (React.createElement("div", { key: item.rank, className: classes.itemWrapper },
            React.createElement(NestedRankingListItem, { balance: item.balance, stars: item.expSubLevel, avatar: item.expLevelName || 'Joker', avatarUrl: item.avatarPath || 'avatars/joker.svg', rank: item.rank, place: medal, userName: item.userName, currentUserRank: currentUserRank })));
    })));
};
