import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cloneDeep from "lodash.clonedeep";
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import values from 'lodash.values';
import i18next from 'i18next';
import { FullscreenLoader } from "~atoms";
import { useIsEasyGameSelector, useIsEasyTipSelector } from "~slices/gameTypes";
import { useAppDispatch } from '~store/configureStore';
import { SIDEBETS_SOCKET_PATH_NAME, SIDEBETS_UPDATES_NAME, activeSidebetIdSelector, matchEventSelector, resetSidebets, } from '~slices/sidebets';
import { useIsDesktop, useStructuredSelector } from '~hooks';
import { bsSelector, getShortcutBetslip, shortcutCountSelector } from '~slices/shortcut';
import { BETSLIP_SOCKET_NAME, connectSocket, resetSidebetsEvents, sendMessage, } from '~slices/events';
import { BettingSlipShortcut } from '~molecules';
import { selectIsIdsMapLoaded } from '~slices/idsMap';
import { dispatchBetSlipWS } from "~utils/dispatchBetSlipWS";
import { isAuthenticatedSelector } from "~slices/auth";
import { Group } from './components/Group';
import { Header } from './components/Header';
import { WidgetTabs } from './components/WidgetTabs';
import { NotAvailableEvent } from './components/NotAvailableEvent';
import { useGroupStyles } from './Sidebets.styles';
var MemoizedGroup = React.memo(Group);
var WEBSOCKET = process.env.REACT_APP_BASE_WEBSOCKET || '';
export var Sidebets = function () {
    var _a;
    var t = useTranslation('ticket').t;
    var dispatch = useAppDispatch();
    var classes = useGroupStyles();
    var isDesktop = useIsDesktop();
    var _b = useStructuredSelector({
        activeID: activeSidebetIdSelector,
        event: matchEventSelector,
        count: shortcutCountSelector,
        isIdsMapLoaded: selectIsIdsMapLoaded,
        isAuthenticated: isAuthenticatedSelector,
        isEasyGame: useIsEasyGameSelector,
        isEasyTip: useIsEasyTipSelector,
        bs: bsSelector,
    }), activeID = _b.activeID, event = _b.event, count = _b.count, isIdsMapLoaded = _b.isIdsMapLoaded, isAuthenticated = _b.isAuthenticated, isEasyGame = _b.isEasyGame, isEasyTip = _b.isEasyTip, bs = _b.bs;
    var id = useParams().id;
    var matchId = id ? Number(id) : activeID;
    var location = useLocation();
    var _c = location.state || {}, roundIndex = _c.round, prevPath = _c.prevPath, isEasyGameLocked = _c.isEasyGameLocked, isMakeTipButton = _c.isMakeTipButton, ticket = _c.ticket;
    var _d = useState(false), openedBetslip = _d[0], setOpenedBetslip = _d[1];
    var _e = useState(false), isDataLoading = _e[0], setIsDataLoading = _e[1];
    var isBettingSlipShortcut = !isDesktop && !isEasyGame && !isEasyTip;
    var onToggleBetslip = function (status) {
        setOpenedBetslip(status);
    };
    useEffect(function () {
        if (matchId) {
            dispatch(connectSocket({
                url: "".concat(WEBSOCKET).concat(SIDEBETS_SOCKET_PATH_NAME),
                langIso: i18next.language,
                eventBetradarId: matchId,
                eventsType: SIDEBETS_UPDATES_NAME,
            }));
            dispatch(getShortcutBetslip());
            if (openedBetslip) {
                setOpenedBetslip(false);
            }
        }
        return function () {
            dispatch({
                type: 'SOCKET_CLOSE_SIDEBET',
                eventsType: SIDEBETS_UPDATES_NAME,
            });
            if (!isDesktop)
                dispatch(resetSidebets());
            dispatch(resetSidebetsEvents());
        };
    }, [matchId]);
    useEffect(function () {
        var tId;
        var shouldReopen = false;
        var handleVisibilityChange = function () {
            if (document.hidden) {
                tId = setTimeout(function () {
                    dispatch({
                        type: 'SOCKET_CLOSE_SIDEBET',
                        eventsType: SIDEBETS_UPDATES_NAME,
                    });
                    shouldReopen = true;
                }, Number(process.env.REACT_APP_SOCKET_BACKGROUND_CLOSE_TIME) * 1000);
            }
            else if (shouldReopen) {
                dispatch(connectSocket({
                    url: "".concat(WEBSOCKET).concat(SIDEBETS_SOCKET_PATH_NAME),
                    langIso: i18next.language,
                    eventBetradarId: matchId,
                    eventsType: SIDEBETS_UPDATES_NAME,
                }));
                shouldReopen = false;
            }
            else {
                clearTimeout(tId);
            }
        };
        if (typeof document.addEventListener === 'undefined') {
            console.log('This page requires a browser, that supports the Page Visibility API.');
        }
        else {
            document.addEventListener('visibilitychange', handleVisibilityChange, false);
        }
        return function () {
            clearTimeout(tId);
            document.removeEventListener('visibilitychange', handleVisibilityChange, false);
        };
    }, []);
    var isFirstRenderRef = useRef(true);
    useEffect(function () {
        if (!isFirstRenderRef.current) {
            dispatch(sendMessage({
                langIso: i18next.language,
                eventBetradarId: matchId,
                eventsType: SIDEBETS_UPDATES_NAME,
            }));
        }
        else {
            isFirstRenderRef.current = false;
        }
    }, [i18next.language]);
    useEffect(function () {
        if (bs) {
            dispatchBetSlipWS(bs);
            return function () {
                dispatch({ type: 'SOCKET_CLOSE', eventsType: BETSLIP_SOCKET_NAME });
            };
        }
    }, [isAuthenticated, bs]);
    var _f = useState(values((event === null || event === void 0 ? void 0 : event.mr) || {})), markets = _f[0], setMarkets = _f[1];
    var updateEvent = useCallback(function () {
        if (!event)
            return {};
        var newEvent = cloneDeep(event);
        var market = (newEvent.mr && (ticket === null || ticket === void 0 ? void 0 : ticket.marketKey)) ? newEvent.mr[ticket === null || ticket === void 0 ? void 0 : ticket.marketKey] : null;
        if (!(market === null || market === void 0 ? void 0 : market.oc))
            return event;
        var outcome = market.oc.find(function (item) { return item.bid === ticket.outcomeBId; });
        if (outcome) {
            outcome.purchased = true;
        }
        return newEvent;
    }, [event, ticket]);
    var eventMarkets = useMemo(function () {
        if (isEasyGame) {
            var updatedEvent = updateEvent();
            return (updatedEvent === null || updatedEvent === void 0 ? void 0 : updatedEvent.mr) || {};
        }
        return (event === null || event === void 0 ? void 0 : event.mr) || {};
    }, [updateEvent, event]);
    useEffect(function () {
        setMarkets(values(eventMarkets));
    }, [eventMarkets]);
    useEffect(function () {
        if (!(event === null || event === void 0 ? void 0 : event.dt))
            return;
        var currentTimestamp = Date.now();
        var eventTimestamp = event.dt * 1000;
        if (eventTimestamp < currentTimestamp)
            return;
        var timeoutDuration = (eventTimestamp - currentTimestamp) + 10000;
        var timeoutId = setTimeout(function () {
            dispatch(sendMessage({
                langIso: i18next.language,
                eventBetradarId: matchId,
                eventsType: SIDEBETS_UPDATES_NAME,
            }));
        }, timeoutDuration);
        return function () { return clearTimeout(timeoutId); };
    }, [event]);
    useEffect(function () {
        var missingMarketsData = markets.find(function (m) { return !m.nm; });
        if (missingMarketsData) {
            dispatch(sendMessage({
                langIso: i18next.language,
                eventBetradarId: matchId,
                eventsType: SIDEBETS_UPDATES_NAME,
            }));
        }
    }, [markets.length]);
    if (!event) {
        return React.createElement(NotAvailableEvent, null);
    }
    var ti = event.ti, c1 = event.c1, c2 = event.c2, tn = event.tn, showMode = event.showMode, dt = event.dt;
    var matchStatus = '';
    switch (showMode) {
        case 'PRE_MATCH':
            matchStatus = 'await';
            break;
        case 'LIVE_MATCH':
            matchStatus = 'active';
            break;
        case 'RESULT':
            matchStatus = 'end';
            break;
        case 'STARTED_BY_DATE_NOT_BOOKED':
        case 'PRE_MATCH_STARTED_BY_DATE':
            matchStatus = 'running';
            break;
        default:
            matchStatus = 'unknown';
    }
    var isGroupDisplay = matchStatus !== 'end' && matchId && !isEasyTip;
    return (React.createElement("div", { style: isDesktop ? { minWidth: '360px' } : {} },
        isDataLoading && React.createElement(FullscreenLoader, null),
        React.createElement("div", { className: "flex-center" },
            React.createElement(Header, { key: activeID, event: event, competitor1: c1, competitor2: c2, time: ti, date: dt, sr: event.sr, sportId: (_a = event === null || event === void 0 ? void 0 : event.sp) === null || _a === void 0 ? void 0 : _a.id, matchStatus: matchStatus }),
            React.createElement("div", null,
                React.createElement(WidgetTabs, null)),
            isGroupDisplay && (React.createElement("div", { className: classNames(count > 0 ? classes.group : undefined) }, markets.length && isIdsMapLoaded ? (React.createElement(MemoizedGroup, { eventsType: SIDEBETS_UPDATES_NAME, markets: markets, tournamentId: (tn === null || tn === void 0 ? void 0 : tn.id) || 0, eventId: matchId, roundIndex: roundIndex, ticket: ticket, prevPath: prevPath, isDisabled: isEasyGameLocked, isMakeTipButton: isMakeTipButton, setIsLoading: setIsDataLoading })) : null))),
        isBettingSlipShortcut && (React.createElement(BettingSlipShortcut, { t: t, openedBetslip: openedBetslip, onToggleBetslip: onToggleBetslip, toRedirectToSidebets: true }))));
};
