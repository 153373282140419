import React from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { Arrows } from './Arrows';
import { useArrowPositionContext } from './ArrowPositionProvider';
import { useStyles } from './TooltipContent.styles';
import { HIGH_ADDITIONAL_DISTANCE_TO_WINDOW_BORDER, HORIZONTAL_PADDING, LOW_ADDITIONAL_DISTANCE_TO_WINDOW_BORDER, } from './constants';
var TooltipContent = function (_a) {
    var children = _a.children;
    var classes = useStyles();
    var left = useArrowPositionContext();
    var theme = useTheme();
    var isLowerThatSM = useMediaQuery(theme.breakpoints.down('sm'));
    var distanceToWindowBorder = isLowerThatSM
        ? LOW_ADDITIONAL_DISTANCE_TO_WINDOW_BORDER
        : HIGH_ADDITIONAL_DISTANCE_TO_WINDOW_BORDER;
    var l = left + HORIZONTAL_PADDING / 2 - distanceToWindowBorder;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classes.container }, children),
        React.createElement("div", { className: classes.arrowsContainer, style: { left: "".concat(l, "px") } },
            React.createElement(Arrows, null))));
};
export { TooltipContent };
