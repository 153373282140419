import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import classNames from 'classnames';
import List from '@material-ui/core/List';
import BetCheckLogo from '~icons/logo.svg';
import TipGameLogo from '~icons/TipGame-logo.png';
import icon1 from '~icons/18+.svg';
import { useAppDispatch } from '~store/configureStore';
import { useBreakpoint, useIsMobileDevise } from '~hooks';
import { changeView } from '~slices/options';
import { getPathWithLanguageCode } from '~utils/languageInURL/getPathWithLanguageCode';
import { isBetCheckProject } from '~utils/constants/betCheckConstants';
import { useFooterStyles } from './Footer.styles';
var betcheckInfoPageLink = process.env.BETCHECK_INFO_PAGE_LINK;
var brandName = process.env.REACT_APP_BRAND_NAME;
var FooterTemp = function () {
    var dispatch = useAppDispatch();
    var footerClasses = useFooterStyles();
    var isMobile = useIsMobileDevise();
    // TODO, change on data from BE
    var isBetCheck = isBetCheckProject;
    var toggleSupport = function () {
        // @ts-ignore
        var supportWidget = window.fcWidget;
        if (supportWidget) {
            supportWidget.init({
                config: {
                    headerProperty: {
                        hideChatButton: true,
                    },
                },
                token: '0e1f1632-f3bb-4edf-8b39-10b1ae087cba',
                host: 'https://wchat.eu.freshchat.com',
            });
            supportWidget.on('widget:closed', supportWidget.destroy);
            supportWidget.open();
        }
    };
    var t = useTranslation('footer').t;
    var LogoClickHandler = function () {
        isBetCheck
            ? dispatch(push(getPathWithLanguageCode('/home')))
            : dispatch(push(getPathWithLanguageCode('/start')));
    };
    var changeToMobile = function () {
        if (isMobile) {
            document.getElementsByTagName('meta').namedItem('viewport').content =
                'width=device-width,initial-scale=1,viewport-fit=cover';
        }
        else {
            dispatch(changeView('mobile'));
        }
    };
    var shouldDisplayLogo = useBreakpoint(isBetCheck ? 1600 : 1635);
    return (React.createElement(React.Fragment, null,
        React.createElement("footer", { className: footerClasses.footer },
            shouldDisplayLogo && (React.createElement("img", { src: isBetCheck ? BetCheckLogo : TipGameLogo, alt: "logo", className: classNames(footerClasses.desktopLogo, !isBetCheck && footerClasses.tipGameDesktopLogo), onClick: LogoClickHandler })),
            React.createElement("div", { className: footerClasses.align },
                React.createElement("div", { className: footerClasses.footerGrid },
                    React.createElement("div", { className: footerClasses.footerColumn },
                        React.createElement("div", { className: footerClasses.footerColumnTitle }, t('information')),
                        React.createElement(List, { disablePadding: true },
                            React.createElement("li", { className: footerClasses.footerColumnLi },
                                React.createElement(NavLink, { to: getPathWithLanguageCode('/about/info/become_player'), exact: true, className: footerClasses.footerColumnLink }, t('become_player'))),
                            React.createElement("li", { className: footerClasses.footerColumnLi },
                                React.createElement(NavLink, { to: getPathWithLanguageCode('/about/info/contact'), exact: true, className: footerClasses.footerColumnLink }, t('contact'))),
                            React.createElement("li", { className: footerClasses.footerColumnLi },
                                React.createElement(NavLink, { to: getPathWithLanguageCode('/about/info/terms_conditions'), exact: true, className: footerClasses.footerColumnLink }, t('terms_conditions'))),
                            React.createElement("li", { className: footerClasses.footerColumnLi },
                                React.createElement(NavLink, { to: getPathWithLanguageCode('/about/info/GDPR'), exact: true, className: footerClasses.footerColumnLink }, t('GDPR'))),
                            React.createElement("li", { className: footerClasses.footerColumnLi },
                                React.createElement(NavLink, { to: getPathWithLanguageCode('/about/info/responsibility'), exact: true, className: footerClasses.footerColumnLink }, t('responsibility'))),
                            React.createElement("li", { className: footerClasses.footerColumnLi },
                                React.createElement(NavLink, { to: getPathWithLanguageCode('/about/info/protection_of_minors'), exact: true, className: footerClasses.footerColumnLink }, t('protection_of_minors'))))),
                    React.createElement("div", { className: footerClasses.footerColumn },
                        React.createElement("div", { className: footerClasses.footerColumnTitle }, t('about_beton')),
                        React.createElement(List, { disablePadding: true },
                            React.createElement("li", { className: footerClasses.footerColumnLi },
                                React.createElement(NavLink, { to: getPathWithLanguageCode("/about/".concat(brandName, "/about_us")), exact: true, className: footerClasses.footerColumnLink }, t('about_us'))),
                            React.createElement("li", { className: footerClasses.footerColumnLi },
                                React.createElement(NavLink, { to: getPathWithLanguageCode("/about/".concat(brandName, "/imprint")), className: footerClasses.footerColumnLink }, t('imprint'))),
                            React.createElement("li", { className: footerClasses.footerColumnLi },
                                React.createElement(NavLink, { to: getPathWithLanguageCode("/about/".concat(brandName, "/company_structure")), exact: true, className: footerClasses.footerColumnLink }, t('company_structure'))),
                            React.createElement("li", { className: footerClasses.footerColumnLi },
                                React.createElement(NavLink, { to: getPathWithLanguageCode("/about/".concat(brandName, "/career")), exact: true, className: footerClasses.footerColumnLink }, t('career'))),
                            React.createElement("li", { className: footerClasses.footerColumnLi },
                                React.createElement("a", { href: brandName, target: "_blank", rel: "noreferrer", className: footerClasses.footerColumnLink }, t('company'))))),
                    React.createElement("div", { className: footerClasses.footerColumn },
                        React.createElement("div", { className: footerClasses.footerColumnTitle }, t('partner_programms')),
                        React.createElement(List, { disablePadding: true },
                            React.createElement("li", { className: footerClasses.footerColumnLi },
                                React.createElement(NavLink, { to: getPathWithLanguageCode('/about/programs/become_affiliate_partner'), exact: true, className: footerClasses.footerColumnLink }, t('become_affiliate_partner'))),
                            React.createElement("li", { className: footerClasses.footerColumnLi },
                                React.createElement(NavLink, { to: getPathWithLanguageCode('/about/programs/become_partner'), exact: true, className: footerClasses.footerColumnLink }, t('become_betting_partner'))))),
                    React.createElement("div", { className: footerClasses.footerColumn },
                        React.createElement("div", { className: footerClasses.footerColumnTitle }, t('find_us_on_social_media')),
                        React.createElement(List, { classes: { root: footerClasses.footerSocialList } },
                            React.createElement("li", { className: footerClasses.footerSocial },
                                React.createElement("a", { target: "_blank", href: "https://www.facebook.com/people/TipGame/61554474497473/", rel: "noreferrer" },
                                    React.createElement("i", { className: "fab fa-facebook-square" }))),
                            React.createElement("li", { className: footerClasses.footerSocial },
                                React.createElement("a", { target: "_blank", href: "https://www.instagram.com/tipgameofficial/", rel: "noreferrer" },
                                    React.createElement("i", { className: "fab fa-instagram" })))),
                        isMobile && (React.createElement("div", { className: footerClasses.toggleBtn, onClick: changeToMobile, role: "button", tabIndex: -1 },
                            React.createElement("i", { className: "far fa-toggle-on fa-rotate-180" }),
                            React.createElement("span", { className: footerClasses.toggleBtnText }, t('mobile_view')))))),
                React.createElement("div", { className: footerClasses.iconListWrap },
                    React.createElement(List, { classes: { root: footerClasses.iconList } },
                        React.createElement("li", { className: footerClasses.iconListItem },
                            React.createElement(NavLink, { to: getPathWithLanguageCode('/about/info/protection_of_minors') },
                                React.createElement("img", { src: icon1, alt: "..." }))),
                        React.createElement("li", { className: footerClasses.iconListItem },
                            React.createElement(NavLink, { to: "/" },
                                React.createElement("i", { className: "fas fa-dice" }))),
                        React.createElement("li", { className: footerClasses.iconListItem },
                            React.createElement(NavLink, { to: "/" },
                                React.createElement("i", { className: "fas fa-file-signature" }))))),
                React.createElement("div", { className: footerClasses.copyBox },
                    React.createElement("div", { className: footerClasses.copyBoxText },
                        t('copyright_title'),
                        " ",
                        new Date().getFullYear(),
                        "."),
                    React.createElement("div", { className: footerClasses.copyBoxText }, t('copyright_text')))))));
};
export var Footer = withRouter(FooterTemp);
