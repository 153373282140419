import React from 'react';
export var WarningIcon = function (_a) {
    var _b = _a.color, color = _b === void 0 ? '#F14A4A' : _b, styles = _a.styles;
    return (React.createElement("svg", { width: "13", height: "12", viewBox: "0 0 13 12", fill: "none", xmlns: "http://www.w3.org/2000/svg", style: styles },
        React.createElement("g", { clipPath: "url(#clip0_6972_13801)" },
            React.createElement("path", { d: "M6.5 1C9.2615 1 11.5 3.2385 11.5 6C11.5 8.7615 9.2615 11 6.5 11C3.7385 11 1.5 8.7615 1.5 6C1.5 3.2385 3.7385 1 6.5 1ZM6.5 2C5.43913 2 4.42172 2.42143 3.67157 3.17157C2.92143 3.92172 2.5 4.93913 2.5 6C2.5 7.06087 2.92143 8.07828 3.67157 8.82843C4.42172 9.57857 5.43913 10 6.5 10C7.56087 10 8.57828 9.57857 9.32843 8.82843C10.0786 8.07828 10.5 7.06087 10.5 6C10.5 4.93913 10.0786 3.92172 9.32843 3.17157C8.57828 2.42143 7.56087 2 6.5 2ZM6.5 7.5C6.63261 7.5 6.75979 7.55268 6.85355 7.64645C6.94732 7.74021 7 7.86739 7 8C7 8.13261 6.94732 8.25979 6.85355 8.35355C6.75979 8.44732 6.63261 8.5 6.5 8.5C6.36739 8.5 6.24021 8.44732 6.14645 8.35355C6.05268 8.25979 6 8.13261 6 8C6 7.86739 6.05268 7.74021 6.14645 7.64645C6.24021 7.55268 6.36739 7.5 6.5 7.5ZM6.5 3C6.63261 3 6.75979 3.05268 6.85355 3.14645C6.94732 3.24021 7 3.36739 7 3.5V6.5C7 6.63261 6.94732 6.75979 6.85355 6.85355C6.75979 6.94732 6.63261 7 6.5 7C6.36739 7 6.24021 6.94732 6.14645 6.85355C6.05268 6.75979 6 6.63261 6 6.5V3.5C6 3.36739 6.05268 3.24021 6.14645 3.14645C6.24021 3.05268 6.36739 3 6.5 3Z", fill: color })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_6972_13801" },
                React.createElement("rect", { width: "12", height: "12", fill: "white", transform: "translate(0.5)" })))));
};
