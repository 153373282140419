import makeStyles from '@material-ui/core/styles/makeStyles';
export var useStyles = makeStyles({
    wrapper: {
        height: '145px',
        width: '100%',
        maxWidth: '123px',
        minWidth: '105px',
        borderRadius: '11px',
        background: 'linear-gradient(270deg, #3C088E -1.63%, #CD0806 135.88%)',
    },
    nickname: {
        padding: '5px 10px',
        textAlign: 'center',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontWeight: 700,
        color: '#FFFFFF',
    },
    avatarContainer: {
        height: '86px',
        background: 'linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.58) 25%, rgba(0, 0, 0, 0.00) 50%, rgba(0, 0, 0, 0.58) 75.52%, #000 100%)',
        opacity: 0.2,
    },
    avatar: {
        position: 'absolute',
        top: 0,
        right: '50%',
        transform: 'translate(50%, 0)',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        fontSize: '12px',
        color: '#FFFFFF',
        '& span': {
            minWidth: '70px',
            textAlign: 'center',
        },
        '& img': {
            maxHeight: '71px',
            maxWidth: '57px',
        },
    },
    stars: {
        height: '28px',
        padding: '0 20px',
        '@media (max-width:376px)': {
            padding: 0,
        },
    },
    minHeight20: {
        minHeight: '20px',
    },
    minHeight14: {
        minHeight: '14px',
    },
    // ========== styles for the nested ranking list ==========
    nestedWrapper: {
        borderRadius: '18px',
        width: '99px',
        height: '111px',
    },
    nestedNickname: {
        padding: '5px 10px',
        textAlign: 'center',
        fontSize: '12px',
    },
    nestedAvatarContainer: {
        height: '63px',
    },
    nestedAvatar: {
        fontSize: '10px',
        '& img': {
            maxHeight: '50px',
        },
    },
    nestedStars: {
        height: '23px',
    },
    // ========== styles for the global ranking list ==========
    globalWrapper: {
        height: '109px'
    },
    globalRankingNickname: {
        width: '123px',
        height: '109px',
        borderRadius: '11px',
    },
    globalRankingAvatar: {
        top: '5px',
        '& img': {
            width: '100%',
            height: '100%',
            maxHeight: '98px',
            maxWidth: '75px',
        },
    },
}, { name: 'RankingListCartoonNickname' });
