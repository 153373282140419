import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { SelectField, TextField } from '~atoms';
import { checkIfFieldIsRequired, minLengthValidation, required, validateField, } from '~utils/formValidators';
import { normalizeTextFieldValue } from '~utils/fieldNormalization';
export var FirstName = function (_a) {
    var titles = _a.titles, tabIndex = _a.tabIndex, registrationBlocks = _a.registrationBlocks;
    var t = useTranslation('registration').t;
    return (React.createElement(Grid, { container: true, spacing: 2 },
        React.createElement(Grid, { key: "title", item: true, xs: 4 },
            React.createElement(SelectField, { name: "userProfileDto.title", title: t('form.title'), placeholder: t('form.defaultPersonTitlePlaceholder'), options: titles, validators: function (value) {
                    return validateField(value, 'name', tabIndex, required, registrationBlocks);
                } })),
        React.createElement(Grid, { key: "firstName", item: true, xs: 8 },
            React.createElement(TextField, { name: "userProfileDto.name", title: t('form.first_name'), placeholder: t('form.enter_first_name'), validators: [
                    function (value) {
                        return validateField(value, 'name', tabIndex, required, registrationBlocks);
                    },
                    function (value) {
                        return validateField(value, 'name', tabIndex, minLengthValidation, registrationBlocks, 50);
                    },
                ], required: checkIfFieldIsRequired('name', tabIndex, registrationBlocks), normalize: normalizeTextFieldValue }))));
};
