import makeStyles from '@material-ui/core/styles/makeStyles';
export var useStyles = makeStyles({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px 0',
        height: 75,
        minHeight: 75,
        overflow: 'hidden',
    },
    fadeIn: {
        animation: '$fadeInAnimation ease-in',
        animationFillMode: 'forwards',
    },
    '@keyframes fadeInAnimation': {
        from: { opacity: 0 },
        to: { opacity: 1 },
    },
}, { name: 'ConversionTeaser' });
