import React, { useState } from 'react';
import classNames from 'classnames';
import { EventButton } from "~atoms/EventButton";
import { useIsEasyGameSelector } from "~slices/gameTypes";
import { normalizeOddOutput } from '~utils/normalizeNumberOutput';
import { noop } from '~utils/noop';
import { useStructuredSelector, useValueChange } from '~hooks';
import { OddWidth } from '~constants/view/OddWidth';
import { DECREASE_ICON, INCREASE_ICON, LOCK_ICON } from './icons';
import { IMG } from './IMG';
import { useStyles } from './OddPlateView.styles';
var Indicators;
(function (Indicators) {
    Indicators["INCREASED"] = "INCREASED";
    Indicators["DECREASED"] = "DECREASED";
    Indicators["NONE"] = "NONE";
})(Indicators || (Indicators = {}));
var OddPlateView = function (_a) {
    var _b;
    var _c = _a.active, active = _c === void 0 ? false : _c, _d = _a.selected, selected = _d === void 0 ? false : _d, isWidget = _a.isWidget, isPurchased = _a.isPurchased, _e = _a.title, title = _e === void 0 ? '' : _e, name = _a.name, value = _a.value, ticketValue = _a.ticketValue, className = _a.className, _f = _a.oddWidth, oddWidth = _f === void 0 ? OddWidth.S : _f, index = _a.index, columnsCount = _a.columnsCount, _g = _a.onClick, onClick = _g === void 0 ? noop : _g;
    var isEasyGame = useStructuredSelector({ isEasyGame: useIsEasyGameSelector }).isEasyGame;
    var classes = useStyles();
    var _h = useState(Indicators.NONE), indicatorToShow = _h[0], setIndicatorToShow = _h[1];
    var isEasyGameView = isEasyGame && !isWidget && active;
    var isEasyGameWidget = isEasyGame && isWidget;
    var isTicketValue = isEasyGame && isPurchased && !!ticketValue;
    useValueChange(function (currentValue, prevValue) {
        setIndicatorToShow(currentValue > prevValue ? Indicators.INCREASED : Indicators.DECREASED);
        var tId = setTimeout(function () {
            setIndicatorToShow(Indicators.NONE);
        }, 3000);
        return function () {
            clearTimeout(tId);
        };
    }, value);
    var renderIndicator = function () {
        switch (indicatorToShow) {
            case Indicators.NONE: {
                return null;
            }
            case Indicators.INCREASED: {
                return (React.createElement(IMG, { key: "INCREASED", className: classes.triangleIncrease, src: INCREASE_ICON }));
            }
            case Indicators.DECREASED: {
                return (React.createElement(IMG, { key: "DECREASED", className: classes.triangleDecrease, src: DECREASE_ICON }));
            }
            default: {
                return null;
            }
        }
    };
    var renderValue = function () {
        var normalizedValue = normalizeOddOutput(value);
        if (isEasyGameView) {
            return React.createElement(EventButton, { value: normalizedValue, name: name || '', columnsCount: columnsCount, isSmall: !!columnsCount && columnsCount > 2 && index === 1 });
        }
        if (title) {
            return (React.createElement("div", { key: "withName", className: classes.content },
                React.createElement("div", { className: classes.title }, title),
                React.createElement("div", { className: classes.score }, normalizedValue)));
        }
        return (React.createElement("div", { key: "noName", className: classes.score }, normalizedValue));
    };
    return (React.createElement("div", { role: "button", tabIndex: 0, style: { width: isEasyGameView ? 'auto' : "".concat(oddWidth, "px") }, className: classNames(classes.root, (_b = {},
            _b[classes.active] = active && selected,
            _b[classes.purchased] = isPurchased && isEasyGameWidget,
            _b[classes.widget] = isEasyGameWidget,
            _b), className), onClick: onClick }, active ? (React.createElement(React.Fragment, null,
        renderIndicator(),
        renderValue(),
        isTicketValue && React.createElement("div", { className: classes.ticketValue }, ticketValue))) : (React.createElement("img", { alt: "lock", src: LOCK_ICON }))));
};
export { OddPlateView };
