import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var BoatIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M13.482 0.765625L4.50684 18.7397H13.4166L13.482 0.765625Z", fill: "#36CFF5" }),
        React.createElement("path", { d: "M14.3862 19.9063H26.0024L14.7513 2.4046C14.6609 2.26382 14.5326 2.15701 14.3862 2.09058V19.9063Z", fill: "#00B7EF" }),
        React.createElement("path", { d: "M14.8202 0.765625H13.9999L13.4165 11.5027L13.9999 22.5859H14.8202V0.765625Z", fill: "#D3D3D8" }),
        React.createElement("path", { d: "M13.1797 0.765625H14V22.5859H13.1797V0.765625Z", fill: "#E2E2E7" }),
        React.createElement("path", { d: "M27.1541 21.5288H14.0001L12.8335 23.7528L14.0001 26.5814H24.7541L27.1541 21.5288Z", fill: "#025B90" }),
        React.createElement("path", { d: "M3.90829 21.5288L1.33691 26.5814H13.9998V21.5288H3.90829Z", fill: "#0279C0" }),
        React.createElement("path", { d: "M27.9999 25.6121H13.9999L13.4165 26.4324L13.9999 27.2527H27.9999V25.6121Z", fill: "#36CFF5" }),
        React.createElement("path", { d: "M0 25.6121H14V27.2527H0V25.6121Z", fill: "#74E0F6" })));
};
