import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var SpecialIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { viewBox: "0 0 28 28", className: className },
        React.createElement("svg", { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("g", { clipPath: "url(#clip0)" },
                React.createElement("path", { d: "M3.26318 4.00964V5.52462C3.26318 5.75267 3.44807 5.93755 3.67612 5.93755C3.90422 5.93755 4.08905 5.75267 4.08905 5.52462V4.00964H3.26318Z", fill: "#655E67" }),
                React.createElement("path", { d: "M24.2399 3.78967V5.30464C24.2399 5.53269 24.4247 5.71758 24.6528 5.71758C24.8808 5.71758 25.0657 5.53269 25.0657 5.30464V3.78967H24.2399Z", fill: "#655E67" }),
                React.createElement("path", { d: "M25.1322 8.91752L24.54 24.872L10.1162 25.508L22.4875 7.65857L23.9007 7.78069L25.1322 8.91752Z", fill: "#FCF5C7", fillOpacity: "0.48" }),
                React.createElement("path", { d: "M23.2889 10.4792L22.8881 24.7346H24.545L25.1321 8.91654L23.8677 7.76413L22.4873 7.65759L22.0199 8.33205C22.8722 8.72428 23.3148 9.54139 23.2889 10.4792Z", fill: "#FAF0B0" }),
                React.createElement("path", { d: "M25.1308 8.91648L25.8794 7.34387C26.227 6.61353 25.9168 5.73965 25.1865 5.39202C24.4562 5.04438 23.5823 5.35463 23.2346 6.08492L22.4861 7.65753L25.1308 8.91648Z", fill: "#8C818F" }),
                React.createElement("path", { d: "M3.53003 8.9716L4.21847 24.6149L10.3387 25.7161L18.1028 24.6149L6.17478 7.71265L4.26059 7.8112L3.53003 8.9716Z", fill: "#FCF5C7", fillOpacity: "0.48" }),
                React.createElement("path", { d: "M6.17349 7.71265L4.76489 8.03451L16.4498 24.6149L17.2559 25.1655L18.1015 24.6149L6.17349 7.71265Z", fill: "#FAF0B0" }),
                React.createElement("path", { d: "M3.52914 8.97239L2.78058 7.39978C2.43294 6.66944 2.74314 5.79556 3.47348 5.44793C4.20382 5.10029 5.0777 5.41054 5.42533 6.14083L6.17389 7.71344L3.52914 8.97239Z", fill: "#8C818F" }),
                React.createElement("path", { d: "M14.6427 15.3362L15.9402 17.9195C15.9745 17.9877 16.0398 18.0351 16.1153 18.0466L18.9731 18.4824C19.1647 18.5116 19.2411 18.7467 19.1032 18.883L17.0473 20.9152C16.993 20.969 16.968 21.0457 16.9804 21.121L17.4491 23.9736C17.4806 24.1648 17.2805 24.3101 17.1084 24.2212L14.5403 22.8939C14.4724 22.8588 14.3918 22.8588 14.3239 22.8939L11.7558 24.2212C11.5837 24.3101 11.3836 24.1648 11.4151 23.9736L11.8838 21.121C11.8962 21.0457 11.8712 20.9689 11.8169 20.9152L9.76103 18.883C9.62322 18.7467 9.69958 18.5116 9.89119 18.4824L12.749 18.0466C12.8245 18.0351 12.8897 17.9877 12.924 17.9195L14.2215 15.3362C14.3085 15.163 14.5557 15.163 14.6427 15.3362Z", fill: "#ECC52E" }),
                React.createElement("path", { d: "M18.9732 18.4824L16.1154 18.0466C16.0399 18.0351 15.9746 17.9877 15.9403 17.9195L14.6428 15.3362C14.5559 15.163 14.3086 15.163 14.2217 15.3362L13.425 16.9224C13.3915 16.989 13.3915 17.0674 13.425 17.134L13.5068 17.297C13.8934 18.0666 14.629 18.601 15.4804 18.7308L15.6952 18.7636C16.4676 18.8814 16.7756 19.8292 16.2199 20.3785L16.0653 20.5312C15.4529 21.1366 15.1719 22.0014 15.3115 22.8512L15.3715 23.2166C15.3836 23.2901 15.4297 23.3535 15.4959 23.3877L17.1085 24.2212C17.2807 24.3102 17.4807 24.1649 17.4493 23.9736L16.9806 21.1211C16.9682 21.0457 16.9931 20.9689 17.0475 20.9153L19.1034 18.883C19.2412 18.7467 19.1648 18.5116 18.9732 18.4824Z", fill: "#DBB72B" }),
                React.createElement("path", { d: "M26.7196 27.2783H1.26589C0.786614 27.2783 0.398071 26.8898 0.398071 26.4105V25.4838C0.398071 25.0045 0.786614 24.616 1.26589 24.616H26.7196C27.1989 24.616 27.5875 25.0045 27.5875 25.4838V26.4105C27.5874 26.8898 27.1989 27.2783 26.7196 27.2783Z", fill: "#C7865B" }),
                React.createElement("path", { d: "M26.7186 24.615H25.0669C25.5462 24.615 25.9347 25.0035 25.9347 25.4828V26.4096C25.9347 26.8888 25.5462 27.2774 25.0669 27.2774H26.7186C27.1979 27.2774 27.5864 26.8888 27.5864 26.4096V25.4828C27.5864 25.0036 27.198 24.615 26.7186 24.615Z", fill: "#C27E4E" }),
                React.createElement("path", { d: "M27.5871 3.27352H26.7364V1.54779H27.5871C27.8152 1.54779 28 1.36291 28 1.13486C28 0.906807 27.8152 0.721924 27.5871 0.721924H0.412933C0.184829 0.721924 0 0.906807 0 1.13486C0 1.36291 0.184829 1.54779 0.412933 1.54779H1.26363V3.27352H0.412933C0.184829 3.27352 0 3.4584 0 3.68645C0 3.9145 0.184829 4.09938 0.412933 4.09938H27.5871C27.8152 4.09938 28 3.9145 28 3.68645C28 3.4584 27.8151 3.27352 27.5871 3.27352ZM25.9104 3.27352H24.2717V1.54779H25.9105V3.27352H25.9104ZM7.01882 1.54779H8.65766V3.27352H7.01882V1.54779ZM6.19295 3.27352H4.55421V1.54779H6.19301V3.27352H6.19295ZM9.48353 1.54779H11.1224V3.27352H9.48353V1.54779ZM11.9482 1.54779H13.5871V3.27352H11.9482V1.54779ZM14.4129 1.54779H16.0518V3.27352H14.4129V1.54779ZM16.8776 1.54779H18.5165V3.27352H16.8776V1.54779ZM19.3423 1.54779H20.9812V3.27352H19.3423V1.54779ZM21.807 1.54779H23.4458V3.27352H21.807V1.54779ZM2.0895 1.54779H3.72835V3.27352H2.0895V1.54779Z", fill: "#C8C3CA" })),
            React.createElement("defs", null,
                React.createElement("clipPath", { id: "clip0" },
                    React.createElement("rect", { width: "28", height: "28", fill: "white" }))))));
};
