import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import MuiListItem from '@material-ui/core/ListItem/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { getTestId } from '~utils/getTestId';
import { getPathWithLanguageCode } from '~utils/languageInURL/getPathWithLanguageCode';
var useStyles = makeStyles({
    listItemText: {
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
    },
    listItem: {
        height: '48px',
        margin: '10px 0px 10px 10px',
        color: '#fff',
    },
    listItemIcon: {
        fontSize: '16px',
        lineHeight: '16px',
        width: '40px',
        minWidth: '40px',
        color: '#fff',
        '& i': {
            fontSize: '16px',
        }
    },
    cashonIcon: {
        display: 'flex',
        alignItems: 'flex-end',
    },
}, { name: 'ListItem' });
export var ListItem = function (_a) {
    var icon = _a.icon, isAuthenticated = _a.isAuthenticated, requiredAuth = _a.requiredAuth, label = _a.label, to = _a.to, textComponent = _a.textComponent, _b = _a.disableRedirect, disableRedirect = _b === void 0 ? false : _b, dataTestId = _a.dataTestId, selected = _a.selected, onClick = _a.onClick;
    var t = useTranslation('header').t;
    var classes = useStyles();
    var pathname = useLocation().pathname;
    var isSelected = selected !== undefined ? selected : pathname === to;
    var handleClick = function (e) {
        if (disableRedirect) {
            e.preventDefault();
        }
        if (onClick) {
            onClick();
        }
    };
    if (requiredAuth && !isAuthenticated) {
        return null;
    }
    return (React.createElement(MuiListItem, { classes: { root: classes.listItem }, component: NavLink, to: getPathWithLanguageCode(to !== null && to !== void 0 ? to : '/'), disableGutters: true, selected: isSelected, onClick: handleClick, "data-testid": getTestId(dataTestId) },
        React.createElement(ListItemIcon, { classes: { root: classes.listItemIcon } }, icon),
        textComponent ? (React.createElement(ListItemText, { classes: { primary: classes.cashonIcon } }, textComponent)) : (React.createElement(ListItemText, { classes: { primary: classes.listItemText }, primary: typeof label === 'string' ? t(label) : '' }))));
};
