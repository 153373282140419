import React from 'react';
import qs from 'qs';
import { push } from 'connected-react-router';
import { confirmUpdateEmail } from '~slices/account';
import { useAppDispatch } from '~store';
import { getPathWithLanguageCode } from '~utils/languageInURL/getPathWithLanguageCode';
export var ConfirmationLink = function (_a) {
    var location = _a.location;
    var dispatch = useAppDispatch();
    var code = qs.parse(location.search, {
        ignoreQueryPrefix: true,
    }).code;
    React.useEffect(function () {
        if (code) {
            dispatch(confirmUpdateEmail({ code: code })).then(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            function (_a) {
                var payload = _a.payload;
                if (payload) {
                    dispatch(push(getPathWithLanguageCode('/my-account/email/updated')));
                }
            });
        }
    }, [code]);
    return React.createElement(React.Fragment, null);
};
