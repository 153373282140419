import React, { useCallback } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { currencyConverter } from '~utils/converters';
import { maxOddBetslipDataSelector } from '~slices/betslip';
import { useStructuredSelector } from '~hooks';
import { useStyles } from './BpListShortcut.style';
import { shortcutDataSelector } from '~slices/shortcut';
export var generate = function (element, length) {
    return Array(length)
        .fill(1)
        .map(function (item, i) {
        return React.cloneElement(element, { key: "".concat(new Date().getTime() * i, ".short") });
    });
};
export var BpListShortcut = function (_a) {
    var _b, _c;
    var count = _a.count, handleClick = _a.handleClick, open = _a.open, wrapperClass = _a.wrapperClass, betType = _a.betType, t = _a.t, mp = _a.mp, flipIcon = _a.flipIcon, currency = _a.currency;
    var classes = useStyles();
    var history = useHistory();
    var _d = useStructuredSelector({
        mo: maxOddBetslipDataSelector,
        shortCut: shortcutDataSelector,
    }), mo = _d.mo, shortCut = _d.shortCut;
    var dots = count && count < 11 ? (generate(React.createElement("i", { className: "fas fa-circle ".concat(classes.icon) }), count)) : (React.createElement(React.Fragment, null,
        React.createElement("i", { className: "fas fa-circle ".concat(classes.icon) }),
        React.createElement("span", { className: classes.count }, count || '')));
    var convertedMP = currencyConverter(mp, currency);
    var arrowIcon = useCallback(function () {
        var isFlipStatus = flipIcon ? !open : open;
        var arrowDirection = isFlipStatus
            ? 'fa-chevron-double-down'
            : 'fa-chevron-double-up';
        return React.createElement("i", { className: "far ".concat(arrowDirection, " ").concat(classes.iconStyles) });
    }, [open]);
    return (React.createElement("div", { className: classNames(classes.headerContainer, wrapperClass), style: {
            paddingBottom: history.location.pathname !== '/top-ranking'
                ? 'env(safe-area-inset-bottom)'
                : '',
            opacity: open && history.location.pathname.includes('home') ? 0 : 1,
        }, onClick: handleClick, role: "button", tabIndex: 0 },
        React.createElement("div", { className: classNames(classes.pointsContainer, (_b = {},
                _b[classes.betType] = betType,
                _b)) }, betType ? (React.createElement("div", { className: classes.capitalize }, t("bet_type.".concat(betType)))) : (dots)),
        React.createElement("div", null,
            React.createElement("div", { className: classes.bettingSlip },
                React.createElement("div", { className: classes.text },
                    React.createElement("span", null, t('betting_slip')),
                    arrowIcon()))),
        React.createElement("div", { className: classes.pointsContainer },
            React.createElement("div", { className: classNames(classes.pointsRow, classes.paddingBottom, (_c = {},
                    _c[classes.currency] = mp,
                    _c)) }, mp ? (React.createElement(React.Fragment, null, convertedMP)) : (React.createElement(React.Fragment, null,
                React.createElement("i", { className: classNames('far fa-filter', classes.moIcon) }),
                mo || shortCut.mo))))));
};
