import makeStyles from '@material-ui/core/styles/makeStyles';
export var useRegisterStyles = makeStyles(function (theme) { return ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '32px 16px',
        background: 'radial-gradient(100% 100% at 50.13% 0%, #303038 0%, #1B1B22 100%)',
        color: '#ffffff',
        flex: '1 1 100%',
        '&.desktop': {
            background: 'transparent',
            padding: 0,
        },
    },
    title: {
        fontWeight: 300,
        fontSize: '20px',
        lineHeight: '23px',
        textAlign: 'center',
        color: '#f2f2f2',
        marginBottom: '24px',
        letterSpacingL: '0.005em',
        '&.desktop': {
            color: '#212121',
            fontWeight: 'bold',
        },
    },
    registerFormMain: {
        width: '100%',
    },
    collapseContent: {
        marginLeft: '27px',
    },
    emailForm: {
        width: '100%',
        alignSelf: 'stretch',
        alignItems: 'stretch',
        flex: '1 1 100%',
    },
    formContainer: {
        flex: 1,
        '&.desktop': {
            minHeight: '250px',
        },
    },
    link: {
        color: '#2AA1F7',
    },
    button: {
        width: 120,
    },
    dark: {
        color: '#FFFFFF',
        '& .MuiCheckbox-root': {
            color: '#FFFFFF',
        },
    },
}); }, { name: 'RegisterForm' });
