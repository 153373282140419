import makeStyles from '@material-ui/core/styles/makeStyles';
export var useStyles = makeStyles({
    wrapper: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    circle: {
        width: '11px',
        height: '11px',
        border: '0.5px solid #FFFFFF',
        borderRadius: '50%',
        marginRight: '3.35px',
        '&:last-child': {
            marginRight: 0,
        }
    },
    small: {
        width: '6px',
        height: '6px',
    }
}, { name: 'PaintedCircle' });
