import React, { useState } from 'react';
import { useStyles } from './PINInput.styles';
export var PINInput = function (_a) {
    var label = _a.label, placeholder = _a.placeholder, onInputChange = _a.onInputChange;
    var classes = useStyles();
    var reg = /^[0-9]{1,10}$/;
    var _b = useState(''), inputValue = _b[0], setInputValue = _b[1];
    var onChange = function (event) {
        var value = event.target.value;
        if (value.match(reg) || value === '') {
            setInputValue(value);
            onInputChange(value);
        }
    };
    return (React.createElement("div", { className: classes.inputWrapper },
        React.createElement("label", { className: classes.label }, label),
        React.createElement("input", { value: inputValue, placeholder: placeholder, className: classes.input, onInput: onChange, autoComplete: "off", 
            // onKeyDown={onKeyDown}
            type: "text" })));
};
