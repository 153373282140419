import React from 'react';
import { useTranslation } from 'react-i18next';
import { SelectField } from '~atoms';
import { checkIfFieldIsRequired, required, validateField, } from '~utils/formValidators';
export var NationalityId = function (_a) {
    var tabIndex = _a.tabIndex, registrationBlocks = _a.registrationBlocks, nationalities = _a.nationalities;
    var t = useTranslation('registration').t;
    return (React.createElement(SelectField, { name: "userProfileDto.nationalityId", title: t('form.nationality'), placeholder: t('form.select_nationality'), options: nationalities, validators: function (value) {
            return validateField(value, 'nationalityId', tabIndex, required, registrationBlocks);
        }, required: checkIfFieldIsRequired('nationalityId', tabIndex, registrationBlocks) }));
};
