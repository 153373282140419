import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var NordicCombinerIcons = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, viewBox: "0 0 28 28" },
        React.createElement("svg", { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("g", { clipPath: "url(#clip0)" },
                React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M14 11.5L11.7631 20.0859L10.5 19.5L11.7631 15L12.35 11.3L14 11.5Z", fill: "#328777" }),
                React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M14.7 11.8L13.0147 19.1161L11.5 19L12.4 15.3297V11.3L14.7 11.8Z", fill: "#4BCDB2" }),
                React.createElement("path", { d: "M12.8143 20.6738L9.96342 22.349L10.5 19.5L11.5 19L12.8143 20.6738Z", fill: "#1F544A" }),
                React.createElement("path", { d: "M15.6144 18.7849L8.60605 22.9029L9.2624 24.0939L16.336 19.8956L15.6144 18.7849Z", fill: "#FFD422" }),
                React.createElement("path", { d: "M18.5208 5.61824L19.6238 4.91389C20.2252 4.52984 20.5765 3.85442 20.5455 3.14149L20.49 1.86197L18.3472 2.50945L17.1817 4.9592L17.8757 6.15413L18.5208 5.61824Z", fill: "#FF9478" }),
                React.createElement("path", { d: "M20.6257 2.02039C20.5585 0.888704 19.5958 0.0161422 18.459 0.0655289C17.311 0.115377 16.4208 1.08644 16.4707 2.23446C16.5178 3.31881 17.3869 4.17272 18.4507 4.22212L18.9057 2.43742L20.6257 2.02039Z", fill: "#1F544A" }),
                React.createElement("path", { opacity: "0.25", d: "M20.5006 1.96001L20.5121 2.2354L18.611 2.85349L18.8501 2.18553L20.5006 1.96001Z", fill: "#1F544A" }),
                React.createElement("path", { opacity: "0.25", d: "M17.7386 3.76198L17.5615 4.15221C17.5615 4.15221 17.8163 4.36193 18.295 4.11192L17.7386 3.76198Z", fill: "#1F544A" }),
                React.createElement("path", { d: "M12.3378 11.3247C12.5061 9.85462 13.4614 6.56776 17.0605 4.45148L18.5948 5.76077C18.5948 5.76077 18.5948 5.76077 17.9111 7.01113C17.2274 8.26149 14.7427 11.8057 14.7427 11.8057L12.3378 11.3247Z", fill: "#4BCDB2" }),
                React.createElement("path", { d: "M12.3377 11.3247C12.3895 10.8729 12.4626 10.3013 12.6436 9.66479C13.0514 8.23021 14.007 6.46588 16.5 5L16.5 6.5C16.5 6.5 15 7.5 14 8.5C13 9.5 12.3377 11.3247 12.3377 11.3247Z", fill: "#328777" }),
                React.createElement("path", { d: "M22.2112 14.4096C22.2112 14.4096 22.3657 15.8042 21.7558 16.1625L2.67647 27.3733L3.14671 28.1736L22.2261 16.9628C22.8358 16.6046 23.0398 15.8197 22.6814 15.21L22.2112 14.4096Z", fill: "#FFE477" }),
                React.createElement("path", { d: "M11.8312 9.66873L10.5923 10.8625C10.3331 11.1122 9.90284 11.0145 9.77697 10.6772L9.59274 10.1836C9.52291 9.99645 9.57114 9.78573 9.71539 9.6476L10.912 8.50186", fill: "#FF9478" }),
                React.createElement("path", { d: "M11.5 19C11.5336 18.9616 13.3991 17.8915 13.3991 17.8915L12.8356 19.6926L13.6418 19.6452L13.8787 20.0485L11.0279 21.7236L11.5 19Z", fill: "#328777" }),
                React.createElement("path", { d: "M11.4288 10.0644L13.136 8.38574L17.1476 6.29191L17.1941 5.27046L16.5786 4.73501L13.2094 6.35343L10.3624 9.01582L11.4288 10.0644Z", fill: "#4BCDB2" })),
            React.createElement("defs", null,
                React.createElement("clipPath", { id: "clip0" },
                    React.createElement("rect", { width: "28", height: "28", fill: "white" }))))));
};
