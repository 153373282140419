export var cssVariables = {
    mainRedColor: {
        color: '#ff0000',
    },
    mainBlueColor: {
        color: '#2aa1f7',
    },
    linkColor: {
        color: '#919191',
    },
    headerBackgroundColor: {
        color: '#151515',
    },
    headerColor: {
        color: '#ffffff',
    },
    mainBackgroundColor: {
        color: 'radial-gradient(100% 100% at 50.13% 0%, #303038 0%, #1B1B22 100%)',
    },
};
