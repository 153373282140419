import React, { useEffect, useState } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useIsDesktop } from '~hooks';
import { ZIndex } from '~constants/view/ZIndex';
import { noop } from '~utils/noop';
import { Portal } from '../Portal';
import { useStyles } from './Modal.styles';
var Modal = function (_a) {
    var _b = _a.display, display = _b === void 0 ? false : _b, _c = _a.onClickAway, onClickAway = _c === void 0 ? noop : _c, children = _a.children;
    var _d = useState(false), isContentHovered = _d[0], setIsContentHovered = _d[1];
    var classes = useStyles();
    var isDesktop = useIsDesktop();
    useEffect(function () {
        document.body.style.overflow = 'hidden';
        return function () {
            document.body.style.overflow = 'unset';
        };
    }, []);
    return (React.createElement(Portal, { display: display, transparent: true, enableWrapperPointerEvents: isContentHovered, zIndex: ZIndex.MODAL },
        React.createElement("div", { className: classes.shadow, style: { marginTop: "".concat(isDesktop ? 77 : 26, "px") } }),
        React.createElement(ClickAwayListener, { mouseEvent: "onMouseDown", onClickAway: onClickAway },
            React.createElement("div", { className: classes.content, onMouseEnter: function () { return setIsContentHovered(true); }, onMouseLeave: function () { return setIsContentHovered(false); }, onTouchStart: function () { return setIsContentHovered(true); }, onTouchEnd: function () { return setIsContentHovered(false); } }, children))));
};
export { Modal };
