var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {};
var modalSlice = createSlice({
    name: 'modal',
    initialState: initialState,
    reducers: {
        openModal: function (state, _a) {
            var _b;
            var payload = _a.payload;
            return __assign(__assign({}, state), (_b = {}, _b[payload.modalName] = {
                isVisible: true,
                modalProps: payload.modalProps,
            }, _b));
        },
        closeModal: function (state, _a) {
            var _b;
            var payload = _a.payload;
            return __assign(__assign({}, state), (_b = {}, _b[payload.key] = __assign(__assign({}, state[payload.key]), { isVisible: false }), _b));
        },
    },
});
export var openModal = (_a = modalSlice.actions, _a.openModal), closeModal = _a.closeModal;
export default modalSlice.reducer;
