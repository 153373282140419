import { HandballIcon, RinkHockeyIcon, BasketBall3x3, KabaddiIcon, SSBMIcon, NordicCombinerIcons, CrossCountryIcon, ShortTrackIcon, OlympicsIcon, SpecialIcon, CounterStrikeIcon, PoolIcon, SpeedSkatingIcon, BowlsIcon, CricketIcon, WorldLotteryIcon, LolIcon, StreetFighterIcon, FootsalIcon, BeachSoccerIcon, BandyIcon, FieldHockeyIcon, MmaIcon, FormulaIcon, FloorBallIcon, AssieRulesIcon, PesapalloIcon, BoxingIcon, GymnasticsIcon, BeachVolleyIcon, AmericanFootballIcon, BaseBallIcon, BasketballIcon, DartsIcon, GolfIcon, HockeyIcon, RugbyIcon, ShuttleCockIcon, SoccerIcon, TableTennisIcon, TennisIcon, VoleyballIcon, BoatIcon, CurlingIcon, MotorsportIcon, GaelicFootballIcon, TekkenIcon, ShotgunIcon, ArtifactIcon, BikeIcon, LacrosseIcon, RampIcon, SkiIcon, SkijumpingIcon, SnookerIcon, SnowboardIcon, HorseBackIcon, GaelicHurlingIcon, SquashIcon, WinterSportIcon, WaterpoloIcon, ESportIcon, } from '~icons/sports';
import { SkeletonIcon } from '~icons/sports/skeleton-icon';
export var name = 'sports';
// Sport icons list with betradar sport ids
export var mapSportIdToSportIcon = {
    1: SoccerIcon,
    2: BasketballIcon,
    3: BaseBallIcon,
    4: HockeyIcon,
    5: TennisIcon,
    6: HandballIcon,
    7: FloorBallIcon,
    9: GolfIcon,
    10: BoxingIcon,
    11: MotorsportIcon,
    12: RugbyIcon,
    13: AssieRulesIcon,
    14: WinterSportIcon,
    15: BandyIcon,
    16: AmericanFootballIcon,
    17: BikeIcon,
    18: SpecialIcon,
    19: SnookerIcon,
    20: TableTennisIcon,
    21: CricketIcon,
    22: DartsIcon,
    23: VoleyballIcon,
    24: FieldHockeyIcon,
    25: PoolIcon,
    26: WaterpoloIcon,
    28: CurlingIcon,
    29: FootsalIcon,
    30: OlympicsIcon,
    31: ShuttleCockIcon,
    32: BowlsIcon,
    34: BeachVolleyIcon,
    36: GymnasticsIcon,
    37: SquashIcon,
    38: RinkHockeyIcon,
    39: LacrosseIcon,
    43: SkiIcon,
    44: ShotgunIcon,
    46: CrossCountryIcon,
    47: NordicCombinerIcons,
    48: SkijumpingIcon,
    49: SnowboardIcon,
    50: SpeedSkatingIcon,
    60: BeachSoccerIcon,
    61: PesapalloIcon,
    76: HorseBackIcon,
    81: BoatIcon,
    103: RampIcon,
    104: SkeletonIcon,
    105: ShortTrackIcon,
    108: WorldLotteryIcon,
    109: CounterStrikeIcon,
    110: LolIcon,
    117: MmaIcon,
    127: StreetFighterIcon,
    135: GaelicFootballIcon,
    136: GaelicHurlingIcon,
    137: ESportIcon,
    138: KabaddiIcon,
    142: FormulaIcon,
    155: BasketBall3x3,
    156: TekkenIcon,
    160: SSBMIcon,
    162: ArtifactIcon,
};
