import makeStyles from '@material-ui/core/styles/makeStyles';
export var useRightSideStyles = makeStyles(function (theme) {
    var _a;
    return ({
        rightSide: (_a = {
                position: 'fixed',
                bottom: '30px',
                right: '30px',
                top: '106px',
                width: '445px',
                display: 'grid',
                alignContent: 'start',
                overflowY: 'scroll',
                scrollbarColor: '#313131 #151515',
                scrollbarWidth: 'thin',
                borderRadius: '4px'
            },
            _a[theme.breakpoints.up('lg')] = {
                boxSizing: 'border-box',
                position: 'relative',
                bottom: 'auto',
                right: 'auto',
                top: 'auto',
                flex: '0 0 445px',
                marginLeft: 30,
                overflow: 'auto',
            },
            _a),
    });
}, { name: 'RightSide' });
