import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '~atoms';
import { useStyles } from './BetNowButton.styles';
export var BetNowButton = function (_a) {
    var onBetNowClick = _a.onBetNowClick;
    var t = useTranslation('tip-game').t;
    var classes = useStyles();
    return (React.createElement(Button, { color: "primary", variant: "contained", fullWidth: true, onClick: onBetNowClick, className: classes.button }, t('payments.tip_now')));
};
