var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from "classnames";
import { initializeAvatarStyles } from "~pages/ranking-list/constants";
import star from "~icons/star.svg";
import activeStar from "~icons/star-active.svg";
import { useStyles } from './RankingListAvatar.styles';
var mainPath = process.env.REACT_CDN_ORIGIN || '';
var MAX_STARS_NUMBER = 5;
export var RankingListAvatar = function (_a) {
    var _b = _a.stars, stars = _b === void 0 ? 0 : _b, _c = _a.avatar, avatar = _c === void 0 ? 'Joker' : _c, avatarUrl = _a.avatarUrl, _d = _a.size, size = _d === void 0 ? 'sm' : _d;
    var classes = useStyles();
    var t = useTranslation('tip-game').t;
    var userAvatar = avatar === null || avatar === void 0 ? void 0 : avatar.toLowerCase();
    var avatarStyles = initializeAvatarStyles(t);
    var userStyle = avatarStyles[userAvatar];
    return (React.createElement("div", { className: classNames(classes.wrapper, size === 'md' && classes.md), style: { background: userStyle === null || userStyle === void 0 ? void 0 : userStyle.background } },
        React.createElement("div", { className: classNames(classes.avatarName, size === 'md' && classes.avatarNameMd) }, userStyle === null || userStyle === void 0 ? void 0 : userStyle.avatarName),
        React.createElement("div", { className: classNames(classes.avatar, size === 'md' && classes.avatarMd) },
            React.createElement("img", { src: avatarUrl ? "".concat(mainPath).concat(avatarUrl) : '', alt: 'user avatar' })),
        React.createElement("div", { className: classNames(classes.stars, size === 'md' && classes.starsMd) },
            __spreadArray([], Array(stars), true).map(function (_, index) { return (React.createElement("img", { src: activeStar, alt: 'active star icon', key: index })); }),
            __spreadArray([], Array(MAX_STARS_NUMBER - stars), true).map(function (_, index) { return (React.createElement("img", { src: star, alt: 'star icon', key: index })); }))));
};
