import makeStyles from '@material-ui/core/styles/makeStyles';
var useStyles = makeStyles({
    item: {
        position: 'relative',
        paddingRight: '20px',
        '&$expanded': {
            borderBottomLeftRadius: '0px',
            borderBottomRightRadius: '0px',
            border: 'none',
            backgroundColor: '#e6e6e6',
        },
        '&$expanded::after': {
            content: '""',
            backgroundColor: '#e6e6e6',
            position: 'absolute',
            bottom: '-10px',
            left: 0,
            width: '100%',
            height: '10px',
        },
    },
    arrowWrapper: {
        position: 'absolute',
        right: '8px',
    },
    arrow: {
        width: '10px',
        maxWidth: '10px',
        minWidth: '10px',
        height: '10px',
        maxHeight: '10px',
        minHeight: '10px',
        color: '#212121',
        transform: 'rotate(-90deg)',
        '&$expanded': {
            transform: 'rotate(90deg)',
            color: '#2AA1F7',
        },
    },
    expanded: {},
}, { name: 'ExpandableItem' });
export { useStyles };
