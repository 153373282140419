var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var createAsyncReducers = function () { return ({
    pending: function (state) { return (__assign(__assign({}, state), { loading: 'pending' })); },
    fulfilled: function (state, action) { return (__assign(__assign({}, state), { data: action.payload, loading: 'fulfilled', error: null })); },
    rejected: function (state, action) { return (__assign(__assign({}, state), { loading: 'rejected', error: action.payload || action.error })); },
}); };
