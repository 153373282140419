import React, { useMemo } from 'react';
import { useStructuredSelector } from '~hooks';
import { makeGetEventByIdSelector } from '~slices/events';
import { EventStatus } from '../EventStatus';
var NonSidebetsEventStatus = function (_a) {
    var eventId = _a.eventId, eventsType = _a.eventsType, favoritesTab = _a.favoritesTab;
    var getEventByIdSelector = useMemo(makeGetEventByIdSelector, []);
    var event = useStructuredSelector({
        event: function (state) { return getEventByIdSelector(state, eventId, eventsType); },
    }).event;
    return React.createElement(EventStatus, { event: event, favoritesTab: favoritesTab });
};
export { NonSidebetsEventStatus };
