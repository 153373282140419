import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import shopIcon from '~icons/balance/shop.svg';
import jackpotIcon from '~icons/balance/jackpot.svg';
import { useStyles } from '~atoms/WithdrawTypeCard/WithdrawTypeCard.styles';
export var WithdrawTypeCard = function (_a) {
    var type = _a.type, isActive = _a.isActive, onClick = _a.onClick;
    var classes = useStyles();
    var t = useTranslation('tip-game').t;
    var imgSrc = type === 'inShop' ? shopIcon : jackpotIcon;
    var titleText = type === 'inShop'
        ? t('payments.pay_in_shop')
        : t('payments.receive_online_payment');
    return (React.createElement("div", { role: "button", tabIndex: 0, className: classNames(classes.wrapper, isActive && classes.active, onClick && classes.cursorPointer), onClick: onClick },
        React.createElement("img", { src: imgSrc, alt: 'balance icon' }),
        React.createElement("span", { className: classes.text }, titleText),
        isActive && (React.createElement("div", { className: classes.checkMark },
            React.createElement("i", { className: "fa fa-check fa-xs", "aria-hidden": "true" })))));
};
