import React from 'react';
import classNames from 'classnames';
import { useStyles } from './MoreInfo.styles';
var MoreInfo = function (_a) {
    var onClick = _a.onClick;
    var classes = useStyles();
    return (React.createElement("div", { tabIndex: 0, role: "button", onClick: onClick, className: classes.container },
        React.createElement("i", { className: classNames('fal fa-ellipsis-h', classes.icon) })));
};
export { MoreInfo };
