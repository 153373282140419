var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var sortGamesByName = function (games, name) {
    if (!name || !games.length) {
        return games || [];
    }
    var sortByName = function (a, b) {
        return a.groupName === name || a.name === name ? -1 : b.groupName === name || b.name === name ? 1 : 0;
    };
    return __spreadArray([], games, true).sort(sortByName);
};
