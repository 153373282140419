import * as React from 'react';
import { goBack } from 'connected-react-router';
import classNames from 'classnames';
import Divider from '@material-ui/core/Divider';
import i18next from 'i18next';
import { useAppDispatch } from '~store/configureStore';
import { useStyles } from '~organisms/terms/styles';
import { Button } from '~atoms/button';
import { useIsDesktop, useStructuredSelector } from '~hooks';
import { aboutPageContent, getAboutPageContent } from '~slices/about';
export var GDPR = function () {
    var isDesktop = useIsDesktop();
    var classes = useStyles();
    var dispatch = useAppDispatch();
    var _a = useStructuredSelector({
        aboutPageContent: aboutPageContent,
    }).aboutPageContent, title = _a.title, text = _a.text;
    var previosPage = function () { return dispatch(goBack()); };
    React.useEffect(function () {
        if (i18next.language && !isDesktop) {
            dispatch(getAboutPageContent({
                name: 'data_security_policy',
                with_child: false,
                lang: i18next.language,
            }));
        }
    }, []);
    // We don't have design for this page (remove this comment)
    return (React.createElement("div", { className: classes.container },
        !isDesktop ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: classNames('flex-row flex-center space-between') },
                React.createElement("b", null, title),
                React.createElement(Button, { className: classes.closeIcon, onClick: previosPage },
                    React.createElement("i", { className: "far fa-times" }))),
            React.createElement(Divider, { style: { marginTop: '16px', background: '#8E8E8E' } }))) : (React.createElement("h2", null, title)),
        React.createElement("p", null, text)));
};
