import moment from 'moment';
import i18next from 'i18next';
import validationMessages from '~utils/validationMessages';
var rq = validationMessages.required, dateFormat = validationMessages.dateFormat, sizeFrom1to50 = validationMessages.sizeFrom1to50, emailFormat = validationMessages.emailFormat, digitsOnly = validationMessages.digitsOnly, passwordFormat = validationMessages.passwordFormat;
export var required = function (v) { return (v ? undefined : rq()); };
export var isValidDate = function (v) {
    return moment(v, 'DD/MM/YYYY', true).isValid() ? undefined : dateFormat();
};
export var onlyNumberField = function (val) {
    return /^\d+$/.test(val) ? undefined : digitsOnly();
};
export var requiredCheckbox = function (v) {
    return v
        ? undefined
        : i18next.t("errors:ER0010", {
            lngs: [i18next.language],
            lng: i18next.language,
        });
};
export var emailValidation = function (value) {
    return value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
        ? undefined
        : emailFormat();
};
export var minLengthValidation = function (value, param) {
    var paramLength = param !== null && param !== void 0 ? param : 50;
    return value && value.length > paramLength ? sizeFrom1to50() : undefined;
};
export var usernameValidation = function (value) {
    return value && /^[a-zA-Z0-9]{8,50}$/g.test(value)
        ? undefined
        : i18next.t("errors:validation.sizeFrom8to50", {
            lngs: [i18next.language],
            lng: i18next.language,
        });
};
export var passwordValidation = function (value) {
    return value && /^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?!.*\s).{8,100})$/g.test(value)
        ? undefined
        : passwordFormat();
};
export var checkIfFieldIsRequired = function (fieldName, tabIndex, conditions) {
    var _a, _b, _c;
    if (!conditions) {
        return true;
    }
    var fieldRule = (_a = conditions[tabIndex]) === null || _a === void 0 ? void 0 : _a.filter(function (rule) { return rule.name === fieldName; });
    return (_c = (_b = fieldRule[0]) === null || _b === void 0 ? void 0 : _b.mandatory) !== null && _c !== void 0 ? _c : true;
};
export var validateField = function (value, fieldName, tabIndex, validationFunction, conditions, params) {
    if (!checkIfFieldIsRequired(fieldName, tabIndex, conditions) && !value) {
        return undefined;
    }
    var paramArg = params !== null && params !== void 0 ? params : undefined;
    return validationFunction(value, paramArg);
};
