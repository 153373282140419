import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
i18n
    // load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-xhr-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    .init({
    ns: ['header', 'ticket', 'footer', 'errors'],
    whitelist: ['en', 'de', 'uk', 'pl', 'nl', 'tr', 'fr', 'es', 'ru'],
    interpolation: { escapeValue: false },
    debug: false,
    fallbackLng: 'en',
    detection: {
        order: ['path', 'localStorage', 'htmlTag', 'cookie'],
        lookupFromPathIndex: 0,
        checkWhitelist: true,
    },
    defaultNS: 'header',
    backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
});
export default i18n;
