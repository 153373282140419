import React from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ConfirmationModal, } from '~modals/components/ConfirmationModal';
import { useIsDesktop } from '~hooks';
import { StatusScreen } from '~molecules';
import failed from '~icons/statusIcons/failed.svg';
var useStyles = makeStyles({
    color: {
        color: '#212121',
        marginTop: '16px',
    },
    logoClasses: {
        paddingTop: '12px',
        paddingBottom: 0,
    },
    desktopHeaderClasses: {
        paddingTop: '24px',
        fontSize: '28px',
        fontStyle: 'normal',
    },
    desktopSubHeaderClasses: {
        paddingBottom: '24px',
    },
}, { name: 'ChangeEmailModal' });
export var ChangeEmailModal = function (_a) {
    var modalName = _a.modalName, modalProps = _a.modalProps, isVisible = _a.isVisible;
    var t = useTranslation('errors').t;
    var classes = useStyles();
    var isDesktop = useIsDesktop();
    return (React.createElement(ConfirmationModal, { modalName: modalName, modalProps: modalProps, isVisible: isVisible },
        React.createElement(StatusScreen, { logoClasses: classes.logoClasses, header: t('deleted_request'), headerClassName: "".concat(classes.color, " ").concat(isDesktop ? classes.desktopHeaderClasses : ''), subheader: modalProps.subheader || t('no_relevant_link'), subheaderClassName: "".concat(classes.color, " ").concat(isDesktop ? classes.desktopSubHeaderClasses : ''), logo: failed })));
};
