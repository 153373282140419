import makeStyles from '@material-ui/core/styles/makeStyles';
export var useStyles = makeStyles(function (theme) {
    var _a;
    return ({
        container: {
            marginTop: '16px',
        },
        input: {
            width: '100%',
            padding: '28px 22px 11px 22px',
            background: '#151515',
            border: '1px solid #3E3E3E',
            borderRadius: '4px',
            fontSize: '16px',
            color: '#FFFFFF',
            '&:focus-visible': {
                outline: 'none',
            },
        },
        label: {
            display: 'flex',
            position: 'absolute',
            top: '10px',
            left: '22px',
            fontSize: '10px',
            color: '#FFFFFF',
            '& div': {
                marginLeft: '3px',
                fontSize: '10px',
                color: '#FFFFFF',
            },
            '& svg': {
                marginLeft: '3px',
            },
        },
        inputWrapper: {
            position: 'relative',
            display: 'flex',
        },
        buttonsWrapper: (_a = {
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '16px'
            },
            _a[theme.breakpoints.up('lg')] = {
                justifyContent: 'flex-start',
            },
            _a),
    });
}, { name: 'BalanceInput' });
