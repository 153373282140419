import React from 'react';
import { Event } from '~organisms';
import { allowedBankerSelectionSelector, betslipEventsSelector, } from '~slices/betslip';
import { useStructuredSelector } from '~hooks';
var BetslipEvents = function (_a) {
    var onClose = _a.onClose, toRedirectToSidebets = _a.toRedirectToSidebets, onBankerClick = _a.onBankerClick;
    var _b = useStructuredSelector({
        events: betslipEventsSelector,
        allowedBankerSelection: allowedBankerSelectionSelector,
    }), events = _b.events, allowedBankerSelection = _b.allowedBankerSelection;
    return (React.createElement(React.Fragment, null, events.map(function (event) { return (React.createElement(Event, { onClose: onClose, showBanker: allowedBankerSelection, key: event.id, 
        // @ts-expect-error
        event: event, bankerClickHandler: onBankerClick, deletable: true, toRedirectToSidebets: toRedirectToSidebets, checkDeactivatedOdds: true })); })));
};
export { BetslipEvents };
