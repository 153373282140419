import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '~atoms';
import { checkIfFieldIsRequired, required, validateField, } from '~utils/formValidators';
export var RepeatedEmail = function (_a) {
    var tabIndex = _a.tabIndex, registrationBlocks = _a.registrationBlocks;
    var t = useTranslation('registration').t;
    return (React.createElement(TextField, { name: "repeatedEmail", title: t('form.repeat_email'), placeholder: t('form.repeat_email_placeholder'), disablePaste: true, validators: function (value) {
            return validateField(value, 'repeatedEmail', tabIndex, required, registrationBlocks);
        }, required: checkIfFieldIsRequired('repeatedEmail', tabIndex, registrationBlocks) }));
};
