import makeStyles from '@material-ui/core/styles/makeStyles';
export var useStyles = makeStyles({
    container: {
        background: '#151515',
        color: '#8E8E8E',
        display: 'flex',
        borderRadius: '4px',
        flexDirection: 'column',
        fontSize: '42px',
        fontWeight: 'bold',
        width: '440px',
    },
    mobile: {
        maxWidth: '346px',
    },
    banner: {
        display: 'flex',
        height: '156px',
        '& [class*="HorizonGallery"]': {
            '& li': {
                height: '156px',
            },
        },
    },
}, { name: 'EmptyBetslipBanner' });
