import React from 'react';
import classNames from 'classnames';
import { Item } from './Item';
import { useStyles } from './ExpandableItem.styles';
var ExpandableItem = function (_a) {
    var _b, _c, _d;
    var active = _a.active, _e = _a.expanded, expanded = _e === void 0 ? false : _e, label = _a.label, autoScrollDelay = _a.autoScrollDelay, onClick = _a.onClick, children = _a.children;
    var classes = useStyles();
    return (React.createElement(Item, { autoScrollDelay: autoScrollDelay, onClick: onClick, active: active, label: label, className: classNames(classes.item, (_b = {}, _b[classes.expanded] = expanded, _b)) },
        children,
        React.createElement("div", { className: classNames(classes.arrowWrapper, (_c = {},
                _c[classes.expanded] = expanded,
                _c)) },
            React.createElement("i", { className: classNames('far fa-chevron-left', classes.arrow, (_d = {},
                    _d[classes.expanded] = expanded,
                    _d)) }))));
};
export { ExpandableItem };
