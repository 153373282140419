import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button, SmallProviderLogo } from '~atoms';
import { isAuthenticatedSelector } from '~slices/auth';
import { useOpenLogin, useStructuredSelector } from '~hooks';
import { selectHasInactiveEvents } from '~slices/betslip';
import { useActivePlaySessionDataSelector } from '~slices/activePlaySession/selectors';
import { useStyles } from './FastPlayButton.styles';
var FastPlayButton = function (_a) {
    var isBPLogo = _a.isBPLogo, redirectToTopRanking = _a.redirectToTopRanking;
    var t = useTranslation('ticket').t;
    var classes = useStyles();
    var _b = useStructuredSelector({
        isAuthenticated: isAuthenticatedSelector,
        hasInactiveEvents: selectHasInactiveEvents,
    }), isAuthenticated = _b.isAuthenticated, hasInactiveEvents = _b.hasInactiveEvents;
    var _c = useActivePlaySessionDataSelector(), providerId = _c.id, backgroundColor = _c.backgroundColor;
    var isProviderLogo = isBPLogo && providerId && backgroundColor;
    var openLogin = useOpenLogin(redirectToTopRanking).openLogin;
    var handleClick = function () {
        if (hasInactiveEvents) {
            return;
        }
        if (isAuthenticated) {
            redirectToTopRanking();
        }
        else {
            openLogin();
        }
    };
    return (React.createElement(Button, { color: isProviderLogo ? 'primary' : undefined, variant: "contained", fullWidth: true, onClick: handleClick, className: classNames(classes.button, isProviderLogo && classes.whiteText), disabled: hasInactiveEvents },
        isProviderLogo && (React.createElement(SmallProviderLogo, { id: providerId, backgroundColor: backgroundColor })),
        React.createElement("span", null,
            t('fast_play'),
            " ",
            isProviderLogo ? t('active_BP') : '')));
};
export { FastPlayButton };
