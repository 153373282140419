import { range } from '~utils/range';
import { getMatrixLine } from './getMatrixLine';
import { getMaxCoordinates } from './getMaxCoordinates';
var getMatrix = function (oc, positionsMap) {
    var _a = getMaxCoordinates(oc, positionsMap), x = _a.x, y = _a.y;
    return range(y).map(function (_, i) {
        var y = i + 1;
        return getMatrixLine(x, y);
    });
};
export { getMatrix };
