import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { selectSportBIdById } from '~slices/sports';
import { isAuthenticatedSelector } from '~slices/auth';
import { name } from './constants';
export var optionsSelector = function (state) { return state[name]; };
export var optionsDataSelector = function (state) {
    return state[name].data;
};
export var userSportIdSelector = function (state) {
    return optionsDataSelector(state).sportId;
};
export var currentSportIdSelector = createSelector(function (state) { return optionsDataSelector(state).localSportId; }, function (state) { return userSportIdSelector(state); }, function (localSportId, sportId) { return localSportId || sportId; });
export var localSportIdSelector = function (state) {
    return optionsDataSelector(state).localSportId;
};
export var sportNameSelector = function (state) {
    return optionsDataSelector(state).sportName;
};
export var selectHeaderSportId = createSelector(function (state) { return optionsDataSelector(state).localSportId; }, userSportIdSelector, isAuthenticatedSelector, function (localSportId, sportId, isAuthenticated) {
    return isAuthenticated ? sportId || localSportId : localSportId;
});
export var selectHeaderSportBid = createSelector(function (state) { return selectSportBIdById(state, currentSportIdSelector(state)); }, function (sportBId) { return sportBId; });
export var selectSoonLiveSportId = createSelector(optionsDataSelector, function (_a) {
    var soonLiveSportId = _a.soonLiveSportId;
    return soonLiveSportId;
});
export var selectedAutoAcceptSelector = function (state) {
    return optionsDataSelector(state).autoAccept;
};
export var selectedWantNewslettersOnEmailSelector = function (state) { return optionsDataSelector(state).wantNewslettersOnEmail; };
export var selectedWasLoadedOptionsSelector = function (state) {
    return optionsDataSelector(state).wasLoadedOptions;
};
export var loadingSelector = function (state) {
    return optionsSelector(state).loading;
};
export var errorSelector = function (state) {
    return optionsSelector(state).error;
};
/* @deprecated use useStructuredSelector instead */
export var useLoadingSelector = function () {
    return useSelector(loadingSelector);
};
/* @deprecated use useStructuredSelector instead */
export var useErrorSelector = function () {
    return useSelector(errorSelector);
};
