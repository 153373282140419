var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createAsyncThunk } from '@reduxjs/toolkit';
import { name } from '~slices/betslip/constants';
import { request } from '~utils/request';
export var getFullBetslip = createAsyncThunk("".concat(name, "/getFullBetslip"), function () { return __awaiter(void 0, void 0, void 0, function () {
    var data, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, request({
                        method: 'get',
                        url: '/sport-events/v1/betslip/full',
                        withCredentials: true,
                    })];
            case 1:
                data = (_a.sent()).data;
                return [2 /*return*/, data];
            case 2:
                error_1 = _a.sent();
                throw new Error('Something went wrong!');
            case 3: return [2 /*return*/];
        }
    });
}); });
export var addCombination = createAsyncThunk("".concat(name, "/addCombination"), function (combination) { return __awaiter(void 0, void 0, void 0, function () {
    var eventsType, rest, data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                eventsType = combination.eventsType, rest = __rest(combination, ["eventsType"]);
                return [4 /*yield*/, request({
                        method: 'post',
                        url: '/sport-events/v1/betslip/combination',
                        withCredentials: true,
                        data: rest,
                    })];
            case 1:
                data = (_a.sent()).data;
                return [2 /*return*/, data];
        }
    });
}); });
export var deleteCombination = createAsyncThunk("".concat(name, "/deleteCombination"), function (combination) { return __awaiter(void 0, void 0, void 0, function () {
    var eventsType, rest, data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                eventsType = combination.eventsType, rest = __rest(combination, ["eventsType"]);
                return [4 /*yield*/, request({
                        method: 'delete',
                        url: '/sport-events/v1/betslip/combination',
                        withCredentials: true,
                        data: rest,
                    })];
            case 1:
                data = (_a.sent()).data;
                return [2 /*return*/, data];
        }
    });
}); });
export var updateWager = createAsyncThunk("".concat(name, "/updateWager"), function (newWagerData) { return __awaiter(void 0, void 0, void 0, function () {
    var eventsType, rest, data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                eventsType = newWagerData.eventsType, rest = __rest(newWagerData, ["eventsType"]);
                return [4 /*yield*/, request({
                        method: 'put',
                        url: '/sport-events/v1/betslip/wager',
                        data: rest,
                        withCredentials: true,
                    })];
            case 1:
                data = (_a.sent()).data;
                return [2 /*return*/, data];
        }
    });
}); });
export var deleteOutcomeFromEvent = createAsyncThunk("".concat(name, "/deleteOutcomeFromEvent"), function (deleteDto) { return __awaiter(void 0, void 0, void 0, function () {
    var eventsType, eventId, selectionId, data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                eventsType = deleteDto.eventsType, eventId = deleteDto.eventId, selectionId = deleteDto.selectionId;
                return [4 /*yield*/, request({
                        method: 'delete',
                        url: "/sport-events/v1/betslip/event/".concat(eventId, "/selection/").concat(selectionId),
                        withCredentials: true,
                    })];
            case 1:
                data = (_a.sent()).data;
                return [2 /*return*/, data];
        }
    });
}); });
export var deleteFullBetslip = createAsyncThunk("".concat(name, "/deleteFullBetslip"), function (deleteDto) { return __awaiter(void 0, void 0, void 0, function () {
    var eventsType, data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                eventsType = deleteDto.eventsType;
                return [4 /*yield*/, request({
                        method: 'delete',
                        url: "/sport-events/v1/betslip/clear",
                        withCredentials: true,
                    })];
            case 1:
                data = (_a.sent()).data;
                return [2 /*return*/, data];
        }
    });
}); });
export var addBanker = createAsyncThunk("".concat(name, "/addBanker"), function (addDto) { return __awaiter(void 0, void 0, void 0, function () {
    var eventId, data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                eventId = addDto.eventId;
                return [4 /*yield*/, request({
                        method: 'post',
                        url: "/sport-events/v1/betslip/banker/".concat(eventId),
                        withCredentials: true,
                    })];
            case 1:
                data = (_a.sent()).data;
                return [2 /*return*/, data];
        }
    });
}); });
export var deleteBanker = createAsyncThunk("".concat(name, "/deleteBanker"), function (addDto) { return __awaiter(void 0, void 0, void 0, function () {
    var eventId, data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                eventId = addDto.eventId;
                return [4 /*yield*/, request({
                        method: 'delete',
                        url: "/sport-events/v1/betslip/banker/".concat(eventId),
                        withCredentials: true,
                    })];
            case 1:
                data = (_a.sent()).data;
                return [2 /*return*/, data];
        }
    });
}); });
export var deleteDeactivatedOdds = createAsyncThunk("".concat(name, "/deleteDeactivatedOdds"), function (removeDto) { return __awaiter(void 0, void 0, void 0, function () {
    var eventsType, data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                eventsType = removeDto.eventsType;
                return [4 /*yield*/, request({
                        method: 'delete',
                        url: "/sport-events/v1/betslip/odds/deactivated",
                        withCredentials: true,
                    })];
            case 1:
                data = (_a.sent()).data;
                return [2 /*return*/, data];
        }
    });
}); });
