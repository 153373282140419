import { LayoutOptions } from '~constants/view/LayoutOptions';
import { BEOptionNames } from '~constants/view/BEOptionNames';
var DEFAULT_OPTION = LayoutOptions.OPTION_1;
var getOptionName = function (option) {
    switch (option) {
        case BEOptionNames.OPTION_1: {
            return LayoutOptions.OPTION_1;
        }
        case BEOptionNames.OPTION_2: {
            return LayoutOptions.OPTION_2;
        }
        case BEOptionNames.OPTION_3: {
            return LayoutOptions.OPTION_3;
        }
        case BEOptionNames.OPTION_4: {
            return LayoutOptions.OPTION_4;
        }
        case BEOptionNames.OPTION_5: {
            return LayoutOptions.OPTION_5;
        }
        case BEOptionNames.OPTION_6: {
            return LayoutOptions.OPTION_6;
        }
        case BEOptionNames.OPTION_7: {
            return LayoutOptions.OPTION_7;
        }
        case BEOptionNames.OPTION_10: {
            return LayoutOptions.OPTION_10;
        }
        case BEOptionNames.OPTION_11: {
            return LayoutOptions.OPTION_11;
        }
        default: {
            return DEFAULT_OPTION;
        }
    }
};
export { getOptionName };
