import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import classNames from "classnames";
import Dialog from "@material-ui/core/Dialog/Dialog";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import { closeModal } from "~modals";
import { useModalStyles } from "~modals/components/Modal.styles";
import { GAME_CLASS } from "~slices/gameTypes/constants";
var GameClassDescriptionModal = function (_a) {
    var modalName = _a.modalName, _b = _a.isVisible, isVisible = _b === void 0 ? false : _b, _c = _a.modalProps, gameClass = _c.gameClass, handleClose = _c.handleClose;
    var dispatch = useDispatch();
    var classes = useModalStyles();
    var t = useTranslation('tip-game').t;
    var translationKey;
    switch (gameClass) {
        case GAME_CLASS.PRO:
            translationKey = 'class-pro-description';
            break;
        case GAME_CLASS.EASY_PLUS:
            translationKey = 'class-plus-description';
            break;
        case GAME_CLASS.EASY_TIP:
            translationKey = 'class-tip-description';
            break;
        default:
            translationKey = '';
    }
    var handleCancel = function () {
        dispatch(closeModal({ key: modalName }));
    };
    var onButtonClick = function () {
        if (handleClose) {
            handleClose();
        }
        handleCancel();
    };
    return (React.createElement(Dialog, { open: isVisible, onClose: handleCancel, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description", className: classes.root },
        React.createElement(DialogContent, { className: classNames(classes.dialog, classes.dialogContainer) },
            translationKey && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: classes.gameClassTitle }, t("".concat(translationKey, ".title"))),
                React.createElement("div", { className: classes.joinGameContent },
                    React.createElement("div", { className: classes.gameClassItem }, t("".concat(translationKey, ".p1"))),
                    React.createElement("div", { className: classes.gameClassItem }, t("".concat(translationKey, ".p2"))),
                    React.createElement("div", { className: classes.gameClassItem }, t("".concat(translationKey, ".p3"))),
                    React.createElement("div", { className: classes.gameClassItem }, t("".concat(translationKey, ".p4")))))),
            React.createElement(Button, { fullWidth: true, size: "large", color: "primary", variant: "contained", style: { margin: '24px 0' }, onClick: onButtonClick },
                t('got_it'),
                "!"))));
};
export { GameClassDescriptionModal };
