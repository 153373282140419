var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import moment from 'moment';
import { DesktopDatePicker } from '@material-ui/pickers';
import './datepicker-styles.scss';
import TextField from '@material-ui/core/TextField';
export var DatePicker = function (_a) {
    var title = _a.title, dateFormat = _a.dateFormat, value = _a.value, placeholder = _a.placeholder, name = _a.name, required = _a.required, showAsterisk = _a.showAsterisk, onBlur = _a.onBlur, _b = _a.onChange, onChange = _b === void 0 ? function () { } : _b, error = _a.error, helperText = _a.helperText, isDesktop = _a.isDesktop;
    // TODO destructure and remove check on line#52 after rewriting registration to react-hook-form
    var methods = useFormContext();
    var textInputRef = useRef(null);
    return (React.createElement("div", { className: "date-input" },
        title && (React.createElement("div", { className: "date-input__title", style: { color: isDesktop ? '#212121' : '' } },
            title,
            " ",
            (required || showAsterisk) && '*')),
        React.createElement(DesktopDatePicker, { renderInput: function (props) { return (React.createElement(TextField, __assign({}, props, { name: name, variant: "outlined", fullWidth: true, onFocus: function () {
                    if (textInputRef.current) {
                        textInputRef.current.selectionStart = dateFormat.length;
                        textInputRef.current.selectionEnd = dateFormat.length;
                    }
                }, onBlur: onBlur, inputProps: __assign(__assign({}, props.inputProps), { placeholder: placeholder }), inputRef: textInputRef, error: error, autoComplete: "off", helperText: helperText }))); }, value: methods ? methods.getValues(name) : moment(value, 'DD/MM/YYYY'), 
            // @ts-ignore
            onChange: onChange, inputFormat: dateFormat, DialogProps: {
                style: {
                    display: 'none',
                },
            } })));
};
