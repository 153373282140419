import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { Button, FullscreenLoader } from '~atoms';
import { useStyles } from '~molecules/DepositTab/components/ShopBalance/ShopBalance.styles';
import { getPathWithLanguageCode } from '~utils/languageInURL';
import { PaymentsURL } from '~pages/payments/payments.routes';
import { useAppDispatch } from '~store';
import { BankDetailsInput } from '~molecules/WithdrawTab/components/BankDetailsInput';
export var OnlineBalance = function () {
    var dispatch = useAppDispatch();
    var classes = useStyles();
    var t = useTranslation('tip-game').t;
    var _a = useState(false), isDataLoading = _a[0], setIsDataLoading = _a[1];
    var onDepositClick = function () {
        setIsDataLoading(true);
        setTimeout(function () {
            setIsDataLoading(false);
        }, 2000);
        setTimeout(function () {
            dispatch(push(getPathWithLanguageCode("/payments/".concat(PaymentsURL.DEPOSIT, "/").concat(PaymentsURL.PAYMENT_CODE))));
        }, 2100);
    };
    return (React.createElement("div", { className: classes.container },
        isDataLoading && React.createElement(FullscreenLoader, null),
        React.createElement("div", null,
            React.createElement(BankDetailsInput, { label: 'OnlineBalance', placeholder: 'OnlineBalance', onInputChange: function (value) { return console.log(value); } }),
            React.createElement(BankDetailsInput, { label: 'OnlineBalance', placeholder: 'OnlineBalance', onInputChange: function (value) { return console.log(value); } }),
            React.createElement(BankDetailsInput, { label: 'OnlineBalance', placeholder: 'OnlineBalance', onInputChange: function (value) { return console.log(value); } })),
        React.createElement(Button, { color: "primary", variant: "contained", fullWidth: true, className: classes.button, onClick: onDepositClick }, t('payments.deposit'))));
};
