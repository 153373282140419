import makeStyles from '@material-ui/core/styles/makeStyles';
var useStyles = makeStyles({
    systemModal: {
        marginTop: '10px',
        padding: '16px',
        color: '#fff',
        position: 'relative',
        background: 'radial-gradient(102.51% 128.71% at 20.97% 0%, #303038 0%, #1B1B22 100%)',
        '&::before': {
            content: '""',
            position: 'absolute',
            left: '19px',
            top: '-16px',
            borderBottom: '8px solid #212128',
            border: '8px solid transparent',
        },
    },
    checkboxLabel: {
        margin: '0 0 16px 0',
        '& span': {
            fontSize: '12px',
            lineHeight: '14px',
        },
    },
}, { name: 'CombinationDropDown' });
export { useStyles };
