import { clamp } from '~utils/math';
function range(a, b, step) {
    if (step === void 0) { step = 1; }
    var count = typeof b === 'number' ? b : a;
    var from = typeof b === 'number' ? a : 0;
    return new Array(clamp(count, 0, Infinity))
        .fill(null)
        .map(function (_, i) { return i * step + from; });
}
export { range };
