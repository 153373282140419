import { useRef } from 'react';
import { RequestStatus } from '~constants/api/RequestStatus';
var useIsLoadedOnce = function (requestStatus, resetOnIdle) {
    if (resetOnIdle === void 0) { resetOnIdle = false; }
    var isLoadedOnceRef = useRef(false);
    if (requestStatus === RequestStatus.FULFILLED) {
        isLoadedOnceRef.current = true;
    }
    if (requestStatus === RequestStatus.IDLE && resetOnIdle) {
        isLoadedOnceRef.current = false;
    }
    return isLoadedOnceRef.current;
};
export { useIsLoadedOnce };
