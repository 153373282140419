var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import makeStyles from '@material-ui/core/styles/makeStyles';
import { AnimationDuration } from '~/constants/view/AnimationDuration';
var animation = {
    '0%': {
        bottom: '-3px',
    },
    '50%': {
        bottom: '0px',
    },
    '100%': {
        bottom: '-3px',
    },
};
var animationSettings = {
    animationDuration: AnimationDuration.SHORT.str,
    animationIterationCount: '1',
    animationDirection: 'normal',
};
export var useStyles = makeStyles({
    bounced: __assign(__assign({}, animationSettings), { animationName: '$bounce' }),
    bounced2: __assign(__assign({}, animationSettings), { animationName: '$bounce2' }),
    // different animations to restart them on value change
    '@keyframes bounce': animation,
    '@keyframes bounce2': animation,
}, { name: 'Shortcut' });
