import React, { useEffect } from 'react';
import orderBy from 'lodash.orderby';
import classNames from 'classnames';
import Divider from '@material-ui/core/Divider';
import i18next from 'i18next';
import { useIsDesktop, useStructuredSelector } from '~hooks';
import { useAppDispatch } from '~store/configureStore';
import { termsHaveUpdatesSelector } from '~slices/terms';
import { TermsButtons } from '~organisms';
import { isAuthenticatedSelector } from '~slices/auth';
import { aboutPageContent, getAboutPageContent, isPendingSelector, resetAboutPageContent, } from '~slices/about';
import { SmallLoader } from '~atoms';
import { useStyles } from './styles';
export var Terms = function () {
    var _a;
    var isDesktop = useIsDesktop();
    var classes = useStyles();
    var dispatch = useAppDispatch();
    var _b = useStructuredSelector({
        isAuthenticated: isAuthenticatedSelector,
        termsHaveUpdate: termsHaveUpdatesSelector,
        termsDocument: aboutPageContent,
        isPending: isPendingSelector,
    }), isAuthenticated = _b.isAuthenticated, termsHaveUpdate = _b.termsHaveUpdate, _c = _b.termsDocument, title = _c.title, children = _c.children, isPending = _b.isPending;
    var rules = orderBy(children, ['priority'], ['asc']);
    var refs = [];
    for (var i = 0; i < rules.length; i++) {
        refs[i] = React.createRef();
    }
    var handleScrollTo = function (elRef) {
        var _a;
        (_a = elRef === null || elRef === void 0 ? void 0 : elRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };
    var scrollToItem = function (ref) { return function () {
        handleScrollTo(ref);
    }; };
    useEffect(function () {
        dispatch(resetAboutPageContent());
    }, []);
    useEffect(function () {
        if (!isDesktop) {
            dispatch(getAboutPageContent({
                name: 'general_terms_and_conditions',
                lang: i18next.language,
                with_child: true,
            }));
        }
    }, [i18next.language]);
    return (React.createElement(React.Fragment, null,
        termsHaveUpdate && React.createElement("div", { className: classes.overlay }),
        React.createElement("div", { className: classNames(classes.container, (_a = {},
                _a[classes.topIndex] = termsHaveUpdate,
                _a)) },
            !isDesktop && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: classNames('flex-row flex-center space-between') },
                    React.createElement("b", null, title)),
                isPending ? (React.createElement(SmallLoader, null)) : (React.createElement(Divider, { style: { marginTop: '16px', background: '#8E8E8E' } })))),
            React.createElement("ul", { className: classes.content }, rules.map(function (rule, index) { return (React.createElement("li", { key: rule.name, onClick: scrollToItem(refs[index]) },
                React.createElement("span", null,
                    index + 1,
                    "."),
                React.createElement("span", { className: classes.titleDescription }, rule.title))); })),
            rules.map(function (rule, index) { return (React.createElement("div", { key: rule.name, ref: refs[index], className: classes.item },
                React.createElement("div", { style: { marginBottom: '16px' } },
                    React.createElement("b", null,
                        index + 1,
                        ". ",
                        rule.title)),
                React.createElement("p", null, rule.text))); }),
            isAuthenticated && termsHaveUpdate && React.createElement(TermsButtons, null))));
};
