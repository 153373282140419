import makeStyles from '@material-ui/core/styles/makeStyles';
import { VERTICAL_MARGIN } from './constants';
var useStyles = makeStyles(function () { return ({
    container: {
        position: 'relative',
    },
    arrowsContainer: {
        height: "".concat(VERTICAL_MARGIN, "px"),
        position: 'absolute',
        bottom: "-".concat(VERTICAL_MARGIN, "px"),
        left: '0',
        display: 'inline-flex',
        alignItems: 'center',
        transform: 'translate(-50%, 0)',
    },
}); }, { name: 'TooltipContent' });
export { useStyles };
