import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
var useFormNormalization = function (normalizationMap) {
    var _a = useFormContext(), watch = _a.watch, setValue = _a.setValue, triggerValidation = _a.triggerValidation;
    var fields = Object.keys(normalizationMap);
    var deps = fields.map(function (field) { return watch(field); });
    useEffect(function () {
        fields.forEach(function (field) {
            var value = watch(field);
            var normalizedValue = normalizationMap[field](value);
            if (value !== normalizedValue) {
                setValue(field, normalizedValue);
                triggerValidation(field);
            }
        });
    }, deps);
};
export { useFormNormalization };
