import makeStyles from '@material-ui/core/styles/makeStyles';
export var useStyles = makeStyles({
    container: {
        color: '#fff',
        width: '100%',
        minHeight: '30px',
        fontSize: '12px',
        lineHeight: '14px',
        textAlign: 'center',
        overflowWrap: 'normal',
        whiteSpace: 'pre-wrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    highlighted: {
        color: '#2AA1F7',
    },
}, { name: 'HighlightedText' });
