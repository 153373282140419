var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createSelector } from 'reselect';
import { HORIZON_GALLERY_TYPE } from '~slices/teasers';
import { GALLERY_TYPE, name } from './constants';
export var teasersSelector = function (state) { return state[name]; };
export var teasersDataSelector = function (state) {
    return teasersSelector(state).data;
};
export var loadingTeasersSelector = function (state) {
    return teasersSelector(state).loading;
};
export var squareGallerySelector = createSelector([teasersDataSelector], function (teasers) {
    var res = __spreadArray([], Object.values(teasers).filter(function (s) { return s.slideshowType === GALLERY_TYPE; }), true);
    return res;
});
export var isLoadedEmptySelector = function (state) {
    return state[name].isTopTeasersLoadedEmpty;
};
export var slideshowSelector = createSelector([teasersDataSelector], function (teasers) {
    var res = __spreadArray([], Object.values(teasers).filter(function (s) { return s.slideshowType !== GALLERY_TYPE; }), true);
    return res;
});
export var isTopTeasersHorizonGalleryShownSelector = createSelector([slideshowSelector], function (slideshows) {
    var slideshowIndex = slideshows === null || slideshows === void 0 ? void 0 : slideshows.map(function (ssh) { var _a; return (_a = ssh === null || ssh === void 0 ? void 0 : ssh.pages) === null || _a === void 0 ? void 0 : _a.filter(function (page) { return page.location === 'TOP'; }); }).map(function (res) { return (res === null || res === void 0 ? void 0 : res.length) !== 0; }).indexOf(true);
    var slideshow = slideshows && slideshowIndex !== undefined
        ? slideshows[slideshowIndex]
        : undefined;
    return (!!slideshows &&
        !!slideshows.length &&
        (slideshow === null || slideshow === void 0 ? void 0 : slideshow.slideshowType) === HORIZON_GALLERY_TYPE);
});
export var selectIsBannersShown = createSelector(function (state) {
    var banners = slideshowSelector(state);
    return Array.isArray(banners) && !!banners.length;
}, isLoadedEmptySelector, function (shouldDisplaySlider, isTopTeasersLoadedEmpty) {
    return shouldDisplaySlider || !isTopTeasersLoadedEmpty;
});
