import * as React from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import RegisterForm from './RegisterForm';
import { RegisterSuccess } from './components';
import { RegisterEmailConfirmation } from './components/RegisterEmailConfirmation';
import { ResendSuccess } from './components/ResendSuccess';
import { RegisterEmail } from './components/RegisterEmail';
export var RegisterRoutes = function (_a) {
    var match = _a.match;
    var root = match.url;
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: "".concat(root, "/success"), component: RegisterSuccess }),
        React.createElement(Route, { exact: true, path: "".concat(root, "/success/resend"), component: ResendSuccess }),
        React.createElement(Route, { exact: true, path: "".concat(root, "/success/email"), component: RegisterEmail }),
        React.createElement(Route, { exact: true, path: "".concat(root, "/confirmation/email/:code"), component: RegisterEmailConfirmation }),
        React.createElement(Route, { path: root, component: RegisterForm }),
        React.createElement(Redirect, { to: root })));
};
