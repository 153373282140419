var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
import { createAsyncReducers } from '~utils/createAsyncReducers';
import { name } from '~slices/bp/constants';
import { getBp } from '~slices/bp/thunks';
export var LinkType;
(function (LinkType) {
    LinkType["HOME"] = "HOME";
    LinkType["TERMS_AND_CONDITIONS"] = "TERMS_AND_CONDITIONS";
    LinkType["BONUS"] = "BONUS";
    LinkType["WITHDRAW"] = "WITHDRAW";
    LinkType["TOP_UP"] = "TOP_UP";
})(LinkType || (LinkType = {}));
var initialState = {
    data: {},
    loading: 'idle',
    error: null,
};
export var bp = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        successPolling: function (state, _a) {
            var _b;
            var payload = _a.payload;
            return __assign(__assign({}, state), { data: __assign(__assign({}, state.data), { responseObject: (_b = state.data.responseObject) === null || _b === void 0 ? void 0 : _b.map(function (provider) {
                        return typeof payload[provider.id] !== 'undefined'
                            ? __assign(__assign({}, provider), { betProviderUser: __assign(__assign({}, provider.betProviderUser), { balance: payload[provider.id] }) }) : provider;
                    }) }) });
        },
        startPolling: function (state, action) {
            return __assign(__assign({}, state), { loading: 'pending' });
        },
        stopPolling: function (state) {
            return __assign(__assign({}, state), { loading: 'fulfilled' });
        },
    },
    extraReducers: function (builder) {
        var _a = createAsyncReducers(), pending = _a.pending, fulfilled = _a.fulfilled, rejected = _a.rejected;
        builder
            .addCase(getBp.pending, pending)
            .addCase(getBp.fulfilled, fulfilled)
            .addCase(getBp.rejected, rejected);
    },
});
export var successPolling = (_a = bp.actions, _a.successPolling), startPolling = _a.startPolling, stopPolling = _a.stopPolling;
