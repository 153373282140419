var normalizeFavoritesEvents = function (events, isEnded) {
    if (isEnded === void 0) { isEnded = false; }
    return events
        .filter(function (event) {
        return (typeof event.bid === 'number' &&
            !Number.isNaN(event.bid) &&
            !Number.isNaN(Number(event.tn)) &&
            !Number.isNaN(Number(event.dt)) &&
            event.fav);
    })
        .sort(function (a, b) {
        var dtSort = isEnded
            ? Number(b.dt) - Number(a.dt)
            : Number(a.dt) - Number(b.dt);
        var tnSort = Number(a.tn) - Number(b.tn);
        var bIdSort = Number(a.bid) - Number(b.bid);
        return dtSort || tnSort || bIdSort;
    })
        .map(function (event) {
        return {
            tournamentId: Number(event.tn),
            eventsIds: [Number(event.bid)].filter(function (v) { return !Number.isNaN(v); }),
        };
    })
        .reduce(function (acc, v) {
        var _a;
        var lastElem = acc[acc.length - 1];
        if ((lastElem === null || lastElem === void 0 ? void 0 : lastElem.tournamentId) === v.tournamentId) {
            (_a = lastElem.eventsIds).push.apply(_a, v.eventsIds);
            return acc;
        }
        acc.push(v);
        return acc;
    }, []);
};
export { normalizeFavoritesEvents };
