import makeStyles from '@material-ui/core/styles/makeStyles';
export var useStyles = makeStyles({
    container: {
        paddingRight: 30,
        borderRadius: 14,
        background: 'linear-gradient(270deg, #00A5FF -94.93%, #0C104A 47.58%, #0C0F49 78.73%)',
        boxSizing: 'border-box',
    },
    wrapper: {
        display: 'flex',
        columnGap: 20,
    },
    greenBorder: {
        border: '1px solid #6CFF00',
    },
    blockWrapper: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    userName: {
        minHeight: 12,
        maxWidth: 235,
        marginTop: 23,
        fontWeight: 700,
        fontSize: 16,
        color: '#FFFFFF',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    level: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 12,
        marginBottom: 6,
        fontSize: 16,
        fontWeight: 700,
        '& span': {
            marginRight: '5px',
        },
    },
    achievement: {
        position: 'absolute',
        top: 8,
        right: 8,
        height: 31.5,
        width: 31.5,
    },
    divider: {
        width: '100%',
        height: 1,
        background: '#FFFFFF',
    },
    bonusWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 8,
    },
    bonusText: {
        fontSize: 12,
        fontWeight: 500,
        background: 'linear-gradient(90deg, #EFEB77 0%, #F6AF04 100%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
    },
    bonus: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 12,
        fontWeight: 600,
        background: 'linear-gradient(90deg, #EFEB77 0%, #F6AF04 100%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        '& img': {
            height: 15,
            marginLeft: 4,
        },
        '& svg': {
            marginLeft: 3,
        },
    },
    balanceWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 2,
        fontSize: 12,
        color: '#FFFFFF',
        '& svg': {
            height: 8,
            marginLeft: 3,
        },
    },
    listWrapper: {
        maxHeight: '710px',
        overflowY: 'auto',
        marginTop: '6px',
    },
    itemWrapper: {
        marginTop: 10,
    },
    globalListWrapper: {
        maxHeight: '460px',
        overflowY: 'auto',
        marginTop: '6px',
    },
    nestedWrapper: {
        display: 'flex',
        padding: '10px 0 13px 10px',
        marginTop: '3px',
        background: 'linear-gradient(0deg, #FFF 0%, #1C1C23 0%, #000 15.76%, #5D5D65 100%)',
        boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.92) inset',
        borderRadius: '18px',
    },
    nestedCenterBlock: {
        width: '100%',
        maxWidth: '193px',
        padding: '0 33px',
        '@media (max-width:412px)': {
            paddingRight: '6px',
        },
        '@media (max-width:376px)': {
            padding: '3px 6px',
        },
        '@media (max-width:340px)': {
            padding: '0 10px',
        },
    },
    nestedRank: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '14px',
        fontSize: '25px',
        fontWeight: 700,
        color: '#FFFFFF',
        '& span:first-child': {
            marginRight: '5px',
        },
        '@media (max-width:340px)': {
            flexDirection: 'column',
            fontSize: '16px',
        },
    },
    nestedCredits: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '10px 0',
        fontSize: '12px',
        color: '#FFFFFF',
        '@media (max-width:340px)': {
            fontSize: '8px',
        },
    },
    nestedBalance: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '16px',
        fontWeight: 700,
        '& >svg': {
            marginLeft: '8px',
        },
        '@media (max-width:340px)': {
            fontSize: '12px',
            '& >svg': {
                marginLeft: '3px',
            },
        },
    },
    jackpotAmount: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '10px',
        fontSize: '12px',
        fontWeight: 900,
        color: '#FFFFFF',
        '& >div': {
            fontSize: '12px',
            marginLeft: '3px',
        },
        '& >svg': {
            marginLeft: '3px',
        },
    },
    pointer: {
        cursor: 'pointer',
    },
    jackpotCount: {
        marginRight: '3px',
    },
    goldText: {
        color: '#F4B71B',
    },
    silverText: {
        color: '#C9C8C8',
    },
    bronzeText: {
        color: '#F87F2B',
    },
    greenText: {
        color: '#6CFF00',
    },
    whiteText: {
        color: '#FFFFFF',
    },
    goldBorder: {
        border: '1px solid #F4B71B',
    },
    silverBorder: {
        border: '1px solid #C9C8C8',
    },
    bronzeBorder: {
        border: '1px solid #F87F2B',
    },
    globalListBackground: {
        height: '47px',
        background: 'linear-gradient(0deg, #000 0%, rgba(36, 36, 36, 0.58) 50%, rgba(84, 84, 84, 0.00) 100%)',
        opacity: '0.189'
    },
    globalListNickname: {
        width: '110px',
        position: 'absolute',
        bottom: '50%',
        right: '50%',
        transform: 'translate(50%, 50%)',
        fontWeight: 700,
        color: '#FFFFFF',
        textAlign: 'center',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    globalListStars: {
        height: '40px',
        padding: '0 20px',
        '& div > div': {
            width: '10px',
            height: '10px',
        },
        '@media (max-width:376px)': {
            padding: 0,
        },
    },
    nicknameWrapper: {
        height: '88px',
        width: '123px',
        minWidth: '123px',
        borderRadius: '17px',
    },
    globalListRank: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '8px 0',
        fontSize: '25px',
        fontWeight: 700,
        color: '#FFFFFF',
        '& span:first-child': {
            marginRight: '5px',
        },
        '& div': {
            display: 'flex',
            alignItems: 'center',
        },
        '@media (max-width:340px)': {
            flexDirection: 'column',
            fontSize: '16px',
        },
    },
    zeroPadding: {
        padding: 0,
    },
    borderRadius14: {
        borderRadius: '14px',
    },
    smallLoader: {
        position: 'absolute',
        minHeight: '470px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        background: 'rgba(15,15,15,0.5)',
        zIndex: 10,
    },
    paddingTop20: {
        paddingTop: '20px',
    },
    paddingBottom20: {
        paddingBottom: '20px',
    },
    stickyBottom: {
        position: 'sticky',
        bottom: 0,
    },
    fullWidth: {
        width: '100%',
    }
}, { name: 'RankingListItem' });
