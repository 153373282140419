import makeStyles from '@material-ui/core/styles/makeStyles';
export default makeStyles(function (theme) {
    var _a;
    return (_a = {
            root: {
                color: '#ffffff',
                background: '#151515',
            },
            header: {
                padding: '17px 31px',
                zIndex: 1,
                background: '#151515',
            },
            liveStandings: {
                boxSizing: 'border-box',
                height: '44px',
                background: '#151515',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                lineHeight: '14px',
                letterSpacing: '0.05em',
                color: '#FFFFFF',
                cursor: 'pointer',
                outline: 'none',
                margin: '16px',
                backgroundColor: '#1b1c22',
                border: '1px solid #F2F2F2',
                borderRadius: '4px',
                fontWeight: 'bold',
                fontSize: '16px',
                userSelect: 'none',
            },
            frame: {
                '@media (max-width:341px)': {
                    marginTop: '-47px',
                },
                '@media (min-width: 342px) and (max-width:772px)': {
                    marginTop: '-55px',
                },
                '@media (min-width: 773px) and (max-width:1366px)': {
                    marginTop: '-53px',
                },
                '@media (min-width: 1367px) and (max-width:1620px)': {
                    marginTop: '-55px',
                },
                '@media (min-width: 1621px)': {
                    marginTop: '-53px',
                },
            },
            hide: {
                display: 'none',
            },
            title: {
                margin: '0 60px',
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: 1.25,
            },
            button: {
                '&:hover': {
                    background: 'none',
                    cursor: 'default',
                },
            },
            backButton: {
                padding: 0,
                height: '20px',
                minWidth: 0,
                float: 'left',
                fontSize: '12px',
                lineHeight: 1.5,
                color: 'white',
            },
            backButtonIcon: {
                fontSize: '10px !important',
            },
            subTitle: {
                padding: '16px',
                fontSize: '12px',
                fontWeight: 'normal',
                lineHeight: 1.1666667,
            },
            listItemContainer: {
                padding: '6px 0',
                borderBottom: '1px solid rgba(142, 142, 142, 0.4);',
                '&:first-child': {
                    borderTop: '1px solid rgba(142, 142, 142, 0.4);',
                },
            },
            listItem: {
                paddingTop: '4px',
                paddingRight: '84px',
                paddingBottom: '4px',
            },
            listItemText: {
                fontSize: '12px',
                lineHeight: 1.5,
            },
            indents: {
                margin: '8px 0',
            },
            listItemButton: {
                padding: '6px 8px',
                height: 'auto',
                minWidth: '52px',
                fontSize: '12px',
            },
            list: {
                padding: '12px 0 12px 16px',
            }
        },
        _a[theme.breakpoints.up('lg')] = {
            listItem: {
                paddingTop: '7px',
                paddingBottom: '7px',
            },
            listItemButton: {
                height: '40px',
                minWidth: '64px',
                fontSize: '14px',
            },
            listItemText: {
                fontSize: '14px',
            },
            list: {
                padding: '16px 0 12px 15px',
            },
            backButton: {
                fontSize: '14px',
            },
            header: {
                padding: '14px 16px',
            },
            title: {
                lineHeight: 1,
            },
        },
        _a);
}, { name: 'Outrights' });
