import React, { forwardRef, useEffect, useLayoutEffect, useRef, useState, } from 'react';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '~store';
import { usePrevious } from '~hooks';
import { useEventRowStyles } from '~pages/home/components/EventRow.styles';
import { getPathWithLanguageCode } from '~utils/languageInURL/getPathWithLanguageCode';
import useStyles from './Event.styles';
export var getScoresOfSets = function (competitor) {
    return [
        competitor.s1,
        competitor.s2,
        competitor.s3,
        competitor.s4,
        competitor.s5,
        competitor.s6,
        competitor.s7,
        competitor.s8,
        competitor.s9,
    ].filter(function (score) { return typeof score === 'number'; });
};
export var Competitor = forwardRef(function Competitor(_a, ref) {
    var style = _a.style, competitor = _a.competitor, competitorStyle = _a.competitorStyle, toRedirectToSidebets = _a.toRedirectToSidebets, eventId = _a.eventId, redCardsToShow = _a.redCardsToShow;
    var classes = useStyles();
    var dispatch = useAppDispatch();
    var history = useHistory();
    var redirectToSidebets = function () {
        if (toRedirectToSidebets) {
            dispatch(push({
                pathname: getPathWithLanguageCode("/sidebets/".concat(eventId)),
                state: { prevPath: history.location.pathname },
            }));
        }
    };
    if (!competitor) {
        return null;
    }
    return (React.createElement("div", { role: "button", tabIndex: 0, className: "flex-row", style: style, onClick: redirectToSidebets },
        React.createElement("span", { ref: ref, className: classes.competitor, style: competitorStyle || {} }, competitor === null || competitor === void 0 ? void 0 : competitor.nm),
        React.createElement("div", { className: "flex-row", style: { margin: '0px 16px 0 auto' } },
            !!competitor.yc && (React.createElement("div", { className: classNames(classes.card, classes.yellowCard) }, competitor.yc)),
            !!redCardsToShow && (React.createElement("div", { className: classNames(classes.card, classes.redCard) }, redCardsToShow)))));
});
export var CompetitorScores = function (_a) {
    var _b, _c;
    var competitor = _a.competitor, style = _a.style, sportId = _a.sportId, sr = _a.sr, isFirst = _a.isFirst, scoreToShow = _a.scoreToShow;
    var classes = useStyles();
    var eventRowStyles = useEventRowStyles();
    var scoresOfSets = getScoresOfSets(competitor || {});
    var isTennis = sportId === 25 || sportId === 2;
    var firstHalfTeamScore = Number.isInteger(competitor === null || competitor === void 0 ? void 0 : competitor.s1) &&
        Number.isInteger(competitor === null || competitor === void 0 ? void 0 : competitor.s2) &&
        (competitor === null || competitor === void 0 ? void 0 : competitor.s1);
    var _d = useState(false), scoreChanged = _d[0], setScoreChanged = _d[1];
    var prevScore = usePrevious(scoreToShow);
    var isSoccer = sportId === 1;
    useEffect(function () {
        var tId;
        if (isSoccer && Number(scoreToShow) > Number(prevScore)) {
            setScoreChanged(true);
            tId = setTimeout(function () {
                setScoreChanged(false);
            }, Number(process.env.GOAL_SCORE_BLINKING_DURATION) * 1000);
        }
        return function () {
            if (tId) {
                clearTimeout(tId);
                setScoreChanged(false);
            }
        };
    }, [scoreToShow, isSoccer]);
    return (React.createElement("div", { className: classNames(classes.flexRow, classes.alignCenter), style: style },
        sportId === 1 ? (React.createElement("div", { className: classNames(classes.competitorScore, classes.competitorScoreResult, classes.shadowColor) }, firstHalfTeamScore)) : (React.createElement(React.Fragment, null, scoresOfSets.length > 0
            ? scoresOfSets.map(function (score, index) { return (React.createElement("div", { 
                // eslint-disable-next-line react/no-array-index-key
                key: index, className: classNames(classes.competitorScore) }, score)); })
            : null)),
        isTennis && (React.createElement("div", { className: classNames(classes.competitorScore, classes.competitorTennisScore) }, Number(competitor === null || competitor === void 0 ? void 0 : competitor.gs) > 40 ? 'AD' : competitor === null || competitor === void 0 ? void 0 : competitor.gs)),
        isTennis && (React.createElement("div", { className: classNames(classes.noSr, (_b = {},
                _b[classes.sr] = (isFirst && "".concat(sr) === '1') || (!isFirst && "".concat(sr) === '2'),
                _b)) })),
        React.createElement("div", { className: classNames(classes.competitorScore, classes.competitorScoreResult, (_c = {}, _c[eventRowStyles.competitorGoal] = scoreChanged, _c)) }, scoreToShow)));
};
export var CompetitorColumn = function (_a) {
    var c1 = _a.c1, c2 = _a.c2, toRedirectToSidebets = _a.toRedirectToSidebets, eventId = _a.eventId, currentRedCards = _a.currentRedCards, temporaryRedCards = _a.temporaryRedCards;
    var comp1 = useRef(null);
    var comp2 = useRef(null);
    var classes = useStyles();
    var _b = useState(undefined), width = _b[0], setWidth = _b[1];
    useLayoutEffect(function () {
        var _a, _b;
        var widthC1 = ((_a = comp1 === null || comp1 === void 0 ? void 0 : comp1.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect().width) || 0;
        var widthC2 = ((_b = comp2 === null || comp2 === void 0 ? void 0 : comp2.current) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect().width) || 0;
        if (widthC2 && widthC1) {
            setWidth(widthC1 > widthC2 ? widthC1 : widthC2);
        }
    }, [comp1.current, comp2.current]);
    return (React.createElement("div", { className: classes.fullWidth },
        React.createElement("div", { className: "flex-column" },
            React.createElement(Competitor, { eventId: eventId, toRedirectToSidebets: toRedirectToSidebets, competitorStyle: { width: width }, style: { marginBottom: '7px' }, competitor: c1, ref: comp1, redCardsToShow: currentRedCards.c1 + temporaryRedCards.c1 }),
            React.createElement(Competitor, { eventId: eventId, toRedirectToSidebets: toRedirectToSidebets, competitorStyle: { width: width }, competitor: c2, ref: comp2, redCardsToShow: currentRedCards.c2 + temporaryRedCards.c2 }))));
};
