var Observer = /** @class */ (function () {
    function Observer() {
        this.subscribers = [];
    }
    Observer.prototype.dispatch = function (data) {
        this.subscribers.forEach(function (s) { return s(data); });
    };
    Observer.prototype.subscribe = function (subscriber) {
        if (this.subscribers.includes(subscriber)) {
            return;
        }
        this.subscribers.push(subscriber);
    };
    Observer.prototype.unsubscribe = function (subscriber) {
        this.subscribers = this.subscribers.filter(function (s) { return s !== subscriber; });
    };
    return Observer;
}());
export { Observer };
