import React, { useCallback, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { Slide } from '@material-ui/core';
import { currencyConverter } from '~utils/converters';
import { maxOddBetslipDataSelector } from '~slices/betslip';
import { usePrevious, useStructuredSelector } from '~hooks';
import { shortcutDataSelector } from '~slices/shortcut';
import { AnimationDuration } from '~/constants/view/AnimationDuration';
import { getTestId } from '~/utils/getTestId';
import { useStyles as useBettingSlipShortcutStyles } from './BettingSlipShortcut.styles';
import { useStyles } from './Shortcut.styles';
export var Shortcut = function (_a) {
    var _b, _c;
    var count = _a.count, handleClick = _a.handleClick, open = _a.open, wrapperClass = _a.wrapperClass, betType = _a.betType, t = _a.t, mp = _a.mp, flipIcon = _a.flipIcon, currency = _a.currency;
    var bSSClasses = useBettingSlipShortcutStyles();
    var classes = useStyles();
    var history = useHistory();
    var _d = useStructuredSelector({
        mo: maxOddBetslipDataSelector,
        shortCut: shortcutDataSelector,
    }), mo = _d.mo, shortCut = _d.shortCut;
    var isOnTopRankingPage = history.location.pathname === '/top-ranking';
    var convertedMP = currencyConverter(mp, currency);
    var arrowIcon = useCallback(function () {
        var isFlipStatus = flipIcon ? !open : open;
        var arrowDirection = isFlipStatus
            ? 'fa-chevron-double-down'
            : 'fa-chevron-double-up';
        return React.createElement("i", { className: "far ".concat(arrowDirection, " ").concat(bSSClasses.iconStyles) });
    }, [open]);
    var prevCount = usePrevious(count);
    var animationClassNameRef = useRef(classes.bounced);
    var animationClassName = useMemo(function () {
        // restarting animations on value change
        var newAnimationClassname = animationClassNameRef.current === classes.bounced
            ? classes.bounced2
            : classes.bounced;
        animationClassNameRef.current = newAnimationClassname;
        return !prevCount || !count ? '' : newAnimationClassname;
    }, [count]);
    return (React.createElement(Slide, { direction: "up", in: count !== 0, timeout: AnimationDuration.SHORT.ms },
        React.createElement("div", { className: classNames(bSSClasses.headerContainer, wrapperClass, animationClassName), style: {
                paddingBottom: isOnTopRankingPage
                    ? 'env(safe-area-inset-bottom)'
                    : '',
                opacity: open && history.location.pathname.includes('home') ? 0 : 1,
            }, onClick: handleClick, role: "button", tabIndex: 0, "data-testid": getTestId('BTN_OPEN_BETSLIP') },
            React.createElement("div", { className: classNames(bSSClasses.pointsContainer, (_b = {},
                    _b[bSSClasses.betType] = betType,
                    _b)) }, betType ? (React.createElement("div", { className: bSSClasses.capitalize }, t("bet_type.".concat(betType)))) : (React.createElement(React.Fragment, null,
                React.createElement("i", { className: "far fa-receipt ".concat(bSSClasses.icon) }),
                React.createElement("span", { className: bSSClasses.count }, count || '')))),
            React.createElement("div", { className: bSSClasses.bettingSlipButtonWrapper },
                React.createElement("div", { className: bSSClasses.bettingSlip },
                    React.createElement("div", { className: bSSClasses.text },
                        React.createElement("span", null, t('betting_slip')),
                        arrowIcon()))),
            React.createElement("div", { className: bSSClasses.moContainer },
                React.createElement("div", { className: classNames(bSSClasses.pointsRow, bSSClasses.paddingBottom, (_c = {},
                        _c[bSSClasses.currency] = mp,
                        _c)) }, mp ? convertedMP : mo || shortCut.mo)))));
};
