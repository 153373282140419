import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { RankingListAvatar } from '~atoms/RankingListItem/components/index';
import CSCoin from '~icons/balance/CS_Coin.svg';
import { CSIcon } from '~icons/rankingList/CSIcon';
import { numberWithDots } from '~utils/numberWithDots';
import first from '../temporaryImages/first.svg';
import second from '../temporaryImages/second.svg';
import third from '../temporaryImages/third.svg';
import { useStyles } from './NestedRankingListItem.styles';
export var NestedRankingListItem = function (_a) {
    var place = _a.place, level = _a.level, rank = _a.rank, userName = _a.userName, avatar = _a.avatar, avatarUrl = _a.avatarUrl, stars = _a.stars, winAmount = _a.winAmount, balance = _a.balance, currentUserRank = _a.currentUserRank, currentUserLevel = _a.currentUserLevel, ranks = _a.ranks, count = _a.count, openedLevel = _a.openedLevel, onItemListClick = _a.onItemListClick;
    var classes = useStyles();
    var t = useTranslation('tip-game').t;
    var balanceValue = numberWithDots(balance || 0);
    var isCurrentUser = (currentUserLevel && currentUserLevel === level) ||
        (currentUserRank && currentUserRank === rank);
    var checkIfNarrowScreen = function (maxWith) { return window.innerWidth < maxWith; };
    var currencyImg = winAmount || balance ? React.createElement("img", { src: CSCoin, alt: "coin" }) : null;
    var achievementImage = place === 'first'
        ? first
        : place === 'second'
            ? second
            : place === 'third'
                ? third
                : null;
    var onItemClick = function (level) {
        if (!(ranks === null || ranks === void 0 ? void 0 : ranks.length))
            return;
        var activeLevel = openedLevel === level ? null : level;
        onItemListClick && onItemListClick(activeLevel);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { role: "button", tabIndex: 0, className: classNames(classes.container, isCurrentUser && classes.greenBorder, (ranks === null || ranks === void 0 ? void 0 : ranks.length) && classes.pointer), onClick: function () { return onItemClick(level); } },
            achievementImage ? (React.createElement("img", { src: achievementImage, className: classes.achievement, alt: "medal icon" })) : (React.createElement(React.Fragment, null,
                rank && (React.createElement("div", { className: classes.level },
                    t('rank'),
                    " ",
                    rank)),
                level && (React.createElement("div", { className: classes.level },
                    t('level'),
                    " ",
                    level)))),
            React.createElement("div", { className: classes.wrapper },
                React.createElement(RankingListAvatar, { stars: stars, avatar: avatar, avatarUrl: avatarUrl }),
                React.createElement("div", { className: classes.fullWidth },
                    React.createElement("div", { className: classes.userName }, userName),
                    React.createElement("div", { className: classes.creditsContainer },
                        React.createElement("div", null,
                            (balance || balance === 0) && (React.createElement(React.Fragment, null,
                                React.createElement("div", { className: classes.creditsText }, t('ranking-list.my_credits')),
                                React.createElement("div", { className: classes.credits },
                                    React.createElement("span", { className: classes.jackpotCount }, balanceValue),
                                    React.createElement(CSIcon, { size: 'sm' })))),
                            winAmount && (React.createElement(React.Fragment, null,
                                React.createElement("div", { className: classes.creditsText }, t('ranking-list.bonus')),
                                React.createElement("div", { className: classes.credits },
                                    count && (React.createElement("div", { className: classes.jackpotCount },
                                        count,
                                        " x")),
                                    numberWithDots(winAmount, checkIfNarrowScreen(435)),
                                    currencyImg))))))))));
};
