import React from 'react';
import { useStructuredSelector } from '~hooks';
import { selectIdsMap } from '~slices/idsMap';
import { useGroupStyles } from '../Sidebets.styles';
import { getOddWidth } from './getOddWidth';
import { getIsHeaderNamed } from './getIsHeaderNamed';
import { getHeaderOddNamesCount } from './getHeaderOddNamesCount';
import { normalizeOneLineOc } from './normalizeOneLineOc';
var MarketsHeader = function (_a) {
    var _b;
    var markets = _a.markets, optionName = _a.optionName;
    var classes = useGroupStyles();
    var oddNamesCount = getHeaderOddNamesCount(optionName);
    var idsMap = useStructuredSelector({
        idsMap: selectIdsMap,
    }).idsMap;
    if (getIsHeaderNamed(optionName)) {
        return (React.createElement("div", { className: classes.groupHeader },
            React.createElement("div", { className: "flex-row" }, normalizeOneLineOc(((_b = markets[0]) === null || _b === void 0 ? void 0 : _b.oc) || [], optionName, oddNamesCount, idsMap).map(function (oc, i) {
                if (typeof oc === 'string') {
                    return (React.createElement("div", { key: "".concat(oc, " ").concat(markets.length * i), className: classes.outcomes, style: {
                            width: "".concat(getOddWidth(optionName), "px"),
                        } }, ' '));
                }
                return (React.createElement("div", { key: "".concat(oc.id, " ").concat(markets.length * i), className: classes.outcomes, style: {
                        width: "".concat(getOddWidth(optionName), "px"),
                    } }, oc.snm || oc.nm || ''));
            }))));
    }
    return (React.createElement("div", { className: classes.groupHeader },
        React.createElement("div", { className: "flex-row" })));
};
export { MarketsHeader };
