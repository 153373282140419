import { createSlice } from '@reduxjs/toolkit';
import { createAsyncReducers } from '~utils/createAsyncReducers';
import { getTournamentById } from './thunks';
import { name } from './constants';
var initialState = {};
var getDefaultState = function () {
    return {
        data: {},
        loading: 'pending',
        error: null,
    };
};
var fillEmptyState = function (state, action) {
    var meta = action.meta;
    if (!state[meta.arg.tournamentId]) {
        state[meta.arg.tournamentId] = getDefaultState();
    }
};
export var tournamentsInfo = createSlice({
    name: name,
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        var _a = createAsyncReducers(), pending = _a.pending, fulfilled = _a.fulfilled, rejected = _a.rejected;
        builder
            .addCase(getTournamentById.pending, function (state, action) {
            fillEmptyState(state, action);
            state[action.meta.arg.tournamentId] = pending(state[action.meta.arg.tournamentId]);
        })
            .addCase(getTournamentById.fulfilled, function (state, action) {
            fillEmptyState(state, action);
            state[action.meta.arg.tournamentId] = fulfilled(state[action.meta.arg.tournamentId], action);
        })
            .addCase(getTournamentById.rejected, function (state, action) {
            fillEmptyState(state, action);
            state[action.meta.arg.tournamentId] = rejected(state[action.meta.arg.tournamentId], action);
        });
    },
});
