import makeStyles from '@material-ui/core/styles/makeStyles';
var useStyles = makeStyles(function (theme) {
    var _a;
    return ({
        root: (_a = {
                paddingTop: 0,
                paddingBottom: 0,
                color: '#b8b8b8',
                textTransform: 'none',
                fontSize: '12px',
                minWidth: '61px'
            },
            _a[theme.breakpoints.up('lg')] = {
                fontSize: 'inherit',
            },
            _a['&:focus'] = {
                opacity: 1,
            },
            _a['&.MuiTab-textColorInherit.Mui-selected'] = {
                color: 'white',
            },
            _a),
    });
}, { name: 'StyledTab' });
export { useStyles };
