import React from 'react';
import { useTranslation } from 'react-i18next';
import { shortcutHasSelectionSelector } from '~slices/shortcut';
import { TicketSelection } from '~pages/ticketSelection';
import { useStructuredSelector } from '~hooks';
import { selectApplicationOnlineStatus } from '~slices/application';
import { EmptyBetslip } from './EmptyBetslip';
export var Betslip = function () {
    var t = useTranslation('ticket').t;
    var _a = useStructuredSelector({
        shortcutHasSelection: shortcutHasSelectionSelector,
        online: selectApplicationOnlineStatus,
    }), shortcutHasSelection = _a.shortcutHasSelection, online = _a.online;
    if (!online) {
        return React.createElement(EmptyBetslip, null);
    }
    return shortcutHasSelection ? (React.createElement(TicketSelection, { t: t, toRedirectToSidebets: true })) : (React.createElement(EmptyBetslip, null));
};
