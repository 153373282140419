import makeStyles from '@material-ui/core/styles/makeStyles';
import { ZIndex } from '~constants/view/ZIndex';
export var useAutoCompleteStyles = makeStyles(function (theme) {
    var _a;
    return ({
        selectBoxTitle: (_a = {
                fontSize: '12px',
                lineHeight: '16px',
                letterSpacing: '0.4px',
                marginBottom: '4px',
                textAlign: 'left'
            },
            _a[theme.breakpoints.up('lg')] = {
                color: '#212121',
            },
            _a),
        option: {
            fontSize: 15,
            background: '#323232',
            color: '#ffffff',
            '&:hover,&:focus': {
                background: '#2e2e2e',
            },
            '& > span': {
                marginRight: 10,
                fontSize: 18,
            },
        },
        input: {
            '&[class*="MuiOutlinedInput-root"]': {
                padding: 0,
                paddingLeft: '9px',
                paddingRight: '24px',
            },
        },
        popper: {
            zIndex: ZIndex.MODAL_POPER,
            '& > div': {
                background: '#323232',
            },
        },
        endAdornment: {
            display: 'none',
        },
    });
}, { name: 'Autocomplete' });
