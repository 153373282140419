import makeStyles from '@material-ui/core/styles/makeStyles';
import { ZIndex } from '~constants/view/ZIndex';
export var useStyles = makeStyles(function (theme) {
    var _a, _b, _c;
    return ({
        container: (_a = {
                display: 'flex',
                flexDirection: 'column',
                padding: '32px 24px 0 20px',
                position: 'relative',
                margin: '24px 16px',
                background: '#151515',
                color: '#fff'
            },
            _a[theme.breakpoints.up('lg')] = {
                margin: 0,
                '& ul': {
                    margin: '0 0 40px',
                },
                '& b': {
                    fontSize: '16px',
                },
            },
            _a['& p'] = (_b = {
                    fontFamily: 'Roboto',
                    fontSize: '12px',
                    lineHeight: '18px',
                    whiteSpace: 'pre-wrap'
                },
                _b[theme.breakpoints.up('lg')] = {
                    fontSize: '14px',
                    lineHeight: '21px',
                },
                _b),
            _a['& li'] = (_c = {
                    marginBottom: '11px',
                    listStyleType: 'none'
                },
                _c[theme.breakpoints.up('lg')] = {
                    '& span': {
                        fontSize: 16,
                    },
                },
                _c),
            _a['& a'] = {
                textDecoration: 'none',
                color: '#f2f2f2',
                lineHeight: '21px',
                paddingLeft: '9px',
            },
            _a),
        topIndex: {
            zIndex: ZIndex.TERMS_TOP_INDEX,
        },
        overlay: {
            position: 'fixed',
            height: '100vh',
            width: '100vw',
            backgroundColor: 'rgb(0 0 0 / 19%)',
            top: 0,
            zIndex: ZIndex.TERMS_OVERLAY,
            left: 0,
            bottom: 0,
            right: 0,
        },
        content: {
            margin: '24px 0 35px',
            padding: '0',
            fontWeight: 'bold',
            fontSize: '14px',
        },
        item: {
            marginBottom: '24px',
        },
        closeIcon: {
            padding: '0',
            minWidth: '0',
            height: 'auto',
        },
        titleDescription: {
            paddingLeft: '9px',
        },
    });
}, { name: 'Terms' });
