var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Field } from 'redux-form';
import { useTranslation } from 'react-i18next';
import SelectBox from '~atoms/inputs/SelectBox/SelectBox';
var renderSelectField = function (_a) {
    var input = _a.input, meta = _a.meta, title = _a.title, options = _a.options, placeholder = _a.placeholder, required = _a.required, optionRenderer = _a.optionRenderer, renderValue = _a.renderValue;
    var t = useTranslation('common').t;
    return (React.createElement(SelectBox, { onChange: input.onChange, value: input.value, error: meta.touched && meta.invalid, helperText: meta.touched ? meta.error : '', title: title, options: options, optionRenderer: optionRenderer, renderValue: renderValue, placeholder: placeholder || "".concat(t('enter'), " ").concat(title.toLocaleLowerCase()), required: required, displayEmpty: true, MenuProps: {
            PopoverClasses: {
                root: 'z-index-MODAL_POPPER',
            },
        } }));
};
var SelectField = function (props) { return (React.createElement(Field, __assign({}, props, { validate: props.validators, component: renderSelectField }))); };
export default SelectField;
