import i18next from 'i18next';
export var numberWithDots = function (num, withShortNumber) {
    if (withShortNumber === void 0) { withShortNumber = false; }
    var shortTextValue = function (translationKey) { return i18next.t("tip-game:ranking-list.".concat(translationKey)); };
    if (withShortNumber && num >= 1000) {
        if (num < 1000000) {
            return (num / 1000).toFixed() + " ".concat(shortTextValue('short_thousand'));
        }
        else if (num < 1000000000) {
            return (num / 1000000).toFixed() + " ".concat(shortTextValue('short_million'));
        }
        else {
            return (num / 1000000000).toFixed() + " ".concat(shortTextValue('short_billion'));
        }
    }
    else {
        var parts = num.toFixed().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        return parts.join('');
    }
};
