import React, { useCallback, useEffect, useRef } from 'react';
import { Drawer } from '@material-ui/core';
import classNames from 'classnames';
import ReactGA from 'react-ga4';
import ReactPixel from "react-facebook-pixel";
import { shortcutCountSelector } from '~slices/shortcut';
import { TicketSelection } from '~pages/ticketSelection';
import { useIsDesktop, useStructuredSelector } from '~hooks';
import { Shortcut } from './Shortcut';
import { useStyles } from './BettingSlipShortcut.styles';
var BettingSlipShortcut = function (_a) {
    var _b, _c;
    var onToggleBetslip = _a.onToggleBetslip, toRedirectToSidebets = _a.toRedirectToSidebets, openedBetslip = _a.openedBetslip, t = _a.t;
    var classes = useStyles();
    var isDesktop = useIsDesktop();
    var count = useStructuredSelector({
        count: shortcutCountSelector,
    }).count;
    var containerRef = useRef(null);
    var handleClick = function () {
        onToggleBetslip(!openedBetslip);
    };
    var handleClose = useCallback(function () {
        onToggleBetslip(false);
    }, [onToggleBetslip]);
    useEffect(function () {
        if (openedBetslip) {
            ReactGA.event('mobile_ticket');
            ReactPixel.trackCustom('mobile_ticket');
        }
        if (typeof openedBetslip === 'boolean') {
            onToggleBetslip(openedBetslip);
        }
    }, [openedBetslip]);
    useEffect(function () {
        if (count === 0) {
            onToggleBetslip(false);
        }
    }, [count]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Shortcut, { count: count, handleClick: handleClick, open: openedBetslip || false, t: t }),
        React.createElement(Drawer, { anchor: "bottom", open: openedBetslip, keepMounted: true, onClose: handleClose, classes: {
                root: classes.root,
                paper: classNames(classes.paper, (_b = {},
                    _b[classes.paperMobile] = !isDesktop,
                    _b)),
            } },
            React.createElement("div", { ref: containerRef, className: classNames(classes.paper, (_c = {},
                    _c[classes.paperMobile] = !isDesktop,
                    _c)) },
                React.createElement(TicketSelection, { open: openedBetslip, onClose: handleClose, t: t, toRedirectToSidebets: toRedirectToSidebets, parrent: containerRef })))));
};
export { BettingSlipShortcut };
