import { createSelector } from 'reselect';
import { name } from '~slices/betslip/constants';
import { isEventActive } from '~utils/events';
export var betslipSelector = function (state) { return state[name]; };
export var betslipDataSelector = createSelector(betslipSelector, function (data) { return data.data; });
export var maxOddBetslipDataSelector = function (state) { var _a, _b; return (_b = (_a = betslipSelector(state).data) === null || _a === void 0 ? void 0 : _a.to) === null || _b === void 0 ? void 0 : _b.mo; };
export var allowedBankerSelectionSelector = function (state) { var _a, _b; return !!((_b = (_a = betslipSelector(state).data) === null || _a === void 0 ? void 0 : _a.to) === null || _b === void 0 ? void 0 : _b.abs); };
export var betslipLoadingSelector = createSelector(betslipSelector, function (data) { return data.loading; });
export var betslipIsPendingSelector = function (state) {
    return betslipLoadingSelector(state) === 'pending';
};
export var betslipIsFulfilledSelector = function (state) {
    return betslipLoadingSelector(state) === 'fulfilled';
};
export var betslipIsRejectedSelector = function (state) {
    return betslipLoadingSelector(state) === 'rejected';
};
var defaultEvents = [];
export var betslipEventsSelector = function (state) { var _a; return ((_a = betslipDataSelector(state).to) === null || _a === void 0 ? void 0 : _a.evs) || defaultEvents; };
export var betslipInactiveEventsSelector = function (state) {
    return betslipEventsSelector(state).filter(function (evt) { return !isEventActive(evt); });
};
var defaultCombinations = [];
export var selectBetslipCombinations = function (state) {
    var _a, _b;
    return ((_b = (_a = betslipSelector(state).data) === null || _a === void 0 ? void 0 : _a.to) === null || _b === void 0 ? void 0 : _b.sy) || defaultCombinations;
};
export var selectBetType = createSelector(selectBetslipCombinations, function (combinations) {
    var _a, _b;
    return (((_b = (_a = combinations.find(function (_a) {
        var ssel = _a.ssel, usel = _a.usel;
        return ssel || usel;
    })) === null || _a === void 0 ? void 0 : _a.bss) === null || _b === void 0 ? void 0 : _b.bssn) || 'SYSTEM');
});
export var selectSelectedCombinationsCount = createSelector(selectBetslipCombinations, function (combinations) {
    return combinations.reduce(function (acc, _a) {
        var ssel = _a.ssel, usel = _a.usel;
        return (ssel || usel ? acc + 1 : acc);
    }, 0);
});
export var selectSelectedBetsCount = createSelector(selectBetslipCombinations, function (combinations) {
    return combinations.reduce(function (acc, _a) {
        var usel = _a.usel, ssel = _a.ssel, nbet = _a.nbet;
        return (ssel || usel ? acc + Number(nbet) : acc);
    }, 0);
});
export var selectHasInactiveEvents = createSelector(betslipEventsSelector, function (bsEvents) {
    var inactiveEvents = bsEvents.filter(function (evt) { return !isEventActive(evt); });
    var hasInactiveEvents = inactiveEvents.length > 0;
    return hasInactiveEvents;
});
