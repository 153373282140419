import React from 'react';
import cn from 'classnames';
import { SelectionButton } from '~atoms';
import { useStyles } from './OptionsDropDownControlls.styles';
var noop = function () { return undefined; };
var OptionsDropDownControlls = function (_a) {
    var optionName = _a.optionName, _b = _a.palette, palette = _b === void 0 ? 'dark' : _b, anchorRef = _a.anchorRef, _c = _a.open, open = _c === void 0 ? false : _c, _d = _a.displayLeftArrow, displayLeftArrow = _d === void 0 ? false : _d, _e = _a.displayRightArrow, displayRightArrow = _e === void 0 ? false : _e, _f = _a.onOptionsClick, onOptionsClick = _f === void 0 ? noop : _f, _g = _a.onArrowClick, onArrowClick = _g === void 0 ? noop : _g;
    var classes = useStyles();
    var handleLeftArrowClick = function () { return onArrowClick('left'); };
    var handleRightArrowClick = function () { return onArrowClick('right'); };
    return (React.createElement("div", { className: classes.container },
        React.createElement(SelectionButton, { palette: palette, className: cn(classes.arrow, !displayLeftArrow && classes.disabled), onClick: displayLeftArrow ? handleLeftArrowClick : noop },
            React.createElement("i", { className: "far fa-chevron-left" })),
        React.createElement(SelectionButton, { palette: palette, bordered: true, className: classes.chosenOption, ref: anchorRef, onClick: onOptionsClick },
            React.createElement("span", { className: classes.optionName }, optionName),
            React.createElement("i", { className: cn('far fa-chevron-down', open && classes.open) })),
        React.createElement(SelectionButton, { palette: palette, className: cn(classes.arrow, !displayRightArrow && classes.disabled), onClick: displayRightArrow ? handleRightArrowClick : noop },
            React.createElement("i", { className: "far fa-chevron-right" }))));
};
export { OptionsDropDownControlls };
