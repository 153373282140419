import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import shopIcon from '~icons/balance/shop.svg';
import onlineIcon from '~icons/balance/online.svg';
import jackpotIcon from '~icons/balance/jackpot.svg';
import CSCoinIcon from '~icons/balance/CS_Coin.svg';
import { Currency } from '~atoms';
import { CSIcon } from "~icons/rankingList/CSIcon";
import { useStyles } from './BalanceCard.styles';
export var BalanceCard = function (_a) {
    var type = _a.type, _b = _a.currency, currency = _b === void 0 ? 'NONE' : _b, _c = _a.isActive, isActive = _c === void 0 ? true : _c, balance = _a.balance, cardClassName = _a.cardClassName, onClick = _a.onClick;
    var classes = useStyles();
    var t = useTranslation('tip-game').t;
    var imgSrc = type === 'SHOP' ? shopIcon : type === 'ONLINE' ? onlineIcon : type === 'CREDITS' ? jackpotIcon : CSCoinIcon;
    var titleText = type === 'SHOP'
        ? t('payments.shop_balance')
        : type === 'ONLINE'
            ? t('payments.online_balance')
            : type === 'CREDITS'
                ? t('payments.credit_balance')
                : t('payments.jackpot_win');
    var currencyValue = currency === 'CRD' ? React.createElement(CSIcon, { size: "sm" }) : React.createElement(Currency, { currency: currency });
    return (React.createElement("div", { role: "button", tabIndex: 0, className: classNames(classes.wrapper, isActive && classes.active, onClick && classes.cursorPointer, cardClassName && cardClassName), onClick: onClick },
        React.createElement("img", { src: imgSrc, alt: 'balance icon' }),
        React.createElement("span", { className: classes.text }, titleText),
        balance && (React.createElement("span", { className: classes.amount },
            balance,
            " ",
            currencyValue)),
        isActive && (React.createElement("div", { className: classes.checkMark },
            React.createElement("i", { className: "fa fa-check fa-xs", "aria-hidden": "true" })))));
};
