var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import MenuList from '@material-ui/core/MenuList';
import Grow from '@material-ui/core/Grow';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Button } from '~atoms/button';
import { betslipDataSelector } from '~slices/betslip';
import { useFirst, useIsDesktop, useOpenLogin, useStructuredSelector, } from '~hooks';
import { isAuthenticatedSelector } from '~slices/auth';
import { isBetCheckProject } from '~utils/constants/betCheckConstants';
import { useStyles } from '../topRanking.styles';
// TODO refactor add translation move options to the separate component
export var Header = function (_a) {
    var _b, _c, _d, _e, _f;
    var _g;
    var sortOptions = _a.sortOptions, selectedOption = _a.selectedOption, handleChange = _a.handleChange, maxOdd = _a.maxOdd;
    var classes = useStyles();
    var t = useTranslation('ticket').t;
    var isDesktop = useIsDesktop();
    var _h = React.useState(false), open = _h[0], setOpen = _h[1];
    var anchorRef = React.useRef(null);
    var _j = useStructuredSelector({
        betslip: betslipDataSelector,
        isAuthenticated: isAuthenticatedSelector,
    }), betslip = _j.betslip, isAuthenticated = _j.isAuthenticated;
    var prevMO = useFirst(maxOdd);
    var ticketOffer = betslip.to;
    var isBetStop = (_g = ticketOffer === null || ticketOffer === void 0 ? void 0 : ticketOffer.evs) === null || _g === void 0 ? void 0 : _g.some(function (item) { return item.bs; });
    var handleToggle = function () {
        setOpen(function (prevOpen) { return !prevOpen; });
    };
    var openLogin = useOpenLogin().openLogin;
    var handleClose = function (event) {
        if (anchorRef.current &&
            anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    var handleListKeyDown = function (event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    };
    return (React.createElement("div", { className: classNames('flex-row', isDesktop ? 'flex-end' : 'space-between', classes.subheader, (_b = {}, _b[classes.subheader_center] = isDesktop, _b)) },
        !isDesktop && (React.createElement(React.Fragment, null,
            React.createElement("button", { className: classes.menuButton, type: "button" },
                React.createElement("i", { className: classNames((_c = {
                            'fas fa-chart-line': +maxOdd >= Number(prevMO) || !prevMO,
                            'far fa-chart-line-down': +maxOdd < Number(prevMO)
                        },
                        _c[classes.trendIconHigher] = +maxOdd > Number(prevMO),
                        _c[classes.trendIconLower] = +maxOdd < Number(prevMO),
                        _c[classes.trendIconEqual] = +maxOdd === Number(prevMO) || !prevMO,
                        _c)) }),
                t('trend')),
            React.createElement("button", { className: classNames((_d = {},
                    _d[classes.menuButton] = true,
                    _d[classes.betstop] = isBetStop,
                    _d)), type: "button" },
                React.createElement("i", { className: "far fa-octagon" }),
                t('betstop')))),
        !isAuthenticated && !isBetCheckProject ? (React.createElement(Button, { className: classNames(classes.menuButton, (_e = {},
                _e[classes.menuButton] = true,
                _e[classes.menuButton_big] = isDesktop,
                _e)), onClick: openLogin, type: "button" }, t(sortOptions[0]))) : (React.createElement(React.Fragment, null,
            React.createElement(Button, { ref: anchorRef, "aria-controls": open ? 'menu-list-grow' : undefined, "aria-haspopup": "true", onClick: handleToggle, className: classNames(classes.menuButton, classes.maxPayout, (_f = {},
                    _f[classes.menuButton_big] = isDesktop,
                    _f)) },
                React.createElement("div", null, t(selectedOption)),
                open ? (React.createElement("i", { className: classNames('far fa-chevron-up') })) : (React.createElement("i", { className: classNames('far fa-chevron-down') }))),
            React.createElement(Popper, { open: open, anchorEl: anchorRef.current, role: undefined, transition: true, disablePortal: true, style: { zIndex: 1 } }, function (_a) {
                var TransitionProps = _a.TransitionProps, placement = _a.placement;
                return (React.createElement(Grow, __assign({}, TransitionProps, { style: {
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                    } }),
                    React.createElement(Paper, { className: classes.popover },
                        React.createElement(ClickAwayListener, { onClickAway: handleClose },
                            React.createElement(MenuList, { autoFocusItem: open, id: "menu-list-grow", onKeyDown: handleListKeyDown }, sortOptions.map(function (option) {
                                var _a;
                                return (React.createElement(MenuItem, { key: option, onClick: function (e) {
                                        handleChange(option);
                                        handleClose(e);
                                    }, className: classNames(classes.menuItem, (_a = {},
                                        _a[classes.activeButton] = option === selectedOption,
                                        _a)) },
                                    React.createElement("div", { className: classes.checkedBlock }, option === selectedOption && (React.createElement(ListItemIcon, null,
                                        React.createElement("i", { className: classNames('far fa-check', classes.checkedIcon) })))),
                                    React.createElement("span", { className: classes.itemText }, t(option))));
                            }))))));
            })))));
};
