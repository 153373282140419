var ScrollWatcher = /** @class */ (function () {
    function ScrollWatcher() {
        var _this = this;
        this.setScrollTop = function (pos) {
            _this.scrollTop = pos;
        };
        this.setHistory = function (pp, cp, la) {
            if (pp === void 0) { pp = ''; }
            if (cp === void 0) { cp = ''; }
            _this.prevPath = pp;
            _this.currentPath = cp;
            _this.lastAction = la;
        };
        this.setSouldScroll = function (ss) {
            _this.shouldScroll = ss;
        };
        this.afterScroll = function () {
            _this.scrollTop = -1;
            _this.shouldScroll = false;
        };
        this.getState = function () {
            return {
                scrollTop: _this.scrollTop,
                prevPath: _this.prevPath,
                currentPath: _this.currentPath,
                shouldScroll: _this.shouldScroll,
                lastAction: _this.lastAction,
            };
        };
        this.getScrollTop = function () {
            return _this.scrollTop;
        };
        this.scrollTop = -1;
        this.prevPath = '';
        this.currentPath = '';
        this.shouldScroll = false;
        this.lastAction = 'PUSH';
    }
    return ScrollWatcher;
}());
export default new ScrollWatcher();
