import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var GymnasticsIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0)" },
            React.createElement("path", { d: "M11.4844 0V4.92188L9.02344 6.41479L8.79375 6.5625H8.30156L6.5625 4.92188V0H11.4844Z", fill: "#FFF489" }),
            React.createElement("path", { d: "M11.4844 4.92188L9.02344 6.41479V0H11.4844V4.92188Z", fill: "#FBBE00" }),
            React.createElement("path", { d: "M21.4375 0V4.92188L18.9766 6.41479L18.7469 6.5625H18.2547L16.5156 4.92188V0H21.4375Z", fill: "#FFF489" }),
            React.createElement("path", { d: "M21.4375 4.92188L18.9766 6.41479V0H21.4375V4.92188Z", fill: "#FBBE00" }),
            React.createElement("path", { d: "M22.7993 10.6968L21.4375 8.20308L19.0421 7.00537L18.9766 7.03818L16.5156 8.20308L15.1539 10.6968C11.9875 12.1733 9.95312 15.3562 9.95312 18.8671C9.95312 23.8382 14.0054 28 18.9766 28C23.9477 28 28 23.8382 28 18.8671C28 15.3562 25.9656 12.1733 22.7993 10.6968ZM18.9766 24.7187C15.8102 24.7187 13.2344 22.0336 13.2344 18.8671C13.2344 15.7007 15.8102 13.125 18.9766 13.125C22.143 13.125 24.7188 15.7007 24.7188 18.8671C24.7188 22.0336 22.143 24.7187 18.9766 24.7187Z", fill: "#EDE0DC" }),
            React.createElement("path", { d: "M28 18.8672C28 23.8382 23.9477 28 18.9766 28V24.7188C22.143 24.7188 24.7188 22.0336 24.7188 18.8672C24.7188 15.7008 22.143 13.125 18.9766 13.125V7.03824L19.0421 7.00537L21.4375 8.20314L22.7993 10.6968C25.9656 12.1734 28 15.3563 28 18.8672Z", fill: "#E0D2CD" }),
            React.createElement("path", { d: "M12.8461 10.6968L11.4844 8.20308L9.08901 7.00537L9.02344 7.03818L6.5625 8.20308L5.20073 10.6968C2.03437 12.1733 0 15.3562 0 18.8671C0 23.8382 4.05229 28 9.02344 28C13.9946 28 18.0469 23.8382 18.0469 18.8671C18.0469 15.3562 16.0125 12.1733 12.8461 10.6968ZM9.02344 24.7187C5.85703 24.7187 3.28125 22.0336 3.28125 18.8671C3.28125 15.7007 5.85703 13.125 9.02344 13.125C12.1898 13.125 14.7656 15.7007 14.7656 18.8671C14.7656 22.0336 12.1898 24.7187 9.02344 24.7187Z", fill: "#EDE0DC" }),
            React.createElement("path", { d: "M18.0469 18.8672C18.0469 23.8382 13.9946 28 9.02344 28V24.7188C12.1898 24.7188 14.7656 22.0336 14.7656 18.8672C14.7656 15.7008 12.1898 13.125 9.02344 13.125V7.03824L9.08901 7.00537L11.4844 8.20314L12.8461 10.6968C16.0125 12.1734 18.0469 15.3563 18.0469 18.8672Z", fill: "#E0D2CD" }),
            React.createElement("path", { d: "M6.5625 4.92188V8.20312H11.4844V4.92188H6.5625Z", fill: "#766E6E" }),
            React.createElement("path", { d: "M9.02344 4.92188H11.4844V8.20312H9.02344V4.92188Z", fill: "#5B5555" }),
            React.createElement("path", { d: "M16.5156 4.92188V8.20312H21.4375V4.92188H16.5156Z", fill: "#766E6E" }),
            React.createElement("path", { d: "M18.9766 4.92188H21.4375V8.20312H18.9766V4.92188Z", fill: "#5B5555" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0" },
                React.createElement("rect", { width: "28", height: "28", fill: "white" })))));
};
