import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var SwimmingPoolIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M16.2104 1.76855C14.1789 1.76855 12.5262 3.42127 12.5262 5.45274H14.7367C14.7367 4.64014 15.3979 3.97908 16.2104 3.97908C17.023 3.97908 17.6841 4.6402 17.6841 5.45274V7.66327H10.3157V5.45274C10.3157 3.42127 8.66298 1.76855 6.63151 1.76855C4.60003 1.76855 2.94727 3.42127 2.94727 5.45274H5.15779C5.15779 4.64014 5.81891 3.97908 6.63145 3.97908C7.444 3.97908 8.10512 4.6402 8.10512 5.45274V19.4527H10.3156V14.2948H17.6841V19.4527H19.8946V5.45274C19.8946 3.42127 18.2419 1.76855 16.2104 1.76855ZM10.3157 12.0843V9.87379H17.6841V12.0843H10.3157Z", fill: "#AAAAAA" }),
        React.createElement("path", { d: "M16.2101 1.76855C14.1786 1.76855 12.5259 3.42127 12.5259 5.45274H14.7364C14.7364 4.64014 15.3975 3.97908 16.2101 3.97908C17.0227 3.97908 17.6837 4.6402 17.6837 5.45274V7.66327H13.9995V9.87379H17.6837V12.0843H13.9995V14.2948H17.6837V19.4527H19.8942V5.45274C19.8942 3.42127 18.2415 1.76855 16.2101 1.76855Z", fill: "#747474" }),
        React.createElement("path", { d: "M26.8947 16.5054C24.9996 16.5054 24.0112 17.0475 23.1393 17.5258C22.3775 17.9437 21.7757 18.2738 20.4471 18.2738C19.1185 18.2738 18.5168 17.9437 17.7549 17.5258C16.883 17.0475 15.8946 16.5054 13.9995 16.5054C12.1044 16.5054 11.1162 17.0475 10.2443 17.5258C9.48254 17.9437 8.88081 18.2738 7.55229 18.2738C6.22382 18.2738 5.62215 17.9437 4.86041 17.5258C3.98852 17.0475 3.00027 16.5054 1.10523 16.5054H0V26.2317H28V16.5054H26.8947Z", fill: "#BAEFFA" }),
        React.createElement("path", { d: "M26.8948 16.5054C24.9996 16.5054 24.0113 17.0475 23.1394 17.5258C22.3776 17.9437 21.7758 18.2738 20.4472 18.2738C19.1185 18.2738 18.5169 17.9437 17.755 17.5258C16.8831 17.0475 15.8949 16.5055 14 16.5054V26.2317H28V16.5054H26.8948Z", fill: "#74E0F6" })));
};
