import i18next from 'i18next';
import { connectSocket } from '~slices/betslip';
import { BETSLIP_SOCKET_NAME } from '~slices/events';
import store from '~store/configureStore';
var WEBSOCKET = process.env.REACT_APP_BASE_WEBSOCKET || '';
export var dispatchBetSlipWS = function (betSlipId) {
    if (betSlipId === void 0) { betSlipId = undefined; }
    store.dispatch(connectSocket({
        eventsType: BETSLIP_SOCKET_NAME,
        url: "".concat(WEBSOCKET, "sport-events/v1/ws/betSlip"),
        langIso: i18next.language,
        withOutMessage: true,
        withOutInterval: true,
        betSlipId: betSlipId,
    }));
};
