import makeStyles from '@material-ui/core/styles/makeStyles';
export var useStyles = makeStyles({
    wrapper: {
        padding: '0 16px 11px 16px',
        marginBottom: 8,
        background: '#2D2D2E',
        border: '1px solid #1B66B9',
        borderRadius: 8,
        cursor: 'pointer',
    },
    tipsWrapper: {
        maxHeight: '480px',
        minHeight: '102px',
        overflow: 'auto',
    },
    date: {
        width: 'fit-content',
        padding: '4px 12px',
        fontSize: 8,
        fontWeight: 500,
        color: '#FFFFFF',
        background: 'linear-gradient(90deg, #211A92 0%, #1B66B9 0.01%, #2C479A 100%)',
        borderRadius: '0px 0px 4px 4px',
    },
    contentContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 9,
    },
    name: {
        marginRight: 24,
        fontSize: 12,
        color: '#FFFFFF',
        opacity: 0.6,
    },
    rank: {
        fontSize: 12,
        color: '#FFFFFF',
        '& span': {
            opacity: 0.6,
        },
    },
    credits: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 12,
        color: '#FFFFFF',
        '& img': {
            marginLeft: 4,
            marginRight: 6,
            height: 12,
            width: 12,
        },
    },
    smallLoader: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        background: 'rgba(15,15,15,0.5)',
        zIndex: 10,
    },
}, { name: 'ArchiveTicket' });
