export var formatLargeNumber = function (num) {
    if (num < 1000000) {
        var parts = num.toFixed().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        return parts.join('');
    }
    else if (num < 1000000000) {
        var millions = Math.floor(num / 1000000);
        var remainder = num % 1000000;
        var thousands = Math.floor(remainder / 1000);
        return "".concat(millions, "M ").concat(thousands, "k");
    }
    else {
        var billions = Math.floor(num / 1000000000);
        var remainder = num % 1000000000;
        var millions = Math.floor(remainder / 1000000);
        return "".concat(billions, "B ").concat(millions, "M");
    }
};
