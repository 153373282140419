import React from 'react';
import groupBy from 'lodash.groupby';
import { useStructuredSelector } from '~hooks';
import { categoriesDataSelector } from '~slices/outrights';
import { ItemsList } from './ItemsList';
import { Category } from './Category';
var Outrights = function (_a) {
    var onSelect = _a.onSelect;
    var _b = useStructuredSelector({
        categories: categoriesDataSelector,
    }).categories, categories = _b === void 0 ? [] : _b;
    var grouppedCategories = groupBy(categories, function (item) {
        var _a;
        return (_a = item === null || item === void 0 ? void 0 : item.category) === null || _a === void 0 ? void 0 : _a.nm;
    });
    return (React.createElement(ItemsList, null, Object.entries(grouppedCategories).map(function (_a) {
        var _b;
        var categoryName = _a[0], categories = _a[1];
        var categoryTournaments = categories.map(function (_a) {
            var tournament = _a.tournament;
            return tournament;
        });
        return (React.createElement(Category, { key: "".concat(categoryName, "-").concat((_b = categoryTournaments === null || categoryTournaments === void 0 ? void 0 : categoryTournaments[0]) === null || _b === void 0 ? void 0 : _b.bid), categoryName: categoryName, onSelect: onSelect, 
            // @ts-expect-error
            tournaments: categoryTournaments, outrights: true }));
    })));
};
export { Outrights };
