import React from 'react';
import { useLocation } from 'react-router-dom';
import { useIsDesktop, useStructuredSelector } from '~hooks';
import { TicketDetails } from '~pages/top-ranking/components/TicketDetails';
import { providerIdSelector } from '~slices/top-ranking';
import { activeTicketIdSelector, useActiveArchiveTicketSelector } from '~slices/tickets';
import { BPRegistration, Betslip } from '~organisms';
import { AdvertisingBanners } from '~molecules';
import { isBetCheckProject } from '~utils/constants/betCheckConstants';
import { RankingListDesktop } from '~pages/ranking-list';
import { TipDetailsBlock } from "~pages/tips-list/components/ticketDetails/Desktop/TipDetailsBlock";
import { ArchiveTipDetailsBlock } from "~pages";
import { useRightSideStyles } from './RightSide.styles';
var mapComponentToRoute = {
    '/top-ranking': TicketDetails,
    '/top-ranking-ticket': BPRegistration,
    '/bp-list-ticket': BPRegistration,
    '/my-tickets/open-ticket': BPRegistration,
    '/my-tickets/won-ticket': BPRegistration,
    '/my-tickets/all-ticket': BPRegistration,
    '/my-tickets/search-ticket': BPRegistration,
};
var MemoizedBetslip = React.memo(Betslip);
export var RightSide = React.memo(function RightSide() {
    var classes = useRightSideStyles();
    var pathname = useLocation().pathname;
    var isDesktop = useIsDesktop();
    var isBetCheck = isBetCheckProject;
    var _a = useStructuredSelector({
        providerId: providerIdSelector,
        activeID: activeTicketIdSelector,
        archiveTicket: useActiveArchiveTicketSelector,
    }), providerId = _a.providerId, activeID = _a.activeID, archiveTicket = _a.archiveTicket;
    if (pathname[0] === '/' && pathname[3] === '/') {
        pathname = pathname.slice(3);
    }
    var Component = mapComponentToRoute[providerId ? "".concat(pathname, "-ticket") : pathname] ||
        (activeID && TipDetailsBlock) ||
        (archiveTicket && ArchiveTipDetailsBlock) ||
        MemoizedBetslip;
    return isDesktop ? (React.createElement("div", { className: classes.rightSide },
        React.createElement(Component, null),
        isBetCheck ? React.createElement(AdvertisingBanners, null) : React.createElement(RankingListDesktop, null))) : null;
});
