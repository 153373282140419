import { getHeaderOddNamesCount } from './getHeaderOddNamesCount';
import { getMatrixLine } from './getMatrixLine';
import { getPositionsMapper } from './getPositionsMapper';
var getIsOcNamesSimilar = function (oc, prevOc, positionsMap, optionName, prevOptionName) {
    var lengthToValidate = getHeaderOddNamesCount(optionName);
    var prevLengthToValidate = getHeaderOddNamesCount(prevOptionName);
    var matrix = getMatrixLine(lengthToValidate, 1);
    var positionMapper = getPositionsMapper(oc, positionsMap[optionName]);
    var prevPositionMapper = getPositionsMapper(prevOc, positionsMap[prevOptionName]);
    if (lengthToValidate !== prevLengthToValidate) {
        return false;
    }
    for (var i = 0; i < lengthToValidate; i++) {
        var position = matrix[i];
        var odd = positionMapper(position);
        var prevOdd = prevPositionMapper(position);
        if (typeof odd === 'string' ||
            typeof prevOdd === 'string' ||
            odd.nm !== prevOdd.nm) {
            return false;
        }
    }
    return true;
};
export { getIsOcNamesSimilar };
