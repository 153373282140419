import makeStyles from '@material-ui/core/styles/makeStyles';
var useStyles = makeStyles({
    container: {
        fontSize: '12px',
        lineHeight: '14px',
        color: '#8E8E8E',
        '& > *': {
            textDecoration: 'none',
        },
    },
}, { name: 'InvisibleRecaptcha' });
export { useStyles };
