import { TournamentLabelType } from '~/constants/view/TournamentLabelType';
var getTournamentLabel = function (live, np) {
    if (live) {
        return TournamentLabelType.LIVE;
    }
    if (np &&
        np * 1000 > Date.now() &&
        new Date(np * 1000).getDate() === new Date(Date.now()).getDate()) {
        return TournamentLabelType.TODAY;
    }
};
export { getTournamentLabel };
