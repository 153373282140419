var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { KEY_PREFIX, REHYDRATE } from 'redux-persist/lib/constants';
import isEqual from 'lodash.isequal';
var deepJsonParse = function (data) {
    return Object.entries(JSON.parse(data)).reduce(
    // @ts-expect-error
    function (acc, _a) {
        var _b;
        var key = _a[0], value = _a[1];
        return (__assign(__assign({}, acc), (_b = {}, _b[key] = JSON.parse(value), _b)));
    }, {});
};
function persistCrossTab(store) {
    function handleStorageEvent(e) {
        if (e.key && e.key.indexOf(KEY_PREFIX) === 0 && e.oldValue !== e.newValue) {
            // @ts-expect-error
            var oldValue = deepJsonParse(e.oldValue);
            // @ts-expect-error
            var newValue = deepJsonParse(e.newValue);
            if (isEqual(oldValue, newValue))
                return;
            // For situations when stringified values won't be equal
            // and the difference between the old and the new value
            // will be only in order of keys
            var key = e.key.split(':')[1]; // persist:auth — key is `auth`
            var action = {
                type: REHYDRATE,
                payload: newValue,
                key: key,
            };
            store.dispatch(action);
        }
    }
    window.addEventListener('storage', handleStorageEvent);
}
export { persistCrossTab };
