import React from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { StatusScreen } from '~molecules';
import failed from '~icons/statusIcons/failed.svg';
import { ConfirmationModal } from './ConfirmationModal';
var useErrorModalStyles = makeStyles({
    color: {
        color: '#212121',
    },
    fullWidth: { width: '100%' },
    header: {
        marginTop: '16px',
        color: '#212121',
    },
    logo: {
        padding: '12px 0 0',
    },
}, { name: 'FastPlayNotAvailableModal' });
var FastPlayNotAvailableModal = function (_a) {
    var modalName = _a.modalName, modalProps = _a.modalProps, isVisible = _a.isVisible;
    var t = useTranslation('errors').t;
    var classes = useErrorModalStyles();
    return (React.createElement(ConfirmationModal, { modalName: modalName, modalProps: modalProps, isVisible: isVisible },
        React.createElement(React.Fragment, null,
            React.createElement(StatusScreen, { header: t('fast_play_not_available'), subheader: t('fast_play_not_available_description'), subheaderClassName: classes.color, logo: failed, headerClassName: classes.header, logoClasses: classes.logo }))));
};
export { FastPlayNotAvailableModal };
