import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var KabaddiIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("svg", { width: "28", height: "28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M4.25 9.87a.39.39 0 0 1 .55.05l1.9 2.27 2.48.4.19-.23a.39.39 0 0 1 .59.5l-.33.4a.39.39 0 0 1-.36.12l-2.83-.44a.39.39 0 0 1-.24-.14l-2-2.38a.39.39 0 0 1 .05-.55Z", fill: "#E89561" }),
            React.createElement("path", { d: "M21.83 12.62c.2.04.34.25.3.46l-.17.83.67 1.66.02.04.22.78a.39.39 0 1 1-.75.22l-.21-.76-.72-1.76a.39.39 0 0 1-.02-.23l.2-.94a.39.39 0 0 1 .46-.3Z", fill: "#D28656" }),
            React.createElement("path", { d: "M22 11.46c.3.05.5.33.45.64l-.2 1.16-1.09-.19.2-1.16c.05-.3.33-.5.64-.45Z", fill: "#FA5E05" }),
            React.createElement("path", { d: "m9.27 11.26.1.1c.24.26.27.66.06.94l-.2.29c-.1.14-.23.26-.4.34l-.72.4a.17.17 0 0 1-.23-.24l1.04-1.78a.22.22 0 0 1 .35-.05ZM16.39 19.6l.1-.09a.74.74 0 0 1 .95 0l.27.22c.13.12.24.26.31.42l.34.76a.17.17 0 0 1-.25.2l-1.7-1.16a.22.22 0 0 1-.02-.35Z", fill: "#2583B8" }),
            React.createElement("path", { d: "m23.96 12.23-.13.76a.4.4 0 0 0 .55.45l.9-.34a.88.88 0 1 0-.68-1.63l-.1.04c-.28.14-.49.4-.54.72Z", fill: "#FFAE7B" }),
            React.createElement("path", { d: "m24.8 11.27-.68.31a.16.16 0 0 0-.07.22c.04.08.13.1.21.07.17-.05.38-.1.5 0 .18.17.06.34 0 .35l-.02.01a.14.14 0 1 0 .1.26l.18-.06c.17-.07.37.02.43.19l.06.15a.39.39 0 0 0 .73-.27l-.29-.76a.85.85 0 0 0-1.15-.47Z", fill: "#424242" }),
            React.createElement("path", { d: "M16.8 11.73c.17.29.08.67-.2.84l-2.5 1.56a.61.61 0 0 1-.5.07l-3.94-1.17a.61.61 0 0 1 .35-1.17l3.68 1.09 2.26-1.41c.29-.18.67-.1.84.2Z", fill: "#D28656" }),
            React.createElement("path", { d: "M18 12.21c.3-.13.66.02.8.33l1.14 2.71c.06.16.06.34-.02.5l-1.77 3.71a.61.61 0 0 1-1.1-.52l1.65-3.47-1.03-2.46a.61.61 0 0 1 .32-.8Z", fill: "#F2A473" }),
            React.createElement("path", { d: "M23.7 11.98c-.07.6-.63 1.03-1.24.95l-5.05-.67a1.11 1.11 0 1 1 .29-2.2l5.05.67c.61.08 1.04.64.96 1.25Z", fill: "#FF8514" }),
            React.createElement("path", { d: "M17.96 10.57c.3.39.22.94-.17 1.24l-1.81 1.38-1.08-1.42 1.81-1.37c.4-.3.95-.22 1.25.17Z", fill: "#FA5E05" }),
            React.createElement("path", { d: "M17.17 10.28a.89.89 0 0 1 1.15.51l.81 2.12-1.66.64-.81-2.12a.89.89 0 0 1 .5-1.15Z", fill: "#FA5E05" }),
            React.createElement("path", { d: "M22.72 12.28c.21-.03.4.12.44.33l.43 3.02 1.8 2.5.7.64a.39.39 0 0 1-.52.57l-.73-.67a.39.39 0 0 1-.05-.06l-1.89-2.6a.39.39 0 0 1-.07-.18l-.44-3.1a.39.39 0 0 1 .33-.45Z", fill: "#F2A473" }),
            React.createElement("path", { d: "M22.68 11.47c.3-.02.57.2.6.51l.09 1.17-1.1.09-.1-1.17c-.02-.3.2-.58.51-.6Z", fill: "#FA5E05" }),
            React.createElement("path", { d: "m4.24 19.94.04-.13c.1-.34.42-.57.77-.53l.35.03c.17.01.34.07.5.16l.7.43a.17.17 0 0 1-.09.32H4.45a.22.22 0 0 1-.21-.28ZM8.46 19.1l-.06-.13a.74.74 0 0 1 .29-.9l.29-.19c.15-.1.31-.15.49-.17l.82-.1a.17.17 0 0 1 .13.3L8.79 19.2a.22.22 0 0 1-.33-.09Z", fill: "#B82556" }),
            React.createElement("path", { d: "M3.71 9.46c.21-.22.56-.23.79-.03l.82.77-.75.82-.83-.77a.56.56 0 0 1-.03-.79Z", fill: "#3D79E7" }),
            React.createElement("path", { d: "M1.79 14.56a.61.61 0 0 1 .7-.5l4 .67c.14.02.27.1.36.2l2.12 2.5a.61.61 0 0 1-.94.8L6.07 15.9l-3.78-.63a.61.61 0 0 1-.5-.7Z", fill: "#E89561" }),
            React.createElement("path", { d: "M4.21 9.42c.29.18.38.56.2.85l-2.34 3.77 3.23.13a.61.61 0 0 1 .59.64l-.23 4.28a.61.61 0 0 1-1.22-.07l.2-3.66-3.66-.14a.61.61 0 0 1-.5-.93l2.89-4.67a.61.61 0 0 1 .84-.2ZM4 9.04l.66.39a.4.4 0 0 0 .62-.35V8.1a.88.88 0 1 0-1.77.06v.1c.03.32.21.6.49.77Z", fill: "#FFAE7B" }),
            React.createElement("path", { d: "m3.4 7.91.05.74c0 .1.09.16.18.15.08-.01.14-.09.14-.17 0-.18.04-.4.17-.46.23-.11.34.05.34.1v.04a.14.14 0 0 0 .27 0v-.2c0-.18.15-.33.34-.33h.16a.39.39 0 1 0 0-.78h-.8c-.5 0-.89.42-.85.91Z", fill: "#8E4B0D" }),
            React.createElement("path", { d: "M4.25 9.28c.52.32.68 1 .36 1.53l-2.44 3.94a1.11 1.11 0 0 1-1.9-1.17l2.45-3.94c.33-.53 1.01-.69 1.53-.36Z", fill: "#548EF7" }),
            React.createElement("path", { d: "M0 14.27a.89.89 0 0 1 1-.76l2.26.3-.24 1.76-2.25-.3a.89.89 0 0 1-.76-1Z", fill: "#3D79E7" }),
            React.createElement("path", { d: "M3.54 9.52a.39.39 0 0 1 .55-.03l2.22 1.95 2.73-.1c.1-.01.18.02.25.07l.5.4a.39.39 0 1 1-.47.6l-.4-.3-2.74.11c-.1 0-.2-.03-.27-.1l-2.33-2.05a.39.39 0 0 1-.04-.55Z", fill: "#FFAE7B" }),
            React.createElement("path", { d: "M3.42 9.41c.2-.23.55-.25.78-.05l.88.78-.73.83-.88-.78a.56.56 0 0 1-.05-.78Z", fill: "#3D79E7" }))));
};
