import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useIsDesktop } from '~hooks';
import { useStyles } from './ProviderLogo.styles';
var BASE_URL = process.env.REACT_APP_BASE_URL || '';
export var ProviderLogo = function (_a) {
    var _b;
    var id = _a.id, money = _a.money, isBalance = _a.isBalance, backgroundColor = _a.backgroundColor, outlineColor = _a.outlineColor;
    if (!id)
        return null;
    var classes = useStyles();
    var isXLDesktop = useIsDesktop('xl');
    var t = useTranslation('bpList').t;
    var isWhiteBackground = (backgroundColor === null || backgroundColor === void 0 ? void 0 : backgroundColor.toLowerCase()) === '#ffffff' ? 'is-white' : '';
    return (React.createElement("div", { style: { backgroundColor: backgroundColor }, className: "".concat(classes.logoWrapper, " ").concat(isWhiteBackground) },
        React.createElement("img", { alt: "logo", className: classes.logoImg, src: "".concat(BASE_URL, "bp/v1/bp-integrator/providers/logo/").concat(id, "/MAIN") }),
        isXLDesktop && React.createElement("div", { className: classes.divider }),
        React.createElement("div", { style: outlineColor
                ? {
                    color: outlineColor,
                    borderColor: outlineColor,
                }
                : undefined, className: classNames((_b = {},
                _b[classes.balance] = true,
                _b[classes.welcomeButton] = !isXLDesktop && !isBalance,
                _b)) },
            React.createElement("div", { className: classNames('flex-row flex-center space-between', classes.fullWidth) },
                React.createElement("div", { className: classes.buttonText }, isBalance ? t('balance') : t('welcome_bonus')),
                React.createElement("div", null, money)))));
};
