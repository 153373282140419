var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Backdrop from '@material-ui/core/Backdrop';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
import ReactPixel from "react-facebook-pixel";
import { isAuthenticatedSelector } from '~slices/auth';
import { bpDataSelector } from '~slices/bp';
import { Currencies, currencyConverter } from '~utils/converters';
import { ProviderLogo, UnloggedProviderLogo } from '~atoms/providerLogo';
import { useIsDesktop, useStructuredSelector } from '~hooks';
import { getTestId } from '~utils/getTestId';
import { useAppDispatch } from '~store';
import { MODALS, openModal } from '~modals';
import { betslipDataSelector } from '~slices/betslip';
import { useStyles } from '../topRanking.styles';
import { PayIcon } from './PayIcon';
export var itemRenderer = function (item) {
    return React.createElement(TopRankingItem, __assign({}, item));
};
var TopRankingItem = function (_a) {
    var _b;
    var topIndex = _a.topIndex, offer = _a.offer, redirectToSdk = _a.redirectToSdk, selectedProviderId = _a.selectedProviderId;
    var classes = useStyles();
    var isXLDesktop = useIsDesktop('xl');
    var isDesktop = useIsDesktop();
    var dispatch = useAppDispatch();
    var _c = useStructuredSelector({
        betslip: betslipDataSelector,
        isAuthenticated: isAuthenticatedSelector,
        providers: bpDataSelector,
    }), betslip = _c.betslip, isAuthenticated = _c.isAuthenticated, providers = _c.providers;
    var _d = useState('#fff'), bgColor = _d[0], setBgColor = _d[1];
    var _e = useState('#fff'), outlineColor = _e[0], setOutlineColor = _e[1];
    var isLink = (offer === null || offer === void 0 ? void 0 : offer.purchaseMethod) === 'AFFILIATE';
    var isDeepLink = (offer === null || offer === void 0 ? void 0 : offer.purchaseMethod) === 'DEEPLINK';
    var paymentIconClassName = isLink ? 'fa fa-share-square' : 'fa fa-link';
    var isDisplayAnonymousRankingList = process.env.ANONYMOUS_RANKING_LIST === 'true';
    var t = useTranslation('bpList').t;
    var convertedBalance = currencyConverter(offer === null || offer === void 0 ? void 0 : offer.balance, offer === null || offer === void 0 ? void 0 : offer.balanceCurrency);
    var convertedWelcomeBonus = currencyConverter(offer === null || offer === void 0 ? void 0 : offer.welcomeBonus, offer === null || offer === void 0 ? void 0 : offer.currency);
    var convertedMaxPayout = currencyConverter(offer === null || offer === void 0 ? void 0 : offer.maxPayout, 'NONE');
    var currentProvider = providers.responseObject
        ? providers.responseObject.filter(function (provider) { return provider.id === offer.providerId; })[0]
        : null;
    useEffect(function () {
        setBgColor((currentProvider === null || currentProvider === void 0 ? void 0 : currentProvider.backgroundColor) || '#fff');
        setOutlineColor((currentProvider === null || currentProvider === void 0 ? void 0 : currentProvider.outlineColor) || '#fff');
    }, [providers, offer]);
    var addGAEvent = function () {
        var _a, _b, _c, _d;
        var methodName = 'purchase';
        var items = (_b = (_a = betslip.to) === null || _a === void 0 ? void 0 : _a.evs) === null || _b === void 0 ? void 0 : _b.map(function (item, indx) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            var index = indx + 1;
            return {
                item_name: "".concat((_a = item.c1) === null || _a === void 0 ? void 0 : _a.nm, " vs ").concat((_b = item.c2) === null || _b === void 0 ? void 0 : _b.nm, " [").concat(((_c = item.sl) === null || _c === void 0 ? void 0 : _c.sel) ? (_d = item.sl) === null || _d === void 0 ? void 0 : _d.sel[index].na : '', " (").concat(((_e = item.sl) === null || _e === void 0 ? void 0 : _e.sel) ? (_g = (_f = item.sl) === null || _f === void 0 ? void 0 : _f.sel[index].oc) === null || _g === void 0 ? void 0 : _g.na : '', ") ]"),
                item_id: "".concat(item.id, " : ").concat(((_h = item.sl) === null || _h === void 0 ? void 0 : _h.sel) ? (_j = item.sl) === null || _j === void 0 ? void 0 : _j.sel[index].key : ''),
                item_brand: item.sp,
                item_category: item.cn,
                item_category2: item.tn,
                index: index,
            };
        });
        var params = {
            currency: (_c = betslip === null || betslip === void 0 ? void 0 : betslip.to) === null || _c === void 0 ? void 0 : _c.cu,
            transaction_id: betslip === null || betslip === void 0 ? void 0 : betslip.bs,
            value: (_d = betslip === null || betslip === void 0 ? void 0 : betslip.to) === null || _d === void 0 ? void 0 : _d.bw,
            affiliation: offer.providerId,
            items: items,
        };
        ReactGA.event(methodName, params);
        ReactPixel.trackCustom(methodName, params);
    };
    var onClick = function () {
        if (!offer.link) {
            return;
        }
        if (!isAuthenticated && isDisplayAnonymousRankingList) {
            dispatch(openModal({
                modalName: MODALS.SUCCESS_MODAL_NOTIFICATION,
                modalProps: {
                    title: t('success'),
                    subheader: t('success_modal_subtitle'),
                },
            }));
            addGAEvent();
            window.open("".concat(offer.link), '_blank');
        }
        else if (redirectToSdk && !selectedProviderId) {
            if (isLink || isDeepLink) {
                addGAEvent();
            }
            redirectToSdk('purchase-ticket', offer === null || offer === void 0 ? void 0 : offer.providerId);
        }
    };
    var isBalance = Boolean(currentProvider === null || currentProvider === void 0 ? void 0 : currentProvider.betProviderUser);
    return (React.createElement(React.Fragment, null,
        React.createElement("li", { key: offer === null || offer === void 0 ? void 0 : offer.providerId, className: classNames(classes.listItem, !offer.link && classes.notAllowed), "data-testid": isAuthenticated
                ? getTestId('OBJ_BETTING_PROVIDER')
                : getTestId('OBJ_BP_PLACEHOLDER'), onClick: onClick },
            isDesktop && (React.createElement(Backdrop, { className: classes.backdrop, open: !!selectedProviderId && (offer === null || offer === void 0 ? void 0 : offer.providerId) !== selectedProviderId })),
            React.createElement("div", { className: classNames('flex-row', classes.container) },
                React.createElement("div", { className: "flex-row" },
                    React.createElement("div", { className: classNames('flex-row flex-center', (_b = {},
                            _b[classes.bpLogo] = isXLDesktop,
                            _b)) }, !isAuthenticated && !isDisplayAnonymousRankingList ? (React.createElement(UnloggedProviderLogo, null)) : (React.createElement(ProviderLogo, { id: offer === null || offer === void 0 ? void 0 : offer.providerId, money: isBalance ? convertedBalance : convertedWelcomeBonus, isBalance: isBalance, outlineColor: outlineColor, backgroundColor: bgColor }))),
                    React.createElement("div", { className: classNames(classes.walletWrapper, {
                            'flex-row flex-center': isXLDesktop,
                        }) },
                        React.createElement(PayIcon, { isLink: isLink, isDeepLink: isDeepLink, iconClassName: paymentIconClassName }),
                        (offer === null || offer === void 0 ? void 0 : offer.cashOut) && (React.createElement(PayIcon, { providedTextKey: "cash_out", iconClassName: "fal fa-wallet" })))),
                React.createElement("div", { className: classNames('flex-center justify-center', {
                        'flex-column': !isXLDesktop,
                        'flex-row-reverse': isXLDesktop,
                    }) }, !isAuthenticated &&
                    isDisplayAnonymousRankingList &&
                    !(offer === null || offer === void 0 ? void 0 : offer.maxPayout) ? (React.createElement("div", null, t('no_offer_received'))) : (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: classes.money },
                        convertedMaxPayout,
                        React.createElement("small", null, (offer === null || offer === void 0 ? void 0 : offer.currency) && Currencies[offer === null || offer === void 0 ? void 0 : offer.currency]
                            ? Currencies[offer === null || offer === void 0 ? void 0 : offer.currency]
                            : offer === null || offer === void 0 ? void 0 : offer.currency)),
                    React.createElement("span", { className: classes.coeficient }, offer === null || offer === void 0 ? void 0 : offer.maxEffOdds.toFixed(2))))),
                React.createElement("div", { className: "flex-center flex-column justify-center" },
                    React.createElement("button", { type: "button", className: classNames(classes.button, !offer.link && classes.notAllowed) },
                        React.createElement("span", { className: classes.number }, !isNaN(topIndex) ? (topIndex) : (React.createElement("div", { className: classes.emptyNumber }))),
                        React.createElement("i", { className: "far fa-chevron-right" })))))));
};
