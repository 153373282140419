import makeStyles from '@material-ui/core/styles/makeStyles';
export var useVideoPlayerStyles = makeStyles({
    root: {
        position: 'relative',
        '& video': {
            display: 'block',
            width: '100%',
            height: 'auto',
        },
        '&:focus': {
            outline: 'none',
        },
    },
    controls: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        transition: 'all .3s',
    },
    actionButton: {
        width: 64,
        height: 64,
        padding: 0,
        borderRadius: '70px',
        background: '#2AA1F7',
        border: 0,
        outline: 0,
        cursor: 'pointer',
        '&:after': {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 0,
            height: 0,
            marginLeft: '3px',
            content: '""',
            borderColor: 'transparent',
            borderStyle: 'solid',
            borderRadius: '1px',
            borderTopWidth: '11px',
            borderBottomWidth: '11px',
            borderLeftWidth: '16px',
            borderLeftColor: '#fff',
        },
        '&.active': {
            cursor: 'default',
        },
        '&:hover': {
            background: '#2AA1F7',
        },
    },
    bgSection: {
        position: 'relative',
        width: '100%',
        '& img': {
            width: '100%',
            height: 272,
            objectFit: 'cover',
        },
    },
}, { name: 'VideoPlayer' });
