import React from 'react';
import { useTranslation } from 'react-i18next';
import { deleteDeactivatedOdds, selectHasInactiveEvents, } from '~slices/betslip';
import { Button } from '~atoms';
import { BETSLIP_SOCKET_NAME } from '~slices/events';
import { useAppDispatch } from '~store';
import { getShortcutBetslip } from '~slices/shortcut';
import { useStructuredSelector } from '~hooks';
import { useStyles } from './CheckNowButton.styles';
var CheckNowButton = function (_a) {
    var onClose = _a.onClose, redirectToTopRanking = _a.redirectToTopRanking;
    var dispatch = useAppDispatch();
    var t = useTranslation('ticket').t;
    var classes = useStyles();
    var hasInactiveEvents = useStructuredSelector({
        hasInactiveEvents: selectHasInactiveEvents,
    }).hasInactiveEvents;
    var removeDeactivatedOdds = function () {
        dispatch(deleteDeactivatedOdds({ eventsType: BETSLIP_SOCKET_NAME }))
            .then(function (data) {
            if (!data.payload && onClose) {
                onClose();
            }
        })
            .then(function () {
            // Test next recheck flow without settimeout
            setTimeout(function () {
                dispatch(getShortcutBetslip());
            }, 200);
        });
    };
    if (hasInactiveEvents) {
        return (React.createElement(Button, { variant: "contained", fullWidth: true, onClick: removeDeactivatedOdds, className: classes.button }, t('remove_inactive_bets')));
    }
    return (React.createElement(Button, { color: "primary", variant: "contained", fullWidth: true, onClick: redirectToTopRanking, className: classes.button }, t('check_now')));
};
export { CheckNowButton };
