import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Button from '@material-ui/core/Button';
import { closeModal } from '~modals';
import success from '~icons/statusIcons/success.svg';
import { StatusScreen } from '~molecules';
import { useModalStyles } from './Modal.styles';
export var SuccessModal = function (_a) {
    var modalName = _a.modalName, _b = _a.isVisible, isVisible = _b === void 0 ? false : _b, _c = _a.modalProps, title = _c.title, subheader = _c.subheader, handleConfirm = _c.handleConfirm;
    var t = useTranslation('common').t;
    var dispatch = useDispatch();
    var classes = useModalStyles();
    var handleCancel = function () {
        if (handleConfirm) {
            handleConfirm();
        }
        dispatch(closeModal({ key: modalName }));
    };
    return (React.createElement(Dialog, { open: isVisible, onClose: handleCancel, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description" },
        React.createElement(Button, { onClick: handleCancel, className: classes.closeIcon },
            React.createElement("i", { className: "fas fa-times" })),
        React.createElement(DialogContent, { className: classes.dialog },
            React.createElement(StatusScreen, { header: title, logo: success, subheader: subheader, buttonLabel: t('buttons.continue'), onClick: handleCancel, headerClassName: classes.header, logoClasses: classes.logo, subheaderClassName: classes.subheader }))));
};
