import { getPositionsMapper } from './getPositionsMapper';
import { getMatrixLine } from './getMatrixLine';
import { getMatrix } from './getMatrix';
var normalizeMatrixOc = function (oc, optionName, idsMap) {
    var positionsMap = idsMap[optionName];
    var matrix = getMatrix(oc, positionsMap);
    if (matrix.length === 0) {
        return [getMatrixLine(3, 1)];
    }
    var whitelistedIds = [];
    Object.values(positionsMap).forEach(function (ids) {
        whitelistedIds.push.apply(whitelistedIds, (ids || []));
    });
    if (oc.some(function (_a) {
        var id = _a.id;
        return !whitelistedIds.includes(Number(id));
    })) {
        return matrix.slice(0, 1);
    }
    var result = matrix.map(function (matrixRow) {
        var rowResult = matrixRow.map(getPositionsMapper(oc, positionsMap));
        return rowResult;
    });
    return result.filter(function (outcomes) {
        return outcomes.some(function (o) { var _a; return typeof o !== 'string' && !!((_a = o === null || o === void 0 ? void 0 : o.od) === null || _a === void 0 ? void 0 : _a.act); });
    });
};
export { normalizeMatrixOc };
