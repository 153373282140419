import { useEffect, useRef } from 'react';
import { noop } from '~utils/noop';
import { useIsFirstRender } from './useIsFirstRender';
var useValueChange = function (callback, value) {
    var isFirstRender = useIsFirstRender();
    var isFirstRenderRef = useRef(isFirstRender);
    var callbackRef = useRef(callback);
    var prevValueRef = useRef(value);
    var cleanupRef = useRef(noop);
    callbackRef.current = callback;
    isFirstRenderRef.current = isFirstRender;
    useEffect(function () {
        if (!isFirstRenderRef.current) {
            cleanupRef.current();
            cleanupRef.current = noop;
            var cleanUpCallback = callbackRef.current(value, prevValueRef.current);
            prevValueRef.current = value;
            if (typeof cleanUpCallback === 'function') {
                cleanupRef.current = cleanUpCallback;
            }
        }
    }, [value]);
    useEffect(function () {
        return function () {
            cleanupRef.current();
        };
    }, []);
};
export { useValueChange };
