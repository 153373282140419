import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { useStyles } from '~molecules/WithdrawTab/components/WithdrawInShop/WithdrawInShop.styles';
import { getPathWithLanguageCode } from '~utils/languageInURL';
import { PaymentsURL } from '~pages/payments/payments.routes';
import { Button, FullscreenLoader } from '~atoms';
import { useAppDispatch } from '~store';
import { WithdrawTypeCard } from '~atoms/WithdrawTypeCard';
import { numberWithDots } from '~utils/numberWithDots';
import { PINInput } from '~molecules/WithdrawTab/components/PINInput';
export var WithdrawInShop = function () {
    var t = useTranslation('tip-game').t;
    var classes = useStyles();
    var dispatch = useAppDispatch();
    var _a = useState(false), isDataLoading = _a[0], setIsDataLoading = _a[1];
    var personalPIN = useRef('');
    // TODO, replace with real data from BE
    var isMoneyWithAgent = Math.random() * 10 > 5;
    var mockData = {
        jackpot: 5500,
    };
    var withdrawSum = numberWithDots(mockData.jackpot);
    var onClaimWithdraw = function () {
        if (!personalPIN || personalPIN.current.length < 4) {
            console.log('Enter the correct PIN!');
            return;
        }
        // TODO, send the PIN to BE
        console.log('Your PIN:', personalPIN.current);
        if (isMoneyWithAgent) {
            setIsDataLoading(true);
            setTimeout(function () {
                setIsDataLoading(false);
            }, 2000);
            setTimeout(function () {
                dispatch(push(getPathWithLanguageCode("/".concat(PaymentsURL.PAYMENTS, "/").concat(PaymentsURL.WITHDRAW_JACKPOT_BALANCE, "/").concat(PaymentsURL.PAYMENT_CODE, "?sum=").concat(withdrawSum))));
            }, 2100);
        }
        else {
            dispatch(push(getPathWithLanguageCode("/".concat(PaymentsURL.PAYMENTS, "/").concat(PaymentsURL.WITHDRAW_JACKPOT_BALANCE, "/").concat(PaymentsURL.PAYMENT_STATUS, "?sum=").concat(withdrawSum))));
        }
    };
    return (React.createElement("div", { className: classes.container },
        isDataLoading && React.createElement(FullscreenLoader, null),
        React.createElement("div", null,
            React.createElement("div", { className: classes.title },
                t('payments.congratulations'),
                "!"),
            React.createElement("div", { className: classes.subtitle }, isMoneyWithAgent ? (React.createElement(React.Fragment, null,
                React.createElement("div", null, t('payments.your_money_is_with_agent')),
                React.createElement("div", null, t('payments.collect_money_from_agent')))) : (React.createElement(React.Fragment, null,
                React.createElement("p", null, t('payments.we_need_to_bring_money_to_agent')),
                React.createElement("span", null, t('payments.collect_after_24h')))))),
        React.createElement("div", { className: classes.actionBlock },
            React.createElement(PINInput, { label: t('PIN'), placeholder: t('payments.enter_your_PIN_and_wait_money'), onInputChange: function (value) { return (personalPIN.current = value); } }),
            React.createElement(WithdrawTypeCard, { type: 'inShop', isActive: true }),
            React.createElement(Button, { color: "primary", variant: "contained", fullWidth: true, className: classes.button, onClick: onClaimWithdraw }, t('payments.claim_withdraw')))));
};
