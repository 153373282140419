import React from 'react';
import InactiveAchievementIcon from '~icons/inactive-achievement.svg';
import makeStyles from '~utils/makeStyles';
import { CircularProgressBar } from '~view/components/atoms';
export var AchievementIcon = function (_a) {
    var achievement = _a.achievement, cdnPath = _a.cdnPath, t = _a.t, showModalWindow = _a.showModalWindow;
    var classes = useStyles();
    var isProgressBarr = achievement.counter || !!achievement.progress || achievement.progress === 0;
    var progressValue = achievement.counter && !achievement.progress ? 100 : achievement.progress;
    var counter = achievement.counter > 99 ? '99+' : achievement.counter;
    return (React.createElement("div", { role: "button", tabIndex: 0, className: classes.iconWrapper, onClick: function () {
            return showModalWindow({
                path: achievement.counter
                    ? "".concat(cdnPath, "achievements/").concat(achievement.image)
                    : '',
                title: "".concat(achievement.counter
                    ? t("tip-game:achievements:".concat(achievement.achievementCode, ":description:successtitle"))
                    : t("tip-game:achievements:".concat(achievement.achievementCode, ":icon_text"))),
                successSubtitle: "".concat(achievement.counter
                    ? t("tip-game:achievements:achieved_info", {
                        title: "".concat(t("tip-game:achievements:".concat(achievement.achievementCode, ":icon_text"))),
                        counter: "".concat(counter),
                    })
                    : ''),
                howto: "".concat(t("tip-game:achievements:".concat(achievement.achievementCode, ":description:howto"))),
            });
        } },
        isProgressBarr && (React.createElement(CircularProgressBar, { height: 104, width: 104, progress: progressValue })),
        achievement.counter ? (React.createElement("img", { src: "".concat(cdnPath, "achievements/").concat(achievement.image), alt: "achievement icon", className: classes.achievementIcon })) : (React.createElement("img", { src: InactiveAchievementIcon, alt: "inactive achievement icon", className: classes.achievementIcon })),
        achievement.counter && (React.createElement("div", { className: classes.counterWrapper },
            React.createElement("span", { className: classes.achievementCounter }, counter))),
        achievement.achievementCode && (React.createElement("div", { className: classes.achievementCodeWrapper },
            React.createElement("span", { className: classes.achievementCode }, t("tip-game:achievements:".concat(achievement.achievementCode, ":card_text")))))));
};
var useStyles = makeStyles({
    iconWrapper: {
        position: 'relative',
        height: 104,
        width: 104,
    },
    achievementIcon: {
        height: 104,
        width: 104,
    },
    counterWrapper: {
        position: 'absolute',
        bottom: -2,
        right: -4,
        height: 22,
        width: 22,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#FFFFFF',
        borderRadius: 20,
        zIndex: 11,
    },
    achievementCounter: {
        fontWeight: 700,
        fontSize: 9,
        color: '#5e5e5ca3',
    },
    achievementCodeWrapper: {
        position: 'absolute',
        bottom: 16,
        left: '50%',
        transform: 'translateX(-50%)',
        width: 50,
        paddingTop: 5,
        paddingBottom: 5,
    },
    achievementCode: {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        overflowWrap: 'normal',
        whiteSpace: 'pre-wrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        textAlign: 'center',
        color: '#FFFFFF',
        fontWeight: 700,
        fontSize: 9,
    },
}, { name: 'AchievementIcon' });
