import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { randomColor } from '~utils/randomColor';
import { useStyles } from './ProviderLogo.styles';
var UnloggedProviderLogo = React.memo(function UnloggedProviderLogo() {
    var classes = useStyles();
    var t = useTranslation('bpList').t;
    return (React.createElement("div", { style: { backgroundColor: randomColor() }, className: classNames(classes.logoWrapper, classes.logoWrapper_blurred) }, t('please_login')));
});
export { UnloggedProviderLogo };
