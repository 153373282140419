import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { name } from './constants';
export var topRankingSelector = function (state) { return state[name]; };
export var topRankingDataSelector = function (state) { return state[name].data; };
export var topRankingLoadingSelector = function (state) {
    return state[name].loading;
};
export var topRankingRequestIdSelector = function (state) {
    return topRankingDataSelector(state).requestId;
};
export var templateInfoSelector = function (state) {
    var _a = topRankingDataSelector(state), requestId = _a.requestId, providerId = _a.providerId, template = _a.template, ticketId = _a.ticketId, linkType = _a.linkType;
    return { requestId: requestId, providerId: providerId, template: template, ticketId: ticketId, linkType: linkType };
};
export var providerIdSelector = createSelector(function (state) { return topRankingDataSelector(state).providerId; }, function (providerId) { return providerId; });
/* @deprecated use useStructuredSelector instead */
export var useTopRankingSelector = function () { return useSelector(topRankingSelector); };
// export const useTopRankingDataSelector = () =>
//   useSelector(topRankingDataSelector);
export var useTopRankingDataSelector = createSelector(function (state) { return state[name].data; }, function (state) { return state[name].loading; }, function (list, loading) { return ({ list: list, loading: loading }); });
/* @deprecated use useStructuredSelector instead */
export var useTopRankingLoadingSelector = function () {
    return useSelector(topRankingLoadingSelector);
};
/* @deprecated use useStructuredSelector instead */
export var useTopRankingRequestIdSelector = function () {
    return useSelector(topRankingRequestIdSelector);
};
/* @deprecated use useStructuredSelector instead */
export var useTemplateInfoSelector = function () { return useSelector(templateInfoSelector); };
