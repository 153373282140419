import './cssZIndexes.scss';
export var ZIndex;
(function (ZIndex) {
    ZIndex[ZIndex["MODAL_POPER"] = 15000] = "MODAL_POPER";
    ZIndex[ZIndex["MODAL"] = 14000] = "MODAL";
    ZIndex[ZIndex["LOADER"] = 13000] = "LOADER";
    ZIndex[ZIndex["TOOLTIP"] = 12000] = "TOOLTIP";
    ZIndex[ZIndex["HEADER_OVERLAY"] = 11100] = "HEADER_OVERLAY";
    ZIndex[ZIndex["HEADER"] = 11000] = "HEADER";
    ZIndex[ZIndex["COOKIE_CONSENT"] = 11000] = "COOKIE_CONSENT";
    ZIndex[ZIndex["PORTAL"] = 10000] = "PORTAL";
    ZIndex[ZIndex["DRAWER"] = 9999] = "DRAWER";
    ZIndex[ZIndex["TOURNAMENTS_DROP_DOWN"] = 1000] = "TOURNAMENTS_DROP_DOWN";
    ZIndex[ZIndex["TERMS_TOP_INDEX"] = 600] = "TERMS_TOP_INDEX";
    ZIndex[ZIndex["TERMS_OVERLAY"] = 500] = "TERMS_OVERLAY";
    ZIndex[ZIndex["HOME_TABS"] = 30] = "HOME_TABS";
    ZIndex[ZIndex["BETSLIP_CONTAINER"] = 40] = "BETSLIP_CONTAINER";
    ZIndex[ZIndex["MARKET_FILTERS_ROW"] = 20] = "MARKET_FILTERS_ROW";
    ZIndex[ZIndex["EVENTS_DROP_DOWN"] = 10] = "EVENTS_DROP_DOWN";
    ZIndex[ZIndex["GALLERY_SWIPE_AREA"] = 10] = "GALLERY_SWIPE_AREA";
})(ZIndex || (ZIndex = {}));
