import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '~atoms';
import { checkIfFieldIsRequired, passwordValidation, required, validateField, } from '~utils/formValidators';
export var Password = function (_a) {
    var tabIndex = _a.tabIndex, registrationBlocks = _a.registrationBlocks, isDesktop = _a.isDesktop;
    var t = useTranslation('registration').t;
    return (React.createElement(TextField, { name: "password", title: t('form.password'), placeholder: t('form.enter_password'), type: "password", validators: [
            function (value) {
                return validateField(value, 'password', tabIndex, required, registrationBlocks);
            },
            function (value) {
                return validateField(value, 'password', tabIndex, passwordValidation, registrationBlocks);
            },
        ], autoCompleteType: "new-password", visibilityColor: isDesktop ? '#212121' : '', required: checkIfFieldIsRequired('password', tabIndex, registrationBlocks) }));
};
