var getGmtTimezone = function (serializable) {
    if (serializable === void 0) { serializable = false; }
    var offset = new Date().getTimezoneOffset();
    var hours = Math.floor(Math.abs(offset) / 60);
    var minutes = Math.abs(offset) % 60;
    var plusSymbol = serializable ? '%2B' : '+';
    var offsetHours = hours < 10 ? "0".concat(hours) : hours;
    var offsetMinutes = minutes < 10 ? "0".concat(minutes) : minutes;
    var gmtOffset = "GMT".concat(offset < 0 ? plusSymbol : '-').concat(offsetHours, ":").concat(offsetMinutes);
    return gmtOffset;
};
export { getGmtTimezone };
