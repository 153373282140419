import makeStyles from '@material-ui/core/styles/makeStyles';
export var useStyles = makeStyles({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
        width: '100%',
        minWidth: '95px',
        maxWidth: '160px',
        height: '34px',
        padding: '20px',
        color: '#FFFFFF',
        fontSize: '12px',
        background: 'linear-gradient(180deg, #575760 -43.24%, #3F3F47 2.27%, #0D0D10 100%)',
        boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.50) inset',
        borderRadius: '7px',
    },
    header: {
        fontSize: '14px',
    },
    cursorPointer: {
        cursor: 'pointer',
    },
    inActive: {
        cursor: 'not-allowed',
    },
    whiteColor: {
        color: '#FFFFFF',
    },
    content: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        width: '100%',
        fontSize: '10px',
        fontWeight: 500,
    },
    spaceEvenly: {
        justifyContent: 'space-evenly'
    },
    justifyContentCenter: {
        justifyContent: 'center',
    },
    balance: {
        '& svg': {
            marginLeft: '3px',
            marginBottom: '-3px',
        },
    },
    activeButton: {
        height: '32px',
        border: '1px solid #6CFF00',
    },
    activeButtonCheckMark: {
        position: 'absolute',
        top: '0',
        right: '0',
        width: '0',
        height: '0',
        borderTop: '23px solid #6CFF00',
        borderLeft: '23px solid transparent',
        borderRadius: '0 4px 0 0',
        '& i': {
            position: 'absolute',
            bottom: '10px',
            right: '0',
            color: '#FFFFFF',
            fontWeight: '400',
        },
    },
}, { name: 'GameTypeButton' });
