// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable max-lines */
import makeStyles from '@material-ui/core/styles/makeStyles';
export var useStyles = makeStyles(function (theme) {
    var _a, _b, _c, _d, _e;
    return ({
        root: (_a = {
                background: '#151515',
                color: '#fff',
                boxSizing: 'border-box',
                margin: '0 !important',
                '&$expanded': {
                    margin: '0',
                },
                '&:before': {
                    display: 'none',
                }
            },
            _a[theme.breakpoints.up('lg')] = {
                background: '#34343E',
                color: '#fff',
            },
            _a),
        details: (_b = {
                background: '#232323',
                padding: '0 8px',
                '&$expanded': {
                    marginTop: 0,
                }
            },
            _b[theme.breakpoints.up('lg')] = {
                background: 'transparent',
                padding: 0,
                '& > div': {
                    padding: 16,
                    fontSize: 12,
                    lineHeight: '18px',
                    background: '#8e8e9d26',
                },
            },
            _b),
        summary: (_c = {
                minHeight: 'auto',
                padding: '8px',
                margin: 0,
                paddingRight: '8px',
                '&$expanded': {
                    minHeight: 'auto',
                    margin: 0,
                }
            },
            _c[theme.breakpoints.up('lg')] = {
                padding: '11px',
            },
            _c),
        expanded: {
            minHeight: 'auto',
            margin: 0,
        },
        content: {
            margin: 0,
            flexGrow: 0,
            marginRight: '8px',
            '&$expanded': {
                minHeight: 'auto',
                marginTop: 0,
                marginBottom: 0,
                marginRight: '8px',
            },
        },
        expandIcon: {
            margin: 0,
            padding: 0,
            fontSize: '8px',
            color: 'inherit',
        },
        icon: {
            color: '#fff',
            fontSize: '14px',
            padding: 0,
        },
        overviewTitle: (_d = {
                padding: '8px',
                fontSize: '10px',
                lineHeight: 1.5
            },
            _d[theme.breakpoints.up('lg')] = {
                fontSize: '12px',
                padding: '10px 0',
            },
            _d),
        overview: (_e = {
                marginBottom: '10px',
                background: '#3C3C3C',
                borderRadius: '2px'
            },
            _e[theme.breakpoints.up('lg')] = {
                maxHeight: '367px',
                background: '#60606F',
                fontSize: '12px',
                lineHeight: '18px',
                margin: 0,
                overflowY: 'auto',
            },
            _e),
        table: {
            width: '100%',
            '& > th': {
                // first cell
                paddingLeft: '8px',
                border: 'none',
            },
            '> td': {
                border: 'none',
            },
        },
        borderlessTableContainer: {
            paddingTop: '6px',
        },
        borderlessTableCell: {
            paddingTop: '2px',
            borderBottom: 'none !important',
        },
        firstTableCell: {
            width: '100%',
            paddingLeft: '8px',
        },
        withSpacer: {
            position: 'relative',
            '&:before': {
                content: '""',
                position: 'absolute',
                left: 0,
                marginTop: '0.0833333em',
                display: 'block',
                height: '1.3333333em',
                borderLeft: '1px solid rgba(142, 142, 142, 0.2);',
            },
        },
        noWrap: {
            whiteSpace: 'nowrap',
        },
        indicator: {
            color: '#8E8E8E',
            fontSize: '8px',
        },
        colorSuccess: {
            color: '#00C150',
        },
        muted: {
            opacity: 0.6,
        },
        fullWidth: {
            width: '100%',
        },
    });
}, { name: 'SystemOverview' });
export var useStylesV2 = makeStyles(function (theme) {
    var _a, _b, _c, _d;
    return ({
        root: (_a = {
                background: '#2D2D2D',
                color: '#fff',
                boxSizing: 'border-box',
                margin: '0 !important',
                '&$expanded': {
                    margin: '0',
                },
                '&:before': {
                    display: 'none',
                }
            },
            _a[theme.breakpoints.up('lg')] = {
                color: '#fff',
            },
            _a),
        details: (_b = {
                padding: '12px 16px',
                '&$expanded': {
                    marginTop: 0,
                }
            },
            _b[theme.breakpoints.up('lg')] = {
                padding: 0,
                '& > div': {
                    padding: '12px 16px',
                    fontSize: 12,
                    lineHeight: '18px',
                },
            },
            _b),
        summary: (_c = {
                backgroundColor: '#151515',
                minHeight: 'auto',
                padding: '8px',
                margin: 0,
                paddingRight: '8px',
                '&$expanded': {
                    minHeight: 'auto',
                    margin: 0,
                }
            },
            _c[theme.breakpoints.up('lg')] = {
                padding: '11px',
            },
            _c),
        expanded: {
            minHeight: 'auto',
            margin: 0,
        },
        content: {
            fontSize: '12px',
            margin: 0,
            flexGrow: 0,
            marginRight: '8px',
            '&$expanded': {
                minHeight: 'auto',
                marginTop: 0,
                marginBottom: 0,
                marginRight: '8px',
            },
        },
        expandIcon: {
            margin: 0,
            padding: 0,
            fontSize: '8px',
            color: 'inherit',
        },
        icon: {
            color: '#fff',
            fontSize: '14px',
            padding: 0,
        },
        overviewTitle: {
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: 1.5,
        },
        overview: (_d = {
                borderRadius: '2px'
            },
            _d[theme.breakpoints.up('lg')] = {
                maxHeight: '367px',
                backgroundColor: '#2D2D2D',
                fontSize: '12px',
                lineHeight: '18px',
                margin: 0,
                overflowY: 'auto',
            },
            _d),
        table: {
            width: '100%',
            '& > th': {
                // first cell
                paddingLeft: '8px',
                border: 'none',
            },
            '> td': {
                border: 'none',
            },
        },
        borderlessTableContainer: {
            paddingTop: '12px',
        },
        borderlessTableCell: {
            paddingTop: '2px',
            paddingRight: '2px !important',
            borderBottom: 'none !important',
        },
        firstTableCell: {
            width: '100%',
            paddingLeft: '0px',
        },
        withSpacer: {
            position: 'relative',
            '&:before': {
                content: '""',
                position: 'absolute',
                left: 0,
                marginTop: '0.0833333em',
                display: 'block',
                height: '1.3333333em',
                borderLeft: '1px solid rgba(142, 142, 142, 0.2);',
            },
        },
        noWrap: {
            whiteSpace: 'nowrap',
        },
        indicator: {
            color: '#8E8E8E',
            fontSize: '8px',
        },
        colorSuccess: {
            color: '#00C150',
        },
        muted: {
            opacity: 0.6,
            marginLeft: '8px',
        },
        fullWidth: {
            width: '100%',
        },
        currency: {
            fontWeight: 500,
        },
    });
}, { name: 'SystemOverview' });
