import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button, SmallProviderLogo } from '~atoms';
import { isAuthenticatedSelector } from '~slices/auth';
import { useOpenLogin, useStructuredSelector } from '~hooks';
import { useActivePlaySessionDataSelector } from '~slices/activePlaySession/selectors';
import { useStyles } from './FastPlayButton.styles';
export var DeactivatePlaySessionButton = function (_a) {
    var redirectToTopRanking = _a.redirectToTopRanking;
    var classes = useStyles();
    var t = useTranslation('ticket').t;
    var isAuthenticated = useStructuredSelector({
        isAuthenticated: isAuthenticatedSelector,
    }).isAuthenticated;
    var _b = useActivePlaySessionDataSelector(), providerId = _b.id, backgroundColor = _b.backgroundColor;
    var openLogin = useOpenLogin(redirectToTopRanking).openLogin;
    var isProviderLogo = providerId && backgroundColor;
    var handleClick = function () {
        if (isAuthenticated) {
            redirectToTopRanking();
        }
        else {
            openLogin();
        }
    };
    return (React.createElement(Button, { variant: "contained", fullWidth: true, onClick: handleClick, className: classNames(classes.button) },
        isProviderLogo && (React.createElement(SmallProviderLogo, { id: providerId, backgroundColor: backgroundColor })),
        t('deactivate_play_session')));
};
