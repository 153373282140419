import makeStyles from '~utils/makeStyles';
export var useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        background: '#151515',
    },
    button: {
        fontSize: '16px',
    },
    actionBlock: {
        '& input': {
            marginBottom: '64px',
        },
        '& button': {
            marginTop: '16px',
        },
    },
    title: {
        marginTop: '60px',
        marginBottom: '10px',
        textAlign: 'center',
        fontWeight: 700,
        fontSize: '18px',
        color: '#FFFFFF',
    },
    subtitle: {
        marginTop: '10px',
        textAlign: 'center',
        fontSize: '18px',
        fontWeight: 300,
        color: '#FFFFFF',
        '& p': {
            margin: 0,
            marginBottom: '20px',
        },
    },
}, { name: 'WithdrawInShop' });
