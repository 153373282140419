import makeStyles from '@material-ui/core/styles/makeStyles';
import { HIGH_ADDITIONAL_DISTANCE_TO_WINDOW_BORDER, HORIZONTAL_PADDING, LOW_ADDITIONAL_DISTANCE_TO_WINDOW_BORDER, VERTICAL_MARGIN, } from './constants';
var useStyles = makeStyles(function (theme) {
    var _a;
    return ({
        tooltip: {
            backgroundColor: '#2AA1F7',
            fontSize: '16px',
            padding: "26px ".concat(HORIZONTAL_PADDING, "px"),
            margin: "".concat(VERTICAL_MARGIN, "px 0px"),
            borderRadius: '8px',
            boxSizing: 'border-box',
        },
        arrow: {
            '&:before': {
                display: 'none',
            },
        },
        popper: (_a = {
                padding: "0 ".concat(HIGH_ADDITIONAL_DISTANCE_TO_WINDOW_BORDER, "px")
            },
            _a[theme.breakpoints.down('sm')] = {
                padding: LOW_ADDITIONAL_DISTANCE_TO_WINDOW_BORDER,
            },
            _a),
    });
}, { name: 'Hint' });
export { useStyles };
