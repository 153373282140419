import makeStyles from '@material-ui/core/styles/makeStyles';
export var useStyles = makeStyles({
    input: {
        height: '48px',
        width: 'calc(100% - 20px)',
        paddingLeft: '16px',
        marginTop: '5px',
        background: '#323232',
        border: '1px solid #414141',
        borderRadius: '4px',
        fontSize: '16px',
        color: '#FFFFFF',
        '&::placeholder': {
            color: 'rgba(255, 255, 255, 0.4)',
        },
        '&:focus-visible': {
            outline: 'none',
        },
    },
    label: {
        fontSize: '12px',
        color: '#FFFFFF',
    },
    inputWrapper: {
        marginTop: '8px',
    },
}, { name: 'BankDetailsInput' });
