import React, { useEffect } from 'react';
import { formValueSelector, reduxForm, stopAsyncValidation } from 'redux-form';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { Button, Checkbox } from '~atoms';
import { shouldValidate, validate } from '~utils/registrationFormValidation';
import asyncValidation from '~utils/asyncValidation';
import { countriesPhoneCodeAndNameSelector, localeDataSelector, } from '~slices/locale';
import { InvisibleRecaptcha } from '~molecules';
import { Divider } from '~pages/registration/components/Divider';
import { useAddressAutocompleteHook, useRecaptcha, useStructuredSelector, } from '~hooks';
import { countryCodeSelector, errorSelector } from '~slices/auth';
import { useRegisterStyles } from '~pages/registration/RegisterForm.styles';
import { useAppDispatch } from '~store';
import { MODALS, openModal } from '~modals';
import { getAsyncValidationMessage } from "~utils/getAsyncValidationMessage";
import { BirthDate, City, CountryCode, Email, FirstName, NationalityId, PassportNumber, Password, Phone, RepeatedEmail, RepeatedPassword, Street, SurName, TermsAndConditionsAgreement, TransferDataToBPAgreement, UserName, } from './stepsFields';
var FIELD_EMAIL = 'email';
var FIELD_USERNAME = 'username';
var ERROR_CODE_EMAIL = 'ER0002_email';
var ERROR_CODE_USERNAME = 'ER0002_username';
var RegistrationSteps = function (_a) {
    var _b;
    var step = _a.step, countries = _a.countries, titles = _a.titles, registrationBlocks = _a.registrationBlocks, handleSubmit = _a.handleSubmit, onCancel = _a.onCancel, onBack = _a.onBack, isDesktop = _a.isDesktop, change = _a.change;
    var classes = useRegisterStyles();
    var formSelector = formValueSelector('registration-form');
    var t = useTranslation('registration').t;
    var tc = useTranslation('common').t;
    var countryCode = useStructuredSelector({
        countryCode: countryCodeSelector,
    }).countryCode;
    var _c = useRecaptcha(), execute = _c.execute, isValidated = _c.isValidated;
    var _d = useAddressAutocompleteHook(), dataCities = _d.dataCities, postCode = _d.postCode, street = _d.street, searchLocation = _d.searchLocation;
    var _e = useStructuredSelector({
        countriesPhone: countriesPhoneCodeAndNameSelector,
        transferDataToBPAccepted: function (state) {
            return formSelector(state, 'userProfileDto.dataTransferToBp');
        },
        termsAndConditionsAccepted: function (state) {
            return formSelector(state, 'termsAndConditionsAccepted');
        },
    }), countriesPhone = _e.countriesPhone, transferDataToBPAccepted = _e.transferDataToBPAccepted, termsAndConditionsAccepted = _e.termsAndConditionsAccepted;
    var _f = useStructuredSelector({
        errorsData: errorSelector,
        localeData: localeDataSelector,
    }), errorsData = _f.errorsData, nationalities = _f.localeData.nationalities;
    var dispatch = useAppDispatch();
    var errors = errorsData === null || errorsData === void 0 ? void 0 : errorsData.errors;
    var checkboxClassName = isDesktop ? '' : classes.dark;
    var tabIndex = step - 1;
    useEffect(function () {
        change('phoneCode', countryCode === null || countryCode === void 0 ? void 0 : countryCode.phoneCode);
    }, [countryCode === null || countryCode === void 0 ? void 0 : countryCode.phoneCode]);
    useEffect(function () {
        var _a, _b;
        if (errors && ((_a = errors[0]) === null || _a === void 0 ? void 0 : _a.type) !== 'AUTHENTICATION' && ((_b = errors[0]) === null || _b === void 0 ? void 0 : _b.type) !== 'VALIDATION') {
            dispatch(openModal({
                modalName: MODALS.REGISTRATION_ERRORS_MODAL,
                modalProps: {
                    closeButtonLabel: 'Continue',
                    cancelVariant: 'contained',
                    fullWidth: true,
                    hugeButton: true,
                    cancelColor: 'primary',
                    columnDialog: false,
                },
            }));
        }
    }, [errors]);
    useEffect(function () {
        var _a;
        if (errors && ((_a = errors[0]) === null || _a === void 0 ? void 0 : _a.type) === 'VALIDATION') {
            var errorsObject_1 = {};
            errors.forEach(function (error) {
                if (error.field === FIELD_EMAIL) {
                    errorsObject_1[error.field] = getAsyncValidationMessage(ERROR_CODE_EMAIL);
                }
                else if (error.field === FIELD_USERNAME) {
                    errorsObject_1[error.field] = getAsyncValidationMessage(ERROR_CODE_USERNAME);
                }
                else
                    return;
            });
            dispatch(stopAsyncValidation('registration-form', errorsObject_1));
        }
    }, [errors]);
    var onSubmit = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        if (!isValidated && process.env.RECAPTCHA_KEY) {
            execute(function () { return handleSubmit.apply(void 0, args); });
        }
        else {
            handleSubmit.apply(void 0, args);
        }
    };
    var customHandleSubmit = function (data) {
        handleSubmit(data);
    };
    var renderStep = function (block) {
        var formFieldsGroup = {
            countryCode: (React.createElement(CountryCode, { countries: countries, registrationBlocks: registrationBlocks, tabIndex: tabIndex })),
            name: (React.createElement(FirstName, { titles: titles, registrationBlocks: registrationBlocks, tabIndex: tabIndex })),
            surname: (React.createElement(SurName, { registrationBlocks: registrationBlocks, tabIndex: tabIndex })),
            email: (React.createElement(Email, { registrationBlocks: registrationBlocks, tabIndex: tabIndex })),
            repeatedEmail: (React.createElement(RepeatedEmail, { registrationBlocks: registrationBlocks, tabIndex: tabIndex })),
            birthDate: (React.createElement(BirthDate, { registrationBlocks: registrationBlocks, tabIndex: tabIndex })),
            username: (React.createElement(UserName, { registrationBlocks: registrationBlocks, tabIndex: tabIndex })),
            password: (React.createElement(Password, { registrationBlocks: registrationBlocks, tabIndex: tabIndex, isDesktop: isDesktop })),
            repeatedPassword: (React.createElement(RepeatedPassword, { registrationBlocks: registrationBlocks, tabIndex: tabIndex, isDesktop: isDesktop })),
            city: (React.createElement(City, { registrationBlocks: registrationBlocks, tabIndex: tabIndex, postCode: postCode, dataCities: dataCities, searchLocation: searchLocation })),
            street: (React.createElement(Street, { registrationBlocks: registrationBlocks, tabIndex: tabIndex, street: street, dataCities: dataCities, searchLocation: searchLocation })),
            phone: (React.createElement(Phone, { registrationBlocks: registrationBlocks, tabIndex: tabIndex, countriesPhone: countriesPhone })),
            nationalityId: (React.createElement(NationalityId, { registrationBlocks: registrationBlocks, tabIndex: tabIndex, nationalities: nationalities })),
            passportNumber: (React.createElement(PassportNumber, { registrationBlocks: registrationBlocks, tabIndex: tabIndex })),
            acceptAll: (React.createElement(Grid, { container: true, justify: "space-between", spacing: 2 },
                React.createElement(Grid, { item: true, style: { width: '100%' } },
                    React.createElement(Checkbox, { className: checkboxClassName, title: t('form.accept_all'), checked: !!termsAndConditionsAccepted && !!transferDataToBPAccepted, onChange: function (_, value) {
                            change('termsAndConditionsAccepted', value);
                            change('userProfileDto.dataTransferToBp', value);
                        } })),
                React.createElement(TermsAndConditionsAgreement, { registrationBlocks: registrationBlocks, tabIndex: tabIndex, checkboxClassName: checkboxClassName, linkClassName: classes.link, isDesktop: isDesktop, onCancel: onCancel }),
                React.createElement(TransferDataToBPAgreement, { registrationBlocks: registrationBlocks, tabIndex: tabIndex, checkboxClassName: checkboxClassName }),
                React.createElement(Grid, { item: true, style: { width: '100%' } },
                    React.createElement(Divider, null)))),
            transferDataToBPAccepted: (React.createElement(Grid, { container: true, justify: "space-between", spacing: 2 },
                React.createElement(TransferDataToBPAgreement, { registrationBlocks: registrationBlocks, tabIndex: tabIndex, checkboxClassName: checkboxClassName }),
                React.createElement(Grid, { item: true, style: { width: '100%' } },
                    React.createElement(Divider, null)))),
            termsAndConditionsAccepted: (React.createElement(Grid, { container: true, justify: "space-between", spacing: 2 },
                React.createElement(TermsAndConditionsAgreement, { registrationBlocks: registrationBlocks, tabIndex: tabIndex, checkboxClassName: checkboxClassName, linkClassName: classes.link, isDesktop: isDesktop, onCancel: onCancel }),
                React.createElement(Grid, { item: true, style: { width: '100%' } },
                    React.createElement(Divider, null)))),
            recaptcha: React.createElement(InvisibleRecaptcha, null),
        };
        if (formFieldsGroup.hasOwnProperty(block.name)) {
            return formFieldsGroup[block.name];
        }
    };
    var renderButtons = function () {
        var isRegisterButton = registrationBlocks.length === 1 || registrationBlocks.length === step;
        var isCancelButton = registrationBlocks.length === 1 || step === 1;
        var nextButtonHandler = isRegisterButton ? customHandleSubmit : onSubmit;
        var nextButtonText = isRegisterButton
            ? tc('buttons.register')
            : tc('buttons.next');
        var backButtonHandler = isCancelButton ? onCancel : onBack;
        var backButtonText = isCancelButton
            ? tc('buttons.cancel')
            : tc('buttons.back');
        return (React.createElement(Grid, { container: true, justify: "space-between", style: { marginTop: '16px' }, spacing: 2 },
            React.createElement(Grid, { key: step, item: true },
                React.createElement(Button, { style: { width: '120px' }, variant: "outlined", onClick: backButtonHandler, color: "secondary" }, backButtonText)),
            React.createElement(Grid, { key: "next", item: true },
                React.createElement(Button, { style: { width: '120px' }, variant: "contained", onClick: nextButtonHandler, color: "primary" }, nextButtonText))));
    };
    return (React.createElement("form", { key: step, className: "flex-auto flex-column" }, (_b = registrationBlocks[tabIndex]) === null || _b === void 0 ? void 0 :
        _b.map(function (block, index) {
            return React.createElement(React.Fragment, { key: index }, renderStep(block));
        }),
        renderButtons()));
};
export default reduxForm({
    form: 'registration-form',
    touchOnChange: true,
    destroyOnUnmount: false,
    asyncValidate: asyncValidation,
    asyncBlurFields: [FIELD_EMAIL, FIELD_USERNAME],
    validate: validate,
    shouldValidate: shouldValidate,
})(RegistrationSteps);
