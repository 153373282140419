/*
.sidebar {
  background: #ffffff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  width: 255px;
  font-size: 16px;
  line-height: 19px;

  .sports {
    overflow: auto;
    padding: 30px 30px 0;
  }

  &__header {
    font-weight: 500;
    text-transform: uppercase;
    color: #d6d6d6;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li:first-child {
      margin-top: 37px;
    }

    li:last-child {
      margin-bottom: 31px;
    }
  }

  &__item {
    display: flex;
    align-items: flex-start;
    font-weight: normal;
    color: #000000;
    margin-bottom: 17px;
  }
}

 */
import makeStyles from '@material-ui/core/styles/makeStyles';
export var useSportListStyles = makeStyles({
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    favouriteSport: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        height: '102px',
        background: 'radial-gradient(100% 100% at 50.13% 0%, #3C3C46 0%, #1B1B22 100%)',
        border: '1px solid #4E4E5A',
        boxSizing: 'border-box',
        borderRadius: '4px',
        marginTop: '32px',
        cursor: 'pointer',
    },
    favouriteBlock: {
        padding: '16px',
    },
    sportLabel: {
        color: '#ffffff',
        fontSize: '16px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    sportIcon: {
        width: '20px',
        height: '20px',
        minWidth: '20px',
        marginRight: '16px',
    },
    scrollableList: {
        flex: '1 1 auto',
        overflowY: 'scroll',
        overflowX: 'hidden',
        scrollbarColor: '#313131 #151515',
        scrollbarWidth: 'thin',
    },
}, { name: 'SportList' });
