import * as React from 'react';
import classNames from 'classnames';
import './live-styles.scss';
import { useTranslation } from 'react-i18next';
export var Live = React.memo(function LiveLabel(_a) {
    var className = _a.className, externalLabel = _a.label;
    var t = useTranslation('ticket').t;
    var label = typeof externalLabel === 'string' ? externalLabel : t('live');
    return React.createElement("div", { className: classNames('live', className) }, label);
});
