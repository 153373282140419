var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect } from 'react';
import { HorizonGalleryPlaceholder } from '~atoms';
import { ConversionTeaser } from '~atoms/ConversionTeaser';
import { useStructuredSelector } from '~hooks';
import { Slider } from '~molecules';
import { useGameTypesDataSelector, useSelectedGroupSelector, } from '~slices/gameTypes';
import { CONVERSION_TYPE, getConversionTeaser, isLoadedEmptySelector, slideshowSelector, } from '~slices/teasers';
import { useAppDispatch } from '~store';
var HorizonGalleryWithPlaceholder = function (_a) {
    var className = _a.className;
    var _b = useStructuredSelector({
        banners: slideshowSelector,
        games: useGameTypesDataSelector,
        selectedGroup: useSelectedGroupSelector,
        isLoadedEmpty: isLoadedEmptySelector,
    }), banners = _b.banners, games = _b.games, selectedGroup = _b.selectedGroup, isLoadedEmpty = _b.isLoadedEmpty;
    var dispatch = useAppDispatch();
    var shouldDisplaySlider = Array.isArray(banners) && !!banners.length;
    var conversionSlideshows = banners === null || banners === void 0 ? void 0 : banners.filter(function (ssh) { return ssh.slideshowType === CONVERSION_TYPE; });
    var hasConversionTeaser = conversionSlideshows.some(function (slideshow) { return !!(slideshow === null || slideshow === void 0 ? void 0 : slideshow.conversionTeaser); });
    //TODO, need to be changed after getting the requirements
    var firstConversionSlideshow = conversionSlideshows === null || conversionSlideshows === void 0 ? void 0 : conversionSlideshows[0];
    useEffect(function () {
        var _a;
        if (conversionSlideshows.length && !hasConversionTeaser && games.length) {
            var groupKey_1 = (_a = games === null || games === void 0 ? void 0 : games.find(function (game) { return game.groupName === selectedGroup; })) === null || _a === void 0 ? void 0 : _a.groupKey;
            var dispatchConversionTeasers = function () { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, Promise.all(conversionSlideshows.map(function (slideshow) {
                                return dispatch(getConversionTeaser({ collectionId: slideshow.id, groupKey: groupKey_1 }));
                            }))];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            }); };
            dispatchConversionTeasers();
        }
    }, [banners, games]);
    useEffect(function () {
        var _a;
        var interval;
        if (hasConversionTeaser && !!(firstConversionSlideshow === null || firstConversionSlideshow === void 0 ? void 0 : firstConversionSlideshow.conversionTeaser)) {
            var groupKey_2 = (_a = games === null || games === void 0 ? void 0 : games.find(function (game) { return game.groupName === selectedGroup; })) === null || _a === void 0 ? void 0 : _a.groupKey;
            var duration = (firstConversionSlideshow.conversionTeaser.duration ||
                firstConversionSlideshow.duration ||
                0) * 1000;
            interval = setInterval(function () {
                dispatch(getConversionTeaser({
                    collectionId: firstConversionSlideshow.id,
                    groupKey: groupKey_2,
                }));
            }, duration);
        }
        return function () { return clearInterval(interval); };
    }, [firstConversionSlideshow === null || firstConversionSlideshow === void 0 ? void 0 : firstConversionSlideshow.conversionTeaser]);
    if (hasConversionTeaser && (firstConversionSlideshow === null || firstConversionSlideshow === void 0 ? void 0 : firstConversionSlideshow.conversionTeaser)) {
        var teaser = firstConversionSlideshow.conversionTeaser;
        return (React.createElement(ConversionTeaser, { key: teaser.id, id: teaser.id, mediaUrl: teaser.mediaUrl, linkUrl: teaser.linkUrl }));
    }
    if (!shouldDisplaySlider) {
        return (React.createElement(HorizonGalleryPlaceholder, { display: !isLoadedEmpty, className: className }));
    }
    return React.createElement(Slider, { position: "TOP", slideshows: banners, className: className });
};
export { HorizonGalleryWithPlaceholder };
