import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var FloorBallIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, viewBox: "0 0 28 28" },
        React.createElement("svg", { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M28 14C28 14.1948 28 14.3896 27.9878 14.5843C27.68 21.8556 21.8556 27.68 14.5844 27.9878C14.3896 28 14.1948 28 14 28C13.8053 28 13.5861 28 13.3792 27.9878C6.13679 27.6602 0.339823 21.8632 0.0121549 14.6209C0 14.414 0 14.207 0 14C0 13.793 0 13.5983 0.0121549 13.4035C0.324986 6.13652 6.14837 0.318367 13.4156 0.0121549C13.6104 0 13.8052 0 14 0C14.2191 0 14.4261 0 14.633 0.0121549C21.8669 0.343704 27.6562 6.13309 27.9878 13.367C28 13.574 28 13.7809 28 14Z", fill: "white" }),
            React.createElement("path", { d: "M27.9878 14.5843C28 14.3896 28 14.1948 28 14C28 13.7809 28 13.574 27.9878 13.367C27.926 12.0192 27.6748 10.7215 27.26 9.5C25.9799 18.8459 17.7864 25.675 8.36281 25.2504L5.5 25.1214C7.70208 26.807 10.42 27.854 13.3792 27.9878C13.5861 28 13.8052 28 13.9999 28H14H14.0001C14.1948 28 14.3896 28 14.5844 27.9878C21.8556 27.68 27.68 21.8556 27.9878 14.5843Z", fill: "#E8E8E8" }),
            React.createElement("circle", { cx: "13.5", cy: "2.5", r: "1.5", fill: "#373737" }),
            React.createElement("ellipse", { cx: "9.34997", cy: "5.5", rx: "1.35", ry: "1.5", transform: "rotate(35.8184 9.34997 5.5)", fill: "#373737" }),
            React.createElement("ellipse", { cx: "4.35001", cy: "7.5", rx: "1.35", ry: "1.5", transform: "rotate(24.1088 4.35001 7.5)", fill: "#373737" }),
            React.createElement("circle", { cx: "10.5", cy: "11.5", r: "1.5", fill: "#373737" }),
            React.createElement("ellipse", { cx: "3.35003", cy: "18.5", rx: "1.35", ry: "1.5", transform: "rotate(-22.0029 3.35003 18.5)", fill: "#373737" }),
            React.createElement("ellipse", { cx: "12.5", cy: "21.35", rx: "1.5", ry: "1.35", fill: "#373737" }),
            React.createElement("ellipse", { cx: "19.9443", cy: "23.9448", rx: "1.25", ry: "1.5", transform: "rotate(44.9189 19.9443 23.9448)", fill: "#373737" }),
            React.createElement("ellipse", { cx: "20.35", cy: "16.5", rx: "1.35", ry: "1.5", transform: "rotate(29.3065 20.35 16.5)", fill: "#373737" }),
            React.createElement("ellipse", { cx: "24.35", cy: "9.5", rx: "1.35", ry: "1.5", transform: "rotate(-20.6512 24.35 9.5)", fill: "#373737" }),
            React.createElement("ellipse", { cx: "24.3627", cy: "17.7118", rx: "1", ry: "1.5", transform: "rotate(15.4126 24.3627 17.7118)", fill: "#373737" }),
            React.createElement("circle", { cx: "15.5", cy: "6.5", r: "1.5", fill: "#373737" }))));
};
