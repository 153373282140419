export var BEOptionNames;
(function (BEOptionNames) {
    BEOptionNames["OPTION_1"] = "Option 1";
    BEOptionNames["OPTION_2"] = "Option 2";
    BEOptionNames["OPTION_3"] = "Option 3";
    BEOptionNames["OPTION_4"] = "Option 4";
    BEOptionNames["OPTION_5"] = "Option 5";
    BEOptionNames["OPTION_6"] = "Option 6";
    BEOptionNames["OPTION_7"] = "Option 7";
    BEOptionNames["OPTION_10"] = "Option 10";
    BEOptionNames["OPTION_11"] = "Option 11";
})(BEOptionNames || (BEOptionNames = {}));
