import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var GaelicHurlingIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, viewBox: "0 0 28 28" },
        React.createElement("svg", { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("g", { clipPath: "url(#clip0)" },
                React.createElement("path", { d: "M27.7683 2.60802L27.7961 2.58227C27.9944 2.39868 28.0559 2.10648 27.9459 1.85798C27.7599 1.43777 27.4216 0.813891 26.9422 0.480023C26.7442 0.342102 26.4764 0.373984 26.3154 0.554945C24.4978 2.59944 12.6823 15.8367 9.7934 17.7097C6.66134 19.7404 3.84871 20.5716 1.86891 21.4262C-0.805087 22.5804 -0.271884 24.9053 1.36212 26.4577C2.85788 27.8787 5.276 28.6523 7.00095 27.2884C7.69215 26.7419 10.3972 21.0048 11.7934 19.3956C13.0504 17.9468 24.033 5.41191 26.2112 2.92609C26.435 2.67064 26.7944 2.58883 27.1042 2.7227C27.3287 2.81961 27.5881 2.77482 27.7683 2.60802Z", fill: "#F5F5F5" }),
                React.createElement("path", { d: "M27.2235 0.722992C23.8967 4.49894 12.5161 16.79 11.2213 18.323C9.69277 20.1328 6.73133 26.585 5.97462 27.1997C5.45919 27.6184 4.88722 27.8576 4.29468 27.9522C5.23831 28.096 6.19529 27.9255 7.00105 27.2884C7.69225 26.7419 10.3973 21.0048 11.7935 19.3955C13.0505 17.9468 24.0331 5.4119 26.2113 2.92608C26.4351 2.67063 26.7945 2.58882 27.1043 2.72269C27.3287 2.81966 27.5882 2.77481 27.7683 2.60802L27.7961 2.58226C27.9944 2.39873 28.0559 2.10648 27.9459 1.85798C27.7987 1.52542 27.5558 1.06594 27.2235 0.722992Z", fill: "#D4D4D4" }),
                React.createElement("path", { d: "M11.1903 16.5796L12.7824 18.2617C14.0717 16.7863 16.3226 14.2151 18.6374 11.5718L17.3398 10.3879C15.0198 12.8515 12.749 15.1788 11.1903 16.5796Z", fill: "#FF9800" }),
                React.createElement("path", { d: "M12.7825 18.2618C14.0718 16.7864 16.3227 14.2151 18.6375 11.5719L17.9383 10.9339C15.4749 13.6229 13.2069 16.0987 12.0031 17.4383L12.7825 18.2618Z", fill: "#FB8C00" }),
                React.createElement("path", { d: "M10.4974 4.62093C9.4403 5.67848 7.7259 5.67848 6.6688 4.62093C6.1497 4.10244 5.88583 3.4247 5.87665 2.74422C5.87233 2.45602 5.91405 2.16721 6.00183 1.88984C6.1281 1.48892 6.35079 1.11076 6.66885 0.79275C7.72596 -0.26425 9.44036 -0.26425 10.4975 0.79275C10.563 0.85832 10.6242 0.926023 10.6812 0.996461C10.8843 1.24403 11.0361 1.51927 11.1374 1.80857C11.4716 2.75991 11.2581 3.86028 10.4974 4.62093Z", fill: "#FF9800" }),
                React.createElement("path", { d: "M6.66799 0.79303C6.49359 0.967429 6.34856 1.15993 6.23169 1.36419C7.26561 0.772687 8.60622 0.917718 9.48904 1.80043C10.3719 2.68314 10.5169 4.02358 9.92534 5.0574C10.1296 4.94053 10.3222 4.79555 10.4965 4.62116C11.5538 3.56405 11.5538 1.85014 10.4965 0.79303C9.43933 -0.264079 7.72521 -0.264079 6.66799 0.79303Z", fill: "#FB8C00" }),
                React.createElement("path", { d: "M11.1364 1.80862C10.6753 2.4116 9.98013 2.6776 9.09419 2.58173C8.00618 2.46415 6.92304 2.5189 5.87567 2.74426C5.87135 2.45606 5.91308 2.16726 6.00085 1.88988C7.04062 1.697 8.10861 1.65746 9.18142 1.7734C9.91942 1.85357 10.3973 1.60654 10.6801 0.996506C10.8833 1.24408 11.035 1.51932 11.1364 1.80862Z", fill: "#F5F5F5" })),
            React.createElement("defs", null,
                React.createElement("clipPath", { id: "clip0" },
                    React.createElement("rect", { width: "28", height: "28", fill: "white" }))))));
};
