import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var MotorsportIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0)" },
            React.createElement("path", { d: "M23.9243 14.0088C21.6734 14.0088 19.8486 15.8335 19.8486 18.0844C19.8486 20.3353 21.6734 22.16 23.9243 22.16C26.1752 22.16 27.9999 20.3353 27.9999 18.0844C27.9999 15.8335 26.1752 14.0088 23.9243 14.0088ZM23.9243 20.5879C22.5417 20.5879 21.4207 19.467 21.4207 18.0844C21.4207 16.7017 22.5416 15.5808 23.9243 15.5808C25.3069 15.5808 26.4278 16.7017 26.4278 18.0844C26.4278 19.467 25.3069 20.5879 23.9243 20.5879Z", fill: "#5B6671" }),
            React.createElement("path", { d: "M23.9238 21.0166C22.3727 21.0166 21.0872 19.8047 20.9971 18.2575L21.8528 18.2077C21.9165 19.3021 22.8263 20.1594 23.9238 20.1594C25.0678 20.1594 25.9986 19.2286 25.9986 18.0845C25.9986 16.9405 25.0678 16.0097 23.9238 16.0097C23.1628 16.0097 22.4641 16.4252 22.1002 17.0939L21.3472 16.6843C21.8612 15.7395 22.8484 15.1526 23.9237 15.1526C25.5403 15.1526 26.8556 16.4679 26.8556 18.0845C26.8557 19.7013 25.5405 21.0166 23.9238 21.0166Z", fill: "#374549" }),
            React.createElement("path", { d: "M4.07564 14.0088C1.82476 14.0088 0 15.8335 0 18.0844C0 20.3353 1.82476 22.16 4.07564 22.16C6.32652 22.16 8.15128 20.3353 8.15128 18.0844C8.15123 15.8335 6.32647 14.0088 4.07564 14.0088ZM4.07564 20.5879C2.69303 20.5879 1.5721 19.467 1.5721 18.0844C1.5721 16.7017 2.69298 15.5808 4.07564 15.5808C5.45825 15.5808 6.57912 16.7017 6.57912 18.0844C6.57912 19.467 5.45825 20.5879 4.07564 20.5879Z", fill: "#5B6671" }),
            React.createElement("path", { d: "M4.07519 21.0166C2.45846 21.0166 1.14307 19.7013 1.14307 18.0845C1.14307 16.4677 2.45836 15.1523 4.07519 15.1523C5.69203 15.1523 7.00732 16.4676 7.00732 18.0845C7.00732 19.7013 5.69197 21.0166 4.07519 21.0166ZM4.07519 16.0096C2.93107 16.0096 2.00024 16.9404 2.00024 18.0845C2.00024 19.2287 2.93102 20.1595 4.07519 20.1595C5.21936 20.1595 6.15014 19.2287 6.15014 18.0845C6.15014 16.9404 5.21936 16.0096 4.07519 16.0096Z", fill: "#374549" }),
            React.createElement("path", { d: "M4.07494 18.5131C4.01872 18.5131 3.96157 18.5019 3.90661 18.4785C3.68895 18.3854 3.58805 18.1335 3.68113 17.9159L5.8286 12.8959C5.92168 12.6782 6.17363 12.5773 6.39117 12.6704C6.60883 12.7635 6.70973 13.0154 6.61665 13.233L4.46918 18.253C4.39956 18.4156 4.24135 18.5131 4.07494 18.5131Z", fill: "#374549" }),
            React.createElement("path", { d: "M17.4371 18.4686L24.2187 18.1645C24.6377 18.1457 24.7372 17.5701 24.3489 17.4116L17.6757 14.6887L14.7776 13.7224L12.292 18.6079L17.4371 18.4686Z", fill: "#374549" }),
            React.createElement("path", { d: "M17.4183 8.27411L9.63546 14.4935L9.2252 12.5895L8.59033 9.64381L9.24002 8.81924C9.85602 8.03754 10.828 7.62322 11.8183 7.72155L13.8326 7.92039L17.4183 8.27411Z", fill: "#F2171C" }),
            React.createElement("path", { d: "M6.68582 10.706L8.06482 6.61912C8.21614 6.16872 7.7986 5.7298 7.343 5.8646C2.15064 7.40055 0.134531 13.222 0.00683594 13.6071C0.00453906 13.614 0.00284375 13.6186 0.00169531 13.622C0.000546875 13.6255 0 13.6272 0 13.6272", fill: "#AF0900" }),
            React.createElement("path", { d: "M11.4858 10.4204C12.4469 12.2255 10.3189 12.5421 9.2252 12.5895L8.59033 9.64382L9.24002 8.81924C9.85602 8.03754 10.828 7.62322 11.8183 7.72155L13.8326 7.9204C13.1423 7.85236 10.2149 8.03409 11.4858 10.4204Z", fill: "#AF0900" }),
            React.createElement("path", { d: "M9.63574 14.4935L14.2711 16.3822H14.2717L21.2002 12.9359L22.6516 9.85166C22.8876 9.35018 23.356 8.99761 23.9032 8.90956L27.3772 8.35055C27.7362 8.2928 28.0001 7.98299 28.0001 7.61943C28.0001 7.21048 27.6686 6.87891 27.2596 6.87891H22.3429L17.4185 8.27431H17.418H17.4174L9.97579 10.3829L9.63574 14.4935Z", fill: "#515F84" }),
            React.createElement("path", { d: "M13.1899 9.47197L14.464 10.443C15.0781 10.9111 15.9187 10.9475 16.571 10.5344L22.3428 6.87891", fill: "#444D56" }),
            React.createElement("path", { d: "M12.1287 9.77246L16.9205 12.9154L21.1997 12.9358L14.2705 16.3821H10.7494L9.9751 10.3827L12.1287 9.77246Z", fill: "#3B4A6B" }),
            React.createElement("path", { d: "M9.99223 20.7394C9.54073 20.7394 9.21982 20.2969 9.36403 19.8691C9.55287 19.3088 9.65535 18.7087 9.65535 18.0846C9.65535 18.0365 9.65535 17.988 9.65366 17.9406C9.63479 17.1892 9.46395 16.4852 9.17366 15.852C9.11881 15.732 9.05997 15.6143 8.99593 15.4989C7.82677 13.3778 5.27193 12.1749 2.73366 12.8743L0 13.6274C0.977703 10.7275 5.03371 8.69603 7.99996 9.45028C9.31197 9.78409 10.6267 10.8035 11.3292 11.417C11.6377 11.6866 11.8009 12.082 11.7873 12.4916C11.7859 12.5369 11.7851 12.5823 11.7851 12.628C11.7851 14.8788 13.6096 16.7034 15.8605 16.7034H15.8669C16.2752 16.7031 16.652 16.9249 16.8489 17.2825C17.2695 18.046 17.5704 18.7444 17.7799 19.3108C17.8976 19.6272 17.9869 19.9023 18.0529 20.1243C18.1442 20.4314 17.9131 20.7394 17.5928 20.7394H9.99223V20.7394Z", fill: "#F2171C" }),
            React.createElement("path", { d: "M9.99239 20.7395C9.54089 20.7395 9.21998 20.297 9.3642 19.8692C9.55303 19.3088 9.65552 18.7088 9.65552 18.0846C9.65552 18.0366 9.65552 17.988 9.65382 17.9406C9.63495 17.1892 9.46411 16.4852 9.17383 15.8521C10.1989 17.4961 14.3933 20.3126 17.7801 19.3109C17.8977 19.6273 17.987 19.9024 18.053 20.1243C18.1443 20.4314 17.9132 20.7394 17.5929 20.7394H9.99239V20.7395Z", fill: "#AF0900" }),
            React.createElement("path", { d: "M13.1785 19.8931H12.0643C11.8992 19.8931 11.7488 19.7984 11.6776 19.6495L10.7348 17.6782C10.6713 17.5454 10.6804 17.3893 10.7589 17.2647C10.8374 17.1402 10.9742 17.0647 11.1214 17.0647H13.0928C13.3294 17.0647 13.5213 17.2566 13.5213 17.4933C13.5213 17.73 13.3294 17.9219 13.0928 17.9219H11.8014L12.3343 19.036H13.1784C13.4151 19.036 13.607 19.2279 13.607 19.4646C13.607 19.7012 13.4151 19.8931 13.1785 19.8931Z", fill: "#374549" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0" },
                React.createElement("rect", { width: "28", height: "28", fill: "white" })))));
};
