import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { goBack } from 'connected-react-router';
import { Button } from '~atoms/button';
import { useAppDispatch } from '~store';
import { useIsDesktop } from '~hooks';
import { getTestId } from '~/utils/getTestId';
import { CombinationSelect } from './CombinationSelect';
import { useStyles } from './Nav.styles';
export var Nav = function (_a) {
    var _b;
    var onClose = _a.onClose, clearButtonActive = _a.clearButtonActive, clearBetslipOnClick = _a.clearBetslipOnClick;
    var t = useTranslation('ticket').t;
    var classes = useStyles();
    var dispatch = useAppDispatch();
    var isDesktop = useIsDesktop();
    return (React.createElement("div", { className: classNames(classes.root, 'flex-row', 'space-between', (_b = {},
            _b[classes.rootDesktop] = isDesktop,
            _b)) },
        React.createElement(Button, { disabled: !clearButtonActive, className: classNames(classes.button, !clearButtonActive && classes.buttonDisabled), onClick: clearBetslipOnClick, "data-testid": getTestId('BTN_CLEAR_BETSLIP') },
            React.createElement("i", { className: classNames('far fa-trash', classes.trash) }),
            t('clear_betslip')),
        React.createElement(CombinationSelect, null),
        !isDesktop && (React.createElement("div", { className: classes.closeButton, tabIndex: 0, role: "button", onClick: function () { return (onClose ? onClose() : dispatch(goBack())); } },
            React.createElement("i", { className: classNames('fas fa-times', classes.closeIcon) })))));
};
