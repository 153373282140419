import React from 'react';
import { useTranslation } from 'react-i18next';
import { TournamentsDropDown } from '~atoms';
import { noop } from '~utils/noop';
import { Content } from './Content';
var TournamentsSelect = function (_a) {
    var anchorEl = _a.anchorEl, _b = _a.onDropdownClose, onDropdownClose = _b === void 0 ? noop : _b, _c = _a.onSelect, onSelect = _c === void 0 ? noop : _c, _d = _a.open, open = _d === void 0 ? false : _d;
    useTranslation('common'); // useTranslation fixes flickering
    return (React.createElement(TournamentsDropDown, { open: open, anchorEl: anchorEl, onClose: onDropdownClose },
        React.createElement(Content, { onSelect: onSelect })));
};
export { TournamentsSelect };
