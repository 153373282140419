var LayoutOptions;
(function (LayoutOptions) {
    LayoutOptions["OPTION_1"] = "OPTION_1";
    LayoutOptions["OPTION_2"] = "OPTION_2";
    LayoutOptions["OPTION_3"] = "OPTION_3";
    LayoutOptions["OPTION_4"] = "OPTION_4";
    LayoutOptions["OPTION_5"] = "OPTION_5";
    LayoutOptions["OPTION_6"] = "OPTION_6";
    LayoutOptions["OPTION_7"] = "OPTION_7";
    LayoutOptions["OPTION_10"] = "OPTION_10";
    LayoutOptions["OPTION_11"] = "OPTION_11";
})(LayoutOptions || (LayoutOptions = {}));
export { LayoutOptions };
