import { deletePurchasedTicket } from '~api/deletePurchasedTicket';
import { getAchievements } from '~api/getAchievements';
import { getJokersStatistics } from '~api/getJokersStatistics';
import { postGameBalance } from '~api/postGameBalance';
import { postJokersUsage } from '~api/postJokersUsage';
import { postTicketsRequest } from '~api/postTicketsRequest';
import { postGameTickets } from '~api/postGameTickets';
import { putConversionTeaserClick } from '~api/putConversionTeaserClick';
import { postTopUp } from '~api/topUp';
import { getTopUpLimits } from '~api/getTopUpLimits';
import { getGamesHistory } from '~api/getGameHistory';
import { getArchiveTickets } from '~api/getArchiveTickets';
import { getWatchVideoLimits } from '~api/getWatchVideoLimits';
import { getTournamentById } from './getTournamentById';
import { getTournamentsList } from './getTournamentsList';
import { getFlagsList } from './getFlagsList';
import { getCategoryOutrights } from './getCategoryOutrights';
import { getOutrightsList } from './getOutrightsList';
import { getMainColumnsInfo } from './getMainColumnsInfo';
import { getOptionsMap } from './getOptionsMap';
var API = {
    deletePurchasedTicket: deletePurchasedTicket,
    getTournamentById: getTournamentById,
    getTournamentsList: getTournamentsList,
    getFlagsList: getFlagsList,
    getCategoryOutrights: getCategoryOutrights,
    getOutrightsList: getOutrightsList,
    getMainColumnsInfo: getMainColumnsInfo,
    getOptionsMap: getOptionsMap,
    postGameTickets: postGameTickets,
    postTopUp: postTopUp,
    postTicketsRequest: postTicketsRequest,
    postGameBalance: postGameBalance,
    postJokersUsage: postJokersUsage,
    putConversionTeaserClick: putConversionTeaserClick,
    getTopUpLimits: getTopUpLimits,
    getGamesHistory: getGamesHistory,
    getArchiveTickets: getArchiveTickets,
    getWatchVideoLimits: getWatchVideoLimits,
    getAchievements: getAchievements,
    getJokersStatistics: getJokersStatistics,
};
export { API };
