import React from 'react';
import { OddPlateView } from '~atoms';
import { OddWidth } from '~constants/view/OddWidth';
import { useIsEasyGameSelector } from "~slices/gameTypes";
import { noop } from '~utils/noop';
import { selectIsOddSelected } from '~slices/shortcut';
import { useStructuredSelector } from '~hooks';
var OddPlateV2 = function (_a) {
    var _b = _a.title, title = _b === void 0 ? '' : _b, name = _a.name, _c = _a.value, value = _c === void 0 ? 0 : _c, id = _a.id, obid = _a.obid, mk = _a.mk, _d = _a.eventId, eventId = _d === void 0 ? 0 : _d, isActive = _a.isActive, isWidget = _a.isWidget, isPurchased = _a.isPurchased, isDisabled = _a.isDisabled, isMakeTipButton = _a.isMakeTipButton, ticketValue = _a.ticketValue, _e = _a.oddWidth, oddWidth = _e === void 0 ? OddWidth.S : _e, _f = _a.onSelect, onSelect = _f === void 0 ? noop : _f, _g = _a.onRemove, onRemove = _g === void 0 ? noop : _g, _h = _a.onChangeTicket, onChangeTicket = _h === void 0 ? noop : _h, className = _a.className, index = _a.index, columnsCount = _a.columnsCount;
    var _j = useStructuredSelector({
        isSelected: function (state) { return selectIsOddSelected(state, eventId, id || 0, mk); },
        isEasyGame: useIsEasyGameSelector
    }), isSelected = _j.isSelected, isEasyGame = _j.isEasyGame;
    var handleOddClick = function () {
        if (typeof obid !== 'string' || (isDisabled && !isMakeTipButton)) {
            return;
        }
        if (isEasyGame && isWidget) {
            onChangeTicket(obid, value, isMakeTipButton);
            return;
        }
        if (isSelected) {
            onRemove(obid);
        }
        else {
            onSelect(obid, value);
        }
    };
    return (React.createElement(OddPlateView, { value: value, title: title, name: name, active: isActive, isWidget: isWidget, isPurchased: isPurchased, ticketValue: ticketValue, oddWidth: oddWidth, className: className, onClick: isActive ? handleOddClick : undefined, selected: isSelected, index: index, columnsCount: columnsCount }));
};
export { OddPlateV2 };
