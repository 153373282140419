import makeStyles from '@material-ui/core/styles/makeStyles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
var useStyles = makeStyles(function () { return ({
    container: {
        position: 'fixed',
        display: 'flex',
        width: '100%',
        height: '100%',
        overflow: 'auto',
        backgroundColor: alpha('#151515', 0.6),
        alignItems: 'center',
        justifyContent: 'center',
        top: 0,
        left: 0,
    },
    transparent: {
        backgroundColor: 'transparent',
    },
    noPointerEvents: {
        pointerEvents: 'none',
        '& *': {
            pointerEvents: 'auto',
        },
    },
}); }, { name: 'Portal' });
export { useStyles };
