import React, { memo } from 'react';
import { HORIZON_GALLERY_TYPE } from '~slices/teasers';
import { CarouselSlider } from './CarouselSlider';
import { HorizonGallery } from './HorizonGallery';
export var Slider = memo(function Slider(_a) {
    var position = _a.position, slideshows = _a.slideshows, className = _a.className;
    var slideshowIndex = slideshows === null || slideshows === void 0 ? void 0 : slideshows.map(function (ssh) { var _a; return (_a = ssh === null || ssh === void 0 ? void 0 : ssh.pages) === null || _a === void 0 ? void 0 : _a.filter(function (page) { return page.location === position; }); }).map(function (res) { return (res === null || res === void 0 ? void 0 : res.length) !== 0; }).indexOf(true);
    var slideshow = slideshows && slideshowIndex !== undefined
        ? slideshows[slideshowIndex]
        : undefined;
    return (slideshow === null || slideshow === void 0 ? void 0 : slideshow.slideshowType) === HORIZON_GALLERY_TYPE ? (React.createElement(HorizonGallery, { position: position, slideshow: slideshow, type: slideshow === null || slideshow === void 0 ? void 0 : slideshow.slideshowType, className: className })) : (React.createElement(CarouselSlider, { position: position, slideshow: slideshow, type: slideshow === null || slideshow === void 0 ? void 0 : slideshow.slideshowType, className: className }));
});
