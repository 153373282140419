import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
var ArrowPositionContext = React.createContext(0);
var useArrowPositionContext = function () { return useContext(ArrowPositionContext); };
var ArrowPositionProvider = function (_a) {
    var children = _a.children, element = _a.element;
    var _b = useState(0), left = _b[0], setLeft = _b[1];
    var elementRef = useRef(element);
    elementRef.current = element;
    var observer = useMemo(function () {
        var handleMutation = function (mutations) {
            mutations.forEach(function (mutation) {
                if (mutation.type === 'attributes' &&
                    mutation.attributeName === 'style') {
                    if (elementRef.current) {
                        var left_1 = getComputedStyle(elementRef.current).left;
                        setLeft(Number(left_1.slice(0, -2)));
                    }
                }
            });
        };
        return new MutationObserver(handleMutation);
    }, []);
    useEffect(function () {
        if (element) {
            setTimeout(function () {
                var left = getComputedStyle(element).left;
                setLeft(Number(left.slice(0, -2)));
            });
            observer.observe(element, { attributes: true });
        }
        var handleResize = function () {
            if (element) {
                var left_2 = getComputedStyle(element).left;
                setLeft(Number(left_2.slice(0, -2)));
            }
        };
        window.addEventListener('resize', handleResize);
        return function () {
            window.removeEventListener('resize', handleResize);
            observer.disconnect();
        };
    }, [element]);
    return (React.createElement(ArrowPositionContext.Provider, { value: left }, children));
};
export { ArrowPositionProvider, useArrowPositionContext };
