import React, { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { ConnectedRouter } from 'connected-react-router';
import { RootModal } from '~modals';
import { BPRegistration } from '~organisms';
import { Main } from '~mainGrid';
import { history } from '~utils/history';
import scrollWatcher from '~utils/scroll';
import { BPList, ConfirmationLink, PasswordRestoreForm, RegisterRoutes, SelectPPMO, Sidebets, Statistic, } from '~pages';
import LazyRoute from '~routes/LazyRoute';
import { PaymentsRoutes } from '~pages/payments/payments.routes';
import { PrivateRoute } from './PrivateRoute';
var TRACKING_ID = process.env.REACT_APP_TRACKING_ID || '';
scrollWatcher.setHistory(history.location.pathname, history.location.pathname, history.action);
ReactGA.initialize(TRACKING_ID);
// Initialize google analytics page view tracking
history.listen(function (location) {
    var current = scrollWatcher.getState();
    scrollWatcher.setHistory(current.prevPath ? current.currentPath : location.pathname, location.pathname, history.action);
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.send({ hitType: 'pageview', page: location.pathname }); // Record a pageview for the given page
});
var Home = lazy(function () { return import(/*webpackChunkName: "Home"*/ '~pages/home/Home'); });
var Options = lazy(function () { return import(/*webpackChunkName: "Options"*/ '~pages/options/Options'); });
var About = lazy(function () { return import(/*webpackChunkName: "About"*/ '~pages/about/About'); });
var TopRanking = lazy(function () {
    return import(/*webpackChunkName: "TopRanking"*/ '~pages/top-ranking/TopRanking');
});
var MyTipsList = lazy(function () { return import('~pages/tips-list/TipsList'); });
var MyAccountRoutes = lazy(function () { return import('~pages/my-account/my-account.routes'); });
var Start = lazy(function () { return import('~pages/start/Start'); });
var TouItUp = lazy(function () { return import('~pages/top-it-up/TopItUp'); });
var RankingListMobile = lazy(function () { return import('~pages/ranking-list/RankingListMobile'); });
var Promo = lazy(function () { return import('~pages/promo/Promo'); });
export var AppRoutes = function () { return (React.createElement(React.Suspense, { fallback: null },
    React.createElement(ConnectedRouter, { history: history },
        React.createElement(Main, null,
            React.createElement(Switch, null,
                React.createElement(Route, { path: "/", component: Main, exact: true }),
                React.createElement(Route, { path: "/:lang?/login", component: Start }),
                React.createElement(LazyRoute, { path: "/:lang?/home", component: Home }),
                React.createElement(LazyRoute, { path: "/:lang?/top-ranking", component: TopRanking }),
                React.createElement(LazyRoute, { exact: true, path: "/:lang?/about", component: About }),
                React.createElement(LazyRoute, { path: "/:lang?/about/:type/:tab", component: About }),
                React.createElement(LazyRoute, { path: '/:lang?/my-tips', component: MyTipsList }),
                React.createElement(LazyRoute, { path: '/:lang?/start', component: Start }),
                React.createElement(LazyRoute, { path: '/:lang?/top-it-up', component: TouItUp }),
                React.createElement(LazyRoute, { path: '/:lang?/ranking-list', component: RankingListMobile }),
                React.createElement(Route, { path: "/:lang?/register", component: RegisterRoutes }),
                React.createElement(PrivateRoute, { path: "/:lang?/code-confirmation", component: ConfirmationLink }),
                React.createElement(PrivateRoute, { path: "/:lang?/bp-registration", component: BPRegistration }),
                React.createElement(LazyRoute, { path: "/:lang?/my-account", component: MyAccountRoutes }),
                React.createElement(LazyRoute, { path: "/:lang?/payments", component: PaymentsRoutes }),
                React.createElement(LazyRoute, { path: "/:lang?/payments/:type", component: PaymentsRoutes }),
                React.createElement(PrivateRoute, { path: "/:lang?/selectPPMO", component: SelectPPMO }),
                React.createElement(Route, { path: "/:lang?/restore-password", component: PasswordRestoreForm }),
                React.createElement(Route, { path: "/:lang?/sidebets/:id", component: Sidebets }),
                React.createElement(Route, { path: "/:lang?/statistic/:season/:eventId", component: Statistic }),
                React.createElement(Route, { path: "/:lang?/bp-list", component: BPList }),
                React.createElement(LazyRoute, { privateRoute: true, path: "/:lang?/options", component: Options }),
                React.createElement(LazyRoute, { privateRoute: true, path: "/:lang?/promo", component: Promo }),
                React.createElement(Redirect, { from: "*", to: '/' })),
            React.createElement(RootModal, null))))); };
