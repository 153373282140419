import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var SkeletonIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, viewBox: "0 0 28 28" },
        React.createElement("svg", { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("g", { clipPath: "url(#clip0)" },
                React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0.135642 11.4582C0.40507 11.0106 0.986334 10.8662 1.43393 11.1356L20.6953 22.7299H23.2704C23.7928 22.7299 24.2164 23.1534 24.2164 23.6759C24.2164 24.1983 23.7928 24.6218 23.2704 24.6218H20.1698L0.458245 12.7565C0.0106477 12.4871 -0.133787 11.9058 0.135642 11.4582Z", fill: "#546E7A" }),
                React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M20.6671 16.5806C20.2859 17.2048 19.9322 17.4368 19.308 17.0556L0.648007 5.66123C0.0237818 5.28006 -0.17325 4.46503 0.207922 3.84081C0.589094 3.21658 1.40413 3.01955 2.02835 3.40072L20 14.5C20.6242 14.8812 21.0483 15.9564 20.6671 16.5806Z", fill: "#FE7851" }),
                React.createElement("path", { d: "M19.3043 9.16481C19.2357 9.15685 19.17 9.13327 19.1117 9.09633L10.777 3.93144C10.62 3.85169 10.5214 3.69006 10.522 3.51408C10.5227 3.33788 10.6225 3.17703 10.7801 3.09849C10.9378 3.01974 11.1261 3.03662 11.2674 3.1419L19.6021 8.30679C19.7854 8.4225 19.8646 8.64922 19.7937 8.85396C19.7225 9.05868 19.5199 9.18762 19.3043 9.16481Z", fill: "#95C2D5" }),
                React.createElement("path", { d: "M21.7205 8.54838C21.652 8.54 21.586 8.51639 21.5277 8.47967L17.5799 6.03285C17.3675 5.89442 17.3044 5.61164 17.4379 5.39626C17.5714 5.18067 17.8527 5.11151 18.071 5.24012L22.0191 7.68696C22.2065 7.80117 22.2889 8.03065 22.217 8.23812C22.1452 8.44538 21.9383 8.57472 21.7205 8.54838Z", fill: "#95C2D5" }),
                React.createElement("path", { d: "M17.2447 17.5686L8.8242 10.7972C8.52551 10.557 8.3501 10.1957 8.34611 9.8124C8.33977 9.20191 8.76521 8.67181 9.36259 8.54586L9.45267 8.52686C9.7962 8.45444 10.1544 8.51439 10.4556 8.69473L20.026 14.4244C20.2172 14.5389 20.385 14.6885 20.5205 14.8654C20.9961 15.4859 21.007 16.3454 20.5474 16.9778L20.3916 17.192C19.9214 17.839 19.1271 18.1664 18.3376 18.0387L18.2797 18.0293C17.9006 17.968 17.544 17.8093 17.2447 17.5686Z", fill: "#E64A19" }),
                React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M19.8559 17.9989C20.0246 17.7434 20.5 17 20.5 17C20.5 17 19.892 17.2622 19.4248 16.9992L9.20976 10.4596C8.75202 10.1573 8.13589 10.2834 7.83361 10.7411C7.53133 11.1989 7.65736 11.815 8.11511 12.1173L17.895 18.484C18.7652 18.8297 19.4155 18.6656 19.8559 17.9989Z", fill: "#FE7851" }),
                React.createElement("path", { d: "M26.3491 20.9539C24.8955 22.0733 22.8096 21.8025 21.6901 20.3488C20.5706 18.8951 20.8417 16.8092 22.2952 15.6898C23.7489 14.5703 25.8347 14.8412 26.9542 16.2949C28.0737 17.7486 27.8028 19.8344 26.3491 20.9539Z", fill: "#F6DB6D" }),
                React.createElement("path", { d: "M27.5834 17.703L25.3787 17.6278C24.9671 17.6136 24.5695 17.7783 24.2885 18.0793C24.0075 18.3804 23.8706 18.7883 23.9132 19.198L24.166 21.6386C25.1817 21.6879 26.1637 21.2685 26.8305 20.5007C27.497 19.7329 27.7746 18.7016 27.5834 17.703Z", fill: "#586270" })),
            React.createElement("defs", null,
                React.createElement("clipPath", { id: "clip0" },
                    React.createElement("rect", { width: "28", height: "28", fill: "white" }))))));
};
