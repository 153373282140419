var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import { Event } from '~organisms';
import { SystemOverview } from '~molecules';
import { currencyConverter } from '~utils/converters';
import { WonLostIndicator } from '~atoms';
import { PayoutStatusBtn } from "~view/pages";
import { useStyles } from './TipDetails.styles';
import '../ticket.scss';
export var TipDetails = function (_a) {
    var _b, _c, _d, _e;
    var ticket = _a.ticket;
    var t = useTranslation('ticket').t;
    var classes = useStyles();
    var betType = ticket.betType, maxOdds = ticket.maxOdds, maxPayout = ticket.maxPayout, mtsTicketId = ticket.mtsTicketId, providerId = ticket.providerId, status = ticket.status, ticketDetails = ticket.ticketDetails, wager = ticket.wager, cashout = ticket.cashout, currency = ticket.currency;
    var convertedMaxPayout = currencyConverter(maxPayout, currency);
    var convertedWagerAmount = currencyConverter(wager, currency);
    var isCashOutPosible = status === 'OPEN' && cashout;
    var bets = 0;
    var statuses = (_b = ticketDetails === null || ticketDetails === void 0 ? void 0 : ticketDetails.evs) === null || _b === void 0 ? void 0 : _b.map(function (event) {
        var _a;
        // @ts-expect-error
        return Object.values((_a = event.sl) === null || _a === void 0 ? void 0 : _a.sel).map(function (selection) {
            var _a, _b;
            return (_b = (_a = selection.oc) === null || _a === void 0 ? void 0 : _a.odd) === null || _b === void 0 ? void 0 : _b.st;
        });
    }).flat();
    (_c = ticketDetails === null || ticketDetails === void 0 ? void 0 : ticketDetails.bts) === null || _c === void 0 ? void 0 : _c.map(function (bet) {
        var _a;
        bets += ((_a = bet.otccl) === null || _a === void 0 ? void 0 : _a.length) || 0;
    });
    var wrapperRef = useRef(null);
    var footerRef = useRef(null);
    var wrapperHeight;
    var footerHeight;
    if (wrapperRef && wrapperRef.current) {
        wrapperHeight = wrapperRef.current.offsetHeight;
    }
    if (footerRef && footerRef.current) {
        footerHeight = footerRef.current.offsetHeight;
    }
    var mainHeight = wrapperHeight && footerHeight && wrapperHeight - footerHeight;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { ref: wrapperRef, className: classes.wrapper },
            React.createElement("div", { className: classes.main, style: { height: "".concat(mainHeight, "px") } },
                React.createElement("div", { className: classes.stickyContainer },
                    React.createElement("header", { className: classes.header },
                        React.createElement(Grid, { container: true, alignItems: "center", spacing: 1, xs: 10 },
                            React.createElement("span", { className: classes.type }, betType === null || betType === void 0 ? void 0 : betType.toLowerCase()),
                            React.createElement("span", { className: classes.bets },
                                bets,
                                " ",
                                t('bets', { count: bets })),
                            React.createElement("div", { className: classes.indicators }, statuses === null || statuses === void 0 ? void 0 : statuses.map(function (statuss, index) { return (React.createElement(WonLostIndicator, { key: (statuss || 'UNABLE') + index, status: statuss, smallCircle: false })); }))),
                        React.createElement("div", { className: classNames(classes.status, isCashOutPosible ? 'sign__CASH_OUT' : "sign__".concat(status)) }, isCashOutPosible ? t('cash_out') : t("status.".concat(status))))),
                React.createElement(Grid, { className: classes.info, container: true, alignItems: "center", spacing: 1, xs: 12 },
                    React.createElement(Grid, { item: true, xs: 4 },
                        React.createElement("div", { className: "bold" }, t('wager')),
                        React.createElement("div", null, convertedWagerAmount)),
                    React.createElement(Grid, { item: true, xs: 4 },
                        React.createElement("div", { className: "bold" }, t('max_odd')),
                        React.createElement("div", null, maxOdds)),
                    React.createElement(Grid, { item: true, xs: 4 },
                        React.createElement("div", { className: "bold" }, t('max_payout')),
                        React.createElement("div", null, convertedMaxPayout))),
                React.createElement(Grid, { item: true, xs: 12 }, (_e = (_d = ticket.ticketDetails) === null || _d === void 0 ? void 0 : _d.evs) === null || _e === void 0 ? void 0 : _e.map(function (event) {
                    var _a;
                    return (React.createElement(Event, { key: event.id, showBanker: !!((_a = event.sl) === null || _a === void 0 ? void 0 : _a.ba), 
                        // @ts-expect-error
                        event: event, toRedirectToSidebets: true, purchasedTicket: true }));
                }))),
            React.createElement("footer", { ref: footerRef, className: classes.footer },
                React.createElement(SystemOverview, __assign({}, ticket, ticket.ticketDetails)),
                Boolean(cashout) && (React.createElement(PayoutStatusBtn, { providerId: providerId, ticketId: mtsTicketId, cashout: cashout, currency: currency }))))));
};
