import React from 'react';
import arrowUp from '~icons/rankingList/green-arrow-up.svg';
import { CSIcon } from "~icons/rankingList/CSIcon";
import { useStyles } from "~molecules/BalanceTrend/BalanceTrend.styles";
export var BalanceTrend = function (_a) {
    var balance = _a.balance, _b = _a.trend, trend = _b === void 0 ? 'up' : _b;
    var classes = useStyles();
    var arrowImg = trend === 'up' ? React.createElement("img", { src: arrowUp, alt: 'arrow up' }) : null;
    return (React.createElement("div", { className: classes.wrapper },
        arrowImg,
        React.createElement("span", null, balance),
        React.createElement(CSIcon, { size: 'sm', color: '#6CFF00' })));
};
