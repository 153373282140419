import React, { memo } from 'react';
import classNames from 'classnames';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import general from '~icons/general-banner.svg';
import { GALLERY_TYPE, SLIDESHOW_TYPE } from '~slices/teasers';
import { useIsDesktop } from '~hooks';
import { SlideTemplate, gallerySlide, slideShowSlide } from './Slides';
export var useStyles = makeStyles({
    mainContainer: {
        '& li.slide': {
            background: 'none !important',
        },
    },
    bannerWrapper: {
        width: '100%',
        outline: 'none',
        cursor: 'pointer',
        userSelect: 'none',
        position: 'relative',
    },
    desktopBanner: {
        height: '200px',
    },
    mobileBanner: {
        height: '120px',
    },
    indicator: {
        borderRadius: '50%',
        opacity: 0.4,
        border: '1px solid #FFFFFF',
        width: 8,
        height: 8,
        display: 'inline-block',
        margin: '0 2px -5px 2px',
    },
    galleryIndicator: {
        background: '#8E8E8E',
        border: 'none',
    },
    activeIndicator: {
        borderRadius: '50%',
        background: '#2AA1F7',
        opacity: 0.6,
        border: '2px solid #FFFFFF',
        width: 8,
        height: 8,
        display: 'inline-block',
        margin: '0 2px -5px 2px',
    },
    galleryActiveIndicator: {
        background: '#FFFFFF',
        border: 'none',
        opacity: 1,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: '8px',
        background: '#151515',
        height: '433px',
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    item: {
        margin: '8px',
        width: '196px',
        height: '180px',
        backgroundColor: '#323232',
        borderRadius: '4px',
        fontSize: '30px',
        textAlign: 'center',
    },
}, { name: 'CarouselSlider' });
var DefaultSlide = function (_a) {
    var _b;
    var type = _a.type, isDesktop = _a.isDesktop, className = _a.className;
    var classes = useStyles();
    return (React.createElement("div", { className: classNames(classes.bannerWrapper, className, (_b = {},
            _b[classes.desktopBanner] = isDesktop && type === SLIDESHOW_TYPE,
            _b[classes.mobileBanner] = !isDesktop && type === SLIDESHOW_TYPE,
            _b)) }, type === SLIDESHOW_TYPE ? (React.createElement(SlideTemplate, { imageSrc: general, offer: "BETCHECK" })) : (React.createElement("div", { className: classes.container },
        React.createElement("div", { className: classes.flexRow },
            React.createElement("div", { className: classes.item }, "1"),
            React.createElement("div", { className: classes.item }, "2")),
        React.createElement("div", { className: classes.flexRow },
            React.createElement("div", { className: classes.item }, "3"),
            React.createElement("div", { className: classes.item }, "4"))))));
};
var CarouselSlider = memo(function CarouselSlider(_a) {
    var type = _a.type, className = _a.className, slideshow = _a.slideshow, position = _a.position;
    var isDesktop = useIsDesktop();
    var classes = useStyles();
    var isGallery = type === GALLERY_TYPE;
    var t = useTranslation('common').t;
    var _b = React.useState(3000), interval = _b[0], setInterval = _b[1];
    var renderIndicator = function (clickHandler, isSelected, index, label) {
        var _a, _b;
        return isSelected ? (React.createElement("div", { className: classNames(classes.activeIndicator, (_a = {},
                _a[classes.galleryActiveIndicator] = isGallery,
                _a)), "aria-label": "".concat(t('selected'), ": ").concat(label, " ").concat(index + 1), title: "".concat(t('selected'), ": ").concat(label, " ").concat(index + 1) })) : (React.createElement("div", { role: "button", className: classNames(classes.indicator, (_b = {},
                _b[classes.galleryIndicator] = isGallery,
                _b)), onClick: clickHandler, onKeyDown: clickHandler, key: index, tabIndex: 0, title: "".concat(label, " ").concat(index + 1), "aria-label": "".concat(label, " ").concat(index + 1) }));
    };
    // @ts-ignore
    var onChange = function (index, item) {
        // eslint-disable-next-line @typescript-eslint/no-implied-eval
        setInterval(item.props.interval * 1000);
    };
    var slideFunc = isGallery ? gallerySlide : slideShowSlide;
    return slideshow ? (React.createElement(Carousel, { className: classNames(className, classes.mainContainer), showArrows: false, showStatus: false, showIndicators: (slideshow === null || slideshow === void 0 ? void 0 : slideshow.teasers) && (slideshow === null || slideshow === void 0 ? void 0 : slideshow.teasers.length) > 1, infiniteLoop: (slideshow === null || slideshow === void 0 ? void 0 : slideshow.teasers) && (slideshow === null || slideshow === void 0 ? void 0 : slideshow.teasers.length) > 1, showThumbs: false, useKeyboardArrows: true, autoPlay: (slideshow === null || slideshow === void 0 ? void 0 : slideshow.teasers) && (slideshow === null || slideshow === void 0 ? void 0 : slideshow.teasers.length) > 1, stopOnHover: true, swipeable: (slideshow === null || slideshow === void 0 ? void 0 : slideshow.teasers) && (slideshow === null || slideshow === void 0 ? void 0 : slideshow.teasers.length) > 1, dynamicHeight: false, emulateTouch: (slideshow === null || slideshow === void 0 ? void 0 : slideshow.teasers) && (slideshow === null || slideshow === void 0 ? void 0 : slideshow.teasers.length) > 1, transitionTime: 500, interval: type === GALLERY_TYPE
            ? ((slideshow === null || slideshow === void 0 ? void 0 : slideshow.duration) && (slideshow === null || slideshow === void 0 ? void 0 : slideshow.duration) * 1000) || 3000
            : interval, renderIndicator: renderIndicator, onChange: onChange }, slideFunc({
        slideshow: slideshow,
        position: position,
        isDesktop: isDesktop,
        classes: classes,
    }))) : null;
});
export { DefaultSlide, CarouselSlider };
