import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var StreetFighterIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, viewBox: "0 0 28 28" },
        React.createElement("svg", { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M10 2H2.5V4.5L14 24.5L25.5 4.5V2H18L14 10L10 2Z", fill: "black" }),
            React.createElement("path", { d: "M4 2L16 22", stroke: "#F49200", strokeWidth: "0.5" }),
            React.createElement("path", { d: "M21 2L13 17", stroke: "#F49200", strokeWidth: "0.5" }),
            React.createElement("path", { d: "M2 4.36521V2H10.5V3V3.1275L10.5611 3.23943L13.5611 8.73943L13.9932 9.53164L14.4357 8.74522L17.531 3.24522L17.5952 3.13103V3V2H26V4.36521L14 25.0052L2 4.36521Z", stroke: "#F49200" }),
            React.createElement("rect", { y: "2", width: "2", height: "3", fill: "#F49200" }),
            React.createElement("rect", { x: "26", y: "1.5", width: "1.5", height: "4", fill: "#F49200" }),
            React.createElement("path", { d: "M2.5 5.25H0.75V1.75H10.7065V2.25V2.30662L10.7309 2.35771L13.7744 8.73271L14 9.20528L14.2256 8.73271L17.2691 2.35771L17.2935 2.30662V2.25V1.75H27.25V4.75H26H25.8549L25.7829 4.87597L14.002 25.4926L2.71804 5.37769L2.64641 5.25H2.5Z", stroke: "#F49200", strokeWidth: "0.5" }),
            React.createElement("path", { d: "M25.7829 5.87597L14 26.4961L2.21706 5.87597L2.14508 5.75H2H0.675814L0.25 5.38502V1.60355L0.603553 1.25H10.8964L11.25 1.60355V2.5V2.55902L11.2764 2.6118L13.7764 7.6118L14 8.05902L14.2236 7.6118L16.7236 2.6118L16.75 2.55902V2.5V1.69463L17.1177 1.25H27.3964L27.75 1.60355V5.39645L27.3964 5.75H26H25.8549L25.7829 5.87597Z", stroke: "black", strokeWidth: "0.5" }))));
};
