import React, { useCallback, useEffect, useState } from 'react';
import { CategoryCard, EventsDropDown, TextInput } from '~atoms';
import { noop } from '~utils/noop';
import { useStyles } from './CategorySelect.styles';
var CategorySelect = function (_a) {
    var selectedSportId = _a.selectedSportId, _b = _a.open, open = _b === void 0 ? false : _b, anchorEl = _a.anchorEl, _c = _a.categories, categories = _c === void 0 ? [] : _c, url = _a.url, _d = _a.onDropdownClose, onDropdownClose = _d === void 0 ? noop : _d, _e = _a.onSportClick, onSportClick = _e === void 0 ? noop : _e;
    var classes = useStyles();
    var _f = useState(''), value = _f[0], setValue = _f[1];
    useEffect(function () {
        if (open) {
            setValue('');
        }
    }, [open]);
    var handleSportClick = useCallback(function (id) {
        onSportClick(id);
    }, [onSportClick]);
    var handleChange = function (e) {
        setValue(e.target.value);
    };
    var searchValue = value.toLowerCase().trim();
    var filteredSports = categories.filter(function (_a) {
        var name = _a.name;
        return !!(name !== null && name !== void 0 ? name : '').toLowerCase().includes(searchValue);
    });
    return (React.createElement(EventsDropDown, { open: open, anchorEl: anchorEl, onClose: onDropdownClose, zIndex: 40, alignment: "left" },
        React.createElement("div", { className: classes.selectContent },
            React.createElement(TextInput, { className: classes.input, value: value, onChange: handleChange }),
            React.createElement("div", { className: classes.sports }, filteredSports.map(function (_a) {
                var id = _a.id, _b = _a.path, path = _b === void 0 ? '' : _b, _c = _a.name, name = _c === void 0 ? '' : _c;
                return (React.createElement(CategoryCard, { key: Number(id), active: id === selectedSportId, highlightedValue: searchValue, fl: path, id: Number(id), name: name, defaultUrl: url, onClick: handleSportClick }));
            })))));
};
export { CategorySelect };
