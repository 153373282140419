var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createSlice } from '@reduxjs/toolkit';
import { createAsyncReducers } from '~utils/createAsyncReducers';
import { range } from '~utils/range';
import { name } from './constants';
import { getMainColumnsInfo } from './thunks';
var initialState = {
    data: [],
    meta: {
        order: range(10),
        langIso: '',
    },
    loading: 'idle',
    error: null,
};
export var markets = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        switchPlaces: function (state, _a) {
            var payload = _a.payload;
            var a = payload.a, b = payload.b;
            var prevOrder = state.meta.order;
            var order = __spreadArray([], prevOrder, true);
            order[a] = prevOrder[b];
            order[b] = prevOrder[a];
            state.meta.order = order;
        },
    },
    extraReducers: function (builder) {
        var _a = createAsyncReducers(), pending = _a.pending, rejected = _a.rejected;
        builder
            .addCase(getMainColumnsInfo.pending, pending)
            .addCase(getMainColumnsInfo.fulfilled, function (state, action) {
            state.data = action.payload;
            state.loading = 'fulfilled';
            state.meta.langIso = action.meta.arg.language;
        })
            .addCase(getMainColumnsInfo.rejected, rejected);
    },
});
export var switchPlaces = markets.actions.switchPlaces;
