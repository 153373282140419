import { createSelector } from 'reselect';
import { name } from './constants';
export var shortcutSelector = function (state) { return state[name]; };
export var shortcutDataSelector = function (state) {
    return shortcutSelector(state).data;
};
export var shortcutCountSelector = createSelector(shortcutDataSelector, function (shortcutData) {
    var shortcut = shortcutData.ps || null;
    if (!shortcut) {
        return 0;
    }
    var shortcutValues = Object.values(shortcut);
    return shortcutValues.reduce(function (count, item) {
        return count + item.length;
    }, 0);
});
export var shortcutHasSelectionSelector = createSelector(shortcutCountSelector, function (count) { return count > 0; });
export var shortcutByIdSelector = createSelector([shortcutDataSelector, function (state, id) { return id; }], function (shortcutData, id) {
    var shortcut = shortcutData.ps;
    return (shortcut && shortcut[id]) || [];
});
export var selectIsOddSelected = createSelector(function (state, eventId) { return shortcutByIdSelector(state, eventId); }, function (_, eventId, id) { return id; }, function (_, eventId, id, mk) { return mk; }, function (shortcutEvents, id, mk) {
    return shortcutEvents.some(function (event) { return event.oi === id && event.mi === mk; });
});
export var bsSelector = createSelector(function (state) { return shortcutDataSelector(state).bs; }, function (bs) { return bs; });
