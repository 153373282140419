import { createSlice } from '@reduxjs/toolkit';
import { createAsyncReducers } from '~utils/createAsyncReducers';
import { getUserCountryLanguage } from '~slices/userCountryLanguage/thunks';
import { name } from './constants';
var initialState = {
    data: { languageCode: '' },
    loading: 'idle',
    error: null,
};
export var userCountryLanguage = createSlice({
    name: name,
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        var _a = createAsyncReducers(), pending = _a.pending, fulfilled = _a.fulfilled, rejected = _a.rejected;
        builder
            .addCase(getUserCountryLanguage.pending, pending)
            .addCase(getUserCountryLanguage.fulfilled, fulfilled)
            .addCase(getUserCountryLanguage.rejected, rejected);
    },
});
