import makeStyles from '@material-ui/core/styles/makeStyles';
export var useStyles = makeStyles({
    container: {
        position: 'relative',
        display: 'flex',
        height: 104,
        borderRadius: 14,
        boxSizing: 'border-box',
    },
    avatarCard: {
        position: 'absolute',
        top: 0,
        width: 104,
        borderRadius: 14,
        background: 'linear-gradient(90deg, #211A92 0%, #1B66B9 0.01%, #2C479A 100%)',
        zIndex: 2,
        boxShadow: '2.6px 0px 5.2px 0px rgba(0, 0, 0, 0.30)',
        backgroundColor: 'transparent',
    },
    avatarWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 52,
        background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.08) 23.12%, rgba(0, 0, 0, 0.00) 40.99%, rgba(0, 0, 0, 0.00) 60.42%, rgba(0, 0, 0, 0.08) 75%, rgba(0, 0, 0, 0.25) 100%)',
        backgroundColor: 'transparent',
        '& img': {
            height: 51,
            width: 48,
        },
    },
    avatarNameWrapper: {
        height: 21,
        textAlign: 'center'
    },
    avatarName: {
        textAlign: 'center',
        fontSize: 12,
        color: '#FFFFFF',
    },
    starsWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 31,
        overflow: 'hidden',
    },
    wrapper: {
        position: 'relative',
        display: 'flex',
        alignSelf: 'center',
        justifyContent: 'center',
        height: 88,
        width: '100%',
        columnGap: 20,
        paddingLeft: 130,
        background: 'linear-gradient(90deg, #211A92 0%, #1B66B9 0.01%, #2C479A 100%)',
        boxSizing: 'border-box',
        borderRadius: 8,
        zIndex: 1,
    },
    greenBorder: {
        border: '1px solid #6CFF00',
    },
    blockWrapper: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    userName: {
        minHeight: 12,
        maxWidth: 235,
        marginTop: 20,
        fontWeight: 700,
        fontSize: 18,
        color: '#FFFFFF',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    level: {
        position: 'absolute',
        top: 0,
        right: 8,
        width: 'fit-content',
        padding: '4px 12px',
        fontSize: 12,
        fontWeight: 500,
        color: '#323232',
        background: '#FFFFFF',
        borderRadius: '0px 0px 4px 4px',
    },
    gameName: {
        position: 'absolute',
        top: 0,
        right: 8,
        width: 'fit-content',
        padding: '4px 10px',
        fontSize: 10,
        fontWeight: 600,
        color: '#FFFFFF',
        borderRadius: '0px 0px 4px 4px',
    },
    achievement: {
        position: 'absolute',
        top: 8,
        right: 8,
        height: 31.5,
        width: 31.5,
    },
    dividerContainer: {
        width: '100%',
        marginTop: 8,
        marginBottom: 15,
        padding: '0 8px',
        borderRadius: 4,
        boxSizing: 'border-box',
    },
    divider: {
        width: '100%',
        height: '1px',
        background: '#292931',
    },
    description: {
        marginTop: 6,
        fontSize: 10,
        color: 'rgba(255, 255, 255, 0.80)',
    },
    creditsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 10,
        paddingRight: 8,
        boxSizing: 'border-box',
    },
    creditsWrapper: {
        display: 'flex',
        alignItems: 'center',
        height: 21,
        '@media(max-width: 395px)': {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
    },
    creditsText: {
        marginRight: 8,
        fontSize: 12,
        color: '#FFFFFF',
    },
    credits: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 16,
        fontWeight: 600,
        color: '#FFFFFF',
        '& img': {
            height: 16,
            width: 16,
            marginLeft: 4,
        },
        '& svg': {
            height: 16,
            width: 16,
            marginLeft: '3px',
        },
    },
    joinGameButton: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        padding: '7px 13px',
        fontSize: 10,
        fontWeight: 600,
        color: '#FFFFFF',
        background: 'rgba(12, 16, 74, 0.5)',
        borderRadius: '5px 0px',
        cursor: 'pointer',
    },
    tipsButton: {
        display: 'flex',
        alignItems: 'center',
        minHeight: 20,
        minWidth: 20,
        paddingLeft: 10,
        textAlign: 'end',
        fontSize: 10,
        fontWeight: 500,
        color: '#2AA1F7',
    },
    achievementsContainer: {
        display: 'flex',
        alignItems: 'center',
        columnGap: 12,
        paddingLeft: 8,
        paddingBottom: 17,
        boxSizing: 'border-box',
        overflow: 'hidden',
        overflowX: 'auto',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '& ::-webkit-scrollbar': {
            display: 'none',
        },
    },
    achievementWrapper: {
        position: 'relative',
        '& img': {
            height: 31.5,
            width: 31.5,
        }
    },
    achievementCounter: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 15,
        width: 15,
        borderRadius: '50%',
        fontSize: 6,
        fontWeight: 700,
        color: '#444446',
        background: '#FFFFFF',
    },
    listWrapper: {
        maxHeight: 710,
        overflowY: 'auto',
        marginTop: 5,
    },
    itemWrapper: {
        marginTop: 8,
    },
    globalListWrapper: {
        maxHeight: 460,
        overflowY: 'auto',
        marginTop: 6,
    },
    nestedWrapper: {
        display: 'flex',
        padding: '10px 0 13px 10px',
        marginTop: 3,
        background: 'linear-gradient(0deg, #FFF 0%, #1C1C23 0%, #000 15.76%, #5D5D65 100%)',
        boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.92) inset',
        borderRadius: 18,
    },
    nestedCenterBlock: {
        width: '100%',
        maxWidth: '193px',
        padding: '0 33px',
        '@media (max-width:412px)': {
            paddingRight: '6px',
        },
        '@media (max-width:376px)': {
            padding: '3px 6px',
        },
        '@media (max-width:340px)': {
            padding: '0 10px',
        },
    },
    nestedRank: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '14px',
        fontSize: '25px',
        fontWeight: 700,
        color: '#FFFFFF',
        '& span:first-child': {
            marginRight: '5px',
        },
        '@media (max-width:340px)': {
            flexDirection: 'column',
            fontSize: '16px',
        },
    },
    nestedCredits: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '10px 0',
        fontSize: '12px',
        color: '#FFFFFF',
        '@media (max-width:340px)': {
            fontSize: '8px',
        },
    },
    nestedBalance: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '16px',
        fontWeight: 700,
        '& >svg': {
            marginLeft: '8px',
        },
        '@media (max-width:340px)': {
            fontSize: '12px',
            '& >svg': {
                marginLeft: '3px',
            },
        },
    },
    jackpotAmount: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '10px',
        fontSize: '12px',
        fontWeight: 900,
        color: '#FFFFFF',
        '& >div': {
            fontSize: '12px',
            marginLeft: '3px',
        },
        '& >svg': {
            marginLeft: '3px',
        },
    },
    pointer: {
        cursor: 'pointer',
    },
    jackpotCount: {
        marginRight: '3px',
    },
    goldText: {
        color: '#F4B71B',
    },
    silverText: {
        color: '#C9C8C8',
    },
    bronzeText: {
        color: '#F87F2B',
    },
    goldBorder: {
        border: '1px solid #F4B71B',
    },
    silverBorder: {
        border: '1px solid #C9C8C8',
    },
    bronzeBorder: {
        border: '1px solid #F87F2B',
    },
    globalListBackground: {
        height: '47px',
        background: 'linear-gradient(0deg, #000 0%, rgba(36, 36, 36, 0.58) 50%, rgba(84, 84, 84, 0.00) 100%)',
        opacity: '0.189'
    },
    globalListNickname: {
        width: '110px',
        position: 'absolute',
        bottom: '50%',
        right: '50%',
        transform: 'translate(50%, 50%)',
        fontWeight: 700,
        color: '#FFFFFF',
        textAlign: 'center',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    globalListStars: {
        height: '40px',
        padding: '0 20px',
        '& div > div': {
            width: '10px',
            height: '10px',
        },
        '@media (max-width:376px)': {
            padding: 0,
        },
    },
    nicknameWrapper: {
        height: '88px',
        width: '123px',
        minWidth: '123px',
        borderRadius: '17px',
    },
    globalListRank: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '8px 0',
        fontSize: '25px',
        fontWeight: 700,
        color: '#FFFFFF',
        '& span:first-child': {
            marginRight: '5px',
        },
        '& div': {
            display: 'flex',
            alignItems: 'center',
        },
        '@media (max-width:340px)': {
            flexDirection: 'column',
            fontSize: '16px',
        },
    },
    zeroPadding: {
        padding: 0,
    },
    borderRadius14: {
        borderRadius: '14px',
    },
    smallLoader: {
        position: 'absolute',
        minHeight: '470px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        background: 'rgba(15,15,15,0.5)',
        zIndex: 10,
    },
    paddingTop20: {
        paddingTop: '20px',
    },
    paddingBottom20: {
        paddingBottom: '20px',
    },
    stickyBottom: {
        position: 'sticky',
        bottom: 0,
    },
    fullWidth: {
        width: '100%',
    }
}, { name: 'UserCard' });
