import React, { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { MODALS, openModal } from '~modals';
import { Button } from '~atoms/button';
import bgImage from '~icons/about-bg.png';
import { useVideoPlayerStyles } from './VideoPlayer.styles';
export var DesktopVideoPlayer = memo(function (_a) {
    var sources = _a.sources, autoplay = _a.autoplay;
    var dispatch = useDispatch();
    var videoPlayerClasses = useVideoPlayerStyles();
    var openVideoModal = function () {
        dispatch(openModal({
            modalName: MODALS.VIDEO_MODAL,
            modalProps: {
                sources: sources,
            },
        }));
    };
    useEffect(function () {
        if (autoplay) {
            openVideoModal();
        }
    });
    return (React.createElement("div", { className: videoPlayerClasses.bgSection },
        React.createElement("img", { src: bgImage, alt: "..." }),
        React.createElement("div", { className: videoPlayerClasses.controls },
            React.createElement(Button, { onClick: openVideoModal, type: "button", className: videoPlayerClasses.actionButton, "aria-label": "marketing video" }))));
});
