import { createSlice } from '@reduxjs/toolkit';
import { getJokersMetaInfo } from './thunks';
import { name } from './constants';
var initialState = {
    data: {
        jokersMetaInfo: [],
    },
    loading: 'idle',
    error: null,
};
export var achievements = createSlice({
    name: name,
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder
            .addCase(getJokersMetaInfo.pending, function (state) {
            state.loading = 'pending';
            state.error = null;
        })
            .addCase(getJokersMetaInfo.fulfilled, function (state, action) {
            state.loading = 'succeeded';
            state.data.jokersMetaInfo = action.payload;
            state.error = null;
        })
            .addCase(getJokersMetaInfo.rejected, function (state, action) {
            var _a;
            state.loading = 'failed';
            state.error =
                ((_a = action.error) === null || _a === void 0 ? void 0 : _a.message) || 'Failed to fetch jokers meta info';
        });
    },
});
