import makeStyles from '@material-ui/core/styles/makeStyles';
export var useStyles = makeStyles({
    wrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        marginTop: '30px',
        height: '100%',
    },
    subheader: {
        display: 'flex',
        marginTop: '50px !important',
        maxWidth: '100% !important',
        fontSize: '64px !important',
        fontWeight: 700,
        '& > div': {
            marginLeft: '5px',
            fontWeight: 700,
            fontSize: '64px',
        },
    },
    balanceCard: {
        width: 'auto',
        marginBottom: '47px',
    },
}, { name: 'DepositStatus' });
