import React from 'react';
export var CSIcon = function (_a) {
    var _b = _a.size, size = _b === void 0 ? 'sm' : _b, _c = _a.color, color = _c === void 0 ? 'white' : _c;
    return (React.createElement("svg", { width: size === 'xl'
            ? '59'
            : size === 'lg'
                ? '26'
                : size === 'md'
                    ? '19'
                    : '14', height: size === 'xl'
            ? '57'
            : size === 'lg'
                ? '24'
                : size === 'md'
                    ? '17'
                    : '13', viewBox: "0 0 19 17", fill: color, xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M12.9746 17.0007C12.1986 17.0007 11.4717 16.8147 10.7941 16.4428C10.488 16.28 10.2312 16.0882 10.0235 15.8674C9.87049 16.1347 9.67921 16.373 9.44968 16.5822C9.25295 16.7334 9.14365 16.8147 9.12179 16.8263H8.71191V13.5135H9.36771C9.53166 14.0831 9.76119 14.5945 10.0563 15.0479C10.723 16.0592 11.5865 16.5648 12.6467 16.5648C13.2697 16.5648 13.767 16.3672 14.1386 15.972C14.5102 15.5768 14.6961 15.0479 14.6961 14.3853C14.6961 14.0017 14.5868 13.6588 14.3682 13.3566C14.1605 13.0544 13.8982 12.787 13.5812 12.5545C13.2642 12.3221 12.8216 12.0315 12.2532 11.6827C11.5318 11.2527 10.9471 10.8632 10.499 10.5145C10.0508 10.1658 9.66828 9.72991 9.35132 9.20683C9.03435 8.67213 8.87586 8.04444 8.87586 7.32375C8.87586 6.75418 9.02342 6.24272 9.31853 5.78939C9.62456 5.32443 10.0782 4.95827 10.6793 4.69092C11.2804 4.41195 12.0182 4.27246 12.8926 4.27246C13.5266 4.27246 14.1605 4.46426 14.7944 4.84785C15.0349 4.98733 15.2753 5.17332 15.5158 5.4058C15.7016 5.10357 15.8929 4.86528 16.0896 4.69092C16.2864 4.53981 16.3957 4.45844 16.4175 4.44682H16.8274V7.75965H16.1716C16.0295 7.2017 15.8164 6.69606 15.5322 6.24272C15.2699 5.80101 14.942 5.43486 14.5485 5.14426C14.166 4.85366 13.7233 4.70836 13.2205 4.70836C12.7942 4.70836 12.4281 4.88853 12.1221 5.24887C11.816 5.60922 11.663 6.06836 11.663 6.62631C11.663 7.0564 11.7778 7.4458 12.0073 7.79452C12.2477 8.13162 12.5374 8.42803 12.8762 8.68375C13.226 8.93948 13.696 9.24752 14.2862 9.60786C14.9748 10.0263 15.5322 10.3983 15.9585 10.7238C16.3847 11.0492 16.7454 11.4561 17.0405 11.9443C17.3356 12.4209 17.4832 12.973 17.4832 13.6007C17.4832 14.2284 17.3138 14.8038 16.9749 15.3268C16.6361 15.8383 16.1279 16.2451 15.4502 16.5474C14.7726 16.8496 13.9474 17.0007 12.9746 17.0007Z", fill: "#503618" }),
        React.createElement("path", { d: "M13.5454 17.0007C12.7694 17.0007 12.0425 16.8147 11.3649 16.4428C11.0588 16.28 10.802 16.0882 10.5943 15.8674C10.4413 16.1347 10.25 16.373 10.0205 16.5822C9.82375 16.7334 9.71445 16.8147 9.69259 16.8263H9.28271V13.5135H9.93851C10.1025 14.0831 10.332 14.5945 10.6271 15.0479C11.2938 16.0592 12.1573 16.5648 13.2175 16.5648C13.8405 16.5648 14.3378 16.3672 14.7094 15.972C15.081 15.5768 15.2669 15.0479 15.2669 14.3853C15.2669 14.0017 15.1576 13.6588 14.939 13.3566C14.7313 13.0544 14.469 12.787 14.152 12.5545C13.835 12.3221 13.3924 12.0315 12.824 11.6827C12.1026 11.2527 11.5179 10.8632 11.0698 10.5145C10.6216 10.1658 10.2391 9.72991 9.92212 9.20683C9.60515 8.67213 9.44666 8.04444 9.44666 7.32375C9.44666 6.75418 9.59422 6.24272 9.88933 5.78939C10.1954 5.32443 10.649 4.95827 11.2501 4.69092C11.8513 4.41195 12.589 4.27246 13.4634 4.27246C14.0974 4.27246 14.7313 4.46426 15.3652 4.84785C15.6057 4.98733 15.8461 5.17332 16.0866 5.4058C16.2724 5.10357 16.4637 4.86528 16.6604 4.69092C16.8572 4.53981 16.9665 4.45844 16.9883 4.44682H17.3982V7.75965H16.7424C16.6003 7.2017 16.3872 6.69606 16.103 6.24272C15.8407 5.80101 15.5128 5.43486 15.1193 5.14426C14.7368 4.85366 14.2941 4.70836 13.7913 4.70836C13.365 4.70836 12.9989 4.88853 12.6929 5.24887C12.3868 5.60922 12.2338 6.06836 12.2338 6.62631C12.2338 7.0564 12.3486 7.4458 12.5781 7.79452C12.8186 8.13162 13.1082 8.42803 13.447 8.68375C13.7968 8.93948 14.2668 9.24752 14.857 9.60786C15.5456 10.0263 16.103 10.3983 16.5293 10.7238C16.9555 11.0492 17.3162 11.4561 17.6113 11.9443C17.9064 12.4209 18.054 12.973 18.054 13.6007C18.054 14.2284 17.8846 14.8038 17.5457 15.3268C17.2069 15.8383 16.6987 16.2451 16.021 16.5474C15.3434 16.8496 14.5182 17.0007 13.5454 17.0007Z", fill: color }),
        React.createElement("path", { d: "M6.59303 13.1095C4.52463 13.1095 2.90869 12.5438 1.74522 11.4123C0.581739 10.2808 0 8.67283 0 6.58846C0 5.21874 0.252087 4.04554 0.75626 3.06887C1.27336 2.08028 1.99084 1.33587 2.90869 0.83562C3.83947 0.323462 4.91246 0.0673828 6.12764 0.0673828C6.86451 0.0673828 7.60784 0.263909 8.35764 0.65696C8.64205 0.799888 8.92645 0.990458 9.21086 1.22867C9.43062 0.918994 9.65685 0.674826 9.88955 0.496166C10.1222 0.341328 10.2515 0.257953 10.2774 0.246043H10.7622V3.64058H9.98651C9.81845 3.06887 9.56636 2.55075 9.23025 2.08624C8.91999 1.63363 8.53216 1.25845 8.06677 0.960682C7.61431 0.662915 7.09074 0.514032 6.49608 0.514032C5.60408 0.514032 4.86075 0.990458 4.26608 1.94331C3.68434 2.89616 3.39347 4.44455 3.39347 6.58846C3.39347 8.76811 3.7102 10.3284 4.34365 11.2694C4.99002 12.1984 5.86909 12.6629 6.98086 12.6629C7.58845 12.6629 8.16373 12.5081 8.70668 12.1984C9.26257 11.8768 10.1559 10.8461 10.1559 10.8461C10.1559 10.8461 11.0043 10.4326 11.0043 9.849C11.0043 10.5279 10.4519 11.18 10.4519 11.18C10.4519 11.18 9.72523 12.1984 8.84242 12.5914C8.17019 12.9368 7.42039 13.1095 6.59303 13.1095Z", fill: "#503618" }),
        React.createElement("path", { d: "M6.87352 13.0745C4.97294 13.0745 3.48812 12.5102 2.41904 11.3818C1.34997 10.2533 0.81543 8.64968 0.81543 6.57092C0.81543 5.20488 1.04706 4.03484 1.51033 3.06079C1.98547 2.07487 2.64474 1.33245 3.48812 0.833553C4.34338 0.322773 5.3293 0.0673828 6.44589 0.0673828C7.12297 0.0673828 7.80599 0.26338 8.49495 0.655374C8.75628 0.797918 9.01761 0.987976 9.27894 1.22555C9.48088 0.916703 9.68875 0.673192 9.90257 0.495013C10.1164 0.340591 10.2352 0.25744 10.2589 0.245562H10.7044V3.63097H9.99166C9.83723 3.06079 9.6056 2.54407 9.29676 2.08081C9.01167 1.62942 8.65531 1.25524 8.22768 0.958279C7.81193 0.661313 7.33085 0.51283 6.78443 0.51283C5.96481 0.51283 5.28179 0.987975 4.73537 1.93826C4.20083 2.88855 3.93357 4.43277 3.93357 6.57092C3.93357 8.74471 4.22459 10.3008 4.80664 11.2392C5.40057 12.1658 6.20832 12.629 7.22988 12.629C7.78817 12.629 8.31677 12.4746 8.81567 12.1658C9.32645 11.845 10.1473 10.8171 10.1473 10.8171L11.0046 9.44144L11.4122 9.849L10.5971 11.0717C10.5971 11.0717 9.75158 12.1658 8.9404 12.5577C8.32271 12.9022 7.63375 13.0745 6.87352 13.0745Z", fill: color }),
        React.createElement("path", { d: "M9.51441 8.1305C9.02146 6.28236 10.3488 4.44724 12.2584 4.33686L13.3725 4.27246L11.361 6.28392L9.51441 8.1305Z", fill: color })));
};
