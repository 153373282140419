import React from 'react';
import classNames from 'classnames';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import { useIsDesktop } from '~hooks';
import { Button, TextInput } from '~atoms';
import validationMessages from '~utils/validationMessages';
import { getTestId } from '~utils/getTestId';
import { useLoginStyles } from '../Login.styles';
var LoginSchema = yup.object().shape({
    username: yup.string().required(validationMessages.required),
    password: yup.string().required(validationMessages.required),
});
export var LoginForm = function (_a) {
    var _b, _c;
    var onSubmit = _a.onSubmit, formError = _a.formError, onClose = _a.onClose;
    var loginClasses = useLoginStyles();
    var _d = useForm({
        validationSchema: LoginSchema,
        mode: 'onChange',
        submitFocusError: true,
    }), register = _d.register, handleSubmit = _d.handleSubmit, triggerValidation = _d.triggerValidation, errors = _d.errors;
    var onBlurChange = function (e) {
        return triggerValidation(e.target.name);
    };
    var t = useTranslation('login').t;
    var isDesktopResolution = useIsDesktop();
    return (React.createElement("form", { className: loginClasses.loginFormMain, onSubmit: handleSubmit(onSubmit), "data-testid": getTestId('FRM_LOGIN') },
        isDesktopResolution && (React.createElement("span", { className: loginClasses.title }, t('login'))),
        isDesktopResolution && (React.createElement("button", { type: "button", onClick: onClose, className: loginClasses.closeIcon },
            React.createElement("i", { className: classNames('fas fa-times') }))),
        React.createElement(TextInput, { name: "username", title: t('username'), type: "text", placeholder: t('enter_username'), error: !!errors.username, helperText: (_b = errors === null || errors === void 0 ? void 0 : errors.username) === null || _b === void 0 ? void 0 : _b.message, inputRef: register, showAsterisk: true, onBlur: onBlurChange, titleClassName: loginClasses.titleClassName, inputClassName: loginClasses.inputes }),
        React.createElement(TextInput, { type: "password", name: "password", title: t('Password'), placeholder: t('enter_password'), inputRef: register, error: !!errors.password, helperText: (_c = errors === null || errors === void 0 ? void 0 : errors.password) === null || _c === void 0 ? void 0 : _c.message, showAsterisk: true, onBlur: onBlurChange, titleClassName: loginClasses.titleClassName, inputClassName: loginClasses.inputes }),
        React.createElement(FormHelperText, { id: "component-error-text", error: !!formError, classes: { root: loginClasses.formError } }, formError),
        React.createElement(Grid, null,
            React.createElement(Button, { className: loginClasses.loginButton, color: "primary", type: "submit", variant: "contained", fullWidth: true }, t('login')))));
};
