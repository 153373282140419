import makeStyles from '@material-ui/core/styles/makeStyles';
var useStyles = makeStyles({
    button: {
        color: '#212121',
        fontSize: '16px',
    },
    whiteText: {
        color: '#FFF',
    },
}, { name: 'FastPlayButton' });
export { useStyles };
