var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { Button } from '~atoms/button';
import { getTestId } from '~utils/getTestId';
import { Currency } from '~atoms';
import { CSIcon } from '~icons/rankingList/CSIcon';
var useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        alignItems: 'center',
        boxSizing: 'border-box',
        padding: '64px 16px 32px 16px',
        alignSelf: 'stretch',
        flex: '1 1 100%',
        color: '#FFFFFF',
        fontWeight: 300,
        textAlign: 'center',
        '&.mobile': {
            padding: 0,
        },
    },
    header: {
        marginTop: '40px',
        fontSize: '24px',
        maxWidth: '314px',
        lineHeight: '32px',
    },
    subheader: {
        marginTop: '16px',
        fontSize: '14px',
        lineHeight: '21px',
        maxWidth: '314px',
        '&.mobile': {
            marginBottom: '40px',
        },
    },
    additionalButton: {
        marginTop: 16,
    },
}, { name: 'StatusScreen' });
export var StatusScreen = function (_a) {
    var header = _a.header, headerClassName = _a.headerClassName, subheader = _a.subheader, subheaderClassName = _a.subheaderClassName, logo = _a.logo, target = _a.target, buttonLabel = _a.buttonLabel, logoClasses = _a.logoClasses, onClick = _a.onClick, isMobile = _a.isMobile, additionalHandler = _a.additionalHandler, additionalButtonLabel = _a.additionalButtonLabel, formTestId = _a.formTestId, buttonTestId = _a.buttonTestId, currency = _a.currency, CSSymbol = _a.CSSymbol;
    var classes = useStyles();
    return (React.createElement("div", { className: classNames(classes.root, logoClasses, { mobile: isMobile }), "data-testid": getTestId(formTestId) },
        logo ? React.createElement("img", { src: logo, alt: "status-logo" }) : null,
        React.createElement("div", { className: classNames(headerClassName, classes.header) }, header),
        subheader && (React.createElement("div", { className: classNames(classes.subheader, subheaderClassName, {
                mobile: isMobile,
            }) },
            subheader,
            CSSymbol ? (React.createElement(CSIcon, { size: "xl" })) : (currency && React.createElement(Currency, { currency: currency })))),
        buttonLabel ? (React.createElement(Grid, { container: true, style: { marginTop: 'auto' } }, onClick ? (React.createElement(Button, { fullWidth: true, onClick: onClick, color: "primary", variant: "contained", "data-testid": getTestId(buttonTestId) }, buttonLabel)) : (React.createElement(Button, __assign({ fullWidth: true, component: Link, color: "primary" }, { to: "".concat(target) }, { variant: "contained" }), buttonLabel)))) : null,
        additionalHandler ? (React.createElement(Button, { fullWidth: true, className: classes.additionalButton, onClick: additionalHandler, color: "primary", variant: "outlined" }, additionalButtonLabel)) : null));
};
