import makeStyles from '@material-ui/core/styles/makeStyles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
var useStyles = makeStyles({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#FFFFFF',
        width: '168px',
        height: '36px',
        borderRadius: '4px',
        border: '1px solid #F2F2F2',
        boxSizing: 'border-box',
        boxShadow: '0px 0px 15px rgba(0, 193, 80, 0.05)',
        color: '#212121',
        textAlign: 'center',
        padding: '10px 4px',
        fontSize: '11px',
        fontWeight: 400,
        cursor: 'pointer',
        position: 'relative',
    },
    active: {
        backgroundColor: alpha('#2AA1F7', 0.2),
    },
    small: {
        width: '160px',
        borderColor: '#e6e6e6',
    },
}, { name: 'Item' });
export { useStyles };
