import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import { StatusScreen } from '~molecules';
import success from '~icons/statusIcons/success.svg';
import failed from '~icons/statusIcons/failed.svg';
import { FullscreenLoader } from '~atoms/loader';
import { confirmRegistrationEmail, errorSelector, loadingSelector, } from '~slices/account';
import { useAppDispatch } from '~store';
import { useIsMobileDevise, useStructuredSelector } from '~hooks';
import { RegistrationStep } from '~constants/steps/RegistrationStep';
import { getPathWithLanguageCode } from '~utils/languageInURL/getPathWithLanguageCode';
export var RegisterEmailConfirmation = function () {
    var t = useTranslation('registration').t;
    var tc = useTranslation('common').t;
    var te = useTranslation('errors').t;
    var dispatch = useAppDispatch();
    var _a = useStructuredSelector({
        loading: loadingSelector,
        responseError: errorSelector,
    }), loading = _a.loading, responseError = _a.responseError;
    var confirmationEmailCode = useParams().code;
    var isMobileDevice = useIsMobileDevise();
    var renderResponseError = function () {
        if (Array.isArray(responseError)) {
            return responseError.map(function (_a) {
                var code = _a.code;
                return te(code);
            }).join('. ');
        }
        return (responseError === null || responseError === void 0 ? void 0 : responseError.message) || te('unknown_error');
    };
    useEffect(function () {
        if (!isMobileDevice) {
            dispatch(push({
                pathname: getPathWithLanguageCode('/home'),
                state: {
                    openRegistrationModal: true,
                    registrationStep: RegistrationStep.EMAIL_CONFIRMED,
                    confirmationCode: confirmationEmailCode,
                },
            }));
        }
        if (!responseError && isMobileDevice) {
            dispatch(confirmRegistrationEmail({ code: confirmationEmailCode }));
        }
    }, []);
    if (!isMobileDevice) {
        return null;
    }
    if (loading === 'pending') {
        return React.createElement(FullscreenLoader, null);
    }
    if (loading === 'fulfilled') {
        return (React.createElement(StatusScreen, { header: t('status.welcome'), subheader: t('status.link_was_confirmed'), logo: success, target: "/", buttonLabel: tc('buttons.continue') }));
    }
    return (React.createElement(StatusScreen, { header: t('status.error'), subheader: renderResponseError(), logo: failed, target: "/register/success/email", buttonLabel: tc('buttons.resend_link') }));
};
