import makeStyles from '@material-ui/core/styles/makeStyles';
export var useStyles = makeStyles(function (theme) {
    var _a, _b, _c, _d, _e, _f, _g;
    return ({
        listItem: {
            position: 'relative',
            backgroundColor: '#FFFFFF',
            borderRadius: '4px',
            justifyContent: 'space-between',
            '&:not(:last-child)': {
                marginBottom: '8px',
            },
        },
        container: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '8px',
        },
        buttonBlock: (_a = {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                maxWidth: '104px',
                width: '100%'
            },
            _a[theme.breakpoints.up('xl')] = {
                width: 'auto',
                maxWidth: 'initial',
                flexDirection: 'row',
                alignItems: 'center',
            },
            _a),
        buttonBlock_central: {
            flex: 'auto',
            flexDirection: 'row-reverse',
            justifyContent: 'space-between',
            marginRight: 8,
        },
        balance: {
            border: '1px solid #ffffff66',
            borderRadius: '4px',
            margin: '8px 6px 6px',
            padding: '6px 8px',
            textAlign: 'center',
            display: 'flex',
            width: 'calc(100% - 12px)',
            boxSizing: 'border-box',
        },
        button: (_b = {
                backgroundColor: 'transparent',
                borderRadius: '4px',
                minWidth: '90px',
                width: '100%',
                padding: '8px 16px',
                color: '#212121',
                boxSizing: 'border-box',
                border: '1px solid rgba(0, 0, 0, 0.8)',
                fontWeight: 500,
                fontSize: '12px',
                lineHeight: '18px',
                height: '28px',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column'
            },
            _b[theme.breakpoints.up('xl')] = {
                padding: '11px 16px',
                width: 'auto',
                height: 'auto',
                minWidth: 128,
                '& + &': {
                    marginLeft: 8,
                },
            },
            _b),
        logoButton: (_c = {
                padding: '0',
                height: 'auto',
                width: '120px'
            },
            _c[theme.breakpoints.up('xl')] = {
                width: 341,
                marginRight: 16,
            },
            _c),
        bonusButton: {
            border: '1px solid #F2F2F2',
            boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.05)',
        },
        withdrawal: (_d = {
                color: '#8E8E8E',
                fontSize: '10px',
                padding: '2px 16px',
                lineHeight: '12px',
                marginTop: 0
            },
            _d[theme.breakpoints.up('xl')] = {
                padding: '8px 16px',
            },
            _d),
        withdrawalBalance: (_e = {
                fontSize: '8px',
                lineHeight: '9px',
                fontWeight: 500,
                color: '#212121',
                textAlign: 'center'
            },
            _e[theme.breakpoints.up('xl')] = {
                fontSize: '10px',
                lineHeight: '12px',
            },
            _e),
        buttonText: {
            width: '53px',
            textAlign: 'start',
        },
        welcomeBonus: {
            display: 'flex',
            alignItems: 'center',
        },
        welcomeButton: {
            padding: '1px 8px',
        },
        maraqueWrapper: {
            overflow: 'hidden',
            background: '#f2f2f2',
            borderRadius: '0px 0px 4px 4px',
        },
        register: (_f = {
                height: '64px',
                fontSize: '12px',
                lineHeight: '18px'
            },
            _f[theme.breakpoints.up('xl')] = {
                height: 'auto',
                minWidth: 264,
            },
            _f),
        payIcons: {
            boxSizing: 'border-box',
            minWidth: 128,
            alignSelf: 'stretch',
            padding: '0 16px',
            borderRadius: 4,
            backgroundColor: '#F2F2F2',
        },
        advertisement: {
            color: '#212121',
            opacity: 0.6,
            fontSize: '10px',
            padding: '8px 0',
            fontWeight: 500,
            animation: '$floatText 15s infinite linear',
            paddingLeft: '100%',
            display: 'inline-block',
            whiteSpace: 'nowrap',
        },
        '@keyframes floatText': {
            to: {
                transform: 'translateX(-100%)',
            },
        },
        teaserWithMargin: {
            marginBottom: 16,
        },
        listWrapper: (_g = {
                marginBottom: 16
            },
            _g[theme.breakpoints.up('xl')] = {
                padding: '24px 16px',
            },
            _g),
        backdrop: {
            '&.MuiBackdrop-root': {
                zIndex: 1,
                color: '#fff',
                position: 'absolute',
            },
        },
    });
}, { name: 'BpList' });
export var useBpListStyles = makeStyles({
    paper: {
        zIndex: 1,
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        background: '#151515',
        width: '100%',
        top: '50px',
    },
}, { name: 'BpList' });
