import * as React from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Accordion from '@material-ui/core/Accordion/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails/AccordionDetails';
import classnames from 'classnames';
import { WonLostIndicator } from '~atoms/WonLostIndicator/WonLostIndicator';
import { currencyConverter } from '~utils/converters';
import { useStyles, useStylesV2 } from './SystemOverview.styles';
var getCombinationStatus = function (otcc, evs) {
    var everyOddIsWon = otcc === null || otcc === void 0 ? void 0 : otcc.split('.').every(function (selectionId) {
        var _a, _b, _c, _d, _e;
        var event = evs.find(function (e) { var _a, _b; return (_b = (_a = e.sl) === null || _a === void 0 ? void 0 : _a.sel) === null || _b === void 0 ? void 0 : _b[selectionId]; });
        return ((_e = (_d = (_c = (_b = (_a = event === null || event === void 0 ? void 0 : event.sl) === null || _a === void 0 ? void 0 : _a.sel) === null || _b === void 0 ? void 0 : _b[selectionId]) === null || _c === void 0 ? void 0 : _c.oc) === null || _d === void 0 ? void 0 : _d.odd) === null || _e === void 0 ? void 0 : _e.st) === 'WON';
    });
    if (everyOddIsWon) {
        return 'WON';
    }
    var isLostCombination = otcc === null || otcc === void 0 ? void 0 : otcc.split('.').some(function (selectionId) {
        var _a, _b, _c, _d, _e;
        var event = evs.find(function (e) { var _a, _b; return (_b = (_a = e.sl) === null || _a === void 0 ? void 0 : _a.sel) === null || _b === void 0 ? void 0 : _b[selectionId]; });
        return ((_e = (_d = (_c = (_b = (_a = event === null || event === void 0 ? void 0 : event.sl) === null || _a === void 0 ? void 0 : _a.sel) === null || _b === void 0 ? void 0 : _b[selectionId]) === null || _c === void 0 ? void 0 : _c.oc) === null || _d === void 0 ? void 0 : _d.odd) === null || _e === void 0 ? void 0 : _e.st) === 'LOST';
    });
    return isLostCombination ? 'LOST' : undefined;
};
export var SystemOverview = function (_a) {
    var _b = _a.bts, bts = _b === void 0 ? [] : _b, _c = _a.evs, evs = _c === void 0 ? [] : _c, bpFee = _a.bpFee, bpFeeAmount = _a.bpFeeAmount, bw = _a.bw, purchaseDate = _a.purchaseDate, mtsTicketId = _a.mtsTicketId, onClickHandle = _a.onClickHandle, currency = _a.currency, recalcParentHeight = _a.recalcParentHeight, _d = _a.newClasses, newClasses = _d === void 0 ? false : _d;
    var classesV1 = useStyles();
    var classesV2 = useStylesV2();
    var classes = newClasses ? classesV2 : classesV1;
    var contentRef = React.useRef(null);
    var t = useTranslation('ticket').t;
    // @ts-expect-error
    var purDate = new Date(purchaseDate);
    var localePurDate = new Date(Date.UTC(purDate.getFullYear(), purDate.getMonth(), purDate.getDate(), purDate.getHours(), purDate.getMinutes(), purDate.getSeconds(), purDate.getMilliseconds()));
    var localeTime = new Date(localePurDate).toLocaleTimeString(i18next.language, {
        hour: '2-digit',
        minute: '2-digit',
    });
    var localeDate = new Date(localePurDate).toLocaleDateString(i18next.language, {
        weekday: 'short',
        day: '2-digit',
        month: 'short',
    });
    var convertedBpFeeAmount = currencyConverter(bpFeeAmount, currency);
    if ((bts === null || bts === void 0 ? void 0 : bts.length) === 0) {
        return null;
    }
    var index = 1;
    return (React.createElement(Accordion, { onClick: function (e) {
            var _a;
            e.stopPropagation();
            if (recalcParentHeight)
                recalcParentHeight(((_a = contentRef === null || contentRef === void 0 ? void 0 : contentRef.current) === null || _a === void 0 ? void 0 : _a.scrollHeight) || 0);
            if (onClickHandle)
                onClickHandle();
        }, elevation: 0, classes: {
            root: classes.root,
        } },
        React.createElement(AccordionSummary, { expandIcon: React.createElement("i", { className: "fas fa-chevron-down" }), classes: {
                root: classes.summary,
                expanded: classes.expanded,
                content: classes.content,
                expandIcon: classes.expandIcon,
            } },
            React.createElement("b", null, t('system_overview.details'))),
        React.createElement(AccordionDetails, { classes: { root: classes.details } },
            React.createElement("div", { ref: contentRef, className: classes.fullWidth },
                mtsTicketId ? (React.createElement(React.Fragment, null,
                    React.createElement(Grid, { style: { padding: '8px 0' } },
                        React.createElement(Grid, { style: { marginBottom: '4px' }, container: true, justify: "space-between" },
                            React.createElement("div", null,
                                t('system_overview.ticket_id'),
                                ": ",
                                mtsTicketId),
                            React.createElement("div", null,
                                t('system_overview.fee'),
                                " ",
                                bpFee,
                                "%")),
                        React.createElement(Grid, { container: true, justify: "space-between" },
                            React.createElement("div", null, "".concat(localeDate, " ").concat(localeTime)),
                            React.createElement("div", null, convertedBpFeeAmount))),
                    React.createElement(Divider, null))) : null,
                React.createElement("div", { className: classes.overviewTitle }, t('system_overview.system_overview')),
                React.createElement("div", { className: classes.overview },
                    React.createElement(TableContainer, { className: classes.borderlessTableContainer },
                        React.createElement(Table, { className: classes.table, size: "small" },
                            React.createElement(TableBody, null, bts.map(function (bet, betIndex) {
                                var _a;
                                return (_a = bet.otccl) === null || _a === void 0 ? void 0 : _a.map(function (combination, combinationIndex) { return (
                                // eslint-disable-next-line react/no-array-index-key
                                React.createElement(TableRow, { key: "".concat(betIndex, "_").concat(combinationIndex) },
                                    React.createElement(TableCell, { component: "th", className: "".concat(classes.borderlessTableCell, " ").concat(classes.firstTableCell) },
                                        // eslint-disable-next-line no-plusplus
                                        index++,
                                        '.',
                                        React.createElement("span", { className: classes.muted }, "".concat(combination.otcc),
                                            " ="), " ".concat(combination.odd),
                                        " x ",
                                        bw),
                                    React.createElement(TableCell, { align: "right", className: classnames(classes.borderlessTableCell, classes.noWrap, 
                                        // @ts-expect-error
                                        classes.currency) }, currencyConverter(combination.pa, currency)),
                                    mtsTicketId ? (React.createElement(TableCell, { align: "center", className: "".concat(classes.withSpacer, " ").concat(classes.borderlessTableCell) },
                                        React.createElement(WonLostIndicator, { status: getCombinationStatus(combination.otcc, evs) }))) : null)); });
                            })))))))));
};
