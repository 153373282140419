import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
var useLocationChange = function (_a) {
    var onClose = _a.onClose;
    var location = useLocation();
    useEffect(function () {
        if (location.pathname === '/home' && onClose) {
            onClose();
        }
    }, [location]);
};
export { useLocationChange };
