import { languagesList } from '~utils/languageInURL/constants';
export var getPathWithoutLanguageCode = function (path) {
    var possiblyLanguage = path.slice(1, 3);
    if (path[0] === '/' &&
        path[3] === '/' &&
        languagesList.includes(possiblyLanguage)) {
        return path.replace("/".concat(possiblyLanguage), '');
    }
    return path;
};
