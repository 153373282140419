import React from 'react';
import cn from 'classnames';
import { useTranslation } from "react-i18next";
import { OptionsDropDown, SelectionButton } from '~atoms';
import { useBreakpoint } from '~hooks';
import { CSIcon } from "~icons/rankingList/CSIcon";
import { currencyConverter } from "~utils/converters";
import { useStyles } from './MatchDayPicker.styles';
var MatchDayPicker = function (_a) {
    var _b;
    var _c = _a.palette, palette = _c === void 0 ? 'dark' : _c, _d = _a.options, options = _d === void 0 ? [] : _d, selectedId = _a.selectedId, onSelect = _a.onSelect, balance = _a.balance, _e = _a.season, season = _e === void 0 ? '' : _e;
    var classes = useStyles();
    var t = useTranslation('tip-game').t;
    var isSingleLineHeader = useBreakpoint(1600);
    return (React.createElement("div", { className: cn(classes.container, (_b = {},
            _b[classes.light] = palette === 'light',
            _b[classes.singleLineContainer] = isSingleLineHeader,
            _b)) },
        !!options.length ? (React.createElement(OptionsDropDown, { palette: palette, options: options, selected: selectedId, onChange: onSelect })) : (React.createElement(SelectionButton, { className: classes.placeholder, bordered: true, disabled: true, palette: palette })),
        balance ? (React.createElement(SelectionButton, { className: classes.balanceContainer, bordered: true, palette: palette }, balance === 'closed' ? (React.createElement("div", { className: classes.closed }, t('not_available'))) : (React.createElement(React.Fragment, null,
            React.createElement("div", { className: classes.balanceTitle },
                t('won_balance'),
                ":"),
            React.createElement("div", { className: classes.balance },
                React.createElement("span", null, balance.balance ? currencyConverter(balance.balance) : 0),
                React.createElement(CSIcon, { size: "sm" })))))) : (React.createElement(SelectionButton, { className: classes.nonClickable, bordered: true, palette: palette }, season))));
};
export { MatchDayPicker };
