import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var AssieRulesIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, viewBox: "0 0 28 28" },
        React.createElement("svg", { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("g", { clipPath: "url(#clip0)" },
                React.createElement("path", { d: "M10.0332 10.0332C3.25662 16.8104 -0.35385 24.1882 1.72861 26.2713L26.2713 1.72861C24.1882 -0.353857 16.8104 3.25662 10.0332 10.0332Z", fill: "#E54530" }),
                React.createElement("path", { d: "M17.9667 17.9667C24.7433 11.1895 28.3537 3.81168 26.2713 1.72859L1.72858 26.2713C3.81166 28.3538 11.1895 24.7433 17.9667 17.9667Z", fill: "#CB2925" }),
                React.createElement("path", { d: "M10.0335 10.0329C16.8108 3.25634 24.1886 -0.354135 26.2716 1.72833C22.6458 -1.89753 13.8717 0.316621 7.09447 7.09387C0.317281 13.8711 -1.89687 22.6452 1.72893 26.271C-0.353531 24.188 3.25694 16.8101 10.0335 10.0329Z", fill: "#E54530" }),
                React.createElement("path", { d: "M26.2713 1.72859C28.3537 3.81167 24.7433 11.1895 17.9667 17.9667C11.1895 24.7433 3.81166 28.3537 1.72858 26.2713C5.355 29.8971 14.1286 27.683 20.9057 20.9057C27.6829 14.1285 29.8971 5.35444 26.2713 1.72859Z", fill: "#CB2925" }),
                React.createElement("path", { d: "M17.0406 10.9607L19.1844 13.1045L20.7841 11.5049L18.6403 9.36103L17.0406 10.9607Z", fill: "#FFF5CC" }),
                React.createElement("path", { d: "M14.8955 8.81582L16.24 10.1603L14.0983 12.3023L12.7539 10.958L11.1543 12.5576L12.4987 13.902L10.1604 16.24L8.81586 14.8955L7.21619 16.4951L9.36086 18.6398C9.36115 18.6401 10.9608 17.0404 10.9608 17.0404L11.7603 17.8399L14.0986 15.5019L13.2987 14.702L14.8983 13.1023L15.6983 13.9022L17.84 11.7602L17.0405 10.9608L18.6402 9.36111C18.6399 9.36083 16.4952 7.21615 16.4952 7.21615L14.8955 8.81582Z", fill: "white" }),
                React.createElement("path", { d: "M13.2985 14.7018L15.4424 16.8456L17.042 15.246L15.6981 13.902L14.8982 13.1021L13.2985 14.7018Z", fill: "#FFF5CC" }),
                React.createElement("path", { d: "M9.36082 18.6403L11.5046 20.7841L13.1043 19.1844L10.9605 17.0406L9.36082 18.6403Z", fill: "#FFF5CC" })),
            React.createElement("defs", null,
                React.createElement("clipPath", { id: "clip0" },
                    React.createElement("rect", { width: "28", height: "28", fill: "white" }))))));
};
