var _a;
import { createBaseSelectors } from '~store/utils';
import { name } from './constants';
export var selectFlagsData = (_a = createBaseSelectors(name), _a.selectFlagsData), selectFlagsState = _a.selectFlagsState, selectFlagsError = _a.selectFlagsError, selectFlagsLoadingStatus = _a.selectFlagsLoadingStatus;
export var flagsTypesSelector = function (state) { return state[name]; };
export var selectFlagIndex = function (state, flagId) {
    var _a, _b;
    return (_b = (_a = flagsTypesSelector(state)) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.findIndex(function (_a) {
        var id = _a.id;
        return id === flagId;
    });
};
export var selectedFlagSelector = function (state) {
    return flagsTypesSelector(state).meta.selectedFlag;
};
export var selectedTournamentSelector = function (state) {
    return flagsTypesSelector(state).meta.selectedTournament;
};
