var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createAsyncThunk } from '@reduxjs/toolkit';
import i18n from 'i18next';
import { shortcutCountSelector } from '~slices/shortcut';
import { request } from '~utils/request';
import { MODALS, openModal } from '~modals';
import { name } from './constants';
export var getShortcutBetslip = createAsyncThunk("".concat(name, "/getShortcutBetslip"), function () { return __awaiter(void 0, void 0, void 0, function () {
    var data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, request({
                    method: 'get',
                    url: 'sport-events/v1/betslip/short',
                    withCredentials: true,
                })];
            case 1:
                data = (_a.sent()).data;
                return [2 /*return*/, data];
        }
    });
}); });
export var selectOddWithValidation = createAsyncThunk("".concat(name, "/selectOddWithValidation"), function (data, _a) {
    var getState = _a.getState, dispatch = _a.dispatch;
    return __awaiter(void 0, void 0, void 0, function () {
        var shortcutCount;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    shortcutCount = shortcutCountSelector(getState());
                    if (shortcutCount >= 30) {
                        dispatch(openModal({
                            modalName: MODALS.ADDING_BET_ERROR_MODAL,
                            modalProps: {
                                closeButtonLabel: i18n.t('common:buttons.try_again'),
                                cancelVariant: 'contained',
                                fullWidth: true,
                                hugeButton: true,
                                cancelColor: 'primary',
                                columnDialog: false,
                            },
                        }));
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, dispatch(selectOdd(data))];
                case 1:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    });
});
export var selectOdd = createAsyncThunk("".concat(name, "/selectOdd"), function (pickDto) { return __awaiter(void 0, void 0, void 0, function () {
    var eventsType, rest, data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                eventsType = pickDto.eventsType, rest = __rest(pickDto, ["eventsType"]);
                return [4 /*yield*/, request({
                        method: 'post',
                        data: rest,
                        url: 'sport-events/v1/betslip/pick',
                        withCredentials: true,
                    })];
            case 1:
                data = (_a.sent()).data;
                return [2 /*return*/, data];
        }
    });
}); });
export var selectOutrightOdd = createAsyncThunk("".concat(name, "/selectOutrightOdd"), function (pickDto) { return __awaiter(void 0, void 0, void 0, function () {
    var eventsType, rest, data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                eventsType = pickDto.eventsType, rest = __rest(pickDto, ["eventsType"]);
                return [4 /*yield*/, request({
                        method: 'post',
                        data: rest,
                        url: 'sport-events/v1/betslip/pick/outright',
                        withCredentials: true,
                    })];
            case 1:
                data = (_a.sent()).data;
                return [2 /*return*/, data];
        }
    });
}); });
export var deleteOdd = createAsyncThunk("".concat(name, "/deleteOdd"), function (pickDto) { return __awaiter(void 0, void 0, void 0, function () {
    var eventsType, rest, data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                eventsType = pickDto.eventsType, rest = __rest(pickDto, ["eventsType"]);
                return [4 /*yield*/, request({
                        method: 'delete',
                        data: rest,
                        url: 'sport-events/v1/betslip/pick',
                        withCredentials: true,
                    })];
            case 1:
                data = (_a.sent()).data;
                return [2 /*return*/, data];
        }
    });
}); });
export var deleteOutrightOdd = createAsyncThunk("".concat(name, "/deleteOutrightOdd"), function (pickDto) { return __awaiter(void 0, void 0, void 0, function () {
    var eventsType, rest, data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                eventsType = pickDto.eventsType, rest = __rest(pickDto, ["eventsType"]);
                return [4 /*yield*/, request({
                        method: 'delete',
                        data: rest,
                        url: 'sport-events/v1/betslip/pick/outright',
                        withCredentials: true,
                    })];
            case 1:
                data = (_a.sent()).data;
                return [2 /*return*/, data];
        }
    });
}); });
