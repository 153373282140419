import makeStyles from '@material-ui/core/styles/makeStyles';
export var useEmptyBetslipStyles = makeStyles({
    container: {
        color: '#8E8E8E',
        display: 'grid',
        fontSize: '42px',
        fontWeight: 'bold',
        height: '470px',
        background: '#151515',
        borderRadius: '4px',
    },
    smallContainer: {
        height: '320px',
    },
    emptyBet: {
        height: '293px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& > i': {
            fontSize: '48px',
            lineHeight: '48px',
        },
    },
    label: {
        fontSize: '18px',
        marginTop: '32px',
    },
    emptyBetSmall: {
        height: '145px',
    },
    emptyWagerBlock: {
        background: '#34343E',
        borderRadius: '0px 0px 4px 4px',
        padding: '21px 16px 16px 16px',
    },
    currencyRow: {
        display: 'flex',
        background: '#151515',
        padding: '22px 16px 22px 24px',
        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.05)',
        borderRadius: '4px',
        height: '72px',
        boxSizing: 'border-box',
        alignItems: 'center',
    },
    cardIcon: {
        fontSize: '28px',
        color: '#8E8E8E',
        paddingRight: '24px',
        borderRight: '1px solid rgba(242, 242, 242, 0.2)',
    },
    input: {
        display: 'flex',
        flex: 1,
        fontSize: '14px',
        lineHeight: '16px',
        paddingLeft: '24px',
        background: 'none',
        border: 'none',
        color: 'white',
        '&:focus': {
            outline: 'none',
        },
        '&:placeholder': {
            color: '#8E8E8E',
        },
    },
    button: {
        background: 'rgba(42,161,247, 0.6) !important',
        marginTop: '24px',
    },
    currencyIcon: {
        fontSize: '12px',
        color: 'white',
    },
}, { name: 'Betslip' });
