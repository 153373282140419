import React from 'react';
import Button from '@material-ui/core/Button/Button';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useIsDesktop } from '~hooks';
import { AlertDialogSlide, StatusScreen } from '~molecules';
import reload from '~icons/statusIcons/reload.svg';
export var useModalStyles = makeStyles(function (theme) { return ({
    dialog: {
        width: '408px',
    },
    closeIcon: {
        fontSize: '20px',
        background: '#fff',
        color: 'rgba(142, 142, 142, 0.2)',
        position: 'absolute',
        top: '4px',
        right: '6px',
        '& i': {
            fontWeight: 300,
        },
    },
    subheader: {
        marginBottom: '40px',
        fontSize: '16px',
        color: '#212121',
    },
    header: { color: '#212121' },
    logo: { paddingTop: '40px' },
}); }, { name: 'ResendModal' });
export var ResendModal = function (_a) {
    var t = _a.t, handleCloseConfirmEmailModal = _a.handleCloseConfirmEmailModal, reSendMail = _a.reSendMail;
    var isDesktop = useIsDesktop();
    var classes = useModalStyles();
    return isDesktop ? (React.createElement(Dialog, { open: true, onClose: handleCloseConfirmEmailModal, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description" },
        React.createElement(Button, { onClick: handleCloseConfirmEmailModal, className: classes.closeIcon },
            React.createElement("i", { className: "fas fa-times" })),
        React.createElement(DialogContent, { className: classes.dialog },
            React.createElement(StatusScreen, { header: t('please_check_email'), headerClassName: classes.header, logoClasses: classes.logo, subheaderClassName: classes.subheader, subheader: t('activation_link'), onClick: handleCloseConfirmEmailModal, logo: reload, buttonLabel: t('ok'), additionalHandler: reSendMail, additionalButtonLabel: t('resend_email') })))) : (React.createElement(AlertDialogSlide, { handleClose: handleCloseConfirmEmailModal, additionalHandler: reSendMail, open: true, title: t('please_check_email'), content: t('activation_link'), firstButtonText: t('resend_email').toUpperCase(), secondButtonText: t('ok').toUpperCase(), needConfirm: true }));
};
