var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from '@reduxjs/toolkit';
import { getDailyRanking, getGlobalRankingDetails, getGlobalRankingMaster, getRankingListAsync, getWeeklyRanking } from './thunks';
import { name } from './constants';
var initialState = {
    data: {
        //@ts-ignore
        daily: {},
        //@ts-ignore
        weekly: {},
        //@ts-ignore
        group: {},
        //@ts-ignore
        globalMaster: {},
        //@ts-ignore
        globalDetails: {}
    },
    loading: 'pending',
    error: null,
};
export var rankingList = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        resetRankingLists: function (state) {
            return __assign(__assign({}, state), { data: initialState.data });
        }
    },
    extraReducers: function (builder) {
        builder
            .addCase(getDailyRanking.pending, function (state) {
            state.loading = 'pending';
            state.error = null;
        })
            .addCase(getDailyRanking.fulfilled, function (state, action) {
            state.loading = 'fulfilled';
            state.data.daily = action.payload;
        })
            .addCase(getDailyRanking.rejected, function (state, action) {
            state.loading = 'rejected';
            state.error = action.error;
        })
            .addCase(getWeeklyRanking.pending, function (state) {
            state.loading = 'pending';
            state.error = null;
        })
            .addCase(getWeeklyRanking.fulfilled, function (state, action) {
            state.loading = 'fulfilled';
            state.data.weekly = action.payload;
        })
            .addCase(getWeeklyRanking.rejected, function (state, action) {
            state.loading = 'rejected';
            state.error = action.error;
        })
            .addCase(getRankingListAsync.pending, function (state) {
            state.loading = 'pending';
            state.error = null;
        })
            .addCase(getRankingListAsync.fulfilled, function (state, action) {
            state.loading = 'fulfilled';
            state.data.group = action.payload;
        })
            .addCase(getRankingListAsync.rejected, function (state, action) {
            state.loading = 'rejected';
            state.error = action.error;
        })
            .addCase(getGlobalRankingMaster.pending, function (state) {
            state.loading = 'pending';
            state.error = null;
        })
            .addCase(getGlobalRankingMaster.fulfilled, function (state, action) {
            state.loading = 'fulfilled';
            state.data.globalMaster = action.payload;
        })
            .addCase(getGlobalRankingMaster.rejected, function (state, action) {
            state.loading = 'rejected';
            state.error = action.error;
        })
            .addCase(getGlobalRankingDetails.fulfilled, function (state, action) {
            state.loading = 'fulfilled';
            state.data.globalDetails = action.payload;
        })
            .addCase(getGlobalRankingDetails.rejected, function (state, action) {
            state.loading = 'rejected';
            state.error = action.error;
        });
    },
});
export var resetRankingLists = rankingList.actions.resetRankingLists;
