import React, { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { closeModal } from '../modalSlice';
var useStyles = makeStyles({
    modalRoot: {
        position: 'absolute',
        width: 720,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        '&:focus': {
            outline: 'none',
        },
    },
    modalRootHolder: {
        borderRadius: '5px',
        overflow: 'hidden',
        '& video': {
            display: 'block',
            width: '100%',
            height: 'auto',
        },
        '&:focus': {
            outline: 'none',
        },
    },
    modalRootX: {
        position: 'absolute',
        top: '-33px',
        right: '-27px',
        cursor: 'pointer',
        outline: 'none',
        color: '#fff',
        fontSize: 20,
    },
}, { name: 'VideoModal' });
export var VideoModal = React.memo(function VideoModal(_a) {
    var sources = _a.modalProps.sources, modalName = _a.modalName;
    var t = useTranslation('errors').t;
    var dispatch = useDispatch();
    var videoRef = useRef(null);
    var classes = useStyles();
    var handleClose = useCallback(function () {
        dispatch(closeModal({ key: modalName }));
    }, []);
    return (React.createElement(Modal, { open: true, onClose: handleClose, "aria-labelledby": "transition-modal-title", "aria-describedby": "transition-modal-description" },
        React.createElement(Fade, { in: true },
            React.createElement("div", { className: classes.modalRoot },
                React.createElement("div", { onClick: handleClose, role: "button", tabIndex: 0, className: classes.modalRootX },
                    React.createElement("i", { className: "fas fa-times" })),
                React.createElement("div", { className: classes.modalRootHolder },
                    React.createElement("video", { ref: videoRef, className: "video", muted: true, controls: true, autoPlay: true, disablePictureInPicture: true, controlsList: "nodownload" },
                        sources.map(function (source) { return (React.createElement("source", { key: source.url, src: source.url, type: source.type })); }),
                        React.createElement("track", { default: true, kind: "captions" }),
                        t('no_video_support')))))));
});
