import React from 'react';
import { useDispatch } from 'react-redux';
import { MODALS, closeModal } from '~modals';
import Login from './Login';
export var LoginModal = function (_a) {
    var modalProps = _a.modalProps;
    var dispatch = useDispatch();
    var close = function () {
        dispatch(closeModal({ key: MODALS.LOGIN_MODAL }));
    };
    return React.createElement(Login, { isOpen: true, onClose: close, modalProps: modalProps });
};
