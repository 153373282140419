import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var SnowboardIcon = function () {
    return (React.createElement(SvgIcon, { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M7.4375 19.25L8.75 21.4375L17.5 14.4375L14.875 13.125L7.4375 19.25Z", fill: "#3E6095" }),
        React.createElement("path", { d: "M14.4375 13.125L12.7758 12.0172C12.1477 11.5984 11.4097 11.375 10.6548 11.375C9.71016 11.375 8.79898 11.7247 8.09687 12.3566L4.8125 15.3125L6.125 17.5L10.5 13.5625L12.874 15.343C13.6049 15.8912 14.4939 16.1875 15.4075 16.1875H15.6274C16.545 16.1875 17.4377 15.8886 18.1702 15.336C18.9028 14.7835 19.4354 14.0073 19.6875 13.125H14.4375Z", fill: "#7696C6" }),
        React.createElement("path", { d: "M26.6875 13.125H24.0625L23.6589 11.375L22.75 7.4375H20.5625V9.625L20.125 14.4375L17.0313 13.7238L16.1891 13.5291L14.4375 13.125L14.5808 12.2648L14.875 10.5L14.7498 9.625L14.6251 8.75L14.4375 7.4375V4.8125H22.6155C23.2708 4.81262 23.9085 5.02523 24.4327 5.41844C24.4595 5.43867 24.4858 5.45891 24.5115 5.47969C25.0756 5.93222 25.459 6.57187 25.5921 7.28273L26.3594 11.375L26.6875 13.125Z", fill: "#F35244" }),
        React.createElement("path", { d: "M14.5808 12.2646C16.7025 11.4357 18.7121 10.3442 20.5625 9.01562V9.62484L20.125 14.4373L17.0313 13.7237L16.1891 13.529L14.4375 13.1248L14.5808 12.2646Z", fill: "#E64C3F" }),
        React.createElement("path", { d: "M26.6876 13.1248H24.0626L23.659 11.3748L22.7501 7.4373H22.5347C23.2298 6.82223 23.8898 6.1686 24.5116 5.47949C25.0758 5.93202 25.4591 6.57168 25.5922 7.28254L26.3595 11.3748L26.6876 13.1248Z", fill: "#E64C3F" }),
        React.createElement("path", { d: "M14.4377 4.8125V7.4375H9.18767L6.36848 8.27695L4.63379 8.75L3.75879 6.5625L8.75017 4.8125H14.4377Z", fill: "#F35244" }),
        React.createElement("path", { d: "M1.72167 15.0739L8.48838 25.6C8.7028 25.9335 8.99755 26.2079 9.3456 26.3979C9.69364 26.5879 10.0838 26.6875 10.4804 26.6875H10.6743C11.0295 26.6875 11.3801 26.6076 11.7001 26.4538C12.0202 26.2999 12.3016 26.076 12.5234 25.7987C12.8278 25.4184 13.0074 24.9534 13.0378 24.4672C13.0681 23.981 12.9479 23.4973 12.6933 23.082L6.10493 12.3324C5.84931 11.9154 5.48025 11.5797 5.04089 11.3647C4.60154 11.1497 4.11006 11.0643 3.62392 11.1183L3.60423 11.1205C2.97414 11.1905 2.39199 11.4904 1.96916 11.9628C1.54634 12.4351 1.31254 13.0468 1.3125 13.6808C1.31248 14.1748 1.45451 14.6584 1.72167 15.0739Z", fill: "#F35244" }),
        React.createElement("path", { d: "M3.75841 6.5625L1.60214 7.64072C1.5406 7.67148 1.48572 7.71407 1.44064 7.76604C1.39556 7.81802 1.36115 7.87836 1.33939 7.94363C1.31763 8.0089 1.30894 8.07782 1.31382 8.14645C1.31869 8.21508 1.33704 8.28208 1.3678 8.34362C1.42425 8.45639 1.51948 8.54502 1.63601 8.59324C1.75254 8.64146 1.88255 8.64603 2.00218 8.60611L2.88341 8.3125L2.73576 8.46015C2.65142 8.54455 2.59575 8.65333 2.57665 8.7711C2.55754 8.88887 2.57595 9.00967 2.62928 9.1164C2.69676 9.25121 2.815 9.35372 2.95802 9.40139C3.10104 9.44907 3.25714 9.43801 3.39201 9.37065L4.63341 8.75L3.75841 6.5625Z", fill: "#3E6095" }),
        React.createElement("path", { d: "M6.36848 8.27695L4.63379 8.75L3.75879 6.5625L5.39941 6.01562L6.36848 8.27695Z", fill: "#7696C6" }),
        React.createElement("path", { d: "M26.6873 13.125H24.0623L23.6587 11.375H26.3592L26.6873 13.125Z", fill: "#7696C6" }),
        React.createElement("path", { d: "M24.0625 13.125L23.708 14.8969C23.6842 15.0162 23.7002 15.14 23.7537 15.2493C23.8071 15.3586 23.8951 15.4473 24.0039 15.5017C24.1355 15.5675 24.2878 15.5783 24.4273 15.5318C24.5669 15.4853 24.6822 15.3853 24.748 15.2537L24.9375 14.875V15.3125C24.9375 15.5446 25.0297 15.7671 25.1937 15.9312C25.3578 16.0953 25.5804 16.1875 25.8125 16.1875C26.0445 16.1875 26.2671 16.0953 26.4312 15.9312C26.5953 15.7671 26.6875 15.5446 26.6875 15.3125V13.125H24.0625Z", fill: "#3E6095" }),
        React.createElement("path", { d: "M17.4998 8.75V10.5C17.4997 10.5207 17.4983 10.5413 17.4955 10.5618L17.058 13.6243C17.0534 13.6585 17.0444 13.6919 17.0312 13.7238L16.189 13.5291C16.1895 13.5198 16.1906 13.51 16.1917 13.5007L16.6248 10.4688V8.75C16.6248 8.63397 16.6709 8.52269 16.753 8.44064C16.835 8.35859 16.9463 8.3125 17.0623 8.3125C17.1784 8.3125 17.2896 8.35859 17.3717 8.44064C17.4537 8.52269 17.4998 8.63397 17.4998 8.75Z", fill: "#D63F30" }),
        React.createElement("path", { d: "M16.1874 8.75V9.625H14.7497L14.625 8.75H16.1874Z", fill: "#D63F30" }),
        React.createElement("path", { d: "M18.375 8.75H20.5625V9.625H18.375V8.75Z", fill: "#D63F30" }),
        React.createElement("path", { d: "M3.9375 14.875L5.25 13.5625L6.125 17.0625L7 16.1875L7.875 19.6875L9.1875 19.25L9.625 24.9375L8.3125 21L7.4375 21.4375L5.6875 17.9375L5.25 18.375L3.9375 14.875Z", fill: "#FFB531" }),
        React.createElement("path", { d: "M17.0625 8.75L14.3664 6.82423C14.2531 6.74328 14.1607 6.63644 14.097 6.51259C14.0332 6.38875 14 6.25148 14 6.1122V3.9375C14 3.70544 14.0922 3.48288 14.2563 3.31878C14.4204 3.15469 14.6429 3.0625 14.875 3.0625H18.8125C19.0446 3.0625 19.2671 3.15469 19.4312 3.31878C19.5953 3.48288 19.6875 3.70544 19.6875 3.9375V5.76259C19.6875 5.99463 19.5953 6.21718 19.4312 6.38127L17.0625 8.75Z", fill: "#FFB531" }),
        React.createElement("path", { d: "M15.3125 7.875H16.625C16.9731 7.875 17.3069 7.73672 17.5531 7.49058C17.7992 7.24444 17.9375 6.9106 17.9375 6.5625V3.9375H14V6.5625C14 6.9106 14.1383 7.24444 14.3844 7.49058C14.6306 7.73672 14.9644 7.875 15.3125 7.875Z", fill: "#FEC9A3" }),
        React.createElement("path", { d: "M17.9375 4.59375V5.46875C17.9375 5.6428 17.8684 5.80972 17.7453 5.93279C17.6222 6.05586 17.4553 6.125 17.2812 6.125H15.75L15.3125 5.6875L14.875 6.125H14.2188C14.1423 6.12519 14.0664 6.11186 13.9945 6.08562C13.8679 6.03964 13.7584 5.95575 13.6811 5.84538C13.6038 5.73501 13.5624 5.6035 13.5625 5.46875V4.59375C13.5625 4.4197 13.6316 4.25278 13.7547 4.12971C13.8778 4.00664 14.0447 3.9375 14.2188 3.9375H17.2812C17.4553 3.9375 17.6222 4.00664 17.7453 4.12971C17.8684 4.25278 17.9375 4.4197 17.9375 4.59375Z", fill: "#348FD9" }),
        React.createElement("path", { d: "M17.9376 4.59375V5.46875C17.9376 5.6428 17.8685 5.80972 17.7454 5.93279C17.6223 6.05586 17.4554 6.125 17.2813 6.125H15.7501L15.3126 5.6875L14.8751 6.125H14.2188C14.1424 6.12519 14.0665 6.11186 13.9946 6.08562C15.0672 5.44718 16.0904 4.72912 17.0555 3.9375H17.2813C17.4554 3.9375 17.6223 4.00664 17.7454 4.12971C17.8685 4.25278 17.9376 4.4197 17.9376 4.59375Z", fill: "#3086CD" }),
        React.createElement("path", { d: "M17.9375 3.49982V3.93732H13.5625V3.49982C13.5623 3.06712 13.6905 2.6441 13.9308 2.28428C14.1712 1.92447 14.5128 1.64403 14.9126 1.47846C15.3124 1.31289 15.7523 1.26962 16.1766 1.35414C16.601 1.43866 16.9907 1.64716 17.2966 1.95326C17.3288 1.98552 17.36 2.01833 17.3895 2.05279C17.7433 2.45172 17.9383 2.96663 17.9375 3.49982Z", fill: "#7696C6" }),
        React.createElement("path", { d: "M15.2662 2.42912L14.4831 2.82068L14.103 2.06053C14.3003 1.83453 14.5419 1.65154 14.8129 1.52295L15.2662 2.42912Z", fill: "#3E6095" }),
        React.createElement("path", { d: "M17.3897 2.05291L17.0189 2.81635L16.2314 2.43354L16.6761 1.51807C16.9067 1.62595 17.1168 1.77327 17.2968 1.95338C17.329 1.98565 17.3602 2.01846 17.3897 2.05291Z", fill: "#3E6095" })));
};
