import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import { unwrapResult } from '@reduxjs/toolkit';
import Modal from '@material-ui/core/Modal';
import ReactGA from 'react-ga4';
import ReactPixel from "react-facebook-pixel";
import { authIsPendingSelector, errorSelector, login as loginAsyncAction, resetErrors, setUserPreData, } from '~slices/auth';
import { checkTermsHaveUpdate, termsHaveUpdatesSelector } from '~slices/terms';
import { useAppDispatch } from '~store/configureStore';
import { useGAMethod, useIsDesktop, useIsMobileDevise, useStructuredSelector, } from '~hooks';
import { Button, FullscreenLoader } from '~atoms';
import { ER2001, ER2003 } from '~utils/errorcodes';
import { MODALS, openModal } from '~modals';
import { getTestId } from '~utils/getTestId';
import { getPathWithLanguageCode } from '~utils/languageInURL/getPathWithLanguageCode';
import { BanModal, LoginForm, ResendModal } from '~view/pages';
import { useLoginStyles } from './Login.styles';
var Login = function (_a) {
    var _b;
    var _c = _a.withRedirectToHome, withRedirectToHome = _c === void 0 ? true : _c, isOpen = _a.isOpen, onClose = _a.onClose, modalProps = _a.modalProps;
    var t = useTranslation('login').t;
    var te = useTranslation('errors').t;
    var dispatch = useAppDispatch();
    var _d = useStructuredSelector({
        isLoading: authIsPendingSelector,
        errors: errorSelector,
        termsHaveUpdate: termsHaveUpdatesSelector,
    }), isLoading = _d.isLoading, errorsData = _d.errors, termsHaveUpdate = _d.termsHaveUpdate;
    var errors = errorsData === null || errorsData === void 0 ? void 0 : errorsData.errors;
    var loginClasses = useLoginStyles();
    var isDesktop = useIsDesktop();
    var _e = useState(false), banModal = _e[0], setBanModal = _e[1];
    var _f = useState(false), confirmEmail = _f[0], setConfirmEmail = _f[1];
    var isOpenModal = (modalProps && modalProps.isOpen) || isOpen;
    var onCloseModal = (modalProps && modalProps.onClose) || onClose;
    var isMobileDevice = useIsMobileDevise();
    var methodName = useGAMethod();
    var handleCloseBanModal = function () {
        dispatch(resetErrors());
        onCloseModal();
        setBanModal(false);
    };
    var handleCloseConfirmEmailModal = function () {
        dispatch(resetErrors());
        onCloseModal();
        setConfirmEmail(false);
    };
    var desktopResendEmail = function () {
        dispatch(openModal({
            modalName: MODALS.RESEND_EMAIL,
            modalProps: {},
        }));
    };
    var mobileResendEmail = function () {
        dispatch(push({
            pathname: getPathWithLanguageCode('/register/success/email'),
            state: {
                userId: errors[0].externalId,
            },
        }));
    };
    var reSendMail = function () {
        handleCloseConfirmEmailModal();
        (isDesktop ? desktopResendEmail : mobileResendEmail)();
    };
    var redirectToHome = function () { return dispatch(push(getPathWithLanguageCode('/home'))); };
    var redirectToTerms = function () {
        var pathname = '/terms';
        if (isDesktop) {
            pathname = '/about/terms';
        }
        var route = {
            pathname: pathname,
            state: {
                type: 'info',
            },
        };
        dispatch(push(getPathWithLanguageCode(route.pathname), route.state));
    };
    var login = function (values) {
        var username = values.username, password = values.password;
        dispatch(loginAsyncAction({
            username: username.trim(),
            password: password.trim(),
        }))
            .then(unwrapResult)
            .then(function () {
            return (modalProps ? modalProps.withRedirectToHome : withRedirectToHome)
                ? redirectToHome()
                : (modalProps === null || modalProps === void 0 ? void 0 : modalProps.nextRedirectionPoint) &&
                    dispatch(push(getPathWithLanguageCode(modalProps === null || modalProps === void 0 ? void 0 : modalProps.nextRedirectionPoint)));
        })
            .then(function () {
            dispatch(checkTermsHaveUpdate()).then(function () {
                return termsHaveUpdate && redirectToTerms();
            });
        })
            .then((modalProps === null || modalProps === void 0 ? void 0 : modalProps.onSubmit) || onCloseModal)
            .then(function () {
            return ReactGA.event('login', {
                method: methodName,
            });
        }).then(function () { return ReactPixel.trackCustom('login', {
            method: methodName,
        }); });
    };
    var errorConverted = Array.isArray(errors)
        ? errors
            .map(function (_a) {
            var code = _a.exceptionCode;
            return te(code !== 'ER2002' ? code : "".concat(code, "_login"));
        })
            .join('. ')
        : '';
    useEffect(function () {
        var _a;
        if (Array.isArray(errors)) {
            dispatch(setUserPreData({
                userId: errors[0].externalId,
                email: errors[0].email,
            }));
            var errorCode = (_a = errors[0]) === null || _a === void 0 ? void 0 : _a.exceptionCode;
            if (errorCode === ER2003) {
                dispatch(push(getPathWithLanguageCode('/home')));
                setBanModal(true);
            }
            if (errorCode === ER2001) {
                setConfirmEmail(true);
            }
        }
    }, [errors]);
    var openDesktopRestorePassModal = function () {
        onCloseModal();
        dispatch(openModal({ modalName: MODALS.RESTORE_PASSWORD, modalProps: {} }));
    };
    var openMobileRestorePassword = function () {
        onCloseModal();
        dispatch(push(getPathWithLanguageCode('/restore-password')));
    };
    var restorePassword = function () {
        (isDesktop ? openDesktopRestorePassModal : openMobileRestorePassword)();
    };
    var handleCloseDialogFromRn = useCallback(
    // @ts-expect-error
    function (event) {
        try {
            if (event.data) {
                var evt = JSON.parse(event.data);
                if (evt.code === 'RN-WEB::CLOSE_LOGIN_DIALOG' && isOpenModal) {
                    onCloseModal();
                }
            }
        }
        catch (error) {
            // no need to handle it
        }
    }, [onCloseModal, isOpenModal]);
    useEffect(function () {
        window.addEventListener('message', handleCloseDialogFromRn, false);
        return function () {
            window.removeEventListener('message', handleCloseDialogFromRn);
        };
    }, [handleCloseDialogFromRn]);
    return (React.createElement(Modal, { open: isOpenModal, onClose: onCloseModal },
        React.createElement(React.Fragment, null,
            !banModal && !confirmEmail ? (React.createElement("div", { className: classNames('flex-column flex-center', loginClasses.loginForm, (_b = {},
                    _b[loginClasses.desktopWidth] = isDesktop,
                    _b[loginClasses.desktopPadding] = !isMobileDevice,
                    _b)) },
                React.createElement(LoginForm, { onClose: onClose || (modalProps === null || modalProps === void 0 ? void 0 : modalProps.onClose), onSubmit: login, formError: errorConverted }),
                !isDesktop ? (React.createElement(Button, { style: { marginTop: '16px' }, fullWidth: true, variant: "contained", component: Link, to: getPathWithLanguageCode('/register'), onClick: onCloseModal, "data-testid": getTestId('BTN_REGISTER') }, t('register_now'))) : null,
                React.createElement(Button, { className: loginClasses.restoreButton, onClick: restorePassword }, t('forgot_password')))) : null,
            isLoading ? React.createElement(FullscreenLoader, null) : null,
            banModal ? (React.createElement(BanModal, { t: t, handleCloseBanModal: handleCloseBanModal })) : null,
            confirmEmail ? (React.createElement(ResendModal, { t: t, handleCloseConfirmEmailModal: handleCloseConfirmEmailModal, reSendMail: reSendMail })) : null)));
};
export default Login;
