import { useEffect, useRef } from 'react';
import { clamp } from '~utils/math';
import { pxToNumber } from '~utils/css';
import { scrollSmoothlyBy } from '~utils/dom';
import { easeInOut } from '~utils/timingFunctions';
var useAutoScroll = function (isEnabled, options) {
    var elementRef = useRef(null);
    var scrollParentRef = useRef(null);
    var isScrolledRef = useRef(!isEnabled);
    var _a = options || {}, _b = _a.threshold, threshold = _b === void 0 ? 0 : _b, _c = _a.delay, delay = _c === void 0 ? 0 : _c;
    useEffect(function () {
        setTimeout(function () {
            var scrollParent = scrollParentRef.current;
            if (!isScrolledRef.current && elementRef.current && scrollParent) {
                var parentComputedStyle = getComputedStyle(scrollParent);
                var parentHeight = pxToNumber(parentComputedStyle.height);
                var yDiff = scrollParent.getBoundingClientRect().top +
                    parentHeight -
                    elementRef.current.getBoundingClientRect().top;
                if (yDiff < threshold) {
                    var scrollAmount = clamp(-yDiff, 0, 99999) + threshold - clamp(yDiff, 0, threshold);
                    scrollSmoothlyBy(scrollParent, scrollAmount, {
                        timingFunction: easeInOut,
                    });
                    isScrolledRef.current = true;
                }
            }
        }, delay);
    }, []);
    return {
        elementRef: elementRef,
        scrollParentRef: scrollParentRef,
    };
};
export { useAutoScroll };
