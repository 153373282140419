import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var GroupIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, width: "22", height: "28", viewBox: "0 0 22 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M13.0776 22.8495V27.9998H15.9037C17.5073 27.9998 18.8123 26.6949 18.8123 25.0912V20.0637C17.3561 21.5266 15.3543 22.4878 13.0776 22.8495Z", fill: "#FF0C38" }),
        React.createElement("path", { d: "M10.9998 23.0108C8.7227 23.0108 6.61817 22.4522 4.91504 21.417V25.0913C4.91504 26.6949 6.22001 27.9998 7.82365 27.9998H13.0775V22.8496C12.4064 22.9562 11.7116 23.0108 10.9998 23.0108Z", fill: "#FF3F62" }),
        React.createElement("path", { d: "M3.89071 8.06055C2.13944 8.06055 0.720215 9.48034 0.720215 11.2316V13.8631C0.720215 17.2474 2.35552 19.8471 4.87891 21.3953V8.06055H3.89071Z", fill: "#FF0C38" }),
        React.createElement("path", { d: "M21.2785 8.06061C21.2027 3.59588 17.5599 0 13.0776 0V8.06061H21.2785Z", fill: "#FF0C38" }),
        React.createElement("path", { d: "M13.0775 0C8.59528 0 4.9547 3.59644 4.87891 8.06061H13.0775V0Z", fill: "#FF3F62" }),
        React.createElement("path", { d: "M21.2796 8.20196C21.2796 8.15445 21.279 8.1075 21.2784 8.06055H4.87891V21.3953C4.8909 21.4027 4.90306 21.4098 4.91511 21.4171C6.61824 22.4523 8.72277 23.0108 10.9999 23.0108C11.7116 23.0108 12.4065 22.9563 13.0775 22.8496C15.3542 22.488 17.356 21.5267 18.8122 20.0638C20.3502 18.5186 21.2796 16.4138 21.2796 13.8631V8.20196Z", fill: "#C70024" })));
};
