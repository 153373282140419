import makeStyles from '@material-ui/core/styles/makeStyles';
export var useStyles = makeStyles(function (theme) {
    var _a, _b, _c, _d;
    return ({
        logoWrapper: (_a = {
                backgroundColor: '#000',
                color: '#fff',
                fontWeight: 500,
                fontSize: '10px',
                maxWidth: '120px',
                minWidth: '120px',
                width: '100%',
                minHeight: 64,
                borderRadius: '4px',
                boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.05)',
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-end',
                '&.is-white': {
                    color: '#000',
                    border: '1px solid rgba(0, 0, 0, 0.8)',
                    '&>div': {
                        border: '1px solid rgba(0, 0, 0, 0.8)',
                    },
                }
            },
            _a[theme.breakpoints.up('xl')] = {
                flexDirection: 'row',
                justifyContent: 'flex-start',
                maxWidth: 'initial',
                minHeight: 80,
                padding: '20px 24px',
                fontSize: '12px',
            },
            _a),
        logoWrapper_blurred: {
            minWidth: 120,
            justifyContent: 'center',
            backgroundImage: 'radial-gradient(circle, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0) 60%)',
        },
        logoImg: (_b = {
                width: 60,
                height: 23
            },
            _b[theme.breakpoints.up('xl')] = {
                width: 77,
                height: 'auto',
            },
            _b),
        smallLogoWrapper: {
            position: 'absolute',
            left: 20,
            padding: 4,
            borderRadius: 4,
            width: 35,
            height: 16,
            '& img': {
                width: 35,
                height: 16,
            },
        },
        balance: (_c = {
                border: '1px solid #ffffff66',
                borderRadius: '4px',
                margin: '8px 6px 6px',
                padding: '6px 8px',
                textAlign: 'center',
                display: 'flex',
                width: 'calc(100% - 12px)',
                boxSizing: 'border-box'
            },
            _c[theme.breakpoints.up('xl')] = {
                width: 'auto',
                flex: 'auto',
                margin: '0',
                padding: '12px 10px',
            },
            _c),
        welcomeButton: {
            padding: '1px 8px',
        },
        buttonText: (_d = {
                width: '53px',
                textAlign: 'start'
            },
            _d[theme.breakpoints.up('xl')] = {
                width: 'auto',
            },
            _d),
        fullWidth: {
            width: '100%',
        },
        divider: {
            margin: 'auto 24px',
            width: '1px',
            height: '32px',
            background: 'rgba(255,255,255,0.4)',
        },
    });
}, { name: 'ProviderLogo' });
