var MILLISECONDS_IN_SECOND = 1000;
// returns ms
var normalizeValue = function (value, defaultValue) {
    if (isFinite(Number(value)) && Number(value) >= 0) {
        return Number(value);
    }
    if (typeof value === 'string' && /^\d+\.{0,1}\d*(ms|s)$/gm.test(value)) {
        var isMS = /(ms)$/gm.test(value);
        var multiplier = isMS ? 1 : MILLISECONDS_IN_SECOND;
        return Number(value.replace(/(ms|s)/gm, '')) * multiplier;
    }
    return defaultValue;
};
var normalizeDuration = function (value, defaultValue) {
    var ms = normalizeValue(value, defaultValue);
    return {
        ms: ms,
        s: ms / MILLISECONDS_IN_SECOND,
        str: "".concat(ms, "ms"),
    };
};
export var AnimationDuration = {
    SHORT: normalizeDuration(process.env.ANIMATION_DURATION_SHORT_1, 300),
    MEDIUM: normalizeDuration(process.env.ANIMATION_DURATION_MEDIUM_1, 800),
    LONG: normalizeDuration(process.env.ANIMATION_DURATION_LONG_1, 1500),
};
