import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '~atoms';
import { checkIfFieldIsRequired, required, validateField, } from '~utils/formValidators';
import { normalizeTextFieldValue } from '~utils/fieldNormalization';
export var PassportNumber = function (_a) {
    var tabIndex = _a.tabIndex, registrationBlocks = _a.registrationBlocks;
    var t = useTranslation('registration').t;
    return (React.createElement(TextField, { name: "userProfileDto.passportNumber", title: t('form.id_card'), placeholder: t('form.id_card'), validators: function (value) {
            return validateField(value, 'passportNumber', tabIndex, required, registrationBlocks);
        }, required: checkIfFieldIsRequired('passportNumber', tabIndex, registrationBlocks), normalize: normalizeTextFieldValue }));
};
