import { getPositionsMapper } from './getPositionsMapper';
import { getMatrixLine } from './getMatrixLine';
var normalizeOneLineOc = function (oc, optionName, columnsCount, idsMap) {
    var matrix = getMatrixLine(columnsCount, 1);
    var positionsMap = idsMap[optionName];
    if (oc.length > columnsCount) {
        return matrix;
    }
    var result = matrix.map(getPositionsMapper(oc, positionsMap));
    var correctCount = result.filter(function (o) { return typeof o !== 'string'; }).length;
    if (correctCount !== oc.length) {
        return matrix;
    }
    return result;
};
export { normalizeOneLineOc };
