import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { MuiThemeProvider } from '@material-ui/core';
import MomentUtils from '@material-ui/pickers/adapter/moment';
import { LocalizationProvider } from '@material-ui/pickers/LocalizationProvider';
import { defaultTheme, desktopToMobileTheme } from '~theme';
import { Crash } from '~pages';
import ErrorBoundary from '~utils/errorBoundary';
import { AppRoutes } from '~routes/AppRouter';
export var useViewDataSelector = createSelector(function (state) { return state.options.data; }, function (_a) {
    var view = _a.view;
    return view;
});
export var ViewEntrypoint = function () {
    var desktopTheme = useSelector(useViewDataSelector);
    var currentTheme = desktopTheme === 'default' ? defaultTheme : desktopToMobileTheme;
    return (React.createElement(ErrorBoundary, { errorScreen: React.createElement(Crash, null) },
        React.createElement(MuiThemeProvider, { theme: currentTheme },
            React.createElement(LocalizationProvider, { dateAdapter: MomentUtils },
                React.createElement(AppRoutes, null)))));
};
