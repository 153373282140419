import makeStyles from '~utils/makeStyles';
var useStyles = makeStyles(function () { return ({
    button: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: '14px',
        maxWidth: '21px',
        padding: '11px 15px',
        border: '1px solid #3E3E3E',
        borderRadius: '4px',
        backgroundColor: '#222222',
        cursor: 'pointer',
        fontSize: '12px',
        color: '#FFFFFF',
        '&:not(:last-child)': {
            marginRight: '12px',
        },
    },
    active: {
        backgroundColor: '#606060',
        border: '1px solid transparent',
    },
}); }, { name: 'BalanceInputButton' });
export { useStyles };
