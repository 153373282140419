import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var BowlsIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg", className: className },
        React.createElement("svg", { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("g", { clipPath: "url(#clip0)" },
                React.createElement("path", { d: "M28 14C28 14.1948 28 14.3896 27.9878 14.5843C27.68 21.8556 21.8556 27.68 14.5844 27.9878C14.3896 28 14.1948 28 14 28C13.8053 28 13.5861 28 13.3792 27.9878C6.13679 27.6602 0.339823 21.8632 0.0121549 14.6209C0 14.414 0 14.207 0 14C0 13.793 0 13.5983 0.0121549 13.4035C0.324986 6.13652 6.14837 0.318367 13.4156 0.0121549C13.6104 0 13.8052 0 14 0C14.2191 0 14.4261 0 14.633 0.0121549C21.8669 0.343704 27.6562 6.13309 27.9878 13.367C28 13.574 28 13.7809 28 14Z", fill: "#FF7E47" }),
                React.createElement("path", { d: "M14 28C6.26799 28 0 21.732 0 14C0 6.26799 6.26799 0 14 0C21.732 0 28 6.26799 28 14C27.9913 21.7284 21.7284 27.9913 14 28ZM14 1.21737C6.94034 1.21737 1.21737 6.94034 1.21737 14C1.21737 21.0597 6.94034 26.7826 14 26.7826C21.0597 26.7826 26.7826 21.0597 26.7826 14C26.7746 6.9437 21.0564 1.22542 14 1.21737Z", fill: "black" }),
                React.createElement("path", { d: "M14 28C6.26799 28 0 21.732 0 14C0 6.26799 6.26799 0 14 0C21.732 0 28 6.26799 28 14C27.9913 21.7284 21.7284 27.9913 14 28ZM14 1.21737C6.94034 1.21737 1.21737 6.94034 1.21737 14C1.21737 21.0597 6.94034 26.7826 14 26.7826C21.0597 26.7826 26.7826 21.0597 26.7826 14C26.7746 6.9437 21.0564 1.22542 14 1.21737Z", fill: "black" }),
                React.createElement("path", { d: "M13.9249 0.700589C14.8606 0.863845 15.6868 1.25505 16.3362 1.89014C18.8705 4.36867 17.751 9.62316 13.8358 13.6264C9.92062 17.6296 4.69231 18.8656 2.15805 16.3871C1.65038 15.8906 1.28933 15.2827 1.06801 14.5958C0.755876 13.627 0.72164 12.5011 0.945955 11.3088", stroke: "black" }),
                React.createElement("path", { d: "M5 22.693C9.25517 23.6876 14.4915 21.8629 18.2591 17.6524C21.9615 13.5149 23.2172 8.22303 21.8749 4.13238", stroke: "black", strokeWidth: "2", strokeLinecap: "round", strokeDasharray: "0.1 3" }),
                React.createElement("path", { d: "M11.9491 4.60433C12.4113 5.0563 12.5858 5.83181 12.3554 6.82794C12.1273 7.81399 11.5145 8.92956 10.549 9.91675C9.5835 10.9039 8.48184 11.5415 7.5011 11.7914C6.51034 12.0439 5.73113 11.8866 5.269 11.4346C4.80686 10.9827 4.63232 10.2072 4.86272 9.21103C5.09078 8.22498 5.70365 7.10941 6.66913 6.12222C7.63462 5.13503 8.73628 4.49751 9.71702 4.24758C10.7078 3.99509 11.487 4.15235 11.9491 4.60433Z", stroke: "black" })),
            React.createElement("defs", null,
                React.createElement("clipPath", { id: "clip0" },
                    React.createElement("rect", { width: "28", height: "28", fill: "white" }))))));
};
