import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var FigureSkatingIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M28 23.0781C28 25.7851 25.7852 28 23.0781 28H0V26.3594H23.0781C24.8828 26.3594 26.3594 24.8828 26.3594 23.0781H28Z", fill: "#FFF5F5" }),
        React.createElement("path", { d: "M4.97656 23.8984H6.61719V27.1797H4.97656V23.8984Z", fill: "#FFF5F5" }),
        React.createElement("path", { d: "M18.1016 23.8984H19.7422V27.1797H18.1016V23.8984Z", fill: "#EFE2DD" }),
        React.createElement("path", { d: "M28 23.0781C28 25.7851 25.7852 28 23.0781 28H11.5391V26.3594H23.0781C24.8828 26.3594 26.3594 24.8828 26.3594 23.0781H28Z", fill: "#EFE2DD" }),
        React.createElement("path", { d: "M24.6641 21.3882V22.2578C24.6641 23.6195 23.5649 24.7188 22.2031 24.7188H4.15625C1.89219 24.7188 0 22.8813 0 20.6172V3.8226L0.164008 3.60938C1.08276 2.37891 2.61406 1.64062 4.15625 1.64062C5.69844 1.64062 7.17506 2.37891 8.09381 3.60938L8.25781 3.8226V12.4141C8.25781 13.7757 9.35698 14.875 10.7188 14.875H14C14.5906 14.875 15.1484 14.6453 15.5914 14.268L18.0195 14.0711C23.368 14.1203 24.6641 20.0266 24.6641 21.3882Z", fill: "#7ED8F6" }),
        React.createElement("path", { d: "M22.2031 24.7185H11.5391V14.8747H14C14.5906 14.8747 15.1484 14.645 15.5914 14.2677L18.0195 14.0708C23.368 14.12 24.6641 20.0263 24.6641 21.3879V22.2575C24.6641 23.6192 23.5649 24.7185 22.2031 24.7185Z", fill: "#6AA9FF" }),
        React.createElement("path", { d: "M16.5594 13.825C14.5906 13.4804 13.1797 11.7906 13.1797 9.78901V0H10.7188C8.45469 0 6.61719 1.8375 6.61719 4.10156V12.4141C6.61719 14.6781 8.45469 16.5156 10.7188 16.5156H14C15.1484 16.5156 16.2641 16.0234 17.0188 15.1703L18.0196 14.0711L16.5594 13.825Z", fill: "#613D5C" }),
        React.createElement("path", { d: "M18.0195 14.0711L17.0188 15.1703C16.2641 16.0234 15.1484 16.5156 14 16.5156H11.5391V0H13.1797V9.78901C13.1797 11.7906 14.5906 13.4804 16.5594 13.825L18.0195 14.0711Z", fill: "#4B2746" }),
        React.createElement("path", { d: "M9.89844 4.92188H14V6.5625H9.89844V4.92188Z", fill: "#FFF5F5" }),
        React.createElement("path", { d: "M9.89844 8.3125H14V9.95312H9.89844V8.3125Z", fill: "#FFF5F5" }),
        React.createElement("path", { d: "M11.5391 4.92188H14V6.5625H11.5391V4.92188Z", fill: "#EFE2DD" }),
        React.createElement("path", { d: "M11.5391 8.3125H14V9.95312H11.5391V8.3125Z", fill: "#EFE2DD" })));
};
