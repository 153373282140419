import * as React from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Button } from '~atoms/button';
import reload from '~icons/statusIcons/reload.svg';
var useStyles = makeStyles({
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    main: {
        minHeight: '100vh',
        background: 'radial-gradient(100% 100% at 50.13% 0%, #303038 0%, #1B1B22 100%)',
    },
    content: {
        flexGrow: 1,
        padding: '30px',
        alignItems: 'center',
    },
    icon: {
        marginTop: '100px',
        marginBottom: '40px',
    },
    title: {
        fontSize: '24px',
        color: 'white',
        marginBottom: '20px',
    },
    subTitle: {
        fontSize: '14px',
        color: 'white',
        lineHeight: '21px',
        textAlign: 'center',
        fontWight: 300,
    },
    button: {
        position: 'fixed',
        bottom: '30px',
        width: 'calc(100% - 60px)',
        color: '#212121',
        background: 'white',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '24px',
    },
}, { name: 'Crash' });
export var Crash = function (_a) {
    var click = _a.click;
    var classes = useStyles();
    var t = useTranslation('common').t;
    return (React.createElement("div", { className: "".concat(classes.main, " ").concat(classes.flexColumn) },
        React.createElement("div", { className: "".concat(classes.content, " ").concat(classes.flexColumn) },
            React.createElement("img", { className: classes.icon, src: reload, alt: "status-logo" }),
            React.createElement("span", { className: classes.title }, t('title')),
            React.createElement("span", { className: classes.subTitle },
                t('subtitle_first_row'),
                t('subtitle_second_row')),
            React.createElement(Button, { className: classes.button, variant: "contained", fullWidth: true, onClick: click }, t('reload_button')))));
};
