import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var WaterpoloIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0)" },
            React.createElement("path", { d: "M27 14.1281C27 17.0826 26.0362 19.8077 24.4079 22H18.4625L3.59144 21.9994C2.65885 20.743 1.94365 19.3116 1.50428 17.7622C1.43546 17.523 1.37446 17.2812 1.31993 17.0367C1.27124 16.8205 1.22844 16.6029 1.1908 16.3826V16.382C1.06491 15.6499 1 14.8962 1 14.1281C1 13.478 1.04671 12.8396 1.13695 12.215C1.15838 12.0662 1.18174 11.9195 1.20832 11.7726V11.772C1.23428 11.6272 1.26285 11.483 1.29335 11.3395C1.55359 10.1231 1.97998 8.96953 2.54786 7.90518C2.67115 7.67386 2.80096 7.44705 2.93791 7.22426C5.22756 3.48653 9.32338 1 14 1C15.0786 1 16.1267 1.13241 17.1281 1.38142C17.5941 1.49739 18.051 1.63896 18.4956 1.8048C19.7981 2.28846 21.0026 2.97666 22.0702 3.83195C22.1864 3.92434 22.3006 4.01874 22.4135 4.11571C22.5277 4.21337 22.64 4.31297 22.7503 4.41458C23.644 5.23582 24.4234 6.18149 25.062 7.22489C25.199 7.44705 25.3287 7.67386 25.4514 7.90518C26.4399 9.75658 27 11.8755 27 14.1281Z", fill: "#FCE45B" }),
            React.createElement("path", { d: "M9.3807 21.9994C8.32277 20.549 7.54991 18.8653 7.14883 17.0367C6.94286 16.1015 6.83448 15.1276 6.83448 14.1281C6.83448 13.3239 6.9046 12.5368 7.03917 11.7726V11.772C7.94556 6.62173 11.769 2.52291 16.6909 1.34366C15.7539 1.11911 14.7774 1 13.7734 1C7.49541 1 2.28361 5.64149 1.20469 11.772V11.7726C1.07012 12.5368 1 13.3239 1 14.1281C1 15.1276 1.10839 16.1015 1.31436 17.0367C1.71543 18.8653 2.48836 20.549 3.54622 21.9994L23.9999 22C23.9999 21.9999 23.9999 21.9999 24 21.9998L9.3807 21.9994Z", fill: "#EACF4E" }),
            React.createElement("path", { d: "M23.1952 9.00238C24.1735 12.9179 24.3334 16.9227 23.6692 20.9082L23.6056 21.2878L22.8458 21.1613L22.9088 20.781C23.5434 16.9734 23.4059 13.1478 22.5002 9.40378L18.7723 11.5562C19.2334 14.8679 19.3741 18.2375 19.1891 21.5807C19.1833 21.6918 19.1769 21.8029 19.1698 21.914L19.1461 22.2988L18.3766 22.2513L18.4004 21.8665C18.4061 21.7715 18.4119 21.6758 18.4171 21.5807C18.5976 18.3775 18.4736 15.1492 18.051 11.9724L14.3859 14.0882V18.6421L14.3904 18.644L14.3859 18.655V20.8523H13.6152V19.1604C9.18839 17.3498 5.03646 14.9392 1.27002 11.991C1.29123 11.8452 1.31434 11.7013 1.34065 11.5574V11.5568C1.36634 11.4149 1.39462 11.2735 1.4248 11.1329L1.57768 11.2536C5.24909 14.1517 9.29756 16.5283 13.6152 18.3248V14.0888L9.67266 11.8125L9.66688 11.8163L9.65661 11.8035L2.66639 7.76724C2.78841 7.54054 2.91689 7.31826 3.05243 7.09992L5.18492 8.33124C8.53711 5.10426 12.6408 2.7085 17.0965 1.37378C17.5577 1.48744 18.0098 1.62618 18.4498 1.78871L17.9071 1.94288C13.4116 3.18379 9.26228 5.52823 5.87991 8.7327L9.60848 10.8857C13.3891 7.95553 17.5519 5.56419 21.9877 3.77539C22.1026 3.86593 22.2157 3.95845 22.3274 4.05348C22.4405 4.14919 22.5516 4.24681 22.6608 4.34639L22.4623 4.4145C18.1209 6.14613 14.041 8.46229 10.3304 11.3026L14.0005 13.421L17.9366 11.1484L17.9334 11.1272L17.9873 11.1196L24.9487 7.10065C25.0842 7.31838 25.2126 7.54066 25.334 7.76737L23.1952 9.00238Z", fill: "#D6B21A" }),
            React.createElement("path", { d: "M6.67877 20.3502L6.26323 20.9996L5.93823 20.7915C4.40373 19.8087 2.95794 18.6789 1.63349 17.4277C1.56538 17.1933 1.50501 16.9563 1.45104 16.7167C1.40285 16.5047 1.36049 16.2915 1.32324 16.0757L1.71186 16.4328C1.71506 16.436 1.71825 16.4393 1.72151 16.4425C3.12944 17.8305 4.68767 19.0753 6.35445 20.1428L6.67877 20.3502Z", fill: "#D6B21A" }),
            React.createElement("path", { d: "M28 23.2786C26.2499 23.2786 26.2499 24.6501 24.4998 24.6501C22.7497 24.6501 22.7497 23.2786 20.9996 23.2786C19.2495 23.2786 19.2495 24.6501 17.4995 24.6501C15.7495 24.6501 15.7495 23.2786 13.9996 23.2786C12.2497 23.2786 12.2497 24.6501 10.4998 24.6501C8.74984 24.6501 8.74984 23.2786 6.99984 23.2786C5.24984 23.2786 5.24984 24.6501 3.49984 24.6501C1.74995 24.6501 1.74995 23.2786 0 23.2786V19.8125C1.74989 19.8125 1.74989 21.184 3.49984 21.184C5.24984 21.184 5.24984 19.8125 6.99984 19.8125C8.74978 19.8125 8.74978 21.184 10.4998 21.184C12.2497 21.184 12.2497 19.8125 13.9996 19.8125C15.7496 19.8125 15.7496 21.184 17.4995 21.184C19.2496 21.184 19.2496 19.8125 20.9996 19.8125C22.7497 19.8125 22.7497 21.184 24.4998 21.184C26.2499 21.184 26.2499 19.8125 28 19.8125", fill: "#53C5F2" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0" },
                React.createElement("rect", { width: "28", height: "28", fill: "white" })))));
};
