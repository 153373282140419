import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { openModal, MODALS } from '~modals';
import { useAppDispatch } from '~store';
import { useStyles } from './Wallet.styles';
export var Wallet = function (_a) {
    var title = _a.title, icon = _a.icon, infoTitle = _a.infoTitle, infoText = _a.infoText, helpfulIcon = _a.helpfulIcon, _b = _a.color, color = _b === void 0 ? '#D3D3D3' : _b, active = _a.active, type = _a.type, onClick = _a.onClick;
    var t = useTranslation('common').t;
    var classes = useStyles();
    var dispatch = useAppDispatch();
    var handleModal = function () {
        dispatch(openModal({
            modalName: MODALS.PPMO_MODAL,
            modalProps: {
                title: infoText,
                fullWidth: false,
                cancelVariant: 'text',
                closeButtonLabel: t('buttons.ok').toLocaleUpperCase(),
                columnDialog: false,
                message: infoTitle,
            },
        }));
    };
    var changeType = function () {
        onClick(type);
    };
    return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    React.createElement("div", { className: classes.ppmo, style: {
            border: "".concat(active ? '1px solid #2AA1F7' : '1px solid #3E3E3E'),
        }, onClick: changeType },
        React.createElement("div", { className: "flex-row flex-center" },
            React.createElement("div", { className: classes.direct },
                React.createElement("div", { className: classes.icon, style: { border: "1px solid ".concat(color) } }, icon),
                helpfulIcon && (React.createElement("div", { className: classes.helpfulIcon, style: { color: "".concat(color) } }, helpfulIcon))),
            React.createElement("div", { className: classes.title }, title),
            React.createElement("div", { className: classes.information },
                React.createElement("i", { className: classNames(classes.info, 'far fa-info-circle'), onClick: handleModal })))));
};
