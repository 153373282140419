import { useEffect, useRef } from 'react';
import i18next from 'i18next';
import { SPORT_EVENTS_SOCKET_PATH, connectSocket, resetEvents, } from '~slices/events';
import { useAppDispatch } from '~store';
import { useIsDesktop } from '~hooks';
var WEBSOCKET = process.env.REACT_APP_BASE_WEBSOCKET || '';
var useWSConnectionController = function (_a) {
    var sportId = _a.sportId, socketName = _a.socketName, evType = _a.eventsType;
    var dispatch = useAppDispatch();
    var isDesktop = useIsDesktop();
    var data = {
        desktop: isDesktop,
        sportId: sportId,
        url: "".concat(WEBSOCKET).concat(SPORT_EVENTS_SOCKET_PATH).concat(socketName),
        langIso: i18next.language,
        eventsType: evType || socketName,
    };
    var socketDataRef = useRef(data);
    socketDataRef.current = data;
    useEffect(function () {
        var tId;
        var shouldReopen = false;
        var handleVisibilityChange = function () {
            var eventsType = socketDataRef.current.eventsType;
            if (document.hidden) {
                console.log(eventsType, 'document.hidden');
                tId = setTimeout(function () {
                    dispatch({ type: 'SOCKET_CLOSE', eventsType: eventsType });
                    dispatch(resetEvents(eventsType));
                    shouldReopen = true;
                    console.log(eventsType, 'close socket');
                }, Number(process.env.REACT_APP_SOCKET_BACKGROUND_CLOSE_TIME) * 1000);
            }
            else if (shouldReopen) {
                socketDataRef.current.langIso = i18next.language;
                dispatch(connectSocket(socketDataRef.current));
                shouldReopen = false;
                console.log(eventsType, 'connect socket');
            }
            else {
                clearTimeout(tId);
                console.log(eventsType, 'clearTimeout');
            }
        };
        if (typeof document.addEventListener === 'undefined') {
            console.error('This page requires a browser, that supports the Page Visibility API.');
        }
        else {
            var eventsType = socketDataRef.current.eventsType;
            document.addEventListener('visibilitychange', handleVisibilityChange, false);
            console.log('addEventListener', eventsType);
        }
        return function () {
            var eventsType = socketDataRef.current.eventsType;
            clearTimeout(tId);
            document.removeEventListener('visibilitychange', handleVisibilityChange, false);
            console.log('removeEventListener', eventsType);
        };
    }, []);
};
export { useWSConnectionController };
