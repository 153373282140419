var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getOptionName } from '~utils/getOptionName';
import { BEOptionNames } from '~constants/view/BEOptionNames';
import { API } from '~api';
import { getInitialData } from './getInitialData';
import { name } from './constants';
var matrix = ['1x1', '2x1', '3x1'];
var shiftPositions = function (map) {
    var positions = Object.entries(map);
    if (positions.length === 2) {
        var aIndex = matrix.indexOf(positions[0][0]);
        var bIndex = matrix.indexOf(positions[1][0]);
        if (aIndex < bIndex) {
            return {
                '1x1': positions[0][1],
                '2x1': positions[1][1],
            };
        }
        else {
            return {
                '1x1': positions[1][1],
                '2x1': positions[0][1],
            };
        }
    }
    return map;
};
var normalizeMap = function (map) {
    var result = {};
    if (typeof map === 'object' && map !== null) {
        Object.entries(map).forEach(function (_a) {
            var key = _a[0], value = _a[1];
            var coordinates = key.split('x').map(Number);
            var xCoordinate = coordinates[0];
            var yCoordinate = coordinates[1];
            var isXValid = Number.isInteger(xCoordinate);
            var isYValid = Number.isInteger(yCoordinate);
            if (coordinates.length === 2 && isXValid && isYValid) {
                result["".concat(xCoordinate, "x").concat(yCoordinate)] = value.map(Number);
            }
        });
    }
    return result;
};
var normalizeData = function (data) {
    var initialData = getInitialData();
    if (typeof data === 'object' && data !== null) {
        Object.entries(data)
            .filter(function (_a) {
            var key = _a[0];
            return Object.values(BEOptionNames).includes(key);
        })
            .forEach(function (_a) {
            var key = _a[0], map = _a[1];
            var optionName = getOptionName(key);
            var normalizedMap = normalizeMap(map);
            initialData[optionName] = shiftPositions(normalizedMap);
        });
    }
    return initialData;
};
export var getIdsMap = createAsyncThunk("".concat(name, "/getIdsMap"), function () { return __awaiter(void 0, void 0, void 0, function () {
    var data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 2, , 3]);
                return [4 /*yield*/, API.getOptionsMap()];
            case 1:
                data = (_b.sent()).data;
                // const response = await fetch('/optionsMap.json');
                // const data = await response.json();
                return [2 /*return*/, normalizeData(data)];
            case 2:
                _a = _b.sent();
                return [2 /*return*/, getInitialData()];
            case 3: return [2 /*return*/];
        }
    });
}); });
