import { LayoutOptions } from '~constants/view/LayoutOptions';
var getColumnsCount = function (optionName) {
    switch (optionName) {
        case LayoutOptions.OPTION_1:
        case LayoutOptions.OPTION_3:
        case LayoutOptions.OPTION_5: {
            return 3;
        }
        case LayoutOptions.OPTION_2:
        case LayoutOptions.OPTION_4:
        case LayoutOptions.OPTION_10:
        case LayoutOptions.OPTION_11: {
            return 2;
        }
        case LayoutOptions.OPTION_7:
        case LayoutOptions.OPTION_6: {
            return 1;
        }
        default: {
            return 0;
        }
    }
};
export { getColumnsCount };
