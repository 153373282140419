import makeStyles from '@material-ui/core/styles/makeStyles';
export var useStyles = makeStyles({
    wrapper: {
        display: 'flex',
        alignItems: 'end',
        height: '100%',
        width: '100%',
        borderRadius: '3px',
        background: 'rgba(255, 255, 255, 0.1)',
    },
    progress: {
        width: '100%',
        borderRadius: '3px',
        background: '#5AF257',
    },
}, { name: 'VerticalLinearProgressCartoon' });
