import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var MmaIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { viewBox: "0 0 28 28", className: className },
        React.createElement("svg", { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M7.04951 9C5.36507 9 4 10.3842 4 12.0915V14.6568C4 17.9562 5.57291 20.4907 8 22V9H7.04951Z", fill: "#C70024" }),
            React.createElement("path", { d: "M24.9995 8.2258C24.9995 8.17658 25.0007 7.93724 24.9995 8.01656L8 8V21.8916C8 21.8916 8.02504 21.9066 8.03752 21.9141L21.8284 22C23.4226 20.3995 24.9995 16.7317 24.9995 14.0896V8.2258Z", fill: "#C70024" }),
            React.createElement("path", { d: "M3.5 14.6568V14.694L3.5055 14.7308C4.0137 18.1288 5.59864 20.2182 8.01007 21.7812L8.01752 21.9823L8.34865 21.9947L9.03732 22.4229L9.0212 22.0199L21.8097 22.4996L22.0283 22.5078L22.1827 22.3528C23.0431 21.489 23.8669 20.1066 24.4744 18.6251C25.0829 17.1413 25.4995 15.4972 25.4995 14.0896V8.2258L25.4999 8.07429L25.5 8.00659L25.4998 7.99283L25.4997 7.98581L25.4996 7.98564C25.4996 7.98365 25.4994 7.97636 25.4987 7.96727L25.4987 7.96712C25.4985 7.96377 25.4976 7.9502 25.4953 7.93311L25.4953 7.93303C25.4944 7.92653 25.4911 7.90286 25.4834 7.87339C25.4786 7.85644 25.4629 7.81187 25.4509 7.78474C25.4232 7.73457 25.2998 7.60059 25.1908 7.53849C25.134 7.52718 25.0676 7.52802 24.9999 7.53704L25 7.51656L8.00049 7.5L7.48112 7.49949L7.50034 8.01851L7.51818 8.5H7.04951C5.08248 8.5 3.5 10.1145 3.5 12.0915V14.6568ZM25.4996 8.01313C25.4996 8.0132 25.4996 8.01417 25.4996 8.01582L25.4996 8.01313Z", fill: "#FF0C38", stroke: "black" }),
            React.createElement("path", { d: "M24.9995 8.01655C25.0007 7.93723 24.9995 8.17657 24.9995 8.22579V14.0896C24.9995 16.7317 23.4226 20.3995 21.8284 22L16 21.7813V8.00778L24.9995 8.01655Z", fill: "#C70024" }),
            React.createElement("path", { d: "M7.5 9V13L8 16", stroke: "black", strokeLinecap: "round" }),
            React.createElement("path", { d: "M7.5 5.49999C7.5 4.39542 8.4481 3.49999 9.61765 3.49999C10.7872 3.49999 11.7353 4.39542 11.7353 5.49999V7.49999H7.5V5.49999Z", fill: "#FFB240" }),
            React.createElement("path", { d: "M11.7353 4.07062C11.7353 2.82798 12.8019 1.82062 14.1176 1.82062C15.2204 1.82062 16.1481 2.52822 16.4196 3.48889C16.472 3.67445 16.5 4.18262 16.5 4.38378V7.49999H11.7353V4.07062Z", fill: "#FFB240" }),
            React.createElement("path", { d: "M16.5 4.38378C16.5 3.14114 17.5666 2.13378 18.8824 2.13378C20.1981 2.13378 21.2647 3.14114 21.2647 4.38378V7.49999H16.5V4.38378Z", fill: "#FFB240" }),
            React.createElement("path", { d: "M21.2647 5.49999C21.2647 5.35727 21.2346 5.46895 21.2647 5.33469C21.4675 4.42988 22.318 3.7509 23.3364 3.7509C24.506 3.7509 25.4541 4.64633 25.4541 5.7509L25.5 7.49999H21.2647V5.49999Z", fill: "#FFB240" }),
            React.createElement("path", { d: "M11.7353 7.49999V5.49999C11.7353 4.39542 10.7872 3.49999 9.61765 3.49999C8.4481 3.49999 7.5 4.39542 7.5 5.49999V7.49999H11.7353ZM11.7353 7.49999H16.5M11.7353 7.49999V4.07062C11.7353 2.82798 12.8019 1.82062 14.1176 1.82062C15.2204 1.82062 16.1481 2.52822 16.4196 3.48889C16.472 3.67445 16.5 4.18262 16.5 4.38378M16.5 7.49999V4.38378M16.5 7.49999H21.2647M16.5 4.38378C16.5 3.14114 17.5666 2.13378 18.8824 2.13378C20.1981 2.13378 21.2647 3.14114 21.2647 4.38378V7.49999M21.2647 7.49999H25.5L25.4541 5.7509C25.4541 4.64633 24.506 3.7509 23.3364 3.7509C22.318 3.7509 21.4675 4.42988 21.2647 5.33469C21.2346 5.46895 21.2647 5.35727 21.2647 5.49999V7.49999Z", stroke: "black" }),
            React.createElement("path", { d: "M16.5 4.38379C16.5 3.14115 17.5666 2.13379 18.8824 2.13379C20.1981 2.13379 21.2647 3.14115 21.2647 4.38379V7.5H16.5V4.38379Z", fill: "#D3963B" }),
            React.createElement("path", { d: "M21.2647 5.5C21.2647 5.35728 21.2346 5.46896 21.2647 5.3347C21.4675 4.42989 22.318 3.75091 23.3364 3.75091C24.506 3.75091 25.4541 4.64634 25.4541 5.75091L25.5 7.5H21.2647V5.5Z", fill: "#D3963B" }),
            React.createElement("path", { d: "M21.2647 7.5V4.38379C21.2647 3.14115 20.1981 2.13379 18.8824 2.13379C17.5666 2.13379 16.5 3.14115 16.5 4.38379V7.5H21.2647ZM21.2647 7.5H25.5L25.4541 5.75091C25.4541 4.64634 24.506 3.75091 23.3364 3.75091C22.318 3.75091 21.4675 4.42989 21.2647 5.3347C21.2346 5.46896 21.2647 5.35728 21.2647 5.5V7.5Z", stroke: "black" }),
            React.createElement("rect", { x: "8", y: "21", width: "15", height: "6.5", rx: "1", fill: "#57565C", stroke: "black" }),
            React.createElement("path", { d: "M11 26C11 25.4477 11.4477 25 12 25H19C19.5523 25 20 25.4477 20 26V27.5H11V26Z", fill: "#FF3F62", stroke: "black" }))));
};
