import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import i18next from 'i18next';
import { name } from './constants';
export var localeSelector = function (state) { return state[name]; };
export var localeDataSelector = function (state) {
    return localeSelector(state).data[i18next.language] ||
        localeSelector(state).data[Object.keys(localeSelector(state).data)[0]];
};
export var localeLoadingSelector = function (state) {
    return localeSelector(state).loading;
};
export var localeIsPendingSelector = function (state) {
    return localeLoadingSelector(state) === 'pending';
};
export var questionsSelector = createSelector(localeDataSelector, function (data) { return data.questions; });
export var countriesPhoneCodeAndNameSelector = createSelector(localeDataSelector, function (data) {
    return data.countries.map(function (country) { return ({
        id: country.phoneCode,
        value: country.country,
    }); });
});
export var defaultCountryCodeSelector = createSelector(localeDataSelector, function (data) { return data.defaultCountry.countryCode; });
/* @deprecated use useStructuredSelector instead */
export var useLocaleSelector = function () { return useSelector(localeSelector); };
/* @deprecated use useStructuredSelector instead */
export var useLocaleDataSelector = function () { return useSelector(localeDataSelector); };
/* @deprecated use useStructuredSelector instead */
export var useLocaleLoadingSelector = function () {
    return useSelector(localeLoadingSelector);
};
/* @deprecated use useStructuredSelector instead */
export var useLocaleIsPendingSelector = function () {
    return useSelector(localeIsPendingSelector);
};
