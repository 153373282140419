import React from 'react';
import { useTranslation } from 'react-i18next';
import doneIcon from '~icons/rankingList/done.svg';
import locked from '~icons/rankingList/locked-white.svg';
import unlocked from '~icons/rankingList/unlocked-white.svg';
import { VerticalLinearProgressCartoon } from "~atoms/VerticalLinearProgressCartoon";
import { numberWithDots } from "~utils/numberWithDots";
import CSCoin from "~icons/balance/CS_Coin.svg";
import { useStyles } from './LevelCardCartoon.styles';
var mainPath = process.env.REACT_CDN_ORIGIN || '';
var qualificationOneEndpoint = process.env.REACT_QUALIFICATION_1_ENDPOINT || '';
var qualificationTwoEndpoint = process.env.REACT_QUALIFICATION_2_ENDPOINT || '';
var qualificationThreeEndpoint = process.env.REACT_QUALIFICATION_3_ENDPOINT || '';
var qualificationFourEndpoint = process.env.REACT_QUALIFICATION_4_ENDPOINT || '';
var levelTwoEndpoint = process.env.REACT_LEVEL_2_ENDPOINT || '';
export var LevelCardCartoon = function (_a) {
    var level = _a.level, isDone = _a.isDone, levelLimit = _a.levelLimit, progress = _a.progress, userBalance = _a.userBalance, isLevelRanking = _a.isLevelRanking, isLocked = _a.isLocked;
    var t = useTranslation('tip-game').t;
    var classes = useStyles();
    var qualificationEndpoints = {
        1: qualificationOneEndpoint,
        2: qualificationTwoEndpoint,
        3: qualificationThreeEndpoint,
        4: qualificationFourEndpoint,
    };
    var iconEndpoint = isLevelRanking ? levelTwoEndpoint : qualificationEndpoints[level] || qualificationOneEndpoint;
    var imgUrl = "".concat(mainPath, "avatars").concat(iconEndpoint);
    var progressValue = progress
        ? progress
        : isDone
            ? 100
            : userBalance && levelLimit
                ? (userBalance * 100) / levelLimit
                : 0;
    var isLockedValue = isLevelRanking ? isLocked : !isDone;
    return (React.createElement("div", { className: classes.wrapper },
        React.createElement("div", { className: classes.mainContentWrapper },
            React.createElement("div", { className: classes.icon },
                React.createElement("img", { src: imgUrl, alt: 'user level icon' })),
            React.createElement("div", { className: classes.content },
                React.createElement("div", { className: classes.levelBlock },
                    React.createElement("div", { className: classes.title },
                        t('level'),
                        React.createElement("span", null, level)),
                    isDone && (React.createElement("img", { src: doneIcon, alt: 'done icon' }))),
                React.createElement("div", { className: classes.divider }),
                React.createElement("div", { className: classes.tipPool },
                    React.createElement("span", null, t('jackpot')),
                    React.createElement("span", { className: classes.tipPool },
                        levelLimit ? numberWithDots(levelLimit) : null,
                        React.createElement("img", { src: CSCoin, alt: 'coin' }))),
                React.createElement("div", { className: classes.divider }))),
        React.createElement("div", { className: classes.rightBlock },
            React.createElement("div", { className: classes.rightBlockBackground }),
            React.createElement("div", { className: classes.rightBlockWrapper },
                React.createElement("div", { className: classes.progressWrapper },
                    React.createElement(VerticalLinearProgressCartoon, { progress: progressValue })),
                isLockedValue ? (React.createElement("img", { src: locked, alt: 'locked' })) : (React.createElement("img", { className: classes.unlocked, src: unlocked, alt: 'unlocked' }))))));
};
