import makeStyles from '@material-ui/core/styles/makeStyles';
export var useLoginStyles = makeStyles({
    loginForm: {
        padding: '16px',
        boxShadow: '0 0 20px rgba(0, 0, 0, 0.05)',
        borderRadius: '8px',
        color: '#ffffff',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: 'radial-gradient(100% 100% at 50.13% 0%, #303038 0%, #1B1B22 100%)',
        width: 'calc(100% - 60px)',
        maxWidth: '450px',
        boxSizing: 'border-box',
        outline: 'none',
    },
    closeIcon: {
        position: 'absolute',
        top: '-25px',
        right: '-25px',
        background: 'transparent',
        border: 'none',
        outline: 'none',
        cursor: 'pointer',
        fontSize: '20px',
        color: '#8E8E8E',
        opacity: '.5',
        '& i': {
            fontWeight: 100,
        },
    },
    loginButton: {
        marginTop: '25px',
    },
    title: {
        display: 'block',
        marginBottom: '25px',
        fontSize: '24px',
    },
    desktopWidth: {
        minWidth: '400px',
        maxWidth: '450px',
    },
    desktopPadding: {
        padding: '40px 40px 27px',
    },
    loginFormMain: {
        position: 'relative',
        width: '100%',
        height: '100%',
        textAlign: 'center',
    },
    loginFormButtons: {
        width: '100%',
        marginTop: '32px',
    },
    formError: {
        marginBottom: '8px !important',
        marginTop: '-5px !important',
    },
    inputes: {
        '& input': {
            background: '#323232',
            color: '#fff',
            border: 'none',
        },
    },
    titleClassName: {
        color: '#fff',
    },
    restoreButton: {
        marginTop: '4px',
        outline: 'none',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: 500,
        color: '#8E8E8E',
    },
}, { name: 'Login' });
