import React, { useCallback, useEffect, useState } from 'react';
import { EventsDropDown, SportCard, TextInput } from '~atoms';
import { noop } from '~utils/noop';
import { useStyles } from './SportSelect.styles';
var SportSelect = function (_a) {
    var selectedSportId = _a.selectedSportId, _b = _a.open, open = _b === void 0 ? false : _b, anchorEl = _a.anchorEl, _c = _a.sports, sports = _c === void 0 ? [] : _c, _d = _a.onDropdownClose, onDropdownClose = _d === void 0 ? noop : _d, _e = _a.onSportClick, onSportClick = _e === void 0 ? noop : _e;
    var classes = useStyles();
    var _f = useState(''), value = _f[0], setValue = _f[1];
    useEffect(function () {
        if (open) {
            setValue('');
        }
    }, [open]);
    var handleSportClick = useCallback(function (id, name) {
        onSportClick(id, name);
    }, [onSportClick]);
    var handleChange = function (e) {
        setValue(e.target.value);
    };
    var searchValue = value.toLowerCase().trim();
    var filteredSports = sports.filter(function (_a) {
        var name = _a.name;
        return !!(name !== null && name !== void 0 ? name : '').toLowerCase().includes(searchValue);
    });
    return (React.createElement(EventsDropDown, { open: open, anchorEl: anchorEl, onClose: onDropdownClose },
        React.createElement("div", { className: classes.selectContent },
            React.createElement(TextInput, { className: classes.input, value: value, onChange: handleChange }),
            React.createElement("div", { className: classes.sports }, filteredSports.map(function (_a) {
                var id = _a.id, betradarId = _a.betradarId, name = _a.name;
                return (React.createElement(SportCard, { key: Number(id), active: id === selectedSportId, highlightedValue: searchValue, bid: Number(betradarId), id: Number(id), name: String(name), onClick: handleSportClick }));
            })))));
};
export { SportSelect };
