import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ViewEntrypoint } from '~view/viewEntrypoint';
import store, { persistor } from '~store/configureStore';
import '../i18n';
import './App.scss';
var App = function () {
    return (React.createElement(Provider, { store: store },
        React.createElement(PersistGate, { persistor: persistor },
            React.createElement(ViewEntrypoint, null))));
};
export default App;
