import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var CrossCountryIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, viewBox: "0 0 28 28" },
        React.createElement("svg", { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M0 19.6811L0.49697 18.1417L16.6667 23.3781C16.1836 24.1808 15.2122 24.5462 14.3198 24.2608L0 19.6811Z", fill: "#FFE477" }),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M14.2227 12.6119C14.4919 11.7843 15.381 11.3316 16.2086 11.6008L21.2025 13.225C21.9569 13.4704 22.4112 14.2382 22.2632 15.0175L21.4875 19.1024C21.3251 19.9573 20.5004 20.5188 19.6454 20.3564C18.7904 20.1941 18.2289 19.3693 18.3913 18.5144L18.9081 15.7928L15.2338 14.5978C14.4062 14.3286 13.9535 13.4395 14.2227 12.6119Z", fill: "#328777" }),
            React.createElement("path", { d: "M9.47554 22.0548L9.46592 20.4372L26.4621 20.3515C26.2545 21.2651 25.4461 21.9161 24.5093 21.9242L9.47554 22.0548Z", fill: "#FFE477" }),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M23.3853 6.68025C24.3948 7.97033 23.7409 9.50917 23.103 10.0084L16.6424 14.0205L15.8078 17.5005C15.7116 17.9363 15.4221 18.3046 15.0213 18.5011L9.9546 20.9253C9.22727 21.2818 8.34862 20.9813 7.99209 20.2539C7.63555 19.5266 7.93614 18.6479 8.66347 18.2914L12.8242 16.2629L13.5515 12.675C13.6969 12.0447 14.0106 11.5878 14.2787 11.3781L19.6606 6.50533C20.2985 6.00614 22.3758 5.39018 23.3853 6.68025Z", fill: "#4BCDB2" }),
            React.createElement("path", { d: "M12.8242 16.2629L13.5515 12.675C13.6969 12.0447 14.0107 11.5878 14.2788 11.3781L19.6606 6.50533L23.3853 6.68025C22.1639 6.94921 19.2921 7.90896 17.5757 9.59624C15.4303 11.7053 15.2969 11.6811 14.7879 12.5417C14.3806 13.2302 13.3091 15.3094 12.8242 16.2629Z", fill: "#328777" }),
            React.createElement("path", { d: "M2.37574 13.4993L1.72119 12.0811L11.4666 7.71745L12.2545 9.03867L2.37574 13.4993Z", fill: "#9EA7D9" }),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M15.2287 5.9258C15.3631 5.87618 15.5052 5.85078 15.6485 5.85078H21.8788C22.5482 5.85078 23.0909 6.39347 23.0909 7.06291C23.0909 7.73234 22.5482 8.27503 21.8788 8.27503H15.8651L11.8986 9.7394C11.2706 9.97125 10.5736 9.65011 10.3417 9.0221C10.1099 8.3941 10.431 7.69705 11.059 7.4652L15.2287 5.9258Z", fill: "#4BCDB2" }),
            React.createElement("circle", { cx: "25.5998", cy: "5.07503", r: "2.4", fill: "#FF9478" }),
            React.createElement("circle", { cx: "23.9029", cy: "1.82655", r: "0.824242", fill: "#FF9533" }),
            React.createElement("path", { d: "M27.8302 4.09321C27.8302 4.09321 26.7635 4.2023 25.4908 4.75988C24.2181 5.31745 23.2847 5.85079 23.2847 5.85079C22.7968 4.61838 23.0099 2.42357 24.2423 1.93564C25.7817 1.32617 27.3422 2.8608 27.8302 4.09321Z", fill: "#FFC633" }))));
};
