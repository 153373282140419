import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { StatusScreen } from '~molecules';
import success from '~icons/statusIcons/success.svg';
export var RegisterSuccess = function (_a) {
    var _b;
    var match = _a.match;
    var t = useTranslation('registration').t;
    var tc = useTranslation('common').t;
    var root = match.url;
    var location = useLocation();
    var subheader = t('status.link_was_sent', {
        email: (_b = location === null || location === void 0 ? void 0 : location.state) === null || _b === void 0 ? void 0 : _b.email,
    });
    return (React.createElement(StatusScreen, { header: t('status.created'), subheader: subheader, logo: success, target: "".concat(root, "/email"), buttonLabel: tc('buttons.resend_email') }));
};
