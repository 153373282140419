import makeStyles from '@material-ui/core/styles/makeStyles';
var useStyles = makeStyles(function (theme) {
    var _a;
    return ({
        root: (_a = {
                position: 'relative',
                width: '48px',
                height: '48px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: '4px',
                backgroundColor: '#606060',
                borderRadius: '4px',
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '16px',
                color: '#ffffff',
                outline: 'none',
                '&:last-child': {
                    marginRight: 0,
                }
            },
            _a[theme.breakpoints.up('lg')] = {
                width: '44px',
                height: '44px',
            },
            _a[theme.breakpoints.down(350)] = {
                maxWidth: 48,
                maxHeight: 48,
            },
            _a),
        root_big: {
            width: '68px',
            margin: '0 0 0 4px',
        },
        active: {
            color: '#212121',
            background: 'linear-gradient(180deg, #FFFFFF 0%, #F6F6F6 100%)',
            border: '1px solid #DADADA',
            boxSizing: 'border-box',
            '& $title': {
                color: '#8E8E8E',
                opacity: 0.8,
            },
        },
        widget: {
            borderRadius: 8,
            border: '2px solid #565656',
            background: '#2D2D2E',
            '& $score': {
                fontSize: 12,
                fontWeight: 400,
            },
        },
        purchased: {
            border: '2px solid #1B66B9',
            '& $score': {
                marginTop: 3,
            },
        },
        ticketValue: {
            position: 'absolute',
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            padding: '0 10px',
            fontSize: 11,
            fontWeight: 500,
            borderRadius: '0 0 3px 3px',
            background: 'linear-gradient(90deg, #211A92 0%, #1B66B9 0.01%, #2C479A 100%)',
        },
        triangleIncrease: {
            top: '2px',
            right: '2px',
            position: 'absolute',
        },
        triangleDecrease: {
            bottom: '2px',
            left: '2px',
            position: 'absolute',
        },
        content: {
            display: 'flex',
            height: '100%',
            padding: '6px 0px 8px 0px',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'space-between',
            boxSizing: 'border-box',
        },
        title: {
            color: '#F2F2F2',
            opacity: 0.6,
            fontSize: '12px',
            lineHeight: '12px',
            fontWeight: 400,
        },
        score: {
            fontSize: '14px',
            lineHeight: '14px',
            fontWeight: 500,
        },
    });
}, { name: 'OddPlateView' });
export { useStyles };
