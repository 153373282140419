var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createTheme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { ZIndex } from '~constants/view/ZIndex';
import { getOverrides } from './overrides';
import { cssVariables } from './variables';
import { common } from './commonAppStyles';
export var ratio = function () {
    var rat = 1;
    var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    if (window.devicePixelRatio >= 1 &&
        window.devicePixelRatio <= 1.75 &&
        !isFirefox) {
        rat = window.devicePixelRatio;
    }
    return rat;
};
var breakpoints = {
    values: {
        xs: 0 / ratio(),
        sm: 600 / ratio(),
        md: 960 / ratio(),
        lg: 1366 / ratio(),
        xl: 1920 / ratio(),
    },
};
var themeBreakpoints = createBreakpoints(breakpoints);
var desktopToMobileBreakpoints = {
    values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1920,
        xl: 1920,
    },
};
var desktopToMobileThemeBreakpoints = createBreakpoints(desktopToMobileBreakpoints);
var createThemeConfig = function (breakpoint) {
    return ({
        palette: {
            type: 'dark',
        },
        props: {
            MuiDrawer: {
                ModalProps: {
                    style: {
                        zIndex: 9999,
                    },
                },
            },
        },
        overrides: getOverrides(breakpoint),
        breakpoints: breakpoints,
        variables: __assign({}, cssVariables),
        common: __assign({}, common),
        zIndex: {
            tooltip: ZIndex.TOOLTIP,
        },
    });
};
var defaultConfig = createThemeConfig(themeBreakpoints);
export var defaultTheme = createTheme(defaultConfig);
var desktopToMobileConfig = createThemeConfig(desktopToMobileThemeBreakpoints);
export var desktopToMobileTheme = createTheme(desktopToMobileConfig);
