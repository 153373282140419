import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var ArtifactIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, viewBox: "0 0 28 28" },
        React.createElement("svg", { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M8.44444 2H0L8.88889 17.5556L12 14.8889L8.44444 2Z", fill: "#FCE45B" }),
            React.createElement("path", { d: "M10.6667 5.55556L9.77778 2H28L24 9.11111L10.6667 5.55556Z", fill: "#FCE45B" }),
            React.createElement("path", { d: "M12.8889 14L11.1111 6.88889L18.2222 9.11111L12.8889 14Z", fill: "#FCE45B" }),
            React.createElement("path", { d: "M23.1111 10.4444L19.5556 9.55556L9.77778 18.8889L14.2222 26.4444L23.1111 10.4444Z", fill: "#FCE45B" }))));
};
