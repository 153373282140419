// TODO: fix max lines issue
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable max-lines */
import * as React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import MuiDrawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ClickAwayListener } from '@material-ui/core';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { LOGIN_PRESS, LOGOUT_PRESS } from '~constants/cpa-message-codes';
import { isAuthenticatedSelector, isCookieAcceptedSelector, logout, useAccessTokenSelector, } from '~slices/auth';
import { changeView } from '~slices/options';
import { useAppDispatch } from '~store/configureStore';
import { PLATFORMS } from '~slices/teasers';
import { changeHintState } from '~slices/hints';
import { MODALS, closeModal, openModal } from '~modals';
import { useIsMobileDevise, usePlatofrmType, useStructuredSelector, } from '~hooks';
import { getTestId } from '~utils/getTestId';
import { getPathWithLanguageCode } from '~utils/languageInURL/getPathWithLanguageCode';
import { isBetCheckProject } from '~utils/constants/betCheckConstants';
import { PaymentsURL } from '~pages/payments/payments.routes';
import userIcon from '~icons/userCircle.svg';
import { parseJwt } from '~utils/parseJwt';
import { resetGames } from '~slices/gameTypes';
import { ListItem } from './components/ListItem';
import { Languages } from './Languages';
var useStyles = makeStyles({
    root: {
        marginTop: '48px',
    },
    backdrop: {
        marginTop: '48px',
    },
    paper: {
        marginTop: '48px',
        width: '267px',
        boxShadow: '0px 5px 30px rgba(0, 0, 0, 0.05)',
        background: 'radial-gradient(102.51% 102.5% at 20.97% 0%, #303038 0%, #1B1B22 100%);',
    },
    list: {
        padding: '0 16px 0 15px',
    },
    lockLogo: {
        top: '12px',
        left: '6px',
        position: 'absolute',
        fontSize: '8px !important',
        bottom: '11px',
        textShadow: '-3px 1px 2px #000',
    },
    listItem: {
        height: '48px',
        margin: '10px 0px 10px 10px',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        outline: 'none',
        padding: '8px 0',
        fontWeight: 'normal',
        border: 'none',
        backgroundColor: 'transparent',
        '& i': {
            fontSize: '16px',
        },
    },
    title: {
        fontSize: '14px',
        marginLeft: '27px',
    },
    icon: {
        width: '16px',
        fontSize: '16px',
    },
    dollarIcon: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        height: '12px',
        width: '12px',
        fontSize: '12px !important',
        border: '2px solid #FFFFFF',
        borderRadius: '100%',
        fontWeight: 400,
    },
    blueBorder: {
        border: '2px solid #2AA1F7',
        color: '#2AA1F7',
    },
    userCard: {
        width: '200px',
        display: 'flex',
        alignItems: 'center',
        margin: '20px 0 0 10px',
        padding: '10px',
        border: '1px solid #FFF',
        borderRadius: '4px',
    },
    mainIcon: {
        width: '22px',
        height: '22px',
        marginRight: '10px',
    },
    helloLabel: {
        width: '100%',
        maxWidth: '160px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '18px',
        color: '#FFFFFF',
    },
}, { name: 'Drawer' });
export var Drawer = function (_a) {
    var open = _a.open, onClose = _a.onClose, onShouldRedirectOptionsChange = _a.onShouldRedirectOptionsChange;
    var classes = useStyles();
    var _b = useStructuredSelector({
        isAuthenticated: isAuthenticatedSelector,
        cookieAccepted: isCookieAcceptedSelector,
        token: useAccessTokenSelector,
    }), isAuthenticated = _b.isAuthenticated, cookieAccepted = _b.cookieAccepted, token = _b.token;
    var isMobile = useIsMobileDevise();
    var dispatch = useAppDispatch();
    var platform = usePlatofrmType();
    var location = useLocation();
    var match = useRouteMatch();
    var isBetCheck = isBetCheckProject;
    var tokenData = isAuthenticated ? parseJwt(token || '') : '';
    var userName = tokenData === null || tokenData === void 0 ? void 0 : tokenData.user_name;
    var t = useTranslation('header').t;
    var _c = React.useState(false), openLanguage = _c[0], setOpenLanguage = _c[1];
    var pathToRedirect = 'ranking-list';
    var logoutPath = platform === PLATFORMS.CROSS_PLATFORM_MOBILE
        ? "/".concat(pathToRedirect)
        : '/start';
    var openLoginModal = function () {
        if (cookieAccepted) {
            dispatch(openModal({
                modalName: MODALS.LOGIN_MODAL,
                modalProps: {
                    isOpen: true,
                    onClose: function () {
                        onShouldRedirectOptionsChange(false);
                        dispatch(closeModal({ key: MODALS.LOGIN_MODAL }));
                    },
                    onSubmit: function () {
                        dispatch(closeModal({ key: MODALS.LOGIN_MODAL }));
                        dispatch(push(getPathWithLanguageCode("".concat(match === null || match === void 0 ? void 0 : match.url).concat(pathToRedirect))));
                    },
                },
            }));
            onClose();
        }
        else if (platform === PLATFORMS.CROSS_PLATFORM_MOBILE) {
            onShouldRedirectOptionsChange(false);
            // @ts-expect-error
            window.ReactNativeWebView.postMessage(JSON.stringify({
                code: LOGIN_PRESS,
                target: "/".concat(pathToRedirect),
            }));
        }
        else {
            onShouldRedirectOptionsChange(false);
            dispatch(changeHintState({ isCookiesConsentHintEnabled: true }));
        }
    };
    var toggleLanguage = function () {
        setOpenLanguage(!openLanguage);
    };
    var closeDrawer = function () {
        onClose();
        setOpenLanguage(false);
    };
    var onLogout = function () {
        dispatch(logout());
        dispatch(resetGames());
        if (platform === PLATFORMS.CROSS_PLATFORM_MOBILE) {
            // @ts-expect-error
            window.ReactNativeWebView.postMessage(JSON.stringify({
                code: LOGOUT_PRESS,
            }));
        }
        onClose();
    };
    var changeToDesktop = function () {
        if (isMobile) {
            // @ts-expect-error
            document.getElementsByTagName('meta').namedItem('viewport').content =
                'width=1920, minimum-scale';
        }
        else {
            dispatch(changeView('default'));
        }
        onClose();
    };
    var handleOptionsClick = function () {
        if (!isAuthenticated) {
            onShouldRedirectOptionsChange(true);
            openLoginModal();
        }
        onClose();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(MuiDrawer, { anchor: "left", open: open, onClose: closeDrawer, classes: { root: classes.root, paper: classes.paper } },
            isAuthenticated && userName ? (React.createElement("div", { className: classes.userCard },
                React.createElement("img", { src: userIcon, alt: "user icon", className: classes.mainIcon }),
                React.createElement("span", { className: classes.helloLabel },
                    t('hello'),
                    ",\u00A0",
                    React.createElement("b", null, userName)))) : null,
            React.createElement("div", { role: "presentation", onKeyDown: onClose, className: "drawer" }, openLanguage ? (React.createElement(Languages, { onCloseLanguages: toggleLanguage, onCloseDrawer: onClose })) : (React.createElement(ClickAwayListener, { onClickAway: onClose },
                React.createElement(List, { classes: { root: classes.list } },
                    React.createElement(ListItem, { to: getPathWithLanguageCode('/my-tips'), label: "my_tickets", requiredAuth: true, isAuthenticated: isAuthenticated, icon: React.createElement("i", { className: "far fa-receipt" }), onClick: onClose }),
                    React.createElement(ListItem, { to: getPathWithLanguageCode('/options'), label: "options", disableRedirect: !isAuthenticated, icon: React.createElement("i", { className: "far fa-cog" }), onClick: handleOptionsClick }),
                    !isAuthenticated && (React.createElement(React.Fragment, null,
                        React.createElement(ListItem, { label: t('login'), to: location.pathname, selected: false, icon: React.createElement(React.Fragment, null,
                                React.createElement("i", { className: "far fa-user" }),
                                React.createElement("span", { className: classes.lockLogo },
                                    React.createElement("i", { className: classNames('fas fa-lock-alt', classes.lockLogo) }))), onClick: openLoginModal }))),
                    React.createElement(ListItem, { to: getPathWithLanguageCode('/my-account'), label: "my_account", requiredAuth: true, isAuthenticated: isAuthenticated, icon: React.createElement("i", { className: "far fa-user-circle" }), onClick: onClose, dataTestId: "BTN_USER_OPTIONS" }),
                    isAuthenticated && !isBetCheckProject && (React.createElement(ListItem, { to: getPathWithLanguageCode("/".concat(PaymentsURL.TOP_IT_UP)), label: "payments", selected: location.pathname.includes("/".concat(PaymentsURL.TOP_IT_UP, "/")), requiredAuth: true, isAuthenticated: isAuthenticated, icon: React.createElement("i", { className: classNames('fa fa-dollar-sign', classes.dollarIcon, location.pathname.includes("/".concat(PaymentsURL.TOP_IT_UP, "/")) && classes.blueBorder) }), onClick: onClose })),
                    isBetCheck && (React.createElement(ListItem, { to: getPathWithLanguageCode('/bp-list'), label: "deposit_withdraw_bonus", requiredAuth: true, isAuthenticated: isAuthenticated, icon: React.createElement("i", { className: "far fa-chart-bar" }), onClick: onClose })),
                    React.createElement(ListItem, { to: getPathWithLanguageCode('/about'), label: "about_beton", icon: React.createElement("i", { className: "fas fa-info-circle" }), onClick: onClose }),
                    React.createElement(ListItem, { label: "language", icon: React.createElement("i", { className: "fal fa-globe" }), onClick: toggleLanguage, "data-testid": getTestId('BTN_LANGUAGE'), to: location.pathname, selected: false }),
                    platform !== PLATFORMS.CROSS_PLATFORM_MOBILE && (React.createElement(ListItem, { to: getPathWithLanguageCode('/home'), isAuthenticated: isAuthenticated, icon: React.createElement("i", { className: "far fa-toggle-on fa-rotate-180" }), label: "desktop_view", onClick: changeToDesktop })),
                    isAuthenticated && (React.createElement(ListItem, { label: "logout", to: getPathWithLanguageCode(logoutPath), icon: React.createElement("i", { className: "far fa-sign-out" }), onClick: onLogout, "data-testid": getTestId('BTN_LOGOUT') })))))))));
};
