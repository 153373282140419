var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useMediaQuery } from '@material-ui/core';
import { AnimationDuration } from '~constants/view/AnimationDuration';
import { ZIndex } from '~constants/view/ZIndex';
import { useStyles } from './TournamentsDropDown.styles';
var noop = function () { return undefined; };
var TournamentsDropDown = function (_a) {
    var _b = _a.open, open = _b === void 0 ? false : _b, anchorEl = _a.anchorEl, children = _a.children, _c = _a.onClose, onClose = _c === void 0 ? noop : _c;
    var classes = useStyles();
    var _d = useState(null), arrowRef = _d[0], setArrowRef = _d[1];
    var isWiderThan420 = useMediaQuery("@media (min-width: ".concat(420, "px)"), {
        noSsr: true,
    });
    var isWiderThan390 = useMediaQuery("@media (min-width: ".concat(390, "px)"), {
        noSsr: true,
    });
    var getOffset = function () {
        if (!isWiderThan390) {
            return '92,5';
        }
        if (!isWiderThan420) {
            return '90,5';
        }
        return '76,5';
    };
    return (React.createElement(React.Fragment, null, React.createElement(Popper, { open: open, style: {
            zIndex: ZIndex.TOURNAMENTS_DROP_DOWN,
        }, anchorEl: anchorEl, transition: true, placement: "bottom-end", className: classes.popper, modifiers: {
            offset: {
                enabled: true,
                offset: getOffset(),
            },
            arrow: {
                enabled: true,
                element: arrowRef,
            },
            preventOverflow: {
                enabled: true,
                padding: 0,
                boundariesElement: 'scrollParent',
            },
            flip: {
                enabled: false,
            },
        } }, function (_a) {
        var TransitionProps = _a.TransitionProps;
        return (React.createElement(Grow, __assign({}, TransitionProps, { timeout: AnimationDuration.SHORT.ms, style: {
                transformOrigin: 'right top',
            } }),
            React.createElement("div", null,
                React.createElement(ClickAwayListener, { onClickAway: onClose },
                    React.createElement(Paper, { className: classes.content },
                        React.createElement("div", { ref: setArrowRef, className: classes.arrow }),
                        children)))));
    })));
};
export { TournamentsDropDown };
