var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { push } from 'connected-react-router';
import React, { useCallback, useRef } from 'react';
import classNames from 'classnames';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { API } from '~api';
import { SHOW_INTERSTITIAL_ADD } from '~constants/cpa-message-codes';
import { MODALS, closeModal, openModal } from '~modals';
import { deleteFullBetslip, updateWager } from '~slices/betslip';
import { BETSLIP_SOCKET_NAME } from '~slices/events';
import { useActiveGameSelector, useGameTypesDataSelector, useSelectedGameDataSelector, useSelectedGroupSelector, } from '~slices/gameTypes';
import { PLATFORMS } from '~slices/teasers';
import { selectActiveTournamentInfo } from '~slices/tournamentsInfo';
import { useAppDispatch } from '~store';
import { OddPlateV2 } from '~molecules';
import { deleteOdd, resetShortcutBetlsip, selectOddWithValidation, } from '~slices/shortcut';
import { usePlatofrmType, useStructuredSelector } from '~hooks';
import { OddWidth } from '~constants/view/OddWidth';
import { LayoutOptions } from '~constants/view/LayoutOptions';
import { selectIdsMap } from '~slices/idsMap';
import { isOddActive } from '~utils/events';
import { getRoundsData } from '~utils/getRoundsData';
import { useRowStyles } from '../Sidebets.styles';
import { RowWrapper } from './RowWrapper';
import { normalizeOneLineOc } from './normalizeOneLineOc';
import { getColumnsCount } from './getColumnsCount';
import { getOddWidth } from './getOddWidth';
import { normalizeMatrixOc } from './normalizeMatrixOc';
import { sortOutcomesByRows } from './sortOutcomesByRows';
var MemoizedOddPlateV2 = React.memo(OddPlateV2);
var Row = function (_a) {
    var oc = _a.oc, mk = _a.mk, eventId = _a.eventId, _b = _a.rowName, rowName = _b === void 0 ? '' : _b, tournamentId = _a.tournamentId, marketId = _a.marketId, roundIndex = _a.roundIndex, ticket = _a.ticket, prevPath = _a.prevPath, eventsType = _a.eventsType, optionName = _a.optionName, defaultMarket = _a.defaultMarket, isDisabled = _a.isDisabled, isMakeTipButton = _a.isMakeTipButton, setIsLoading = _a.setIsLoading;
    var classes = useRowStyles();
    var dispatch = useAppDispatch();
    var platform = usePlatofrmType();
    var columnsCount = getColumnsCount(optionName);
    var t = useTranslation('tip-game').t;
    var _c = useStructuredSelector({
        idsMap: selectIdsMap,
        activeGame: useActiveGameSelector,
        selectedGame: useSelectedGameDataSelector,
        selectedGroup: useSelectedGroupSelector,
        games: useGameTypesDataSelector,
        tournament: selectActiveTournamentInfo,
    }), idsMap = _c.idsMap, activeGame = _c.activeGame, selectedGame = _c.selectedGame, selectedGroup = _c.selectedGroup, games = _c.games, tournament = _c.tournament;
    var isCPA = platform === PLATFORMS.CROSS_PLATFORM_MOBILE;
    var oddData = {
        dm: defaultMarket,
        lc: i18next.language,
        ei: eventId,
        mi: mk,
        ti: tournamentId,
        eventsType: eventsType,
        bmi: marketId,
    };
    var oddDataRef = useRef(oddData);
    oddDataRef.current = oddData;
    var handleSelect = useCallback(function (obid) { return __awaiter(void 0, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, dispatch(selectOddWithValidation(__assign(__assign({}, oddDataRef.current), { obid: obid })))];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    console.error(e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, []);
    var handleRemove = useCallback(function (obid) {
        try {
            dispatch(deleteOdd(__assign(__assign({}, oddDataRef.current), { obid: obid })));
        }
        catch (e) {
            console.error(e);
        }
    }, []);
    var goBackFromSideBets = function () {
        dispatch(push({
            pathname: prevPath,
            state: {
                round: roundIndex,
            },
        }));
    };
    var playVideo = function () {
        try {
            // @ts-expect-error
            window.ReactNativeWebView.postMessage(JSON.stringify({ code: SHOW_INTERSTITIAL_ADD }));
        }
        catch (e) {
            console.error(e);
        }
    };
    var deletePurchasedTicket = function () { return __awaiter(void 0, void 0, void 0, function () {
        var currentGame, _a, roundsArray, cupRoundsArray, rounds, cupRounds, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    currentGame = games === null || games === void 0 ? void 0 : games.find(function (game) {
                        return selectedGroup
                            ? game.groupName === selectedGroup
                            : game.name === selectedGame || game.name === activeGame;
                    });
                    if (!(ticket === null || ticket === void 0 ? void 0 : ticket.mtsTicketId) || !currentGame) {
                        return [2 /*return*/];
                    }
                    playVideo();
                    _a = getRoundsData(tournament, roundIndex || 0), roundsArray = _a.rounds, cupRoundsArray = _a.cupRounds;
                    rounds = roundsArray === null || roundsArray === void 0 ? void 0 : roundsArray.join(',');
                    cupRounds = cupRoundsArray === null || cupRoundsArray === void 0 ? void 0 : cupRoundsArray.join(',');
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, API.deletePurchasedTicket({
                            gameUrl: currentGame.url,
                            mtsTicketId: ticket.mtsTicketId,
                            groupName: currentGame.groupName,
                            gameName: currentGame.name,
                            rounds: rounds,
                            cupRounds: cupRounds,
                        })];
                case 2:
                    _c.sent();
                    return [3 /*break*/, 4];
                case 3:
                    _b = _c.sent();
                    dispatch(openModal({
                        modalName: MODALS.ERROR_MODAL,
                        modalProps: {
                            title: t('easy-tip-message.delete_ticket_error'),
                        },
                    }));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var purchaseTicket = function (obid, value) { return __awaiter(void 0, void 0, void 0, function () {
        var game, resetBetslip, _a, roundsArray, cupRoundsArray, rounds, cupRounds, app_version, lang, gameUrl, maxOdds, gameName, groupName;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    game = games === null || games === void 0 ? void 0 : games.find(function (item) {
                        return selectedGroup
                            ? item.groupName === selectedGroup
                            : item.name === selectedGame || item.name === activeGame;
                    });
                    if (!game)
                        return [2 /*return*/];
                    return [4 /*yield*/, handleSelect(obid)];
                case 1:
                    _b.sent();
                    return [4 /*yield*/, dispatch(updateWager({
                            wager: game.startCredits,
                            wagerType: 'T',
                            eventsType: BETSLIP_SOCKET_NAME,
                        }))];
                case 2:
                    _b.sent();
                    resetBetslip = function () {
                        dispatch(deleteFullBetslip({ eventsType: BETSLIP_SOCKET_NAME })).then(function () { return dispatch(resetShortcutBetlsip()); });
                    };
                    _a = getRoundsData(tournament, roundIndex || 0), roundsArray = _a.rounds, cupRoundsArray = _a.cupRounds;
                    rounds = roundsArray === null || roundsArray === void 0 ? void 0 : roundsArray.join(',');
                    cupRounds = cupRoundsArray === null || cupRoundsArray === void 0 ? void 0 : cupRoundsArray.join(',');
                    app_version = process.env.REACT_APP_VERSION || '';
                    lang = i18next.language;
                    gameUrl = game.url;
                    maxOdds = value;
                    gameName = game.name;
                    groupName = game.groupName;
                    return [4 /*yield*/, API.postGameTickets({
                            app_version: app_version,
                            lang: lang,
                            gameUrl: gameUrl,
                            maxOdds: maxOdds,
                            gameName: gameName,
                            groupName: groupName,
                            rounds: rounds,
                            cupRounds: cupRounds,
                        })
                            .catch(function () {
                            dispatch(openModal({
                                modalName: MODALS.PURCHASE_TICKETS_RESULT_MODAL,
                                modalProps: {
                                    result: [{ success: false, game: game }],
                                    errorDescription: t('easy-plus-message.purchase_ticket_error'),
                                },
                            }));
                        })
                            .finally(function () {
                            resetBetslip();
                            goBackFromSideBets();
                        })];
                case 3:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var onUpdateTicket = function (obid, value) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (setIsLoading)
                        setIsLoading(true);
                    return [4 /*yield*/, deletePurchasedTicket()];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, purchaseTicket(obid, value)];
                case 2:
                    _a.sent();
                    if (setIsLoading)
                        setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var onChangeTicket = function (obid, value, isNoVideo) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!isCPA)
                        return [2 /*return*/];
                    if (!isNoVideo) return [3 /*break*/, 2];
                    if (setIsLoading)
                        setIsLoading(true);
                    return [4 /*yield*/, purchaseTicket(obid, value)];
                case 1:
                    _a.sent();
                    if (setIsLoading)
                        setIsLoading(false);
                    return [2 /*return*/];
                case 2:
                    dispatch(openModal({
                        modalName: MODALS.DELETE_PURCHASED_TICKET_MODAL,
                        modalProps: {
                            handleConfirm: function () {
                                dispatch(closeModal({ key: MODALS.DELETE_PURCHASED_TICKET_MODAL }));
                                onUpdateTicket(obid, value);
                            },
                        },
                    }));
                    return [2 /*return*/];
            }
        });
    }); };
    var renderOdd = function (_a) {
        var _b;
        var outcome = _a.outcome, _c = _a.oddWidth, oddWidth = _c === void 0 ? OddWidth.M : _c, _d = _a.titled, titled = _d === void 0 ? false : _d, _e = _a.isShortName, isShortName = _e === void 0 ? false : _e;
        var nm = outcome.nm, snm = outcome.snm;
        var title = isShortName ? snm || nm || '' : nm || snm || '';
        var isPurchased = outcome.purchased;
        return (React.createElement(MemoizedOddPlateV2, { value: (_b = outcome === null || outcome === void 0 ? void 0 : outcome.od) === null || _b === void 0 ? void 0 : _b.vl, title: titled ? title : '', oddWidth: oddWidth, key: outcome.id, isActive: isOddActive(outcome), isPurchased: isPurchased, isWidget: true, isDisabled: isDisabled, isMakeTipButton: isMakeTipButton, ticketValue: ticket === null || ticket === void 0 ? void 0 : ticket.value, onSelect: handleSelect, onRemove: handleRemove, onChangeTicket: onChangeTicket, id: outcome.id, obid: outcome.bid, mk: mk, eventId: eventId }));
    };
    var renderEmptyOdd = function (key, oddWidth) {
        if (oddWidth === void 0) { oddWidth = OddWidth.M; }
        return React.createElement(MemoizedOddPlateV2, { key: key, oddWidth: oddWidth, isWidget: true });
    };
    var renderRow = function (_a) {
        var outcomes = _a.outcomes, _b = _a.name, name = _b === void 0 ? '' : _b, _c = _a.oddWidth, oddWidth = _c === void 0 ? OddWidth.M : _c, _d = _a.titledOdds, titledOdds = _d === void 0 ? false : _d, _e = _a.isShortName, isShortName = _e === void 0 ? false : _e;
        var firstOutcome = outcomes[0];
        var id = typeof firstOutcome === 'string' ? firstOutcome : firstOutcome.id;
        return (React.createElement(RowWrapper, { key: "".concat(optionName, "_").concat(marketId, "_").concat(id) },
            React.createElement("span", { className: classes.rowName }, name),
            React.createElement("div", { className: classes.rows }, outcomes.map(function (outcome) {
                return typeof outcome === 'string'
                    ? renderEmptyOdd(outcome, oddWidth)
                    : renderOdd({
                        outcome: outcome,
                        oddWidth: oddWidth,
                        titled: titledOdds,
                        isShortName: isShortName,
                    });
            }))));
    };
    var renderRowWithOddName = function (_a) {
        var outcome = _a.outcome, _b = _a.name, name = _b === void 0 ? '' : _b;
        return (React.createElement(RowWrapper, { key: "".concat(optionName, "_").concat(marketId, "_").concat(outcome.id) },
            React.createElement("div", { className: classNames(classes.rowField), key: outcome.id },
                React.createElement("span", { className: classNames(classes.rowName) }, name),
                React.createElement("div", { className: classNames(classes.oddNames) },
                    React.createElement("div", { className: classNames(classes.oddName), key: outcome.id }, outcome.nm)),
                React.createElement("div", { className: classes.rows }, renderOdd({ outcome: outcome })))));
    };
    switch (optionName) {
        case LayoutOptions.OPTION_1:
        case LayoutOptions.OPTION_2:
        case LayoutOptions.OPTION_3:
        case LayoutOptions.OPTION_4:
        case LayoutOptions.OPTION_10:
        case LayoutOptions.OPTION_11: {
            var outcomes = normalizeOneLineOc(oc, optionName, columnsCount, idsMap);
            var oddWidth = getOddWidth(optionName);
            var name_1 = rowName;
            return renderRow({ outcomes: outcomes, name: name_1, oddWidth: oddWidth, isShortName: true });
        }
        case LayoutOptions.OPTION_5: {
            var matrix = normalizeMatrixOc(oc, optionName, idsMap);
            var titledOdds_1 = true;
            return (React.createElement(React.Fragment, null, matrix.map(function (outcomes, i) {
                var name = i === 0 ? rowName : '';
                return renderRow({ outcomes: outcomes, name: name, titledOdds: titledOdds_1, isShortName: true });
            })));
        }
        case LayoutOptions.OPTION_6: {
            return (React.createElement(React.Fragment, null, sortOutcomesByRows(oc, optionName, idsMap).map(function (outcome, i) {
                var name = i === 0 ? rowName : '';
                return renderRowWithOddName({ outcome: outcome, name: name });
            })));
        }
        case LayoutOptions.OPTION_7: {
            return renderRow({ outcomes: oc.slice(0, 1), name: rowName });
        }
        default: {
            return null;
        }
    }
};
export { Row };
