import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var ShotgunIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0)" },
            React.createElement("path", { d: "M28.0001 9.85434H19.1548V8.21365H26.3594V7.21021H28.0001V9.85434Z", fill: "#08475E" }),
            React.createElement("path", { d: "M28.0001 9.85434H19.1548V8.21365H26.3594V7.21021H28.0001V9.85434Z", fill: "#05303D" }),
            React.createElement("path", { d: "M9.79443 7.21021H11.4351V9.03399H9.79443V7.21021Z", fill: "#08475E" }),
            React.createElement("path", { d: "M16.3124 20.79H7.88133C5.86152 20.79 4.21826 19.1467 4.21826 17.1269H5.85895C5.85895 18.2421 6.7662 19.1493 7.88133 19.1493H16.3124C17.4275 19.1493 18.3347 18.2421 18.3347 17.1269V13.0957H19.9754V17.1269C19.9754 19.1467 18.3322 20.79 16.3124 20.79Z", fill: "#ECF9FB" }),
            React.createElement("path", { d: "M18.3345 13.0957V17.1269C18.3345 18.2421 17.4272 19.1493 16.3121 19.1493H14.001V20.79H16.3121C18.332 20.79 19.9752 19.1467 19.9752 17.1269V13.0957H18.3345Z", fill: "#D8F1FB" }),
            React.createElement("path", { d: "M14.9033 16.7276H9.14355V15.087H13.2626V13.0957H14.9033V16.7276Z", fill: "#08475E" }),
            React.createElement("path", { d: "M14.001 13.0957H14.9035V16.7276H14.001V13.0957Z", fill: "#05303D" }),
            React.createElement("path", { d: "M0 10.3887V18.7835L5.03818 17.8027V10.3887H0Z", fill: "#9DDFF6" }),
            React.createElement("path", { d: "M8.04357 8.21387V9.03421C8.04357 9.78127 7.43581 10.3891 6.68869 10.3891H3.39746V18.1225L7.98276 17.2298C9.72233 16.8912 11.1279 15.5909 11.6248 13.9161H19.9751V8.21387H8.04357Z", fill: "#5E69E2" }),
            React.createElement("path", { d: "M14.001 8.21387H19.9752V13.9161H14.001V8.21387Z", fill: "#424FDD" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0" },
                React.createElement("rect", { width: "28", height: "28", fill: "white" })))));
};
