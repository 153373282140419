import { useCallback, useState } from 'react';
import throttle from 'lodash.throttle';
import { useWindowResize } from './useWindowResize';
var useViewportHeight = function () {
    var _a = useState(0), vh = _a[0], setVh = _a[1];
    var handleResize = useCallback(throttle(function () {
        var height = getComputedStyle(document.documentElement).height;
        setVh(Number(height.replace('px', '')) || 0);
    }, 32, { trailing: true }), []);
    useWindowResize(handleResize);
    return vh;
};
export { useViewportHeight };
