import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var SkiIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M27.9774 20.0571L14.1959 0.215947C13.996 -0.0719823 13.5701 -0.0719823 13.3701 0.215947L0.0224609 19.4956V27.9999H13.7476H27.9774V20.0571Z", fill: "#836948" }),
        React.createElement("path", { d: "M27.9774 20.0572L19.9724 8.53239C19.5028 8.37577 18.9645 8.48301 18.5906 8.85685C18.0637 9.38377 17.2093 9.38377 16.6824 8.85685C16.1555 8.32994 15.3011 8.32994 14.7741 8.85685C14.2472 9.38377 13.3928 9.38377 12.8659 8.85685C12.3389 8.32994 11.4846 8.32994 10.9576 8.85685C10.4307 9.38377 9.57629 9.38377 9.04933 8.85685C8.65607 8.4636 8.08054 8.36418 7.59481 8.55793L0.0224609 19.4956V27.9999H13.7476H27.9774V20.0572Z", fill: "#CCE4F7" }),
        React.createElement("path", { d: "M12.5442 5.43126L13.0758 4.89965C13.4279 4.54746 13.9988 4.54746 14.351 4.89965L14.8826 5.43126C15.2348 5.78345 15.8057 5.78345 16.1579 5.43126L17.1309 4.45821L14.1842 0.215947C13.9843 -0.0719823 13.5584 -0.0719823 13.3584 0.215947L10.3643 4.52652L11.2688 5.43121C11.6211 5.78334 12.192 5.78334 12.5442 5.43126Z", fill: "#ECE4DC" }),
        React.createElement("path", { d: "M14.1756 28.0001V23.0673C14.1756 22.831 13.984 22.6394 13.7477 22.6394C13.5114 22.6394 13.3198 22.831 13.3198 23.0673V28.0001H14.1756Z", fill: "#8EC1ED" }),
        React.createElement("path", { d: "M12.692 14.2387C12.4893 14.1172 12.2265 14.183 12.105 14.3857L12.0526 14.4728C11.8099 14.877 11.5078 15.3799 11.1651 16.1042C10.6624 17.1667 10.6817 18.0231 10.7003 18.8512C10.7243 19.9191 10.747 20.9277 9.53975 22.4308C7.25797 25.2717 6.91305 27.8387 6.89949 27.9466C6.89725 27.9643 6.89654 27.9818 6.89648 27.9991H7.75688C7.8178 27.6279 8.25208 25.4007 10.207 22.9667C11.6096 21.2204 11.581 19.9515 11.5559 18.832C11.5387 18.0698 11.5226 17.3498 11.9387 16.4703C12.2633 15.7844 12.5412 15.3217 12.7863 14.9136L12.839 14.8257C12.9605 14.623 12.8947 14.3602 12.692 14.2387Z", fill: "#8EC1ED" }),
        React.createElement("path", { d: "M11.3054 10.9586C11.0704 10.9359 10.8608 11.1072 10.8376 11.3423C10.6688 13.0511 10.0616 13.6416 8.9592 14.7134C8.5503 15.111 8.08683 15.5616 7.57523 16.1436C6.73255 17.1024 6.53868 18.0486 6.35121 18.9637C6.09878 20.1959 5.88074 21.2602 4.07009 22.3439C1.83234 23.6833 0.62971 25.3938 0.0224609 26.5599V27.9983H0.33489C0.521866 27.4355 1.51122 24.8728 4.50956 23.0782C6.64352 21.8009 6.93358 20.3849 7.18957 19.1355C7.36222 18.2927 7.52524 17.4968 8.21802 16.7087C8.70753 16.1518 9.15815 15.7136 9.55579 15.327C10.6786 14.2354 11.4898 13.4467 11.6893 11.4265C11.7124 11.1912 11.5406 10.9817 11.3054 10.9586Z", fill: "#8EC1ED" }),
        React.createElement("path", { d: "M20.6486 27.9998C20.6486 27.9822 20.6479 27.9644 20.6456 27.9465C20.632 27.8386 20.2871 25.2716 18.0054 22.4307C16.7981 20.9276 16.8208 19.919 16.8448 18.8512C16.8635 18.023 16.8827 17.1666 16.38 16.1042C16.0373 15.3799 15.7351 14.8769 15.4924 14.4727L15.4144 14.3425C15.2931 14.1396 15.0304 14.0734 14.8275 14.1947C14.6247 14.316 14.5586 14.5787 14.6798 14.7816L14.7588 14.9133C15.004 15.3215 15.2818 15.7842 15.6064 16.4702C16.0226 17.3497 16.0064 18.0697 15.9893 18.8319C15.9641 19.9514 15.9355 21.2203 17.3381 22.9666C19.2836 25.3887 19.7259 27.6277 19.7882 27.9999H20.6487V27.9998H20.6486Z", fill: "#8EC1ED" }),
        React.createElement("path", { d: "M27.9624 27.5516C27.6362 26.6491 26.5037 24.1565 23.4747 22.3437C21.6641 21.26 21.446 20.1958 21.1936 18.9635C21.0062 18.0484 20.8123 17.1022 19.9696 16.1434C19.458 15.5613 18.9945 15.1107 18.5857 14.7132C17.4833 13.6414 16.876 13.051 16.7073 11.3422C16.684 11.107 16.4743 10.9353 16.2394 10.9584C16.0043 10.9816 15.8324 11.1911 15.8556 11.4262C16.0551 13.4464 16.8663 14.2351 17.9891 15.3268C18.3867 15.7134 18.8374 16.1516 19.3268 16.7085C20.0196 17.4965 20.1826 18.2926 20.3553 19.1353C20.6112 20.3847 20.9013 21.8008 23.0352 23.078C26.0242 24.867 27.0167 27.419 27.2081 27.9926H27.9624L27.9624 27.5516Z", fill: "#8EC1ED" }),
        React.createElement("path", { d: "M4.62646 10.645L2.31898 9.28092C2.09159 9.14645 2.09159 8.81745 2.31898 8.68297L4.62646 7.31885V10.645Z", fill: "#3ABD64" }),
        React.createElement("path", { d: "M4.62646 10.645L2.31898 9.28092C2.09159 9.14645 2.09159 8.81745 2.31898 8.68297L4.62646 7.31885V10.645Z", fill: "#3ABD64" }),
        React.createElement("path", { d: "M22.8691 10.645L25.1766 9.28092C25.404 9.14645 25.404 8.81745 25.1766 8.68297L22.8691 7.31885V10.645Z", fill: "#3ABD64" }),
        React.createElement("path", { d: "M3.27376 19.0307L0.397642 17.3304C0.114197 17.1629 0.114197 16.7527 0.397642 16.5851L3.27376 14.8848V19.0307Z", fill: "#3ABD64" }),
        React.createElement("path", { d: "M24.8892 19.0308L27.7653 17.3304C28.0487 17.1629 28.0487 16.7527 27.7653 16.5851L24.8892 14.8848V19.0308Z", fill: "#3ABD64" }),
        React.createElement("path", { d: "M4.77162 14.3951C4.53532 14.3951 4.34375 14.2035 4.34375 13.9672V7.32265C4.34375 7.08635 4.53532 6.89478 4.77162 6.89478C5.00793 6.89478 5.1995 7.08635 5.1995 7.32265V13.9673C5.1995 14.2035 5.00793 14.3951 4.77162 14.3951Z", fill: "#2B8E4A" }),
        React.createElement("path", { d: "M22.8683 14.4963C22.632 14.4963 22.4404 14.3047 22.4404 14.0684V7.42372C22.4404 7.18742 22.632 6.99585 22.8683 6.99585C23.1046 6.99585 23.2962 7.18742 23.2962 7.42372V14.0684C23.2962 14.3047 23.1046 14.4963 22.8683 14.4963Z", fill: "#2B8E4A" }),
        React.createElement("path", { d: "M3.27358 23.7255C3.03727 23.7255 2.8457 23.534 2.8457 23.2977V15.0155C2.8457 14.7792 3.03727 14.5876 3.27358 14.5876C3.50988 14.5876 3.70145 14.7792 3.70145 15.0155V23.2977C3.70145 23.534 3.50988 23.7255 3.27358 23.7255Z", fill: "#2B8E4A" }),
        React.createElement("path", { d: "M24.8888 23.7255C24.6525 23.7255 24.4609 23.534 24.4609 23.2977V15.0155C24.4609 14.7792 24.6525 14.5876 24.8888 14.5876C25.1251 14.5876 25.3167 14.7792 25.3167 15.0155V23.2977C25.3167 23.534 25.1251 23.7255 24.8888 23.7255Z", fill: "#2B8E4A" })));
};
