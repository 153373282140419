import makeStyles from '@material-ui/core/styles/makeStyles';
var useStyles = makeStyles({
    container: {
        padding: '4px 8px',
        boxSizing: 'border-box',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        rowGap: '8px',
        overflowY: 'scroll',
        maxHeight: '100%',
    },
}, { name: 'ItemsList' });
export { useStyles };
