import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var ShuttleCockIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, viewBox: "0 0 28 28" },
        React.createElement("path", { d: "M15.8075 19.992L14.2803 18.4648L12.5059 19.8906L13.6928 21.0775L15.8075 19.992Z", fill: "#C6C5CA" }),
        React.createElement("path", { d: "M9.53651 13.7195L8.00931 12.1924L6.92383 14.3071L8.1108 15.494L9.53651 13.7195Z", fill: "#C6C5CA" }),
        React.createElement("path", { d: "M12.6721 16.8548L11.1465 15.3291L9.7207 17.1036L10.8977 18.2805L12.6721 16.8548Z", fill: "#C6C5CA" }),
        React.createElement("path", { d: "M12.5458 9.974L10.3003 7.72852L9.21484 9.84321L11.1201 11.7485L12.5458 9.974Z", fill: "#C6C5CA" }),
        React.createElement("path", { d: "M20.2729 17.7006L18.0273 15.4551L16.2529 16.8808L18.1582 18.786L20.2729 17.7006Z", fill: "#C6C5CA" }),
        React.createElement("path", { d: "M16.4181 13.8449L14.1562 11.583L12.7305 13.3575L14.6437 15.2706L16.4181 13.8449Z", fill: "#C6C5CA" }),
        React.createElement("path", { d: "M15.5561 6.2275L12.5923 3.26367L11.5068 5.37836L14.1304 8.00191L15.5561 6.2275Z", fill: "#C6C5CA" }),
        React.createElement("path", { d: "M20.164 10.835L17.1659 7.83691L15.7402 9.61133L18.3896 12.2607L20.164 10.835Z", fill: "#C6C5CA" }),
        React.createElement("path", { d: "M24.7363 15.4091L21.7725 12.4453L19.998 13.871L22.6216 16.4946L24.7363 15.4091Z", fill: "#C6C5CA" }),
        React.createElement("path", { d: "M26.7036 10.6198C25.7787 9.69496 24.5431 9.2662 23.3296 9.33233C23.3958 8.11888 22.967 6.88325 22.0421 5.95831C21.2058 5.12202 20.094 4.66147 18.9113 4.66147C18.8299 4.66147 18.7489 4.66413 18.6682 4.66849C18.7338 3.45577 18.3049 2.2211 17.3806 1.29678C16.5443 0.460547 15.4324 0 14.2497 0C13.0671 0 11.9552 0.460547 11.1189 1.29684L10.9934 1.42231L3.4082 16.1999C3.40854 16.1995 5.08922 17.8802 5.08922 17.8802L6.92309 14.3074L8.00858 12.1927L9.21468 9.843L10.3002 7.72831L11.5063 5.37863L12.5918 3.26394L12.836 2.78817C13.2288 2.44823 13.725 2.26285 14.2497 2.26285C14.828 2.26285 15.3716 2.48806 15.7805 2.8969C16.6245 3.74094 16.6245 5.11432 15.7805 5.95837L15.737 6.00187L15.5555 6.22776L14.1298 8.00217L12.5456 9.9738L11.1199 11.7482L9.53578 13.7199L8.11007 15.4943L5.70132 18.4922L7.31088 20.1018L9.71963 17.1039L11.1453 15.3295L12.7295 13.3578L14.1552 11.5834L15.7394 9.6118L17.1651 7.83738L17.4201 7.52002C17.8241 7.13556 18.3515 6.92444 18.9113 6.92449C19.4895 6.92449 20.0332 7.14965 20.442 7.55854C21.2731 8.38957 21.2859 9.73371 20.4805 10.5805L20.1632 10.8355L18.3888 12.2612L16.4172 13.8454L14.6427 15.2711L12.6711 16.8552L10.8967 18.2809L7.89872 20.6897L9.50822 22.2992L12.5062 19.8905L14.2806 18.4647L16.2523 16.8806L18.0267 15.4549L19.9983 13.8707L21.7728 12.445L21.9507 12.302L21.9987 12.2635L22.0421 12.2201C22.887 11.376 24.2597 11.3763 25.1035 12.2201C25.9096 13.0262 25.9459 14.3152 25.2121 15.1647L24.7365 15.4089L22.6218 16.4944L20.2722 17.7005L18.1575 18.786L15.8077 19.9921L13.693 21.0773L10.1203 22.9112L11.8009 24.5919C11.8006 24.5922 26.5781 17.007 26.5781 17.007L26.7036 16.8815C28.43 15.1552 28.43 12.3462 26.7036 10.6198Z", fill: "#E0E0E2" }),
        React.createElement("path", { d: "M10.1209 22.9111L9.50882 22.299L7.60537 20.3955L1.73633 26.2646C4.05004 28.5783 7.81502 28.5783 10.1291 26.2642L11.8013 24.5921C11.8016 24.5917 10.1209 22.9111 10.1209 22.9111Z", fill: "#3D9AE2" }),
        React.createElement("path", { d: "M7.31142 20.1016L5.70185 18.492L3.40908 16.1992C3.40874 16.1996 1.7366 17.8717 1.7366 17.8717C-0.5775 20.1858 -0.5775 23.9508 1.73621 26.2645L7.60531 20.3954L7.31142 20.1016Z", fill: "#78B9EB" })));
};
