import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { StatusScreen } from '~molecules';
import success from '~icons/statusIcons/success.svg';
export var ResendSuccess = function () {
    var _a;
    var t = useTranslation('registration').t;
    var tc = useTranslation('common').t;
    var location = useLocation();
    var subheader = t('resend.link_was_sent', {
        email: (_a = location === null || location === void 0 ? void 0 : location.state) === null || _a === void 0 ? void 0 : _a.email,
    });
    return (React.createElement(StatusScreen, { header: t('resend.created'), subheader: subheader, logo: success, target: "/register/success/email", buttonLabel: tc('buttons.resend_email') }));
};
