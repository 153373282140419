import React, { useCallback, useEffect, useRef } from 'react';
import i18next from 'i18next';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import ReactPixel from 'react-facebook-pixel';
import { useLocation } from 'react-router-dom';
import { CLOSE_SPLASH, LOGOUT_PRESS, REWARDED_FAILED, REWARDED_LOADED, REWARDED_SUCCESS, } from '~constants/cpa-message-codes';
import { Footer, Header, RightSide, SportList } from '~mainGrid';
import { getJokersMetaInfo } from '~slices/achievements/thunks';
import { changeOnlineStatus, selectApplicationOnlineStatus, } from '~slices/application';
import { deleteFullBetslip } from '~slices/betslip';
import { BETSLIP_SOCKET_NAME } from '~slices/events';
import { GAME_CLASS } from '~slices/gameTypes/constants';
import { resetShortcutBetlsip } from '~slices/shortcut';
import { availableSportsSelector } from '~slices/sports';
import { useAppDispatch } from '~store/configureStore';
import { getCookieAccepatation, isAuthenticatedSelector, isCookieAcceptedSelector, logout, refresh, } from '~slices/auth';
import { changeLocalSportId, getLanguage, getOptions, localSportIdSelector, optionsDataSelector, selectHeaderSportId, setSportName, userSportIdSelector, } from '~slices/options';
import { checkTermsHaveUpdate, resetTermsState, termsAcceptStatusSelectorDataSelector, termsHaveUpdatesSelector, } from '~slices/terms';
import { getCashOut } from '~slices/cashOut';
import { getTickets } from '~slices/tickets';
import { getAccount } from '~slices/account';
import { useIsDesktop, usePlatofrmType, useStructuredSelector, useWindowResize, } from '~hooks';
import { CookieConsent } from '~organisms';
import { ratio } from '~theme';
import { PLATFORMS } from '~slices/teasers';
import { getIdsMap } from '~slices/idsMap';
import { contentContainerSizeAtom } from '~utils/atoms/globalAtoms';
import { pxToNumber } from '~utils/css';
import { addExternalScript } from '~utils/addExternalScript';
import { getTwoFirstGlobalGames } from '~utils/getTwoFirstGlobalGames';
import { getPathWithLanguageCode } from '~utils/languageInURL/getPathWithLanguageCode';
import { GERMAN, checkIfIsLanguageInURL, languagesList, } from '~utils/languageInURL';
import { getUserCountryLanguage } from '~slices/userCountryLanguage';
import { isBetCheckProject } from '~utils/constants/betCheckConstants';
import { getGames, resetGames, setActiveGame, setGames, setIsEasyGame, setIsEasyTip, setSelectedGame, setTournamentParams, useActiveGameSelector, } from '~slices/gameTypes';
import { getPlayerAccounts, setPlayerAccounts, setRewardedLoadedStatus, } from '~slices/playerAccounts';
var prevRatio = NaN;
var memoizedZoom = function () {
    if (prevRatio) {
        return String(1 / prevRatio);
    }
    prevRatio = ratio();
    return String(1 / prevRatio);
};
var useStyles = makeStyles(function (theme) {
    var _a, _b, _c;
    return (_a = {
            '@global': {
                body: {
                    '& > *': {
                        zoom: memoizedZoom(),
                    },
                    '& > div[style*="visibility"]': {
                        zoom: '1',
                    },
                    '& > div > div[style*="z-index: 2000000000"]:nth-child(2)': {
                        transform: "scale(".concat(memoizedZoom(), ") translate(-50%, -50%) !important"),
                    },
                },
                iframe: {
                    height: "calc(".concat(100 * ratio(), "vh - 50px)"),
                },
            }
        },
        _a["@media (min-width: ".concat(1366 / ratio(), "px)")] = {
            '@global': {
                iframe: {
                    height: "calc(".concat(100 * ratio(), "vh - 107px)"),
                },
            },
        },
        _a.flexColumn = {
            display: 'flex',
            flexDirection: 'column',
        },
        _a.mainContainer = {
            overflow: 'auto',
            maxHeight: '100%',
            minHeight: 'calc(100vh - calc(100vh - 100%))',
        },
        _a.main = {
            minHeight: '100vh',
            background: theme.variables.mainBackgroundColor.color,
            overflowX: 'hidden',
        },
        _a.content = {
            flex: 'auto',
            borderRadius: '4px',
            minHeight: '560px',
        },
        _a.testStyles = {
            background: '#151515',
            color: '#8E8E8E',
            display: 'grid',
            fontSize: '42px',
            fontWeight: 'bold',
        },
        _a.sports = (_b = {
                position: 'fixed',
                bottom: '30px',
                left: '30px',
                top: '106px',
                width: '283px',
                background: '#151515',
                boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.05)',
                borderRadius: '4px',
                fontSize: '18px',
                lineHeight: '18px',
                color: '#8E8E8E',
                boxSizing: 'border-box',
                padding: '16px',
                display: 'grid'
            },
            _b[theme.breakpoints.up('lg')] = {
                boxSizing: 'border-box',
                position: 'static',
                flex: '0 0 283px',
                marginRight: 30,
                overflow: 'auto',
            },
            _b),
        _a.contentContainer = (_c = {
                paddingTop: '52px',
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column',
                minHeight: 'calc(100vh - calc(100vh - 100%) - 52px)'
            },
            _c[theme.breakpoints.up('lg')] = {
                boxSizing: 'border-box',
                height: "".concat(100 * ratio(), "vh"),
                minHeight: 700,
                flexDirection: 'row',
                marginBottom: 30,
                padding: '106px 30px 0',
            },
            _c),
        _a);
}, { name: 'Main' });
export var Main = function (_a) {
    var children = _a.children;
    var theme = useTheme();
    var classes = useStyles(theme);
    var platform = usePlatofrmType();
    var isDesktop = useIsDesktop();
    var dispatch = useAppDispatch();
    var _b = useStructuredSelector({
        isAuthenticated: isAuthenticatedSelector,
        optionsData: optionsDataSelector,
        termsHaveUpdate: termsHaveUpdatesSelector,
        termsAccetpted: termsAcceptStatusSelectorDataSelector,
        cookieAccepted: isCookieAcceptedSelector,
        activeGame: useActiveGameSelector,
        online: selectApplicationOnlineStatus,
        userSportId: userSportIdSelector,
        sportId: selectHeaderSportId,
        localSportId: localSportIdSelector,
        sports: availableSportsSelector,
    }), isAuthenticated = _b.isAuthenticated, wasLoadedOptions = _b.optionsData.wasLoadedOptions, termsHaveUpdate = _b.termsHaveUpdate, termsAccetpted = _b.termsAccetpted, cookieAccepted = _b.cookieAccepted, activeGame = _b.activeGame, online = _b.online, userSportId = _b.userSportId, sportId = _b.sportId, localSportId = _b.localSportId, sports = _b.sports;
    var search = useLocation().search;
    var params = new URLSearchParams(search);
    var selectedGameParam = params.get('selectedGame');
    var selectedGroupParam = params.get('selectedGroup');
    var tournamentParam = params.get('tournament');
    var isCPA = platform === PLATFORMS.CROSS_PLATFORM_MOBILE;
    var redirectToTerms = function () {
        var pathname = '/about';
        if (isDesktop) {
            pathname = '/about/info/terms_conditions';
        }
        var route = {
            pathname: pathname,
            state: {
                type: 'info',
                mobileTab: 'terms',
            },
        };
        dispatch(push(getPathWithLanguageCode(route.pathname), route.state));
    };
    var handleRNMessage = function (event) {
        try {
            if (event.data) {
                var parsedData = JSON.parse(event.data);
                var code = parsedData === null || parsedData === void 0 ? void 0 : parsedData.code;
                if (code === REWARDED_SUCCESS || code === REWARDED_FAILED) {
                    dispatch(getGames(i18next.language)).then(function (_a) {
                        var payload = _a.payload;
                        setGames(payload);
                    });
                    dispatch(getPlayerAccounts()).then(function (_a) {
                        var payload = _a.payload;
                        setPlayerAccounts(payload);
                    });
                }
                if (code === REWARDED_LOADED) {
                    dispatch(setRewardedLoadedStatus(true));
                }
            }
        }
        catch (error) { }
    };
    var setGameNameInURL = function (id) {
        var selectedSport = sports.find(function (game) { return game.id === id; });
        var name = (selectedSport === null || selectedSport === void 0 ? void 0 : selectedSport.name) || '';
        dispatch(setSportName({ id: id, name: name }));
        dispatch(push(getPathWithLanguageCode("/home/all/live/".concat(name))));
    };
    var findGameByNameFromURL = useCallback(function () {
        var splitPathname = window.location.pathname.split('/');
        var possiblySport = splitPathname[3];
        var lastURLPart = splitPathname[splitPathname.length - 1];
        return sports.find(function (game) {
            return (game === null || game === void 0 ? void 0 : game.name) === decodeURI(possiblySport) ||
                (game === null || game === void 0 ? void 0 : game.name) === decodeURI(lastURLPart);
        });
    }, [sports]);
    var dispatchLocalSportId = function (id) {
        dispatch(changeLocalSportId({
            id: id,
            desktop: isDesktop,
            langIso: i18next.language,
        }));
    };
    var changeSport = function (id) {
        setGameNameInURL(id);
        if (localSportId !== id) {
            dispatchLocalSportId(id);
        }
    };
    useEffect(function () {
        var selectedGameFromURL = findGameByNameFromURL();
        if (selectedGameFromURL && selectedGameFromURL.id !== localSportId) {
            dispatchLocalSportId(selectedGameFromURL.id);
        }
    }, []);
    useEffect(function () {
        dispatch(getJokersMetaInfo());
    }, []);
    useEffect(function () {
        if (location.pathname.endsWith('home/all/live') ||
            location.pathname.endsWith('home/all/live/')) {
            if (sportId) {
                changeSport(sportId);
            }
            if (!sportId && !isAuthenticated && sports) {
                var firstSport = sports[0];
                changeSport((firstSport === null || firstSport === void 0 ? void 0 : firstSport.id) || 1);
            }
            return;
        }
        var selectedGameFromURL = findGameByNameFromURL();
        if (selectedGameFromURL && selectedGameFromURL.id !== localSportId) {
            dispatchLocalSportId(selectedGameFromURL.id);
            return;
        }
    }, [sports, userSportId]);
    useEffect(function () {
        window.addEventListener('message', handleRNMessage, false);
        return function () {
            window.removeEventListener('message', handleRNMessage);
        };
    }, []);
    useEffect(function () {
        if (isAuthenticated && (selectedGameParam || selectedGroupParam)) {
            dispatch(setSelectedGame({
                selectedGame: selectedGameParam,
                selectedGroup: selectedGroupParam,
            }));
            if (tournamentParam === 'true') {
                dispatch(setTournamentParams({ pathname: window.location.pathname }));
            }
        }
    }, []);
    useEffect(function () {
        ReactPixel.trackCustom('page view', { page: window.location.pathname });
    }, [window.location.pathname]);
    useEffect(function () {
        if (window.location.pathname === '/' ||
            window.location.pathname === "/".concat(i18next.language, "/")) {
            if (isAuthenticated) {
                dispatch(push(getPathWithLanguageCode(isDesktop ? '/home' : '/ranking-list')));
            }
            else {
                dispatch(push(getPathWithLanguageCode('/start')));
            }
        }
    }, []);
    useEffect(function () {
        if (isCPA) {
            // @ts-expect-error
            window.ReactNativeWebView.postMessage(JSON.stringify({ code: CLOSE_SPLASH }));
        }
    }, []);
    useEffect(function () {
        var currentURL = window.location.pathname;
        var isLanguageInURL = checkIfIsLanguageInURL(currentURL);
        if (!isLanguageInURL) {
            dispatch(getUserCountryLanguage()).then(function (result) {
                var payload = result.payload;
                var userCountryLanguage = payload.languageCode;
                var pushURL = function () {
                    dispatch(push(getPathWithLanguageCode(currentURL)));
                };
                if (languagesList.includes(userCountryLanguage)) {
                    i18next.changeLanguage(userCountryLanguage).then(function () { return pushURL(); });
                }
                else {
                    i18next.changeLanguage(GERMAN).then(function () { return pushURL(); });
                }
            });
        }
    }, [isAuthenticated, i18next.language, window.location.pathname]);
    useEffect(function () {
        if (termsHaveUpdate)
            redirectToTerms();
    }, [isDesktop]);
    useEffect(function () {
        dispatch(getIdsMap());
    }, [isAuthenticated]);
    useEffect(function () {
        window.addEventListener('online', function () {
            console.log('online');
            dispatch(changeOnlineStatus(true));
            window.location.href = window.location.pathname;
        });
        window.addEventListener('offline', function () {
            console.log('offline');
            dispatch(changeOnlineStatus(false));
        });
    }, []);
    useEffect(function () {
        var handleVisibilityChange = function () {
            if (!document.hidden && window.navigator.onLine !== online) {
                dispatch(changeOnlineStatus(window.navigator.onLine));
            }
        };
        if (typeof document.addEventListener === 'undefined') {
            console.error('This page requires a browser, that supports the Page Visibility API.');
        }
        else {
            document.addEventListener('visibilitychange', handleVisibilityChange, false);
        }
        return function () {
            document.removeEventListener('visibilitychange', handleVisibilityChange, false);
        };
    }, []);
    useEffect(function () {
        if (isAuthenticated) {
            dispatch(checkTermsHaveUpdate());
            dispatch(getCashOut());
            if (isBetCheckProject) {
                dispatch(getTickets({
                    tabId: 'ALL',
                    ticketSearchStatus: 'ALL',
                }));
            }
        }
    }, []);
    var logoutAndNotifyCPA = function () {
        if (isCPA) {
            // @ts-expect-error
            window.ReactNativeWebView.postMessage(JSON.stringify({ code: LOGOUT_PRESS }));
        }
        else {
            dispatch(logout());
            dispatch(resetGames());
        }
    };
    useEffect(function () {
        if (termsAccetpted) {
            dispatch(refresh())
                .then(function () { return dispatch(getAccount()); })
                .then(function () { return dispatch(push(getPathWithLanguageCode('/home'))); })
                .then(function () { return dispatch(resetTermsState()); });
        }
    }, [termsAccetpted]);
    useEffect(function () {
        if (termsHaveUpdate)
            redirectToTerms();
    }, [termsHaveUpdate]);
    useEffect(function () {
        if (isAuthenticated) {
            dispatch(getAccount()).then(function (_a) {
                var _b;
                var payload = _a.payload;
                //@ts-ignore
                if (((_b = payload === null || payload === void 0 ? void 0 : payload.response) === null || _b === void 0 ? void 0 : _b.status) === 404) {
                    logoutAndNotifyCPA();
                }
                var userExternalId = payload === null || payload === void 0 ? void 0 : payload.externalId;
                var scriptBody = "\n            srtmCommands.push({\n              event: \"track.user.login\",\n              payload: {\n                  action: \"complete\",\n                  userId: \"".concat(userExternalId, "\",\n                  }\n            });\n          ");
                addExternalScript({ body: scriptBody });
            });
        }
        dispatch(getCookieAccepatation());
        // TODO check it, probably getOptions will never called if default user sport is soccer
        if (isAuthenticated && !wasLoadedOptions) {
            dispatch(checkTermsHaveUpdate());
            dispatch(getOptions());
            dispatch(getLanguage()).then(function (result) {
                i18next.changeLanguage(result.payload);
                try {
                    var language = result.payload === 'uk' ? 'ukr' : result.payload;
                    window.SIR('changeLanguage', language);
                }
                catch (e) {
                    console.error('SIR Error:', e);
                }
            });
            dispatch(getCashOut());
            dispatch(getTickets({
                tabId: 'ALL',
                ticketSearchStatus: 'ALL',
            }));
        }
    }, [isAuthenticated]);
    var isAuthenticatedRef = useRef(isAuthenticated);
    isAuthenticatedRef.current = isAuthenticated;
    useEffect(function () {
        var interval = setInterval(function () {
            if (isAuthenticatedRef.current) {
                dispatch(getGames(i18next.language)).then(function (_a) {
                    var payload = _a.payload;
                    setGames(payload);
                });
            }
        }, 30000);
        return function () {
            clearInterval(interval);
        };
    }, []);
    useEffect(function () {
        if (!isBetCheckProject) {
            dispatch(getGames(i18next.language)).then(function (_a) {
                var _b, _c, _d;
                var payload = _a.payload;
                setGames(payload);
                if (activeGame === null && payload) {
                    var globalGames = (_b = getTwoFirstGlobalGames(payload)) === null || _b === void 0 ? void 0 : _b.filter(function (game) {
                        return tournamentParam === 'true' ? game.tournament : !game.tournament;
                    });
                    var gameName = (_c = globalGames[0]) === null || _c === void 0 ? void 0 : _c.name;
                    dispatch(setActiveGame(gameName));
                }
                if (tournamentParam === 'true') {
                    var gameClass = (_d = payload.find(function (game) {
                        return selectedGroupParam
                            ? game.groupName === selectedGroupParam
                            : game.name === selectedGameParam;
                    })) === null || _d === void 0 ? void 0 : _d.gameClass;
                    if (gameClass === GAME_CLASS.EASY_PLUS) {
                        dispatch(setIsEasyGame(true));
                    }
                    if (gameClass === GAME_CLASS.EASY_TIP) {
                        dispatch(setIsEasyTip(true));
                    }
                }
            });
        }
    }, [isAuthenticated]);
    useEffect(function () {
        if (selectedGroupParam || tournamentParam === 'true') {
            dispatch(deleteFullBetslip({ eventsType: BETSLIP_SOCKET_NAME })).then(function () { return dispatch(resetShortcutBetlsip()); });
        }
    }, []);
    var contentContainerRef = useRef(null);
    useWindowResize(function () {
        if (contentContainerRef.current) {
            var computedStyle = getComputedStyle(contentContainerRef.current);
            var height = pxToNumber(computedStyle.height);
            var paddingTop = pxToNumber(computedStyle.paddingTop);
            var paddingBottom = pxToNumber(computedStyle.paddingBottom);
            var contentHeight = height - paddingTop - paddingBottom;
            if (contentContainerSizeAtom.getValue() !== contentHeight) {
                contentContainerSizeAtom.setValue(contentHeight);
            }
        }
    });
    var lastActiveTimeRef = useRef(new Date().getTime());
    useEffect(function () {
        var handleVisibilityChange = function () {
            if (document.visibilityState === 'visible') {
                var currentTime = new Date().getTime();
                var inactiveDuration = (currentTime - lastActiveTimeRef.current) / 1000;
                var updateTimeInSeconds = 300;
                if (inactiveDuration >= updateTimeInSeconds) {
                    dispatch(getAccount()).then(function (_a) {
                        var _b;
                        var payload = _a.payload;
                        //@ts-ignore
                        if (((_b = payload === null || payload === void 0 ? void 0 : payload.response) === null || _b === void 0 ? void 0 : _b.status) === 404) {
                            logoutAndNotifyCPA();
                        }
                    });
                }
                lastActiveTimeRef.current = currentTime;
            }
        };
        if (isAuthenticated) {
            document.addEventListener('visibilitychange', handleVisibilityChange);
        }
        return function () {
            return document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [isAuthenticated]);
    return (React.createElement("div", { className: classes.mainContainer },
        React.createElement("div", { className: "".concat(classes.main, " ").concat(classes.flexColumn) },
            React.createElement(Header, null),
            React.createElement("div", { ref: contentContainerRef, className: classes.contentContainer },
                isDesktop ? (React.createElement("div", { className: "".concat(classes.sports, " ").concat(classes.testStyles) },
                    React.createElement(SportList, null))) : null,
                React.createElement("div", { className: classNames(classes.content, classes.flexColumn, {
                        'flex-scroll': isDesktop,
                    }) }, children),
                React.createElement(RightSide, null)),
            isDesktop && React.createElement(Footer, null),
            !cookieAccepted && !isCPA && React.createElement(CookieConsent, null))));
};
