import makeStyles from '@material-ui/core/styles/makeStyles';
export var useStyles = makeStyles({
    wrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        height: '100%',
        '& [class*="subheader"]': {
            display: 'flex',
            alignItems: 'center',
            marginTop: '50px',
            maxWidth: '100%',
            fontWeight: 700,
            fontSize: '64px',
            '& > svg': {
                marginLeft: '5px',
            },
        },
    },
}, { name: 'TopUpStatus' });
