import makeStyles from '@material-ui/core/styles/makeStyles';
var useStyles = makeStyles(function (theme) {
    var _a;
    return ({
        controlButtons: (_a = {
                position: 'fixed',
                width: '100%',
                bottom: 0,
                left: 0
            },
            _a[theme.breakpoints.up('lg')] = {
                position: 'static',
            },
            _a),
        blockMargin: {
            margin: '16px',
        },
    });
}, { name: 'BottomButtons' });
export { useStyles };
