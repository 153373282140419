import makeStyles from '@material-ui/core/styles/makeStyles';
var useStyles = makeStyles({
    button: {
        height: '34px',
        background: 'radial-gradient(100% 100% at 50.13% 0%, #3C3C46 0%, #1B1B22 100%)',
        boxSizing: 'border-box',
        color: '#fff',
    },
    light: {
        background: 'transparent',
        color: '#212121',
    },
    bordered: {
        boxSizing: 'border-box',
        '&$light': {
            border: '1px solid #151515',
        },
    },
}, { name: 'SelectionButton' });
export { useStyles };
