import React, { useEffect, useRef, useState } from 'react';
import { noop } from '~utils/noop';
import { useIsMobileDevise } from '~hooks';
export var CurrencyInput = React.forwardRef(function CurrencyInput(_a, externalRef) {
    var value = _a.value, onChange = _a.onChange, _b = _a.onValidate, onValidate = _b === void 0 ? noop : _b, onBluer = _a.onBluer, inputClassNames = _a.inputClassNames, placeholder = _a.placeholder, wagerValue = _a.wagerValue;
    var _c = useState(typeof value === 'number' ? String(value) : ''), inputValue = _c[0], setInputValue = _c[1];
    var isMobile = useIsMobileDevise();
    var isFocusedRef = useRef(false);
    var reg = /^\d{1,6}((\.|,)?)(\d{1,2})?$/;
    useEffect(function () {
        var normalizedValue = typeof value === 'number' ? String(value) : '';
        if (normalizedValue !== inputValue && !isFocusedRef.current) {
            setInputValue(normalizedValue);
            onValidate(normalizedValue);
        }
    }, [value]);
    var localOnFocus = function () {
        if (value) {
            isFocusedRef.current = true;
        }
    };
    var localOnBlur = function (e) {
        isFocusedRef.current = false;
        onBluer ? onBluer() : undefined;
    };
    var handleChange = function (event) {
        var eventValue = event.target.value;
        var newValue = eventValue.replace(',', '.');
        if (newValue.match(reg) || newValue === '') {
            setInputValue(newValue);
            onValidate(newValue);
            onChange(newValue);
        }
    };
    var onKeyPress = function (event) {
        var deniedCodes = [189, 107, 69];
        if (deniedCodes.includes(event.keyCode)) {
            event.preventDefault();
        }
    };
    return (React.createElement("input", { 
        /* eslint-disable-next-line jsx-a11y/no-autofocus */
        autoFocus: true, value: isMobile ? wagerValue : inputValue, className: inputClassNames, onChange: handleChange, autoComplete: "off", onBlur: localOnBlur, onFocus: localOnFocus, onKeyDown: onKeyPress, placeholder: placeholder, type: "text", inputMode: "decimal", ref: externalRef, readOnly: isMobile }));
});
