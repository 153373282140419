import makeStyles from '@material-ui/core/styles/makeStyles';
var useStyles = makeStyles({
    content: {
        margin: '6px 0px',
        display: 'inline-box',
        background: 'radial-gradient(100% 100% at 50.13% 0%, #3C3C46 0%, #1B1B22 100%)',
        borderRadius: '6px',
        overflow: 'hidden',
        minWidth: '100px',
        '&$light': {
            margin: '12px 0px',
            background: '#fff',
        },
        '&::before': {
            content: '""',
            position: 'absolute',
            top: '1px',
            right: '16px',
            marginLeft: '-6px',
            width: '0',
            height: '0',
            borderStyle: 'solid',
            borderWidth: '0 6px 5px 6px',
            borderColor: 'transparent transparent #303038 transparent',
        },
        '&$light::before': {
            borderColor: 'transparent transparent #fff transparent',
            top: '7px',
        },
    },
    light: {
    /* for typings */
    },
}, { name: 'DropDown' });
export { useStyles };
