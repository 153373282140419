import { CircularProgress } from '@material-ui/core';
import React from 'react';
import makeStyles from '~utils/makeStyles';
export var CircularProgressBar = function (_a) {
    var progress = _a.progress, height = _a.height, width = _a.width;
    var classes = useStyles();
    if (progress > 100) {
        progress = 100;
    }
    if (progress < 0) {
        progress = 0;
    }
    return (React.createElement("div", { className: classes.overlay, style: { height: height, width: width } },
        React.createElement(CircularProgress, { variant: "determinate", value: progress, size: Math.min(height, width), thickness: 4.2, className: classes.progressCircle }),
        React.createElement(CircularProgress, { variant: "determinate", value: 100, size: Math.min(height, width), thickness: 4, className: classes.background })));
};
var useStyles = makeStyles(function () { return ({
    overlay: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        pointerEvents: 'none',
        zIndex: 10,
    },
    progressCircle: {
        position: 'absolute',
        top: 0,
        left: 0,
        color: '#DC343B',
        opacity: '0.8',
        zIndex: 10,
    },
    background: {
        position: 'absolute',
        top: 0,
        left: 0,
        color: '#C8B7B7',
        opacity: '0.7',
        zIndex: 9,
    },
}); }, { name: 'CircularProgressBar' });
