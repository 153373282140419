import React from "react";
import classNames from "classnames";
import moment from 'moment';
import { useTranslation } from "react-i18next";
import { useIsDesktop } from "~hooks";
import { useStyles } from './DateSwitcher.styles';
export var DateSwitcher = function (_a) {
    var current = _a.current, prev = _a.prev, next = _a.next, nextName = _a.nextName, name = _a.name, currentName = _a.currentName, onClick = _a.onClick;
    var t = useTranslation('tip-game').t;
    var classes = useStyles();
    var isDesktop = useIsDesktop();
    var formatDate = function (timestamp, isCurrent) {
        if (!timestamp) {
            return '';
        }
        var date = new Date(Number(timestamp) * 1000);
        return moment(date).format(isCurrent ? "DD.MM.Y" : "DD.MM");
    };
    var dropDownButtonText = currentName
        ? currentName
        : !next && !nextName
            ? t('ranking-list.current')
            : formatDate(current, true);
    return (React.createElement("div", { className: classNames(classes.container, !isDesktop && classes.positionFixed) },
        React.createElement("div", { className: classes.buttonsContainer },
            React.createElement("div", { role: "button", tabIndex: 0, className: classNames(classes.button, !prev && classes.lastButton), onClick: function () { return prev && onClick('prev'); } },
                React.createElement("span", null,
                    React.createElement("i", { className: "far fa-angle-left" }))),
            React.createElement("div", { className: classes.dropDownButton },
                React.createElement("span", null, dropDownButtonText)),
            React.createElement("div", { role: "button", tabIndex: 0, className: classNames(classes.button, !next && !nextName && classes.lastButton), onClick: function () { return (next || nextName) && onClick('next'); } },
                React.createElement("span", null,
                    React.createElement("i", { className: "far fa-angle-right" })))),
        React.createElement("div", { className: classes.name }, name)));
};
