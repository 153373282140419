import React from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from '~modals';
import { AlertDialogSlide } from '~molecules';
export var PPMOModal = function (_a) {
    var modalName = _a.modalName, _b = _a.isVisible, isVisible = _b === void 0 ? false : _b, _c = _a.modalProps, message = _c.message, title = _c.title;
    var dispatch = useDispatch();
    var handleClose = function () {
        dispatch(closeModal({ key: modalName }));
    };
    return (React.createElement(AlertDialogSlide, { open: isVisible, needConfirm: false, title: title, content: message, handleClose: handleClose }));
};
