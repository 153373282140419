import makeStyles from '@material-ui/core/styles/makeStyles';
export var useStyles = makeStyles(function (theme) {
    var _a;
    return (_a = {
            imprint: {
                padding: '24px 16px',
                margin: '16px',
                background: '#151515',
            }
        },
        _a[theme.breakpoints.up('lg')] = {
            imprint: {
                margin: 0,
            },
        },
        _a.imprintText = {
            display: 'inline-block',
            color: '#fff',
            padding: '5px 15px',
            fontSize: '14px',
            lineHeight: '21px',
        },
        _a.imprintHeaderText = {
            color: '#F2F2F2',
            fontWeight: 'bold',
            fontSize: '16px',
            lineHeight: '24px',
        },
        _a.closeButton = {
            color: '#8E8E8E',
        },
        _a.line = {
            border: 'none',
            margin: '0 15px 5px',
            backgroundColor: '#8E8E8E',
            color: '#8E8E8E',
            height: '1px',
        },
        _a.icon = {
            fontWeight: 900,
            fontSize: '12px',
            lineHeight: '24px',
            color: '#2AA1F7',
            marginRight: '15px',
            border: '1px solid #2AA1F7',
            padding: '0 4px',
            borderRadius: '4px',
        },
        _a.imprintRow = {
            marginBottom: 20,
        },
        _a.imprintRowBorder = {
            borderTop: '1px solid #8E8E8E',
            paddingTop: 15,
            marginTop: 10,
        },
        _a.imprintGrid = {
            display: 'flex',
            alignItems: 'flex-start',
            borderTop: '1px solid #8E8E8E',
            padding: '40px 25px',
            marginTop: 10,
            minHeight: 500,
        },
        _a.imprintCol = {
            width: '50%',
            paddingRight: 100,
            '& div': {
                border: 'none',
                marginTop: 0,
                paddingTop: 0,
            },
        },
        _a.imprintColFirst = {
            borderLeft: '1px solid #8E8E8E',
            paddingLeft: 25,
        },
        _a);
}, { name: 'Imprint' });
