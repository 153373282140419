import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var RugbyIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, viewBox: "0 0 28 28" },
        React.createElement("g", { clipPath: "url(#clip0)" },
            React.createElement("path", { d: "M10.0335 10.0332C3.25693 16.8105 -0.353544 24.1883 1.72892 26.2714L26.2716 1.72868C24.1885 -0.353789 16.8107 3.25668 10.0335 10.0332Z", fill: "#FFF5CC" }),
            React.createElement("path", { d: "M17.9671 17.9669C24.7437 11.1896 28.3542 3.81185 26.2717 1.72876L1.729 26.2715C3.81209 28.3539 11.1899 24.7435 17.9671 17.9669Z", fill: "#FFEB99" }),
            React.createElement("path", { d: "M10.0337 10.033C16.8109 3.25643 24.1887 -0.354043 26.2718 1.72842C22.646 -1.89744 13.8718 0.316712 7.09465 7.09396C0.317464 13.8712 -1.89669 22.6453 1.72912 26.2711C-0.353348 24.188 3.25712 16.8102 10.0337 10.033Z", fill: "#78B9EB" }),
            React.createElement("path", { d: "M26.2717 1.72876C28.3542 3.81184 24.7437 11.1896 17.9671 17.9669C11.1899 24.7434 3.81209 28.3539 1.729 26.2714C5.35543 29.8973 14.129 27.6831 20.9062 20.9059C27.6834 14.1287 29.8975 5.35461 26.2717 1.72876Z", fill: "#3D9AE2" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0" },
                React.createElement("rect", { width: "28", height: "28", fill: "white" })))));
};
