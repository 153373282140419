var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import i18next from 'i18next';
import qs from 'qs';
import { createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';
import { name } from '~slices/auth/constants';
import { request, requestWithoutAuth } from '~utils/request';
import { getGmtTimezone } from '~utils/gmtTimezone';
import { needRefreshTokens, refreshTokenSelector, } from '~slices/auth/selectors';
var CLIENT_ID = process.env.REACT_APP_CLIENT_ID || '';
var CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET || '';
var getMilliseconds = function (seconds) { return seconds * 1000; };
var makeExpiresAtFromExpiresIn = function (expiresIn) {
    return getMilliseconds(expiresIn) + Date.now();
};
var getAuthStateData = function (data) { return ({
    accessToken: data.access_token || null,
    refreshToken: data.refresh_token || null,
    expiresAt: makeExpiresAtFromExpiresIn(Number(data.expires_in)),
    userId: data.externalId,
}); };
export var login = createAsyncThunk("".concat(name, "/login"), function (requestData, _a) {
    var rejectWithValue = _a.rejectWithValue;
    return __awaiter(void 0, void 0, void 0, function () {
        var data, error_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, requestWithoutAuth({
                            method: 'post',
                            url: "auth/v1/oauth/token/beton?client_id=".concat(CLIENT_ID, "&client_secret=").concat(CLIENT_SECRET, "&timezone=").concat(getGmtTimezone(true), "&app_version=").concat(process.env.REACT_APP_VERSION),
                            data: qs.stringify(__assign(__assign({}, requestData), { grant_type: 'password' })),
                            withCredentials: true,
                        })];
                case 1:
                    data = (_b.sent()).data;
                    return [2 /*return*/, getAuthStateData(data)];
                case 2:
                    error_1 = _b.sent();
                    // @ts-expect-error
                    if (!error_1.response) {
                        throw error_1;
                    }
                    // @ts-expect-error
                    return [2 /*return*/, rejectWithValue(error_1.response.data)];
                case 3: return [2 /*return*/];
            }
        });
    });
});
export var getCookieAccepatation = createAsyncThunk("".concat(name, "/getCookieAccepatation"), function () { return __awaiter(void 0, void 0, void 0, function () {
    var data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, requestWithoutAuth({
                    method: 'get',
                    url: 'player/v1/cookie-accept',
                    withCredentials: true,
                })];
            case 1:
                data = (_a.sent()).data;
                return [2 /*return*/, data];
        }
    });
}); });
export var setCookieAccepatation = createAsyncThunk("".concat(name, "/setCookieAccepatation"), function () { return __awaiter(void 0, void 0, void 0, function () {
    var data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, requestWithoutAuth({
                    method: 'post',
                    url: 'player/v1/cookie-accept',
                    withCredentials: true,
                })];
            case 1:
                data = (_a.sent()).data;
                return [2 /*return*/, data];
        }
    });
}); });
export var registration = createAsyncThunk("".concat(name, "/registration"), function (_a, _b) { return __awaiter(void 0, void 0, void 0, function () {
    var data, error_2;
    var recaptchaToken = _a.recaptchaToken, requestData = __rest(_a, ["recaptchaToken"]);
    var rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 2, , 3]);
                return [4 /*yield*/, requestWithoutAuth({
                        method: 'post',
                        url: 'auth-service/v1/users',
                        data: requestData,
                        headers: {
                            lang: i18next.language,
                            timezone: getGmtTimezone(false),
                            'recaptcha-token': recaptchaToken,
                        },
                    })];
            case 1:
                data = (_c.sent()).data;
                return [2 /*return*/, data];
            case 2:
                error_2 = _c.sent();
                // @ts-expect-error
                if (!error_2.response) {
                    throw error_2;
                }
                // @ts-expect-error
                return [2 /*return*/, rejectWithValue(error_2.response.data)];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var resendEmail = createAsyncThunk("".concat(name, "/resendEmail"), function (values, _a) {
    var rejectWithValue = _a.rejectWithValue;
    return __awaiter(void 0, void 0, void 0, function () {
        var data, error_3;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, request({
                            method: values.isOldEmail ? 'get' : 'post',
                            url: "auth/v1/users/confirm/registration/resend".concat(values.isOldEmail ? "/".concat(values.externalId) : ''),
                            data: values.isOldEmail ? undefined : values,
                        })];
                case 1:
                    data = (_b.sent()).data;
                    return [2 /*return*/, data];
                case 2:
                    error_3 = _b.sent();
                    // @ts-expect-error
                    if (!error_3.response) {
                        throw error_3;
                    }
                    // @ts-expect-error
                    return [2 /*return*/, rejectWithValue(error_3.response.data)];
                case 3: return [2 /*return*/];
            }
        });
    });
});
// eslint-disable-next-line @typescript-eslint/no-explicit-any
var cachedRefreshPromise = null;
export var refresh = createAsyncThunk("".concat(name, "/refresh"), function (_, _a) {
    var getState = _a.getState, rejectWithValue = _a.rejectWithValue;
    if (cachedRefreshPromise) {
        return cachedRefreshPromise;
    }
    var refreshToken = refreshTokenSelector(getState());
    var refreshPromise = requestWithoutAuth({
        method: 'post',
        url: "auth/v1/oauth/token/beton?client_id=".concat(CLIENT_ID, "&client_secret=").concat(CLIENT_SECRET, "&timezone=").concat(getGmtTimezone(true)),
        data: qs.stringify({
            refresh_token: refreshToken,
            grant_type: 'refresh_token',
        }),
        withCredentials: true,
    })
        // @ts-expect-error
        .then(function (_a) {
        var data = _a.data;
        return getAuthStateData(data);
    })
        .catch(function (error) {
        if (!error.response) {
            throw error;
        }
        return rejectWithValue(error.response.data);
    })
        .finally(function () {
        cachedRefreshPromise = null;
    });
    cachedRefreshPromise = refreshPromise;
    return refreshPromise;
});
export var checkTokens = createAsyncThunk("".concat(name, "/checkTokens"), function (_, _a) {
    var getState = _a.getState, dispatch = _a.dispatch;
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_b) {
            if (needRefreshTokens(getState())) {
                return [2 /*return*/, dispatch(refresh())
                        .then(unwrapResult)
                        .then(function (data) { return data; })];
            }
            return [2 /*return*/, undefined];
        });
    });
});
export var checkRegistrationData = createAsyncThunk('checkRegistrationData', function (values, _a) {
    var rejectWithValue = _a.rejectWithValue;
    return __awaiter(void 0, void 0, void 0, function () {
        var data, err_1, error;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, requestWithoutAuth({
                            method: 'post',
                            url: '/auth/v1/users/field/validate',
                            data: values,
                        })];
                case 1:
                    data = (_c.sent()).data;
                    return [2 /*return*/, data];
                case 2:
                    err_1 = _c.sent();
                    error = err_1;
                    return [2 /*return*/, rejectWithValue((_b = error === null || error === void 0 ? void 0 : error.response) === null || _b === void 0 ? void 0 : _b.data)];
                case 3: return [2 /*return*/];
            }
        });
    });
});
export var checkForUpdateEmail = createAsyncThunk('checkUpdatedEmail', function (value) { return __awaiter(void 0, void 0, void 0, function () {
    var data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, request({
                    method: 'PUT',
                    url: 'rule/v1/rules/validate/update/email',
                    data: value,
                })];
            case 1:
                data = (_a.sent()).data;
                return [2 /*return*/, data];
        }
    });
}); });
