import React from 'react';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next';
import { SelectField, TextField } from '~atoms';
import { checkIfFieldIsRequired, onlyNumberField, required, validateField, } from '~utils/formValidators';
import { normalizeNumberFieldValue } from '~utils/fieldNormalization';
export var Phone = function (_a) {
    var registrationBlocks = _a.registrationBlocks, tabIndex = _a.tabIndex, countriesPhone = _a.countriesPhone;
    var t = useTranslation('registration').t;
    return (React.createElement(Grid, { container: true, spacing: 2 },
        React.createElement(Grid, { key: "phoneCode", item: true, xs: 5 },
            React.createElement(SelectField, { name: "phoneCode", title: t('form.phone_code'), options: countriesPhone, optionRenderer: function (_a) {
                    var id = _a.id, value = _a.value;
                    return (React.createElement(MenuItem, { key: "".concat(id, " ").concat(value), value: id }, "".concat(id, " ").concat(value)));
                }, renderValue: function (value) { return value; }, placeholder: t('form.enter_phone_code'), validators: function (value) {
                    return validateField(value, 'phoneCode', tabIndex, required, registrationBlocks);
                }, required: checkIfFieldIsRequired('phoneCode', tabIndex, registrationBlocks) })),
        React.createElement(Grid, { key: "phone", item: true, xs: 7 },
            React.createElement(TextField, { name: "phone", title: t('form.mobile_number'), placeholder: t('form.enter_mobile_number'), validators: [
                    function (value) {
                        return validateField(value, 'phone', tabIndex, required, registrationBlocks);
                    },
                    function (value) {
                        return validateField(value, 'phone', tabIndex, onlyNumberField, registrationBlocks);
                    },
                ], required: checkIfFieldIsRequired('phone', tabIndex, registrationBlocks), normalize: normalizeNumberFieldValue }))));
};
