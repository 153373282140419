var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import moment from 'moment';
import { findByValue } from './findByValue';
var prepareDataForRequest = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h;
    var data = _a.data, nationalities = _a.nationalities, titles = _a.titles, recaptchaToken = _a.recaptchaToken, pTag = _a.pTag;
    return __assign(__assign({}, data), { username: (data === null || data === void 0 ? void 0 : data.username) ? data.username.trim() : undefined, phone: (data === null || data === void 0 ? void 0 : data.phone) ? data.phoneCode + data.phone : undefined, recaptchaToken: recaptchaToken, pTag: pTag, userProfileDto: __assign(__assign({}, data.userProfileDto), { name: ((_b = data.userProfileDto) === null || _b === void 0 ? void 0 : _b.name)
                ? data.userProfileDto.name.trim()
                : undefined, surname: ((_c = data.userProfileDto) === null || _c === void 0 ? void 0 : _c.surname)
                ? data.userProfileDto.surname.trim()
                : undefined, birthDate: ((_d = data.userProfileDto) === null || _d === void 0 ? void 0 : _d.birthDate)
                ? moment(data.userProfileDto.birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
                : undefined, phoneCode: (data === null || data === void 0 ? void 0 : data.phone) ? data.phoneCode : undefined, addresses: [
                __assign(__assign({}, data.userProfileDto.addresses), { countryCode: ((_e = data.userProfileDto.addresses) === null || _e === void 0 ? void 0 : _e.countryCode)
                        ? data.userProfileDto.addresses.countryCode.countryCode
                        : undefined }),
            ], nationalityId: ((_f = nationalities.find(findByValue(data.userProfileDto.nationalityId))) === null || _f === void 0 ? void 0 : _f.id) || undefined, title: ((_g = data.userProfileDto) === null || _g === void 0 ? void 0 : _g.title)
                ? (_h = titles.find(findByValue(data.userProfileDto.title))) === null || _h === void 0 ? void 0 : _h.id
                : undefined }) });
};
export { prepareDataForRequest };
