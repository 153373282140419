import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var WinterSportIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0)" },
            React.createElement("path", { d: "M24.7178 7.2251H26.3583V26.4773H24.7178V7.2251Z", fill: "#F9F9F9" }),
            React.createElement("path", { d: "M25.5381 7.2251H26.3584V26.4773H25.5381V7.2251Z", fill: "#ECEAE7" }),
            React.createElement("path", { d: "M1.6416 7.2251H3.28213V26.4773H1.6416V7.2251Z", fill: "#F9F9F9" }),
            React.createElement("path", { d: "M2.46191 7.2251H3.28218V26.4773H2.46191V7.2251Z", fill: "#ECEAE7" }),
            React.createElement("path", { d: "M23.0776 1.45898H27.9994V8.04559H23.0776V1.45898Z", fill: "#4793FF" }),
            React.createElement("path", { d: "M23.6245 22.7678H27.4526V24.4084H23.6245V22.7678Z", fill: "#5ECBF1" }),
            React.createElement("path", { d: "M0.000976562 1.45898H4.92277V8.04559H0.000976562V1.45898Z", fill: "#4793FF" }),
            React.createElement("path", { d: "M0.547852 22.7678H4.37596V24.4084H0.547852V22.7678Z", fill: "#5ECBF1" }),
            React.createElement("path", { d: "M10.1895 0C8.53901 0 7.19629 1.34272 7.19629 2.99314V13.3793L13.1827 7.39303V2.99314C13.1827 1.34272 11.84 0 10.1895 0V0Z", fill: "#5ECBF1" }),
            React.createElement("path", { d: "M13.1826 2.99314C13.1826 1.34272 11.8399 0 10.1895 0V10.3862L13.1826 7.39303V2.99314Z", fill: "#4793FF" }),
            React.createElement("path", { d: "M7.19629 17.6218V28H13.1826V11.6355L7.19629 17.6218Z", fill: "#5ECBF1" }),
            React.createElement("path", { d: "M13.1826 28V11.6355L10.1895 14.6286V28H13.1826Z", fill: "#4793FF" }),
            React.createElement("path", { d: "M7.19629 11.0593V19.9418L13.1826 13.9555V5.073L7.19629 11.0593Z", fill: "#ECEAE7" }),
            React.createElement("path", { d: "M14.8237 11.6375V28.0001H20.8101V17.6238L14.8237 11.6375Z", fill: "#5ECBF1" }),
            React.createElement("path", { d: "M20.8096 28.0001V17.6238L17.8164 14.6306V28.0001H20.8096Z", fill: "#4793FF" }),
            React.createElement("path", { d: "M17.8169 0C16.1664 0 14.8237 1.34272 14.8237 2.99314V7.39489L20.8101 13.3813V2.99314C20.81 1.34272 19.4673 0 17.8169 0V0Z", fill: "#5ECBF1" }),
            React.createElement("path", { d: "M20.8096 2.99314C20.8096 1.34272 19.4668 0 17.8164 0V10.3881L20.8096 13.3813V2.99314Z", fill: "#4793FF" }),
            React.createElement("path", { d: "M14.8237 5.07471V13.9573L20.8101 19.9437V11.0611L14.8237 5.07471Z", fill: "#ECEAE7" }),
            React.createElement("path", { d: "M20.8096 11.0613L17.8164 8.06812V16.9507L20.8096 19.9439V11.0613Z", fill: "#DAD0CB" }),
            React.createElement("path", { d: "M13.1826 5.073L10.1895 8.06614V16.9487L13.1826 13.9555V5.073Z", fill: "#DAD0CB" }),
            React.createElement("path", { d: "M2.46191 1.45898H4.92282V8.04559H2.46191V1.45898Z", fill: "#525CDD" }),
            React.createElement("path", { d: "M2.46191 22.7678H4.37597V24.4084H2.46191V22.7678Z", fill: "#4793FF" }),
            React.createElement("path", { d: "M25.5381 1.45898H27.999V8.04559H25.5381V1.45898Z", fill: "#525CDD" }),
            React.createElement("path", { d: "M25.5381 22.7678H27.4522V24.4084H25.5381V22.7678Z", fill: "#4793FF" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0" },
                React.createElement("rect", { width: "28", height: "28", fill: "white" })))));
};
