import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import chunk from 'lodash.chunk';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { push } from 'connected-react-router';
import general from '~icons/general-banner.svg';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { checkIsExternalLink } from '~utils/checkIsExternalLink';
import { useAppDispatch } from '~store';
var MIN_OFFER_WIDTH = 400;
var useStyles = makeStyles({
    slide: {
        background: 'linear-gradient(90deg, rgba(35,35,35,1) 26%, rgba(255,255,255,0) 100%);',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        outline: 'none',
        overflow: 'hidden',
    },
    offer: {
        fontWeight: 'bold',
        color: 'white',
        fontSize: '36px',
        minWidth: "".concat(MIN_OFFER_WIDTH, "px"),
    },
    image: {
        width: 'auto !important',
        height: '100%',
        maxHeight: '100%',
    },
    video: {
        width: '100%',
    },
    cell: {
        flex: '1 1 100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        background: '#151515',
    },
    withShadow: {
        boxShadow: '15px 0px 20px 20px #151515',
    },
    hideOffer: {
        display: 'none',
    },
    cursorPointer: {
        cursor: 'pointer',
    },
}, { name: 'SlideTemplate' });
export var SlideTemplate = function (_a) {
    var _b, _c;
    var linkUrl = _a.linkUrl, offer = _a.offer, imageSrc = _a.imageSrc, hideOffer = _a.hideOffer;
    var dispatch = useAppDispatch();
    var classes = useStyles();
    var mainRef = useRef(null);
    var _d = useState(false), imageLoaded = _d[0], setImageIsLoaded = _d[1];
    var imageRef = useRef(null);
    var _e = useState(true), offerShow = _e[0], setOfferShow = _e[1];
    var _f = useState(false), showShadow = _f[0], setShowShadow = _f[1];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var timeoutId = null;
    var recalculateWidth = function () {
        clearTimeout(timeoutId);
        if (imageRef.current && mainRef.current) {
            var mainWidth = mainRef.current.getBoundingClientRect().width;
            var imageWidth = imageRef.current.getBoundingClientRect().width;
            if (imageWidth + MIN_OFFER_WIDTH > mainWidth) {
                timeoutId = setTimeout(function () { return setOfferShow(false); }, 150);
            }
            else {
                timeoutId = setTimeout(function () { return setOfferShow(true); }, 150);
            }
            if (mainWidth > imageWidth) {
                setShowShadow(true);
            }
            else {
                setShowShadow(false);
            }
        }
    };
    useEffect(function () {
        window.addEventListener('resize', recalculateWidth);
        return function () {
            window.removeEventListener('resize', recalculateWidth);
            clearTimeout(timeoutId);
        };
    }, []);
    useEffect(function () {
        if (mainRef.current && imageRef.current && imageLoaded) {
            window.dispatchEvent(new CustomEvent('resize'));
        }
    }, [mainRef.current, imageRef.current, imageLoaded]);
    var imageType = imageSrc.split('.').pop();
    var onItemClick = function (url) {
        checkIsExternalLink(url)
            ? window.open(url, '_blank')
            : dispatch(push("".concat(url)));
    };
    return (React.createElement("div", { role: "button", tabIndex: 0, ref: mainRef, className: "".concat(classes.slide, " ").concat(linkUrl && classes.cursorPointer), onClick: function () { return (linkUrl ? onItemClick(linkUrl) : null); } },
        React.createElement("div", { className: classNames(classes.cell, (_b = {},
                _b[classes.withShadow] = showShadow,
                _b)) }, !hideOffer && (React.createElement("span", { className: classNames(classes.offer, (_c = {},
                _c[classes.hideOffer] = !offerShow,
                _c)) }))),
        imageType === 'mp4' || imageType === 'avi' ? (React.createElement("video", { muted: true, loop: true, playsInline: true, autoPlay: true, onLoad: function () { return setImageIsLoaded(true); }, 
            // @ts-expect-error
            ref: imageRef, className: classes.video, src: imageSrc })) : (React.createElement("img", { onLoad: function () { return setImageIsLoaded(true); }, 
            // @ts-expect-error
            ref: imageRef, className: classes.image, src: imageSrc, alt: "alt" }))));
};
export var gallerySlide = function (_a) {
    var slideshow = _a.slideshow, classes = _a.classes;
    return chunk(slideshow.teasers, 4).map(function (partOfTeasers, index) {
        var _a, _b, _c, _d;
        return (
        // eslint-disable-next-line react/no-array-index-key
        React.createElement("div", { className: classes.container, key: index },
            React.createElement("div", { className: classes.flexRow },
                React.createElement("div", { className: classes.item }, partOfTeasers[0] ? (React.createElement(SlideTemplate, { hideOffer: true, linkUrl: partOfTeasers[0].linkUrl, imageSrc: ((_a = partOfTeasers[0]) === null || _a === void 0 ? void 0 : _a.mediaUrl) || general })) : null),
                React.createElement("div", { className: classes.item }, partOfTeasers[1] ? (React.createElement(SlideTemplate, { hideOffer: true, linkUrl: partOfTeasers[1].linkUrl, imageSrc: ((_b = partOfTeasers[1]) === null || _b === void 0 ? void 0 : _b.mediaUrl) || general })) : null)),
            React.createElement("div", { className: classes.flexRow },
                React.createElement("div", { className: classes.item }, partOfTeasers[2] ? (React.createElement(SlideTemplate, { hideOffer: true, linkUrl: partOfTeasers[2].linkUrl, imageSrc: ((_c = partOfTeasers[2]) === null || _c === void 0 ? void 0 : _c.mediaUrl) || general })) : null),
                React.createElement("div", { className: classes.item }, partOfTeasers[3] ? (React.createElement(SlideTemplate, { hideOffer: true, linkUrl: partOfTeasers[3].linkUrl, imageSrc: ((_d = partOfTeasers[3]) === null || _d === void 0 ? void 0 : _d.mediaUrl) || general })) : null))));
    });
};
export var slideShowSlide = function (_a) {
    var _b;
    var slideshow = _a.slideshow, position = _a.position, classes = _a.classes, isDesktop = _a.isDesktop;
    return (_b = slideshow.teasers) === null || _b === void 0 ? void 0 : _b.map(function (_a, index) {
        var _b;
        var linkUrl = _a.linkUrl, duration = _a.duration, mediaUrl = _a.mediaUrl;
        return (React.createElement("div", { 
            // @ts-expect-error
            interval: duration, className: classNames(classes.bannerWrapper, (_b = {},
                _b[classes.desktopBanner] = isDesktop,
                _b[classes.mobileBanner] = !isDesktop,
                _b)), key: index },
            React.createElement(SlideTemplate, { linkUrl: linkUrl, imageSrc: mediaUrl || general, offer: position || '' })));
    });
};
