var Socket = /** @class */ (function () {
    function Socket(url) {
        this._socket = undefined;
        this.handlers = {
            SOCKET_CONNECTED: [],
            SOCKET_ERROR: [],
            SOCKET_DISCONNECTED: [],
            SOCKET_MESSAGE: [],
            SOCKET_RECONNECT: [],
            SOCKET_MESSAGE_SEND: [],
        };
        this.url = url;
    }
    Socket.prototype.setUrl = function (url) {
        this.url = url;
    };
    Socket.prototype.connect = function () {
        var _this = this;
        this.disconnect(false);
        this._socket = new WebSocket(this.url);
        this._socket.onopen = function () {
            _this.connected();
        };
        this._socket.onclose = function (e) {
            if (e.code === 1005 || e.code === 1006 || e.code === 1011) {
                _this.reconnect();
            }
            else {
                _this.disconnect();
            }
        };
        this._socket.onmessage = function (e) {
            _this.message(JSON.parse(e.data));
        };
    };
    Socket.prototype.connected = function () {
        this.emit('SOCKET_CONNECTED');
    };
    Socket.prototype.disconnected = function () {
        this.emit('SOCKET_DISCONNECTED');
        this.handlers = {
            SOCKET_CONNECTED: [],
            SOCKET_ERROR: [],
            SOCKET_DISCONNECTED: [],
            SOCKET_MESSAGE: [],
            SOCKET_RECONNECT: [],
            SOCKET_MESSAGE_SEND: [],
        };
    };
    Socket.prototype.getReadyState = function () {
        var _a;
        return (_a = this._socket) === null || _a === void 0 ? void 0 : _a.readyState;
    };
    Socket.prototype.add = function (messageType, handler) {
        this.handlers[messageType].push(handler);
    };
    Socket.prototype.reconnect = function () {
        var _this = this;
        setTimeout(function () {
            _this.emit('SOCKET_RECONNECT');
            _this.connect();
        }, 1000);
    };
    Socket.prototype.disconnect = function (emitEvent) {
        if (emitEvent === void 0) { emitEvent = true; }
        if (this._socket) {
            this._socket.onclose = null;
            this._socket.close();
            if (emitEvent) {
                this.disconnected();
            }
        }
    };
    Socket.prototype.message = function (message) {
        this.emit('SOCKET_MESSAGE', message);
    };
    Socket.prototype.sendMessage = function (data) {
        this.emit('SOCKET_MESSAGE_SEND');
        if (this._socket && this.getReadyState() === 1) {
            this._socket.send(JSON.stringify(data));
        }
        else if (this.getReadyState() === 3) {
            this.reconnect();
        }
    };
    Socket.prototype.emit = function (status) {
        var _this = this;
        var rest = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            rest[_i - 1] = arguments[_i];
        }
        this.handlers[status].forEach(function (handler) {
            handler.apply(_this, rest);
        });
    };
    return Socket;
}());
export { Socket };
