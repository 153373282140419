var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createAction, createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createAsyncReducers } from '~utils/createAsyncReducers';
import { getCookieAccepatation, login, refresh, registration, resendEmail, setCookieAccepatation, } from '~slices/auth/thunks';
import { name } from './constants';
export var resetErrors = createAction("".concat(name, "/resetErrors"));
var initialState = {
    data: {
        accessToken: null,
        refreshToken: null,
        expiresAt: null,
        userId: undefined,
        cookieAccepted: true,
    },
    meta: {},
    loading: 'idle',
    error: null,
};
export var auth = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        logout: function (state) {
            return __assign(__assign({}, initialState), { data: __assign(__assign({}, initialState.data), { cookieAccepted: state.data.cookieAccepted }) });
        },
        resetRegistrationErrors: function (state) {
            return __assign(__assign({}, state), { error: undefined });
        },
        setUserPreData: function (state, _a) {
            var payload = _a.payload;
            return __assign(__assign({}, state), { data: __assign(__assign({}, state.data), { userId: payload.userId, email: payload.email }) });
        },
        setIsLogOutMessage: function (state, _a) {
            var payload = _a.payload;
            return __assign(__assign({}, state), { meta: __assign(__assign({}, state.meta), { isLogOutMessage: payload }) });
        },
    },
    extraReducers: function (builder) {
        var _a = createAsyncReducers(), pending = _a.pending, fulfilled = _a.fulfilled, rejected = _a.rejected;
        builder
            .addCase(login.pending, pending)
            .addCase(login.fulfilled, function (state, action) {
            return __assign(__assign({}, state), { data: __assign(__assign(__assign({}, state.data), action.payload), { cookieAccepted: true }) });
        })
            .addCase(login.rejected, rejected)
            .addCase(registration.pending, pending)
            .addCase(registration.fulfilled, function (state) {
            state.loading = 'fulfilled';
            state.error = null;
        })
            .addCase(registration.rejected, rejected)
            .addCase(refresh.fulfilled, function (state, action) {
            return __assign(__assign({}, state), { data: __assign(__assign(__assign({}, state.data), action.payload), { cookieAccepted: true }) });
        })
            .addCase(refresh.rejected, function (state, action) { return (__assign(__assign({}, rejected(state, action)), { data: initialState.data, cookieAccepted: state.data.cookieAccepted })); })
            .addCase(resetErrors, function (state) { return (__assign(__assign({}, state), { error: null })); })
            .addCase(resendEmail.pending, pending)
            .addCase(resendEmail.fulfilled, function (state, action) {
            if (!action.payload) {
                return __assign(__assign({}, state), { loading: 'fulfilled' });
            }
            return fulfilled(state, action);
        })
            .addCase(getCookieAccepatation.fulfilled, function (state, action) {
            return __assign(__assign({}, state), { data: __assign(__assign({}, state.data), { cookieAccepted: action.payload.accepted }), error: null });
        })
            .addCase(getCookieAccepatation.rejected, function (state) {
            return __assign(__assign({}, state), { data: __assign(__assign({}, state.data), { cookieAccepted: false }), error: null });
        })
            .addCase(setCookieAccepatation.fulfilled, function (state) {
            return __assign(__assign({}, state), { data: __assign(__assign({}, state.data), { cookieAccepted: true }), error: null });
        });
    },
});
export var logout = (_a = auth.actions, _a.logout), setUserPreData = _a.setUserPreData, resetRegistrationErrors = _a.resetRegistrationErrors, setIsLogOutMessage = _a.setIsLogOutMessage;
export var authPersistedReducer = persistReducer({
    key: name,
    whitelist: ['data'],
    storage: storage,
}, auth.reducer);
