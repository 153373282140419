import makeStyles from '@material-ui/core/styles/makeStyles';
export var useStyles = makeStyles(function (theme) {
    var _a, _b;
    return ({
        root: {
            background: '#151515',
            padding: '10px 18px',
            position: 'sticky',
            top: '52px',
            marginLeft: '-3px',
            zIndex: 2,
            boxSizing: 'border-box',
            border: '3px solid #8E8E8E',
            borderBottom: 'none',
            borderRadius: '12px 12px 0px 0px',
            width: 'calc(100vw + 6px)',
        },
        rootDesktop: {
            border: 'none',
            borderTop: '4px solid #151515',
            borderBottom: '4px solid #151515',
            backgroundColor: '#2D2D2D',
            top: '0px',
            borderRadius: '0px',
            width: 'initial',
        },
        closeButton: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '32px',
            width: '32px',
            cursor: 'pointer',
        },
        closeIcon: {
            fontSize: '20px',
            fontWeight: 100,
        },
        button: (_a = {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '135px',
                maxWidth: '135px',
                border: '1px solid #444444',
                borderRadius: '4px',
                height: '32px',
                padding: '0 8px',
                backgroundColor: '#151515',
                boxSizing: 'border-box',
                '& span': (_b = {
                        display: 'inline-flex',
                        fontSize: '12px',
                        color: '#fff',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: 'auto',
                        minWidth: '103px'
                    },
                    _b[theme.breakpoints.up('lg')] = {
                        minWidth: '88px',
                    },
                    _b)
            },
            _a[theme.breakpoints.up('lg')] = {
                width: '120px',
                maxWidth: '120px',
            },
            _a),
        buttonDisabled: {
            opacity: 0.5,
        },
        trash: {
            paddingRight: '8px',
        },
    });
}, { name: 'Nav' });
