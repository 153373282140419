import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '~atoms';
import { checkIfFieldIsRequired, emailValidation, required, validateField, } from '~utils/formValidators';
export var Email = function (_a) {
    var tabIndex = _a.tabIndex, registrationBlocks = _a.registrationBlocks;
    var t = useTranslation('registration').t;
    return (React.createElement(TextField, { name: "email", title: t('form.email'), placeholder: t('form.enter_email'), validators: [
            function (value) {
                return validateField(value, 'email', tabIndex, required, registrationBlocks);
            },
            function (value) {
                return validateField(value, 'email', tabIndex, emailValidation, registrationBlocks);
            },
        ], required: checkIfFieldIsRequired('email', tabIndex, registrationBlocks) }));
};
