import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import capitalize from '@material-ui/core/utils/capitalize';
import classNames from 'classnames';
import i18next from "i18next";
import { CurrencyInput } from '~pages/ticketSelection/components/currencyInput/CurrencyInput';
import { currencySelector } from '~slices/cashOut';
import { Currencies } from '~utils/converters';
import { usePlatofrmType, useStructuredSelector } from '~hooks';
import { Button, LocalStorageField } from '~atoms';
import { getTestId } from '~/utils/getTestId';
import { isBetCheckProject } from '~utils/constants/betCheckConstants';
import { EmptyBetslipBanner } from '~molecules/EmptyBetslipBanner';
import { getTeasers, squareGallerySelector } from '~slices/teasers';
import { CSIcon } from "~icons/rankingList/CSIcon";
import { useAppDispatch } from "~store";
import { isAuthenticatedSelector } from "~slices/auth";
import { useEmptyBetslipStyles } from './Betslip.styles';
export var EMPTY_BETSLIP_FIELD_NAME = 'localWager';
export var EmptyBetslip = function () {
    var _a;
    var classes = useEmptyBetslipStyles();
    var t = useTranslation('ticket').t;
    var _b = useStructuredSelector({
        isAuthenticated: isAuthenticatedSelector,
        currency: currencySelector,
        banners: function (state) { return squareGallerySelector(state); },
    }), isAuthenticated = _b.isAuthenticated, currency = _b.currency, banners = _b.banners;
    var dispatch = useAppDispatch();
    var slideshowPlatform = usePlatofrmType();
    var currencyValue = !isBetCheckProject ? React.createElement(CSIcon, { size: "md" }) : currency === 'CRD' ? React.createElement(CSIcon, { size: "md" }) : (_a = Currencies[currency]) !== null && _a !== void 0 ? _a : currency;
    useEffect(function () {
        var pagesWithTeasers = window.location.pathname === "/".concat(i18next.language, "/options") ||
            window.location.pathname === "/".concat(i18next.language, "/start") ||
            window.location.pathname === "/".concat(i18next.language, "/bp-list") ||
            window.location.pathname === "/".concat(i18next.language, "/top-ranking") ||
            window.location.pathname.includes("/".concat(i18next.language, "/my-tips")) ||
            window.location.pathname.includes('/home/all/live');
        if (i18next.language && !pagesWithTeasers) {
            dispatch(getTeasers({
                language: i18next.language,
                pageName: 'ANY_PAGE',
                platformType: slideshowPlatform,
                teaserStatus: isAuthenticated ? 'LOGGED_IN' : 'ANONYMOUS',
            }));
        }
    }, [i18next.language, isAuthenticated]);
    return (React.createElement("div", { className: classNames(classes.container, !isBetCheckProject && classes.smallContainer), "data-testid": getTestId('FRM_EMPTY_BETSLIP') },
        !isBetCheckProject && !!banners.length ? (React.createElement(EmptyBetslipBanner, null)) : (React.createElement("div", { className: classNames(classes.emptyBet, !isBetCheckProject && classes.emptyBetSmall) },
            React.createElement("i", { className: "far fa-receipt" }),
            React.createElement("span", { className: classes.label },
                "0 ",
                capitalize(t('bets_plural'))))),
        React.createElement("div", { className: classes.emptyWagerBlock },
            React.createElement("div", { className: classes.currencyRow },
                React.createElement("div", { className: classes.cardIcon },
                    React.createElement("i", { className: "fas fa-calculator" })),
                React.createElement(LocalStorageField, { name: EMPTY_BETSLIP_FIELD_NAME }, function (value, onChange) { return (React.createElement(CurrencyInput, { inputClassNames: classes.input, value: value, onChange: onChange, placeholder: t('wager') })); }),
                React.createElement("div", { className: classes.currencyIcon }, currencyValue)),
            React.createElement(Button, { classes: { disabled: classes.button }, color: "primary", variant: "contained", fullWidth: true, disabled: true }, t('check_now')))));
};
