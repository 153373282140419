var URLS;
(function (URLS) {
    URLS["ACHIEVEMENTS"] = "achievements-service/v1/achievements";
    URLS["TOURNAMENTS_LIST"] = "sport-events/v1/tournaments";
    URLS["TOURNAMENT_BY_ID"] = "sport-events/v1/tournaments/$id";
    URLS["FLAGS_LIST"] = "sport-info/v1/categories/flags";
    URLS["CATEGORY_BY_ID"] = "sport-events/v1/outrights/category/$id";
    URLS["OUTRIGHTS_LIST"] = "sport-events/v1/outrights/flags/$sportId/$flagId";
    URLS["MAIN_COLUMNS_INFO"] = "sport-info/v1/markets/maincolumns";
    URLS["GET_OPTIONS_MAP"] = "sport-info/v1/markets/layouts";
    URLS["TOP_UP"] = "/payment-service/v1/payments/top-up";
    URLS["TOP_UP_LIMITS"] = "/v1/player-games/topup-limits";
    URLS["GAMES_HISTORY"] = "game-manager/v1/games-history";
    URLS["TICKETS"] = "/v1/tickets";
    URLS["PAYMENTS_LIMITS"] = "payment-service/v1/payments/limits";
    URLS["GAME_BALANCE"] = "/v1/player-games/accounts/balance-request";
    URLS["CONVERSION_TEASERS"] = "/content/v1/conversion-teasers";
    URLS["JOKERS"] = "achievements-service/v1/jokers";
})(URLS || (URLS = {}));
export { URLS };
