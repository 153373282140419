import makeStyles from '@material-ui/core/styles/makeStyles';
export var useStyles = makeStyles({
    backdropClass: {
        position: 'absolute',
    },
    rootModal: {
        position: 'absolute',
        zIndex: '99999 !important',
    },
    title: {
        padding: '32px 16px 0',
    },
    content: {
        padding: '16px',
    },
    cancelButton: {
        marginTop: '16px',
        backgroundColor: 'transparent',
        border: '1px solid #212121',
        boxShadow: 'none',
    },
    clearTitle: {
        fontWeight: 300,
        fontSize: '24px',
        lineHeight: '36px',
        marginBottom: '15px',
    },
    clearQuestion: {
        fontWeight: 300,
        fontSize: '14px',
        lineHeight: '21px',
        marginBottom: '5px',
    },
    radius: {
        borderRadius: '8px',
        position: 'relative',
    },
    text: {
        padding: '0 6px',
        textAlign: 'center',
        color: 'black',
    },
    closeIcon: {
        position: 'absolute',
        top: '8px',
        right: '10px',
        color: '#ECECEC',
        padding: '0',
        minWidth: '0',
        height: '16px',
    },
    shinyBack: { background: '#fff' },
}, { name: 'ClearBetslipModal' });
