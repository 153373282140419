import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var WorldLotteryIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { viewBox: "0 0 28 28", className: className },
        React.createElement("svg", { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("g", { clipPath: "url(#clip0)" },
                React.createElement("path", { d: "M14.6875 14.8407C13.9718 15.5389 13.1218 16.1237 12.1543 16.5528C11.0478 17.0434 9.89369 17.2831 8.75559 17.2965L2.45794 14.687C1.75958 13.9711 1.17454 13.121 0.745404 12.1532C0.254748 11.0466 0.0150527 9.89237 0.00170898 8.75416L2.68763 2.38312C3.38736 1.71724 4.21133 1.15823 5.14496 0.744298C6.28579 0.238439 7.47738 -0.000654903 8.64983 1.34716e-06L14.9155 2.68713C15.5811 3.38669 16.14 4.21056 16.5538 5.14385C17.0598 6.28496 17.2989 7.47682 17.2981 8.64949L14.6875 14.8407Z", fill: "#FFC250" }),
                React.createElement("path", { d: "M17.2981 8.64858C17.2989 7.47591 17.0598 6.28405 16.5538 5.14294C16.14 4.20959 15.5812 3.38578 14.9155 2.68622L8.64987 -0.000853034C8.27411 -0.00107178 7.89639 0.0233188 7.51904 0.0732485C10.4068 0.454202 13.0333 2.2853 14.3003 5.14294C16.2359 9.50832 14.2662 14.6162 9.90079 16.5518C9.12554 16.8955 8.32694 17.1161 7.52681 17.2219C9.05035 17.4229 10.6466 17.2204 12.1543 16.5518C13.1219 16.1228 13.9719 15.5379 14.6876 14.8397L17.2981 8.64858Z", fill: "#EBB044" }),
                React.createElement("path", { d: "M2.76035 6.00465C3.23012 4.78648 3.16755 3.49455 2.68767 2.38379C1.78762 3.23905 1.0582 4.30217 0.581765 5.53762C0.173687 6.59598 -0.0118681 7.68525 0.00153033 8.75417C1.22451 8.28659 2.25214 7.32251 2.76035 6.00465Z", fill: "#EAF3F9" }),
                React.createElement("path", { d: "M14.6674 11.3468C14.2155 12.5186 14.2562 13.7587 14.6871 14.8401C15.5522 13.9976 16.254 12.9599 16.717 11.7594C17.1115 10.7362 17.2979 9.68405 17.2978 8.64941C16.1317 9.13422 15.1577 10.0754 14.6674 11.3468Z", fill: "#EAF3F9" }),
                React.createElement("path", { d: "M11.7608 0.58047C10.7373 0.18579 9.68493 -0.000584769 8.65002 -0.000366019C9.1228 1.20648 10.0808 2.21863 11.3854 2.72176C12.5705 3.17873 13.8252 3.13159 14.9151 2.68692C14.0598 1.7866 12.9965 1.05702 11.7608 0.58047Z", fill: "#EAF3F9" }),
                React.createElement("path", { d: "M9.50725 0.58047C10.743 1.05702 11.8063 1.7866 12.6616 2.68692C12.7561 2.79165 12.8626 2.91677 12.9658 3.04108C13.6398 3.06077 14.3035 2.93646 14.9151 2.68692C14.0598 1.7866 12.9965 1.05702 11.7608 0.58047C10.7373 0.18579 9.68493 -0.000584769 8.65002 -0.000366019C8.69542 0.115517 8.74534 0.22965 8.79959 0.341704C9.03661 0.410556 9.2727 0.490017 9.50725 0.58047Z", fill: "#DEEDF8" }),
                React.createElement("path", { d: "M6.04213 14.6285C4.8372 14.1639 3.56025 14.2204 2.45764 14.6861C3.30005 15.551 4.33758 16.2526 5.5378 16.7154C6.59628 17.1236 7.68571 17.3092 8.75474 17.2957C8.27458 16.1133 7.32685 15.124 6.04213 14.6285Z", fill: "#EAF3F9" }),
                React.createElement("path", { d: "M8.97987 13.4325C11.6188 13.25 13.6102 10.9628 13.4278 8.32384C13.2453 5.68488 10.9581 3.69349 8.31915 3.87594C5.68019 4.05839 3.6888 6.3456 3.87125 8.98456C4.05371 11.6235 6.34091 13.6149 8.97987 13.4325Z", fill: "white" }),
                React.createElement("path", { d: "M9.69332 11.116C9.52526 11.116 9.36629 11.015 9.3005 10.8493L7.77773 7.01459L7.26678 7.21748C7.04984 7.30356 6.80424 7.19757 6.71816 6.98074C6.63208 6.76385 6.73801 6.51819 6.95495 6.43206L7.85856 6.07325C8.07545 5.98718 8.3211 6.09316 8.40718 6.31L10.0859 10.5375C10.172 10.7543 10.0661 11 9.84912 11.0861C9.79805 11.1064 9.74522 11.116 9.69332 11.116Z", fill: "#FFC250" }),
                React.createElement("path", { d: "M18.1065 10.7939C19.056 10.6555 20.045 10.6741 21.0398 10.872C22.2639 11.1154 23.3757 11.6061 24.3338 12.2818L27.909 18.0867C28.0503 19.042 28.0328 20.0373 27.8336 21.0386C27.5901 22.2629 27.0993 23.375 26.4234 24.3333L20.7239 27.8916C19.7366 28.0502 18.705 28.0389 17.6669 27.8325C16.4798 27.5964 15.3982 27.1278 14.46 26.4833L10.814 20.7235C10.6553 19.736 10.6665 18.7041 10.873 17.6657C11.1091 16.4785 11.5779 15.3969 12.2224 14.4586L18.1065 10.7939Z", fill: "#679E51" }),
                React.createElement("path", { d: "M26.4233 24.3339C27.0992 23.3756 27.59 22.2635 27.8336 21.0392C28.0327 20.0378 28.0502 19.0425 27.9089 18.0873L24.3338 12.2823C23.3756 11.6066 22.2638 11.116 21.0398 10.8725C20.0449 10.6747 19.056 10.6561 18.1064 10.7945C18.2577 10.8164 18.4093 10.8424 18.5609 10.8725C23.2445 11.804 26.2861 16.3558 25.3547 21.0393C24.6212 24.7279 21.6419 27.3981 18.1213 27.9111C19.0055 28.0391 19.8808 28.0276 20.7238 27.8922L26.4233 24.3339Z", fill: "#487536" }),
                React.createElement("path", { d: "M16.0623 13.799C17.1478 13.0736 17.8407 11.9813 18.088 10.7969C16.8596 10.9778 15.6511 11.4269 14.5501 12.1626C13.607 12.7928 12.828 13.5765 12.2234 14.4582C13.4926 14.7801 14.8879 14.5837 16.0623 13.799Z", fill: "#EAF3F9" }),
                React.createElement("path", { d: "M22.7188 25.0226C21.6746 25.7204 20.9938 26.7577 20.7233 27.89C21.9157 27.6994 23.087 27.2551 24.1568 26.5402C25.0686 25.9309 25.8269 25.1781 26.4226 24.3321C25.1901 24.057 23.8518 24.2656 22.7188 25.0226Z", fill: "#EAF3F9" }),
                React.createElement("path", { d: "M23.8482 24.4664C23.2678 25.2579 22.5422 25.9635 21.6789 26.5403C21.4806 26.6729 21.2787 26.7961 21.0739 26.9101C20.9218 27.224 20.8049 27.5528 20.7242 27.89C21.9166 27.6994 23.088 27.2551 24.1578 26.5403C25.0696 25.931 25.8278 25.1782 26.4236 24.3322C25.5814 24.1443 24.6899 24.1821 23.8482 24.4664Z", fill: "#DEEDF8" }),
                React.createElement("path", { d: "M26.5415 14.5472C25.932 13.6352 25.179 12.8768 24.3327 12.281C24.0243 13.5399 24.2246 14.9191 25.0016 16.0817C25.7072 17.1377 26.7602 17.8216 27.9074 18.0858C27.7264 16.8571 27.2773 15.6483 26.5415 14.5472Z", fill: "#DEEDF8" }),
                React.createElement("path", { d: "M13.7783 22.7397C13.0608 21.666 11.9842 20.9769 10.8146 20.7227C11.0052 21.9148 11.4494 23.0859 12.1642 24.1554C12.7945 25.0986 13.5783 25.8777 14.46 26.4823C14.7484 25.2392 14.5433 23.8846 13.7783 22.7397Z", fill: "#EAF3F9" }),
                React.createElement("path", { d: "M22.7348 22.7372C24.6043 20.8677 24.6043 17.8367 22.7348 15.9672C20.8653 14.0977 17.8343 14.0977 15.9648 15.9672C14.0953 17.8367 14.0953 20.8677 15.9648 22.7372C17.8343 24.6067 20.8653 24.6067 22.7348 22.7372Z", fill: "white" }),
                React.createElement("path", { d: "M19.0395 22.0406C18.9049 22.0406 18.7686 22.0254 18.6327 21.9936C17.7449 21.7859 17.1517 20.9471 17.2528 20.0424C17.2786 19.8104 17.4883 19.6436 17.7196 19.6695C17.9515 19.6954 18.1185 19.9044 18.0926 20.1363C18.039 20.6158 18.354 21.0605 18.8251 21.1707C19.3099 21.2839 19.8055 21.0011 19.9533 20.5264C20.0323 20.2729 20.0047 20.0061 19.8755 19.7752C19.7464 19.5441 19.5343 19.3808 19.2782 19.3154C18.9226 19.2246 18.7628 19.2411 18.5597 19.2766C18.536 19.2807 18.521 19.284 18.5171 19.2848C18.3618 19.3195 18.1991 19.264 18.0979 19.141C17.9967 19.0179 17.9726 18.8486 18.0367 18.7028L18.862 16.8252C18.9453 16.6359 19.1532 16.5347 19.3534 16.5858L20.993 17.0044C21.2191 17.0621 21.3556 17.2922 21.2978 17.5183C21.2401 17.7444 21.0105 17.8809 20.7839 17.8232L19.4915 17.4932L19.0847 18.4187C19.2046 18.433 19.3361 18.458 19.4872 18.4966C19.963 18.6181 20.3734 18.9339 20.6132 19.363C20.8565 19.7983 20.9086 20.3007 20.7601 20.7776C20.5229 21.5402 19.8102 22.0406 19.0395 22.0406Z", fill: "#679E51" })),
            React.createElement("defs", null,
                React.createElement("clipPath", { id: "clip0" },
                    React.createElement("rect", { width: "28", height: "28", fill: "white" }))))));
};
