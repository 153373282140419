import { getOptionName } from '~utils/getOptionName';
import { getIsOcNamesSimilar } from './getIsOcNamesSimilar';
import { getIsGroupable } from './getIsGroupable';
var getShouldMarketsBeGrouped = function (positionsMap, mr, prevMr) {
    var _a, _b;
    var optionName = getOptionName(((_a = mr === null || mr === void 0 ? void 0 : mr.spec) === null || _a === void 0 ? void 0 : _a.lay) || (mr === null || mr === void 0 ? void 0 : mr.lay));
    var prevOptionName = getOptionName(((_b = prevMr === null || prevMr === void 0 ? void 0 : prevMr.spec) === null || _b === void 0 ? void 0 : _b.lay) || (prevMr === null || prevMr === void 0 ? void 0 : prevMr.lay));
    var isGroupable = getIsGroupable(optionName, prevOptionName);
    if (!isGroupable) {
        return false;
    }
    var isOcNamesSimilar = getIsOcNamesSimilar((mr === null || mr === void 0 ? void 0 : mr.oc) || [], (prevMr === null || prevMr === void 0 ? void 0 : prevMr.oc) || [], positionsMap, optionName, prevOptionName);
    return isOcNamesSimilar;
};
export { getShouldMarketsBeGrouped };
