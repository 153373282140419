import React, { useEffect } from 'react';
import classNames from 'classnames';
import i18next from 'i18next';
import { unwrapResult } from '@reduxjs/toolkit';
import Slide from '@material-ui/core/Slide';
import Paper from '@material-ui/core/Paper/Paper';
import { push } from "connected-react-router";
import { useAppDispatch } from '~store';
import { bpDataSelector, getBp, loadingSelector, startPolling, stopPolling, } from '~slices/bp';
import { resetTopRanking, saveProviderId, templateInfoSelector, } from '~slices/top-ranking';
import { FullscreenLoader, List } from '~atoms';
import { useFullHeight, useIsDesktop, useIsMobileDevise, usePlatofrmType, useStructuredSelector, } from '~hooks';
import { HorizonGalleryWithPlaceholder, Slider } from '~molecules';
import { isAuthenticatedSelector } from '~slices/auth';
import { BPRegistration } from '~organisms';
import { SLIDESHOW_TYPE, getTeasers, slideshowSelector } from '~slices/teasers';
import { isBetCheckProject } from "~utils/constants/betCheckConstants";
import { getPathWithLanguageCode } from "~utils/languageInURL";
import { useBpListStyles, useStyles } from './BPList.styles';
import { UnloggedBPList } from './components/UnloggedBPList';
import { itemRenderer } from './components';
export var BPList = function () {
    var dispatch = useAppDispatch();
    var _a = useStructuredSelector({
        items: bpDataSelector,
        isLoading: loadingSelector,
        isAuthenticated: isAuthenticatedSelector,
        templateInfo: templateInfoSelector,
        banners: function (state) { return slideshowSelector(state); },
    }), items = _a.items, isLoading = _a.isLoading, isAuthenticated = _a.isAuthenticated, providerId = _a.templateInfo.providerId, banners = _a.banners;
    var isDesktop = useIsDesktop();
    var slideshowPlatform = usePlatofrmType();
    var classes = useStyles();
    var styles = useBpListStyles();
    var height = useFullHeight();
    var isMobile = useIsMobileDevise();
    var isBetCheck = isBetCheckProject;
    useEffect(function () {
        if (!isBetCheck) {
            dispatch(push(getPathWithLanguageCode('/start')));
        }
    }, []);
    useEffect(function () {
        if (i18next.language) {
            dispatch(getTeasers({
                language: i18next.language,
                pageName: 'ANY_PAGE',
                platformType: slideshowPlatform,
                slideshowType: SLIDESHOW_TYPE,
                teaserStatus: isAuthenticated ? 'LOGGED_IN' : 'ANONYMOUS',
            }));
        }
    }, [i18next.language, isMobile, isAuthenticated]);
    var redirectToSdk = function (template, pId, linkType) {
        dispatch(saveProviderId({ providerId: pId, template: template, linkType: linkType }));
    };
    useEffect(function () {
        if (isAuthenticated) {
            dispatch(getBp(false))
                .then(unwrapResult)
                .then(function (res) {
                if (res.requestId) {
                    dispatch(startPolling(res.requestId));
                }
            });
        }
    }, [isAuthenticated]);
    useEffect(function () {
        return function () {
            dispatch(resetTopRanking());
            dispatch(stopPolling());
        };
    }, []);
    var wrapInTeasers = function (children) { return (React.createElement("div", { className: classNames('home-page', {
            'flex-column flex-scroll': isDesktop,
        }), style: providerId
            ? {
                overflow: 'hidden',
                height: height ? "".concat(height - 52, "px") : 'calc(100vh - 52px)',
            }
            : undefined },
        React.createElement("div", { className: classNames('flex-column', {
                'flex-scroll': isDesktop,
            }) },
            React.createElement("div", null,
                React.createElement(HorizonGalleryWithPlaceholder, { className: classes.teaserWithMargin })),
            React.createElement("div", { className: classNames({
                    'flex-scroll': isDesktop,
                }) },
                children,
                React.createElement("div", null, Array.isArray(banners) && Boolean(banners.length) && (React.createElement(React.Fragment, null,
                    React.createElement(Slider, { className: classes.teaserWithMargin, position: "BOTTOM_1", slideshows: banners }),
                    React.createElement(Slider, { className: classes.teaserWithMargin, position: "BOTTOM_2", slideshows: banners }),
                    React.createElement(Slider, { className: classes.teaserWithMargin, position: "BOTTOM_3", slideshows: banners }),
                    React.createElement(Slider, { className: classes.teaserWithMargin, position: "BOTTOM_4", slideshows: banners }),
                    React.createElement(Slider, { className: classes.teaserWithMargin, position: "BOTTOM_5", slideshows: banners }))))),
            !isDesktop && (React.createElement(Slide, { direction: "up", in: !!providerId, mountOnEnter: true, unmountOnExit: true, timeout: 500 },
                React.createElement(Paper, { elevation: 4, className: styles.paper, style: {
                        height: height ? "".concat(height - 50, "px") : 'calc(100vh - 50px)',
                        zIndex: 20,
                    } },
                    React.createElement(BPRegistration, null))))))); };
    var wrapInTopTeaser = function (children) { return (React.createElement("div", null,
        React.createElement(HorizonGalleryWithPlaceholder, { className: classes.teaserWithMargin }),
        children)); };
    return (React.createElement(React.Fragment, null,
        Array.isArray(items.responseObject) &&
            isAuthenticated &&
            wrapInTeasers(React.createElement(List, { items: items.responseObject, selectedProviderId: providerId, redirectToSdk: redirectToSdk, renderItem: itemRenderer, className: isDesktop ? classes.listWrapper : undefined })),
        !isAuthenticated &&
            (isDesktop
                ? wrapInTeasers(React.createElement(UnloggedBPList, null))
                : wrapInTopTeaser(React.createElement(UnloggedBPList, null))),
        isLoading === 'pending' && React.createElement(FullscreenLoader, null)));
};
