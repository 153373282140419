var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useRef, useState } from 'react';
import cn from 'classnames';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import FormGroup from '@material-ui/core/FormGroup';
import { Button } from '~atoms/button';
import { switchPlaces } from '~slices/markets';
import { useAppDispatch } from '~store';
import { useSelectionColumnsCount } from '~hooks';
import { useStyles } from './MarketFilters.styles';
export var MarketFiltersList = function (_a) {
    var markets = _a.markets, listIndex = _a.listIndex;
    var classes = useStyles();
    var dispatch = useAppDispatch();
    var columnsCount = useSelectionColumnsCount();
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    var anchorRef = useRef(null);
    var selected = markets[listIndex];
    var chooseMarket = function (a, b) {
        setOpen(false);
        dispatch(switchPlaces({ a: a, b: b }));
    };
    var handleClose = function (event) {
        if (anchorRef.current &&
            anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    var handleToggle = function () {
        setOpen(function (prevOpen) { return !prevOpen; });
    };
    return (React.createElement("div", { className: classes.listWrapper },
        listIndex === 0 ? (React.createElement(Button, { className: classes.dropdownButton },
            React.createElement("span", { className: classes.value }, selected.name))) : (React.createElement(Button, { ref: anchorRef, "aria-controls": open ? 'menu-list-grow' : undefined, "aria-haspopup": "true", onClick: handleToggle, className: classes.dropdownButton },
            React.createElement("span", { className: classes.value }, selected.name),
            open ? (React.createElement("i", { className: "far fa-chevron-up" })) : (React.createElement("i", { className: "far fa-chevron-down" })))),
        React.createElement(Popper, { open: open, anchorEl: anchorRef.current, placement: "bottom-end", role: undefined, transition: true, disablePortal: true, style: {
                zIndex: 3,
            } }, function (_a) {
            var TransitionProps = _a.TransitionProps, placement = _a.placement;
            return (React.createElement(Grow, __assign({}, TransitionProps, { style: {
                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                } }),
                React.createElement(Paper, { className: classes.systemModal },
                    React.createElement(ClickAwayListener, { onClickAway: handleClose },
                        React.createElement(MenuList, { autoFocusItem: open, disablePadding: true, id: "menu-list-grow" },
                            React.createElement(FormGroup, { className: classes.group }, markets.map(function (market, i) {
                                return i !== 0 && (React.createElement(Button, { key: market.id, disabled: i < columnsCount, onClick: function () {
                                        chooseMarket(listIndex, i);
                                    }, classes: { root: classes.buttonRoot }, className: cn(classes.button, selected.id === market.id ? classes.selected : '') },
                                    selected.id === market.id && (React.createElement("i", { className: cn('far fa-check', classes.selectedIcon) })),
                                    market.name));
                            })))))));
        })));
};
