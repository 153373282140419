var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { push } from 'connected-react-router';
import { StyledTab, StyledTabs } from '~atoms';
import { useAppDispatch } from '~store';
import { useIsDesktop } from '~hooks';
import { getPathWithLanguageCode } from '~utils/languageInURL/getPathWithLanguageCode';
import { useStyles } from '../Sidebets.styles';
import { WidgetTab } from './WidgetTab';
export var WidgetTabs = function () {
    var classes = useStyles();
    var location = useLocation();
    var dispatch = useAppDispatch();
    var isDesktop = useIsDesktop();
    var t = useTranslation('ticket').t;
    var _a = useState('live-tracker'), widgetTab = _a[0], setWidgetTab = _a[1];
    var changeTab = function (event, value) {
        setWidgetTab(value);
    };
    var goBackFromSideBets = function () {
        var _a = location.state || {}, round = _a.round, prevPath = _a.prevPath, scrollPosition = _a.scrollPosition;
        var state = {
            scrollPosition: scrollPosition,
            scrollFlag: true,
            isFromSideBets: true,
        };
        if (round) {
            dispatch(push({
                pathname: prevPath,
                state: __assign(__assign({}, state), { round: round }),
            }));
        }
        else if (prevPath) {
            dispatch(push({
                pathname: prevPath,
                state: state,
            }));
        }
        else {
            var path = getPathWithLanguageCode('/home/all/live');
            dispatch(push(path));
        }
    };
    return (React.createElement("div", null,
        React.createElement("div", { className: classes.widgetWrapper },
            React.createElement(WidgetTab, { tab: widgetTab })),
        React.createElement("div", { className: classes.tabsWrapper },
            React.createElement("div", { className: classNames('flex-row flex-center', classes.tab) },
                !isDesktop && (React.createElement("button", { onClick: goBackFromSideBets, className: classes.button, type: "button" },
                    React.createElement("i", { className: "fas fa-chevron-left" }))),
                React.createElement(StyledTabs, { variant: "fullWidth", value: widgetTab, 
                    // @ts-expect-error
                    onChange: changeTab, rootClassName: classes.root, orientation: "horizontal" },
                    React.createElement(StyledTab, { label: t('commentary'), className: classes.tabText, value: "commentary", textColorClassNames: classes.textColorInherit }),
                    React.createElement(StyledTab, { label: t('live_stream'), value: "live-stream", className: classes.tabText, textColorClassNames: classes.textColorInherit }),
                    React.createElement(StyledTab, { label: t('live_tracker'), value: "live-tracker", className: classes.tabText, textColorClassNames: classes.textColorInherit }),
                    React.createElement(StyledTab, { label: t('statistics'), value: "statistics", className: classes.tabText, textColorClassNames: classes.textColorInherit }))))));
};
