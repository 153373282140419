var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable max-lines */
// TODO: fix no-non-null-assertion issues
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createAction, createSlice, } from '@reduxjs/toolkit';
import moment from 'moment';
import groupBy from 'lodash.groupby';
import mergeWith from 'lodash.mergewith';
import cloneDeep from 'lodash.clonedeep';
import unionBy from 'lodash.unionby';
import { normalize, schema } from 'normalizr';
import { FINISHED_STATUSES } from '~utils/events';
import { SIDEBETS_UPDATES_NAME } from '~slices/sidebets/constants';
import { addToFavourite, removeFromFavourite } from '~slices/events/thunks';
import { ENDED_EVENT_AFTER_THE_ACTION, ENDED_EVENT_LIFETIME, ENDED_EVENT_WAITING_FOR_ACTION, FAVOURITES_SOCKET_NAME, HIGHLIGHTS_SOCKET_NAME, LIVE_SOCKET_NAME, LIVE_SOCKET_SOON_LIVE_SECTION_NAME, MATCHDAY_SOCKET_NAME, UPDATE_TYPE_MATCH, UPDATE_TYPE_ODD, name, } from './constants';
var sportEntity = new schema.Entity('sp', undefined, {
    processStrategy: function (value, parent) { return (__assign(__assign({}, value), { tournamentsId: [parent.tn] })); },
    mergeStrategy: function (valueA, valueB) {
        return __assign(__assign(__assign({}, valueA), valueB), { tournamentsId: unionBy(valueA.tournamentsId, valueB.tournamentsId) });
    },
});
var tournamentEntity = new schema.Entity('tn', undefined, {
    processStrategy: function (value, parent) {
        var markets = parent.mr || {};
        var tournamentMarkets = Object.keys(markets).reduce(function (acc, mk) {
            var _a;
            var market = markets[mk];
            if (!FINISHED_STATUSES.includes((parent === null || parent === void 0 ? void 0 : parent.ms) || '')) {
                return __assign(__assign({}, acc), (_a = {}, _a["".concat(market.bid)] = market, _a));
            }
            return acc;
        }, {});
        return __assign(__assign({}, value), { eventsId: [parent.bid], categoryId: parent.ct, tournamentMarkets: tournamentMarkets });
    },
    mergeStrategy: function (valueA, valueB) { return (__assign(__assign(__assign({}, valueA), valueB), { eventsId: __spreadArray(__spreadArray([], valueA.eventsId, true), valueB.eventsId, true), tournamentMarkets: __assign(__assign({}, valueA.tournamentMarkets), valueB.tournamentMarkets) })); },
});
var categoryEntity = new schema.Entity('ct', undefined, {
    processStrategy: function (value, parent, key) { return (__assign(__assign({}, value), { eventsId: [parent.bid] })); },
    mergeStrategy: function (valueA, valueB) { return (__assign(__assign(__assign({}, valueA), valueB), { eventsId: __spreadArray(__spreadArray([], valueA.eventsId, true), valueB.eventsId, true) })); },
    idAttribute: function (value) { return value.bid; },
});
var eventEntity = new schema.Entity('events', {
    ct: categoryEntity,
    tn: tournamentEntity,
    sp: sportEntity,
}, {
    idAttribute: function (value) { return value.bid; },
});
export var eventsEntity = new schema.Array(eventEntity);
var initialState = {
    data: {},
    meta: {
        deletedEventsIds: [],
    },
    loading: 'idle',
    error: null,
};
export var connectSocket = createAction("".concat(name, "/connectSocket"), function (params) { return ({
    payload: params,
}); });
export var sendMessage = createAction("".concat(name, "/sendMessage"), function (params) { return ({
    payload: params,
}); });
export var getEvents = createAction("".concat(name, "/getEvents"), function (data, eventsType) {
    var normalized = normalize(data, eventsEntity);
    // @ts-expect-error
    var tournamentsOrder = Array.from(new Set(data.map(function (d) { return d.tn.id; }))).filter(Boolean);
    return {
        payload: __assign(__assign({ tnOrder: tournamentsOrder }, normalized.entities), { eventsType: eventsType }),
    };
});
export var socketMessage = createAction("".concat(name, "/socketMessage"));
export var updateEvent = createAction("".concat(name, "/updateEvent"), function (data, shortcut, eventsType) {
    var _a;
    var _b = groupBy(data, 'ut'), _c = UPDATE_TYPE_MATCH, match = _b[_c], _d = UPDATE_TYPE_ODD, odd = _b[_d];
    return {
        payload: (_a = {},
            _a[UPDATE_TYPE_MATCH] = match || [],
            _a[UPDATE_TYPE_ODD] = odd || [],
            _a.shortcut = shortcut,
            _a.eventsType = eventsType,
            _a),
    };
});
export var addEvent = createAction("".concat(name, "/addEvent"), function (data, eventsType) {
    var normalized = normalize(data, eventsEntity);
    return {
        payload: __assign(__assign({}, normalized.entities), { eventsType: eventsType }),
    };
});
var SOCKETS_WITH_DELETABLE_EVENTS = [
    LIVE_SOCKET_NAME,
    LIVE_SOCKET_SOON_LIVE_SECTION_NAME,
    HIGHLIGHTS_SOCKET_NAME,
];
var getDeletionTime = function () {
    return Date.now() + ENDED_EVENT_LIFETIME * 1000;
};
export var events = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        resetEvents: function (state, _a) {
            var _b;
            var payload = _a.payload;
            return __assign(__assign({}, state), { data: __assign(__assign({}, state.data), (_b = {}, _b[payload] = {}, _b)) });
        },
        resetSidebetsEvents: function (state) {
            var newState = cloneDeep(state);
            if (newState.data[SIDEBETS_UPDATES_NAME]) {
                delete newState.data[SIDEBETS_UPDATES_NAME];
            }
            return __assign(__assign({}, newState), { loading: 'fulfilled' });
        },
        startLoading: function (state) { return (__assign(__assign({}, state), { loading: 'pending' })); },
        socketError: function (state) { return (__assign(__assign({}, state), { loading: 'rejected' })); },
        deleteEvent: function (state, _a) {
            var _b, _c;
            var _d;
            var payload = _a.payload;
            var isEventDeletable = SOCKETS_WITH_DELETABLE_EVENTS.includes(payload.eventsType);
            var eventsTypeData = __assign({}, state.data[payload.eventsType]);
            var tnKey = (_d = eventsTypeData === null || eventsTypeData === void 0 ? void 0 : eventsTypeData.events) === null || _d === void 0 ? void 0 : _d[Number(payload.eventId)].tn;
            if (!eventsTypeData.events) {
                return __assign({}, state);
            }
            var newTnOrder = __spreadArray([], eventsTypeData.tnOrder, true);
            var newEventsid = __spreadArray([], eventsTypeData.tn[tnKey].eventsId, true);
            var eventsAfterDeleting = Object.values((eventsTypeData === null || eventsTypeData === void 0 ? void 0 : eventsTypeData.events) || {})
                .filter(function (event) {
                return isEventDeletable ? event.bid !== payload.eventId : true;
            })
                .reduce(function (acc, event) {
                var _a;
                return __assign(__assign({}, acc), (_a = {}, _a[event.bid] = __assign(__assign({}, event), { deletionTime: event.bid === payload.eventId ? undefined : event.deletionTime, endedStatus: event.bid === payload.eventId
                        ? ENDED_EVENT_AFTER_THE_ACTION
                        : event.endedStatus }), _a));
            }, {});
            if (eventsTypeData.tn["".concat(tnKey)].eventsId.length === 1 &&
                tnKey &&
                isEventDeletable) {
                newTnOrder = newTnOrder.filter(function (key) { return key !== +tnKey; });
            }
            else if (isEventDeletable) {
                newEventsid = newEventsid.filter(function (key) { return key !== payload.eventId; });
            }
            var prevDeletedEventsIds = state.meta.deletedEventsIds || [];
            var deletedEventsIds = isEventDeletable && payload.eventId
                ? Array.from(new Set(__spreadArray(__spreadArray([], prevDeletedEventsIds, true), [payload.eventId], false)))
                : prevDeletedEventsIds;
            return __assign(__assign({}, state), { data: __assign(__assign({}, state.data), (_b = {}, _b[payload.eventsType] = __assign(__assign({}, eventsTypeData), { tn: __assign(__assign({}, eventsTypeData.tn), (_c = {}, _c[tnKey] = __assign(__assign({}, eventsTypeData.tn[tnKey]), { eventsId: __spreadArray([], newEventsid, true) }), _c)), events: eventsAfterDeleting, tnOrder: newTnOrder }), _b)), meta: {
                    deletedEventsIds: deletedEventsIds,
                } });
        },
    },
    extraReducers: function (builder) {
        builder.addCase(addEvent.toString(), function (state, _a) {
            var _b;
            var payload = _a.payload;
            var eventsType = payload.eventsType, rest = __rest(payload, ["eventsType"]);
            var clonedState = cloneDeep(state.data[eventsType]);
            var tnKeys = __spreadArray([], Object.keys(__assign(__assign({}, clonedState.tn), rest.tn)), true);
            var newState = {
                ct: __assign(__assign({}, clonedState.ct), rest.ct),
                events: __assign(__assign({}, clonedState.events), rest.events),
                // @ts-expect-error
                eventsType: clonedState.eventsType,
                sp: __assign(__assign({}, clonedState.sp), rest.sp),
                tn: tnKeys.reduce(function (acc, tnKey) {
                    var _a, _b;
                    var prevTn = (_a = clonedState === null || clonedState === void 0 ? void 0 : clonedState.tn) === null || _a === void 0 ? void 0 : _a[tnKey];
                    var tn = (_b = rest === null || rest === void 0 ? void 0 : rest.tn) === null || _b === void 0 ? void 0 : _b[tnKey];
                    if (!tn && prevTn) {
                        acc[tnKey] = prevTn;
                        return acc;
                    }
                    if (!prevTn && tn) {
                        acc[tnKey] = tn;
                        return acc;
                    }
                    if (tn && prevTn) {
                        acc[tnKey] = __assign(__assign(__assign({}, prevTn), tn), { eventsId: Array.from(new Set(__spreadArray(__spreadArray([], prevTn.eventsId, true), tn.eventsId, true))), tournamentMarkets: __assign(__assign({}, prevTn.tournamentMarkets), tn.tournamentMarkets) });
                        return acc;
                    }
                    return acc;
                }, {}),
                tnOrder: __spreadArray([], clonedState.tnOrder, true),
            };
            return __assign(__assign({}, state), { data: __assign(__assign({}, state.data), (_b = {}, _b[eventsType] = newState, _b)) });
        });
        // @ts-expect-error
        builder.addCase(updateEvent.toString(), function (state, _a) {
            var payload = _a.payload;
            var _b = payload, _c = UPDATE_TYPE_MATCH, match = _b[_c], _d = UPDATE_TYPE_ODD, odd = _b[_d], eventsType = _b.eventsType;
            match.forEach(function (_a) {
                var _b;
                var bid = _a.bid, ut = _a.ut, lu = _a.lu, departureTime = _a.departureTime, sportEventsArriveTime = _a.sportEventsArriveTime, rest = __rest(_a, ["bid", "ut", "lu", "departureTime", "sportEventsArriveTime"]);
                var events = (_b = state.data[eventsType]) === null || _b === void 0 ? void 0 : _b.events;
                var event = events === null || events === void 0 ? void 0 : events[Number(bid)];
                if (typeof bid !== 'number' || !event) {
                    return;
                }
                if (!FINISHED_STATUSES.includes(event.ms || '') &&
                    FINISHED_STATUSES.includes(rest.ms || '')) {
                    rest.endedStatus = ENDED_EVENT_WAITING_FOR_ACTION;
                    rest.deletionTime = getDeletionTime();
                }
                events[bid] = mergeWith(event, rest, function (objValue, srcValue, key) {
                    if (key === 'tn' || key === 'ct' || key === 'sp') {
                        return objValue;
                    }
                });
            });
            odd.forEach(function (_a) {
                var _b, _c;
                var bid = _a.bid, rest = __rest(_a, ["bid"]);
                if ((_c = (_b = state.data[eventsType]) === null || _b === void 0 ? void 0 : _b.events) === null || _c === void 0 ? void 0 : _c[bid]) {
                    Object.entries(rest.mr || {}).forEach(function (_a) {
                        var _b;
                        var _c, _d;
                        var mk = _a[0], market = _a[1];
                        var oldMarketKey = Object.keys(state.data[eventsType].events[bid].mr || {}).find(function (mk) {
                            return mk === "".concat(market.bid) || mk.startsWith("".concat(market.bid, "_"));
                        });
                        if (oldMarketKey && oldMarketKey === mk) {
                            // eslint-disable-next-line no-param-reassign,consistent-return
                            state.data[eventsType].events[bid].mr[oldMarketKey].oc =
                                mergeWith((_c = state.data[eventsType].events[bid].mr[oldMarketKey]) === null || _c === void 0 ? void 0 : _c.oc, market.oc, function (objValue, srcValue) {
                                    return srcValue;
                                });
                        }
                        if (oldMarketKey && oldMarketKey !== mk) {
                            // eslint-disable-next-line no-param-reassign
                            state.data[eventsType].events[bid].mr = __assign(__assign({}, state.data[eventsType].events[bid].mr), (_b = {}, _b[mk] = __assign(__assign(__assign({}, state.data[eventsType].events[bid].mr[oldMarketKey]), market), { oc: mergeWith((_d = state.data[eventsType].events[bid].mr[oldMarketKey]) === null || _d === void 0 ? void 0 : _d.oc, market.oc, function (objValue, srcValue) {
                                    return srcValue;
                                }) }), _b));
                            // eslint-disable-next-line no-param-reassign
                            delete state.data[eventsType].events[bid].mr[oldMarketKey];
                        }
                    });
                    // eslint-disable-next-line no-param-reassign
                    state.data[eventsType].events[bid] = __assign(__assign({}, state.data[eventsType].events[bid]), { mr: __assign({}, state.data[eventsType].events[bid].mr) });
                }
            });
            return state;
        });
        builder.addCase(connectSocket.toString(), function (state) {
            return __assign(__assign({}, state), { loading: 'pending', data: __assign({}, state.data) });
        });
        builder.addCase(getEvents.toString(), function (state, _a) {
            var _b;
            var _c, _d, _e, _f;
            var payload = _a.payload;
            var eventsTypeData = __assign({}, state.data[payload.eventsType]);
            Object.values(payload.events || {}).forEach(
            // @ts-expect-error
            function (event) {
                var _a, _b, _c, _d;
                var bid = event.bid;
                var prevEvent = (_a = eventsTypeData.events) === null || _a === void 0 ? void 0 : _a[Number(bid)];
                var isEnded = FINISHED_STATUSES.includes(event.ms || '');
                var prevIsEnded = FINISHED_STATUSES.includes((prevEvent === null || prevEvent === void 0 ? void 0 : prevEvent.ms) || '');
                var isDeleted = !!state.meta.deletedEventsIds.includes(Number(bid));
                if (!prevEvent && isDeleted) {
                    var tnKey_1 = (_b = payload === null || payload === void 0 ? void 0 : payload.events) === null || _b === void 0 ? void 0 : _b[Number(bid)].tn;
                    delete (payload.events || {})[Number(bid)];
                    if (payload.tn["".concat(tnKey_1)].eventsId.length === 1 && tnKey_1) {
                        payload.tnOrder = payload.tnOrder.filter(function (key) { return key !== +tnKey_1; });
                    }
                    else if ((_c = payload.tn) === null || _c === void 0 ? void 0 : _c[tnKey_1].eventsId) {
                        var eventsId = (_d = payload.tn) === null || _d === void 0 ? void 0 : _d[tnKey_1].eventsId;
                        payload.tn[tnKey_1].eventsId = eventsId.filter(function (key) { return key !== bid; });
                    }
                    return;
                }
                if (prevEvent && prevIsEnded) {
                    event.endedStatus = prevEvent.endedStatus;
                    event.deletionTime = prevEvent.deletionTime;
                    return;
                }
                if (prevEvent && !prevIsEnded && isEnded) {
                    prevEvent.endedStatus = ENDED_EVENT_WAITING_FOR_ACTION;
                    prevEvent.deletionTime = getDeletionTime();
                    event.endedStatus = ENDED_EVENT_WAITING_FOR_ACTION;
                    event.deletionTime = getDeletionTime();
                }
            });
            var eventsEndedBeforeResponce = Object.values(payload.eventsType !== MATCHDAY_SOCKET_NAME
                ? (eventsTypeData === null || eventsTypeData === void 0 ? void 0 : eventsTypeData.events) || {}
                : {})
                .filter(function (event) {
                return (payload.events &&
                    !payload.events[event.bid] &&
                    FINISHED_STATUSES.includes(event.ms || ''));
            })
                .reduce(function (acc, event) {
                var _a;
                return __assign(__assign({}, acc), (_a = {}, _a[event.bid] = event, _a));
            }, {});
            var tnsWithEndedEvents = groupBy(Object.values(eventsEndedBeforeResponce), 'tn');
            if (!(((_d = (_c = payload.sp) === null || _c === void 0 ? void 0 : _c[Object.keys(payload.sp)[0]]) === null || _d === void 0 ? void 0 : _d.id) !==
                ((_f = (_e = eventsTypeData.sp) === null || _e === void 0 ? void 0 : _e[Object.keys(eventsTypeData.sp)[0]]) === null || _f === void 0 ? void 0 : _f.id)) &&
                payload.eventsType === LIVE_SOCKET_NAME) {
                Object.keys(tnsWithEndedEvents).forEach(function (tnKey) {
                    // @ts-expect-error
                    if (Object.keys(payload.tn).indexOf(tnKey) === -1) {
                        // @ts-expect-error
                        payload.tn[tnKey] = __assign(__assign({}, eventsTypeData === null || eventsTypeData === void 0 ? void 0 : eventsTypeData.tn[tnKey]), { eventsId: __spreadArray([], tnsWithEndedEvents[tnKey].map(
                            // @ts-expect-error
                            function (endedEvent) { return +(endedEvent === null || endedEvent === void 0 ? void 0 : endedEvent.bid); }), true) });
                        var tnOrderIndex = eventsTypeData.tnOrder.indexOf(+tnKey);
                        payload.tnOrder.splice(tnOrderIndex, 0, +tnKey);
                    }
                    else {
                        // оконченный ивент после респонса остаётся на совем месте
                        tnsWithEndedEvents[tnKey].forEach(function (endedEvent) {
                            var endedEventIndex = eventsTypeData.tn[tnKey
                            // @ts-expect-error
                            ].eventsId.indexOf(+(endedEvent === null || endedEvent === void 0 ? void 0 : endedEvent.bid));
                            // @ts-expect-error
                            payload.tn[tnKey].eventsId.splice(endedEventIndex, 0, 
                            // @ts-expect-error
                            +endedEvent.bid);
                        });
                        // оконченный ивент после респонса опускается в конец группы
                        // // eslint-disable-next-line no-param-reassign
                        // payload.tn[tnKey].eventsId = [
                        //   ...payload.tn[tnKey].eventsId,
                        //   // @ts-ignore
                        //   ...tnsWithEndedEvents[tnKey]
                        //     // @ts-ignore
                        //     .map((endedEvent) => +endedEvent.bid!),
                        // ];
                        var tnOrderIndex = eventsTypeData.tnOrder.indexOf(+tnKey);
                        if (payload.tnOrder.indexOf(+tnKey) === -1) {
                            payload.tnOrder.splice(tnOrderIndex, 0, +tnKey);
                        }
                    }
                });
            }
            return __assign(__assign({}, state), { loading: 'fulfilled', data: __assign(__assign({}, state.data), (_b = {}, _b[payload.eventsType] = __assign(__assign({}, payload), { events: __assign(__assign({}, payload.events), eventsEndedBeforeResponce), tnOrder: payload.tnOrder }), 
                // TODO: fix time prop issue got because of mapped type of data
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                _b.time = moment().format('hh:mm:ss.SSS a'), _b)) });
        });
        builder.addCase(removeFromFavourite.fulfilled.toString(), function (state, _a) {
            var payload = _a.payload;
            Object.values(state.data).forEach(function (_a) {
                var events = _a.events;
                if (events === null || events === void 0 ? void 0 : events[payload.eventId]) {
                    events[payload.eventId].fav = false;
                }
            });
            var tns = state.data[FAVOURITES_SOCKET_NAME].tn;
            if (tns) {
                Object.values(tns).forEach(function (tn) {
                    tn.eventsId = tn.eventsId.filter(function (eventId) {
                        return eventId !== payload.eventId;
                    });
                });
            }
        });
        builder.addCase(addToFavourite.fulfilled.toString(), function (state, _a) {
            var payload = _a.payload;
            Object.values(state.data).forEach(function (_a) {
                var events = _a.events;
                if (events === null || events === void 0 ? void 0 : events[payload.eventId]) {
                    events[payload.eventId].fav = true;
                }
            });
        });
    },
});
export default events.reducer;
export var resetEvents = (_a = events.actions, _a.resetEvents), startLoading = _a.startLoading, socketError = _a.socketError, resetSidebetsEvents = _a.resetSidebetsEvents, deleteEvent = _a.deleteEvent;
