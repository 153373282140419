var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import withStyles from '@material-ui/core/styles/withStyles';
var GreenCheckbox = withStyles({
    root: {
        color: '#fff',
        '&$checked': {
            color: '#00C150',
        },
    },
    checked: {},
})(function (props) { return React.createElement(Checkbox, __assign({ color: "default" }, props)); });
export { GreenCheckbox };
