var checkIsSubstringIncluded = function (str) {
    return function (subStr) {
        return (!!str &&
            !!str.trim().length &&
            !!subStr &&
            !!subStr.trim().length &&
            str.trim().toLowerCase().includes(subStr.trim().toLowerCase()));
    };
};
export { checkIsSubstringIncluded };
