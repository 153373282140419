import makeStyles from '@material-ui/core/styles/makeStyles';
import { ZIndex } from '~constants/view/ZIndex';
export var useStyles = makeStyles(function (theme) {
    var _a;
    return ({
        paddingBottom: {
            paddingBottom: '3px',
        },
        headerContainer: (_a = {
                userSelect: 'none',
                cursor: 'pointer',
                outline: 'none',
                position: 'fixed',
                bottom: '-3px',
                left: '-3px',
                display: 'flex',
                justifyContent: 'space-between',
                width: 'calc(100% + 6px)',
                height: '54px',
                padding: '0 16px',
                zIndex: 2,
                background: 'radial-gradient(100% 100% at 50.13% 0%, #3C3C46 0%, #1B1B22 100%)',
                border: '3px solid #8E8E8E',
                borderBottom: 'none',
                borderRadius: '12px 12px 0px 0px',
                boxSizing: 'border-box',
                overflowY: 'auto'
            },
            _a[theme.breakpoints.up('lg')] = {
                position: 'relative',
            },
            _a),
        pointsContainer: {
            display: 'flex',
            justifyContent: 'flex-start',
            height: 'max-content',
            marginTop: '14px',
            alignItems: 'center',
            padding: 0,
            width: '33%',
        },
        moContainer: {
            justifySelf: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 0,
            width: '33%',
        },
        betType: {
            fontWeight: 'normal',
            fontSize: '10px',
            color: '#fff',
        },
        currency: {
            fontSize: '12px',
            lineHeight: '18px',
            color: '#fff',
        },
        pointsRow: {
            display: 'flex',
            fontSize: '16px',
            lineHeight: '18px',
            color: '#FFFFFF',
        },
        icon: {
            fontSize: '17px',
            marginRight: '8px',
            color: '#FFFFFF',
        },
        moIcon: {
            color: '#00C150',
            marginRight: '8px',
        },
        active: {
            color: '#2AA1F7',
        },
        bettingSlipButtonWrapper: {
            display: 'flex',
            justifyContent: 'center',
            width: '33%',
        },
        bettingSlip: {
            display: 'grid',
            justifyContent: 'center',
            alignContent: 'center',
            height: '100%',
            backgroundColor: '#151515',
            borderRadius: '100px 100px 0 0 ',
            width: 129,
        },
        text: {
            fontSize: '10px',
            color: '#2AA1F7',
        },
        iconStyles: {
            fontSize: '8px',
            marginLeft: '5px',
        },
        count: {
            fontSize: '16px',
            lineHeight: '18px',
            color: '#FFFFFF',
        },
        root: {
            top: '52px',
            zIndex: '1299 !important',
        },
        paper: {
            width: '100vw',
            minWidth: '100vw',
            maxWidth: '100vw',
            boxSizing: 'border-box',
            zIndex: ZIndex.BETSLIP_CONTAINER,
            background: 'transparent',
        },
        paperMobile: {
            overflowX: 'hidden',
        },
        rounded: {
            borderRadius: '0',
        },
        capitalize: {
            textTransform: 'capitalize',
            fontSize: '16px',
        },
    });
}, { name: 'BettingSlipShortcut' });
