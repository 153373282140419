import React from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from '~modals';
import { EditSecurity } from '~pages/my-account/EditSecurity';
import { useStyles } from './EditModals.styles';
import { EditModalContainer } from './EditModalContainer';
export var EditSecurityModal = function (_a) {
    var modalName = _a.modalName;
    var dispatch = useDispatch();
    var classes = useStyles();
    var handleCancel = function () {
        dispatch(closeModal({ key: modalName }));
    };
    return (React.createElement(EditModalContainer, { handleCancel: handleCancel },
        React.createElement(EditSecurity, { handleClose: handleCancel, titleClassName: classes.title })));
};
