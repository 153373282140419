import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var CricketIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, viewBox: "0 0 28 28" },
        React.createElement("svg", { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M26.6061 3.29793L20.6754 9.22798C20.3522 9.55171 20.1895 9.97525 20.1895 10.3988C20.1895 10.8236 20.3522 11.2477 20.6754 11.5709L18.912 13.3342L18.3388 13.9081L17.594 14.6522L17.0207 15.2261L5.62287 26.6234C5.12076 27.1255 4.30735 27.1255 3.80525 26.6234L1.37635 24.1951C0.874848 23.693 0.874251 22.8796 1.37635 22.3775L1.73441 22.0195L2.81603 20.9378L5.68471 18.0691L6.75505 16.9988L12.7742 10.9795L13.3481 10.4063L14.0923 9.66149L14.6655 9.08823L16.4289 7.32488C17.0763 7.97174 18.1249 7.97174 18.7723 7.32488L24.7024 1.39357C24.965 1.13163 25.3099 1 25.6536 1C25.9986 1 26.3435 1.13163 26.6061 1.39357C27.1313 1.91943 27.1313 2.77147 26.6061 3.29793Z", fill: "#FFD15C" }),
            React.createElement("path", { d: "M18.9121 13.3342L18.3389 13.9081L14.0923 9.66156L14.6655 9.0883L18.9121 13.3342Z", fill: "#FF525D" }),
            React.createElement("path", { d: "M17.5941 14.6522L17.0209 15.2261L12.7743 10.9795L13.3481 10.4063L17.5941 14.6522Z", fill: "#FF525D" }),
            React.createElement("path", { d: "M7.42178 18.7828V18.809C7.42178 20.3528 6.1699 21.6047 4.6261 21.6047C3.08165 21.6047 1.82977 20.3528 1.82977 18.809C1.82977 17.6769 2.50219 16.7025 3.46962 16.2628C3.82206 16.1025 4.21378 16.0133 4.62604 16.0133C6.16118 16.0133 7.40745 17.2502 7.42178 18.7828Z", fill: "#EF4152" }),
            React.createElement("path", { d: "M26.6061 3.29793L20.6753 9.22798C20.3522 9.55171 20.1894 9.97525 20.1894 10.3988C20.1894 10.8236 20.3522 11.2477 20.6753 11.5709L16.4288 7.32494C17.0762 7.9718 18.1248 7.9718 18.7722 7.32494L24.7023 1.39363C24.965 1.13163 25.3099 1 25.6536 1C25.9985 1 26.3435 1.13163 26.6061 1.39357C27.1313 1.91943 27.1313 2.77147 26.6061 3.29793Z", fill: "#B58657" }),
            React.createElement("path", { d: "M26.9596 3.6515L26.9601 3.65106C27.6798 2.92955 27.6801 1.76144 26.9599 1.04026L26.9593 1.03958C26.5991 0.680375 26.1255 0.5 25.6536 0.5C25.1827 0.5 24.7092 0.680551 24.3493 1.03958L24.3488 1.04006L18.4189 6.97116C17.9667 7.42298 17.2345 7.42298 16.7823 6.97116L16.4287 6.61793L16.0753 6.97133L6.75434 16.2924C6.18011 15.8063 5.43732 15.5133 4.62608 15.5133C4.14104 15.5133 3.67885 15.6184 3.26266 15.8077C2.12335 16.3255 1.32981 17.4737 1.32981 18.809C1.32981 19.6203 1.62306 20.3631 2.10936 20.9374L1.0228 22.024C0.325168 22.7216 0.32637 23.8514 1.02259 24.5485L1.02284 24.5487L3.4517 26.977C3.45171 26.977 3.45173 26.977 3.45174 26.977C4.14911 27.6743 5.27907 27.6743 5.97642 26.977L5.62287 26.6234L5.97642 26.977L21.0289 11.9244L21.3825 11.5709L21.0289 11.2173C20.8029 10.9913 20.6895 10.6958 20.6895 10.3988C20.6895 10.1035 20.8026 9.80824 21.0293 9.58121L26.9596 3.6515Z", stroke: "black" }))));
};
