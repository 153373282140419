import makeStyles from '@material-ui/core/styles/makeStyles';
export var useStyles = makeStyles(function () { return ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        color: '#6CFF00',
        fontSize: '12px',
        fontWeight: 400,
        textTransform: 'none',
        '& span': {
            marginRight: '5px',
        },
        '& img': {
            height: '11.81px',
            width: '10.21px',
            marginRight: '5px',
        },
        '& svg': {
            height: '11.72px',
            width: '11.93px',
        },
    }
}); }, { name: 'BalanceTrend' });
