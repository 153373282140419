import DailyGameIcon from "~icons/games/calendar-daily.svg";
import WeeklyGameIcon from "~icons/games/calendar-weekly.svg";
import GroupGameIcon from "~icons/games/group.svg";
import Medal from "~icons/games/medal.svg";
export var gameIcons = {
    'WEEKLY': WeeklyGameIcon,
    'DAILY': DailyGameIcon,
    'TOURNAMENT': Medal,
    'GROUP': GroupGameIcon,
};
