var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { Redirect, Route, withRouter, } from 'react-router-dom';
import { isAuthenticatedSelector } from '~slices/auth';
import { useStructuredSelector } from '~hooks';
import { getPathWithLanguageCode } from '~utils/languageInURL/getPathWithLanguageCode';
var PrivateRouteTemp = function (_a) {
    var Component = _a.component, rest = __rest(_a, ["component"]);
    var isAuthenticated = useStructuredSelector({
        isAuthenticated: isAuthenticatedSelector,
    }).isAuthenticated;
    var render;
    if (isAuthenticated) {
        // eslint-disable-next-line react/display-name
        render = function (props) { return React.createElement(Component, __assign({}, props)); };
    }
    else {
        // eslint-disable-next-line react/display-name
        render = function (props) {
            return (React.createElement(Redirect, { to: {
                    pathname: getPathWithLanguageCode('/login'),
                    state: { from: props.location },
                } }));
        };
    }
    return React.createElement(Route, __assign({}, rest, { render: render }));
};
export var PrivateRoute = withRouter(PrivateRouteTemp);
