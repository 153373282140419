var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import failed from '~icons/statusIcons/failed.svg';
import { ConfirmationModal, } from '~modals/components/ConfirmationModal';
import { StatusScreen } from '~molecules';
import { errorSelector } from '~slices/auth';
import { useStructuredSelector } from '~hooks';
var useErrorModalStyles = makeStyles({
    root: {
        zIndex: '99999 !important',
    },
    color: {
        color: '#212121',
    },
    fullWidth: { width: '100%' },
    error: {
        textAlign: 'center',
        fontSize: '12px',
        fontWeight: 'normal',
        color: '#000',
        listStyle: 'none',
        letterSpacing: '0.01em',
        '& span': {
            color: 'red',
            position: 'relative',
            '&::before': {
                content: "''",
                width: '3px',
                position: 'absolute',
                height: '3px',
                display: 'block',
                borderRadius: '50%',
                top: '4px',
                left: '-13px',
                background: '#000',
            },
        },
    },
    errorBlock: {
        marginTop: '-16px',
    },
    header: {
        marginTop: '16px',
        color: '#212121',
    },
    logo: {
        paddingTop: '12px',
    },
}, { name: 'RegistrationErrorModal' });
export var RegistrationErrorModal = function (_a) {
    var modalName = _a.modalName, modalProps = _a.modalProps, isVisible = _a.isVisible;
    var asyncError = useStructuredSelector({
        errors: errorSelector,
    }).errors;
    var errorData = asyncError === null || asyncError === void 0 ? void 0 : asyncError.errors;
    var errors = modalProps.errors || errorData;
    var classes = useErrorModalStyles();
    var t = useTranslation('errors').t;
    var getErrorMessage = function (item) {
        if ((item === null || item === void 0 ? void 0 : item.exceptionCode) === 'ER0002' && (item.field === 'email' || item.field === 'username')) {
            return (React.createElement("span", null, t("ER0002_".concat(item.field))));
        }
        return (React.createElement(React.Fragment, null,
            "\"",
            React.createElement("span", null, t("fields.".concat(item.field))),
            "\": ",
            t(item === null || item === void 0 ? void 0 : item.exceptionCode)));
    };
    return (React.createElement(ConfirmationModal, { modalName: modalName, modalProps: __assign(__assign({}, modalProps), { rootClassName: classes.root }), isVisible: isVisible },
        React.createElement(React.Fragment, null,
            React.createElement(StatusScreen, { header: t('error'), subheader: "".concat(t('error_check_info'), ":"), subheaderClassName: classes.color, logo: failed, headerClassName: classes.header, logoClasses: classes.logo }),
            React.createElement("div", { className: classes.errorBlock }, Array.isArray(errors) &&
                errors.map(function (item) { return (React.createElement("li", { className: classes.error, key: item.exceptionCode }, getErrorMessage(item))); })))));
};
export default RegistrationErrorModal;
