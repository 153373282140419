import { createSlice } from '@reduxjs/toolkit';
import { createAsyncReducers } from '~utils/createAsyncReducers';
import { getTournamentsList } from './thunks';
import { name } from './constants';
var initialState = {
    data: [],
    loading: 'idle',
    error: null,
};
export var tournamentsList = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        resetTournaments: function () {
            return initialState;
        },
    },
    extraReducers: function (builder) {
        var _a = createAsyncReducers(), pending = _a.pending, fulfilled = _a.fulfilled, rejected = _a.rejected;
        builder
            .addCase(getTournamentsList.pending, pending)
            .addCase(getTournamentsList.fulfilled, fulfilled)
            .addCase(getTournamentsList.rejected, rejected);
    },
});
export var resetTournaments = tournamentsList.actions.resetTournaments;
