import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { push } from 'connected-react-router';
import { useLocation } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useAppDispatch } from '~store';
import { Button, TextInput } from '~atoms';
import { MODALS, closeModal, openModal } from '~modals';
import { confirmUpdateEmail } from '~slices/account';
import { useIsDesktop } from '~hooks';
import validationMessages from '~utils/validationMessages';
import { getPathWithLanguageCode } from '~utils/languageInURL/getPathWithLanguageCode';
import { useEditAccountStyles } from '../MyAccount.styles';
var useStyles = makeStyles({
    subHeader: {
        fontSize: '16px',
        lineHeight: '24px',
        color: '#FFFFFF',
        textAlign: 'center',
    },
    input: {
        marginTop: '32px',
    },
    editEmailMarginBottom: {
        marginBottom: '32px',
    },
    errorModalContainer: {
        padding: '0 32px 32px',
    },
    titleError: {
        padding: '10px 0',
    },
}, { name: 'EnterCode' });
export var EnterCode = function (_a) {
    var _b;
    var _c, _d;
    var email = _a.email, subheaderClassName = _a.subheaderClassName, goToStart = _a.goToStart;
    var classes = useStyles();
    var formClasses = useEditAccountStyles();
    var location = useLocation();
    var dispatch = useAppDispatch();
    var t = useTranslation('my-account').t;
    var tc = useTranslation('common').t;
    var te = useTranslation('errors').t;
    var isDesktop = useIsDesktop();
    var _e = useForm({
        validationSchema: yup.object().shape({
            code: yup.string().required(validationMessages.required),
        }),
        mode: 'onChange',
        submitFocusError: true,
    }), register = _e.register, handleSubmit = _e.handleSubmit, errors = _e.errors;
    var desktopHandleTryAgain = function () {
        dispatch(closeModal({ key: MODALS.CHANGE_EMAIL_MODAL }));
    };
    var successAction = function () {
        return isDesktop
            ? (dispatch(closeModal({ key: MODALS.EDIT_EMAIL })),
                dispatch(openModal({
                    modalName: MODALS.SUCCESS_MODAL_NOTIFICATION,
                    modalProps: {
                        title: t('updated_messages.email.header'),
                    },
                })))
            : dispatch(push(getPathWithLanguageCode('/my-account/security/updated')));
    };
    useEffect(function () {
        dispatch(openModal({
            modalName: MODALS.CONFIRMATION_MODAL,
            modalProps: {
                title: t('updated_messages.success'),
                fullWidth: false,
                cancelVariant: 'text',
                closeButtonLabel: tc('buttons.ok').toLocaleUpperCase(),
                columnDialog: false,
                message: t('updated_messages.email.check_email'),
            },
        }));
    }, []);
    var redirectToAccountMenu = function () {
        dispatch(push(getPathWithLanguageCode('/my-account')));
    };
    var closeEnterCodeModal = function () {
        goToStart('', false);
    };
    var submit = function (values) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dispatch(confirmUpdateEmail(values)).then(function (_a) {
            var payload = _a.payload;
            if (Array.isArray(payload) && payload.length) {
                var subheader = payload[0].code === 'ER3001'
                    ? te("".concat(payload[0].code, "_").concat(payload[0].field), te(payload[0].code))
                    : undefined;
                dispatch(openModal({
                    modalName: MODALS.CHANGE_EMAIL_MODAL,
                    modalProps: {
                        closeButtonLabel: t('updated_messages.cancel'),
                        confirmButtonLabel: t('updated_messages.try_again'),
                        variant: 'contained',
                        fullWidth: true,
                        subheader: subheader,
                        hugeButton: true,
                        closable: true,
                        dialogClassName: isDesktop
                            ? classes.errorModalContainer
                            : undefined,
                        titleClassName: isDesktop ? classes.titleError : undefined,
                        handleConfirm: function () {
                            if (isDesktop) {
                                desktopHandleTryAgain();
                            }
                            else {
                                dispatch(push(getPathWithLanguageCode('/my-account/email')));
                            }
                            dispatch(closeModal({ key: MODALS.CHANGE_EMAIL_MODAL }));
                        },
                    },
                }));
            }
            else {
                successAction();
            }
        });
    };
    return (React.createElement("form", { className: classNames(formClasses.form, 'flex-column'), onSubmit: handleSubmit(submit) },
        React.createElement("div", { className: formClasses.flex },
            React.createElement("div", { className: classNames(subheaderClassName || classes.subHeader, 'flex-column') },
                t('email.subheader'),
                React.createElement("b", null, ((_c = location === null || location === void 0 ? void 0 : location.state) === null || _c === void 0 ? void 0 : _c.email) || email)),
            React.createElement(TextInput, { fullWidth: true, className: classes.input, name: "code", title: t('email.code'), placeholder: t('email.enter_code'), inputRef: register, showAsterisk: true, error: !!errors.code, helperText: (_d = errors === null || errors === void 0 ? void 0 : errors.code) === null || _d === void 0 ? void 0 : _d.message })),
        React.createElement(Grid, { container: true, style: { marginTop: isDesktop ? '87px' : 'auto' }, className: classNames((_b = {},
                _b[classes.editEmailMarginBottom] = isDesktop,
                _b)), justify: "space-between", spacing: 2 },
            React.createElement(Grid, { key: "cancel", item: true },
                React.createElement(Button, { size: "large", classes: { root: formClasses.button }, variant: "outlined", onClick: isDesktop ? closeEnterCodeModal : redirectToAccountMenu, color: "secondary" }, tc('buttons.back'))),
            React.createElement(Grid, { key: "next", item: true },
                React.createElement(Button, { classes: { root: formClasses.button }, size: "large", variant: "contained", type: "submit", color: "primary" }, tc('buttons.done'))))));
};
