import makeStyles from '@material-ui/core/styles/makeStyles';
var useStyles = makeStyles({
    container: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: '164px',
        maxWidth: '164px',
        minWidth: '164px',
        height: '68px',
        minHeight: '68px',
        maxHeight: '68px',
        backgroundColor: '#484848',
        border: '1px solid #2AA1F7',
        borderRadius: '4px',
        cursor: 'text',
        padding: '0 20px',
        boxSizing: 'border-box',
    },
    input: {
        width: '100%',
        lineHeight: '19px',
        textAlign: 'end',
        border: 'none',
        background: 'transparent',
        fontSize: 'inherit',
        color: 'inherit',
        outline: 'none',
        '&::placeholder': {
            fontWeight: 500,
            fontSize: '18px',
            lineHeight: '21px',
            color: '#8E8E8E',
        },
        '&:focus': {
            outline: 'none',
        },
    },
    divider: {
        width: '1px',
        maxWidth: '1px',
        minWidth: '1px',
        height: '35px',
        minHeight: '35px',
        maxHeight: '35px',
        margin: '0 12px',
        backgroundColor: '#8E8E8E',
    },
}, { name: 'WagerView' });
export { useStyles };
