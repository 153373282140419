var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createSelector } from 'reselect';
import moment from 'moment';
import groupBy from 'lodash.groupby';
import { FINISHED_STATUSES } from '~utils/events';
import { selectOrderedMarketsData } from '~slices/markets';
import { selectActiveTournamentInfo } from '~slices/tournamentsInfo';
import { ENDED_EVENT_WAITING_FOR_ACTION, FAVOURITES_SOCKET_NAME, LIVE_SOCKET_NAME, MATCHDAY_SOCKET_NAME, SOON_LIVE_SOCKET_NAME, name, } from './constants';
import { normalizeFavoritesEvents } from './normalizeFavoritesEvents';
export var eventsSelector = function (state) { return state[name]; };
export var dataSelector = function (state, eventsType) { return state[name].data[eventsType] || {}; };
export var eventsDataSelector = function (state, eventsType) { return dataSelector(state, eventsType).events; };
export var tnOrderSelector = function (state, eventsType) { return dataSelector(state, eventsType).tnOrder || []; };
export var eventsLoadingSelector = createSelector(function (state) { return state[name].loading; }, function (loading) { return loading; });
export var eventsPendingSelector = createSelector(function (state) { return state[name].loading; }, function (loading) { return loading === 'pending'; });
export var eventsIdleSelector = createSelector(function (state) { return state[name].loading; }, function (loading) { return loading === 'idle'; });
export var isLiveEventsSelector = createSelector(function (state) { return state[name].data[LIVE_SOCKET_NAME]; }, function (liveData) { return !!(liveData === null || liveData === void 0 ? void 0 : liveData.tnOrder); });
export var selectSeason = createSelector(function (state) { return eventsDataSelector(state, MATCHDAY_SOCKET_NAME); }, function (events) {
    var firstDate = Object.values(events || {}).reduce(function (acc, _a) {
        var dt = _a.dt;
        if (!acc) {
            return dt || 0;
        }
        return dt ? Math.min(acc, dt) : acc;
    }, 0);
    var lastDate = Object.values(events || {}).reduce(function (acc, _a) {
        var lId = _a.lId;
        if (!acc) {
            return lId || 0;
        }
        return lId ? Math.max(acc, lId) : acc;
    }, 0);
    var fullYear = new Date(Number(firstDate) * 1000).getFullYear();
    var lastFullYear = new Date(Number(lastDate || firstDate) * 1000).getFullYear();
    var season = '';
    if (firstDate) {
        season = String(fullYear);
    }
    if (firstDate && lastDate && fullYear !== lastFullYear) {
        season += "/".concat(String(lastFullYear).slice(-2));
    }
    return season;
});
export var tournamentEventsSelector = function (state, eventsType) { return dataSelector(state, eventsType).tn; };
var splitEventsByStatus = function (events) {
    var finishedEvents = [];
    var prematchEvents = [];
    var liveEvents = [];
    if (events) {
        Object.values(events).forEach(function (e) {
            if (e.ms &&
                FINISHED_STATUSES.includes(e.ms) &&
                e.endedStatus !== ENDED_EVENT_WAITING_FOR_ACTION) {
                finishedEvents.push(e);
            }
            else if (e.ms === 'RUNNING' ||
                e.endedStatus === ENDED_EVENT_WAITING_FOR_ACTION) {
                liveEvents.push(e);
            }
            else {
                prematchEvents.push(e);
            }
        });
    }
    return {
        finishedEvents: finishedEvents,
        prematchEvents: prematchEvents,
        liveEvents: liveEvents,
    };
};
var getSortByDateAndRoundIterator = function (tn, isDesc) {
    if (tn === void 0) { tn = {}; }
    if (isDesc === void 0) { isDesc = false; }
    return function (a, b) {
        if (tn[Number(a.tn)].si === tn[Number(b.tn)].si) {
            if (isDesc) {
                return Number(b.dt) - Number(a.dt);
            }
            return Number(a.dt) - Number(b.dt);
        }
        return Number(tn[Number(a.tn)].si) - Number(tn[Number(b.tn)].si);
    };
};
var getGroupByDateAndRoundIterator = function (crnds) {
    var currentDate = 0;
    var currentRound = '';
    return function (acc, e) {
        var _a;
        var date = moment
            .unix(e.dt)
            .startOf('day')
            .unix();
        var round = ((_a = crnds.find(function (_a) {
            var cr = _a.cr, r = _a.r;
            return cr === e.cr || r === e.cr;
        })) === null || _a === void 0 ? void 0 : _a.rn) || '';
        var isDateChanged = date !== currentDate;
        var isRoundChanged = round !== currentRound;
        if (!acc[acc.length - 1] || isDateChanged || isRoundChanged) {
            currentDate = date;
            currentRound = round;
            var newGroup = {
                events: [],
            };
            if (isDateChanged) {
                newGroup.date = date;
            }
            if (isRoundChanged) {
                newGroup.round = round;
            }
            acc.push(newGroup);
        }
        acc[acc.length - 1].events.push(e);
        return acc;
    };
};
var getGroupByDateAndTournamentNameIterator = function (tn) {
    if (tn === void 0) { tn = {}; }
    var currentDate = 0;
    var currentRound = '';
    return function (acc, e) {
        var _a;
        var date = moment
            .unix(e.dt)
            .startOf('day')
            .unix();
        var round = ((_a = tn[Number(e.tn)]) === null || _a === void 0 ? void 0 : _a.nm) || '';
        var isDateChanged = date !== currentDate;
        var isRoundChanged = round !== currentRound;
        if (!acc[acc.length - 1] || isDateChanged || isRoundChanged) {
            currentDate = date;
            currentRound = round;
            var newGroup = {
                events: [],
            };
            if (isDateChanged) {
                newGroup.date = date;
            }
            if (isRoundChanged) {
                newGroup.round = round;
            }
            acc.push(newGroup);
        }
        acc[acc.length - 1].events.push(e);
        return acc;
    };
};
var EMPTY_CRNDS_ARRAY = [];
export var finalsMatchdayEventsSelector = createSelector(function (state, eventsType, roundIndex) {
    return eventsDataSelector(state, eventsType);
}, function (state, eventsType, roundIndex) {
    var round = (selectActiveTournamentInfo(state).stg || [])[roundIndex] || {};
    return 'crnds' in round
        ? round.crnds || EMPTY_CRNDS_ARRAY
        : EMPTY_CRNDS_ARRAY;
}, tournamentEventsSelector, function (allEvents, crnds, tn) {
    /**
     * 1 level: (SF+F) fixed area above, (other finals) below
     */
    var finals = [];
    var nonFinals = [];
    var finalCrnds = crnds.filter(function (_a) {
        var rn = _a.rn;
        return rn === 'SF' || rn === 'F';
    });
    if (allEvents) {
        Object.values(allEvents).forEach(function (e) {
            var isFinals = finalCrnds.some(function (_a) {
                var cr = _a.cr, r = _a.r;
                return e.cr === cr || e.cr === r;
            });
            if (isFinals) {
                finals.push(e);
            }
            else {
                nonFinals.push(e);
            }
        });
    }
    /**
     * 2 level: live and prematch above, finished below
     */
    var f = splitEventsByStatus(finals);
    var nf = splitEventsByStatus(nonFinals);
    /**
     * 4 level: by start date time
     */
    var sortAndGroup = function (events, isFinished) {
        if (isFinished === void 0) { isFinished = false; }
        return events
            .sort(getSortByDateAndRoundIterator(tn, isFinished))
            .reduce(getGroupByDateAndRoundIterator(crnds), []);
    };
    return __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], sortAndGroup(f.liveEvents), true), sortAndGroup(f.prematchEvents), true), sortAndGroup(f.finishedEvents, true), true), sortAndGroup(nf.liveEvents), true), sortAndGroup(nf.prematchEvents), true), sortAndGroup(nf.finishedEvents, true), true);
});
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export var matchDataEventsSelector = createSelector(function (state, eventsType) {
    return eventsDataSelector(state, eventsType);
}, tournamentEventsSelector, function (allEvents, tn) {
    /**
     * 1 level: live and prematch area above, finished area below
     */
    var _a = splitEventsByStatus(allEvents), finishedEvents = _a.finishedEvents, prematchEvents = _a.prematchEvents, liveEvents = _a.liveEvents;
    /**
     * 2 level: by child tournament name within each area
     * 3 level: by start date time within a group
     */
    var sortAndGroup = function (events, isFinished) {
        if (isFinished === void 0) { isFinished = false; }
        return events
            .sort(getSortByDateAndRoundIterator(tn, isFinished))
            .reduce(getGroupByDateAndTournamentNameIterator(tn), []);
    };
    return __spreadArray(__spreadArray(__spreadArray([], sortAndGroup(liveEvents), true), sortAndGroup(prematchEvents), true), sortAndGroup(finishedEvents, true), true);
});
export var selectMatchdayGrouppedEvents = createSelector(function (state, eventsType, roundIndex) {
    var round = (selectActiveTournamentInfo(state).stg || [])[roundIndex] || {};
    return round.rt === 'S'
        ? finalsMatchdayEventsSelector(state, eventsType, roundIndex)
        : matchDataEventsSelector(state, eventsType);
}, tournamentEventsSelector, function (groups) {
    return groups;
});
export var categoryEventsSelector = function (state, eventsType) { return dataSelector(state, eventsType).ct; };
export var sportEventsSelector = function (state, eventsType) { return dataSelector(state, eventsType).sp; };
export var getTournamentsSelector = createSelector(function (state, eventsType) {
    return tournamentEventsSelector(state, eventsType);
}, function (tn) { return tn; });
export var getSportBIdSelector = createSelector(function (state, eventsType) {
    return sportEventsSelector(state, eventsType);
}, function (sp) { var _a; return sp && ((_a = Object.values(sp)[0]) === null || _a === void 0 ? void 0 : _a.bid); });
export var getTnOrder = createSelector(function (state, eventsType) { return tnOrderSelector(state, eventsType); }, function (tnOrder) { return tnOrder; });
export var getSeasonId = createSelector(function (state, eventsType) {
    return eventsDataSelector(state, eventsType);
}, function (events) {
    if (events) {
        var eventId = Object.keys(events).find(function (key) { return events[key].se; });
        return eventId ? events[eventId].se : null;
    }
    return null;
});
export var eventsTournamentByIdSelector = createSelector(function (state, _, eventsType) {
    return tournamentEventsSelector(state, eventsType);
}, function (_, id) { return id; }, function (tn, id) {
    return tn && tn[id];
});
export var getShortcutByIdSelector = createSelector(function (state) { return state.shortcut.data.ps; }, function (_, id) { return id; }, function (shortcut, id) { return shortcut && shortcut[id]; });
export var makeGetShortcutByIdSelector = function () {
    return getShortcutByIdSelector;
};
export var getCategoriesSelector = createSelector(function (state, eventsType) { return dataSelector(state, eventsType); }, function (events) { return events.ct; });
export var getEventsByTournamentSelector = createSelector(function (state, _, eventsType) {
    return tournamentEventsSelector(state, eventsType);
}, function (_, id) { return id; }, function (tn, id) { var _a; return tn && ((_a = tn[id]) === null || _a === void 0 ? void 0 : _a.eventsId); });
export var selectTournamentHeaderMarkets = createSelector(function (state, tournamentId, eventsType) {
    return eventsTournamentByIdSelector(state, tournamentId, eventsType);
}, selectOrderedMarketsData, function (tournament, orderedMarkets) {
    return orderedMarkets.map(function (_a) {
        var _b;
        var id = _a.id;
        var market = (_b = Object.values((tournament === null || tournament === void 0 ? void 0 : tournament.tournamentMarkets) || {})) === null || _b === void 0 ? void 0 : _b.find(function (_a) {
            var mcid = _a.mcid;
            return mcid === id;
        });
        return market || id || 0;
    });
});
export var makeGetEventsByTournamentSelector = function () { return getEventsByTournamentSelector; };
export var getEventByIdSelector = createSelector(function (state, _, eventsType) {
    return eventsDataSelector(state, eventsType);
}, function (_, id) { return id; }, function (events, id) {
    return events && Object.keys(events).length ? events[id] : {};
});
export var makeGetEventByIdSelector = function () {
    return getEventByIdSelector;
};
export var getCategoryByIdSelector = createSelector(function (state, _, eventsType) {
    return categoryEventsSelector(state, eventsType);
}, function (_, id) { return id; }, function (ct, id) { return (ct && Object.keys(ct).length ? ct[id] : {}); });
export var makeGetCategoryByIdSelector = function () {
    return getCategoryByIdSelector;
};
export var selectFavoritesEventsOrder = createSelector(function (state) { return eventsDataSelector(state, FAVOURITES_SOCKET_NAME); }, function (events) {
    if (!events) {
        return { eventsOrder: [], endedEventsOrder: [] };
    }
    var allEvents = Object.values(events);
    var eventsOrder = normalizeFavoritesEvents(allEvents.filter(function (event) { return !FINISHED_STATUSES.includes(event.ms || ''); }));
    var endedEventsOrder = normalizeFavoritesEvents(allEvents.filter(function (event) { return FINISHED_STATUSES.includes(event.ms || ''); }), true);
    return { eventsOrder: eventsOrder, endedEventsOrder: endedEventsOrder };
});
export var eventsByTournamentIdSelector = createSelector(function (state, _, eventsType) {
    return tournamentEventsSelector(state, eventsType);
}, function (state, _, eventsType) {
    return eventsDataSelector(state, eventsType);
}, function (state, id) { return id; }, function (tournaments, storeEvents, id) {
    var _a;
    if (!tournaments ||
        !tournaments[id] ||
        !((_a = tournaments[id].eventsId) === null || _a === void 0 ? void 0 : _a.length) ||
        !storeEvents) {
        return {};
    }
    var payloadEvents = tournaments[id].eventsId.map(function (eventId) { return storeEvents[eventId]; });
    return groupBy(payloadEvents, function (item) {
        return moment.unix(item.dt).startOf('day');
    });
});
export var grouppedTournamentsDatesSelector = createSelector(function (state, tournamentId, eventsType) {
    return eventsByTournamentIdSelector(state, tournamentId, eventsType);
}, function (groupedTournaments) {
    var dates = Object.keys(groupedTournaments);
    return dates;
});
export var selectTournamentGroupEvents = createSelector(function (state, tournamentId, eventsType) {
    return eventsByTournamentIdSelector(state, tournamentId, eventsType);
}, function (state, tournamentId, eventsType, date) {
    return date;
}, function (groupedTournaments, date) {
    var events = groupedTournaments[date];
    return events || [];
});
export var soonLiveEventsTournamentsSelector = createSelector(function (state) { return tnOrderSelector(state, SOON_LIVE_SOCKET_NAME); }, function (state) {
    return tournamentEventsSelector(state, SOON_LIVE_SOCKET_NAME) || {};
}, function (state) { return eventsDataSelector(state, SOON_LIVE_SOCKET_NAME); }, function (tnOrder, tournaments, storeEvents) {
    var existingTournaments = Object.keys(tournaments).map(String);
    return tnOrder
        .filter(function (tId) { return existingTournaments.includes(String(tId)); })
        .map(function (tId) {
        var tournament = tournaments[tId];
        var payloadEvents = tournaments[tId].eventsId.map(function (eventId) {
            return storeEvents ? storeEvents[eventId] : {};
        });
        var eventDates = groupBy(payloadEvents, function (item) {
            return moment.unix(Number(item.dt)).startOf('day');
        });
        var tournamentDate = new Date(Object.keys(eventDates)[0]).getTime();
        return {
            tId: String(tId),
            events: tournament.eventsId,
            tournamentDate: tournamentDate,
        };
    });
});
