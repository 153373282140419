var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useMemo, useRef, useState } from 'react';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { normalizeTextFieldValue } from '~utils/fieldNormalization';
import { useStyles } from './styles';
function countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== 'undefined' ? (React.createElement("img", { src: "https://flagcdn.com/h24/".concat(typeof isoCode === 'object'
            ? isoCode.countryCode.toLowerCase()
            : isoCode.toLowerCase(), ".png"), alt: typeof isoCode === 'object'
            ? isoCode.countryCode.toLowerCase()
            : isoCode.toLowerCase(), width: "40", height: "24" })) : (isoCode);
}
export var CountryAutocomplete = function (_a) {
    var input = _a.input, meta = _a.meta, title = _a.title, options = _a.options, required = _a.required;
    var classes = useStyles();
    var selectedCountryNameRef = useRef(input.value.country);
    return (React.createElement("div", { className: classes.selectBox },
        React.createElement("div", { className: classes.selectBoxTitle },
            title,
            " ",
            required && '*'),
        React.createElement(Autocomplete, { fullWidth: true, autoSelect: true, value: input.value, options: options, filterOptions: createFilterOptions({
                matchFrom: 'start',
                stringify: function (option) { return option.country; },
            }), classes: {
                option: classes.option,
                inputRoot: classes.input,
                popper: classes.popper,
            }, closeIcon: false, autoHighlight: false, popupIcon: React.createElement("i", { className: "far fa-chevron-down icon ".concat(classes.chevronIcon) }), onChange: function (event, value) {
                if (value) {
                    selectedCountryNameRef.current = value.country;
                    input.onChange(value);
                }
            }, getOptionLabel: function (option) { return option.country; }, renderOption: function (option) { return (
            // @ts-expect-error
            React.createElement("div", { className: classes.countryItem },
                countryToFlag(option.countryCode),
                option.country)); }, renderInput: function (params) {
                var _a;
                return (React.createElement(TextField, { value: input.value.countryCode, style: { width: '100%' }, error: meta.touched && meta.invalid, helperText: meta.touched ? meta.error : '', label: title, variant: "outlined", inputProps: __assign(__assign({}, params.inputProps), { autoComplete: 'new-password' }), 
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    InputProps: __assign(__assign({}, params.InputProps), { startAdornment: ((_a = input === null || input === void 0 ? void 0 : input.value) === null || _a === void 0 ? void 0 : _a.countryCode) ? (
                        // @ts-expect-error
                        React.createElement(InputAdornment, { position: "start", className: classes.flagIcon }, countryToFlag(input.value.countryCode))) : null }) }));
            } })));
};
// TODO refactor component and remove duplicate after rewriting registration to react-hook-form
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export var SimpleCountryAutocomplete = function (_a) {
    var title = _a.title, options = _a.options, showAsterisk = _a.showAsterisk, onChange = _a.onChange, error = _a.error, helperText = _a.helperText, value = _a.value;
    var classes = useStyles();
    var initialCountry = useMemo(function () {
        return options.find(function (_a) {
            var countryCode = _a.countryCode;
            return countryCode === value;
        });
    }, []);
    var initialCountryName = useMemo(function () {
        var country = options.find(function (_a) {
            var countryCode = _a.countryCode;
            return countryCode === value;
        });
        return (country === null || country === void 0 ? void 0 : country.country) || '';
    }, []);
    var _b = useState(initialCountryName), inputValue = _b[0], setInputValue = _b[1];
    var selectedCountryNameRef = useRef(initialCountryName);
    return (React.createElement("div", { className: classes.selectBox },
        React.createElement("div", { className: classes.selectBoxTitle },
            title,
            " ",
            showAsterisk && '*'),
        React.createElement(Autocomplete, { fullWidth: true, inputValue: inputValue, options: [initialCountry], filterOptions: createFilterOptions({
                matchFrom: 'start',
                stringify: function (option) { return option.country; },
            }), classes: {
                option: classes.option,
                inputRoot: classes.input,
                popper: classes.popper,
            }, closeIcon: false, autoHighlight: false, popupIcon: React.createElement("i", { className: "far fa-chevron-down icon ".concat(classes.chevronIcon) }), onClose: function () { return setInputValue(selectedCountryNameRef.current); }, 
            // @ts-expect-error
            onChange: function (event, newValue) {
                selectedCountryNameRef.current = newValue === null || newValue === void 0 ? void 0 : newValue.country;
                setInputValue(newValue === null || newValue === void 0 ? void 0 : newValue.country);
                onChange(newValue);
            }, getOptionLabel: function (option) { return option.country; }, renderOption: function (option) { return (
            // @ts-expect-error
            React.createElement("div", { className: classes.countryItem },
                countryToFlag(option.countryCode),
                option.country)); }, renderInput: function (params) { return (React.createElement(TextField, { value: value, style: { width: '100%' }, error: error, helperText: helperText, label: title, variant: "outlined", onChange: function (e) {
                    return setInputValue(normalizeTextFieldValue(e.target.value));
                }, inputProps: __assign(__assign({}, params.inputProps), { autoComplete: 'new-password' }), 
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps: __assign(__assign({}, params.InputProps), { startAdornment: value ? (
                    // @ts-expect-error
                    React.createElement(InputAdornment, { position: "start", className: classes.flagIcon }, countryToFlag(value))) : null }) })); } })));
};
