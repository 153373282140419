import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore } from 'redux-persist';
import { routerMiddleware } from 'connected-react-router';
import { storeContainer } from '~/utils/storeContainer';
import { persistCrossTab } from '~utils/persistCrossTab';
import { history } from '~utils/history';
import rootSaga from './rootSaga';
import reducer from './reducers';
import { authMiddleware, socketMiddleware } from './middleware';
var sagaMiddleware = createSagaMiddleware();
var defaultMiddleware = getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
});
var store = configureStore({
    reducer: reducer,
    middleware: defaultMiddleware.concat(sagaMiddleware, socketMiddleware(), authMiddleware(), routerMiddleware(history)),
});
storeContainer.setStore(store);
export var useAppDispatch = function () { return useDispatch(); };
sagaMiddleware.run(rootSaga);
var persistor = persistStore(store);
persistCrossTab(store);
export { persistor };
export default store;
