import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useFirst } from '~hooks';
import { useStyles } from '../topRanking.styles';
export var DesktopTicketDetailsHeader = function (_a) {
    var _b, _c;
    var _d;
    var to = _a.to;
    var classes = useStyles();
    var t = useTranslation('ticket').t;
    var maxOdd = to === null || to === void 0 ? void 0 : to.mo;
    var prevMO = useFirst(maxOdd);
    var isBetStop = (_d = to === null || to === void 0 ? void 0 : to.evs) === null || _d === void 0 ? void 0 : _d.some(function (item) { return item.bs; });
    if (!maxOdd) {
        return null;
    }
    return (React.createElement("div", { className: classNames('flex-row', 'space-between', classes.subheader, classes.subheaderDesktop) },
        React.createElement("button", { className: classNames(classes.menuButton, classes.desktopButton), type: "button" },
            React.createElement("i", { className: classNames((_b = {
                        'fas fa-chart-line': +maxOdd >= +prevMO || !prevMO,
                        'far fa-chart-line-down': +maxOdd < +prevMO
                    },
                    _b[classes.trendIconHigher] = +maxOdd > +prevMO,
                    _b[classes.trendIconLower] = +maxOdd < +prevMO,
                    _b[classes.trendIconEqual] = +maxOdd === +prevMO || !prevMO,
                    _b)) }),
            t('trend')),
        React.createElement("button", { className: classNames(classes.menuButton, classes.desktopButton, (_c = {},
                _c[classes.betstop] = isBetStop,
                _c)), type: "button" },
            React.createElement("i", { className: "far fa-octagon" }),
            t('betstop'))));
};
