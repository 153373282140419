import { shallowEqual, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { name } from './constants';
export var sportSelector = function (state) { return state[name]; };
export var availableSportsSelector = function (state) {
    return state[name].data;
};
export var loadingSelector = function (state) {
    return sportSelector(state).loading;
};
export var errorSelector = function (state) {
    return sportSelector(state).error;
};
export var useSportById = createSelector(function (state) { return sportSelector(state).data; }, function (_, id) { return id; }, function (sports, id) { return sports.find(function (s) { return s.id === id; }) || {}; });
export var selectSportBIdById = createSelector(function (state, id) { return useSportById(state, id); }, function (sport) { var _a; return (_a = sport.betradarId) !== null && _a !== void 0 ? _a : NaN; });
/* @deprecated use useStructuredSelector instead */
export var useSportSelector = function () { return useSelector(sportSelector); };
/* @deprecated use useStructuredSelector instead */
export var useAvailableSportsSelector = function () {
    return useSelector(availableSportsSelector, shallowEqual);
};
/* @deprecated use useStructuredSelector instead */
export var useLoadingSelector = function () {
    return useSelector(loadingSelector);
};
/* @deprecated use useStructuredSelector instead */
export var useErrorSelector = function () {
    return useSelector(errorSelector);
};
