import validationMessages from '~utils/validationMessages';
import { checkIsSubstringIncluded } from '~utils/checkIsSubstringIncluded';
var passwordsDontMatch = validationMessages.passwordsDontMatch, emailsDontMatch = validationMessages.emailsDontMatch, passwordLikeUserNameOrSurname = validationMessages.passwordLikeUserNameOrSurname;
export var validate = function (values) {
    var errors = {};
    if (values.email !== values.repeatedEmail) {
        errors.repeatedEmail = emailsDontMatch();
    }
    var password = values.password, username = values.username, userProfileDto = values.userProfileDto;
    var _a = userProfileDto || {}, name = _a.name, surname = _a.surname;
    var checkIsSubstringIncludedInPassword = checkIsSubstringIncluded(password);
    if (checkIsSubstringIncludedInPassword(username) ||
        checkIsSubstringIncludedInPassword(name) ||
        checkIsSubstringIncludedInPassword(surname)) {
        errors.password = passwordLikeUserNameOrSurname();
    }
    if (values.password !== values.repeatedPassword) {
        errors.repeatedPassword = passwordsDontMatch();
    }
    return errors;
};
/* see @CAS-3742 https://github.com/redux-form/redux-form/issues/3276 */
export var shouldValidate = function () { return true; };
