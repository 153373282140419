var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import './textinput-styles.scss';
export function TextInput(_a) {
    var placeholder = _a.placeholder, title = _a.title, required = _a.required, onChange = _a.onChange, onBlur = _a.onBlur, onFocus = _a.onFocus, value = _a.value, type = _a.type, helperText = _a.helperText, name = _a.name, InputProps = _a.InputProps, error = _a.error, titleClassName = _a.titleClassName, className = _a.className, inputRef = _a.inputRef, showAsterisk = _a.showAsterisk, _b = _a.size, size = _b === void 0 ? 'small' : _b, _c = _a.disablePaste, disablePaste = _c === void 0 ? false : _c, _d = _a.disabled, disabled = _d === void 0 ? false : _d, inputType = _a.inputType, _e = _a.variant, variant = _e === void 0 ? 'outlined' : _e, inputClassName = _a.inputClassName, autoCompleteType = _a.autoCompleteType, visibilityColor = _a.visibilityColor, onClick = _a.onClick, onKeyDown = _a.onKeyDown;
    var isPasswordField = type === 'password';
    var _f = useState(isPasswordField), isTextHidden = _f[0], setIsTextHidden = _f[1];
    var toggleHiddenText = function () { return setIsTextHidden(!isTextHidden); };
    var inputTypeValue = inputType || 'text';
    var allowPaste = function (e) {
        if (disablePaste) {
            e.preventDefault();
        }
    };
    return (React.createElement("div", { className: classNames('text-input', className) },
        title && (React.createElement("div", { className: classNames('text-input__title', titleClassName) },
            title,
            " ",
            (required || showAsterisk) && '*')),
        React.createElement(TextField, { inputRef: inputRef, name: name, value: value, onChange: onChange, onBlur: onBlur, onFocus: onFocus, onKeyDown: onKeyDown, type: isTextHidden ? type : inputTypeValue, classes: { root: inputClassName }, fullWidth: true, InputProps: __assign(__assign({}, InputProps), { autoComplete: autoCompleteType || 'off' }), error: error, helperText: helperText, placeholder: placeholder, variant: variant, onPaste: allowPaste, required: required, disabled: disabled, onClick: onClick }),
        isPasswordField && (React.createElement("div", null, isTextHidden ? (React.createElement(Visibility, { style: { color: visibilityColor || 'fff' }, onClick: toggleHiddenText })) : (React.createElement(VisibilityOff, { style: { color: visibilityColor || 'fff' }, onClick: toggleHiddenText }))))));
}
