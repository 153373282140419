import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { closeModal } from '~modals';
import { EditEmailForm } from '~pages/my-account/components/EditEmailForm';
import { EnterCode } from '~pages/my-account/components/EnterCode';
import { useStyles } from './EditModals.styles';
import { EditModalContainer } from './EditModalContainer';
export var EditEmailModal = function (_a) {
    var modalName = _a.modalName;
    var dispatch = useDispatch();
    var t = useTranslation('my-account').t;
    var classes = useStyles();
    var _b = useState(false), successForm = _b[0], setSuccessForm = _b[1];
    var _c = useState(''), email = _c[0], setEmail = _c[1];
    var handleCancel = function () {
        dispatch(closeModal({ key: modalName }));
    };
    var changePage = function (emailValue, form) {
        setEmail(emailValue);
        setSuccessForm(form);
    };
    return (React.createElement(EditModalContainer, { handleCancel: handleCancel, title: t('email.header'), specialPaddings: true }, successForm ? (React.createElement(EnterCode, { email: email, subheaderClassName: classes.subheaderSuccessEmailEdit, goToStart: changePage })) : (React.createElement(EditEmailForm, { handleCancel: handleCancel, goToNextStep: changePage, inputClassName: classes.editInputClasses, visibilityColor: "#212121" }))));
};
