import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { numberWithDotsAndComma } from '~utils/numberWithDotsAndComma';
import { Button, Currency, FullscreenLoader } from '~atoms';
import { BalanceInput } from '~atoms/BalanceInput';
import { useStyles } from '~molecules/DepositTab/components/ShopBalance/ShopBalance.styles';
import { useStructuredSelector } from '~hooks';
import { betslipDataSelector } from '~slices/betslip';
import { getPathWithLanguageCode } from '~utils/languageInURL';
import { PaymentsURL } from '~pages/payments/payments.routes';
import { useAppDispatch } from '~store';
import { CSIcon } from "~icons/rankingList/CSIcon";
var DEFAULT_DEPOSIT_AMOUNT = '10';
export var ShopBalance = function () {
    var _a;
    var dispatch = useAppDispatch();
    var classes = useStyles();
    var t = useTranslation('tip-game').t;
    var _b = useState(DEFAULT_DEPOSIT_AMOUNT), depositAmount = _b[0], setDepositAmount = _b[1];
    var _c = useState(false), isDataLoading = _c[0], setIsDataLoading = _c[1];
    // TODO, replace with real data from BE
    var betslip = useStructuredSelector({
        betslip: betslipDataSelector,
    }).betslip;
    var ticketOffer = betslip.to;
    var currency = (_a = ticketOffer === null || ticketOffer === void 0 ? void 0 : ticketOffer.cu) !== null && _a !== void 0 ? _a : 'NONE';
    var currencyValue = currency === 'CRD' ? React.createElement(CSIcon, { size: "lg" }) : React.createElement(Currency, { currency: currency });
    var mockData = {
        shopBalance: 11.11,
        onlineBalance: 222,
        jackpot: 1000,
    };
    var onDepositClick = function () {
        setIsDataLoading(true);
        setTimeout(function () {
            setIsDataLoading(false);
        }, 2000);
        setTimeout(function () {
            dispatch(push(getPathWithLanguageCode("/".concat(PaymentsURL.PAYMENTS, "/").concat(PaymentsURL.DEPOSIT_SHOP_BALANCE, "/").concat(PaymentsURL.PAYMENT_CODE, "?process=deposit&sum=").concat(depositAmount))));
        }, 2100);
    };
    return (React.createElement("div", { className: classes.container },
        isDataLoading && React.createElement(FullscreenLoader, null),
        React.createElement("div", null,
            React.createElement("div", { className: classes.currentBalanceTitle },
                t('payments.your_current_balance'),
                ":"),
            React.createElement("div", { className: classes.currentBalanceAmount },
                numberWithDotsAndComma(mockData.shopBalance),
                currencyValue),
            React.createElement(BalanceInput, { setValue: setDepositAmount, currency: currency, amount: depositAmount })),
        React.createElement(Button, { color: "primary", variant: "contained", fullWidth: true, className: classes.button, onClick: onDepositClick }, t('payments.deposit'))));
};
