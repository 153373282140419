import React from 'react';
import { useStyles } from './ItemsGroup.styles';
import { useTournamentsSelectAutoscroll } from './useTournamentsSelectAutoscroll';
var ItemsGroup = function (_a) {
    var _b = _a.autoScroll, autoScroll = _b === void 0 ? false : _b, children = _a.children;
    var classnames = useStyles();
    var elementRef = useTournamentsSelectAutoscroll(autoScroll, { threshold: 222 }).elementRef;
    return (React.createElement("div", { ref: elementRef, className: classnames.container }, children));
};
export { ItemsGroup };
