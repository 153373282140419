import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CheckboxField } from '~atoms';
import { getPathWithLanguageCode } from '~utils/languageInURL';
import { checkIfFieldIsRequired, requiredCheckbox, validateField, } from '~utils/formValidators';
export var TermsAndConditionsAgreement = function (_a) {
    var tabIndex = _a.tabIndex, registrationBlocks = _a.registrationBlocks, checkboxClassName = _a.checkboxClassName, isDesktop = _a.isDesktop, linkClassName = _a.linkClassName, onCancel = _a.onCancel;
    var t = useTranslation('registration').t;
    return (React.createElement(Grid, { item: true, style: { width: '100%' } },
        React.createElement(CheckboxField, { className: checkboxClassName, name: "termsAndConditionsAccepted", title: React.createElement(React.Fragment, null,
                t('form.terms_agree'),
                ' ',
                React.createElement(Link, { className: linkClassName, to: isDesktop
                        ? getPathWithLanguageCode('/about/info/terms_conditions')
                        : getPathWithLanguageCode('/terms'), onClick: onCancel }, t('form.terms_and_conditions_link'))), validators: function (value) {
                return validateField(value, 'termsAndConditionsAccepted', tabIndex, requiredCheckbox, registrationBlocks);
            }, required: checkIfFieldIsRequired('termsAndConditionsAccepted', tabIndex, registrationBlocks) })));
};
