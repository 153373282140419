import React, { useEffect, useRef, useState } from 'react';
import BScroll from '@better-scroll/core';
import { useStyles } from './HorizontalScroll.styles';
var ANIMATION_SPEED = 300;
var Y_POSITION = 0;
var HorizontalScroll = function (_a) {
    var children = _a.children, elementsCount = _a.elementsCount, elementWidth = _a.elementWidth, focusedIndex = _a.focusedIndex;
    var classes = useStyles();
    var containerRef = useRef(null);
    var _b = useState(), bs = _b[0], setBs = _b[1];
    useEffect(function () {
        if (containerRef.current && elementsCount) {
            setBs(new BScroll(containerRef.current, {
                scrollX: true,
                click: true,
            }));
        }
        if (bs) {
            bs.scrollTo(0, 0);
            bs.destroy();
        }
    }, [containerRef, elementsCount]);
    useEffect(function () {
        var _a;
        if (typeof focusedIndex === 'number' && !Number.isNaN(focusedIndex) && bs) {
            var visibleWidth = ((_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.clientWidth) || 20;
            var partialElementWidth = visibleWidth % elementWidth;
            var visibleCount = Math.floor(visibleWidth / elementWidth);
            var totalCount = elementsCount;
            var focusedCount = 1;
            var visibleFrom = Math.ceil(-bs.x / elementWidth) - 1;
            var visibleTo = visibleFrom + visibleCount - 1;
            var isInView = focusedIndex > visibleFrom && focusedIndex <= visibleTo;
            if (totalCount <= visibleCount || isInView) {
                return;
            }
            var rightSideCount = totalCount - (focusedIndex + 1);
            if (rightSideCount + focusedCount > visibleCount) {
                var shiftToRight = focusedIndex > visibleTo;
                var firstElement_1 = shiftToRight
                    ? focusedIndex - visibleCount + focusedCount
                    : focusedIndex;
                var additionalShift = shiftToRight ? -partialElementWidth : 0;
                var position_1 = firstElement_1 * elementWidth + additionalShift;
                bs.scrollTo(-position_1, Y_POSITION, ANIMATION_SPEED);
                return;
            }
            var firstElement = focusedIndex - visibleCount + rightSideCount + focusedCount;
            var position = firstElement * elementWidth - partialElementWidth;
            bs.scrollTo(-position, Y_POSITION, ANIMATION_SPEED);
        }
    }, [focusedIndex]);
    return (React.createElement("div", { className: classes.container, ref: containerRef },
        React.createElement("div", { className: classes.content }, children)));
};
export { HorizontalScroll };
