var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import classNames from 'classnames';
import Tab from '@material-ui/core/Tab';
import { getTestId } from '~/utils/getTestId';
import { useStyles } from './StyledTab.styles';
var StyledTab = React.forwardRef(function StyledTab(_a, ref) {
    var rootClassNames = _a.rootClassNames, selectedClassNames = _a.selectedClassNames, textColorClassNames = _a.textColorClassNames, dataTestId = _a.dataTestId, rest = __rest(_a, ["rootClassNames", "selectedClassNames", "textColorClassNames", "dataTestId"]);
    var classes = useStyles();
    return (React.createElement(Tab, __assign({ disableRipple: true, ref: ref }, rest, { classes: {
            root: classNames(classes.root, rootClassNames),
            selected: classNames(selectedClassNames),
            textColorInherit: classNames(textColorClassNames),
        }, "data-testid": getTestId(dataTestId) })));
});
export { StyledTab };
