import { selectActiveTournamentId } from '~slices/router';
import { name } from './constants';
var selectTournamentsInfoState = function (state) { return state[name]; };
export var selectTournamentInfo = function (state, tournamentId) {
    var _a;
    return ((_a = selectTournamentsInfoState(state)[Number(tournamentId)]) === null || _a === void 0 ? void 0 : _a.data) || {};
};
export var selectTournamentInfoLoadingStatus = function (state, tournamentId) {
    var _a;
    return (_a = selectTournamentsInfoState(state)[Number(tournamentId)]) === null || _a === void 0 ? void 0 : _a.loading;
};
export var selectActiveTournamentInfo = function (state) {
    return selectTournamentInfo(state, Number(selectActiveTournamentId(state)));
};
export var selectActiveTournamentInfoLoadingStatus = function (state) {
    return selectTournamentInfoLoadingStatus(state, Number(selectActiveTournamentId(state)));
};
