import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
export var HorseBackIcon = function (_a) {
    var className = _a.className;
    return (React.createElement(SvgIcon, { className: className, width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M11.2 1.2s-1.2-.8-2 0c-.9.9-1.5 1.1-2.2 1.1 0 0 2.6 1.7 3.8.5", fill: "#D67C05" }),
        React.createElement("path", { d: "M16 3.2v.4a2.9 2.9 0 1 1 0-.4Z", fill: "#F9DCA2" }),
        React.createElement("path", { d: "M11.9 3.2c0-1.3.8-2.4 2-2.7A2.9 2.9 0 1 0 14 6c-1.2-.4-2-1.5-2-2.8Z", fill: "#E5C691" }),
        React.createElement("path", { d: "M5.9 13.7c-1.5-1.5-3.4 0-3.3 2 .1 2.6-2.6 2.2-2.6 2.2s1-1.2.7-3.6c-.3-2.4 1.2-5 3.7-4.1 1.4.6 2.1 1.6 2.5 2.3", fill: "#EFBC4B" }),
        React.createElement("path", { d: "m20.9 23-4.2.5-2.1-1L16 21l1.4 1.1h1.8l-3-3 1.3-2 3.7 4.5.1.2c.4.5.1 1.2-.5 1.2Z", fill: "#EAEAE4" }),
        React.createElement("path", { d: "M21.3 21.6c-.7 0-1.5-.2-2.2-.4a7 7 0 0 1-1-.3l-1.8-1.8 1.3-2 3.7 4.5Z", fill: "#CECECC" }),
        React.createElement("path", { d: "m28 11.6-.9 1H27a1 1 0 0 1-1.5 0l-.3-.2-1-.3a3 3 0 0 1-2.3-2.5s-.3.6-.3 1.5A4.1 4.1 0 0 0 22 13l.3.6c1.5 2.3-.3 4.6-.3 4.6l3.4 1.2c.2.1.3.2.3.4.3 1 1.2 5.4-2.7 6.7l-.1-2.2 1.6-.5v-3.3s-3.2 0-5.4-.5a7 7 0 0 1-1-.4 7.9 7.9 0 0 0-3.4-.6l-2.4.7-1.1.3c-.5.7-1.5 2-3 2l-1.6-.2-1.2-.4s1.4 3.9-1.2 6.2l-1-1.5s3.2-1.3 0-6l1-.9s1.5 1 2.9-.4c0 0-2.7-1.4-1.3-4.8.5-1.2 1.2-1.7 2-1.8l.4-.1L17 9.8c1.1-2.4 3.4-5.6 6.8-5 0 0 1-1.2 1.8-1.2l-.2 2 2.7 6Z", fill: "#EAEAE4" }),
        React.createElement("path", { d: "m27.1 12.5-.1.1a1 1 0 0 1-1.5 0l-.3-.2-1-.3a3 3 0 0 1-2.3-2.5s-.3.6-.3 1.5c0 .5 0 1 .3 1.7a2.8 2.8 0 0 1-.2-4.6l2-1.6c-.7.7 1 4.8 3.3 5.9h.1Z", fill: "#CECECC" }),
        React.createElement("path", { d: "m16 21 1.5 1.1-.8 1.4-2.1-1L16 21ZM25.2 25c-.5.6-1.2 1.2-2.2 1.5l-.1-2.2 1.6-.5.7 1.2Z", fill: "#455256" }),
        React.createElement("path", { d: "M19 20a7 7 0 0 1-1-.4 7.9 7.9 0 0 0-3.3-.6l-2.4.7.2-6 1.7.4c-.1.8 1.2 4.7 4.9 5.9ZM6.6 21.8l-1.2-.4s1.4 3.9-1.2 6.2l-1-1.5s3.2-1.3 0-6l1-.9s1.5 1 2.9-.4c0 0-2.7-1.4-1.3-4.8.5-1.2 1.2-1.7 2-1.8l.4-.1.2.8s-2 3 .3 5.5c0 0 .4 2.2-2 3.4Z", fill: "#CECECC" }),
        React.createElement("path", { d: "m4.2 25.2 1.2.8c-.3.6-.7 1.1-1.2 1.6l-1-1.5s.6-.2 1-1Z", fill: "#455256" }),
        React.createElement("path", { d: "M24.8 8c-.4 0-.6-.6-.3-.8.3-.3.8 0 .7.4 0 .2-.2.4-.4.4Z", fill: "#252C2D" }),
        React.createElement("path", { d: "M21.9 10h-.1a.4.4 0 0 1-.3-.5c.8-3 3.5-4.3 3.6-4.3a.4.4 0 0 1 .4.8S23 7.2 22.3 9.8c0 .1-.2.3-.4.3ZM25.2 12.8a.4.4 0 0 1-.4-.6A5.4 5.4 0 0 1 27 9.6a.4.4 0 0 1 .4.8s-1.3.7-2 2.1c0 .2-.2.3-.3.3Z", fill: "#455256" }),
        React.createElement("path", { d: "M26.1 11c-1.8-1-3.2-3.4-3.3-3.5l.7-.4-.3.2.3-.2s1.4 2.4 3 3.2l-.4.8ZM16 3.1l-4-.7-1.6-.2a2.9 2.9 0 0 1 5.6 1Z", fill: "#455256" }),
        React.createElement("path", { d: "M14 .5c-1 .3-1.7 1-2 2l-1.6-.3A2.9 2.9 0 0 1 14 .5Z", fill: "#2D373A" }),
        React.createElement("path", { d: "M22 12.8v.2c-.7.4-1.5.7-2.5.8a12.7 12.7 0 0 1-5-.7l-1.5-.6.3-.8 1.5.6 1.1.3c2.6.6 4.5.4 5.8-.4l.2.6Z", fill: "#CECECC" }),
        React.createElement("path", { d: "M16.2 9.6h-2.6l-1.1-2.8-.5-1c-.5-.9-1.9-.9-2.3 0-1.2 2.3-2.4 5.2-1.9 6.4.8 1.7 4 2 4 2L11 20h3.7v-.5c0-.7-.5-1.2-1.2-1.2l.7-4.8c0-.5-.2-1-.6-1.3l-.4-.2 3-.1c.6 0 1.1-.6 1.1-1.2s-.6-1.2-1.2-1.1Z", fill: "#F7CF2B" }),
        React.createElement("path", { d: "M20.5 13.2a12.7 12.7 0 0 1-5-1.2l-1.4-.7.4-.7 1.5.7 1 .4c2.7.8 4.7.8 6 0 1.3-.9 1.5-2.1 1.5-2.2l.8.1a4 4 0 0 1-1.8 2.8c-.8.5-1.8.8-3 .8Z", fill: "#455256" }),
        React.createElement("path", { d: "M8.7 12.2c-.5-1.2.7-4 1.8-6.3.2-.3.5-.6.8-.7-.6-.2-1.3 0-1.6.7-1.2 2.2-2.4 5-1.9 6.3.8 1.7 4 2 4 2L11 20h.8l.7-5.1c0-.5-.3-1-.8-1-1-.2-2.6-.6-3-1.7Z", fill: "#DBB826" }),
        React.createElement("path", { d: "M14.8 19.5v.5h-3.7l.5-4.3H14l-.4 2.6c.7 0 1.2.5 1.2 1.2Z", fill: "#455256" }),
        React.createElement("path", { d: "m12.5 15.7-.6 4.3h-.8l.5-4.3h.9Z", fill: "#2D373A" }),
        React.createElement("path", { d: "m17.4 10.7-.1.6c-.2.3-.6.6-1 .6h-3.4a1.2 1.2 0 0 1-1.1-.6l-.2-.6-2.9-.3H8c.3-1.4 1-3 1.8-4.5.3-.7 1-.9 1.6-.7.3.1.5.4.7.7l.5.9 1.1 2.8h2.6c.6 0 1.2.5 1.2 1.1Z", fill: "#E22342" }),
        React.createElement("path", { d: "M11.3 5.2c-.3.1-.6.4-.8.7C9.8 7.4 9 9 8.7 10.4H8c.3-1.4 1-3 1.8-4.5a1.3 1.3 0 0 1 1.6-.7Z", fill: "#C9183A" }),
        React.createElement("path", { d: "M14.4 4.1a.4.4 0 0 1-.4-.5l.1-.8a.4.4 0 0 1 .9.2l-.2.8c0 .2-.2.3-.4.3Z", fill: "#252C2D" }),
        React.createElement("path", { d: "M11.9 4.7h-.1A.8.8 0 0 1 11 4l-.4-1.4a.4.4 0 1 1 .8-.3l.4 1.3.5-1a.4.4 0 1 1 .8.3l-.7 1.3c0 .2-.4.4-.6.4Z", fill: "#455256" }),
        React.createElement("path", { d: "M17.3 11.3c-.2.3-.6.6-1 .6h-3.4a1.2 1.2 0 0 1-1.1-.6l-.2-.6-1-2.4c.4-.1.8.1 1 .5l1 2.1c0 .2.3.4.5.4h4.2Z", fill: "#C9183A" }),
        React.createElement("path", { d: "M17.4 10.7c0 .6-.5 1.2-1.1 1.2h-.9V9.6h.8a1.2 1.2 0 0 1 1.2 1.1Z", fill: "#E5C691" }),
        React.createElement("path", { d: "M17.4 10.4c-.2.5-.6.9-1.1.9h-.9V9.6h.8c.5 0 1 .3 1.2.8Z", fill: "#F9DCA2" }),
        React.createElement("path", { d: "M17.5 3.4a.4.4 0 0 1-.5.3l-1-.1-5.7-1c0-.3.2-.6.3-.8l5.3.9 1.2.2c.2 0 .4.2.4.5Z", fill: "#2D373A" })));
};
