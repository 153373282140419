import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useIsDesktop } from '~hooks';
import { currencyConverter } from '~utils/converters';
import { getTestId } from '~utils/getTestId';
import { useStyles } from './Tip.styles';
export var TipPayoutStatus = function (_a) {
    var status = _a.status, cashedOutAmount = _a.cashedOutAmount, cashout = _a.cashout, winAmount = _a.winAmount, currency = _a.currency;
    var isDesktop = useIsDesktop();
    var classes = useStyles();
    var t = useTranslation('ticket').t;
    var isCashOutPosible = status === 'OPEN' && cashout;
    var convertedAmount = currencyConverter(cashout || cashedOutAmount || winAmount, currency);
    return (React.createElement("div", { role: "button", tabIndex: 0, className: classNames('cash-on-button', isDesktop && classes.cashOnBtnDesktop), "data-testid": getTestId('BTN_OPEN_TICKET') },
        React.createElement("span", { className: "cash-on-button__label" },
            React.createElement("div", { className: "cash-on-button__label__".concat(status) },
                React.createElement("b", null, status && t("status.".concat(status)))),
            React.createElement("div", { className: "cash-on-button__label__value" }, (isCashOutPosible || cashedOutAmount || winAmount) &&
                convertedAmount))));
};
