var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _a;
import { createAction, createSlice, } from '@reduxjs/toolkit';
import merge from 'lodash.merge';
import { createAsyncReducers } from '~utils/createAsyncReducers';
import { name } from '~slices/betslip/constants';
import { UPDATE_TYPE_MATCH, UPDATE_TYPE_ODD } from '~slices/events';
import { addBanker, addCombination, deleteBanker, deleteCombination, deleteDeactivatedOdds, deleteFullBetslip, deleteOutcomeFromEvent, getFullBetslip, updateWager, } from '~slices/betslip/thunks';
export var connectSocket = createAction("".concat(name, "/connectSocket"), function (params) { return ({
    payload: __assign(__assign({}, params), { url: params.url, betSlipId: params.betSlipId }),
}); });
var initialState = {
    data: {},
    loading: 'idle',
    error: null,
};
export var betslip = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        updateSsua: function (state, _a) {
            var payload = _a.payload;
            return __assign(__assign({}, state), { data: __assign(__assign({}, state.data), { ssua: payload.ssua }) });
        },
        updateMaxOdd: function (state, _a) {
            var payload = _a.payload;
            return __assign(__assign({}, state), { data: __assign(__assign({}, state.data), { to: __assign(__assign({}, state.data.to), { mo: payload }) }) });
        },
        updateEvents: function (state, 
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        _a) {
            var _b, _c, _d, _e, _f, _g, _h, _j;
            var payload = _a.payload;
            var _k = payload, _l = UPDATE_TYPE_MATCH, match = _k[_l], _m = UPDATE_TYPE_ODD, odd = _k[_m];
            // @ts-ignore
            var newMatchesById = match.reduce(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            function (acc, event) {
                var _a;
                var evs = (_a = event === null || event === void 0 ? void 0 : event.to) === null || _a === void 0 ? void 0 : _a.evs;
                // @ts-ignore
                var nestedMatches = evs === null || evs === void 0 ? void 0 : evs.reduce(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                function (nestedAcc, _a) {
                    var _b;
                    var lu = _a.lu, rest = __rest(_a, ["lu"]);
                    return (__assign(__assign({}, nestedAcc), (_b = {}, _b[rest.id] = rest, _b)));
                }, {});
                return __assign(__assign({}, acc), nestedMatches);
            }, {});
            // @ts-ignore
            var newMatchesByOdd = odd.reduce(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            function (acc, event) {
                var _a;
                var evs = (_a = event === null || event === void 0 ? void 0 : event.to) === null || _a === void 0 ? void 0 : _a.evs;
                // @ts-ignore
                var nestedMatches = evs === null || evs === void 0 ? void 0 : evs.reduce(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                function (nestedAcc, nestedEvent) {
                    var _a;
                    return (__assign(__assign({}, nestedAcc), (_a = {}, _a[nestedEvent.id] = nestedEvent, _a)));
                }, {});
                return __assign(__assign({}, acc), nestedMatches);
            }, {});
            var restTo;
            restTo = state.data.to;
            if (state.data.to) {
                var _o = state.data.to, bw = _o.bw, wa = _o.wa, restData = __rest(_o, ["bw", "wa"]);
                restTo = restData;
            }
            return __assign(__assign({}, state), { data: __assign(__assign({}, state.data), { to: __assign(__assign(__assign({}, state.data.to), restTo), { bts: odd.length ? odd[0].to.bts : (_c = (_b = state.data) === null || _b === void 0 ? void 0 : _b.to) === null || _c === void 0 ? void 0 : _c.bts, mo: odd.length ? odd[0].to.mo : (_e = (_d = state.data) === null || _d === void 0 ? void 0 : _d.to) === null || _e === void 0 ? void 0 : _e.mo, mp: odd.length ? odd[0].to.mp : (_g = (_f = state.data) === null || _f === void 0 ? void 0 : _f.to) === null || _g === void 0 ? void 0 : _g.mp, evs: (_j = (_h = state.data.to) === null || _h === void 0 ? void 0 : _h.evs) === null || _j === void 0 ? void 0 : _j.map(function (event) {
                            if (newMatchesById[event.id] || newMatchesByOdd[event.id]) {
                                return merge({}, event, newMatchesByOdd[event.id], newMatchesById[event.id]);
                            }
                            return event;
                        }) }) }) });
        },
        updateBetslip: function (state, _a) {
            var payload = _a.payload;
            var to = payload.to, rest = __rest(payload, ["to"]);
            return __assign(__assign({}, state), { data: __assign(__assign(__assign({}, state.data), rest), { to: __assign(__assign({}, state.data.to), to) }) });
        },
        resetBetslip: function (state) {
            var _a, _b;
            var mo = (_b = (_a = state.data) === null || _a === void 0 ? void 0 : _a.to) === null || _b === void 0 ? void 0 : _b.mo;
            return __assign(__assign({}, initialState), { data: {
                    to: {
                        mo: mo,
                    },
                } });
        },
    },
    extraReducers: function (builder) {
        var _a = createAsyncReducers(), pending = _a.pending, fulfilled = _a.fulfilled, rejected = _a.rejected;
        builder
            .addCase(getFullBetslip.fulfilled, fulfilled)
            .addCase(getFullBetslip.rejected, rejected)
            .addCase(addCombination.fulfilled, fulfilled)
            .addCase(addCombination.rejected, rejected)
            .addCase(deleteCombination.fulfilled, fulfilled)
            .addCase(deleteCombination.rejected, rejected)
            .addCase(updateWager.fulfilled, fulfilled)
            .addCase(updateWager.rejected, rejected)
            .addCase(deleteOutcomeFromEvent.fulfilled, fulfilled)
            .addCase(deleteOutcomeFromEvent.rejected, rejected)
            .addCase(deleteFullBetslip.pending, pending)
            .addCase(deleteFullBetslip.fulfilled, fulfilled)
            .addCase(deleteFullBetslip.rejected, rejected)
            .addCase(addBanker.fulfilled, fulfilled)
            .addCase(addBanker.rejected, rejected)
            .addCase(deleteBanker.fulfilled, fulfilled)
            .addCase(deleteBanker.rejected, rejected)
            .addCase(deleteDeactivatedOdds.pending, pending)
            .addCase(deleteDeactivatedOdds.fulfilled, fulfilled)
            .addCase(deleteDeactivatedOdds.rejected, rejected);
    },
});
export var updateEvents = (_a = betslip.actions, _a.updateEvents), updateBetslip = _a.updateBetslip, updateSsua = _a.updateSsua, updateMaxOdd = _a.updateMaxOdd, resetBetslip = _a.resetBetslip;
export var disconnect = createAction("".concat(name, "/disconnect"));
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export var socketMessage = createAction("".concat(name, "/socketMessage"));
