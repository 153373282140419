import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { BpListShortcut, generate } from '~molecules';
import { betslipDataSelector } from '~slices/betslip';
import { useStructuredSelector } from '~hooks';
import { TicketDetails } from './TicketDetails';
var useStyles = makeStyles(function (theme) { return ({
    root: {
        marginBottom: '10px',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
    },
    icon: {
        fontSize: '9px',
        marginRight: '8px',
        color: '#8e8e8e',
    },
    countContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: '30px',
        background: '#23232B',
    },
    count: {
        fontSize: '16px',
        lineHeight: '18px',
        color: '#FFFFFF',
    },
    svg: {
        width: 100,
        height: 100,
    },
    polygon: {
        fill: theme.palette.common.white,
        stroke: theme.palette.divider,
        strokeWidth: 1,
    },
    wrapperClass: {
        position: 'relative',
    },
}); }, { name: 'TicketDetailsBar' });
export var TicketDetailsBar = function () {
    var _a, _b, _c, _d;
    var classes = useStyles();
    var t = useTranslation('ticket').t;
    var _e = React.useState(false), open = _e[0], setOpen = _e[1];
    var betslip = useStructuredSelector({
        betslip: betslipDataSelector,
    }).betslip;
    var handleClick = function () {
        setOpen(function (prev) { return !prev; });
    };
    var count = (((_a = betslip.to) === null || _a === void 0 ? void 0 : _a.evs) || []).reduce(function (acc, _a) {
        var sl = _a.sl;
        return acc + Object.values((sl === null || sl === void 0 ? void 0 : sl.sel) || {}).length;
    }, 0);
    return (React.createElement("div", { className: classes.root },
        React.createElement("div", { className: classes.container },
            React.createElement("div", { className: classNames(classes.countContainer), role: "button", tabIndex: 0 }, count < 11 ? (generate(React.createElement("i", { className: "fas fa-circle ".concat(classes.icon) }), count)) : (React.createElement(React.Fragment, null,
                React.createElement("i", { className: "fas fa-circle ".concat(classes.icon) }),
                React.createElement("span", { className: classes.count }, count)))),
            React.createElement(Collapse, { in: open },
                React.createElement(Paper, { elevation: 4 },
                    React.createElement(TicketDetails, null))),
            React.createElement(BpListShortcut, { open: open, t: t, handleClick: handleClick, betType: (_b = betslip === null || betslip === void 0 ? void 0 : betslip.to) === null || _b === void 0 ? void 0 : _b.bt, mp: "".concat((_c = betslip.to) === null || _c === void 0 ? void 0 : _c.wa), wrapperClass: classes.wrapperClass, currency: (_d = betslip.to) === null || _d === void 0 ? void 0 : _d.cu, flipIcon: true }))));
};
