var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
import mergeWith from 'lodash.mergewith';
import { UPDATE_TYPE_MATCH, UPDATE_TYPE_ODD, addToFavourite, removeFromFavourite, } from '~slices/events';
import { name } from './constants';
var initialState = {
    data: {},
    loading: 'idle',
    error: null,
};
export var sidebets = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        getEvent: function (state, _a) {
            var payload = _a.payload;
            return __assign(__assign({}, state), { data: __assign(__assign({}, payload), { activeSidebetId: 0 || state.data.activeSidebetId }) });
        },
        pickActiveSidebetId: function (state, _a) {
            var payload = _a.payload;
            return __assign(__assign({}, state), { data: __assign(__assign({}, initialState.data), { activeSidebetId: 0 || payload }) });
        },
        updateEvent: function (state, _a) {
            var payload = _a.payload;
            var _b = payload, _c = UPDATE_TYPE_MATCH, match = _b[_c], _d = UPDATE_TYPE_ODD, odd = _b[_d];
            state.data.updateTime = new Date();
            match.forEach(function (data) {
                state.data = mergeWith(state.data, data, function (objValue, srcValue, key) {
                    if (key === 'mr') {
                        return objValue;
                    }
                });
            });
            if (!state.data.mr) {
                state.data.mr = {};
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            odd.forEach(function (markets) {
                for (var key in state.data.mr) {
                    if (!markets.mr.hasOwnProperty(key)) {
                        delete state.data.mr[key];
                    }
                }
                Object.keys(markets.mr).forEach(function (market) {
                    var _a;
                    if (!state.data.mr) {
                        return;
                    }
                    if (!state.data.mr.hasOwnProperty(market)) {
                        state.data.mr[market] = markets.mr[market];
                    }
                    else {
                        state.data.mr[market] = __assign(__assign(__assign({}, state.data.mr[market]), markets.mr[market]), { oc: (((_a = state.data.mr[market]) === null || _a === void 0 ? void 0 : _a.oc) || []).map(function (ocItem) { return (__assign(__assign({}, ocItem), markets.mr[market].oc.find(function (updatedOcItem) {
                                return ocItem.id === updatedOcItem.id;
                            }))); }) });
                    }
                });
            });
            return state;
        },
        resetSidebets: function () {
            return initialState;
        },
    },
    extraReducers: function (builder) {
        builder.addCase(removeFromFavourite.fulfilled.toString(), function (state, _a) {
            var payload = _a.payload;
            if (state.data.bid === payload.eventId) {
                state.data.fav = false;
            }
        });
        builder.addCase(addToFavourite.fulfilled.toString(), function (state, _a) {
            var payload = _a.payload;
            if (state.data.bid === payload.eventId) {
                state.data.fav = true;
            }
        });
    },
});
export default sidebets.reducer;
export var getEvent = (_a = sidebets.actions, _a.getEvent), resetSidebets = _a.resetSidebets, pickActiveSidebetId = _a.pickActiveSidebetId;
