import { chars, filterChars, filters } from '~utils/stringNormalization';
var additionalAllowedSymbols = [
    chars.space,
    chars.apostrophe,
    chars.umlauts,
    chars.hyphenMinus,
].join('');
var normalizeTextFieldValue = function (value) {
    if (typeof value !== 'string') {
        return value;
    }
    var filteredChars = filterChars(value, function (char) {
        return (filters.checkIsInCharset(char, additionalAllowedSymbols) ||
            filters.checkIsLatin(char));
    });
    var hasNonSpaceChars = !!filteredChars.split(' ').filter(Boolean).length;
    return hasNonSpaceChars ? filteredChars : '';
};
var normalizeNumberFieldValue = function (value) {
    if (typeof value !== 'string') {
        return value;
    }
    return filterChars(value, filters.checkIsDecimal).trim();
};
export { normalizeTextFieldValue, normalizeNumberFieldValue };
