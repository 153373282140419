import React from 'react';
import { ArrowIcon } from '~icons/arrow-icon';
import { useStyles } from './Arrows.styles';
var Arrows = function () {
    var classes = useStyles();
    return (React.createElement("div", { className: classes.container },
        React.createElement(ArrowIcon, { className: classes.arrow, style: { animationDelay: '-300ms' } }),
        React.createElement(ArrowIcon, { className: classes.arrow, style: { animationDelay: '-150ms' } }),
        React.createElement(ArrowIcon, { className: classes.arrow, style: { animationDelay: '0ms' } })));
};
export { Arrows };
