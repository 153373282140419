import React from 'react';
import ReactDOM from 'react-dom';
import ReactPixel from 'react-facebook-pixel';
import App from '~app/App';
import { registerServiceWorker, uregisterServiceWorker, } from './serviceWorkerRegistration';
import './index.scss';
console.log("\n  VERSION: ".concat(process.env.REACT_APP_VERSION, "\n  COMMIT: ").concat(process.env.COMMIT_HASH, "\n  COMMIT_DATE: ").concat(process.env.COMMIT_DATE, "\n\n"));
var facebookPixelId = process.env.FACEBOOK_PIXEL_ID || '';
ReactPixel.init(facebookPixelId);
var render = function () {
    ReactDOM.render(React.createElement(React.StrictMode, null,
        React.createElement(App, null)), document.getElementById('root'));
};
render();
// @ts-expect-error
if (module.hot) {
    // @ts-expect-error
    module.hot.accept('~app/App', function () {
        render();
    });
}
if (String(process.env.mode) !== 'local' &&
    process.env.REACT_APP_SERVICE_WORKER_ENABLE === 'true') {
    registerServiceWorker();
}
if (process.env.REACT_APP_SERVICE_WORKER_ENABLE === 'false') {
    console.log('uregisterServiceWorker');
    uregisterServiceWorker();
}
