import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { useAppDispatch } from '~store/configureStore';
import { BottomButtons, Button, FullscreenLoader, TextInput } from '~atoms';
import { PPMODataSelector, getDefaultPPMO, isFulfilledSelector, isPendingSelector, setPPMOType, } from '~slices/selectPPMO';
import { useStructuredSelector } from '~hooks';
import { getPathWithLanguageCode } from '~utils/languageInURL/getPathWithLanguageCode';
import { Wallet } from './components/Wallet';
import { usePPMOStyles } from './SelectPPMO.styles';
var PPMOTypes;
(function (PPMOTypes) {
    PPMOTypes["M"] = "M";
    PPMOTypes["D"] = "D";
    PPMOTypes["O"] = "O";
})(PPMOTypes || (PPMOTypes = {}));
export var SelectPPMO = function () {
    var classes = usePPMOStyles();
    var _a = useStructuredSelector({
        isPending: isPendingSelector,
        fulfilled: isFulfilledSelector,
        ppmoData: PPMODataSelector,
    }), isPending = _a.isPending, fulfilled = _a.fulfilled, _b = _a.ppmoData, phoneCode = _b.phoneCode, phone = _b.phone, ppmo = _b.ppmo;
    var dispatch = useAppDispatch();
    var t = useTranslation('PPMO').t;
    var _c = useState(ppmo), selected = _c[0], setSelected = _c[1];
    var setPPMO = function () {
        dispatch(setPPMOType(selected));
    };
    var phoneNumber = phone === null || phone === void 0 ? void 0 : phone.replace(phoneCode, '');
    var changePPMOType = function (type) {
        setSelected(type);
    };
    useEffect(function () {
        dispatch(getDefaultPPMO());
    }, []);
    if (isPending) {
        return React.createElement(FullscreenLoader, null);
    }
    if (fulfilled) {
        return (React.createElement("div", { className: classes.container },
            React.createElement("h1", { className: classes.header }, t('select_PPMO')),
            React.createElement("div", { className: "flex-row" },
                React.createElement(Grid, { container: true, spacing: 2 },
                    React.createElement(Grid, { key: "title", item: true, xs: 5 },
                        React.createElement(TextInput, { title: t('international_code'), disabled: true, placeholder: phoneCode || t('phone_code') })),
                    React.createElement(Grid, { key: "\u0441ity", item: true, xs: 7 },
                        React.createElement(TextInput, { title: t('mobile_number'), placeholder: phoneNumber || t('mobile_number'), disabled: true })))),
            React.createElement(Divider, null),
            React.createElement("div", { className: classes.ppmoTypes },
                React.createElement(Wallet, { type: PPMOTypes.D, icon: React.createElement("i", { className: "far fa-credit-card" }), title: t('direct_pay'), helpfulIcon: React.createElement("i", { className: "fas fa-crown" }), color: "#2AA1F7", infoText: "Direct pay works like this", infoTitle: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since", active: selected ? selected === PPMOTypes.D : ppmo === PPMOTypes.D, onClick: changePPMOType }),
                React.createElement(Wallet, { type: PPMOTypes.M, icon: React.createElement("i", { className: "far fa-credit-card" }), title: t('manual_pay'), infoText: "Manual pay works like this", infoTitle: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since", active: selected ? selected === PPMOTypes.M : ppmo === PPMOTypes.M, onClick: changePPMOType })),
            React.createElement(BottomButtons, { withBackground: false },
                React.createElement(Grid, { container: true, justify: "space-between", spacing: 2 },
                    React.createElement(Grid, { key: "cancel", item: true },
                        React.createElement(Button, { size: "large", variant: "outlined", classes: { root: classes.button }, component: Link, to: getPathWithLanguageCode('/home'), color: "secondary" }, t('cancel'))),
                    React.createElement(Grid, { key: "next", item: true },
                        React.createElement(Button, { type: "submit", size: "large", classes: { root: classes.button }, onClick: setPPMO, variant: "contained", color: "primary" }, t('save')))))));
    }
    return null;
};
