import React from 'react';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { useLocation } from 'react-router-dom';
import success from '~icons/statusIcons/success.svg';
import { StatusScreen } from '~molecules';
import { useIsMobileDevise, useStructuredSelector } from '~hooks';
import { cashOutDataSelector } from '~slices/cashOut';
import { Button } from '~atoms';
import { getPathWithLanguageCode } from '~utils/languageInURL';
import { useAppDispatch } from '~store';
import { useStyles } from '~molecules/WithdrawTab/components/WithdrawConfirmation/WithdrawConfirmation.styles';
export var WithdrawConfirmation = function () {
    var t = useTranslation('tip-game').t;
    var classes = useStyles();
    var isMobileDevice = useIsMobileDevise();
    var dispatch = useAppDispatch();
    // TODO, replace with real data from BE
    var _a = useStructuredSelector({ cashoutData: cashOutDataSelector }).cashoutData.currency, currency = _a === void 0 ? 'EUR' : _a;
    var search = useLocation().search;
    var params = new URLSearchParams(search);
    var amount = params.get('sum');
    var header = t('payments.withdraw_successful');
    var subheader = "".concat(amount);
    var onButtonClick = function (isMoneyReceived) {
        isMoneyReceived
            ? console.log('Money Received!')
            : console.log('No Money Received!');
        dispatch(push(getPathWithLanguageCode("/home/all/live")));
    };
    return (React.createElement("div", { className: classes.wrapper },
        React.createElement(StatusScreen, { header: header, subheader: subheader, currency: currency, logo: success, isMobile: isMobileDevice }),
        React.createElement("div", { className: classes.actionBlock },
            React.createElement(Button, { color: "primary", variant: "contained", fullWidth: true, className: classes.receivedButton, onClick: function () { return onButtonClick(true); } }, t('payments.money_received')),
            React.createElement(Button, { color: "primary", variant: "contained", fullWidth: true, className: classes.NotReceivedButton, onClick: function () { return onButtonClick(false); } }, t('payments.no_money_received')))));
};
