import React from 'react';
import { localeDataSelector } from '~slices/locale';
import { useStructuredSelector } from '~hooks';
import RegistrationSteps from '~pages/registration/components/RegistrationSteps';
export var StepModal = function (_a) {
    var step = _a.step, onSubmit = _a.onSubmit, onCancel = _a.onCancel, onBack = _a.onBack, isDesktop = _a.isDesktop;
    var _b = useStructuredSelector({
        localeData: localeDataSelector,
    }).localeData, countries = _b.countries, titles = _b.titles, defaultCountry = _b.defaultCountry, registrationBlocks = _b.registrationBlocks;
    var initialValues = {
        userProfileDto: {
            addresses: {
                countryCode: defaultCountry,
            },
        },
    };
    return (React.createElement(RegistrationSteps, { initialValues: initialValues, step: step, countries: countries, registrationBlocks: registrationBlocks, titles: titles, isDesktop: isDesktop, onSubmit: onSubmit, onCancel: onCancel, onBack: onBack }));
};
