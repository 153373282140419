var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import classNames from 'classnames';
import Tabs from '@material-ui/core/Tabs';
import withStyles from '@material-ui/core/styles/withStyles';
import { useIsDesktop } from '~hooks';
export var StyledTabs = withStyles({
    scroller: {
        overflowY: 'hidden',
    },
    root: {
        width: '100%',
    },
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        '& > span': {
            width: '64px',
            backgroundColor: '#FFF',
        },
        '&.desktop > span': {
            width: '100%',
        },
        '&.withoutIndicator > span': {
            display: 'none',
        },
    },
})(function StyledTabs(_a) {
    var rootClassName = _a.rootClassName, indicatorClassName = _a.indicatorClassName, value = _a.value, withoutIndicator = _a.withoutIndicator, rest = __rest(_a, ["rootClassName", "indicatorClassName", "value", "withoutIndicator"]);
    var isDesktop = useIsDesktop();
    var tabsActions = React.useRef(null);
    React.useEffect(function () {
        var _a;
        if (tabsActions.current) {
            (_a = tabsActions.current) === null || _a === void 0 ? void 0 : _a.updateIndicator();
        }
    }, [value]);
    return (React.createElement(Tabs, __assign({}, rest, { value: value, classes: {
            root: classNames(rest.classes.root, rootClassName),
            indicator: classNames(rest.classes.indicator, indicatorClassName, {
                desktop: isDesktop,
                withoutIndicator: withoutIndicator,
            }),
            scroller: rest.classes.scroller,
        }, TabIndicatorProps: { children: React.createElement("span", null) }, action: tabsActions })));
});
