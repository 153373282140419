import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '~atoms';
import { checkIfFieldIsRequired, required, validateField, } from '~utils/formValidators';
export var RepeatedPassword = function (_a) {
    var tabIndex = _a.tabIndex, registrationBlocks = _a.registrationBlocks, isDesktop = _a.isDesktop;
    var t = useTranslation('registration').t;
    return (React.createElement(TextField, { name: "repeatedPassword", title: t('form.repeat_password'), placeholder: t('form.enter_password_again'), type: "password", validators: function (value) {
            return validateField(value, 'repeatedPassword', tabIndex, required, registrationBlocks);
        }, visibilityColor: isDesktop ? '#212121' : '', disablePaste: true, required: checkIfFieldIsRequired('repeatedPassword', tabIndex, registrationBlocks) }));
};
