import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useIsDesktop, useStructuredSelector } from '~hooks';
import FootballFieldIcon from '~icons/football-field.jpg';
import tennisFieldIcon from '~icons/tennis-field.jpg';
import { activeSidebetIdSelector, matchEventSelector } from '~slices/sidebets';
import { SmallLoader } from '~atoms/loader';
import { useStyles } from '../Sidebets.styles';
export var WidgetTab = function (_a) {
    var _b;
    var tab = _a.tab;
    var classes = useStyles();
    var isDesktop = useIsDesktop();
    var widgetRef = useRef(null);
    var _c = useStructuredSelector({
        event: matchEventSelector,
        activeID: activeSidebetIdSelector,
    }), event = _c.event, activeID = _c.activeID;
    var t = useTranslation('ticket').t;
    var _d = useState(true), isLoading = _d[0], setIsLoading = _d[1];
    // @ts-expect-error
    var id = useParams().id;
    var matchId = id || activeID;
    var sp = event.sp;
    var unaviableWidgetImage = (sp === null || sp === void 0 ? void 0 : sp.id) === 2 ? tennisFieldIcon : FootballFieldIcon;
    var recalculateWidth = function () {
        if (widgetRef.current) {
            var width = isDesktop ? widgetRef.current.scrollWidth : window.innerWidth;
            widgetRef.current.style.height = "".concat(width * 0.615, "px");
        }
    };
    useEffect(function () {
        window.addEventListener('resize', recalculateWidth);
        window.dispatchEvent(new CustomEvent('resize'));
        return function () {
            window.removeEventListener('resize', recalculateWidth);
        };
    }, []);
    var onAddWidget = function (widget, config) {
        window.SIR('addWidget', '.sr-widget', widget, config);
    };
    var addWidget = function (widget, config) {
        // TODO remove setTimeout
        if (document.querySelector('.sr-widget')) {
            onAddWidget(widget, config);
        }
        else {
            setTimeout(onAddWidget, 100, widget, config);
        }
    };
    var removeWidget = function () {
        window.SIR('removeWidget', '.sr-widget');
    };
    var _e = useState(false), isVisibleWidget = _e[0], setIsVisibleWidget = _e[1];
    var showWidget = function () {
        setIsVisibleWidget(true);
    };
    var hideWidget = function () {
        setIsVisibleWidget(false);
    };
    var tabs = {
        'live-stream': function () {
            removeWidget();
            hideWidget();
        },
        commentary: function () {
            addWidget('match.lmtPlus', {
                matchId: matchId,
                scoreboard: 'disable',
                detailedScoreboard: 'disable',
                disablePitch: 'true',
                tabsPosition: 'disable',
                tabs: {
                    1: ['timeline'],
                },
                onTrack: function () {
                    setIsLoading(false);
                },
            });
            showWidget();
        },
        'live-tracker': function () {
            addWidget('match.lmtPlus', {
                matchId: matchId,
                layout: 'single',
                scoreboard: 'disable',
                detailedScoreboard: 'disable',
                tabsPosition: isDesktop ? 'disable' : 'true',
                dataByLogoColor: 'black',
                onTrack: function () {
                    setIsLoading(false);
                },
            });
            showWidget();
        },
        statistics: function () {
            addWidget('match.statistics', {
                matchId: matchId,
            });
            showWidget();
        },
        default: function () {
            removeWidget();
            hideWidget();
        },
    };
    useEffect(function () {
        (tabs[tab] || tabs.default)();
        return function () {
            removeWidget();
        };
    }, [tab, matchId]);
    // TODO change displaying image if widget unaviable
    return (React.createElement("div", { ref: widgetRef, className: classNames(classes.srWidgetContainer, (_b = {},
            _b[classes.flex] = isLoading,
            _b)) },
        isLoading && React.createElement(SmallLoader, { className: classes.fullWidth }),
        isVisibleWidget ? (React.createElement("div", { className: "sr-widget" })) : (React.createElement("div", { className: classes.unaviableWidget, style: { backgroundImage: "url(".concat(unaviableWidgetImage) } }, t('unaviable_match')))));
};
