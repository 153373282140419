import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { reduxForm } from 'redux-form';
import { goBack } from 'connected-react-router';
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from '@material-ui/core/Grid';
import i18next from 'i18next';
import { requestWithoutAuth } from '~utils/request';
import { useAppDispatch } from '~store';
import { required } from '~utils/formValidators';
import { ER2003 } from '~utils/errorcodes';
import { useIsDesktop } from '~hooks';
import { Button, TextField } from '~atoms';
import { AlertDialogSlide } from '~molecules';
import { MODALS, closeModal, openModal } from '~modals';
import { usePasswordRestoreFormStyles } from './PasswordRestoreForm.styles';
var PasswordRestoreForm = function (_a) {
    var handleSubmit = _a.handleSubmit, onModalClose = _a.onModalClose;
    var t = useTranslation(['login', 'errors']).t;
    var isDesktop = useIsDesktop();
    var _b = useState(false), modalSuccessWindow = _b[0], setModalSuccessWindow = _b[1];
    var passwordRestoreFormClasses = usePasswordRestoreFormStyles();
    var dispatch = useAppDispatch();
    var _c = useState(false), banModal = _c[0], setBanModal = _c[1];
    var _d = useState(''), formError = _d[0], setFormError = _d[1];
    var _e = useState(false), isDataLoading = _e[0], setIsDataLoading = _e[1];
    var handleCloseBanModal = function () {
        setBanModal(false);
    };
    var goToThePreviousPage = function () {
        dispatch(goBack());
    };
    var successDesktopRestore = function () {
        dispatch(closeModal({ key: MODALS.RESTORE_PASSWORD }));
        dispatch(openModal({
            modalName: MODALS.SUCCESS_MODAL_NOTIFICATION,
            modalProps: {
                title: t('login:success'),
                subheader: t('login:new_password'),
            },
        }));
    };
    var openRestorePassword = function (data) {
        setIsDataLoading(true);
        var options = {
            url: 'auth-service/v1/users/password',
            headers: {
                lang: i18next.language,
            },
            method: 'put',
            data: data,
        };
        requestWithoutAuth(options)
            .then(function (res) {
            var _a;
            if (res.status === 200) {
                (isDesktop ? successDesktopRestore : setModalSuccessWindow)(true);
            }
            else {
                //@ts-ignore
                var errorCode = ((_a = res.errors[0]) === null || _a === void 0 ? void 0 : _a.exceptionCode) || '';
                setFormError(errorCode);
            }
        })
            .catch(function (error) {
            var _a, _b, _c, _d;
            var errorCode = ((_b = (_a = error.response.data) === null || _a === void 0 ? void 0 : _a.errors[0]) === null || _b === void 0 ? void 0 : _b.exceptionCode) || ((_c = error.response.data[0]) === null || _c === void 0 ? void 0 : _c.code);
            if (((_d = error === null || error === void 0 ? void 0 : error.response) === null || _d === void 0 ? void 0 : _d.status) === 401) {
                if (errorCode === ER2003) {
                    setBanModal(true);
                }
            }
            else {
                setFormError(errorCode);
            }
        })
            .finally(function () { return setIsDataLoading(false); });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: passwordRestoreFormClasses.root },
            React.createElement("h1", { className: passwordRestoreFormClasses.title }, t('login:header')),
            React.createElement("div", { className: passwordRestoreFormClasses.main },
                React.createElement("form", null,
                    React.createElement("div", { role: "button", tabIndex: 0, onClick: function () { return setFormError(''); } },
                        React.createElement(TextField, { name: "usernameOrEmail", title: t('login:email_or_username'), validators: required, titleClassName: passwordRestoreFormClasses.labelText, required: true })),
                    React.createElement(FormHelperText, { id: "component-error-text", error: !!formError, classes: { root: passwordRestoreFormClasses.formError } }, t("errors:".concat(formError))))),
            modalSuccessWindow ? (React.createElement(AlertDialogSlide, { open: true, handleClose: function () {
                    goToThePreviousPage();
                }, title: t('login:success'), content: t('login:new_password'), needConfirm: false })) : (React.createElement(React.Fragment, null)),
            React.createElement(Grid, { container: true, justify: "space-between" },
                React.createElement(Grid, null,
                    React.createElement(Button, { size: "large", variant: "outlined", color: "secondary", classes: { root: passwordRestoreFormClasses.button }, onClick: onModalClose || goToThePreviousPage }, t('login:cancel'))),
                React.createElement(Grid, null,
                    React.createElement(Button, { color: "primary", variant: "contained", classes: { root: passwordRestoreFormClasses.button }, disabled: isDataLoading || !!formError, onClick: handleSubmit(openRestorePassword) }, t('login:continue'))))),
        banModal ? (React.createElement(AlertDialogSlide, { handleClose: handleCloseBanModal, open: true, title: t('login:all_attemps'), content: t('login:locked_for_30_minutes'), needConfirm: false })) : null));
};
export default reduxForm({
    form: 'restore-form',
    touchOnChange: true,
})(PasswordRestoreForm);
